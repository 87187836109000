// This is duplicated! But we have too many dependency loops otherwise
const TOAST_NOTIFICATION_TIME_MS = 6000;

// calculates the total time to read a sentence by an average user
// based on number of words in a sentence
export function calcMessageReadingTime(message: string) {
    const wordsPerSec = 4;
    const bufferInSec = 2; // buffer, since a user does not start reading a toast the moment its created.
    try {
        if (typeof message === "string") {
            let calculatedTimeToRead = Math.max(
                Math.ceil(message?.split(" ")?.length / wordsPerSec) * 1000,
                TOAST_NOTIFICATION_TIME_MS
            );
            calculatedTimeToRead += bufferInSec * 1000;
            return calculatedTimeToRead;
        }
    } catch {
        return TOAST_NOTIFICATION_TIME_MS;
    }
    return TOAST_NOTIFICATION_TIME_MS;
}

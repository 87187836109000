import { SceneConfig } from "@internal/data-access-calcifer";
import { ImagePreviewDimension } from "../types";

type BackgroundPreviewType = {
    underlayScene: SceneConfig;
    dimension: ImagePreviewDimension;
};

interface DesignAreaDetails {
    x: number;
    y: number;
    height?: number;
    width?: number;
}

const CIMPRESS_RENDERING_URL = "https://rendering.documents.cimpress.io";

export const getBackgroundPreview = ({ underlayScene, dimension }: BackgroundPreviewType) => {
    const sceneWidth = underlayScene.sceneInfo?.width;
    const sceneHeight = underlayScene.sceneInfo?.height;

    let designAreaDetails: DesignAreaDetails = { x: 0, y: 0 };
    if (underlayScene.sceneInfo?.slots && underlayScene.sceneInfo.slots.length) {
        if (underlayScene.sceneInfo.slots[0].bounds) {
            const { x, y, width, height } = underlayScene.sceneInfo.slots[0].bounds;
            designAreaDetails = {
                x,
                y,
                height,
                width
            };
        }
    }

    const width = sceneWidth || dimension.width;
    const height = sceneHeight || dimension.height;

    let widthRatio = 1;
    let heightRatio = 1;

    if (designAreaDetails.width && designAreaDetails.height) {
        widthRatio =
            Math.max(designAreaDetails.width, dimension.width) / Math.min(designAreaDetails.width, dimension.width);
        heightRatio =
            Math.max(designAreaDetails.height, dimension.height) / Math.min(designAreaDetails.height, dimension.height);
    }
    const ratio = Math.max(widthRatio, heightRatio);
    const sceneUrl = underlayScene?.url;

    const underlayUrl = `${CIMPRESS_RENDERING_URL}/preview?scene=${encodeURIComponent(
        sceneUrl
    )}&width=${width}&category=studio`;

    const backgroundSize = `${width * ratio}px ${height * ratio}px`;
    const backgroundPosition = `-${designAreaDetails.x * ratio}px -${designAreaDetails.y * ratio}px`;
    const backgroundImage = `url(${underlayUrl})`;

    return { backgroundSize, backgroundPosition, backgroundImage };
};

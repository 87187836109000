import React, { useState } from "react";
import { Column, GridContainer, Row } from "@vp/swan";
import { ErrorIcon } from "./ErrorIcon";
import { ErrorMessagingCTA } from "./ErrorMessagingCTA";
import { ErrorDetailsPanel } from "./ErrorDetailsPanel";

/* Define custom props for your component */
export interface DesignExperienceErrorUiProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Type of icon to show.  Example icons are provided in the ErrorIconTypes object
     */
    Icon: React.ReactNode;
    /**
     * Action when the user clicks on the error logo.
     */
    handleErrorLogoClick?: () => void;
    /**
     * Primary header shown.  Should be succinct.
     */
    errorHeader: string;
    /**
     * Sub header. should be descriptive
     */
    errorSubHeader?: string;
    /**
     * Message for the primary CTA
     */
    primaryButtonMessage: string;
    /**
     * CSS color value for the text
     */
    primaryOnClick: () => void;
    /**
     * Message for the secondary CTA
     */
    secondaryButtonMessage?: string;
    /**
     * Action for the secondary CTA
     */
    secondaryOnClick?: () => void;
    /**
     * Children to be shown in error details.  Generally made up of ErrorDetail components
     */
    errorDetailChildren: React.ReactNode;
}

/* Your starter React component */
export const DesignExperienceErrorUi = (props: DesignExperienceErrorUiProps) => {
    const {
        Icon,
        handleErrorLogoClick,
        errorHeader,
        errorSubHeader,
        primaryButtonMessage,
        primaryOnClick,
        secondaryButtonMessage,
        secondaryOnClick,
        errorDetailChildren,
        ...rest
    } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleLogoClick = () => {
        handleErrorLogoClick && handleErrorLogoClick();
        setShowDetails(!showDetails);
    };

    return (
        <GridContainer {...rest}>
            <Row>
                <Column span={5} spanXs={12} spanSm={12} pushMd={7} pushLg={7} pushXl={7}>
                    <ErrorIcon Icon={Icon} handleErrorLogoClick={handleLogoClick} />
                </Column>
                <Column span={6} spanXs={12} spanSm={12} pullMd={4} pullLg={4} pullXl={4}>
                    <ErrorMessagingCTA
                        errorHeader={errorHeader}
                        errorSubHeader={errorSubHeader}
                        primaryButtonMessage={primaryButtonMessage}
                        primaryOnClick={primaryOnClick}
                        secondaryButtonMessage={secondaryButtonMessage}
                        secondaryOnClick={secondaryOnClick}
                    />
                </Column>
            </Row>
            <Row>{showDetails && <ErrorDetailsPanel>{errorDetailChildren}</ErrorDetailsPanel>}</Row>
        </GridContainer>
    );
};

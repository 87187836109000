import { jsx } from "react/jsx-runtime";
import { createContext, useMemo, useContext, useCallback } from "react";
import { mapDesignAttributeToProductOption } from "@internal/utils-studio-configuration-core";
const SimTeamsNameContext = /* @__PURE__ */ createContext(void 0);
const substrateColorAttributeKey = "Substrate Color";
const SimTeamsNameProvider = ({
  children,
  useDesignAttributeMappings
}) => {
  const designAttributeMappings = /* @__PURE__ */ useDesignAttributeMappings();
  const getSubstrateColor = /* @__PURE__ */ useCallback((studioSelectedProductOptions) => {
    const substrateColorDesignAttribute = /* @__PURE__ */ mapDesignAttributeToProductOption(designAttributeMappings, substrateColorAttributeKey);
    return studioSelectedProductOptions[substrateColorDesignAttribute];
  }, [designAttributeMappings]);
  const contextObject = /* @__PURE__ */ useMemo(() => {
    return {
      getSubstrateColor
    };
  }, [getSubstrateColor]);
  return /* @__PURE__ */ jsx(SimTeamsNameContext.Provider, {
    value: contextObject,
    children
  });
};
SimTeamsNameProvider.displayName = "SimTeamsNameProvider";
const useSimTeamsName = () => {
  const result = /* @__PURE__ */ useContext(SimTeamsNameContext);
  if (!result) {
    throw new Error("useSimTeamsName must be used as a child of <SimTeamsNameProvider />");
  }
  return result;
};
export {
  SimTeamsNameProvider,
  useSimTeamsName
};

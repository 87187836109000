import { areSelectableColorsEqual, convertToSelectableColor, SelectableColor } from "@internal/utils-color";
import { action, computed, makeObservable, observable } from "mobx";
import { ColorPalette } from "./types";

const DEFAULT_MAX_RECENT_COLORS = 7;

export const convertColorMapToSortedArray = (value: Map<string, number>): string[] => {
    return Array.from(value)
        .sort(([, colorACount], [, colorBCount]) => {
            return colorBCount - colorACount;
        })
        .map(([colorFamily]) => colorFamily);
};

/**
 * In practice, the RecentColorsManager is initialized with the top 7 (`maxColors`) used colors
 * in the active panel. Then, as a user selects different colors, that list is updated, pushing
 * the last selected color to the beginning of the list.
 */
export class RecentColorsManager {
    private maxColors: number;

    @observable
    private internalRecentColors: SelectableColor[];

    private initialized: boolean;

    private colorPalette: ColorPalette;

    private getColorsFromDesign: (() => Map<string, number>) | undefined;

    @observable
    lastSelectedColor: SelectableColor | undefined;

    constructor(
        getColorsFromDesign: () => Map<string, number> = () => new Map(),
        colorPalette: ColorPalette,
        maxColors = DEFAULT_MAX_RECENT_COLORS
    ) {
        this.internalRecentColors = [];
        this.maxColors = maxColors;
        this.getColorsFromDesign = getColorsFromDesign;
        this.initialized = false;
        this.colorPalette = colorPalette;
        makeObservable(this);
    }

    @computed
    get recentColors() {
        this.initialize();
        return this.internalRecentColors;
    }

    private initialize() {
        if (this.internalRecentColors.length === 0 && !this.initialized && this.getColorsFromDesign !== undefined) {
            this.internalRecentColors = convertColorMapToSortedArray(this.getColorsFromDesign())
                .filter(color => !color.includes("spot"))
                .slice(0, this.maxColors)
                .map(colorString =>
                    convertToSelectableColor(colorString, this.colorPalette.fromCdif(colorString)?.cssBackground)
                );
            this.initialized = true;
            this.getColorsFromDesign = undefined;
        }
    }

    @action.bound
    setLastSelectedColor(selectedColor: SelectableColor) {
        this.lastSelectedColor = selectedColor;
    }

    @action.bound
    resetLastSelectedColor() {
        this.lastSelectedColor = undefined;
    }

    @action.bound
    pushSelectedColor(selectedColor: SelectableColor) {
        this.initialize();

        this.internalRecentColors = [
            selectedColor,
            ...this.internalRecentColors.filter(
                existingColor => !areSelectableColorsEqual(existingColor, selectedColor)
            )
        ].slice(0, this.maxColors);
    }

    @action.bound
    pushColor(getColorsFromDesign: () => Map<string, number>) {
        this.initialize();

        if (this.internalRecentColors.length === 0) {
            this.initialized = false;
            this.getColorsFromDesign = getColorsFromDesign;
        }
    }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type DesignDialogsSeen } from "@internal/utils-custom-metadata";

const designDialogsSeenSlice = createSlice({
    name: "designDialogsSeen",
    initialState: {} as DesignDialogsSeen,
    reducers: {
        setDesignDialogsSeen(state, action: PayloadAction<DesignDialogsSeen>) {
            return { ...state, ...action.payload };
        }
    }
});

export const { setDesignDialogsSeen } = designDialogsSeenSlice.actions;
export const designDialogsSeen = designDialogsSeenSlice.reducer;

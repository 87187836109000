import type { BaseSim } from "../BaseSim";
import { DecorateWithSelectedZone } from "./types";

type DeferredLoaded = Omit<DecorateWithSelectedZone<BaseSim>, "deferred"> & {
    deferred: NonNullable<DecorateWithSelectedZone<BaseSim>["deferred"]>;
};

export const isSimWithSelectedZone = (sim: BaseSim): sim is DecorateWithSelectedZone<BaseSim> => {
    return "selectedZone" in sim.core;
};

export const isSimWithSelectedZoneAndDeferredLoaded = (sim: BaseSim): sim is DeferredLoaded => {
    return isSimWithSelectedZone(sim) && Boolean(sim.deferred);
};

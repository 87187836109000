import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useClickOutside } from "@internal/ui-studio-chrome";
import { Button } from "@vp/swan";
import { useEyeDropper } from "@design-stack-vista/core-features";
import { useLocalized } from "../localized";
import { Icon } from "@vp/diy-editor-ui";

export interface EyeDropperProps {
    onSelectColor: (color: string) => void;
}

/**
 * Responsible for providing an eye dropper
 */
export const EyeDropper = observer(({ onSelectColor }: EyeDropperProps) => {
    const { open } = useEyeDropper();
    const localized = useLocalized();
    const abortControllerRef = useRef<AbortController | null>(null);
    const ref = useRef<HTMLElement>(null);

    // Close eyeDropper if click occurs outside button but not on design
    useClickOutside(ref, () => {
        abortControllerRef?.current?.abort();
        abortControllerRef.current = null;
    });

    const handleOpen = async () => {
        try {
            abortControllerRef.current = new AbortController();
            const newColor = await open({ abortSignal: abortControllerRef.current.signal });
            onSelectColor(newColor);
        } catch {
            abortControllerRef?.current?.abort();
            abortControllerRef.current = null;
        }
    };

    return (
        <Button ref={ref} buttonShape="round" onClick={handleOpen} aria-label={localized.get("eyeDropperAriaLabel")}>
            <Icon iconType="eyeDropper" size="16p" />
        </Button>
    );
});
EyeDropper.displayName = "EyeDropper";

import { defineMessages } from "@vp/i18n-helper";
const patternMessage = defineMessages({
  closeButtonAccessibilityLabel: {
    id: "sims.pattern.contentDiscoveryZone.closeButtonAccessibilityLabel",
    defaultMessage: "Close",
    description: {
      note: "text for accessibility for the closes button"
    }
  },
  backButtonAccessibilityLabel: {
    id: "sims.pattern.contentDiscoveryZone.backButtonAccessibilityLabel",
    defaultMessage: "Back",
    description: {
      note: "text for accessibility for the back button"
    }
  },
  patternTitle: {
    id: "sims.pattern.contentDiscoveryZone.patternTitle",
    defaultMessage: "Patterns"
  },
  patternDescription: {
    id: "sims.pattern.contentDiscoveryZone.patternDescription",
    defaultMessage: "Apply patterned backgrounds to your entire product at once."
  },
  deletePattern: {
    id: "sims.pattern.contentDiscoveryZone.deletePattern",
    defaultMessage: "Delete Pattern"
  },
  toolbarThemeButtonLabel: {
    id: "sims.pattern.contentDiscoveryZone.toolbarThemeButtonLabel",
    defaultMessage: "Theme"
  },
  toolbarResizeButtonLabel: {
    id: "sims.pattern.contentDiscoveryZone.toolbarResizeButtonLabel",
    defaultMessage: "Resize Pattern"
  },
  allPattern: {
    id: "sims.pattern.contentDiscoveryZone.patternsList.allPattern",
    defaultMessage: "All patterns"
  },
  selectPattern: {
    id: "sims.pattern.contentDiscoveryZone.patternsList.selectPattern",
    defaultMessage: "Select a pattern"
  },
  patternColorsTitle: {
    id: "sims.pattern.contentDiscoveryZone.patternColorTitle",
    defaultMessage: "Pattern colors"
  },
  patternRecolorizationIconLabel: {
    id: "sims.pattern.selectedZoneToolbarConfig.patternRecolorizationIconLabel",
    defaultMessage: "Colors"
  },
  themePanelTitle: {
    id: "sims.pattern.contentDiscoveryZone.themePanelTitle",
    defaultMessage: "Themes",
    description: {
      note: "Title for pattern themes panel"
    }
  },
  themeButtonAriaLabel: {
    id: "sims.pattern.contentDiscoveryZone.themeButtonAriaLabel",
    defaultMessage: "Theme color group and [[colorNames]]",
    description: {
      note: "Aria label for themes color button of pattern"
    }
  },
  customizeButtonLabel: {
    id: "sims.pattern.contentDiscoveryZone.customizeButtonLabel",
    defaultMessage: "Customize",
    description: {
      note: "Customize button label in mobile view to open the edit panel"
    }
  },
  sheetEditPanelLabel: {
    id: "sims.pattern.contentDiscoveryZone.sheetEditPanelLabel",
    defaultMessage: "Edit Pattern",
    description: {
      note: "Mobile view edit panel title"
    }
  },
  themeTab: {
    id: "sims.pattern.contentDiscoveryZone.themeTab",
    defaultMessage: "Theme"
  },
  colorsTab: {
    id: "sims.pattern.contentDiscoveryZone.colorsTab",
    defaultMessage: "Colors"
  },
  resizeTab: {
    id: "sims.pattern.contentDiscoveryZone.resizeTab",
    defaultMessage: "Resize"
  },
  shuffleThemeColors: {
    id: "sims.pattern.contentDiscoveryZone.shuffleThemeColors",
    defaultMessage: "Shuffle theme colors"
  },
  resizePanelTitle: {
    id: "studio.features.pattern.resizePanelTitle",
    defaultMessage: "Resize Pattern",
    description: {
      note: "Title for Resize Pattern panel"
    }
  },
  scaleLabel: {
    id: "studio.features.pattern.resize.scaleLabel",
    defaultMessage: "Scale",
    description: {
      note: "Label to adjust scale pattern"
    }
  },
  rotationLabel: {
    id: "studio.features.pattern.resize.pattern",
    defaultMessage: "Rotate",
    description: {
      note: "Label to adjust Pattern rotation"
    }
  },
  horizontalPositionLabel: {
    id: "studio.features.pattern.resize.horizontalPositionLabel",
    defaultMessage: "Horizontal Position",
    description: {
      note: "Label to adjust horizontal position of Pattern"
    }
  },
  verticalPositionLabel: {
    id: "studio.features.pattern.resize.verticalPositionLabel",
    defaultMessage: "Vertical Position",
    description: {
      note: "Label to adjust vertical position of Pattern"
    }
  },
  patternTitleLabel: {
    id: "studio.features.pattern.contentDiscoveryZone.patternTitleLabel",
    defaultMessage: "Pattern",
    description: {
      note: "Label for the CDZ"
    }
  },
  patternPreviewCanvas: {
    id: "studio.features.pattern.ida.patternPreviewCanvas",
    defaultMessage: "Pattern preview canvas"
  },
  patternSheet: {
    id: "studio.features.pattern.ida.patternSheet",
    defaultMessage: "Pattern sheet"
  }
});
export {
  patternMessage
};

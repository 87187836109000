import { createSlice } from "@reduxjs/toolkit";
import { easelFinishedLoading } from "../compoundActions";

const easelLoadedSlice = createSlice({
    name: "easelLoaded",
    initialState: false,
    reducers: {
        easelIsLoading() {
            return false;
        }
    },
    extraReducers: builder => {
        builder.addCase(easelFinishedLoading, () => true);
    }
});

export const { easelIsLoading } = easelLoadedSlice.actions;
export const easelLoaded = easelLoadedSlice.reducer;

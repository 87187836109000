import type { FigureItem } from "@design-stack-vista/cdif-types";
import type { ItemState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import {
    PanelTextRecommendationExtension,
    useDesignEngine,
    useActivePanel,
    getOptionalExtension,
    useOptionalExtension
} from "@design-stack-vista/core-features";
import {
    ItemTemplateExtension,
    ItemType,
    type InteractiveDesignEngine
} from "@design-stack-vista/interactive-design-engine-core";
import { useColorPaletteForDecorationTechnology, type ColorPaletteType } from "@internal/feature-color-palette";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { useIsSingleColor } from "@internal/utils-deco-tech";
import { calculateLuminance, convertToRGBA } from "@mcp-artwork/color-converter";
import { useCallback } from "react";

interface GetLightestAndDarkestPaletteColorResult {
    darkest?: string;
    lightest?: string;
}

interface ColorLuminance {
    cdifValue: string;
    luminance: number;
}

const DEFAULT_FONT_COLOR = "rgb(#000000)";

// TODO(DDS-373): used when the product doesn't have color set in surface spec. Will be removed later
// This is temporarily used to achieve a compatible behavior with studio 5
const SINGLE_COLOR_DEFAULT_PANTONE_COLOR = "pantone(Yellow C)";

function getLightestAndDarkestPaletteColor(colorPalette: ColorPaletteType): GetLightestAndDarkestPaletteColorResult {
    const sortedColors = colorPalette.entries
        .reduce<ColorLuminance[]>((acc, { cdifValue }) => {
            const convertedColor = convertToRGBA(cdifValue);
            if (convertedColor) {
                acc.push({ cdifValue, luminance: calculateLuminance(convertedColor) });
            }
            return acc;
        }, [])
        .sort((a, b) => a.luminance - b.luminance);

    return {
        darkest: sortedColors?.shift()?.cdifValue,
        lightest: sortedColors?.pop()?.cdifValue
    };
}

function getOppositeColor(colorPalette: ColorPaletteType, color?: string) {
    const convertedColor = color && convertToRGBA(color);

    if (!convertedColor) {
        return undefined;
    }

    const luminance = calculateLuminance(convertedColor);
    const { lightest, darkest } = getLightestAndDarkestPaletteColor(colorPalette);

    return luminance < 0.5 ? lightest : darkest;
}

function getBackgroundColor(designEngine: InteractiveDesignEngine, activePanel: PanelState) {
    const backgroundItem = activePanel.items.find((item): item is ItemState<FigureItem> => {
        if (!item.isItemState() || !item.isShapeItem()) {
            return false;
        }

        const template = getOptionalExtension(designEngine, item, ItemTemplateExtension);
        return !!template?.isBackground;
    });

    return backgroundItem?.model?.color;
}

interface GetItemDefaultColorArgs {
    substrateColor?: string;
    itemType?: ItemType | ItemReferenceTypes;
}

export function useGetItemDefaultColor() {
    const designEngine = useDesignEngine();
    const isSingleColor = useIsSingleColor();
    const { activePanel } = useActivePanel();
    const targetPanel = activePanel ?? designEngine.layoutStore.visiblePanels[0];
    const decorationTechnology = targetPanel.panelProperties.decorationTechnology;
    const colorPalette = useColorPaletteForDecorationTechnology(decorationTechnology);

    const panelTextRecommendationComponent = useOptionalExtension(targetPanel, PanelTextRecommendationExtension);

    return useCallback(
        ({ substrateColor, itemType }: GetItemDefaultColorArgs = {}) => {
            if (
                (itemType === ItemType.TextArea || itemType === ItemReferenceTypes.TEAMS_NAME) &&
                panelTextRecommendationComponent?.defaultColor
            ) {
                return panelTextRecommendationComponent.defaultColor;
            }

            if (!colorPalette || colorPalette.entries.length === 0) {
                return DEFAULT_FONT_COLOR;
            }

            if (isSingleColor) {
                // For a single color product, we'll assume that the document is in a good state so the single color should be the only color in this array
                const [firstColor] = designEngine.colorRestrictionStore.currentColors[targetPanel.id];
                if (firstColor) {
                    return firstColor;
                }

                // We want Text to skip TextArea here so that it goes through the substrateColor logic
                // TODO(DDS-378): all items would be similar
                if (itemType !== ItemType.TextArea && itemType !== ItemReferenceTypes.TEAMS_NAME) {
                    const defaultPantoneColorPaletteEntry = colorPalette.entries.find(
                        entry => entry.cdifValue === SINGLE_COLOR_DEFAULT_PANTONE_COLOR
                    );

                    return defaultPantoneColorPaletteEntry?.cdifValue || colorPalette.entries[0].cdifValue;
                }
            }

            const backgroundColor = getBackgroundColor(designEngine, targetPanel) || substrateColor;
            const oppositeColor = getOppositeColor(colorPalette, backgroundColor);
            if (oppositeColor) {
                return oppositeColor;
            }

            const paletteColor = colorPalette.fromCdif(DEFAULT_FONT_COLOR);
            if (paletteColor) {
                return paletteColor.cdifValue;
            }

            if (colorPalette.entries.length) {
                return colorPalette.entries[0].cdifValue;
            }

            return DEFAULT_FONT_COLOR;
        },
        [targetPanel, colorPalette, designEngine, isSingleColor, panelTextRecommendationComponent]
    );
}

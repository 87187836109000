import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const switchingProjectSlice = createSlice({
    name: "switchingProject",
    initialState: false as boolean,
    reducers: {
        setSwitchingProject(state, action: PayloadAction<{ switching: boolean; loadingMessage?: string | null }>) {
            return action.payload.switching;
        }
    }
});

export const { setSwitchingProject } = switchingProjectSlice.actions;
export const switchingProject = switchingProjectSlice.reducer;

import { dialogTypes } from "../dialogTypes.js";
import "../shared/patternMessage.js";
import { isPatternTypeReference, PatternPanelMode } from "../shared/patternsTypeGuard.js";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/pattern-sdk";
import "@design-stack-vista/i18n-color-name";
import "@design-stack-ct/preview-sdk";
import "@design-stack-vista/cimdoc-state-manager";
import "../extensions/PatternItemExtension.js";
import { patternStore } from "../extensions/PatternStore.js";
import "../extensions/PatternExtension.js";
function openContentPanelOnItemSelect({
  firstSelectedItem,
  designEngine
}) {
  if (isPatternTypeReference(firstSelectedItem)) {
    patternStore.setPatternPanelMode(PatternPanelMode.Edit);
    return dialogTypes.pattern;
  }
  return void 0;
}
export {
  openContentPanelOnItemSelect
};

import type { StudioDesignExperienceExtension } from "@internal/utils-studio-configuration-core";

const shouldShowDigitalExperience: StudioDesignExperienceExtension = {
    shouldShowDigitalExperience: [
        {
            conditions: {
                value: true
            },
            overrides: {
                disableViewControls: true
            }
        }
    ]
};

export default shouldShowDigitalExperience;

import { observer } from "mobx-react-lite";
import React, { createContext, useState, useEffect } from "react";
import type { ScopedSimName } from "@internal/utils-sim-types";
import { SimsManager } from "./simsManager";
import type { SimInterface } from "./ComposedSim";
import type { StudioConfigurationManager } from "@internal/utils-studio-configuration-core";

export const SimsContext = createContext<SimsManager | undefined>(undefined);

type Overrides = Record<ScopedSimName, boolean>;

type Props = Overrides & {
    sims: Set<SimInterface>;
    studioConfigurationManager: StudioConfigurationManager;
};

// add locale here OR move to a separate Context specific for Locale
export const SimsProvider = observer((props: React.PropsWithChildren<Props>) => {
    const { sims, children, studioConfigurationManager, ...overrides } = props;
    /**
     * Initialize the SimsManager with the totality of SIMs. However, the constructor
     * will default treat all sims as inactive
     */
    const [simsManager] = useState<SimsManager>(() => new SimsManager(sims, studioConfigurationManager));

    // Handle activating and deactivating each SIM
    useEffect(() => {
        sims.forEach(sim => {
            const isActive = overrides[sim.name] ?? true;
            simsManager.setActivation(sim.name, isActive);
        });
    }, [sims, simsManager, overrides]);

    return <SimsContext.Provider value={simsManager}>{children}</SimsContext.Provider>;
});

import React from "react";
import classNames from "classnames";
import { Typography, Divider } from "@vp/swan";
import { ColorSwatch } from "./ColorSwatch";
import styles from "./ColorPalette.module.scss";
import { useLocalized } from "../localized";
import {
    ColorSpace,
    colorToString,
    convertToRgb,
    convertToSelectableColor,
    parseColor,
    SelectableColor
} from "@internal/utils-color";

interface Props {
    locale: string;
    /** Selected color */
    selectedColor?: string;
    /** List of recommended colors that are selectable */
    recentColors?: SelectableColor[];
    /** List of standard colors that are selectable */
    paletteColors: SelectableColor[];
    /** Function called when a color has been selected */
    onSelectColor(color: any, colorSpace: ColorSpace): void;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
    descriptionTitle?: string;
}

export function ColorPalette({
    selectedColor = "",
    onSelectColor,
    recentColors = [],
    paletteColors,
    trackUsage,
    descriptionTitle,
    locale
}: Props) {
    const localized = useLocalized();

    const toggleColor = (color: SelectableColor) => {
        const parsedColor = parseColor(color.value);
        if (parsedColor) {
            onSelectColor(parsedColor.value, parsedColor.colorSpace);
            if (trackUsage) {
                trackUsage("ColorPalette", color.toString());
            }
        }
    };

    const showRecentColors = recentColors.length > 0;

    return (
        <>
            {showRecentColors && (
                <>
                    <Typography fontSize="xsmall" textColor="subtle" fontWeight="bold" className={styles.titleOffset}>
                        {localized.get("recentColors")}
                    </Typography>
                    <div className={styles.colorsPaletteWrapper}>
                        {recentColors.map(color => (
                            <ColorSwatch
                                key={color.value}
                                color={color}
                                isSelected={
                                    color.value === selectedColor ||
                                    color.value === colorToString(convertToRgb(selectedColor)) ||
                                    color.cssBackground === convertToSelectableColor(selectedColor).cssBackground
                                }
                                toggleColor={toggleColor}
                                locale={locale}
                            />
                        ))}
                    </div>
                    <Divider className={styles.paletteDivider} />
                </>
            )}
            {descriptionTitle && (
                <Typography
                    fontSize="xsmall"
                    textColor="subtle"
                    fontWeight="bold"
                    className={classNames(styles.titleOffset, {
                        [styles.titleOffsetAdded]: showRecentColors
                    })}
                >
                    {descriptionTitle}
                </Typography>
            )}
            <div
                className={classNames(styles.colorsPaletteWrapper, {
                    // switch the flow to columns only if the color palette is completely full
                    // to order the standard colors correctly and avoid weird wrapping when it is not full
                    [styles.paletteColorsColumns]: paletteColors.length === 28
                })}
            >
                {paletteColors.map(color => (
                    <ColorSwatch
                        key={color.value}
                        color={color}
                        isSelected={
                            color.value === selectedColor ||
                            color.value === colorToString(convertToRgb(selectedColor)) ||
                            color.cssBackground === convertToSelectableColor(selectedColor).cssBackground
                        }
                        toggleColor={toggleColor}
                        locale={locale}
                    />
                ))}
            </div>
        </>
    );
}

ColorPalette.displayName = "ColorPalette";

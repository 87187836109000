import React, { ReactNode, useContext, useMemo } from "react";
import { DSS } from "@vp/types-ddif";

interface AITemplatesContext {
    productKey: string;
    productVersion: number;
    selectedOptions: Record<string, string>;
    updateProductWithNewDocument: (
        targetDocument: DSS.DesignDocument,
        activeDesignPanelId: string | undefined
    ) => Promise<void>;
    setAITemplateUpdating: (aiTemplateUpdating: boolean) => void;
    isDesignAssistantTabEnabled: boolean;
    substrateColor: string;
    designAssistantTemplatesSearchParams?: string;
}

interface AITemplatesProviderProps {
    children: ReactNode | ReactNode[];
    productKey: string;
    productVersion: number;
    selectedOptions: Record<string, string>;
    updateProductWithNewDocument: (
        targetDocument: DSS.DesignDocument,
        activeDesignPanelId: string | undefined // used for persisting current active panel
    ) => Promise<void>;
    setAITemplateUpdating: (aiTemplateUpdating: boolean) => void;
    isDesignAssistantTabEnabled: boolean;
    substrateColor: string;
    designAssistantTemplatesSearchParams?: string;
}

const aiTemplatesContext = React.createContext<AITemplatesContext | undefined>(undefined);

export function useAITemplatesContext() {
    const result = useContext(aiTemplatesContext);

    if (result === undefined) {
        throw new Error("useAITemplatesContext must be used within a AITemplatesProvider");
    }
    return result;
}

export function AITemplatesProvider({
    children,
    productKey,
    productVersion,
    selectedOptions,
    updateProductWithNewDocument,
    setAITemplateUpdating,
    isDesignAssistantTabEnabled,
    substrateColor,
    designAssistantTemplatesSearchParams
}: AITemplatesProviderProps) {
    const contextObject = useMemo(() => {
        return {
            productKey,
            productVersion,
            selectedOptions,
            updateProductWithNewDocument,
            setAITemplateUpdating,
            isDesignAssistantTabEnabled,
            substrateColor,
            designAssistantTemplatesSearchParams
        };
    }, [
        productKey,
        productVersion,
        selectedOptions,
        updateProductWithNewDocument,
        setAITemplateUpdating,
        isDesignAssistantTabEnabled,
        substrateColor,
        designAssistantTemplatesSearchParams
    ]);

    return <aiTemplatesContext.Provider value={contextObject}>{children}</aiTemplatesContext.Provider>;
}

AITemplatesProvider.displayName = "AITemplatesProvider";

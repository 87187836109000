import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChangeDesignData = {
    selectedOption: string;
};

const changeDesignDataSlice = createSlice({
    name: "changeDesignData",
    initialState: null as ChangeDesignData | null,
    reducers: {
        setChangeDesignData: (state, action: PayloadAction<ChangeDesignData | null>) => action.payload
    }
});

export const { setChangeDesignData } = changeDesignDataSlice.actions;
export const changeDesignData = changeDesignDataSlice.reducer;

import { useIsQuantityPageEnabled } from "@shared/features/StudioBootstrap";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { isObject } from "@design-stack-vista/utility-core";

/**
 * We need this check as quantityPerSize might be undefined / number / object like "{"S":10,"M":2}"
 *
 * @param quantityPerSize
 * @returns boolean
 */
function isValidObject(quantityPerSize: string | undefined) {
    if (!quantityPerSize) {
        return false;
    }
    try {
        return isObject(JSON.parse(quantityPerSize));
    } catch (e) {
        return false;
    }
}

export const useMultiSizeProduct = (): { isMultiSizeProduct: boolean } => {
    const isQuantityPageEnabled = useIsQuantityPageEnabled();
    const { quantityPerSize } = useProductAndProjectStateManager().data;

    return {
        isMultiSizeProduct: !!isQuantityPageEnabled || isValidObject(quantityPerSize)
    };
};

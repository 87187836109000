import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const confirmImageDeletionSlice = createSlice({
    name: "confirmImageDeletion",
    initialState: false,
    reducers: {
        setConfirmImageDeletion: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setConfirmImageDeletion } = confirmImageDeletionSlice.actions;
export const confirmImageDeletion = confirmImageDeletionSlice.reducer;

import { getQueryParams } from "@internal/utils-browser";
import merge from "lodash/merge";
import { isFlagEnabled } from "@internal/utils-flags";
import type {
    DesignExperienceManagementAndProductGroupConfigStates,
    DesignExperienceManagementState
} from "./DesignExperienceManagerTypes";
import type { StudioDesignExperienceExtension } from "./designExperienceExtension/DesignExperienceExtensionTypes";

export function applyDeveloperOverrides<T extends DesignExperienceManagementState>(
    studioConfiguration: T,
    extensions: Set<StudioDesignExperienceExtension>
): T {
    const overridesThroughExtensionFlags = getExperienceOverridesThroughFeatureFlags(extensions);
    const overridesThroughQSP = getQueryParams().configurationOverrides;

    let configOverride: Partial<DesignExperienceManagementState> = {};

    /**
     * JSON.parse of undefined will throw an error.
     * We want to catch this and set the overrides though flags
     */
    try {
        configOverride = merge({}, JSON.parse(overridesThroughQSP), overridesThroughExtensionFlags);
    } catch (error) {
        configOverride = overridesThroughExtensionFlags;
    }

    try {
        return merge<object, T, Partial<DesignExperienceManagementState>>({}, studioConfiguration, configOverride);
    } catch (error) {
        // this is a developer tool.  No reason to fail.  Return redux configuration
        return studioConfiguration;
    }
}

function getExperienceOverridesThroughFeatureFlags(extensions: Set<StudioDesignExperienceExtension>) {
    const override: Partial<DesignExperienceManagementAndProductGroupConfigStates> = {};
    for (const extension of extensions) {
        for (const matchKey of Object.keys(extension)) {
            const key = matchKey as keyof DesignExperienceManagementAndProductGroupConfigStates;
            const configs = extension[key];

            configs?.forEach(config => {
                if (config.conditions.featureFlags && config.conditions.featureFlags.length) {
                    const allFlagsEnabled = config.conditions.featureFlags.every(isFlagEnabled);
                    if (allFlagsEnabled) {
                        (override[key] as unknown) = allFlagsEnabled;
                    }
                }
            });
        }
    }
    return override;
}

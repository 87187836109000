import React from "react";
import classnames from "classnames";
import styles from "./ColorSwatchHandle.module.scss";

interface ColorSwatchHandleProps {
    /** Inline styles passed to the handle */
    handleStyle?: { [key: string]: string };
    /** Inline styles passed to the handle container */
    handleContainerStyle?: React.CSSProperties;
    /** class name for the container */
    containerClassName?: string;
}

export function ColorSwatchHandle({ handleStyle, handleContainerStyle, containerClassName }: ColorSwatchHandleProps) {
    return (
        <div
            style={{ ...handleContainerStyle }}
            className={classnames(styles.easelSliderHandleBase, "easel-slider-handle-base", containerClassName)}
        >
            <div
                style={{ ...handleStyle }}
                className={classnames(styles.easelSliderHandle, "easel-slider-handle")}
                data-testid="slider__handle"
            ></div>
        </div>
    );
}
ColorSwatchHandle.displayName = "ColorSwatchHandle";

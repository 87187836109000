export const OUTER_CUTLINE_GAP_MM = 1.59;
export const INNER_CUTLINE_STROKE_COLOR = "spot(KissCutting, cmyk(50,50,50,0))";
export const OUTER_CUTLINE_STROKE_COLOR = "color(255,0,255,0,100,0,0,1,,Cutting,true,true)";
export const OUTER_CUTLINE_OVERPRINT = "spot(Cutting, cmyka(0, 100, 0, 0, 0))";
export const CUTLINE_THICKNESS = "0.1mm";
export const CUTLINE_ERROR_TOLERANCE = 0.002;

export const CUTLINE_SHAPE_ID_SUBSTRING = "-cutline-";
export const OUTER_CUTLINE_SHAPE_ID = `outer-cutline`;
export const BACKGROUND_COLOR_SHAPE_ID = "background-color-shape";
export const DESIGN_SUBPANEL_ID = "design-subpanel";
export const CUTLINE_SUBPANEL_ID = "cut-line-subpanel";

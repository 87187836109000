import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
    show: boolean;
    startTime?: number;
};

const showDesignReviewSlice = createSlice({
    name: "showDesignReview",
    initialState: { show: false } as StateType,
    reducers: {
        showDesignReview(state, action: PayloadAction<StateType>) {
            return action.payload;
        }
    }
});

export const { showDesignReview } = showDesignReviewSlice.actions;
export const designReviewState = showDesignReviewSlice.reducer;

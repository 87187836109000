import { type NewrelicError } from "./errors";
import { NewRelicWrapper, getNewrelicAgent } from "./newRelicWrapper";
import { Newrelic, SimpleType } from "./types";

/**
 * Please refer to the README for usage instructions.
 *
 * A wrapper around the NewRelic agent that automatically injects the scope into all events.
 */
export class ScopedNewrelicWrapper extends NewRelicWrapper {
    private scope: string;

    constructor(scope: string, agent: Newrelic) {
        super(agent);

        this.scope = scope;
    }

    addPageAction(name: string, attributes?: Record<string, SimpleType> | undefined): void {
        super.addPageAction(name, { ...attributes, scope: this.scope });
    }

    noticeError(error: NewrelicError, customAttributes?: Record<string, SimpleType> | undefined): void {
        error.scope = this.scope;
        super.noticeError(error, { ...customAttributes, scope: this.scope });
    }

    newTimingEvent(eventName: string, includedMetadata?: Record<string, any>) {
        return super.newTimingEvent(eventName, { ...includedMetadata, scope: this.scope });
    }
}

/**
 * Please refer to the README for usage instructions.
 *
 * Obtain a new ScopedNewrelicWrapper instance bound to the specified scope.
 * @param scope - The scope for the newrelic wrapper. This scope must be registered in the playbook.
 */
export const getScopedNewrelicWrapper = (scope: string) => new ScopedNewrelicWrapper(scope, getNewrelicAgent());

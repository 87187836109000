import type { DSS } from "@vp/types-ddif";
import type { Item, Panel, RectangleItem } from "@design-stack-vista/cdif-types";
import { isRectangleItem } from "@design-stack-vista/cimdoc-state-manager";
import { Measurement } from "@design-stack-vista/utility-core";

function getItemsSortedByZOrder(panel: Panel): Item[] {
    const itemsSortedByZOrder: Item[] = [];
    if (panel.images) {
        itemsSortedByZOrder.push(...panel.images);
    }
    if (panel.itemReferences) {
        itemsSortedByZOrder.push(...panel.itemReferences);
    }
    if (panel.textAreas) {
        itemsSortedByZOrder.push(...panel.textAreas);
    }
    if (panel.shapes) {
        itemsSortedByZOrder.push(...panel.shapes);
    }
    itemsSortedByZOrder.sort((a, b) => a.zIndex - b.zIndex);
    return itemsSortedByZOrder;
}

const FUZZ_FACTOR = 0.01;

// Check if the shape is full bleed.  We use a fuzz factor because close is good enough for us and horseshoes
// For each positional data we're fine if the shape extends wayyyyy beyond the bleed.  It doesn't need to kiss it.
function isFullBleed(item: RectangleItem, panel: Panel) {
    return (
        new Measurement(item.position.x).mm < FUZZ_FACTOR &&
        new Measurement(item.position.y).mm < FUZZ_FACTOR &&
        new Measurement(item.position.height).mm - new Measurement(panel.height).mm > -FUZZ_FACTOR &&
        new Measurement(item.position.width).mm - new Measurement(panel.width).mm > -FUZZ_FACTOR
    );
}

// Templates have full-bleed shapes at the lowest z-index that are not configured as backgrounds
// That can be confusing in studio where we do have backgrounds
// Update the shape so that we treat it as a background
export function fixBackgroundFromTemplate(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        // Only compare template items
        // When changing template, the items already on the canvas end up behind the background shape
        const itemsSortedByZOrder = getItemsSortedByZOrder(panel).filter(item => {
            const templateMetadataForItem = cimDoc.metadata?.template?.find(
                templateMetadata => templateMetadata.id === item.id
            );
            // We only want to run this for actual template items
            // Converting a shape that a user dragged to cover the background might be too confusing for them
            const isOriginalTemplateItem = templateMetadataForItem?.originalTemplateElementId;
            // If the element is hard locked than we can include those too.  The user didn't add them and cannot edit them.
            const isHardLocked =
                templateMetadataForItem?.locks &&
                "edit" in templateMetadataForItem.locks &&
                templateMetadataForItem.locks.edit;
            return isOriginalTemplateItem || isHardLocked;
        });
        if (itemsSortedByZOrder.length > 0) {
            const lowestItem = itemsSortedByZOrder[0];
            if (isRectangleItem(lowestItem) && isFullBleed(lowestItem, panel)) {
                const templateMetadataForItem = cimDoc.metadata?.template?.find(
                    templateMetadata => templateMetadata.id === lowestItem.id
                );

                if (templateMetadataForItem) {
                    // the z-index for background items
                    lowestItem.zIndex = -1001;
                    // @ts-expect-error background isn't defined our template metadata
                    templateMetadataForItem.background = true;
                }
            }
        }
    });
}

import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    closeToastButton: string;
    closeErrorButton: string;
    toastErrorMessage: string;
    contactUs: string;
    toastErrorCode: string;
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    closeToastButton: {
        id: "studio.components.Toast.closeToastButton",
        defaultMessage: "Close alert message",
        description: "Accessibility label for the close button on the error toast"
    },
    toastErrorMessage: {
        id: "studio.components.Toast.toastErrorMessage",
        defaultMessage: "Sorry - something went wrong. Please refresh your browser. If you need assistance, ",
        description: ""
    },
    toastErrorCode: {
        id: "studio.components.Toast.toastErrorCode",
        defaultMessage: "Error Code: [[errorCode]]"
    },
    contactUs: {
        id: "studio.components.Toast.contactUs",
        defaultMessage: "give us a call",
        description:
            "The 'g' in give us a call is intentionally lowercase. This bit of text is meant to be a link that will be added inside other sentences."
    },
    closeErrorButton: {
        id: "studio.components.Toast.closeErrorButton",
        defaultMessage: "Close error message",
        description: "Accessibility label for the close button on the error toast"
    }
};

import { v4 as uuidv4 } from "uuid";
import { windowExists } from "@internal/utils-browser";

declare global {
    interface Window {
        tracking: any;
    }
}

export function getUserSessionId(): string {
    // Assuming tracking will be enable 100% on all studio pages
    return (
        (windowExists() && window.tracking && window.tracking.visit && window.tracking.visit()) || "tracking-not-setup"
    );
}

const studioUniqueSession = uuidv4();
export function getStudioUniqueSessionId() {
    return studioUniqueSession;
}

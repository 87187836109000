import { InitialStudioConfigurationState, StudioConfigurationState } from "@internal/utils-studio-configuration-core";
import z from "zod";

/**
 * These overrides apply globally to all modes.  These features VCS never wants to show at all.
 */
export const GLOBAL_VCS_CONFIG_MODIFICATIONS: Partial<InitialStudioConfigurationState> = {
    showChangeTemplate: false,
    showChangeSize: false,
    isCompatibleForMailingService: false,
    showDesignPanel: false,
    disableDesignReview: true,
    shouldAllowUserSave: false,
    shouldAllowRenameDesign: false,
    shouldAllowDuplicateDesign: false,
    shouldAllowMyProjects: false
};

export const VCS_INITIAL_RESTRICTED_EXPERIENCE_CONFIG: Partial<InitialStudioConfigurationState> = {
    shouldShowBackgroundColor: false,
    shouldShowPatterns: false,
    disableFileBrowserDragNDrop: true,
    shouldAllowToChangeFinish: false,
    shouldShowText: false,
    shouldShowImages: false,
    shouldShowGraphics: false,
    shouldShowQRCodes: false,
    shouldShowCutline: false,
    shouldShowTables: false,
    hasAiTemplatesEnabled: false
};

export const VCS_UNRESTRICTED_EXPERIENCE_OVERRIDE_CONFIG: Partial<InitialStudioConfigurationState> = {
    shouldShowBackgroundColor: true,
    shouldShowPatterns: true,
    disableFileBrowserDragNDrop: false,
    shouldAllowToChangeFinish: true,
    shouldShowText: true,
    shouldShowImages: true,
    shouldShowGraphics: true,
    shouldShowQRCodes: true,
    shouldShowCutline: true,
    shouldShowTables: true,
    hasAiTemplatesEnabled: true
};

export const VCS_ADMIN_EXPERIENCE_OVERRIDE_CONFIG: Partial<InitialStudioConfigurationState> = {
    shouldShowBackgroundColor: true,
    shouldShowPatterns: true,
    disableFileBrowserDragNDrop: false,
    shouldAllowToChangeFinish: true,
    shouldShowText: true,
    shouldShowImages: true,
    shouldShowGraphics: true,
    shouldShowQRCodes: true,
    shouldShowCutline: true,
    shouldShowTables: true,
    hasAiTemplatesEnabled: true
};

export const VCSUrlSchema = z.object({
    mode: z.enum(["admin", "shopper"]),
    brandedProductId: z.union([z.string(), z.null(), z.undefined()]),
    storeId: z.string()
});

export interface CustomVCSData {
    url: z.infer<typeof VCSUrlSchema>;
}

export function applyVCSModeOverrides(
    baseConfig: StudioConfigurationState,
    customConfig?: CustomVCSData
): StudioConfigurationState {
    let vcsModeOverrides: Partial<StudioConfigurationState> = {
        ...GLOBAL_VCS_CONFIG_MODIFICATIONS,
        ...VCS_INITIAL_RESTRICTED_EXPERIENCE_CONFIG
    };

    /**
     * In addition to the global overrides VCS also wishes to disable features based on the current editor mode
     * and whether this came from a template (branded product)
     */
    if (customConfig?.url.mode === "shopper" && !customConfig?.url.brandedProductId) {
        vcsModeOverrides = {
            ...vcsModeOverrides,
            ...VCS_UNRESTRICTED_EXPERIENCE_OVERRIDE_CONFIG
        };
    } else if (customConfig?.url.mode === "admin") {
        vcsModeOverrides = {
            ...vcsModeOverrides,
            ...VCS_ADMIN_EXPERIENCE_OVERRIDE_CONFIG
        };
    }
    return { ...baseConfig, ...vcsModeOverrides };
}

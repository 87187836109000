import { SimInterface } from "@internal/sim-framework";
import { EditShapeDialogType } from "../../../shapeEditing/components/ShapeEditingDialogTypes";

export const legacyShapeSim: SimInterface<"SelectedZone"> = {
    name: "@internal/advanced-editor-sim-shape-legacy",
    core: {
        extensions: [],
        selectedZone: {
            detailZoneIds: new Set([
                { type: "ColorPicker", activeDialogType: EditShapeDialogType.FillColorPicker },
                { type: "ColorPicker", activeDialogType: EditShapeDialogType.StrokeColorPicker }
            ])
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { getQueryParams } from "../queryString";
import { windowExists } from "../windowExists";

export function isCareAgent() {
    if (!windowExists()) {
        return false;
    }
    const urlParams = getQueryParams();

    // If query params say false remove local storage value to have same state
    if (urlParams.careAgent === "false") {
        window.localStorage.removeItem("vp-care-agent");
    }

    return urlParams.careAgent === "true" || window.localStorage.getItem("vp-care-agent") === "true";
}

// Purpose: If care agents are creating new documents and not assigning it to any owner but rather them self,
// we want to make sure that we don't add those documents to cart by default
export function isCareAgentEditingCareAgentDocument() {
    const urlParams = getQueryParams();
    const { owner } = urlParams;

    return isCareAgent() && !owner;
}

import { PanelChromesExtension } from "@design-stack-vista/core-features";
import { QRCodesContentDiscoveryButton } from "./contentDiscoveryZone/QRCodesContentDiscoveryButton.js";
import "react/jsx-runtime";
import "mobx-react-lite";
import "react";
import "@vp/i18n-helper";
import "@vp/swan";
import "@internal/feature-responsive-design";
import "@internal/utils-tracking";
import "./features/addEditUrlTab/components/QRCodeURLInput.js";
import "./shared/assets.js";
import "@design-stack-vista/cimdoc-state-manager";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/identity-provider";
import "@internal/sim-framework";
import "@internal/utils-custom-metadata";
import "./shared/clients/apiConfig.js";
import "@internal/utils-color";
import "./shared/utils/validation.js";
import "@design-stack-vista/interactive-design-engine-core";
import "./shared/utils/upgradeLegacyQRCode.js";
import "@internal/utils-newrelic";
import "./shared/clients/fetchQRMetadata.js";
import "./extensions/qrCodeStore.js";
import { QRCodeExtension } from "./extensions/qrCodeExtension.js";
import "classnames";
import "./_baseGetTag-C0F3avit.js";
import "@internal/feature-color-picker";
import "@internal/feature-recent-colors";
import "@internal/utils-item-color";
import "@design-stack-vista/vista-assets-sdk";
import "@design-stack-vista/upload-components";
import "@vp/diy-editor-ui";
import "mobx";
import "@internal/ui-studio-chrome";
import "./features/imageTab/components/DeleteImageModal.js";
import "@internal/utils-active-dialog";
import { dialogTypes } from "./dialogTypes.js";
import { openContentPanelOnItemSelect } from "./contentDiscoveryZone/openContentPanelOnItemSelect.js";
import { name } from "./name.js";
import { QRCodePluginPreloaderPanelExtension } from "./extensions/QRCodePluginPreloaderPanelExtension.js";
const defaultTranslations = {
  "easel.components.qrCodes.qrCodePanel.title": "QR Code",
  "easel.components.qrCodes.qrCodeSheet.title": "QR Code",
  "easel.components.qrCodes.qrCodeSheet.editQrCodeSheetTitle": "Edit QR Code",
  "easel.components.qrCodes.qrCodePanel.buttonTitle": "Add QR Code",
  "easel.components.qrCodes.qrCodePanel.addQRInstructions": "Enter a valid URL and click the ‘add’ button.",
  "easel.components.qrCodes.qrCodePanel.editQRInstructions": "Update the link for your QR code below.",
  "easel.components.qrCodes.qrCodeForm.url": "URL",
  "easel.components.qrCodes.qrCodeForm.example": "https://www.example.com/",
  "easel.components.qrCodes.qrCodeForm.exampleForQrCode": "QR code URL",
  "easel.components.qrCodes.qrCodeForm.required": "(Required)",
  "easel.components.qrCodes.qrCodeDialog.optional": "(Optional)",
  "easel.components.qrCodes.qrCodeDialog.invalidURL": "Invalid URL",
  "easel.components.qrCodes.qrCodeDialog.wrongURL": "Potentially wrong URL",
  "easel.components.qrCodes.qrCodeEditButton.text": "Edit",
  "easel.components.qrCodes.qrCodeCancelButton.text": "Cancel",
  "easel.components.qrCodes.qrCodeDoneButton.text": "Done",
  "easel.components.qrCodes.qrCodeEditor.header": "Edit your QR Code:",
  "easel.components.qrCodes.qrCodeEditor.dialogAriaLabel": "Edit your QR Code dialog",
  "easel.components.qrCodes.qrCodeEditor.ariaCloseButton": "Close QR Code Edit dialog",
  "easel.components.qrCodes.qrCodePanel.loading": "Loading ...",
  "easel.components.qrCodes.qrCodePanel.shapeTab.corners": "Corners",
  "easel.components.qrCodes.qrCodePanel.shapeTab.square": "Square",
  "easel.components.qrCodes.qrCodePanel.shapeTab.curved": "Curved",
  "easel.components.qrCodes.qrCodePanel.shapeTab.leftLeaf": "Left leaf",
  "easel.components.qrCodes.qrCodePanel.shapeTab.rightLeaf": "Right Leaf",
  "easel.components.qrCodes.qrCodePanel.shapeTab.classyBlocks": "Classy blocks",
  "easel.components.qrCodes.qrCodePanel.shapeTab.dots": "Dots",
  "easel.components.qrCodes.qrCodePanel.shapeTab.connectHorizontal": "Connect horizontal",
  "easel.components.qrCodes.customizationPanel.urlTab": "URL",
  "easel.components.qrCodes.customizationPanel.shapeTab": "Shape",
  "easel.components.qrCodes.customizationPanel.colorTab": "Color",
  "easel.components.qrCodes.customizationPanel.extraImageTab": "Extra Images",
  "easel.components.qrCodes.customizationPanel.extraImageTab.none": "None",
  "easel.components.qrCodes.customizationPanel.extraImageTab.scanMe": "Scan me",
  "easel.components.qrCodes.customizationPanel.extraImageTab.instagram": "Instagram",
  "easel.components.qrCodes.customizationPanel.extraImageTab.facebook": "Facebook",
  "easel.components.qrCodes.customizationPanel.extraImageTab.linkedIn": "LinkedIn",
  "easel.components.qrCodes.customizationPanel.extraImageTab.twitter": "Twitter",
  "easel.components.qrCodes.customizationPanel.extraImageTab.review": "Review",
  "easel.components.qrCodes.customizationPanel.extraImageTab.youTube": "YouTube",
  "easel.components.qrCodes.customizationPanel.extraImageTab.google": "Google",
  "easel.components.qrCodes.customizationPanel.extraImageTab.watch": "Watch",
  "easel.components.qrCodes.customizationPanel.extraImageTab.imageDeleteBtnAriaLabel": "Delete image",
  "easel.components.qrCodes.customizationPanel.extraImageTab.imagesContentLegend": "Select image or icon to add on a QR code",
  "easel.components.qrCodes.customizationPanel.updateButtonLabel": "Update",
  "easel.components.qrCodes.customizationPanel.addMoreQRCodeButtonLabel": "Add Another QR Code",
  "easel.components.qrCodes.customizationPanel.toastMessage": "URL updated",
  "easel.components.qrCodes.customizationPanel.updateErrorMessage": "Update failed. Please try again.",
  "sims.qrCode.contentDiscoveryZone.closeButtonAccessibilityLabel": "Close",
  "sims.qrCode.contentDiscoveryZone.backButtonAccessibilityLabel": "Back",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploadOrSelectImage": "Accepted formats",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploadConditions": "PNG, JPEG, or SVG. Files must be under 7MB",
  "easel.components.qrCodes.customizationPanel.extraImageTab.selectionExtraImage": "Selection: ",
  "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadLoading": "loading",
  "easel.components.qrCodes.customizationPanel.extraImageTab.largeImageError": "Upload failed. Image must be under 7MB.",
  "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadFailed": "Upload failed. Please try again.",
  "easel.components.qrCodes.customizationPanel.extraImageTab.invalidExtraImageExtension": "Oops! Looks like you uploaded a file that we do not support. You can upload files with following extensions:",
  "easel.components.qrCodes.customizationPanel.extraImageTab.imageUploadButtonText": " Upload logo or image",
  "sims.qrCode.colorEditor.invertButtonAccessibilityLabel": "Invert",
  "sims.qrCode.colorEditor.invertButtonAccessibilityLabel.on": "On",
  "sims.qrCode.colorEditor.invertButtonAccessibilityLabel.off": "Invert",
  "sims.qrCode.colorEditor.changeImageColorButtonAccessibilityLabel": "Change",
  "sims.qrCode.colorEditor.changeImageColorHeadlineAccessibilityLabel": "Extra Image",
  "sims.qrCode.colorEditor.sameAsQrCodeButtonAccessibilityLabel": "Same as QR code",
  "sims.qrCode.colorEditor.extraImageHeadlineAccessibilityLabel": "Extra Image",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.closeButton": "Close Delete Image Dialog",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.modalDialogTitle": "Delete the image?",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.modalDialogBody": "Are you sure you want to delete this image?",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.cancelButton": "Cancel",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.confirmButton": "Delete",
  "easel.components.qrCodes.customizationPanel.extraImageTab.uploads.deleteImageDialog.extraImageModalAriaLabel": "Delete the qr code panel extra image dialog",
  "sims.qrCode.qrCodeSmallScreenSheet": "QR Code Small Screen Sheet",
  "sims.qrCode.selectedZoneToolbar.toolbarColorButtonLabel": "Color",
  "sims.qrCode.selectedZoneToolbar.toolbarEditButtonLabel": "Edit"
};
const QR_CODE_SIM = {
  name,
  deferredLoader: () => import("./index.deferred.js"),
  core: {
    defaultTranslations,
    extensions: [PanelChromesExtension, QRCodePluginPreloaderPanelExtension, QRCodeExtension],
    contentDiscoveryZone: {
      QRCodes: {
        activeDialogType: dialogTypes.qrcode,
        component: QRCodesContentDiscoveryButton
      }
    },
    openContentPanelAfterItemSelectionCallback: openContentPanelOnItemSelect,
    selectedZone: {
      detailZoneIds: /* @__PURE__ */ new Set([{
        type: "ColorPicker",
        activeDialogType: dialogTypes.qrcodeColor
      }, {
        type: "General",
        activeDialogType: dialogTypes.qrcodeEdit
      }])
    }
  }
};
export {
  QR_CODE_SIM as default
};

import type { SimInterface } from "@internal/sim-framework";
import type { ScopedSimName } from "@internal/utils-sim-types";
import cutlineGenerationSim from "@internal/advanced-editor-sim-cutline-generation";
import patternSim from "@internal/advanced-editor-sim-pattern";
import qrCodeSim from "@internal/advanced-editor-sim-qrcode";
import vectorSim from "@internal/advanced-editor-sim-vector";
import teamsNameSim from "@internal/advanced-editor-sim-teams-name";
import prototypeSim from "@internal/advanced-editor-sim-prototype";
import textSim from "@internal/advanced-editor-sim-text";
import imageSim from "@internal/advanced-editor-sim-image";
import type { DexConfiguration } from "@internal/dex-framework";

export const sims: Set<SimInterface | ScopedSimName> = new Set([
    "@internal/advanced-editor-sim-image-legacy",
    "@internal/advanced-editor-sim-table-legacy",
    "@internal/advanced-editor-sim-background-legacy",
    "@internal/advanced-editor-sim-icon-legacy",
    "@internal/advanced-editor-sim-shape-legacy",
    // exporting the text sim's name since the module isn't a fully fledged sim yet
    textSim.name,
    cutlineGenerationSim,
    patternSim,
    qrCodeSim,
    vectorSim,
    teamsNameSim,
    prototypeSim,
    imageSim
]);

export type VCSDexSchema = DexConfiguration.CreateSimSchema<
    | typeof qrCodeSim
    | typeof vectorSim
    | typeof cutlineGenerationSim
    | typeof patternSim
    | typeof teamsNameSim
    | typeof prototypeSim
    | typeof textSim
    | typeof imageSim
>;

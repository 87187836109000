import { useDesignEngine, useActivePanel } from "@design-stack-vista/core-features";
import { isEngraved } from "@internal/utils-deco-tech";
import { observer } from "mobx-react-lite";
import React, { type JSX, useMemo } from "react";
import { AdjustContrastColorPicker } from "./adjustContrastColorPicker";

interface SingleColorColorPickerProps {
    enableToggleBackground: boolean;
}

export const SingleColorColorPicker = observer((props: SingleColorColorPickerProps): JSX.Element => {
    const { enableToggleBackground } = props;
    const designEngine = useDesignEngine();
    const { layoutStore } = designEngine;
    const { activePanel } = useActivePanel();
    const targetPanel = activePanel ?? layoutStore.visiblePanels[0];

    const showPalette = useMemo(() => {
        const { decorationTechnology } = targetPanel.panelProperties;
        return !isEngraved(decorationTechnology);
    }, [targetPanel.panelProperties]);

    if (!showPalette) {
        return <React.Fragment />;
    }
    return <AdjustContrastColorPicker enableToggleBackground={enableToggleBackground} />;
});

SingleColorColorPicker.displayName = "SingleColorColorPicker";

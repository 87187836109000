import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showImageSearchFiltersPanelSlice = createSlice({
    name: "showImageSearchFiltersPanel",
    initialState: false,
    reducers: {
        setShowImageSearchFiltersPanel: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowImageSearchFiltersPanel } = showImageSearchFiltersPanelSlice.actions;
export const showImageSearchFiltersPanel = showImageSearchFiltersPanelSlice.reducer;

import { useCallback } from "react";
import {
    mapDesignAttributeToProductOption,
    mapDesignAttributeValueToProductOptionValue
} from "@internal/utils-studio-configuration-core";
import { ProductAttributesKeyAndValues, ProductAttributeValue } from "@internal/data-access-product";
import { useDesignAttributeMappings, useProductAttributeAndValues } from "@shared/features/StudioBootstrap";

export const useFlexibilityMerchandisingData = () => {
    const keyAndValues = useProductAttributeAndValues();
    const designAttributeMappings = useDesignAttributeMappings();

    const getFlexibilityMerchandisingData = useCallback(
        (designAttributeName: string, designAttributeValue: string): ProductAttributeValue | undefined => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                designAttributeMappings,
                designAttributeName,
                designAttributeValue
            );
            const kvPair =
                Array.isArray(keyAndValues) &&
                keyAndValues.length !== 0 &&
                keyAndValues.find(
                    (keyAndValue: ProductAttributesKeyAndValues) => keyAndValue.key.key === productOptionName
                );
            if (kvPair) {
                return kvPair.value.find((value: ProductAttributeValue) => value.key === productOptionValue);
            }
            return undefined;
        },
        [designAttributeMappings, keyAndValues]
    );

    return getFlexibilityMerchandisingData;
};

import { DecorationTechnology } from "../types";
import { SINGLE_COLOR_TYPES } from "./SINGLE_COLOR_TYPES";

export function isSingleColor(decorationTechnology: string | undefined) {
    return decorationTechnology ? SINGLE_COLOR_TYPES.includes(decorationTechnology) : false;
}

export function isEngraved(decorationTechnology: string | undefined) {
    return decorationTechnology ? decorationTechnology === DecorationTechnology.LaserEngraving : false;
}

import { useCallback } from "react";
import { DesignTileCallbackProps } from "@shared/utils/DesignPanel";
import { documentSourceTypeMap } from "@internal/data-access-design-specifications-service";
import { useErrors } from "@internal/utils-errors";
import { FULLBLEED_SELECTED_TEMPLATE } from "@internal/data-access-template";
import { type DTR } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useSurfaceUpsellData } from "@internal/utils-surface-upsell";
import { useDesignDialog } from "../DesignDialogProvider";
import { applyDesignOption } from "../utils";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

interface SelectTemplateExternalParameters extends DesignTileCallbackProps {
    templateToken: string;
    designType?: string;
    colorMatchedDocument?: DTR.EditDocV2;
}

export const useSelectTemplateCallback = () => {
    const { getDocumentWithNonServerRenderableItems, loadNewDesign, fireInteractionTimedEvent } = useDesignDialog();
    const getPanelSourceExtension = useGetPanelSourceExtension();
    const { handleError } = useErrors();
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const { surfaceUpsellData: surfaceUpsells, updateSurfaceUpsellData } = useSurfaceUpsellData();
    /**
     * This function is a callback that when called, will convert the panel with panelName to the template passed in
     * @param templateToken - template id to convert the panel name too
     * @param panelName - panel to be converted
     * @param resetting - are we resetting the panel (meaning are we going to ignore the saved panel) meaning the user would be able to start from the template again
     * @returns callback
     */
    return useCallback(
        ({
            templateToken,
            panelName,
            // returned from calcifer/DTeC as generic or bespoke
            designType,
            resetting = false,
            colorMatchedDocument
        }: SelectTemplateExternalParameters) => {
            return async (dispatch: any) => {
                const panelSourceExtension = getPanelSourceExtension(panelName);
                if (!panelSourceExtension) {
                    return undefined;
                }
                const surfaceUpsell = surfaceUpsells[panelName];
                const isFullbleed = templateToken === FULLBLEED_SELECTED_TEMPLATE;

                return applyDesignOption({
                    dispatch,
                    productAndProjectState: productAndProjectStateManager.data,
                    updateProductAndProjectStateConfiguration: productAndProjectStateManager.updateConfiguration,
                    panelName,
                    templateDesignType: designType,
                    resetting,
                    newDocumentSourceType: isFullbleed
                        ? documentSourceTypeMap.FULLBLEED
                        : documentSourceTypeMap.TEMPLATE_TOKEN,
                    newDocumentSourceData: isFullbleed ? "" : templateToken,
                    newOptions: surfaceUpsell?.colorOption
                        ? {
                              [surfaceUpsell.optionName]: surfaceUpsell.colorOption
                          }
                        : undefined,
                    fireInteractionTimedEvent,
                    colorMatchedDocument,
                    getDocument: getDocumentWithNonServerRenderableItems,
                    panelSourceExtension,
                    loadNewDesign,
                    handleError,
                    surfaceUpsell: surfaceUpsells,
                    updateSurfaceUpsellData
                });
            };
        },
        [
            getPanelSourceExtension,
            surfaceUpsells,
            productAndProjectStateManager.data,
            productAndProjectStateManager.updateConfiguration,
            fireInteractionTimedEvent,
            getDocumentWithNonServerRenderableItems,
            loadNewDesign,
            handleError,
            updateSurfaceUpsellData
        ]
    );
};

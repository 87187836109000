import {
    getQueryParams,
    cleanStudioUrl,
    isCareAgent,
    isCareAgentEditingCareAgentDocument
} from "@internal/utils-browser";
import { tryFetch } from "@internal/utils-network";
import type { UdsResponse } from "@internal/data-access-document-storage";
import { getProductName, MERCHANDISING_TENANT } from "@internal/data-access-product";
import cloneDeep from "lodash/cloneDeep";
import type { Identity } from "./Identity";
import { type RecursivePartial, WesSortOptions, type WorkEntity, type WorkEntityToCreate } from "./workTypes";
import { ENTITY_CODE, MERCHANDISING_PRODUCT_URL, WORK_ENTITY_SERVICE_URL } from "./configuration";

type TrackingFn = (data: {
    event?: string;
    eventDetail: string;
    label?: string;
    pageNameBase?: string | null;
    extraData?: any | null;
    timeSinceLoad?: number;
}) => void;

// Only exporting this until we migrate the rest of the work entity client
export async function fetchWorkEntity(authToken: string, workId: string): Promise<WorkEntity> {
    // We want to allow anyone to view deleted documents in studio (DT-6258), so fetching with includeHidden=true
    const url = `${WORK_ENTITY_SERVICE_URL}/v1/works/${encodeURIComponent(workId)}?includeHidden=true`;

    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:fetchWorkEntity",
        friendlyDescription: "retrieve user's work",
        entityCode: ENTITY_CODE
    });
}

export async function getWorkEntity(
    authToken: string,
    identity: Identity,
    workId: string,
    fireTrackingEvent: TrackingFn
): Promise<WorkEntity> {
    if (isCareAgent()) {
        const { careforceData, chatTranscriptId, careforceUserId } = getQueryParams();
        fireTrackingEvent({
            event: "CARE_AGENT_LOADED_WORK",
            eventDetail: "Information",
            label: "info",
            extraData: () => ({
                workId,
                agentId: identity.shopperId,
                careforceData,
                chatTranscriptId,
                careforceUserId
            })
        });
    }
    const content = await fetchWorkEntity(authToken, workId);
    return content;
}

interface ConstructWorkEntityParams {
    authToken: string;
    udsResponse: UdsResponse;
    dexName: string;
    hasTeamsNameItems: boolean;
    mpvId?: string;
    productName: string;
    quantity: number;
    locale: string;
    productKey: string;
    workId?: string;
    productVersion: number;
    quantityPerSize?: string;
    studioSelectedProductOptions: Record<string, string>;
    customerSelectedProductOptions: Record<string, string>;
    workTenant: string;
    newWorkName?: string;
}

export async function constructWorkEntity({
    authToken,
    udsResponse,
    dexName,
    hasTeamsNameItems,
    mpvId,
    productName,
    quantity,
    locale,
    productKey,
    workId,
    productVersion,
    quantityPerSize,
    studioSelectedProductOptions,
    customerSelectedProductOptions,
    workTenant,
    newWorkName = ""
}: ConstructWorkEntityParams): Promise<{
    newWork: WorkEntityToCreate;
    existingWork: RecursivePartial<WorkEntity> | undefined;
}> {
    const workEntity: RecursivePartial<WorkEntity> =
        !newWorkName && workId ? await fetchWorkEntity(authToken, workId) : {};

    // save this to return later
    const existingWork = cloneDeep(workEntity);

    if (!workEntity.merchandising) {
        workEntity.merchandising = {};
    }

    workEntity.tenant = workTenant;
    workEntity.workName = newWorkName || workEntity.workName || productName;
    workEntity.merchandising.merchandisingSelections = customerSelectedProductOptions;
    workEntity.merchandising.quantity = quantity;

    if (mpvId) {
        workEntity.merchandising.mpvUrl = `${MERCHANDISING_PRODUCT_URL}/mpv/${MERCHANDISING_TENANT}/${locale}/${mpvId}`;
    }

    // the new way of saving product data
    workEntity.product = workEntity.product || {};
    workEntity.product.key = productKey;
    workEntity.product.version = productVersion ?? undefined;

    workEntity.resources = workEntity.resources || {};
    workEntity.resources.qty = quantityPerSize ?? undefined;

    workEntity.design = workEntity.design || {};
    workEntity.design.metadata = workEntity.design.metadata || {};

    // clean up this old field we no longer use
    if (workEntity.design.metadata.udsDocumentUrl) {
        delete workEntity.design.metadata.udsDocumentUrl;
    }

    // backwards compatibility with other consumers depending on this data
    workEntity.design.metadata.productKey = productKey;

    // Track the studio that generated this work (or in the case of advanced studio, the work revision)
    // We're using 'studioVersion' as thats the same name we use for tracking in segment
    workEntity.design.metadata.studioVersion = dexName;

    // we only need to keep the studio selected product options if we actually have some,
    // and if the customer still has not selected a complete set of product options
    if (
        studioSelectedProductOptions &&
        JSON.stringify(Object.keys(studioSelectedProductOptions).sort()) !==
            JSON.stringify(Object.keys(customerSelectedProductOptions).sort())
    ) {
        // WES throws a validation error when trying to put json here and not stringifying it :(
        workEntity.design.metadata.studioSelectedProductOptions = JSON.stringify(studioSelectedProductOptions);
    } else if (workEntity.design.metadata.studioSelectedProductOptions) {
        // if we no longer need to keep the studio selected product options around because the customer
        // has a full set of product options that they have selected, just remove these from the work
        delete workEntity.design.metadata.studioSelectedProductOptions;
    }

    workEntity.design.designUrl = udsResponse.documentRevisionUrl;
    workEntity.design.manufactureUrl = udsResponse.instructionSourceUrl;
    workEntity.design.displayUrl = udsResponse.previewInstructionSourceUrl;
    if (workEntity.design.docRefUrl) {
        // most pages etc do not update this.
        delete workEntity.design.docRefUrl;
    }
    // on production the path could be /studio, or /fr/studio.  so have to do get the actual pathname.
    workEntity.design.editUrl = `${cleanStudioUrl(window.location.pathname)}?workId=\${workId}`;

    workEntity.design.metadata.hasTeamsPlaceholders = hasTeamsNameItems ? "true" : undefined;

    return { newWork: workEntity as WorkEntity, existingWork };
}

export async function undeleteWorkEntity(workId: string, authToken: string) {
    const url = `${WORK_ENTITY_SERVICE_URL}/v1/works/${workId}/setVisible`;
    const content = await tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ hidden: false })
        },
        moduleFunction: "workEntityClient:undeleteWorkEntity",
        friendlyDescription: "undelete user's work to enable save",
        entityCode: ENTITY_CODE,
        retryCount: 0
    });
    return content;
}

interface PostWorkEntityParams {
    workEntity: WorkEntityToCreate;
    authToken: string;
}

export async function postWorkEntity({ workEntity, authToken }: PostWorkEntityParams) {
    // Always add vp-care keys if they exist in localStorage
    if (isCareAgent() && typeof window !== "undefined" && window.localStorage) {
        Object.keys(window.localStorage)
            .filter(key => key.startsWith("vp-care"))
            .forEach(key => {
                workEntity.resources[key] = window.localStorage.getItem(key)!;
            });
    }

    let url = `${WORK_ENTITY_SERVICE_URL}/v1/works${
        workEntity.workId ? `/${encodeURIComponent(workEntity.workId)}/update` : `?tenant=${workEntity.tenant}`
    }`;

    if (isCareAgent() && !isCareAgentEditingCareAgentDocument()) {
        const urlParams = getQueryParams();

        const { owner } = urlParams;
        if (!owner) {
            throw new Error("Please define owner query param");
        }
        url += `${workEntity.workId ? "?" : "&"}ownerId=${owner}`;
    }

    const content = await tryFetch<WorkEntity>({
        url: url ?? `${WORK_ENTITY_SERVICE_URL}/v1/works?tenant=${workEntity.tenant}`,
        options: {
            method: "POST",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify(workEntity)
        },
        moduleFunction: "workEntityClient:postWorkEntity",
        friendlyDescription: "save user's work",
        entityCode: ENTITY_CODE
    });
    return content;
}

/**
 *
 * @param {string} ownerId
 * @param {string} authToken
 * @returns list of works, up to 200
 */
export async function getAllWorks(ownerId: string, authToken: string): Promise<WorkEntity[]> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v1/works?ownerId=${ownerId}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorks",
        friendlyDescription: "retrieve all of a user's works",
        entityCode: ENTITY_CODE
    });
}

interface GetWorkCountInfoParams {
    ownerId: string;
    authToken: string;
    workTenant: string;
}

export interface WorkCountInfo {
    ownedWorks: number;
    ownerId: string;
}

export async function getWorkCountInfo({
    ownerId,
    authToken,
    workTenant
}: GetWorkCountInfoParams): Promise<WorkCountInfo> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v2/works/count?tenants=${workTenant}&ownerId=${ownerId}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorks",
        friendlyDescription: "retrieve all of a user's works",
        entityCode: ENTITY_CODE
    });
}

export async function patchWorkName(workId: string, authToken: string, newName: string): Promise<void> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v2/works/${workId}/name`;
    return tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ workName: newName })
        },
        moduleFunction: "workEntityClient:patchWorkName",
        friendlyDescription: "update the name of a user's works",
        entityCode: ENTITY_CODE
    });
}

// Use WesSortOptions for available values
// Returns list of works, up to 50, optionally sorted
interface GetSortedWorksInternalParams {
    ownerId: string;
    authToken: string;
    sortBy: string;
    workTenant: string;
    pageSize: number;
    offset: number;
}

async function getSortedWorksInternal({
    workTenant,
    ownerId,
    authToken,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    offset = 0
}: GetSortedWorksInternalParams): Promise<WorkEntity[]> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v2/works?tenants=${workTenant}&ownerId=${ownerId}&pageSize=${pageSize}&sortBy=${sortBy}&offset=${offset}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getAllWorksSorted",
        friendlyDescription: "retrieve sorted works",
        entityCode: ENTITY_CODE
    });
}

// Use WesSortOptions for available values
// Returns list of works matching the search term, up to 50, optionally sorted
interface GetWorksSearchResultsParams {
    ownerId: string;
    authToken: string;
    workTenant: string;
    searchTerm: string;
    sortBy?: string;
    pageSize?: number;
    offset?: number;
}
export async function getWorksSearchResults({
    workTenant,
    ownerId,
    authToken,
    searchTerm,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    offset = 0
}: GetWorksSearchResultsParams): Promise<WorkEntity[]> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v2/works:search?tenants=${workTenant}&ownerId=${ownerId}&pageSize=${pageSize}&sortBy=${sortBy}&offset=${offset}&workName=${searchTerm}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getWorksSearchResults",
        friendlyDescription: "retrieve works with names including the search term",
        entityCode: ENTITY_CODE
    });
}

export async function replaceEmptyNames(works: WorkEntity[], locale: string) {
    const namedWorks = await Promise.all(
        works.map(async work => {
            const updatedWork = { ...work };
            if (!updatedWork.workName || !updatedWork.workName.length) {
                try {
                    const workLocale =
                        (updatedWork?.merchandising?.mpvUrl &&
                            updatedWork.merchandising.mpvUrl
                                .toLowerCase()
                                .split("/")
                                .find((section: string) => /^[a-z]{2}-[a-z]{2}$/.test(section))) ||
                        locale;
                    const name = await getProductName(
                        updatedWork.product?.key || updatedWork.design.metadata?.productKey,
                        workLocale
                    );
                    updatedWork.workName = name;
                } catch (err) {
                    // account for possible null values if the mpv call fails
                    updatedWork.workName = "";
                }
            }
            return updatedWork;
        })
    );
    return namedWorks;
}

/**
 * Retrieves sorted works with special logic for sorting legacy/new world works by date
 * This should go away after IM fixes dates for imported works (July or August 2021)
 * Copied from https://gitlab.com/vistaprint-org/design-technology/my-project-microfrontend/-/blob/master/src/components/projects/ProjectList.jsx#L174
 * At that point we should be able to rename getSortedWorksInternal and just use that
 */
interface GetSortedWorksParams {
    workTenant: string;
    ownerId: string;
    authToken: string;
    locale: string;
    sortBy?: string;
    pageSize?: number;
    previouslyFetchedWorks?: WorkEntity[];
}

export async function getSortedWorks({
    workTenant,
    ownerId,
    authToken,
    locale,
    sortBy = WesSortOptions.LAST_CREATED,
    pageSize = 50,
    previouslyFetchedWorks = []
}: GetSortedWorksParams) {
    const nameSort = sortBy === WesSortOptions.NAME_ASCENDING || sortBy === WesSortOptions.NAME_DESCENDING;

    // intentionally ignoring abelli projects for now
    const fetchedWorks = await getSortedWorksInternal({
        workTenant,
        ownerId,
        authToken,
        sortBy,
        pageSize,
        offset: previouslyFetchedWorks?.length
    });

    if (nameSort) {
        return fetchedWorks;
    }

    const namedWorks = await replaceEmptyNames(fetchedWorks, locale);

    return namedWorks;
}

export async function hideWork(authToken: string, workId: string): Promise<void> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v1/works/${workId}/setVisible`;
    return tryFetch({
        url,
        options: {
            method: "PATCH",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
            body: JSON.stringify({ hidden: true })
        },
        moduleFunction: "workEntityClient:hideWork",
        friendlyDescription: "hide (soft delete) a user's work by workId",
        entityCode: ENTITY_CODE
    });
}

interface GetWorkRevisionsParams {
    workTenant: string;
    authToken: string;
    workId: string;
    includeHidden: boolean; // include hidden (deleted) works
}

export async function getWorkRevisions({
    workTenant,
    authToken,
    workId,
    includeHidden = false
}: GetWorkRevisionsParams): Promise<WorkEntity[]> {
    const url = `${WORK_ENTITY_SERVICE_URL}/v1/works/${workId}/revisions?includeHidden=${includeHidden}&tenant=${workTenant}`;
    return tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                From: "studio",
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "workEntityClient:getWorkRevisions",
        friendlyDescription: "retrieve revisions associated with a work",
        entityCode: ENTITY_CODE
    });
}

import React from "react";
import {
    type StudioContentDiscoveryZone,
    StudioContentDiscoveryZoneProvider,
    type StudioContentDiscoveryZoneName
} from "@internal/feature-studio-content-discovery-zone";
import { aiTemplatesContentDiscoveryModule } from "@internal/feature-ai-templates";
import { layersContentDiscoveryModule } from "@six/features/editorUI/layers";
import { premiumFinishContentDiscoveryModule } from "@six/features/editorUI/premiumFinish";
import { templateAndSubstrateColorContentDiscoveryModule } from "@six/features/ChangeTemplate";
import { shapesAndGraphicsContentDiscoveryModule } from "@six/features/editorUI/shapesContentPanel";
import { flexibilityContentDiscoveryModule } from "@six/features/Flexibility";
import { useStudioConfigurationManager } from "@internal/dex";
import { ChangeSizeContentDiscoveryModule } from "@shared/features/Flexibility";

const ALL_CONTENT_DISCOVERY_ZONE_MODULES: Set<StudioContentDiscoveryZone> = new Set([
    layersContentDiscoveryModule,
    premiumFinishContentDiscoveryModule,
    templateAndSubstrateColorContentDiscoveryModule,
    flexibilityContentDiscoveryModule,
    shapesAndGraphicsContentDiscoveryModule,
    aiTemplatesContentDiscoveryModule,
    ChangeSizeContentDiscoveryModule
]);

const useAggregateAllContentDiscoveryZoneOverrides = () => {
    const studioConfigurationManager = useStudioConfigurationManager();
    return Array.from(ALL_CONTENT_DISCOVERY_ZONE_MODULES).reduce<
        Record<StudioContentDiscoveryZoneName, boolean | undefined>
    >((acc, module) => {
        const { useOverrideEnableState } = module;

        if (!useOverrideEnableState) {
            return acc;
        }

        return {
            ...acc,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            ...useOverrideEnableState(studioConfigurationManager.data)
        };
    }, {});
};

export const ContentDiscoveryZoneProviderWrapper = ({ children }: React.PropsWithChildren<{}>) => {
    const overrides = useAggregateAllContentDiscoveryZoneOverrides();

    return (
        <StudioContentDiscoveryZoneProvider modules={ALL_CONTENT_DISCOVERY_ZONE_MODULES} {...overrides}>
            {children}
        </StudioContentDiscoveryZoneProvider>
    );
};

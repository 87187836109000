const getPatternTileDimension = (width, height, patternDimensionRatio) => {
  const patternDimension = Math.min(height, width) * patternDimensionRatio;
  return patternDimension;
};
const getCanvasDimensionAndPosition = (panelWidth, panelHeight, patternDimensionRatio, xOffsetRatio, yOffsetRatio, patternImageAspectRatio) => {
  const newDimension = Math.sqrt(panelHeight ** 2 + panelWidth ** 2);
  const patternTileDimension = getPatternTileDimension(panelWidth, panelHeight, patternDimensionRatio);
  const x = -((newDimension - panelWidth) / 2 + patternTileDimension * (1 - xOffsetRatio / 100));
  const y = -((newDimension - panelHeight) / 2 + patternTileDimension * (1 - yOffsetRatio / 100));
  const canvasWidth = newDimension + patternTileDimension * (1 - xOffsetRatio / 100);
  const canvasHeight = newDimension + patternTileDimension * (1 - yOffsetRatio / 100);
  return {
    patternTileDimension: {
      width: patternTileDimension * patternImageAspectRatio,
      height: patternTileDimension * patternImageAspectRatio
    },
    canvasWidth,
    canvasHeight,
    canvasPositionX: x,
    canvasPositionY: y
  };
};
export {
  getCanvasDimensionAndPosition,
  getPatternTileDimension
};

import { useDesignEngine } from "@design-stack-vista/core-features";
import { useCallback } from "react";
import { PanelUsedColorsExtension } from "./extension/PanelUsedColorsExtension";

/**
 * Iterates over all the panels in the CimDoc, and counts all the instances of
 * unique colors used across TextArea, Word Art Item Reference,
 * Table Item Reference items, Shapes, and Images.
 */

export const useGetAllColorsFromCimDoc = () => {
    const designEngine = useDesignEngine();

    const getAllColorsMap = useCallback((): Map<string, number> => {
        const allPanelsColorUsage: Map<string, number>[] = [];
        for (const panel of designEngine.cimDocStore.panels) {
            const usedColorsExtension = designEngine.designExtensionSystem?.getExtension(
                panel.iid,
                PanelUsedColorsExtension
            );

            if (usedColorsExtension?.colorUsage) {
                allPanelsColorUsage.push(usedColorsExtension?.colorUsage);
            }
        }

        if (allPanelsColorUsage.length === 0) {
            return new Map();
        }
        const [initialMap, ...remainingPanelColors] = allPanelsColorUsage;

        const accumulatedColorsMap = remainingPanelColors.reduce<Map<string, number>>((acc, currentPanelColorMap) => {
            currentPanelColorMap.forEach((count, color) => {
                const currentCount = acc.get(color) ?? 0;
                acc.set(color, currentCount + count);
            });
            return acc;
        }, initialMap);

        return accumulatedColorsMap;
    }, [designEngine.cimDocStore.panels, designEngine.designExtensionSystem]);

    return getAllColorsMap;
};

import React from "react";
import { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import { AITemplatesIcon, IdeaPanelIcon } from "@internal/ui-studio-chrome";
import { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { AITemplatesContentPanelTypes } from "./types";
import { LocalizedKeys, useLocalized } from "./localized";
import { useAITemplatesContext } from "./AITemplatesProvider";

interface ContentDiscoveryZoneButtonConfig {
    Icon: React.JSX.Element;
    labelKey: keyof LocalizedKeys;
}

const ideaPanelContentDiscoveryZoneButtonConfig: ContentDiscoveryZoneButtonConfig = {
    Icon: <IdeaPanelIcon aria-hidden="true" />,
    labelKey: "generateIdeasTabTitle"
};

const aITemplatesContentDiscoveryZoneButtonConfig: ContentDiscoveryZoneButtonConfig = {
    Icon: <AITemplatesIcon aria-hidden="true" />,
    labelKey: "aiTemplatesDesignsTitle"
};

const getContentDiscoveryZoneButtonConfig = (isDesignAssistantTabEnabled: boolean) =>
    isDesignAssistantTabEnabled
        ? ideaPanelContentDiscoveryZoneButtonConfig
        : aITemplatesContentDiscoveryZoneButtonConfig;

const CDZButton: ContentDiscoveryZoneButton = ({ children }) => {
    const localized = useLocalized();
    const { isDesignAssistantTabEnabled } = useAITemplatesContext();
    const { Icon, labelKey } = getContentDiscoveryZoneButtonConfig(isDesignAssistantTabEnabled);
    return children({
        icon: Icon,
        label: localized.get(labelKey)
    });
};

export const aiTemplatesContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-ai-templates",
    core: {
        Designs: {
            activeDialogType: AITemplatesContentPanelTypes.design,
            component: CDZButton
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showSpecsAndTemplateSlice = createSlice({
    name: "showSpecsAndTemplate",
    initialState: false,
    reducers: {
        setShowSpecsAndTemplateModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowSpecsAndTemplateModal } = showSpecsAndTemplateSlice.actions;
export const showSpecsAndTemplate = showSpecsAndTemplateSlice.reducer;

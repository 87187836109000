import { DSS } from "@vp/types-ddif";

/**
 * Some data on documents is not supported in studio or may cause unexpected behavior
 * For now we remove the sku and skuVariables
 * @param cimDoc
 */
export function removeUnsupportedCDIFKeys(cimDoc: DSS.DesignDocument) {
    // we don't use or support sku/skuVariables on documents and they can cause issues in fusion and other dependencies
    if (cimDoc.sku) {
        delete cimDoc.sku;
    }
    // @ts-expect-error not part of the cdif-types CimDoc type, but it can exist on documents in the wild
    if (cimDoc.skuVariables) {
        // @ts-expect-error not part of the cdif-types CimDoc type, but it can exist on documents in the wild
        delete cimDoc.skuVariables;
    }
}

import { isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
const isVectorUrl = (url) => {
  return url.indexOf("items.documents.cimpress.io/v1/vector") > 0;
};
const isVectorItemReference = (item) => {
  return item.isItemReference() && item.model.type === ItemReferenceTypes.VECTOR_PANEL && isVectorUrl(item.model.url);
};
function isVectorItem(item) {
  return isItemReference(item) && item.type === ItemReferenceTypes.VECTOR_PANEL && isVectorUrl(item.url);
}
export {
  isVectorItem,
  isVectorItemReference,
  isVectorUrl
};

import { trackApiCall } from "@internal/utils-network";
import { formatError } from "@internal/utils-errors";

export async function checkResponseError(
    moduleFunction: string,
    response: Response,
    entityCode: number,
    method?: string,
    body?: any
) {
    if (!response.ok) {
        let jsonResponse = "";
        try {
            jsonResponse = `Response: ${await response.text()}`;
        } catch (e) {
            jsonResponse = `Exception getting text: ${e}`;
        }
        trackApiCall({
            module: moduleFunction,
            body,
            method,
            url: response.url,
            result: jsonResponse,
            statusCode: response.status
        });

        throw formatError(moduleFunction, jsonResponse, entityCode, response.status.toString(), undefined, response);
    } else {
        try {
            const result = await response.json();
            trackApiCall({
                module: moduleFunction,
                body,
                method,
                url: response.url,
                result,
                statusCode: response.status
            });
            return result;
        } catch (ex: any) {
            throw formatError(
                moduleFunction,
                `Error parsing JSON: ${ex.message} from ${response.url}`,
                entityCode,
                response.status.toString(),
                ex,
                response
            );
        }
    }
}

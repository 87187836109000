import { v4 as uuid } from "uuid";
import { MeasurementUnit, Measurement } from "@design-stack-vista/utility-core";
import type { BackgroundItem, CimDoc, FigureItem } from "@design-stack-vista/cdif-types";
import {
    getPanelById,
    isFigureItem,
    initializeMetadata,
    BACKGROUND_Z_INDEX,
    isBackgroundItem
} from "@design-stack-vista/cimdoc-state-manager";

export interface SetBackgroundColorParameters {
    panelId: string;
    color: string;
}

function getDefaultBackgroundMetadataTemplate(id: string): BackgroundItem {
    return {
        id,
        background: true,
        locks: {
            edit: false,
            transform: false,
            zIndex: true
        }
    };
}

/**
 * Sets the background color of a panel by either making a new background shape or finding an existing one, then changing its color
 */
export function setBackgroundColor(cimDoc: CimDoc, { panelId, color }: SetBackgroundColorParameters) {
    const panel = getPanelById(cimDoc, panelId);

    if (!panel) {
        throw new Error(`No panel found with matching id: ${panelId}`);
    }

    // get the shape item which has metadata set as background
    const backgroundShapeItem = panel.shapes?.filter(shape => isBackgroundItem(shape, cimDoc.metadata));
    if (backgroundShapeItem && isFigureItem(backgroundShapeItem[0])) {
        backgroundShapeItem[0].color = color;
    } else {
        const itemId = uuid();
        const shapeItem: FigureItem = {
            id: itemId,
            zIndex: BACKGROUND_Z_INDEX,
            type: "rectangle",
            position: {
                x: `0${MeasurementUnit.MM}`,
                y: `0${MeasurementUnit.MM}`,
                height: `${new Measurement(panel.height).mm}${MeasurementUnit.MM}`,
                width: `${new Measurement(panel.width).mm}${MeasurementUnit.MM}`
            },
            color
        };

        if (panel.shapes) {
            panel.shapes.push(shapeItem);
        } else {
            panel.shapes = [shapeItem];
        }

        // add required metadata template to indicate item as background
        const backgroundItemTemplate = getDefaultBackgroundMetadataTemplate(itemId);

        initializeMetadata(cimDoc, { initializeTemplate: true });

        cimDoc.metadata!.template!.push(backgroundItemTemplate);
    }
}

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { windowExists } from "./windowExists";
import { tryParse } from "./tryParse";

export function getQueryParams(search?: string | undefined): { [key: string]: any } {
    if ((search && search.length === 0) || (!search && (!windowExists() || window.location.search.length === 0))) {
        return {};
    }
    const rawParams = search ? qs.parse(search.substring(1)) : qs.parse(window.location.search.substring(1));
    return Object.assign({}, rawParams, {
        selectedOptions: rawParams.selectedOptions ? tryParse(rawParams.selectedOptions as string, {}) : {},
        qty: rawParams.qty ? tryParse(rawParams.qty as string, "") : "",
        owner: rawParams.owner || rawParams.ownerId || undefined
    });
}

export function addQueryParam(url: string | URL, key: string, value: string) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    params[key] = value;
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ""}${
        realUrl.pathname
    }?${newParams}`;
}

export function addQueryParams(url: string | URL, newParameters: Record<string, string | number | null | undefined>) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    Object.keys(newParameters).forEach(key => {
        const newValue = newParameters[key];
        if (newValue === null || newValue === undefined) {
            delete params[key];
        } else {
            params[key] = `${newValue}`;
        }
    });
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ""}${
        realUrl.pathname
    }?${newParams}`;
}

export function replaceQueryParam(url: string, newParams: string) {
    const realUrl = new URL(url);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ""}${
        realUrl.pathname
    }?${newParams}`;
}

export function removeQueryParam(url: string | URL, key: string | undefined) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    if (key && params[key]) {
        delete params[key];
    }
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ""}${
        realUrl.pathname
    }?${newParams}`;
}

export function addQueryStringData(url: string, queryStringData = {}) {
    let newUrl = url;
    const data = Object.keys(queryStringData)
        // @ts-ignore FIXME: must handle implicit `any` type
        .filter(key => queryStringData[key] != null)
        // @ts-ignore FIXME: must handle implicit `any` type
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryStringData[key])}`);

    if (data.length) {
        newUrl += newUrl.indexOf("?") > 0 ? "&" : "?";
        newUrl += data.join("&");
    }

    return newUrl;
}

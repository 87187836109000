/**
 * @note if you add or remove any error codes from this list,
 * you must update https://vistaprint.atlassian.net/wiki/spaces/AE/pages/83001969/Error+Code+Mappings+in+Studio
 */

export const ERROR_CODES = {
    START_STUDIO: 10,
    LOAD_REDUX: 11,
    ANON_TRY_LOGIN: 13,
    DOC_PERM_DENIED: 14,
    SAVE_DOCUMENT: 17,
    ERROR_BOUNDARY: 18,
    NO_PRODUCT_DATA: 19,
    PDF_PROOF_PROCESSING: 20,
    LOAD_PROJECT: 21,
    DUPLICATE_PROJECT: 22,
    DELETE_PROJECT: 23,
    ADD_TO_CART_ERROR: 50,
    SURFACE_UPSELL_LOAD: 53,
    SURFACE_UPSELL_CHANGE: 54,
    SURFACE_UPSELL_PRICING: 55,
    MATCHING_TEMPLATE_LOAD: 56,
    MATCHING_TEMPLATE_LOAD_INDIVIDUAL: 57,
    CHANGE_TEMPLATE: 60,
    NAVIGATE_TO_TEAMS: 61,
    AI_TEMPLATES_SELECTION_NOT_FOUND: 62,
    AI_TEMPLATES_USE_TEMPLATE: 63,
    AI_TEMPLATES_RESIZE_DOC: 64,
    DESIGN_ENGINE_PROCESSING_TIMEOUT: 65,
    FLEXIBILITY_PRICING: 66,
    STANDARD_VORTEX_PREVIEW: 67,
    CONTENT_ZONE_SLOT_COLLISION: 68,
    FUSION_PANEL_PREVIEW: 69,
    FUSION_STUDIO_PREVIEW: 70,
    VECTORS: 71,
    PREVIEW_FAILURE: 72,
    QR_CODE_UPLOADS: 73,
    DIGITAL_DOWNLOAD: 74,
    ARTWORK_API_GETTING_DESIGN: 75,
    SINGLE_COLOR_PALETTE: 76,
    LOAD_COLORS: 122,
    UNKNOWN: 999
};

import React, { createContext, useContext, ReactNode, useMemo } from "react";
import type { DispatchToast } from "@internal/utils-detail-zone";

interface ColorPickerDispatchToastProviderContext {
    dispatchToast?: DispatchToast;
}

interface ColorPickerDispatchToastProviderProps {
    children: ReactNode | ReactNode[];
    dispatchToast?: DispatchToast;
}

const Context = createContext<ColorPickerDispatchToastProviderContext>({});

export const useColorPickerDispatchToast = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within a ColorPickerDispatchToastProvider");
    }
    return result;
};

/**
 * This is meant to be a temporary solution to providing the dispatchToast function to a feature lib, hence the very
 * specific name. There should be a solution in the future where dispatchToast is availble anywhere in the monorepo,
 * removing the need to pass it to this provider.
 */
export const ColorPickerDispatchToastProvider = ({
    children,
    dispatchToast
}: ColorPickerDispatchToastProviderProps) => {
    const contextObject = useMemo(
        () => ({
            dispatchToast
        }),
        [dispatchToast]
    );

    return <Context.Provider value={contextObject}>{children}</Context.Provider>;
};

import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ImageIcon, ImagesIcon } from "@internal/ui-studio-chrome";
import type { SimInterface } from "@internal/sim-framework";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { ImageContentPanelDialogTypes } from "../ImageContentPanelDialogTypes";
import { ImagePluginPreloaderPanelExtension } from "../extensions/ImagePluginPreloaderExtension";
import { name } from "./name";

const messages = defineMessages({
    imagesTitle: {
        id: "editorUI.addContent.panel.images.title",
        defaultMessage: "Images",
        description: {
            note: "Content Panel title text for adding images"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    if (screenSize === "smallScreen") {
        return children({
            icon: <ImageIcon aria-hidden="true" />,
            label: t(messages.imagesTitle.id)
        });
    }

    return children({
        icon: <ImagesIcon aria-hidden="true" />,
        label: t(messages.imagesTitle.id)
    });
};

export const legacyImageSim: SimInterface<"ContentDiscoveryZone"> = {
    name,
    core: {
        extensions: [ImagePluginPreloaderPanelExtension],
        contentDiscoveryZone: {
            Images: { activeDialogType: ImageContentPanelDialogTypes.images, component: CDZButton }
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import type { CdifColor } from "@design-stack-vista/cimdoc-state-manager";
import type { CMYK, CMYKA, RGB, RGBA } from "@design-stack-vista/utility-core";
import { cmyk2hex, cmyk2rgb, rgb2hex, round, isPresent } from "@design-stack-vista/utility-core";

import type { ColorPaletteType, ColorPaletteEntry, CustomColorsConfiguration } from "../types";
import { ParsedColor, parseColor } from "@mcp-artwork/color-converter";

export class GenericColorPalette implements ColorPaletteType {
    readonly entries: ColorPaletteEntry[];

    readonly customColors = {
        enabled: true
    };

    constructor(entries: ColorPaletteEntry[], customColorsConfiguration?: Partial<CustomColorsConfiguration>) {
        this.entries = entries;
        this.customColors = {
            ...this.customColors,
            ...customColorsConfiguration
        };
    }

    supports(decorationTechnology: string): boolean {
        return true;
    }

    fromCdif(cdifColor: CdifColor): ColorPaletteEntry | undefined {
        const existingPaletteEntry = this.entries.find(entry => entry.cdifValue === cdifColor);
        if (existingPaletteEntry) {
            return existingPaletteEntry;
        }

        let parsedColor: ParsedColor | undefined;

        try {
            parsedColor = parseColor(cdifColor);
        } catch {
            return undefined;
        }

        if (!parsedColor) {
            return undefined;
        }

        switch (parsedColor.colorSpace) {
            case "rgb": {
                if ("a" in parsedColor.value) {
                    const { r, g, b, a } = parsedColor.value as RGBA;
                    const normalizedAlpha = round(a / 255, 2);
                    return {
                        cdifValue: cdifColor,
                        cssBackground: `rgba(${r}, ${g}, ${b}, ${normalizedAlpha})`,
                        name: `${rgb2hex(parsedColor.value as RGB)} (${round(normalizedAlpha * 100, 0)}%)`
                    };
                }
                const hex = rgb2hex(parsedColor.value as RGB);
                return {
                    cdifValue: `rgb(${hex})`,
                    cssBackground: hex,
                    name: hex
                };
            }
            case "cmyk": {
                if ("a" in parsedColor.value) {
                    const rgb = cmyk2rgb(parsedColor.value as CMYK);
                    const { r, g, b } = rgb;
                    const { a } = parsedColor.value as CMYKA;
                    const normalizedAlpha = round(a / 100, 2);
                    return {
                        cdifValue: cdifColor,
                        cssBackground: `rgba(${r}, ${g}, ${b}, ${normalizedAlpha})`,
                        name: `${rgb2hex(rgb)} (${round(normalizedAlpha * 100, 0)}%)`
                    };
                }
                const hex = cmyk2hex(parsedColor.value as CMYK);
                return {
                    cdifValue: cdifColor,
                    cssBackground: hex,
                    name: hex
                };
            }
            default:
                return undefined;
        }
    }

    toCdif(color: string, alpha?: number): CdifColor | undefined {
        let parsedColor: ParsedColor | undefined;

        try {
            parsedColor = parseColor(color);
        } catch {
            return undefined;
        }

        if (!parsedColor) {
            return undefined;
        }

        // if alpha is 1, no need to change the color space to a transparency-enabled one
        if (!isPresent(alpha) || alpha === 1) {
            switch (parsedColor.colorSpace) {
                case "rgb": {
                    if ("a" in parsedColor.value) {
                        return color;
                    }
                    return `rgb(${rgb2hex(parsedColor.value)})`;
                }
                default:
                    return color;
            }
        }

        switch (parsedColor.colorSpace) {
            case "rgb": {
                const { r, g, b } = parsedColor.value as RGB;
                return `rgba(${r}, ${g}, ${b}, ${Math.floor(alpha * 255)})`;
            }
            case "cmyk": {
                const { c, m, y, k } = parsedColor.value as CMYK;
                return `cmyka(${c}, ${m}, ${y}, ${k}, ${Math.floor(alpha * 100)})`;
            }
            default:
                return undefined;
        }
    }
}

import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { getOptionalExtension, useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useCallback } from "react";
import { PanelSourceExtension } from "../extensions/PanelSourceExtension";

export function useGetPanelSourceExtension(): (canvasName: string) => PanelSourceExtension | undefined {
    const designEngine = useAvailableDesignEngine();

    return useCallback(
        (canvasName: string) => {
            if (!designEngine) {
                return undefined;
            }
            const targetPanel = designEngine.cimDocStore.panels.find(
                (panel: PanelState) => panel.panelProperties.name.toLowerCase() === canvasName.toLowerCase()
            );
            return targetPanel && getOptionalExtension(designEngine, targetPanel, PanelSourceExtension);
        },
        [designEngine]
    );
}

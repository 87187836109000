import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { CalciferSceneConfiguration } from "@internal/data-access-calcifer";

const initialState = {
    underlay: [],
    overlay: [],
    review: [],
    transient: [],
    hqTransient: []
} as CalciferSceneConfiguration;

const scenesConfigurationSlice = createSlice({
    name: "scenesConfiguration",
    initialState,
    reducers: {
        setScenesConfiguration: (state, action: PayloadAction<CalciferSceneConfiguration>) => action.payload
    }
});

export const { setScenesConfiguration } = scenesConfigurationSlice.actions;
export const scenesConfiguration = scenesConfigurationSlice.reducer;

import { useTranslationSSR } from "@vp/i18n-helper";
import { useMemo } from "react";
import { localizationMessages, type LocalizedKeys } from "./localizations";

export const useLocalizedValues = () => {
    const { t } = useTranslationSSR();

    return useMemo(
        () =>
            Object.entries(localizationMessages).reduce<Record<keyof LocalizedKeys, string>>((acc, [key, value]) => {
                acc[key as keyof LocalizedKeys] = t(value.id);
                return acc;
            }, {} as Record<keyof LocalizedKeys, string>),
        [t]
    );
};

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { isCareAgent } from "./EnvironmentChecks/careAgent";
import { getQueryParams, removeQueryParam } from "./queryString";

export function buildHistoryUrl(queryParams: { [key: string]: string | boolean }) {
    const urlParams = getQueryParams();

    const updatedQueryParams = queryParams || {};
    if (isCareAgent()) {
        updatedQueryParams.careAgent = true;
    }
    if (urlParams.owner) {
        updatedQueryParams.owner = urlParams.owner;
    }
    if (urlParams.debugMode) {
        updatedQueryParams.debugMode = urlParams.debugMode;
    }
    if (urlParams.studio_bundle_source) {
        updatedQueryParams.studio_bundle_source = urlParams.studio_bundle_source;
    }
    if (urlParams.metadata) {
        updatedQueryParams.metadata = urlParams.metadata;
    }
    if (urlParams.quickStudioAnalytics) {
        updatedQueryParams.quickStudioAnalytics = urlParams.quickStudioAnalytics;
    }
    if (urlParams.returnPath) {
        updatedQueryParams.returnPath = urlParams.returnPath;
    }

    return `${window.location.pathname}?${qs.stringify(updatedQueryParams)}`;
}

export function removeGalleryUrlData() {
    window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "metadata"));
    window.history.replaceState("update-url", "", removeQueryParam(window.location.href, "quickStudioAnalytics"));
}

export function cleanStudioUrl(incomingUrl: string) {
    return incomingUrl.replace(/studio5|studio6|studioab/, "studio");
}

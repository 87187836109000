import { jsx } from "react/jsx-runtime";
import { PatternIcon } from "@internal/ui-studio-chrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "../shared/patternMessage.js";
import "@internal/utils-custom-metadata";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/pattern-sdk";
import "@design-stack-vista/i18n-color-name";
import "@design-stack-ct/preview-sdk";
import "@design-stack-vista/cimdoc-state-manager";
import { PatternItemExtension } from "../extensions/PatternItemExtension.js";
import "../extensions/PatternStore.js";
import "../extensions/PatternExtension.js";
import { useActivePanel, useDesignEngine, getOptionalExtension } from "@design-stack-vista/core-features";
const PatternContentDiscoveryZoneButton = ({
  screenSize,
  children
}) => {
  const strokeColor = screenSize === "smallScreen" ? "white" : "black";
  const {
    activePanel
  } = useActivePanel();
  const designEngine = useDesignEngine();
  const patternItemExtension = activePanel && getOptionalExtension(designEngine, activePanel, PatternItemExtension);
  const itemBlocksPattern = patternItemExtension && (patternItemExtension == null ? void 0 : patternItemExtension.itemBlocksPattern);
  const {
    t
  } = useTranslationSSR();
  if (itemBlocksPattern) {
    return null;
  }
  return children({
    icon: /* @__PURE__ */ jsx(PatternIcon, {
      "aria-hidden": "true",
      color: strokeColor
    }),
    label: t(patternMessage.patternTitleLabel.id)
  });
};
export {
  PatternContentDiscoveryZoneButton
};

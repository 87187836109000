import { useDesignEngine, useSelectedItems } from "@design-stack-vista/core-features";
import { useFileMethods, useUploadManager } from "@design-stack-vista/upload-components";
import { useAsyncEffect } from "@design-stack-vista/utility-react";
import type { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { isAssetMultiPage } from "@internal/utils-assets";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { useCallback, useState } from "react";
import { SPOT_MASK_COLOR } from "../constant";
import { SingleColorImageExtension } from "../extension";
import { getOriginalImageAsset, getRasterizedFile, waitForImageToBeReady } from "../util";

type SingleColorProperties = {
    threshold: number;
    isInverted: boolean;
};

export const useSingleColorContrastProperties = () => {
    const designEngine = useDesignEngine();
    const { selectedItems } = useSelectedItems();
    const { assetStore } = useUploadManager();
    const { uploadFile } = useFileMethods();

    const selectImageItem = selectedItems[0];

    const singleColorExtension = designEngine.designExtensionSystem.getExtension(
        selectImageItem.iid,
        SingleColorImageExtension
    );

    if (!singleColorExtension) {
        throw new Error("SingleColorImageExtension must be provided");
    }

    const [rasterizedImageFile, setRasterizedImageFile] = useState<File>();
    const [originalAsset, setOriginalAsset] = useState<VistaAsset>();
    const [imageUrl, setImageUrl] = useState<string>();

    /**
     * Retrieves the page number of the selected image item, if applicable.
     *
     * @returns The page number of the selected image item, or `undefined` if the selected item is not an image or is on the first page.
     */
    const getSelectedItemPageNumber = useCallback(
        (asset: VistaAsset) => {
            if (selectImageItem.isImageItem()) {
                // If the asset is not a multipage asset, Assets-sdk, expects us not to pass in a page number.
                if (isAssetMultiPage(asset)) {
                    return selectImageItem.model.pageNumber;
                }
                return selectImageItem.model.pageNumber === 1 ? undefined : selectImageItem.model.pageNumber;
            }
            return undefined;
        },
        [selectImageItem]
    );

    /**
     * Generates rasterizedFile for preview.
     * And also stores the asset to create stacked monochromized variant of the asset.
     *
     */
    useAsyncEffect(
        helper => {
            helper.runIfMounted(async () => {
                try {
                    if (singleColorExtension.getOriginalImageUrl) {
                        let assetUrl;
                        const isInstantUpload = singleColorExtension.getOriginalImageUrl.startsWith("blob:");
                        if (isInstantUpload) {
                            assetUrl = singleColorExtension.getOriginalImageUrl;
                        } else if (assetStore) {
                            const asset = await getOriginalImageAsset({
                                assetStore,
                                originalUrl: singleColorExtension.getOriginalImageUrl,
                                uploadFile
                            });

                            if (asset) {
                                setOriginalAsset(asset);
                                assetUrl = asset.webPreview.getUrl({
                                    pageNum: getSelectedItemPageNumber(asset)
                                });
                                await waitForImageToBeReady(assetUrl, "generating image preview");
                            }
                        }
                        if (assetUrl) {
                            const imageFilePreview = await getRasterizedFile(assetUrl);
                            setImageUrl(assetUrl);
                            setRasterizedImageFile(imageFilePreview);
                        }
                    }
                } catch (error) {
                    newRelicWrapper.noticeError(
                        new Error("Error in getting getOriginalImageUrl for single color image"),
                        {
                            message: (error as Error).message
                        }
                    );
                }
            });
        },
        [assetStore, getSelectedItemPageNumber, selectImageItem, singleColorExtension.getOriginalImageUrl, uploadFile]
    );

    // generates vectorized monochrome url for the given threshold & invert-status
    const getMonochromeUrl = useCallback(
        async ({ isInverted, threshold }: SingleColorProperties) => {
            // asset-sdk expects color to be in hex format without #
            const color = SPOT_MASK_COLOR.replace("#", "");
            // asset-sdk expects threshold to be between 0 - 1
            const monoChromeThreshold = threshold / 100;
            try {
                return await originalAsset?.getStackedVariantUrlV2(
                    {
                        variant1: "monochrome",
                        variant2: "vector",
                        includeSignature: true,
                        pageNum: getSelectedItemPageNumber(originalAsset)
                    },
                    {
                        monochromeColorParams: {
                            color,
                            threshold: monoChromeThreshold,
                            inverted: isInverted
                        }
                    }
                );
            } catch (error) {
                // handle error so that the experience does not break
                newRelicWrapper.noticeError(new Error("Error in getting stacked variant from assets sdk"), {
                    message: (error as Error).message
                });
            }
            return undefined;
        },
        [getSelectedItemPageNumber, originalAsset]
    );

    return {
        rasterizedImageFile,
        getMonochromeUrl,
        imageUrl
    };
};

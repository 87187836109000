import React from "react";
import type { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { GraphicsIcon } from "@internal/ui-studio-chrome";
import { useIsEmbroidery } from "@internal/utils-deco-tech";
import { useDesignEngine, useActivePanel } from "@design-stack-vista/core-features";
import { useAllowVectors } from "@shared/features/IconAssetLibrary";
import { ShapesContentPanelDialogTypes } from "../ShapesContentPanelDialogTypes";

const messages = defineMessages({
    shapesTitle: {
        id: "editorUI.addContent.panel.shapes.title",
        defaultMessage: "Shapes",
        description: {
            note: "Content Panel title text for adding shapes"
        }
    },
    graphicsTitle: {
        id: "editorUI.addContent.panel.graphics.title",
        defaultMessage: "Graphics",
        description: {
            note: "Content Panel title text for adding graphics"
        }
    },
    shapesTitleEmbroidery: {
        id: "editorUI.addContent.panel.shapes.titleEmbroidery",
        defaultMessage: "Icons",
        description: {
            note: "Embroidery Content Panel title text for adding shapes (icons)"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ children }) => {
    const { t } = useTranslationSSR();
    const isEmbroidery = useIsEmbroidery();
    const designEngine = useDesignEngine();
    const { layoutStore } = designEngine;
    const { activePanel } = useActivePanel();
    const targetPanel = activePanel ?? layoutStore.visiblePanels[0];
    const allowVectors = useAllowVectors(targetPanel);

    /**
     * Three different button titles:
     * If the product is for embroidery, we use "Icons".
     * If the product allows vectors (which is now the default for Studio), we use "Graphics".
     * If the product doesn't allow vectors (such as single color), we use "Shapes".
     */
    return children({
        icon: <GraphicsIcon aria-hidden="true" />,
        label: isEmbroidery
            ? t(messages.shapesTitleEmbroidery.id)
            : t(allowVectors ? messages.graphicsTitle.id : messages.shapesTitle.id)
    });
};

export const shapesAndGraphicsContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-graphics-and-shapes",
    core: {
        GraphicsAndShapes: {
            activeDialogType: ShapesContentPanelDialogTypes.graphics,
            component: CDZButton
        }
    },
    deferredLoader: () => import("./index.deferred")
};

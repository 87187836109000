import {
    getGeneralTrackingData,
    getQueryStringTrackingData,
    getStudioTrackingMetadata,
    shouldTrack
} from "@shared/utils/Tracking";
import { windowExists, isDebugMode } from "@internal/utils-browser";
import { Store } from "@shared/redux";
import {
    BaseTrackingClient,
    Events,
    ExperimentClickedPayload,
    ExtraData,
    TrackingFunctionParameters,
    UserInteractionPayload
} from "@internal/utils-tracking";
import { productAndProjectStateManager } from "@internal/utils-product-and-project-state";

// ToDo: Improve types
export function getTrackingInfoBase(pageNameBase = "Studio", isErrorPage = false) {
    const currentState = Store.getState();
    const { mpvId, locale } = productAndProjectStateManager.data;

    const studioVersion = currentState.tracking?.dexName;

    return {
        currentState,
        trackingBase: {
            pageName: isErrorPage
                ? `${pageNameBase || "Studio"}:ErrorPage` // pageName for the Error Page does not have an associated mpvId
                : `${mpvId}:${pageNameBase || "Studio"}`, // pageName is of the format mpvID:pageSection
            pageSection: "Studio", // Section of the page, which will correspond to the functional side of it
            pageStage: "Design", // Stage of the page, which correspond to what we want the customer do in the website
            languageLocale: locale, // 2 chars for language (without the notion of a dialect/version of the language)
            // and 2 chars for the locale
            studioVersion
        }
    };
}

interface TrackingBase {
    pageName: string; // pageName is of the format mpvID:pageSection
    pageSection: string; // Section of the page, which will correspond to the functional side of it
    pageStage: string; // Stage of the page, which correspond to what we want the customer do in the website
    languageLocale: string; // 2 chars for language (without the notion of a dialect/version of the language)
}

function firePageEvent(trackingBase: TrackingBase) {
    if (!windowExists()) {
        return;
    }
    window.tracking.page(trackingBase);
}

interface TrackAndPageEvent {
    label: string;
    pageNameBase?: string;
    event: any;
    eventDetail?: string;
    timeSinceLoad: any;
    extraData: any;
    firePage: any;
    addRoute: any;
    category?: string;
}

function fireTrackAndPageEvent({
    label,
    pageNameBase,
    event,
    eventDetail,
    timeSinceLoad,
    extraData,
    firePage,
    addRoute,
    category
}: TrackAndPageEvent) {
    const { currentState, trackingBase } = getTrackingInfoBase(pageNameBase);
    const {
        mpvId,
        workId,
        workRevisionId,
        productName,
        isFullBleed,
        productVersion,
        productKey,
        studioSelectedProductOptions,
        template,
        documentUrl
    } = productAndProjectStateManager.data;

    if (firePage) {
        firePageEvent(trackingBase);
    }
    window.tracking.track(event || "Product Viewed", {
        category, // Category influences whether an event is routed to GA.
        label, // the event label name
        eventDetail, // detail of events
        pageSection: trackingBase.pageSection, // the section of the page
        pageStage: trackingBase.pageStage, // the stage of the page
        pageName: trackingBase.pageName, // detailed name of the page where the event has been sent
        studioVersion: trackingBase.studioVersion,
        product_id: mpvId, // MPVID
        workId, // Work Id, if available
        workRevisionId,
        documentUrl, // Doc URL if available
        name: productName, // Detailed Product Name / MPV
        template: isFullBleed ? "FullBleed" : template,
        core_product_id: productKey, // Set to custom dimension
        core_product_version: productVersion ? Number(productVersion) : undefined,
        product_options: JSON.stringify(studioSelectedProductOptions),
        ...(extraData instanceof Function ? extraData(currentState) : extraData),
        ...getGeneralTrackingData(),
        route: addRoute && "studio",
        timeSinceLoad: timeSinceLoad || performance.now(), // time since load
        ...getStudioTrackingMetadata(),
        ...getQueryStringTrackingData()
    });
}

interface TrackingEvent {
    label: string;
    pageNameBase?: string;
    event?: any;
    eventDetail?: string;
    extraData?: any;
    firePage?: any;
    addRoute?: any;
    category?: string;
    timeSinceLoad?: number;
}

function fireTrackingEvent({
    label,
    pageNameBase,
    event,
    eventDetail,
    extraData,
    firePage,
    addRoute,
    category,
    timeSinceLoad
}: TrackingEvent) {
    if (!windowExists()) {
        return;
    }
    if (window.tracking && window.tracking.page && window.tracking.track) {
        fireTrackAndPageEvent({
            label,
            pageNameBase,
            event,
            eventDetail,
            extraData,
            firePage,
            addRoute,
            category,
            timeSinceLoad
        });
    } else {
        window.addEventListener("trackingReady", () => {
            fireTrackAndPageEvent({
                label,
                pageNameBase,
                event,
                eventDetail,
                extraData,
                firePage,
                addRoute,
                category,
                timeSinceLoad
            });
        });
    }
}

function fireTrackingEventWithStudioCategory({
    label,
    pageNameBase,
    event,
    eventDetail,
    extraData,
    firePage,
    addRoute,
    timeSinceLoad
}: TrackingEvent) {
    const category = "Studio";
    fireTrackingEvent({
        label,
        pageNameBase,
        event,
        eventDetail,
        extraData,
        firePage,
        addRoute,
        category,
        timeSinceLoad
    });
}

interface GenericTrackingEvent {
    event?: string;
    eventDetail: string;
    label?: string;
    pageNameBase?: string | null;
    extraData?: any | null;
    timeSinceLoad?: number;
}
/**
 * Fires a generic studio tracking event.
 * The 'event' parameter will route the event to the correct snowflake table
 * EventDetail will correspond to the specific action user performed.
 * extraData is a function that takes the current state as a parameter
 *
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireGenericTrackingEvent({
    event,
    eventDetail,
    label = undefined,
    pageNameBase = undefined,
    extraData = undefined,
    timeSinceLoad = undefined
}: GenericTrackingEvent) {
    fireTrackingEventWithStudioCategory({
        event: event || "Studio Tracking",
        eventDetail: eventDetail || "Generic Event",
        label: label || "General",
        pageNameBase: pageNameBase || "Studio",
        extraData,
        firePage: false,
        addRoute: true,
        timeSinceLoad
    });
}

interface PerformanceTrackingEvent {
    eventDetail: string;
    label?: string;
    extraData?: any;
    timeSinceLoad?: number;
}

/**
 * Use for performance events - includes built-in sampling
 *
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function firePerformanceTrackingEvent({
    eventDetail,
    label = undefined,
    extraData = undefined,
    timeSinceLoad = undefined
}: PerformanceTrackingEvent) {
    if (shouldTrack()) {
        fireTrackingEventWithStudioCategory({
            event: "Studio Performance",
            eventDetail,
            label: label || "General",
            extraData,
            firePage: false,
            addRoute: true,
            timeSinceLoad
        });
    }
}
interface BasicTrackingEvent {
    eventDetail: any;
    label?: string;
    pageNameBase?: string | null;
    extraData?: string | Function | null;
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireDesignToolTrackingEvent({
    eventDetail,
    label = undefined,
    pageNameBase = undefined,
    extraData = undefined
}: BasicTrackingEvent) {
    fireGenericTrackingEvent({ event: "Design Tool Used", eventDetail, label, pageNameBase, extraData });
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireLoginTrackingEvent({ eventDetail, label, pageNameBase, extraData }: BasicTrackingEvent) {
    fireGenericTrackingEvent({ event: "Studio Login", eventDetail, label, pageNameBase, extraData });
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireStudioTrackingEvent() {
    const label = "Studio page view";
    const pageNameBase = "Studio";

    fireTrackingEventWithStudioCategory({ label, pageNameBase, firePage: true });

    // this way we have an event similar to product viewed (review page), but we control the schema
    fireGenericTrackingEvent({
        eventDetail: "Studio Page",
        label: "Studio page View",
        pageNameBase
    });
}

interface ReviewPageTrackingEvent {
    extraData: any;
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireReviewPageTrackingEvent({ extraData }: ReviewPageTrackingEvent) {
    const label = "Studio review page view";
    const pageNameBase = "Studio:Review";

    fireTrackingEventWithStudioCategory({ label, pageNameBase, firePage: true, extraData });

    // this way we have an event similar to product viewed (studio), but we control the schema
    fireGenericTrackingEvent({
        eventDetail: "Studio Review Page",
        label: "Studio review page view",
        pageNameBase,
        extraData
    });
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireAddToCartPageTrackingEvent() {
    const event = "Product Added";
    const label = "Product Added";
    const pageNameBase = "Studio:Review";
    const { quantity } = productAndProjectStateManager.data;

    fireTrackingEvent({
        label,
        pageNameBase,
        event,
        firePage: true,
        extraData: () => ({
            price: 0.0,
            list_price: 0,
            quantity: 1,
            sales_quantity: quantity,
            discount: 0
        })
    });
}

interface UpdateCartTrackingEvent {
    extraData: any;
}

/**
 * Fires when an item in cart is edited
 *
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireUpdateCartTrackingEvent({ extraData }: UpdateCartTrackingEvent) {
    fireGenericTrackingEvent({
        eventDetail: "Studio Update Cart",
        label: "Studio Update Cart",
        extraData
    });
}

/**
 * Fires a tracking event to the site-wide user interactions tracking table.
 * pageSection, pageStage, and pageName should be captured implicitly by the tracking API via the page’s meta tags,
 * The 'eventName' parameter corresponds to the action within Studio being tracked.
 * The 'timing' parameter tracks how many ms elapsed for the event.
 * extraData is an object of additional data we would like to track (if any).
 *
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireUserInteractionTrackingEvent({ eventName, timing, extraData }: UserInteractionPayload) {
    if (shouldTrack()) {
        const { productKey } = productAndProjectStateManager.data;
        const { trackingBase } = getTrackingInfoBase("studio");
        const eventDetails = {
            coreProductId: productKey,
            debugMode: isDebugMode(),
            ...extraData
        };
        window.tracking?.track("Interaction Timed", {
            eventName,
            timing,
            pageSection: trackingBase.pageSection, // the section of the page
            pageStage: trackingBase.pageStage, // the stage of the page
            pageName: trackingBase.pageName, // detailed name of the page where the event has been sent
            studioVersion: trackingBase.studioVersion,
            eventDetail: JSON.stringify(eventDetails)
        });
    }
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireStudioProjectSavedTrackingEvent({ extraData = undefined }: { extraData?: ExtraData }) {
    fireGenericTrackingEvent({
        event: "Studio Project Saved",
        eventDetail: "Studio project saved",
        label: "Studio project saved",
        extraData
    });
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireStudioDiagnosticTrackingEvent({ eventDetail, label, extraData }: BasicTrackingEvent) {
    fireGenericTrackingEvent({ event: Events.StudioDiagnostic, eventDetail, label, extraData });
}

/**
 * @deprecated Use the new {@link TrackingClient} class instead
 */
export function fireExperimentClickedTrackingEvent(
    experimentDetail: string,
    experimentData: ExperimentClickedPayload["experimentData"],
    eventDetail?: string,
    navigationDetail?: string
) {
    const { mpvId, productName, productVersion, productKey } = productAndProjectStateManager.data;

    if (window?.tracking?.page && window?.tracking?.track) {
        const { trackingBase } = getTrackingInfoBase();

        window.tracking.track("Experiment Clicked", {
            label: `${trackingBase.pageSection} page view`,
            experimentDetail,
            pageSection: trackingBase.pageSection, // the section of the page where the interaction happened
            pageStage: trackingBase.pageStage, // the stage of the page where the interaction happened
            pageName: trackingBase.pageName, // detailed name of the page where the interaction happened
            studioVersion: trackingBase.studioVersion,
            trackingTenant: "vistaprint",
            navigationDetail,
            eventDetail,
            ...experimentData,
            product_id: mpvId,
            name: productName,
            core_product_id: productKey,
            core_product_version: productVersion
        });
    }
}

/**
 * This is meant to serve as a drop in replacement for the individual tracking functions, but with
 * a simplier interface and improved typesafety.
 */
export class TrackingClient extends BaseTrackingClient {
    // eslint-disable-next-line class-methods-use-this
    track<T extends Events>(...args: TrackingFunctionParameters<T>) {
        const [event, payload] = args;

        /**
         * We are wrapping the existing tracking functions in order to mitigate the risk of breaking the current
         * tracking behavior while also provided a better user experience with this new interface.
         *
         * This will prevent the accumulation of more technical debt in Studio 6, but we should also look to
         * revist this soon with a complete refactor that is heavily unit tested. Upon doing so we can also
         * start forwarding the dexName provided in constructor instead of relying on the Redux store.
         */
        if (event === Events.StudioPerformance) {
            firePerformanceTrackingEvent(payload);
        } else if (event === Events.DesignToolUsed) {
            fireDesignToolTrackingEvent(payload);
        } else if (event === Events.StudioPageView) {
            fireStudioTrackingEvent();
        } else if (event === Events.StudioReviewPage) {
            fireReviewPageTrackingEvent(payload);
        } else if (event === Events.ProductAdded) {
            fireAddToCartPageTrackingEvent();
        } else if (event === Events.StudioUpdateCart) {
            fireUpdateCartTrackingEvent(payload);
        } else if (event === Events.InteractionTimed) {
            fireUserInteractionTrackingEvent(payload);
        } else if (event === Events.StudioProjectSaved) {
            fireStudioProjectSavedTrackingEvent(payload);
        } else if (event === Events.ExperimentClicked) {
            fireExperimentClickedTrackingEvent(
                payload.experimentDetail,
                payload.experimentData,
                payload.eventDetail,
                payload.navigationDetail
            );
        } else if (event === Events.StudioDiagnostic) {
            fireStudioDiagnosticTrackingEvent(payload);
        } else if (event === Events.StudioLogin) {
            fireLoginTrackingEvent(payload);
        } else if (event === Events.StudioInstantUploadPreview) {
            fireGenericTrackingEvent({
                event: Events.StudioInstantUploadPreview,
                eventDetail: payload.eventDetail,
                extraData: { timing: payload.timing }
            });
        } else {
            fireGenericTrackingEvent(payload);
        }
    }
}

import type { DSS, MCP } from "@vp/types-ddif";
import type { Panel } from "@design-stack-vista/cdif-types";
import cloneDeep from "lodash/cloneDeep";
import type { DesignSpecification } from "@internal/data-access-calcifer";

/**
 * Determines whether the document panels should to be updated to match the surfaces
 */
function shouldUpdatePanels(panels: Panel[], views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]) {
    return panels.some((panel, index) => {
        const view = views[index];
        if (view) {
            return panel.id !== view.id || panel.name !== view.name || !panel.colorMode;
        }
        return false;
    });
}

/**
 * Updates document panels to match the surface ids and names
 */
export function updatePanels(
    cimDoc: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[],
    designDocument: DSS.DesignDocument
) {
    const updatedCimDoc = cloneDeep(cimDoc);

    const viewNames = views.map(s => s.name?.toLowerCase());
    const panelNames = cimDoc.document.panels.map(p => p.name?.toLowerCase());
    const nameMatches = viewNames.map(viewName => panelNames.indexOf(viewName)).filter(value => value >= 0);

    if (nameMatches.length === views.length) {
        // names match, just out of order (happen with folded products sometimes, you get front/back/inside)
        const newPanels = viewNames.map(viewName => {
            const view = views.find(view => view.name.toLowerCase() === viewName)!;
            const panel = cimDoc.document.panels.find(panel => panel.name?.toLowerCase() === viewName)!;
            const designDocumentColorMode = designDocument.document.panels.find(p => p.name === view.name)!.colorMode;
            // need to update document source, though they don't need to be in order
            const panelSource = updatedCimDoc.metadata?.documentSources?.panels.find(
                panelSource => panelSource.id === panel.id
            );
            if (panelSource) {
                panelSource.id = view.id;
            }
            return { ...panel, id: view.id, name: view.name, colorMode: designDocumentColorMode };
        });
        updatedCimDoc.document.panels = newPanels;
    } else {
        // just try to fix via index comparison
        updatedCimDoc.document.panels = cimDoc.document.panels.map((panel, index) => {
            const view = views[index];
            if (view) {
                const designDocumentColorMode = designDocument.document.panels[index].colorMode;
                return { ...panel, id: view.id, name: view.name, colorMode: designDocumentColorMode };
            }
            return panel;
        });
        if (updatedCimDoc.metadata?.documentSources?.panels) {
            updatedCimDoc.metadata.documentSources.panels = cimDoc.metadata!.documentSources!.panels.map(
                (panel, index) => {
                    if (views[index]) {
                        return { ...panel, id: views[index].id };
                    }
                    return panel;
                }
            );
        }
    }
    return updatedCimDoc;
}

/**
 * Updates document to match the surface/view ids & names as some services/code expect them to match
 * @param cimDoc
 * @param designSpecification
 * @returns
 */
export function checkForPanelMetadataDrift(cimDoc: DSS.DesignDocument, designSpecification: DesignSpecification) {
    if (shouldUpdatePanels(cimDoc.document.panels, designSpecification.views)) {
        cimDoc = updatePanels(cimDoc, designSpecification.views, designSpecification.designDocument);
    }
    return cimDoc;
}

import React from "react";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import type { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { PremiumFinishIcon } from "@internal/ui-studio-chrome";
import { PremiumFinishDialogTypes } from "../PremiumFinishDialogTypes";
import { useFullBleedPremiumFinish } from "../fullBleedPremiumFinish";

const messages = defineMessages({
    premiumFinishTitle: {
        id: "editorUI.addContent.drawer.premiumFinish.title",
        defaultMessage: "Finishes",
        description: {
            note: "Content Panel title text for premium finish button"
        }
    }
});

const CDZButtonPremiumFinish: ContentDiscoveryZoneButton = ({ screenSize, children, activeDialogType }) => {
    const { t } = useTranslationSSR();

    const { isButtonVisible: isFullBleedPremiumFinishButtonVisible } = useFullBleedPremiumFinish();

    if (
        screenSize === "largeScreen" &&
        !isFullBleedPremiumFinishButtonVisible &&
        activeDialogType === PremiumFinishDialogTypes.premiumFinishes
    ) {
        return children({
            icon: <PremiumFinishIcon aria-hidden="true" />,
            label: t(messages.premiumFinishTitle.id)
        });
    }

    return null;
};

const CDZButtonFullBleedPremiumFinish: ContentDiscoveryZoneButton = ({ screenSize, children, activeDialogType }) => {
    const { t } = useTranslationSSR();

    const { isButtonVisible: isFullBleedPremiumFinishButtonVisible } = useFullBleedPremiumFinish();

    if (
        screenSize === "largeScreen" &&
        isFullBleedPremiumFinishButtonVisible &&
        activeDialogType === PremiumFinishDialogTypes.fullBleedPremiumFinish
    ) {
        return children({
            icon: <PremiumFinishIcon aria-hidden="true" />,
            label: t(messages.premiumFinishTitle.id)
        });
    }

    return null;
};

export const premiumFinishContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-premium-finish",
    core: {
        Finish: [
            { activeDialogType: PremiumFinishDialogTypes.premiumFinishes, component: CDZButtonPremiumFinish },
            {
                activeDialogType: PremiumFinishDialogTypes.fullBleedPremiumFinish,
                component: CDZButtonFullBleedPremiumFinish
            }
        ]
    },
    deferredLoader: () => import("./index.deferred")
};

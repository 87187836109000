import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ShowReplaceImageModalState {
    showReplaceImageModal: boolean;
    isReplaceOpenOnCanvasChange: boolean;
    isUploadModalAutoLoaded: boolean;
}

const showReplaceImageModalSlice = createSlice({
    name: "showReplaceImageModal",
    initialState: false,
    reducers: {
        setShowReplaceImageModal: (state, action: PayloadAction<ShowReplaceImageModalState>) =>
            action.payload.showReplaceImageModal
    }
});

export const { setShowReplaceImageModal } = showReplaceImageModalSlice.actions;
export const showReplaceImageModal = showReplaceImageModalSlice.reducer;

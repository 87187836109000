import React from "react";
import { GenericHeaderButton } from "@internal/ui-studio-chrome";
import { Icon } from "@vp/swan";
import { useLocalized } from "../../localized";
import { useEngraving } from "../../hooks/EngravingProvider";

export const EngravingTipsButton = ({ className }: { className?: string }) => {
    const localized = useLocalized();
    const { openEngravingGuidelineModal } = useEngraving();

    const label = localized.get("engravingTipsButton");

    return (
        <GenericHeaderButton
            data-translationid={label}
            onClick={openEngravingGuidelineModal}
            label={label}
            icon={<Icon iconType="info" aria-hidden />}
            className={className}
        />
    );
};

EngravingTipsButton.displayName = "EngravingTipsButton";

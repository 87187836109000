import type { StudioDesignExperienceExtension } from "@internal/utils-studio-configuration-core";

const hasAiTemplatesEnabled: StudioDesignExperienceExtension = {
    hasAiTemplatesEnabled: [
        {
            conditions: {
                value: true
            },
            overrides: {
                defaultSlotName: "Designs",
                openSelectImageModalOnLoad: false
            }
        }
    ]
};

export default hasAiTemplatesEnabled;

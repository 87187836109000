import React from "react";
import { SimpleButtonProps, ToolbarButton } from "@internal/ui-studio-chrome";
import classNames from "classnames";
import { FillColorsIcon } from "../FillColorsIcon/FillColorsIcon";
import styles from "./MultiColorPickerToolbarButton.module.scss";
import { useStudioLayout } from "@internal/feature-responsive-design";

interface MultiColorPickerToolbarButtonProps extends SimpleButtonProps {
    label: string;
    isSelected: boolean;
    colors: string[];
}
export function MultiColorPickerToolbarButton({
    className,
    label,
    isSelected = false,
    colors = [],
    ...rest
}: MultiColorPickerToolbarButtonProps) {
    const { isSmall } = useStudioLayout();

    return (
        <>
            {isSmall && (
                <ToolbarButton
                    className={classNames(styles.smallScreenToolbarButton, className)}
                    isSelected={isSelected}
                    {...rest}
                >
                    <FillColorsIcon colors={colors} />
                    <label>{label}</label>
                </ToolbarButton>
            )}
            {!isSmall && (
                <ToolbarButton
                    className={classNames(styles.colorPickerButton, className)}
                    isSelected={isSelected}
                    {...rest}
                >
                    <FillColorsIcon colors={colors} />
                    <div className="dcl-ctx-button-label">{label}</div>
                </ToolbarButton>
            )}
        </>
    );
}
MultiColorPickerToolbarButton.displayName = "MultiColorPickerToolbarButton";

import classNames from "classnames";
import React from "react";
import { CMYK, cmyk2hex } from "@design-stack-vista/utility-core";
import { Slider } from "./Slider";
import { BasicSliderProps } from "./types";

export interface CMYKColorSliderProps extends BasicSliderProps {
    cmykColor: CMYK;
    cmykAttribute: "c" | "m" | "y" | "k";
}

export function CMYKColorSlider(props: CMYKColorSliderProps) {
    const { cmykColor, cmykAttribute, className, ...rest } = props;
    const beginGradient = cmyk2hex({ ...cmykColor, [cmykAttribute]: 0 });
    const endGradient = cmyk2hex({ ...cmykColor, [cmykAttribute]: 100 });

    return (
        <Slider
            className={classNames(className, "new-slider-style")}
            max={100}
            value={cmykColor[cmykAttribute]}
            barStyle={{
                background: `linear-gradient(to right,
            ${beginGradient} 0%,
            ${endGradient} 100%)`
            }}
            startSwatchColor={beginGradient}
            endSwatchColor={endGradient}
            {...rest}
        />
    );
}

CMYKColorSlider.displayName = "CMYKColorSlider";

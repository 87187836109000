/* eslint-disable import/prefer-default-export */
import React from "react";
import { isLocalHost } from "@internal/utils-browser";
import Cookies from "js-cookie";
import { DefaultExperienceWrapper as StudioSixDefaultExperienceWrapper } from "./src/studioSix/defaultExperience/DefaultExperienceWrapper";

if (isLocalHost()) {
    /**
     * This silences the ctc-reader warnings we see when running localhost
     * See https://gitlab.com/vistaprint-org/personalization/content-personalization/customer-treatment-configurator/decision-engine-reader#local-development
     */
    Cookies.set(
        "userContext",
        // eslint-disable-next-line max-len
        "{%22ctc%22:{%22v1%22:[%22CH_Consumer_products_Tests_2%22%2C%22CH_Consumer_products_Tests%22%2C%22employee_discount%22%2C%22Winning_Outdoors_15Off%22%2C%22design_services_hub_inspiration%22%2C%22buyers_sg_full_width%22%2C%22site_tagging_repeat_customer%22%2C%22registered_repeat_user_hero%22]}%2C%22userProfile%22:{%22v1%22:{%22couponCode%22:%22NEW25%22%2C%22industry_l1_id%22:%22d4f8659d324d47f39fbaa5e247b73204%22%2C%22culture%22:%22en-US%22}}}",
        { domain: "localhost" }
    );
}

export const wrapPageElement = ({ element, props }) => {
    return <StudioSixDefaultExperienceWrapper {...props}>{element}</StudioSixDefaultExperienceWrapper>;
};
wrapPageElement.displayName = "wrapPageElement";

export const onClientEntry = () => {
    if (typeof window.IntersectionObserver === "undefined") {
        // this is used by visage
        // eslint-disable-next-line no-unused-expressions
        import("intersection-observer");
    }

    // rIC shims from google https://developer.chrome.com/blog/using-requestidlecallback#checking_for_requestidlecallback
    // we could simply check for rIC existence but this gives control back to the browser to resolve any UI stuff until setTimeout fires
    // which at least partially does what we want for rIC
    window.requestIdleCallback =
        window.requestIdleCallback ||
        function requestIdleCallbackShim(cb) {
            const start = Date.now();
            return setTimeout(() => {
                cb({
                    didTimeout: false,
                    timeRemaining() {
                        return Math.max(0, 50 - (Date.now() - start));
                    }
                });
            }, 1);
        };

    window.cancelIdleCallback =
        window.cancelIdleCallback ||
        function cancelIdleCallbackShim(id) {
            clearTimeout(id);
        };

    // everything else taken from @wardpeet/gatsby-plugin-static-site because the plugin doesn't work well with newer versions of gatsby.
    // There is a PR there waiting to be merged
    // eslint-disable-next-line no-underscore-dangle
    const loader = window.___loader;

    // if development or no loader exists we shouldn't do anything
    if (process.env.NODE_ENV === "development" || !loader) {
        return;
    }

    const { pagePath } = window;
    const { location } = window;

    if (pagePath && pagePath !== location.pathname && pagePath !== `${location.pathname}/`) {
        const originalLoadPageSync = loader.loadPageSync;
        const originalLoadPage = loader.loadPage;

        loader.loadPageSync = path => {
            let pageResources;
            // With Gatsby V4, path has been appended with window.location.search when invoking loadPageSync
            const pathWithoutQueryParams = path.replace(location.search, "");

            // if the pathWithoutQueryParams is the same as our current page we know it's not a prefetch
            if (pathWithoutQueryParams === location.pathname) {
                pageResources = originalLoadPageSync(pagePath);
            } else {
                pageResources = originalLoadPageSync(pathWithoutQueryParams);
            }

            if (pageResources.page) {
                pageResources.page.matchPath = "*";
            }

            return pageResources;
        };

        loader.loadPage = path => {
            let pageResources;
            // With Gatsby V4, path has been appended with window.location.search when invoking loadPage
            const pathWithoutQueryParams = path.replace(location.search, "");

            // if the pathWithoutQueryParams is the same as our current page we know it's not a prefetch
            if (pathWithoutQueryParams === location.pathname) {
                pageResources = originalLoadPage(pagePath);
            } else {
                pageResources = originalLoadPage(pathWithoutQueryParams);
            }

            if (pageResources.page) {
                pageResources.page.matchPath = "*";
            }

            return pageResources;
        };
    }

    // disable hovering prefetching as we don't know if we can.
    loader.hovering = () => {};
};

// Also from @wardpeet/gatsby-plugin-static-site
export const disableCorePrefetching = () => true;

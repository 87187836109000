export enum FlexibilityDesignAttributes {
    PremiumFinish = "Finish",
    Size = "Size",
    Trim = "Trim",
    Quantity = "Quantity"
}

export enum TrimIconTypes {
    STANDARD = "Standard",
    ROUNDED = "Rounded"
}

import { isCareAgent, isShowWarningsMode } from "@internal/utils-browser";
import { setScenesConfiguration, useAppDispatch } from "@shared/redux";
import React, { useCallback } from "react";
import { setHasDesigns } from "@shared/redux/slices/hasDesigns";
import { batch } from "react-redux";
import { CalciferSceneConfiguration } from "@internal/data-access-calcifer";
import { ProductLoadingProvider } from "@internal/dex";
import { useToastManager } from "@internal/feature-toasts";
import { WarningContent, ToastCareMessage, calcMessageReadingTime } from "@shared/features/SystemMessages";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { newRelicWrapper } from "@internal/utils-newrelic";

// Wraps the PLP to provide access to redux until dependencies are extracted
export function ProductLoadingProviderWrapper({ children }: React.PropsWithChildren) {
    const dispatch = useAppDispatch();
    const toastManager = useToastManager();
    const advancedTools = useIsFlagEnabled("advancedTools");

    const showWarnings = useCallback(
        (warnings: string[]) => {
            if (isShowWarningsMode() && warnings.length > 0) {
                const warningTimeout = calcMessageReadingTime(warnings.join(""));

                toastManager.pushToast({
                    skin: "warning",
                    autoClose: true,
                    autoCloseTimeOutDuration: warningTimeout,
                    content: <WarningContent warnings={warnings} />
                });
            }
        },
        [toastManager]
    );

    const showAdjustmentAlert = useCallback(
        (changes: string) => {
            toastManager.pushToast({
                autoClose: false,
                content: <ToastCareMessage careOnlyMessage={changes} />,
                skin: "standard"
            });
        },
        [toastManager]
    );

    const showCareOnlyAlert = useCallback(
        (careOnlyMessage: string, productKey?: string) => {
            const showCareMessages = isCareAgent() || advancedTools;

            newRelicWrapper.addPageAction("studioCareOnlyErrorShown", {
                errorMessage: careOnlyMessage,
                productKey
            });

            if (showCareMessages) {
                toastManager.pushToast({
                    autoClose: false,
                    content: careOnlyMessage,
                    skin: "error"
                });
            }
        },
        [toastManager, advancedTools]
    );

    const setRemainingDefaultState = useCallback(
        (scenesConfiguration: CalciferSceneConfiguration | undefined, hasDesigns: boolean) => {
            batch(() => {
                if (scenesConfiguration) {
                    dispatch(setScenesConfiguration(scenesConfiguration));
                }
                dispatch(setHasDesigns(hasDesigns));
            });
        },
        [dispatch]
    );

    return (
        <ProductLoadingProvider
            showWarnings={showWarnings}
            showAdjustmentAlert={showAdjustmentAlert}
            showCareOnlyAlert={showCareOnlyAlert}
            setRemainingDefaultState={setRemainingDefaultState}
        >
            {children}
        </ProductLoadingProvider>
    );
}
ProductLoadingProviderWrapper.displayName = "ProductLoadingProviderWrapper";

import React from "react";
import { lazily } from "react-lazily";
import { ERROR_CODES, debouncedReportRawErrorToSegment } from "@internal/utils-errors";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getQueryParams, addQueryParam, windowExists, isLocalHost } from "@internal/utils-browser";
import { ErrorPage } from "@shared/features/Errors";
import type { Save } from "@internal/data-access-work-entity-service";
import { BaseTrackingClient } from "@internal/utils-tracking";

const { StudioSixDebugTools } = lazily(() => import("../features/Debug"));

const PREVENT_RELOAD_CYCLE = 7000;
const AUTO_REFRESH_WAIT_TIME = 2000;

type StudioErrorBoundaryProps = {
    onComponentDidCatch?: (error: Error, errorInfo: React.ErrorInfo) => void;
    save?: Save;
    trackingClient: BaseTrackingClient;
};

function addErrorTimingToURL() {
    if (windowExists()) {
        window.location.href = addQueryParam(window.location.href, "pe", Date.now().toString());
    }
}

function reloadAfterTimeout(errorTime: number) {
    const difference = Date.now() - errorTime;
    // if the save happens quickly the error page flashes for only a few hundred milliseconds, and it is jarring
    if (difference < AUTO_REFRESH_WAIT_TIME) {
        setTimeout(addErrorTimingToURL, AUTO_REFRESH_WAIT_TIME - difference);
    } else {
        addErrorTimingToURL();
    }
}

const errorCode = `${ENTITY_CODE}-${ERROR_CODES.ERROR_BOUNDARY}`;

// Lint is not familiar with render prop patterns
// and thinks the error boundary doesn't have a display name
// eslint-disable-next-line react/display-name
export class Studio6ErrorBoundary extends React.Component<
    React.PropsWithChildren<StudioErrorBoundaryProps>,
    { hasError: boolean; errorMessage: string }
> {
    constructor(props: StudioErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: "" };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message || error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // errorInfo includes the componentStack
        newRelicWrapper.noticeError(error, errorInfo);
        const errorMessage = error.message || error;
        newRelicWrapper.addPageAction("studio-errorpage", {
            errorCode,
            errorMessage
        });
        debouncedReportRawErrorToSegment(
            errorMessage.toString(),
            errorCode,
            error?.stack ?? "",
            this.props.trackingClient,
            true
        );

        // first make sure this isn't a loop (react crashes on load, perhaps)
        const errorTime = Date.now();
        if (windowExists() && !isLocalHost()) {
            const previousError = getQueryParams().pe;
            // if we don't have a previous error, or the previous error was a long time ago, then try to save & reload
            if (!previousError || errorTime - previousError > PREVENT_RELOAD_CYCLE) {
                // try to reload, and try to save if designer is loaded
                if (this.props.save) {
                    this.props
                        .save()
                        .then(s => {
                            reloadAfterTimeout(errorTime);
                        })
                        .catch(err => {
                            newRelicWrapper.noticeError(err, { errorBoundary: true });
                            reloadAfterTimeout(errorTime);
                        });
                } else {
                    reloadAfterTimeout(errorTime);
                }
            }
        }
    }

    DebugToolbar = StudioSixDebugTools;

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPage
                    errorCodeOverride={errorCode}
                    errorMessageOverride={this.state.errorMessage}
                    DebugToolbar={this.DebugToolbar}
                />
            );
        }

        return this.props.children;
    }
}

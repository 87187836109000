import { defineMessages } from "@vp/i18n-helper";

export const changeProductSizeMessages = defineMessages({
    changeProductSizeButtonTitle: {
        id: "studio.components.changeProductSize.changeProductSizeButtonTitle",
        defaultMessage: "Change size",
        description: {
            note: "Text for header change product size button"
        }
    },
    productSizeButtonTitle: {
        id: "studio.components.changeProductSize.productSizeButtonTitle",
        defaultMessage: "Product size",
        description: {
            note: "Text for header button that changes product size"
        }
    },
    modalCloseButton: {
        id: "studio.components.changeProductSize.modalCloseButton",
        defaultMessage: "Close",
        description: {
            note: "Text message for change product size modal close button"
        }
    },
    changeProductSizeModalHeader: {
        id: "studio.components.changeProductSize.changeProductSizeModalHeader",
        defaultMessage: "Change product size",
        description: {
            note: "Text message for change product size modal header"
        }
    },
    changeProductSizeModalDescription: {
        id: "studio.components.changeProductSize.changeProductSizeModalDescription",
        defaultMessage: "The design will adjust to fit the selected size.",
        description: {
            note: "Description text message for change product size modal"
        }
    },
    changeProductSizeModalButtonText: {
        id: "studio.components.changeProductSize.changeProductSizeModalButtonText",
        defaultMessage: "Change size",
        description: {
            note: "Change product size button text message in modal"
        }
    },
    changeSizeButtonLoadingText: {
        id: "studio.components.changeProductSize.changeSizeButtonLoadingText",
        defaultMessage: "Changing size...",
        description: {
            note: "Change product size button loading text message in modal while it is updating the size on canvas"
        }
    },
    changeProductSizePreviewLoader: {
        id: "studio.components.changeProductSize.changeProductSizePreviewLoader",
        defaultMessage: "Loading preview...",
        description: {
            note: "Shows when preview for a product is loading on clicking on change size button."
        }
    },
    frontButtonText: {
        id: "studio.components.changeProductSize.frontButtonText",
        defaultMessage: "Front",
        description: {
            note: "Change product size front preview button text message in modal"
        }
    },
    backButtonText: {
        id: "studio.components.changeProductSize.backButtonText",
        defaultMessage: "Back",
        description: {
            note: "Change product size back preview button text message in modal"
        }
    },
    previewImageAlt: {
        id: "studio.components.changeProductSize.previewImageAlt",
        defaultMessage: "Preview image",
        description: {
            note: "Preview image alt text"
        }
    }
});

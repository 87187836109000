import qs from "qs";
import { tryFetch, REQUESTOR } from "@internal/utils-network";

const host = STATIC_URL_SERVICE_URL;

interface StaticUrlResult {
    id: string;
    url: string;
    type: string;
    description: string;
}

export async function getMyProjectsUrl(locale: string): Promise<StaticUrlResult> {
    const query = qs.stringify({
        requestor: REQUESTOR
    });
    return tryFetch({
        url: `${host}/vistaprint/${locale}/ma:myProjects?${query}`
    });
}

export async function getHomepageUrl(locale: string): Promise<StaticUrlResult> {
    const query = qs.stringify({
        requestor: REQUESTOR
    });
    return tryFetch({
        url: `${host}/vistaprint/${locale}/other:home?${query}`
    });
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const currentImageTabSlice = createSlice({
    name: "currentImageTab",
    initialState: "UPLOADS",
    reducers: {
        setCurrentImageTab: (state, action: PayloadAction<string | null>) => action.payload || state
    }
});

export const { setCurrentImageTab } = currentImageTabSlice.actions;
export const currentImageTab = currentImageTabSlice.reducer;

import { isDebugMode, getQueryParams, windowExists, isCareAgent } from "@internal/utils-browser";
import { getUserSessionId, getStudioUniqueSessionId } from "@internal/utils-tracking";
import { Newrelic, SimpleType } from "./types";
import { type NewrelicError } from "./errors";

function cleanseAttributeName(input: string) {
    // allowable characters retrieved from
    // https://docs.newrelic.com/docs/insights/insights-data-sources/custom-data/insights-custom-data-requirements-limits#general
    return input.replace(/[^a-zA-Z0-9_.:]/g, "_");
}

export class NewRelicWrapper implements Newrelic {
    agent: Newrelic;

    constructor(agent: Newrelic) {
        this.agent = agent;

        if (windowExists()) {
            this.agent.setCustomAttribute("metadata/href", window.location.href);
            this.agent.setCustomAttribute("isDebugMode", `${isDebugMode()}`);

            const queryParams = getQueryParams();
            Object.keys(queryParams).forEach(param => {
                if (param === "selectedOptions") {
                    Object.keys(queryParams[param]).forEach(option => {
                        this.agent.setCustomAttribute(
                            `param/selectedOptions/${cleanseAttributeName(option)}`,
                            queryParams[param][option]
                        );
                    });
                } else {
                    this.agent.setCustomAttribute(`param/${cleanseAttributeName(param)}`, queryParams[param]);
                }
            });

            if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                this.agent.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
            } else {
                window.QuantumMetricOnload = () => {
                    if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                        this.agent.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
                    }
                };
            }
        }
    }

    setCustomAttribute(name: string, value: SimpleType): void {
        this.agent.setCustomAttribute(name, value);
    }

    setUpUserVars(locale: string) {
        this.agent.setCustomAttribute("studioUserSession", getUserSessionId());
        this.agent.setCustomAttribute("studioUniqueSession", getStudioUniqueSessionId());
        this.agent.setCustomAttribute("metadata/locale", locale);
    }

    setLoadFinished() {
        this.agent.setCustomAttribute("loadingFinished", "true");
    }

    setDecorationTechnology(decorationTechnology?: string) {
        this.agent.setCustomAttribute("decorationTechnology", decorationTechnology ?? null);
    }

    updateCustomAttributes(state: Record<string, any>) {
        try {
            const selectedProductOptions = state.selectedProductOptions || state.customerSelectedProductOptions;
            if (selectedProductOptions) {
                Object.keys(selectedProductOptions).forEach(option => {
                    this.agent.setCustomAttribute(
                        `param/selectedOptions/${cleanseAttributeName(option)}`,
                        selectedProductOptions[option]
                    );
                });
            }
            if (state.productKey) {
                this.agent.setCustomAttribute(`param/key`, state.productKey);
            }
            if (state.productVersion) {
                this.agent.setCustomAttribute(`param/productVersion`, state.productVersion);
            }
            if (state.quantity) {
                this.agent.setCustomAttribute(`param/qty`, state.quantity);
            }
            if (state.workId) {
                this.agent.setCustomAttribute(`param/workId`, state.workId);
            }
            if (state.mpvId) {
                this.agent.setCustomAttribute(`param/mpvId`, state.mpvId);
            }
            this.agent.setCustomAttribute(`isCareAgent`, `${isCareAgent()}`);
            // eslint-disable-next-line no-empty -- @todo https://vistaprint.atlassian.net/browse/AST-2426
        } catch {}
    }

    addRelease(releaseId: string) {
        this.agent.addRelease("studio", releaseId);
    }

    /*
     * @deprecated Use addRelease instead.
     */
    markRelease(releaseId: string) {
        this.agent.addRelease("studio", releaseId);
    }

    addPageAction(eventName: string, includedMetadata?: Record<string, any>) {
        this.agent.addPageAction(eventName, includedMetadata);
    }

    newTimingEvent(eventName: string, includedMetadata?: Record<string, any>) {
        let startTime: number;
        return {
            start: () => {
                startTime = performance.now();
            },
            end: () => {
                const duration = (performance.now() - startTime) / 1000;

                this.agent.addPageAction(eventName, {
                    ...includedMetadata,
                    duration
                });
            },
            status: () => {
                return performance.now() - startTime;
            }
        };
    }

    noticeError(error: NewrelicError | Error | string, includedMetadata?: Record<string, any>) {
        this.agent.noticeError(error, includedMetadata);
    }
}

export const getNewrelicAgent = () =>
    windowExists() && typeof window.newrelic !== "undefined"
        ? window.newrelic
        : {
              addPageAction() {},
              addRelease() {},
              noticeError() {},
              setCustomAttribute() {}
          };

/*
 * @deprecated Use the new {@link ScopedNewrelicWrapper} instead.
 */
export const newRelicWrapper = new NewRelicWrapper(getNewrelicAgent());

import { useContext } from "react";
import type { SceneConfiguration } from "../../types";
import { rootContext } from "../providers/rootContext";

export function usePrintConfiguration() {
    const appRootContext = useContext(rootContext);

    if (!appRootContext) {
        throw new Error(" Cannot use the print config without a RootContext provider");
    }

    return appRootContext.printConfig;
}

export function useSceneConfiguration(): SceneConfiguration | undefined {
    const appRootContext = useContext(rootContext);

    if (!appRootContext) {
        throw new Error(" Cannot use the sceneConfiguration without a RootContext provider");
    }

    return appRootContext.sceneConfiguration;
}

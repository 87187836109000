import { useEffect, useMemo, useState } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { CimDoc, ShapeItem } from "@design-stack-vista/cdif-types";
import { useSimAfterDuplicatePanel, AfterDuplicateTransformationFunction } from "@internal/sim-framework";
import { usePreviewsContext, usePanelPreviewData } from "@internal/feature-previews";
import { getTransientPreviewURL } from "@shared/utils/Previews";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useAppSelector } from "@shared/redux";
import { isHorizontalArrow } from "@shared/utils/Shapes";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
// `structuredClone` is not yet supported in Safari 15.0; see https://vistaprint.atlassian.net/wiki/spaces/NTEO/pages/60132385/Vistaprint+browser+support+
// eslint-disable-next-line
import cloneDeep from "lodash/cloneDeep";
import { useStudioConfigurationManager } from "@internal/dex";
import { useDesignAttributeMappings } from "@shared/features/StudioBootstrap";
// eslint-disable-next-line import/no-restricted-paths
import { useSceneConfiguration } from "@six/features/editorUI";
import { DSS } from "@vp/types-ddif";
import { useDesignEngine } from "@design-stack-vista/core-features";
import {
    createDocumentPanelSource,
    documentSourceTypeMap,
    getDesignDocumentWithSources
} from "@internal/data-access-design-specifications-service";
import { getStudioUniqueSessionId } from "@internal/utils-tracking";
import { useSurfaceUpsellData } from "@internal/utils-surface-upsell";
import { useDesignDialog } from "../DesignDialogProvider";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

export const flipArrows: AfterDuplicateTransformationFunction = (existingDocument: CimDoc, panelId: string) => {
    const newDocument = cloneDeep(existingDocument);
    const targetPanel = newDocument.document.panels.find(panel => panel.id === panelId);
    if (targetPanel) {
        const arrows = targetPanel?.shapes?.filter((shape: ShapeItem) => isHorizontalArrow(shape, existingDocument));

        // flip horizontal arrows 180 degrees
        arrows?.forEach((arrow: ShapeItem) => {
            let rotationAngle = arrow.rotationAngle ? parseInt(arrow.rotationAngle, 10) : 0;
            rotationAngle += 180;
            if (rotationAngle >= 360) {
                rotationAngle -= 360;
            }
            // eslint-disable-next-line no-param-reassign
            arrow.rotationAngle = `${rotationAngle}`;
        });
    }

    return { doc: newDocument };
};

export const ApplyDuplicatePanelTransformations = (
    existingDocument: CimDoc,
    panelId: string,
    operations: AfterDuplicateTransformationFunction[]
): CimDoc => {
    let newCimdoc = cloneDeep(existingDocument);
    for (const operation of operations) {
        const result = operation(newCimdoc, panelId);
        if (result?.doc) {
            newCimdoc = result.doc;
        }
    }
    return newCimdoc;
};

export function useGetDuplicatePanelTransformations(): AfterDuplicateTransformationFunction[] {
    const studioConfiguration = useStudioConfigurationManager().data;
    const dupOperations = useSimAfterDuplicatePanel(studioConfiguration);
    const { shouldFlipArrows } = useStudioConfigurationManager().data;

    return useMemo(() => {
        return shouldFlipArrows ? [...dupOperations, flipArrows] : dupOperations;
    }, [dupOperations, shouldFlipArrows]);
}

export function useGetPreviewWithTransformations() {
    const [previewUrl, setPreviewUrl] = useState<string>("");
    const designRequirements = useDesignRequirementsContext();
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { surfaceUpsellData } = useSurfaceUpsellData();
    const { getDocument } = useDesignDialog();
    const { auth } = useIdentityContext();
    const { canvasSelectorUrls } = usePreviewsContext();
    const transformations = useGetDuplicatePanelTransformations();
    const { cimDoc } = usePanelPreviewData({
        useDesignAttributeMappings,
        useSceneConfiguration
    });
    const productAndProjectState = useProductAndProjectStateManager();
    const studioSelectedProductOptionsData = productAndProjectState.data;
    const { studioSelectedProductOptions } = studioSelectedProductOptionsData;
    const designAttributeMappings = useDesignAttributeMappings();
    const [duplicatePreviewCimDoc, setDuplicatePreviewCimDoc] = useState<CimDoc>(cimDoc);
    const designEngine = useDesignEngine();
    const getPanelSourceExtension = useGetPanelSourceExtension();

    useEffect(() => {
        if (!canvasSelectorUrls) {
            return;
        }
        if (!easelLoaded) {
            return;
        }

        const getDuplicatePanelDocument = async (): Promise<DSS.DesignDocument> => {
            const frontPanelId = designEngine?.cimDocStore.panels[0].id;
            const secondPanelName = designRequirements?.panels[1].name;

            if (!frontPanelId || !secondPanelName) {
                return Promise.reject();
            }

            const panelSourceExtension = getPanelSourceExtension(secondPanelName.toLowerCase());
            const surfaceUpsell = surfaceUpsellData[secondPanelName];

            if (!panelSourceExtension) {
                return Promise.reject();
            }
            const { productKey, productVersion, locale } = studioSelectedProductOptionsData;

            const newDocumentSource = createDocumentPanelSource(
                panelSourceExtension.designState.id,
                documentSourceTypeMap.DUPLICATE,
                frontPanelId
            );

            const documentSources = {
                panels: [newDocumentSource]
            };

            const newOptions = surfaceUpsell?.colorOption
                ? { [surfaceUpsell.optionName]: surfaceUpsell.colorOption }
                : undefined;

            return getDesignDocumentWithSources(
                productKey,
                productVersion,
                { ...studioSelectedProductOptions, ...newOptions },
                cimDoc,
                documentSources,
                undefined,
                [],
                getStudioUniqueSessionId(),
                undefined,
                locale
            );
        };

        const getUrl = async (): Promise<string> => {
            const existingDocument = await getDocument();
            const firstPanel = existingDocument?.document.panels[0];
            if (transformations.length > 0 && existingDocument && firstPanel) {
                const authToken = auth.getToken();
                const firstPanel = existingDocument.document.panels[0];
                const url = await getTransientPreviewURL({
                    designDocument: ApplyDuplicatePanelTransformations(
                        existingDocument,
                        firstPanel.id,
                        transformations
                    ),
                    width: 400,
                    page: 1,
                    authToken,
                    studioSelectedProductOptions,
                    designAttributeMappings
                });
                return url;
            }

            const front: any = canvasSelectorUrls.find(
                (selector: any) => selector.name.toLowerCase() === designRequirements?.panels[0].name?.toLowerCase()
            );

            return Promise.resolve(front?.src);
        };
        getDuplicatePanelDocument().then(document => setDuplicatePreviewCimDoc(document));
        getUrl().then(url => setPreviewUrl(url));
    }, [
        auth,
        canvasSelectorUrls,
        cimDoc,
        designRequirements?.panels,
        easelLoaded,
        getDocument,
        studioSelectedProductOptions,
        designAttributeMappings,
        transformations,
        designEngine?.cimDocStore.panels,
        getPanelSourceExtension,
        studioSelectedProductOptionsData,
        surfaceUpsellData
    ]);

    return { previewUrl, duplicatePreviewCimDoc };
}

import {
    LightBlueOXIcon,
    LightBlueSleepyIcon,
    LightBlueSurprisedIcon,
    LightBlueXOIcon,
    LightBlueXXIcon,
    LightBlueXXSadIcon
} from "./icons";
import styles from "./ErrorConstants.module.scss";

export const ErrorIconTypes = {
    SurfaceIssue: LightBlueXXSadIcon,
    Critical400Failure: LightBlueXXIcon,
    InvalidMerchandisingData: LightBlueXOIcon,
    InvalidProductData: LightBlueOXIcon,
    Critical500Failure: LightBlueSleepyIcon,
    Default: LightBlueSurprisedIcon
};

export const ErrorIconColor = {
    ProductTribe: styles.productTribe,
    ProductSetupTeam: styles.productSetupTeam,
    DesignTechTribe: styles.designTechTribe,
    MerchTechTribe: styles.merchTechTribe,
    Default: styles.default
};

// This needs to be of type
type DebouncedFunction = (firstArg: string, ...args: any[]) => any;

// this will run the last instance of the debounced function
function debounce<F extends DebouncedFunction>(cb: F, duration: number) {
    let timer: ReturnType<typeof setTimeout>;
    return ((...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, duration);
    }) as F;
}

// debounce functions by first argument
export function getDebouncedByType<F extends DebouncedFunction>(func: F, delay: number): F {
    const memory: Record<string, Function> = {};

    const f = ((firstArg: string, ...args: any[]) => {
        // use first argument as a key

        if (typeof memory[firstArg] === "function") {
            return memory[firstArg](firstArg, ...args);
        }

        memory[firstArg] = debounce(func, delay);

        return memory[firstArg](firstArg, ...args);
    }) as F;

    return f;
}

import type { DSS } from "@vp/types-ddif";
import { isLineItem, isFigureItem, isCurveItem } from "@design-stack-vista/cimdoc-state-manager";
import { setBackgroundColor } from "@internal/utils-background-color";
import cloneDeep from "lodash/cloneDeep";
import {
    BACKGROUND_COLOR_SHAPE_ID,
    CUTLINE_SHAPE_ID_SUBSTRING,
    CUTLINE_SUBPANEL_ID,
    DESIGN_SUBPANEL_ID
} from "./cutlineConstants";
import { convertFigureItemToPath } from "./convertFigureItemToPath";
import { convertCurveItemToPath } from "./convertCurveItemToPath";
import type { CurveItem, CurveItemV2 } from "@design-stack-vista/cdif-types";

const isCurveItemV2 = (item: CurveItemV2 | CurveItem): item is CurveItemV2 => {
    if ("svgPathData" in item) {
        return true;
    }
    return false;
};

export const convertFromCutlineDocument = (cimDoc: DSS.DesignDocument): DSS.DesignDocument => {
    const designSubpanel = cimDoc.document.panels[0].subpanels?.find(subpanel => subpanel.id === DESIGN_SUBPANEL_ID);
    if (!designSubpanel) {
        // FAST FAIL
        throw new Error("Can't find design subpanel");
    }

    const updatedCimDoc = cloneDeep(cimDoc);

    // The cutline will be in the Cutline subpanel, but the id varies based on the shape and index of the cutline
    const cutlineItem = cimDoc.document.panels[0].subpanels
        ?.find(subpanel => subpanel.id === CUTLINE_SUBPANEL_ID)
        ?.shapes?.find(shape => shape.id?.includes(CUTLINE_SHAPE_ID_SUBSTRING));

    // Move images to main panel
    updatedCimDoc.document.panels[0].images = cloneDeep(designSubpanel.images);

    const backgroundItem = designSubpanel.shapes?.find(shape => shape.id === BACKGROUND_COLOR_SHAPE_ID);
    // if there is a background item, use its color to set the background color.
    if (
        backgroundItem &&
        // For type safety, since LineItems don't have a color
        !isLineItem(backgroundItem) &&
        backgroundItem.color
    ) {
        setBackgroundColor(updatedCimDoc, {
            panelId: updatedCimDoc.document.panels[0]?.id,
            color: backgroundItem.color
        });
    }

    // after getting all the data from subpanels, remove the subpanels
    updatedCimDoc.document.panels[0].subpanels = [];

    const selectedCutlineShape = updatedCimDoc.metadata?.cutlineDetails.selectedCutlineShape;
    if (!selectedCutlineShape || !cutlineItem) {
        // no cutline
        return updatedCimDoc;
    }

    // Does the metadata have the cutlinePath?
    let cutlinePath = updatedCimDoc.metadata?.cutlineDetails.cutlinePath;
    // If not, back derive the path from the item
    if (!cutlinePath) {
        if (isCurveItem(cutlineItem) && isCurveItemV2(cutlineItem)) {
            cutlinePath = convertCurveItemToPath(cutlineItem);
        }
        if (isFigureItem(cutlineItem)) {
            cutlinePath = convertFigureItemToPath(cutlineItem);
        }
    }

    // Add the cutlineItem
    updatedCimDoc.metadata = {
        ...updatedCimDoc.metadata,
        cutlineDetails: {
            ...updatedCimDoc.metadata?.cutlineDetails,
            cutlineItem,
            selectedCutlineShape,
            cutlinePath
        }
    };

    return updatedCimDoc;
};

export const isStickerDexDocument = (cimDoc: DSS.DesignDocument): boolean => {
    const subpanels = cimDoc.document.panels[0].subpanels;
    if (!subpanels) {
        return false;
    }
    // Must have a design subpanel and a cutline subpanel to be a StickerDEX document
    return (
        subpanels.findIndex(subpanel => subpanel.id === DESIGN_SUBPANEL_ID) !== -1 &&
        subpanels.findIndex(subpanel => subpanel.id === CUTLINE_SUBPANEL_ID) !== -1
    );
};

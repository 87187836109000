import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
    isTransitionComplete: boolean;
    isEventActive: boolean;
};

const initialState: State = {
    isTransitionComplete: false,
    isEventActive: false
};

const trackModalTransitionEventSlice = createSlice({
    name: "trackModalTransitionEvent",
    initialState,
    reducers: {
        setTrackModalTransitionEvent: (state, action: PayloadAction<State>) => action.payload
    }
});

export const { setTrackModalTransitionEvent } = trackModalTransitionEventSlice.actions;
export const trackModalTransitionEvent = trackModalTransitionEventSlice.reducer;

export enum DecorationTechnology {
    None = "none",
    Offset = "offset",
    Digital = "digital",
    OffsetOrDigital = "offsetOrDigital",
    DirectToGarment = "directToGarment",
    HeatTransfer = "heatTransfer",
    LaserEngraving = "laserEngraving",
    ScreenPrint = "screenPrint",
    PadPrint = "padPrint",
    InkJet = "inkJet",
    Thermography = "thermography",
    EmbroideryFlat = "embroideryFlat",
    EmbroideryCylinder = "embroideryCylinder",
    Sublimation = "sublimation",
    Unknown = "unknown"
}

export function toDecorationTechnology(decoTech: string | undefined): DecorationTechnology {
    switch (decoTech?.trim()) {
        case "none": {
            return DecorationTechnology.None;
        }
        case "offset": {
            return DecorationTechnology.Offset;
        }
        case "digital": {
            return DecorationTechnology.Digital;
        }
        case "offsetOrDigital": {
            return DecorationTechnology.OffsetOrDigital;
        }
        case "directToGarment": {
            return DecorationTechnology.DirectToGarment;
        }
        case "heatTransfer": {
            return DecorationTechnology.HeatTransfer;
        }
        case "laserEngraving": {
            return DecorationTechnology.LaserEngraving;
        }
        case "screenPrint": {
            return DecorationTechnology.ScreenPrint;
        }
        case "padPrint": {
            return DecorationTechnology.PadPrint;
        }
        case "inkJet": {
            return DecorationTechnology.InkJet;
        }
        case "thermography": {
            return DecorationTechnology.Thermography;
        }
        case "embroideryFlat": {
            return DecorationTechnology.EmbroideryFlat;
        }
        case "embroideryCylinder": {
            return DecorationTechnology.EmbroideryCylinder;
        }
        case "sublimation": {
            return DecorationTechnology.Sublimation;
        }
        default: {
            return DecorationTechnology.Unknown;
        }
    }
}

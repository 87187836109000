import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import classnames from "classnames";
import { clamp } from "@design-stack-vista/utility-core";
import { useEventCallback } from "@design-stack-vista/utility-react";
import { ColorSwatchHandle } from "./ColorSwatchHandle";
import styles from "./Slider.module.scss";
import { useClientRect } from "@internal/utils-browser";
import { BasicSliderProps } from "./types";

type Orientation = "horizontal" | "vertical";

interface Props extends BasicSliderProps {
    value: number;
    min?: number;
    max?: number;
    /** Inline styles passed to the bar */
    barStyle?: { [key: string]: string };
    /** Inline styles passed to the handle */
    handleStyle?: { [key: string]: string };
    /**
     * Whether the bar sits vertically or horizontally. Valid values: 'horizontal' | 'vertical'
     * @default 'horizontal'
     */
    orientation?: Orientation;
    /**
     * aria label for slider
     * @default ''
     */
    ariaLabel?: string;
    startSwatchColor?: string;
    endSwatchColor?: string;
    /**
     * Whether the slider is disabled
     * @default false
     */
    disabled?: boolean;
}

function getNewHandlePosition(value: number, min: number, max: number, barRect: DOMRect, orientation: Orientation) {
    const percentage = clamp((value - min) / (max - min), 0, 1);
    if (orientation === "vertical") {
        return percentage * barRect.height;
    }
    // horizontal
    return percentage * barRect.width;
}

function getHandlePositionStyle(position: number, orientation: Orientation) {
    if (orientation === "vertical") {
        return {
            bottom: position
        };
    }

    // horizontal
    return {
        left: position
    };
}

export function Slider({
    value: inputValue,
    min = 0,
    max = 100,
    onChange,
    onRelease = () => {},
    barStyle: inputBarStyle,
    handleStyle: inputHandleStyle,
    orientation = "horizontal",
    ariaLabel = "",
    startSwatchColor,
    endSwatchColor,
    className,
    disabled = false
}: Props) {
    const [handlePos, setHandlePos] = useState(0);
    const barRef = useRef<HTMLDivElement>(null);
    const [isActive, setActive] = useState(false);
    const barRect = useClientRect(barRef);

    useLayoutEffect(() => {
        if (!isActive) {
            setHandlePos(getNewHandlePosition(inputValue, min, max, barRect, orientation));
        }
    }, [inputValue, max, min, barRect, isActive, orientation]);

    const updatePosition = ({ clientX, clientY }: { clientX: number; clientY: number }) => {
        let newValue = 0;
        if (orientation === "horizontal") {
            const { left: barX, width: barWidth } = barRect;
            const newX = clamp(clientX - barX, 0, barWidth);
            newValue = (newX / barWidth) * (max - min) + min;
        } else if (orientation === "vertical") {
            const { bottom: barY, height: barHeight } = barRect;
            const newY = clamp(barY - clientY, 0, barHeight);
            newValue = (newY / barHeight) * (max - min) + min;
        }

        setHandlePos(getNewHandlePosition(newValue, min, max, barRect, orientation));
        onChange(newValue);
    };

    const handleMouseMove = useEventCallback(
        (event: MouseEvent) => {
            updatePosition(event);
        },
        [barRect, min, max, orientation, onChange]
    );

    const handleTouchMove = useEventCallback(
        (event: TouchEvent) => {
            updatePosition(event.touches[0]);
        },
        [barRect, min, max, orientation, onChange]
    );

    const handleRelease = useEventCallback(() => {
        setActive(false);
        onRelease();

        document.body.removeEventListener("mousemove", handleMouseMove);
        document.body.removeEventListener("mouseup", handleRelease);
        document.body.removeEventListener("touchmove", handleTouchMove);
        document.body.removeEventListener("touchend", handleRelease);
    }, [barRect, min, max, orientation, onChange]);

    const handleTouchDown = useEventCallback(
        (event: React.TouchEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (disabled) {
                return;
            }
            setActive(true);
            event.persist();
            updatePosition(event.touches[0]);

            document.body.addEventListener("touchmove", handleTouchMove);
            document.body.addEventListener("touchend", handleRelease);
        },
        [barRect, min, max, orientation, onChange]
    );

    const handleMouseDown = useEventCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();
            if (isActive || disabled) {
                return;
            }
            setActive(true);
            updatePosition(event.nativeEvent);

            document.body.addEventListener("mousemove", handleMouseMove);
            document.body.addEventListener("mouseup", handleRelease);
        },
        [barRect, min, max, orientation, onChange]
    );

    const handleKeyDown = useEventCallback(
        (event: KeyboardEvent) => {
            if (
                event.key === "ArrowRight" ||
                event.key === "ArrowLeft" ||
                event.key === "ArrowUp" ||
                event.key === "ArrowDown" ||
                event.key === "PageDown" ||
                event.key === "PageUp"
            ) {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (disabled) {
                    return;
                }

                let newValue = inputValue;

                const change = (max - min) / 100;

                if (event.key === "ArrowRight" || event.key === "ArrowUp") {
                    newValue = inputValue + change;
                } else if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
                    newValue = inputValue - change;
                } else if (event.key === "PageUp") {
                    newValue = max;
                } else if (event.key === "PageDown") {
                    newValue = min;
                }

                newValue = clamp(newValue, min, max);

                if (newValue !== inputValue) {
                    setHandlePos(getNewHandlePosition(newValue, min, max, barRect, orientation));
                    onChange(newValue);

                    document.body.addEventListener("keyup", handleRelease);
                }
            }
        },
        [barRect, min, max, orientation, onChange]
    );

    // Remove event listeners on unmount
    useEffect(
        () => () => {
            document.body.removeEventListener("mousemove", handleMouseMove);
            document.body.removeEventListener("mouseup", handleRelease);
            document.body.removeEventListener("touchmove", handleTouchMove);
            document.body.removeEventListener("touchend", handleRelease);
            document.body.removeEventListener("keyup", handleRelease);
        },
        [handleMouseMove, handleRelease, handleTouchMove]
    );

    useEffect(() => {
        if (barRef.current) {
            barRef.current.addEventListener("keydown", handleKeyDown, false);
        }
    }, [barRef, handleKeyDown]);

    return (
        <>
            <div
                data-testid="slider_container"
                className={classnames(
                    styles.easelSliderContainer,
                    "slider",
                    "easel-slider-container ",
                    {
                        "easel-slider-container-vertical": orientation === "vertical"
                    },
                    { "slider--dragging": isActive },
                    className
                )}
            >
                <div
                    className={classnames("easel-slider-cap easel-slider-startswatch")}
                    style={{ background: startSwatchColor }}
                ></div>
                <div
                    tabIndex={0}
                    ref={barRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchDown}
                    style={{ ...inputBarStyle }}
                    className={classnames(
                        "slider__bar",
                        "easel-slider-bar",
                        {
                            "easel-slider-bar--disabled": disabled
                        },
                        { [styles.easelSliderBarDisabled]: disabled }
                    )}
                    data-testid="slider__bar"
                    role="slider"
                    aria-valuemax={max}
                    aria-valuemin={min}
                    aria-valuenow={inputValue}
                    aria-label={ariaLabel}
                >
                    <ColorSwatchHandle
                        handleStyle={inputHandleStyle}
                        handleContainerStyle={getHandlePositionStyle(handlePos, orientation)}
                        containerClassName={orientation === "vertical" ? "easel-slider-handle-base-vertical" : ""}
                    />
                </div>
                <div
                    className={classnames("easel-slider-cap easel-slider-endswatch")}
                    style={{ background: endSwatchColor }}
                ></div>
            </div>
        </>
    );
}
Slider.displayName = "Slider";

import React, { useEffect } from "react";
import { withPrefix } from "gatsby";
import { GuidelineModal, GuidelineModalSection, GuidelineModalImage } from "@internal/ui-guidelines";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useEngraving } from "../../hooks/EngravingProvider";
import { useLocalized } from "../../localized";
import { useIsEngraving } from "../../hooks";

function EngravingGuidelineModalContent() {
    const localized = useLocalized();
    const { closeEngravingGuidelineModal } = useEngraving();

    return (
        <GuidelineModal
            isOpen={true}
            closeButtonAccessibleText={localized.get("engravingGuidelineAriaCloseLabel")}
            closeButtonText={localized.get("engravingGuidelineCloseButtonText")}
            closeDialog={closeEngravingGuidelineModal}
            title={localized.get("engravingGuidelineHeader")}
        >
            <GuidelineModalSection
                title={localized.get("engravingGuidelineTextTipTitle")}
                text={localized.get("engravingGuidelineTextTipText")}
            >
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/text-bad.jpg")}
                    alt={localized.get("engravingGuidelineTextBadImage")}
                />
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/text-good.jpg")}
                    alt={localized.get("engravingGuidelineTextGoodImage")}
                />
            </GuidelineModalSection>
            <GuidelineModalSection
                title={localized.get("engravingGuidelineGraphicsTipTitle")}
                text={localized.get("engravingGuidelineGraphicsTipText")}
            >
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/graphics-bad.jpg")}
                    alt={localized.get("engravingGuidelineGraphicsBadImage")}
                />
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/graphics-good.jpg")}
                    alt={localized.get("engravingGuidelineGraphicsGoodImage")}
                />
            </GuidelineModalSection>
            <GuidelineModalSection
                title={localized.get("engravingGuidelineLinesTipTitle")}
                text={localized.get("engravingGuidelineLinesTipText")}
            >
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/lines-bad.jpg")}
                    alt={localized.get("engravingGuidelineLinesBadImage")}
                />
                <GuidelineModalImage
                    src={ASSET_PREFIX + withPrefix("/engravingGuidelineModal/lines-good.jpg")}
                    alt={localized.get("engravingGuidelineLinesGoodImage")}
                />
            </GuidelineModalSection>
        </GuidelineModal>
    );
}

export function EngravingGuidelineModal() {
    const isEngraving = useIsEngraving();
    const {
        state: { engravingGuidelineModalOpen, engravingGuidelineModalShown },
        openEngravingGuidelineModal,
        closeEngravingGuidelineModal
    } = useEngraving();
    const { workId } = useProductAndProjectStateManager().data;

    useEffect(() => {
        if (isEngraving && !engravingGuidelineModalShown) {
            if (!workId) {
                openEngravingGuidelineModal();
            } else {
                closeEngravingGuidelineModal();
            }
        }
    }, [isEngraving, engravingGuidelineModalShown, openEngravingGuidelineModal, closeEngravingGuidelineModal, workId]);

    if (!engravingGuidelineModalOpen) {
        return null;
    }

    return <EngravingGuidelineModalContent />;
}

EngravingGuidelineModal.displayName = "EngravingGuidelineModal";

import type { DSS } from "@vp/types-ddif";
import { FONT_REPOSITORY_URL, getFontNames, getMinMaxFontSizes } from "@internal/utils-fonts";
import type { AnyTextContent } from "@design-stack-vista/cdif-types";
import { MinFontSize } from "@internal/utils-studio-configuration-core";

function fixFontCase(fontName: string): string {
    const allFonts = getFontNames().print;
    // Convert the input font name to lowercase for case-insensitive comparison
    const lowerCaseFontName = fontName.toLowerCase();

    // Check if the lowercase font name exists in the array
    const foundFont = allFonts.find(font => font.toLowerCase() === lowerCaseFontName);

    // Return the found font or the original input if not found
    return foundFont || fontName;
}

function recursivelyupdateCimdocToNormalizeFontFamily(content: AnyTextContent[]) {
    content.forEach((field: AnyTextContent) => {
        if (field.fontFamily) {
            field.fontFamily = fixFontCase(field.fontFamily);
        }
        if (field.content && Array.isArray(field.content)) {
            recursivelyupdateCimdocToNormalizeFontFamily(field.content);
        }
    });
}

/**
 * Normalizes font families to match the casing used in studio
 * @param cimDoc
 */
export function updateCimdocToNormalizeFontFamily(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                // @ts-expect-error -- textFields are not part of the cimdoc spec but can occur on older documents or templates, so to be safe we check for both
                recursivelyupdateCimdocToNormalizeFontFamily(textArea.content || textArea.textFields);
            });
        }
    });
}

/**
 * Updates the font repository url to the one studio uses
 * @param cimDoc
 */
export function updateFontRepositoryUrl(cimDoc: DSS.DesignDocument) {
    // we have to set the font repository to the one that we use, otherwise fonts will fail
    // cimdocs generated by cimpress services will generally use their own font repos, which are no good for us
    // we hardcode the fonts that we want, so text will be useless unless we use our own repository
    cimDoc.fontRepositoryUrl = FONT_REPOSITORY_URL;
}

export function updateCimDocMinMaxFontSizes(cimDoc: DSS.DesignDocument, productMinFontSize?: MinFontSize) {
    const { decorationTechnology } = cimDoc.document.panels[0];
    const { minFontSize, maxFontSize } = getMinMaxFontSizes(decorationTechnology, productMinFontSize);

    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                const { fontSize, content } = textArea.content[0];
                const fontSizeNumerical = Number(parseFloat(fontSize));

                // only update to use minmax font sizes for empty fields
                // to prevent changing size for previously edited fields
                if (content.length === 0) {
                    if (fontSizeNumerical < minFontSize) {
                        textArea.content[0].fontSize = `${minFontSize}pt`;
                    } else if (fontSizeNumerical > maxFontSize) {
                        textArea.content[0].fontSize = `${maxFontSize}pt`;
                    }
                }
            });
        }
    });
}

import React, { createContext, ReactNode, useCallback, useContext, useMemo, useReducer } from "react";

interface State {
    embroideryGuidelineModalOpen: boolean;
    embroideryGuidelineModalShown: boolean;
}

const initialState: State = {
    embroideryGuidelineModalOpen: false,
    embroideryGuidelineModalShown: false
};

enum ActionTypes {
    openEmbroideryGuidelineModalOpen = "openEmbroideryGuidelineModalOpen",
    closeEmbroideryGuidelineModal = "closeEmbroideryGuidelineModal"
}

interface OpenEmbroideryGuidelineModalOpenAction {
    type: ActionTypes.openEmbroideryGuidelineModalOpen;
}

interface CloseEmbroideryGuidelineModalAction {
    type: ActionTypes.closeEmbroideryGuidelineModal;
}

type Action = OpenEmbroideryGuidelineModalOpenAction | CloseEmbroideryGuidelineModalAction;

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionTypes.openEmbroideryGuidelineModalOpen: {
            return {
                embroideryGuidelineModalOpen: true,
                embroideryGuidelineModalShown: state.embroideryGuidelineModalShown
            };
        }
        case ActionTypes.closeEmbroideryGuidelineModal: {
            return {
                embroideryGuidelineModalOpen: false,
                embroideryGuidelineModalShown: true
            };
        }
        default: {
            throw new Error(`Invalid action specified: ${action}`);
        }
    }
};

interface EmbroideryContextData {
    state: State;
    closeEmbroideryGuidelineModal: () => void;
    openEmbroideryGuidelineModal: () => void;
}

const context = createContext<EmbroideryContextData | undefined>(undefined);

export function useEmbroidery() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a EmbroideryProvider");
    }
    return result;
}

interface Props {
    children: ReactNode | ReactNode[];
}

export const EmbroideryProvider = ({ children }: Props) => {
    const { Provider } = context;
    const [state, dispatch] = useReducer(reducer, initialState);

    const closeEmbroideryGuidelineModal = useCallback(() => {
        dispatch({ type: ActionTypes.closeEmbroideryGuidelineModal });
    }, []);

    const openEmbroideryGuidelineModal = useCallback(() => {
        dispatch({ type: ActionTypes.openEmbroideryGuidelineModalOpen });
    }, []);

    const contextObject = useMemo(
        () => ({
            state,
            closeEmbroideryGuidelineModal,
            openEmbroideryGuidelineModal
        }),
        [state, closeEmbroideryGuidelineModal, openEmbroideryGuidelineModal]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

EmbroideryProvider.displayName = "EmbroideryProvider";

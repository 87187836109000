import { BoundingBox, Point } from "../types";

/**
 *  Get bounding box for curved item.
 * @param p0 Start point.
 * @param p1 Control point 1.
 * @param p2 Control point 2.
 * @param p3 End point.
 * @returns Bounding box with left, right, top, bottom of curve item.
 */
export const getBoundsOfCurve = (p0: Point, p1: Point, p2: Point, p3: Point): BoundingBox => {
    /*
        A cubic bazier curve is in the form of
        B(t) = (1-t)^3 *P0 + 3*(1-t)^2 * t * P1 + 3*(1-t)*t^2 * P2 + P3, 0 <= t <=1;

        To get the maxima or minima of this curve we will have to take the derivative of B(t)
        B'(t) = 3*(1-t)^2*(P1 - P0) + 6*(1-t)*t*(P2 - P1) = 3*t^2*(P3 - P2);
        B'(t) is a quadratic equation which can be simplified to a*t^2 + b*t + c
        After simplification
        a = -3 * P0 + 9 * P1 - 9 * P2 + 3 * x3
        b = 6 * P0 - 12 * P1 + 6 * P2
        c = 3 * P1 - 3 * P0

        t1 and t2 are the two possible values of the above quadratic equation.

        t = (-b +- squareRoot(b^2 - 4ac)) / 2a

        After getting the t values we put them in the cubic bazier curve equation to get the points of maxima and minima.

        Double derivative to check the if it is a maxima or a minima
        B''(t) = 6*(1-t)*(P2 - 2*P1 + P0) + 6*t*(P3 - 2*P2 + P1)

        Bounding box is calculated considering P0 , P3 and the maxima/minima Point
    */
    const { x: x0, y: y0 } = p0;
    const { x: x1, y: y1 } = p1;
    const { x: x2, y: y2 } = p2;
    const { x: x3, y: y3 } = p3;

    const tvalues = [];
    const bounds: number[][] = [[], []];
    const points = [];

    let a;
    let b;
    let c;
    let t;
    let t1;
    let t2;
    let b2ac;
    let sqrtb2ac;
    for (let i = 0; i < 2; ++i) {
        if (i === 0) {
            b = 6 * x0 - 12 * x1 + 6 * x2;
            a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
            c = 3 * x1 - 3 * x0;
        } else {
            b = 6 * y0 - 12 * y1 + 6 * y2;
            a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
            c = 3 * y1 - 3 * y0;
        }

        if (Math.abs(a) < 1e-12) {
            // Numerical robustness
            if (Math.abs(b) < 1e-12) {
                // Numerical robustness

                continue;
            }
            t = -c / b;
            if (t > 0 && t < 1) {
                tvalues.push(t);
            }

            continue;
        }
        b2ac = b * b - 4 * c * a;
        sqrtb2ac = Math.sqrt(b2ac);
        if (b2ac < 0) {
            continue;
        }
        t1 = (-b + sqrtb2ac) / (2 * a);
        if (t1 > 0 && t1 < 1) {
            tvalues.push(t1);
        }
        t2 = (-b - sqrtb2ac) / (2 * a);
        if (t2 > 0 && t2 < 1) {
            tvalues.push(t2);
        }
    }

    let x;
    let y;
    let j = tvalues.length;
    let mt;
    const jlen = j;

    while (j--) {
        t = tvalues[j];
        mt = 1 - t;
        x = mt * mt * mt * x0 + 3 * mt * mt * t * x1 + 3 * mt * t * t * x2 + t * t * t * x3;
        bounds[0][j] = x;

        y = mt * mt * mt * y0 + 3 * mt * mt * t * y1 + 3 * mt * t * t * y2 + t * t * t * y3;
        bounds[1][j] = y;
        points[j] = {
            X: x,
            Y: y
        };
    }

    tvalues[jlen] = 0;
    tvalues[jlen + 1] = 1;
    points[jlen] = {
        X: x0,
        Y: y0
    };
    points[jlen + 1] = {
        X: x3,
        Y: y3
    };
    bounds[0][jlen] = x0;
    bounds[1][jlen] = y0;
    bounds[0][jlen + 1] = x3;
    bounds[1][jlen + 1] = y3;
    // eslint-disable-next-line no-multi-assign -- this code was likely copied from elsewhere so its better to avoid refactoring it
    tvalues.length = bounds[0].length = bounds[1].length = points.length = jlen + 2;

    return {
        left: Math.min.apply(null, bounds[0]),
        top: Math.min.apply(null, bounds[1]),
        right: Math.max.apply(null, bounds[0]),
        bottom: Math.max.apply(null, bounds[1])
    };
};

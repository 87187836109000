import * as React from "react";
import { SVGProps } from "react";

export const LightBlueSleepyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 182 218" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
        <g opacity={0.1} filter="url(#a)">
            <path
                d="M171.5 201.4c0 3.6-36 6.5-80.5 6.5s-80.5-2.9-80.5-6.5c0-3.5 36-6.5 80.5-6.5s80.5 3 80.5 6.5Z"
                fill="#000"
            />
        </g>
        <path
            d="M7.5 53.6a2 2 0 0 1 2.6-2c9.2 2.2 42.4 8.7 80.9-.7 45.5-11 62.8 0 62.8 0v19c0 1 .8 2 2 2h18.7v84.5a2 2 0 0 1-2.5 2 232 232 0 0 0-81 .5 230.3 230.3 0 0 1-82 .4 2 2 0 0 1-1.5-2V53.7Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.1 49.3c-9.6-1.3-25-1.4-47.5 4a184.5 184.5 0 0 1-81.6.9v102.7c8 1.6 39.7 6.8 80.6-.4 40.2-7.1 71.7-2.4 81.4-.6V74.3h-16.2c-2.5 0-4.5-2-4.5-4.5V52.5l-1.8-.8c-2.2-.7-5.6-1.7-10.4-2.4Zm14.7 1.7 1.3-2.2.4.4L176.3 70c.4.4.7 1 .7 1.7v84.6c0 3-2.7 5-5.5 4.5-8.6-1.7-40-6.6-80 .5a232.8 232.8 0 0 1-83 .3 4.4 4.4 0 0 1-3.5-4.3V53.6c0-3 2.8-5.1 5.6-4.4 9.1 2.1 41.8 8.5 79.8-.7 23-5.5 39-5.6 49.4-4.2a54.6 54.6 0 0 1 15.3 4.5l-1.3 2.2Zm2.5 6 12.2 12.3h-12.2V57Z"
            fill="#6A7076"
        />
        <path
            className="color-element"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.4 99c0-1.1.8-2 1.9-2.2l14-.7a2 2 0 0 1 .2 4l-14 .7a2 2 0 0 1-2.1-1.8Zm35.9 24a2 2 0 0 1 1.8-2.2l7-.7a2 2 0 1 1 .4 4l-7 .7a2 2 0 0 1-2.2-1.8Zm25.1-27c0-1.1.8-2 1.9-2.2l14-.7a2 2 0 1 1 .2 4l-14 .7a2 2 0 0 1-2.1-1.8Z"
            fill="#36B7FF"
        />
        <path
            d="M73.8 10.8c.3 0 .5.1.8.3.2.2.3.5.3.7v.9c0 .6-.2 1.3-.6 1.8L69.7 21h4.1c.3 0 .5.2.8.4.2.2.3.4.3.7v.8c0 .3-.1.5-.3.7a1 1 0 0 1-.8.4h-7.3a1 1 0 0 1-.7-.4 1 1 0 0 1-.3-.7v-.8c0-.7.2-1.3.6-1.9l4.5-6.5h-4a1 1 0 0 1-.8-.3 1 1 0 0 1-.3-.7v-.9c0-.2.1-.5.3-.7.2-.2.5-.3.7-.3h7.3Zm18.1 10.6c.3 0 .6 0 .8.3.2.2.3.5.3.8v1c0 .7-.2 1.4-.6 2l-5 7.2h4.5c.3 0 .6.2.8.4.2.2.3.5.3.8v1c0 .2 0 .5-.3.7a1 1 0 0 1-.8.4h-8.1a1 1 0 0 1-.8-.4 1 1 0 0 1-.4-.8v-1c0-.7.2-1.4.7-2l5-7.2h-4.5a1 1 0 0 1-.8-.4 1 1 0 0 1-.4-.8v-1c0-.2.1-.5.4-.7.2-.2.5-.3.8-.3h8ZM87.3 0c.2 0 .4 0 .6.3l.2.6v.6c0 .6-.1 1.1-.5 1.6L84 8.5h3.4l.6.3.2.6v.7c0 .2 0 .4-.2.6l-.6.3h-6.1c-.3 0-.5-.1-.6-.3a.8.8 0 0 1-.3-.6v-.7c0-.6.2-1 .5-1.5l3.8-5.5h-3.4c-.3 0-.5 0-.6-.3a.8.8 0 0 1-.3-.6V1c0-.3.1-.5.3-.6.1-.2.3-.3.6-.3h6Z"
            fill="#6A7076"
        />
        <defs>
            <filter
                id="a"
                x={0.5}
                y={184.9}
                width={181}
                height={33}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur" />
            </filter>
        </defs>
    </svg>
);

LightBlueSleepyIcon.displayName = "LightBlueSleepyIcon";

import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { BucketColorIcon } from "@internal/ui-studio-chrome";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import type { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import { ChangeTemplateDialogTypes } from "../ChangeTemplateDialogTypes";

const messages = defineMessages({
    designColorTitle: {
        id: "editorUI.addContent.drawer.designColor.title",
        defaultMessage: "Design Color",
        description: {
            note: "Content Panel title text for changing substrate and/or template color"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    if (screenSize === "largeScreen") {
        return children({
            icon: <BucketColorIcon aria-hidden="true" />,
            label: t(messages.designColorTitle.id)
        });
    }

    return children({
        icon: <BucketColorIcon aria-hidden="true" />,
        label: t(messages.designColorTitle.id)
    });
};

export const templateAndSubstrateColorContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-template-and-substrate-color",
    core: {
        TemplatesAndSubstrates: {
            activeDialogType: ChangeTemplateDialogTypes.Color,
            component: CDZButton
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { PatternContentDiscoveryZoneButton } from "./contentDiscoveryZone/PatternContentDiscoveryZoneButton.js";
import "react/jsx-runtime";
import "./features/patternList/PatternContainer.js";
import "./features/sheetTabs/SheetTabs.js";
import "./contentDiscoveryZone/SmallScreenContentPanel.js";
import { openContentPanelOnItemSelect } from "./contentDiscoveryZone/openContentPanelOnItemSelect.js";
import { name } from "./name.js";
import { PatternItemExtension } from "./extensions/PatternItemExtension.js";
import "./extensions/PatternStore.js";
import { PatternExtension } from "./extensions/PatternExtension.js";
import { usePatternSimOverride } from "./usePatternSimOverride.js";
import { dialogTypes } from "./dialogTypes.js";
import { PatternItemPreviewWrapper } from "./features/patternItemPreview/PatternItemPreviewWrapper.js";
const defaultTranslations = {
  "studio.features.elements.scrollRight": "Scroll right",
  "studio.features.elements.scrollLeft": "Scroll left",
  "studio.features.elements.noResults": `Sorry, we couldn't find any matches for <bold>"[[term]]"</bold>.`,
  "studio.features.elements.noResultsDescription": "Please check your spelling or try searching with a different word.",
  "studio.features.elements.showMore": "Show more",
  "studio.features.elements.error": "We couldn't load these elements - please try again.",
  "sims.pattern.contentDiscoveryZone.closeButtonAccessibilityLabel": "Close",
  "sims.pattern.contentDiscoveryZone.backButtonAccessibilityLabel": "Back",
  "sims.pattern.contentDiscoveryZone.patternTitle": "Patterns",
  "sims.pattern.contentDiscoveryZone.patternDescription": "Apply patterned backgrounds to your entire product at once.",
  "sims.pattern.contentDiscoveryZone.deletePattern": "Delete Pattern",
  "sims.pattern.contentDiscoveryZone.toolbarThemeButtonLabel": "Theme",
  "sims.pattern.contentDiscoveryZone.toolbarResizeButtonLabel": "Resize Pattern",
  "sims.pattern.contentDiscoveryZone.patternsList.allPattern": "All patterns",
  "sims.pattern.contentDiscoveryZone.patternsList.selectPattern": "Select a pattern",
  "sims.pattern.contentDiscoveryZone.patternColorTitle": "Pattern colors",
  "sims.pattern.selectedZoneToolbarConfig.patternRecolorizationIconLabel": "Colors",
  "sims.pattern.contentDiscoveryZone.themePanelTitle": "Themes",
  "sims.pattern.contentDiscoveryZone.themeButtonAriaLabel": "Theme color group and [[colorNames]]",
  "sims.pattern.contentDiscoveryZone.customizeButtonLabel": "Customize",
  "sims.pattern.contentDiscoveryZone.sheetEditPanelLabel": "Edit Pattern",
  "sims.pattern.contentDiscoveryZone.themeTab": "Theme",
  "sims.pattern.contentDiscoveryZone.colorsTab": "Colors",
  "sims.pattern.contentDiscoveryZone.resizeTab": "Resize",
  "sims.pattern.contentDiscoveryZone.shuffleThemeColors": "Shuffle theme colors",
  "studio.features.pattern.resizePanelTitle": "Resize Pattern",
  "studio.features.pattern.resize.scaleLabel": "Scale",
  "studio.features.pattern.resize.pattern": "Rotate",
  "studio.features.pattern.resize.horizontalPositionLabel": "Horizontal Position",
  "studio.features.pattern.resize.verticalPositionLabel": "Vertical Position",
  "studio.features.pattern.contentDiscoveryZone.patternTitleLabel": "Pattern",
  "studio.features.pattern.ida.patternPreviewCanvas": "Pattern preview canvas",
  "studio.features.pattern.ida.patternSheet": "Pattern sheet"
};
const patternSim = {
  name,
  deferredLoader: () => import("./index.deferred.js"),
  core: {
    defaultTranslations,
    extensions: [PatternItemExtension, PatternExtension],
    contentDiscoveryZone: {
      Pattern: {
        activeDialogType: dialogTypes.pattern,
        component: PatternContentDiscoveryZoneButton
      }
    },
    selectedZone: {
      detailZoneIds: /* @__PURE__ */ new Set([{
        type: "ColorPicker",
        activeDialogType: dialogTypes.patternColors
      }, {
        type: "ColorPicker",
        activeDialogType: dialogTypes.patternTheme
      }, {
        type: "General",
        activeDialogType: dialogTypes.patternResize
      }])
    },
    openContentPanelAfterItemSelectionCallback: openContentPanelOnItemSelect,
    useOverrideEnableState: usePatternSimOverride,
    itemPreview: {
      matchSelection: "itemReference/panel",
      ItemPreview: PatternItemPreviewWrapper
    }
  }
};
export {
  patternSim as default,
  dialogTypes
};

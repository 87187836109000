import type { DSS } from "@vp/types-ddif";

/**
 * Remove unsupported clips from the cimdoc.
 * Fixes an issue that causes moved template items to not appear in preview,
 * specifically on the left side of the inside of folded cards.
 */
export function removeClipsFromCimDoc(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if ("clips" in panel) {
            delete panel.clips;
        }
    });
}

import React, { useEffect } from "react";
import { SimsProvider, useSims } from "@internal/sim-framework";
import { observer } from "mobx-react-lite";
import { useStudioConfigurationManager } from "@internal/dex";
import { useAggregateAllSimOverrides } from "./useAggregateAllSimOverrides";
import { ALL_SIM_MODULES } from "./configureSims";

export const SimProviderWrapper = observer(({ children }: React.PropsWithChildren<{}>) => {
    const overrides = useAggregateAllSimOverrides();
    const studioConfigurationManager = useStudioConfigurationManager();

    return (
        <SimsProvider sims={ALL_SIM_MODULES} studioConfigurationManager={studioConfigurationManager} {...overrides}>
            {process.env.NODE_ENV !== "production" ? <SimManagerDebugger /> : null}
            {children}
        </SimsProvider>
    );
});

SimProviderWrapper.displayName = "SimProviderWrapper";

declare global {
    interface Window {
        simsManager: ReturnType<typeof useSims>;
    }
}

const SimManagerDebugger = observer(() => {
    const simsManager = useSims();

    useEffect(() => {
        window.simsManager = simsManager;
    }, [simsManager]);

    return null;
});

SimManagerDebugger.displayName = "SimManagerDebugger";

import type { StudioError } from "./types";

export function getCleanErrorMessage(convertedError: any, errorCode: string) {
    const probableMessage = convertedError.errorMessage || convertedError.message || convertedError;

    let possibleResponseMessage = "";
    try {
        const parsedResponse = JSON.parse(convertedError.replace("Response:", "").replaceAll(" ", ""));
        if (parsedResponse.message || parsedResponse.Message) {
            possibleResponseMessage = `: ${parsedResponse.message || parsedResponse.Message}`;
        }
        // eslint-disable-next-line no-empty -- @todo https://vistaprint.atlassian.net/browse/AST-2427
    } catch {}

    if (
        probableMessage.includes("DSS: Cannot read property 'widthCm' of undefined") ||
        probableMessage.includes("DSS: Product does not have any surfaces defined") ||
        probableMessage.includes("DSS: This product has no design views")
    ) {
        return "No surfaces are defined for this product";
    }
    if (errorCode.includes("20-400") && probableMessage.includes("is not a valid guid")) {
        return "Invalid Work ID";
    }
    if (errorCode.includes("-000")) {
        return `Unable to ${convertedError.friendlyDescription || convertedError.moduleFunction} due to network issues`;
    }
    if (probableMessage.includes("You do not have permission to access WorkId")) {
        return "User does not have permission to edit work";
    }
    if (errorCode.includes("20-401")) {
        return "User is not authenticated when attempting to access work";
    }
    if (
        (errorCode.includes("-54-404") || errorCode.includes("-54-400")) &&
        probableMessage.includes("No completion data")
    ) {
        return "Failed to retrieve complete set of options from RANCH: No completion data found";
    }
    if (errorCode.includes("-54-500")) {
        return "Failed to retrieve complete set of options from RANCH: Internal server error";
    }
    if (errorCode.includes("-54-422") && probableMessage.includes("configuration is invalid")) {
        return "Failed to retrieve complete set of options from RANCH: Provided configuration is invalid";
    }
    if (probableMessage.includes("DSS: There was a problem retrieving merchandising quantities for this product")) {
        return "Error retrieving merchandising quantities for this product while fetching surfaces from DSS";
    }
    if (probableMessage.includes("filterBySameDPS") && probableMessage.includes("Unknown error")) {
        return "Unknown error fetching template ensemble from DSS";
    }
    if (probableMessage.includes("The surface sizes don't match the document or template canvas sizes")) {
        return "Surface dimensions do not match the document dimensions";
    }
    if (probableMessage.includes("does not match `ownerId` in request body")) {
        return "User does not have permission to save another user’s work";
    }
    if (probableMessage.includes("DSS: Product has incomplete list of selectable options")) {
        return "Failed to retrieve surfaces due to incomplete or invalid combination of option selections";
    }
    if (probableMessage.includes("DSS: timeout of")) {
        return "DSS timed out waiting on dependent service";
    }
    if (errorCode.includes("20-500")) {
        return `Work Entity Service encountered internal error${possibleResponseMessage}`;
    }
    if (
        probableMessage.includes("Error occurred while getting quantities from product catalog") &&
        /-5\d{2}/.test(errorCode)
    ) {
        return "Product Catalog Quantity endpoint encountered internal error";
    }
    if (
        /.*surfaces\.products.*5\d{2}.*/.test(probableMessage) ||
        /.*5\d{2}.*surfaces\.products.*/.test(probableMessage)
    ) {
        return "Product Surface Specification Service encountered internal error";
    }
    if (errorCode.includes("-39-")) {
        return `UDS Error${possibleResponseMessage}`;
    }
    if (
        probableMessage.includes("The specified key does not exist") ||
        probableMessage.includes("No data found for mpvId")
    ) {
        return "MPV not found";
    }
    if (errorCode.includes("22-422")) {
        return "Unable to retrieve quantities";
    }
    if (probableMessage.includes("ImportFailureException")) {
        return "Failed to import altDocId";
    }
    if (errorCode === "10-55-24-404" || errorCode === "10-66-24-404") {
        return "Pricing not found";
    }

    return probableMessage;
}

function isStudioError(errObj: any): errObj is StudioError {
    return (errObj as StudioError).message !== undefined;
}

export const getStackTrace = (errObj: unknown) => {
    if (isStudioError(errObj)) {
        return errObj.stack ? errObj.stack.replace("Error: ", "").replace(errObj.message, " ") : "";
    }

    return "";
};

export function getErrorCode(err: any, studioErrorCode: string | number, entityCode: number): string {
    let errorCode = `${entityCode}-${studioErrorCode}`;

    if (err.errorCodeStack) {
        errorCode += `-${err.errorCodeStack}`;
    }

    return errorCode;
}

// parse json object from errObj.message
// or if errObj is string, parse json object from it
// or return errObj, hoping it is a string.
export function parseMessage(errObj: any) {
    try {
        return JSON.parse(errObj.message);
    } catch (e) {
        try {
            return JSON.parse(errObj);
        } catch (f) {
            return errObj;
        }
    }
}

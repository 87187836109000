import { DesignState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { computed, makeObservable } from "mobx";
import {
    BaseExtension,
    DesignExtensionSystem,
    DESIGN_EXTENSION_SYSTEM_TOKEN
} from "@design-stack-vista/interactive-design-engine-core";
import { countColorsPerItem } from "../countColorsPerForItem";

function countColor(count: Map<string, number>, colors?: string[]) {
    if (!colors) {
        return count;
    }

    colors.forEach(color => {
        if (!count.has(color)) {
            count.set(color, 1);
        } else {
            count.set(color, count.get(color)! + 1);
        }
    });

    return count;
}

/**
 * This component that computes the most actively used colors of a given `PanelState`
 */
export class PanelUsedColorsExtension extends BaseExtension {
    declare designState: PanelState;

    static override inject? = [DESIGN_EXTENSION_SYSTEM_TOKEN];

    static supports(state: DesignState) {
        return state.isPanelState();
    }

    constructor(designState: DesignState, private designExtensionSystem: DesignExtensionSystem) {
        super(designState);
        makeObservable(this);
    }

    @computed
    get colorUsage() {
        const colorCounts = this.designState.items.reduce<Map<string, number>>((acc, item) => {
            if (item.isSubpanelState()) {
                return acc;
            }

            const colorsOnItem = countColorsPerItem(item, this.designExtensionSystem);

            return countColor(acc, colorsOnItem);
        }, new Map());

        return colorCounts;
    }
}

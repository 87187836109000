import { retry } from "@internal/utils-network";

export async function waitForImageToBeReady(url: string, retryName: string) {
    let response: Response | undefined;
    try {
        // 202 means the asset is still being generated.  That is the only time we'll retry.  Any other response indicates failure.
        response = await retry<Response>(
            additionalHeaders => fetch(url, { method: "HEAD", headers: additionalHeaders }),
            {
                retryCount: 5,
                interval: 100,
                maxInterval: 2500,
                name: retryName,
                retryWhenNoException: result => result.status === 202
            }
        );
    } catch (e: unknown) {
        if (e instanceof Error) {
            throw new Error(`Stacked monochrome Url Failure (retry): ${e.message}`);
        }
        throw new Error(`Stacked monochrome Url Failure (retry)`);
    }
    // 400 or higher is an error
    // 202 means the asset is still processing but we aren't going to wait forever
    if (response && (response.status >= 400 || response.status === 202)) {
        let msg = `${response.status} unknown`;
        try {
            if (response.status === 401) {
                msg = "401 Unauthorized";
            } else if (response.status === 202) {
                msg = "202 Still Processing";
            }
            // the HEAD request we make does not contain any body to extract more information from so 'unknown' is the best we can do for generic error codes like 400
        } catch {
            /* empty */
        }
        throw new Error(`Stacked monochrome Url Failure: ${msg}`);
    }
}

import { jsx } from "react/jsx-runtime";
import { useCutlineConfiguration } from "@internal/feature-cutline-generation";
import { useActivePanel } from "@design-stack-vista/core-features";
import { useTranslationSSR } from "@vp/i18n-helper";
import { cutlineSimMessages } from "../shared/cutlineSimMessages.js";
import { CutlineIcon } from "./components/CutlinePanel/CutlineIcons/CutlineIcon.js";
import { messages } from "./components/CutlinePanel/CutlineGenerationPanel.js";
const CutlineContentDiscoveryZoneButton = ({
  screenSize,
  children
}) => {
  const {
    setCutlineMessages
  } = /* @__PURE__ */ useCutlineConfiguration();
  const {
    activePanel
  } = /* @__PURE__ */ useActivePanel();
  const currentPanelHasImageItems = /* @__PURE__ */ Boolean(activePanel == null ? void 0 : activePanel.items.find((item) => item.type === "image"));
  const {
    t
  } = /* @__PURE__ */ useTranslationSSR();
  setCutlineMessages(cutlineSimMessages);
  if (screenSize === "largeScreen") {
    return children({
      icon: /* @__PURE__ */ jsx(CutlineIcon, {
        "aria-hidden": "true"
      }),
      label: /* @__PURE__ */ t(messages.panelTitle.id),
      enabled: currentPanelHasImageItems
    });
  }
  return children({
    icon: /* @__PURE__ */ jsx(CutlineIcon, {
      "aria-hidden": "true"
    }),
    label: /* @__PURE__ */ t(messages.panelTitle.id),
    enabled: currentPanelHasImageItems
  });
};
export {
  CutlineContentDiscoveryZoneButton
};

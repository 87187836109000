import { createSlice } from "@reduxjs/toolkit";
import { setSwitchingProject } from "../Actions";
import { showSaveLoader, easelFinishedLoading, hideLoader, showLoader } from "../compoundActions";

const isLoadingSlice = createSlice({
    name: "isLoading",
    initialState: true,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(showLoader, () => true);
        builder.addCase(showSaveLoader, () => true);
        builder.addCase(setSwitchingProject, (state, action) => action.payload.switching ?? false);
        builder.addCase(hideLoader, () => false);
        builder.addCase(easelFinishedLoading, () => false);
    }
});

export const isLoading = isLoadingSlice.reducer;

import { getHash } from "./getHash";

const memoizedFileFromImageUrl = new Map<number, File>();

export const getRasterizedFile = async (imageUrl: string) => {
    const hash = getHash(imageUrl);
    const fileFromCache = memoizedFileFromImageUrl.get(hash);
    if (fileFromCache) {
        return fileFromCache;
    }

    return fetch(imageUrl)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], "file.png", { type: blob.type });
            memoizedFileFromImageUrl.set(hash, file);
            return file;
        });
};

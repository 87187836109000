import { jsx } from "react/jsx-runtime";
import { TextIcon } from "@internal/ui-studio-chrome";
const ThingAContentDiscoveryZoneButton = ({
  screenSize,
  children
}) => {
  return children({
    icon: /* @__PURE__ */ jsx(TextIcon, {
      "aria-hidden": "true"
    }),
    label: "Thing A"
  });
};
const ThingBContentDiscoveryZoneButton = ({
  screenSize,
  children
}) => {
  if (screenSize === "largeScreen") {
    return children({
      icon: /* @__PURE__ */ jsx(TextIcon, {
        "aria-hidden": "true"
      }),
      label: "Thing B"
    });
  }
  return null;
};
export {
  ThingAContentDiscoveryZoneButton,
  ThingBContentDiscoveryZoneButton
};

import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { ColorPaletteType } from "@internal/feature-color-palette";
import { useRecentColors } from "@internal/feature-recent-colors";
import { CMYK, HSV, RGB } from "@design-stack-vista/utility-core";
import { useIsSingleColor, useIsEmbroidery } from "@internal/utils-deco-tech";
import { ColorSpace, StateColorObject } from "@internal/utils-color";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { ColorPickerPanelContent } from "./ColorPickerPanelContent";
import { EyeDropper } from "../EyeDropper/EyeDropper";

export interface ColorPickerProps {
    currentColorValues: StateColorObject;
    onChange: (newColor: string | RGB | CMYK | HSV, colorSpace: ColorSpace) => void;
    allowTransparency?: boolean;
    colorPalette?: ColorPaletteType;
    panelType?: string;
    descriptionTitle?: string;
    enableToggleBackground: boolean;
}

const getConvertedPaletteColors = (colorPalette: ColorPaletteType) => {
    const { entries } = colorPalette;
    const newPaletteColors = entries.map(({ cdifValue, cssBackground }) => {
        return { value: cdifValue, cssBackground };
    });
    return newPaletteColors;
};

export const ColorPickerPanelWrapper = observer((props: ColorPickerProps) => {
    const {
        allowTransparency,
        currentColorValues,
        colorPalette,
        onChange,
        panelType,
        descriptionTitle,
        enableToggleBackground
    } = props;
    const { recentColors } = useRecentColors();
    const isEmbroidery = useIsEmbroidery();
    const isSingleColor = useIsSingleColor();
    const trackingClient = useTrackingClient();
    // only track each component once to avoid spamming tracking events
    const previouslyTrackedComponents = useRef<string[]>([]);

    const newColorPalette = colorPalette ? getConvertedPaletteColors(colorPalette) : [];

    const onEyeDropperSelectColor = (color: string) => {
        onChange(color, ColorSpace.HEX);
    };

    const trackComponentUsage = (component: string) => {
        if (!previouslyTrackedComponents.current.includes(component)) {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: "click:colorPickerPanel",
                label: `Click ${component} to change color`,
                extraData: () => ({ panelType, component })
            });
            previouslyTrackedComponents.current.push(component);
        }
    };

    return (
        <ColorPickerPanelContent
            currentColorValues={currentColorValues}
            onColorChange={onChange}
            paletteColors={newColorPalette}
            eyeDropperNode={<EyeDropper onSelectColor={onEyeDropperSelectColor} />}
            allowTransparency={allowTransparency}
            descriptionTitle={descriptionTitle}
            recentColors={isSingleColor ? [] : recentColors}
            allowCustom={!isEmbroidery && !isSingleColor}
            trackComponentUsage={trackComponentUsage}
            enableToggleBackground={enableToggleBackground}
        />
    );
});

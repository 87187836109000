import React, { createContext, useState, useMemo, useContext, useEffect, useCallback, PropsWithChildren } from "react";
import { ExperimentData, useAbTestContext } from "@internal/ab-test-framework";

type ContextData = {
    isDesignReviewAnatomyABEnabled: boolean;
    trackDesignReviewAnatomyImpression: () => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useDesignReviewAnatomy() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a DesignReviewAnatomyABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Enabled = "test"
}

export const DesignReviewAnatomyExperimentData: ExperimentData = {
    experimentKey: "design_review_anatomy",
    experimentName: "Design Review Anatomy",
    variations: Variations
};

export const DesignReviewAnatomyABProvider = ({ children }: PropsWithChildren) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = DesignReviewAnatomyExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [hasTrackedImpression, setHasTrackedImpression] = useState(false);

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentUsingVariation } = ABTest;

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Enabled);
            setIsEnabled(!!res);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest]);

    const trackDesignReviewAnatomyImpression = useCallback(() => {
        if (!experimentKey || !ABTest || hasTrackedImpression) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
            }
        };

        trackImpressionIfInVariant(Variations.Enabled);
        trackImpressionIfInVariant(Variations.Control);
        setHasTrackedImpression(true);
    }, [experimentKey, ABTest, hasTrackedImpression]);

    const contextObject = useMemo(
        () => ({
            isDesignReviewAnatomyABEnabled: isEnabled,
            trackDesignReviewAnatomyImpression
        }),
        [isEnabled, trackDesignReviewAnatomyImpression]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

DesignReviewAnatomyABProvider.displayName = "DesignReviewAnatomyABProvider";

import { ImageItem } from "@design-stack-vista/cdif-types";
import { UnitlessDimensions } from "@design-stack-vista/utility-core";
import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import type { ImageItemMetadata } from "../types";

export function buildImageMetadataFromUpload(
    upload: VistaAsset,
    dimensions: UnitlessDimensions,
    originalImageData?: ImageItem
): ImageItemMetadata {
    const studioMetadata = {};
    const dclMetadata = {};

    const imageInfo = upload.data?.info;
    const { image, storage } = imageInfo ?? {};
    if (storage) {
        const { contentType, fileName } = storage;
        Object.assign(dclMetadata, {
            mimeType: contentType
        });
        Object.assign(studioMetadata, {
            fileName
        });
    }
    if (image?.format && image.format !== "pdf" && image.format !== "unknown") {
        const isLogo = "isLogo" in image && image.isLogo;
        const isPhoto = "isPhoto" in image && image.isPhoto;
        const isVector = "isVector" in image && image.isVector;
        const lineartness = "lineartness" in image && image.lineartness;
        const width = "width" in image && image.width;
        const height = "height" in image && image.height;

        const naturalDimensions = { width, height };
        Object.assign(studioMetadata, {
            analysis: {
                isLogo,
                isPhoto,
                isVector,
                lineartness
            }
        });
        Object.assign(dclMetadata, {
            naturalDimensions,
            initialSize: dimensions && {
                width: dimensions.width,
                height: dimensions.height
            },
            imageTransformations: {
                appliedProcesses: []
            }
        });
    }

    if (upload.data?.id) {
        Object.assign(dclMetadata, {
            requestId: upload.data.id
        });
    }

    if (originalImageData) {
        Object.assign(studioMetadata, { originalImageData });
    }

    return { dclMetadata, studioMetadata };
}

import { useErrors } from "@internal/utils-errors";
import { getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { getStudioUniqueSessionId } from "@internal/utils-tracking";
import { AlertBox, AlertBoxDismissButton, Box, Link, Typography } from "@vp/swan";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useToastHelpCenterUrl } from "../../hooks";
import { useLocalized } from "../../localized";
import styles from "./Toast.module.scss";

const newRelicWrapper = getScopedNewrelicWrapper("Strawhats");

export const ErrorToast = observer(() => {
    const helpCenterUrl = useToastHelpCenterUrl();
    const localized = useLocalized();
    const {
        errorState: { errorMessage, errorCode, showErrorPage, hideErrorToast },
        resetErrorState
    } = useErrors();

    const [render, setRender] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (errorCode && !showErrorPage && !hideErrorToast) {
            setShow(true);
            setRender(true);
            newRelicWrapper.addPageAction("studio-toast", { errorCode, errorMessage });
        }
    }, [errorCode, errorMessage, showErrorPage, hideErrorToast]);

    const onAnimationEnd = () => {
        if (!render) {
            resetErrorState();
            setShow(false);
        }
    };

    if (showErrorPage || !show) {
        return null;
    }

    return (
        <AlertBox
            skin="error"
            dismissed={!show}
            onRequestDismiss={() => setRender(false)}
            onAnimationEnd={onAnimationEnd}
            className={render ? styles.fadeIn : styles.fadeOut}
            data-testid="errorToast"
        >
            <Box className={styles.toastMessageContainer}>
                <Typography>
                    {localized.get("toastErrorMessage")}
                    <Link href={helpCenterUrl}>{localized.get("contactUs")}</Link>.
                </Typography>
                {errorCode && (
                    <Typography mt={"3"}>
                        {`${localized.get("toastErrorCode", { errorCode })}    `}
                        {`S:${getStudioUniqueSessionId()}`}
                    </Typography>
                )}
            </Box>
            <AlertBoxDismissButton visuallyHiddenLabel={localized.get("closeErrorButton")} />
        </AlertBox>
    );
});
ErrorToast.displayName = "ErrorToast";

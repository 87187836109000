import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const specsAndTemplateModalViewedSlice = createSlice({
    name: "specsAndTemplateModalViewed",
    initialState: false,
    reducers: {
        setSpecsAndTemplateModalViewed: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setSpecsAndTemplateModalViewed } = specsAndTemplateModalViewedSlice.actions;
export const specsAndTemplateModalViewed = specsAndTemplateModalViewedSlice.reducer;

import React from "react";
import type { SimInterface } from "@internal/sim-framework";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TablesIcon } from "@internal/ui-studio-chrome";
import { TableEditingDialogTypes } from "../TableEditingDialogTypes";

const messages = defineMessages({
    tablesTitle: {
        id: "editorUI.addContent.panel.tables.title",
        defaultMessage: "Tables",
        description: {
            note: "Content Panel title text for adding tables"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    return children({
        icon: <TablesIcon aria-hidden="true" />,
        label: t(messages.tablesTitle.id)
    });
};

export const legacyTableSim: SimInterface<"ContentDiscoveryZone" | "SelectedZone"> = {
    name: "@internal/advanced-editor-sim-table-legacy",
    core: {
        extensions: [],
        contentDiscoveryZone: {
            Tables: { activeDialogType: TableEditingDialogTypes.tables, component: CDZButton }
        },
        selectedZone: {
            detailZoneIds: new Set([
                { type: "ColorPicker", activeDialogType: TableEditingDialogTypes.TableBackgroundColorPicker }
            ])
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { CutlineContentDiscoveryZoneButton } from "./contentDiscoveryZone/CutlineContentDiscoveryZoneButton.js";
import { name } from "./simName.js";
import { cutlineActiveDialogTypes } from "./cutlineGenerationContentSymbols.js";
const defaultTranslations = {
  "studio.sims.cutlineGeneration.cutlinePanel.title": "Cutline",
  "studio.sims.cutlineGeneration.cutlinePanel.description": "Select the shape for your sticker.",
  "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.rectangle": "Rectangle",
  "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.circle": "Circle",
  "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.roundedRectangle": "Rounded Rectangle",
  "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.custom": "Custom",
  "studio.sims.cutlineGeneration.cutlineSheet.title": "Cutline Sheet",
  "easel.components.customCutlineGeneration.error": "Custom cutline isn't available. Try another cutline, sticker size or image.",
  "easel.components.basicCutlineGeneration.error": "Basic cutline isn't available. Try another cutline, sticker size or image."
};
const cutlineGenerationSim = {
  name,
  core: {
    defaultTranslations,
    extensions: [],
    contentDiscoveryZone: {
      Cutline: {
        activeDialogType: cutlineActiveDialogTypes["cutline-generation"],
        component: CutlineContentDiscoveryZoneButton
      }
    }
  },
  deferredLoader: () => import("./index.deferred.js")
};
export {
  cutlineGenerationSim as default
};

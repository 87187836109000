import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { allDecorationTechnologies } from "./decorationTechnologies";
import { cmykPrint } from "./decorationTechnologies/cmykPrint";

const DEFAULT_DECORATION_TECHNOLOGY = cmykPrint;

export function getOptionalDecorationTechnology(panel: PanelState | undefined) {
    return panel === undefined
        ? undefined
        : allDecorationTechnologies.find(tech => tech.isActive(panel)) || DEFAULT_DECORATION_TECHNOLOGY;
}

export function getDecorationTechnology(panel: PanelState) {
    const result = getOptionalDecorationTechnology(panel);

    if (result === undefined) {
        throw Error("Unrecognized decoration technology");
    }

    return result;
}

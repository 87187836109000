import { DecorationTechnology } from "@internal/utils-deco-tech";
import type { DesignExperienceManagementAndProductGroupConfigStates } from "../DesignExperienceManagerTypes";

export function applyDecoTechUXOverrides<T extends DesignExperienceManagementAndProductGroupConfigStates>(
    baseConfig: T,
    decorationTechnology: string | undefined,
    locale?: string
): T {
    const localeIsEnglish = locale?.toLowerCase().startsWith("en");

    switch (decorationTechnology) {
        case DecorationTechnology.ScreenPrint:
        case DecorationTechnology.PadPrint:
            return {
                ...baseConfig,
                shouldShowText: true,
                shouldShowQRCodes: false,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,
                shouldShowTeams: false,
                supportsAdjustContrast: true,
                // AI templates only supported for english locales on single color products
                hasAiTemplatesEnabled: baseConfig.hasAiTemplatesEnabled && localeIsEnglish
            };
        case DecorationTechnology.LaserEngraving:
            return {
                ...baseConfig,
                shouldShowText: true,
                shouldShowQRCodes: false,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,
                supportsAdjustContrast: true,
                // AI templates only supported for english locales on single color products
                hasAiTemplatesEnabled: baseConfig.hasAiTemplatesEnabled && localeIsEnglish
            };
        case DecorationTechnology.EmbroideryCylinder:
        case DecorationTechnology.EmbroideryFlat:
            return {
                ...baseConfig,
                shouldShowText: true,
                shouldShowQRCodes: false,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,

                // For some products with multiple decotech that one of them is Embroidery, product grouping returns that AI template is enabled, which is wrong
                // product grouping owners can't guarantee that the value would be accurate if a decotech is added to a PRD
                hasAiTemplatesEnabled: false
            };
        case DecorationTechnology.Unknown:
            return {
                ...baseConfig,
                shouldShowText: true,
                // setting this to true can cause the button to flash and screw up the a/b test
                // since this section is just a fallback (since "unknown" is a misconfiguration) we'll just disable this
                shouldShowBackgroundColor: false
            };
        case DecorationTechnology.DirectToGarment:
            return {
                ...baseConfig,
                shouldShowText: true,
                // QR codes will not print correctly on direct to garment (https://vistaprint.slack.com/archives/C019L6KGWVA/p1675964085913519)
                shouldShowQRCodes: false
            };
        case DecorationTechnology.Digital:
        case DecorationTechnology.HeatTransfer:
        case DecorationTechnology.InkJet:
        case DecorationTechnology.Sublimation:
        case DecorationTechnology.None:
        case DecorationTechnology.Offset:
        case DecorationTechnology.OffsetOrDigital:
        case DecorationTechnology.Thermography:
        default:
            return {
                ...baseConfig,
                shouldShowText: true
            };
    }
}

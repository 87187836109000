import { Events } from "./events";
import { TrackingFunctionParameters } from "./trackingFunctionParameters";

export abstract class BaseTrackingClient {
    /**
     * The studioVersion to include in tracking events
     */
    dexName: string;

    constructor(dexName: string) {
        this.dexName = dexName;
    }

    abstract track<T extends Events>(...args: TrackingFunctionParameters<T>): void;
}

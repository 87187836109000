import React from "react";
import classnames from "classnames";
import { FlexBox } from "@vp/swan";
import styles from "./ErrorDetailsPanel.module.scss";

/* Define custom props for your component */
export type ErrorDetailsPanelProps = React.HTMLAttributes<HTMLDivElement>;

export const ErrorDetailsPanel = ({ children, className, ...rest }: ErrorDetailsPanelProps) => {
    return (
        <FlexBox className={classnames(styles.errorTextContainer, className)} flexDirection="column" {...rest}>
            {children}
        </FlexBox>
    );
};

ErrorDetailsPanel.displayName = "ErrorDetailsPanel";

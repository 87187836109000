import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const loginReasonSlice = createSlice({
    name: "loginReason",
    initialState: null as string | null,
    reducers: {
        setLoginReason(state, action: PayloadAction<string | null>) {
            return action.payload;
        }
    }
});

export const { setLoginReason } = loginReasonSlice.actions;
export const loginReason = loginReasonSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SAVE_STATUS } from "@shared/utils/Save";
import { setSaveSuccess } from "./saveSuccess";

const saveStatusSlice = createSlice({
    name: "saveStatus",
    initialState: SAVE_STATUS.LOADING,
    reducers: {
        setSaveStatus: (state, action: PayloadAction<SAVE_STATUS>) => action.payload
    },
    extraReducers: builder => {
        builder.addCase(setSaveSuccess, (state, action) => {
            if (action.payload && action.payload?.saveSuccess !== null) {
                return action.payload?.saveSuccess ? SAVE_STATUS.CHANGES_SAVED : SAVE_STATUS.CAN_BE_SAVED;
            }
            return state;
        });
    }
});

export const { setSaveStatus } = saveStatusSlice.actions;
export const saveStatus = saveStatusSlice.reducer;

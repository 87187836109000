import { useCallback } from "react";
import { CimDoc, CurveItemV2, ShapeItem } from "@design-stack-vista/cdif-types";
import cloneDeep from "lodash/cloneDeep";
import {
    getRequiredExtension,
    PanelChromesExtension,
    useAvailableDesignEngine
} from "@design-stack-vista/core-features";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { generateOuterCutline } from "./generateOuterCutline";
import {
    OUTER_CUTLINE_GAP_MM,
    INNER_CUTLINE_STROKE_COLOR,
    OUTER_CUTLINE_STROKE_COLOR,
    OUTER_CUTLINE_OVERPRINT,
    CUTLINE_THICKNESS,
    CUTLINE_ERROR_TOLERANCE,
    BACKGROUND_COLOR_SHAPE_ID,
    DESIGN_SUBPANEL_ID,
    CUTLINE_SUBPANEL_ID
} from "./cutlineConstants";
import { isBackgroundItem } from "@design-stack-vista/cimdoc-state-manager";

export const useConvertCutlineDocument = () => {
    const designEngine = useAvailableDesignEngine();
    const { auth } = useIdentityContext();

    const convertToCutlineDocument = useCallback(
        async (cimDoc: CimDoc, productOptions: Record<string, string>, forPreview: boolean) => {
            if (!designEngine) {
                return Promise.reject("Can't convert cimdoc without design engine");
            }

            const convertedCimDoc = cloneDeep(cimDoc);
            const { width, height, images, shapes } = convertedCimDoc.document.panels[0];
            const shouldGenerateWhitePlate = productOptions["White Layer"] === "True";
            const shouldGenerateDoubleCutline = !forPreview && productOptions["Double Cut Line"] === "True";
            const cutlineItems: ShapeItem[] = [];
            const backgroundItems: ShapeItem[] = [];
            let outerCutlineGapMm = OUTER_CUTLINE_GAP_MM;

            const cutlineItem: ShapeItem | undefined = cloneDeep(cimDoc.metadata?.cutlineDetails?.cutlineItem);

            const { masks } = getRequiredExtension(
                designEngine,
                designEngine.cimDocStore.panels[0],
                PanelChromesExtension
            );
            const trimMask = masks.find(mask => mask.type === "TRIM");
            const trimToleranceCm = trimMask?.paths[0].anchor.x;
            const trimToleranceMm = trimToleranceCm ? trimToleranceCm * 10 : 2;

            if (cutlineItem?.stroke) {
                delete cutlineItem.stroke.dashPattern;
                // @ts-ignore -- color is a required property in ShapeItem.Stroke but OuterCutline doesn't have this property
                // So we need to make sure to remove this property.
                delete cutlineItem.stroke.color;

                if (shouldGenerateDoubleCutline) {
                    cutlineItem.stroke.overprints = [INNER_CUTLINE_STROKE_COLOR];
                    cutlineItem.stroke.thickness = CUTLINE_THICKNESS;
                } else {
                    cutlineItem.stroke.overprints = [OUTER_CUTLINE_STROKE_COLOR];
                    cutlineItem.overprints = [OUTER_CUTLINE_OVERPRINT];
                }

                cutlineItems.push(cutlineItem);
            }

            if (cutlineItem && shouldGenerateDoubleCutline) {
                if (
                    cimDoc.metadata?.cutlineDetails.selectedCutlineShape === "Custom" &&
                    OUTER_CUTLINE_GAP_MM === trimToleranceMm
                ) {
                    const actualWidth = parseFloat(width) - 2 * trimToleranceMm;
                    const actualHeight = parseFloat(height) - 2 * trimToleranceMm;
                    const maxActualDimension = Math.max(actualWidth, actualHeight);

                    let maxErrorMargin = 0;
                    if ("viewBox" in cutlineItem) {
                        const { viewBox } = cutlineItem;
                        const viewBoxWidth = parseFloat(viewBox.width);
                        const viewBoxHeight = parseFloat(viewBox.height);
                        const viewBoxX = parseFloat(viewBox.x);
                        const viewBoxY = parseFloat(viewBox.y);

                        const maxViewBoxDimension = Math.max(viewBoxWidth, viewBoxHeight);
                        const minViewBoxPoint = Math.min(viewBoxX, viewBoxY);

                        if (maxViewBoxDimension > maxActualDimension) {
                            maxErrorMargin = Math.max(maxErrorMargin, (maxViewBoxDimension - maxActualDimension) / 2);
                        }

                        if (minViewBoxPoint < trimToleranceMm) {
                            maxErrorMargin = Math.max(maxErrorMargin, trimToleranceMm - minViewBoxPoint);
                        }
                    }

                    outerCutlineGapMm =
                        outerCutlineGapMm -
                        maxErrorMargin -
                        CUTLINE_ERROR_TOLERANCE * Math.max(parseFloat(width), parseFloat(height)) -
                        parseFloat(CUTLINE_THICKNESS);
                }

                let doubleCutlineGenerationFailed = false;

                try {
                    const outerCutline = await generateOuterCutline({
                        cimDoc: convertedCimDoc,
                        innerCutlineItem: cutlineItem,
                        distanceInMm: outerCutlineGapMm,
                        accessToken: auth.getToken()
                    });
                    cutlineItems.push(outerCutline as ShapeItem);
                } catch (err) {
                    doubleCutlineGenerationFailed = true;
                }

                convertedCimDoc.metadata = {
                    ...convertedCimDoc.metadata,
                    doubleCutlineGenerationFailed
                };
            }

            const backgroundItem = shapes?.find(shapeItem => isBackgroundItem(shapeItem, convertedCimDoc.metadata));

            if (backgroundItem && "color" in backgroundItem && backgroundItem.color) {
                // don't add the background shape to the final document if the original image background will cover it and remove relevant metadata
                if (convertedCimDoc.metadata?.cutlineBaseImageVariantType === "saliencyMask") {
                    const bgMetadataIndex = convertedCimDoc.metadata?.template?.findIndex(
                        bgMetadata => bgMetadata.id === backgroundItem.id
                    );
                    if (bgMetadataIndex !== undefined && bgMetadataIndex >= 0) {
                        convertedCimDoc.metadata?.template?.splice(bgMetadataIndex, 1);
                    }
                } else {
                    backgroundItems.push({
                        ...cutlineItem,
                        id: BACKGROUND_COLOR_SHAPE_ID,
                        overprints: undefined,
                        stroke: {
                            thickness: "4mm",
                            color: backgroundItem.color
                        },
                        color: backgroundItem.color,
                        zIndex: 0
                    } as CurveItemV2);
                }
            }

            // remove images, text, and shapes
            convertedCimDoc.document.panels[0].images = [];
            convertedCimDoc.document.panels[0].shapes = [];

            // update metadata
            convertedCimDoc.metadata = {
                ...convertedCimDoc.metadata,
                cutlineDetails: {
                    ...convertedCimDoc.metadata?.cutlineDetails,
                    bgColor: backgroundItems.length > 0 && "color" in backgroundItems[0] ? backgroundItems[0].color : ""
                }
            };

            const { SPOT_COLOR_GENERATORS } = await import("@prepress/custom-cutline-utility");

            // create subpanels
            convertedCimDoc.document.panels[0].subpanels = [
                {
                    id: DESIGN_SUBPANEL_ID,
                    position: {
                        x: "0mm",
                        y: "0mm"
                    },
                    zIndex: 0,
                    images,
                    shapes: backgroundItems,
                    spotColorGenerators: shouldGenerateWhitePlate ? SPOT_COLOR_GENERATORS : undefined
                },
                {
                    id: CUTLINE_SUBPANEL_ID,
                    position: {
                        x: "0mm",
                        y: "0mm"
                    },
                    zIndex: 10,
                    shapes: cutlineItems
                }
            ];

            return convertedCimDoc;
        },
        [auth, designEngine]
    );

    return {
        convertToCutlineDocument
    };
};

import { upgradeV1DesignDocument } from "@internal/data-access-design-specifications-service";
import type { DSS } from "@vp/types-ddif";

/**
 * Performs any updates to the newest DDIF format
 * @param cimDoc
 * @returns
 */
export async function upgradeDDIFDocument(cimDoc: DSS.DesignDocument) {
    if (cimDoc.version === undefined) {
        cimDoc = await upgradeV1DesignDocument(cimDoc);
    }

    return cimDoc;
}

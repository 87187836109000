import { Link, Typography } from "@vp/swan";
import React from "react";
import classNames from "classnames";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { isVatInclusive, DEFAULT_FRACTION_DIGITS } from "@internal/data-access-pricing";
import { RawPrice } from "@vp/vp-tokenized-fragment";
import {
    useActiveFlexibilityOptions,
    FlexibilityDesignAttributes,
    useFlexibilityMerchandisingData
} from "@shared/features/Flexibility";
import { usePricing } from "@internal/feature-pricing";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import * as styles from "./FlexibilityCard.module.scss";

interface FlexibilityProps {
    title: string;
    description?: string;
    designAttributeName?: FlexibilityDesignAttributes;
    previewUrl?: string;
    showPreview?: boolean;
    showPricing?: boolean;
    onClick: () => void;
    className?: string;
}

const messages = defineMessages({
    changeButtonText: {
        id: "studio.components.panels.flexibilityCard.changeButton.text",
        defaultMessage: "Change"
    },
    quantityPricePlaceholder: {
        id: "studio.components.flexibilityCard.quantityPricePlaceholder",
        defaultMessage: "Total price",
        description: {
            note: "Placeholder text for total price"
        }
    },
    quantityPriceAggregator: {
        id: "studio.components.flexibilityCard.quantityPriceAggregator",
        defaultMessage: "for",
        description: {
            note: "Text between quantity and pricing"
        }
    }
});

export const FlexibilityCard = (props: FlexibilityProps) => {
    const {
        title,
        description,
        designAttributeName,
        previewUrl,
        showPreview = true,
        showPricing,
        onClick,
        className
    } = props;
    const { t } = useTranslationSSR();
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const { getCurrentDesignAttributeValue, getProductSize } = useActiveFlexibilityOptions();
    const getFlexibilityMerchandisingData = useFlexibilityMerchandisingData();
    const { locale } = useProductAndProjectStateManager().data;
    const { pricing } = usePricing();
    const vatInc = isVatInclusive();

    if (designAttributeName && !isDesignAttributeActive(designAttributeName)) {
        return null;
    }

    let translatedOptionName;
    let imageName;
    if (designAttributeName) {
        const currentDesignAttributeValue = getCurrentDesignAttributeValue(designAttributeName);
        if (currentDesignAttributeValue) {
            const attributesAndValues = getFlexibilityMerchandisingData(
                designAttributeName,
                currentDesignAttributeValue
            );
            translatedOptionName = currentDesignAttributeValue;

            if (attributesAndValues) {
                translatedOptionName =
                    designAttributeName === FlexibilityDesignAttributes.Size
                        ? getProductSize()
                        : attributesAndValues.merchandisingName;
                if (attributesAndValues.images) {
                    [imageName] = attributesAndValues.images;
                }
            }
        }
    }

    return (
        <div className={classNames(styles.flexibilityCard, className)}>
            {showPreview && (previewUrl || imageName) && (
                <div
                    className={classNames(styles.optionPreview, {
                        [styles.optionPreviewColoredBackground]: !!previewUrl
                    })}
                >
                    <img
                        src={previewUrl || imageName}
                        alt={translatedOptionName}
                        className={classNames({
                            [styles.optionPreviewImg]: !!previewUrl
                        })}
                    />
                </div>
            )}
            <div className={classNames(styles.cardData, { [styles.cardDataFullWidth]: !showPreview })}>
                <div className={styles.cardDataTitleRow}>
                    <Typography fontWeight="bold">{title}</Typography>
                    <Link className={styles.cardDataChangeButton} component="button" onClick={onClick} skin="standard">
                        {t(messages.changeButtonText.id)}
                    </Link>
                </div>
                {(translatedOptionName || description) && (
                    <Typography fontSize="small" textColor="standard">
                        {translatedOptionName || description}
                        {showPricing && pricing && (
                            <>
                                {` ${t(messages.quantityPriceAggregator.id)} `}
                                <RawPrice
                                    placeHolderText={t(messages.quantityPricePlaceholder.id)}
                                    pricingInfo={{
                                        listPrice: pricing.baseSelectionsListPrice,
                                        discountPrice: pricing.baseSelectionsDiscountedPrice,
                                        minimumFractionDigits: DEFAULT_FRACTION_DIGITS,
                                        currency: pricing.currency,
                                        culture: locale,
                                        hideVatMessage: true,
                                        vatInc
                                    }}
                                />
                            </>
                        )}
                    </Typography>
                )}
            </div>
        </div>
    );
};

FlexibilityCard.displayName = "FlexibilityCard";

import { Dimensions } from "@design-stack-vista/interactive-design-engine-core";
import { UnitlessDimensions } from "@design-stack-vista/utility-core";

/**
 * Calculates available space after applying padding percentage.
 *
 * @param boundingBox - The original bounding box dimensions
 * @param paddingPercentage - The padding percentage (0-100)
 * @returns The available width and height after padding
 */
export function getAvailableSpace(boundingBox: UnitlessDimensions, paddingPercentage: number): Dimensions {
    if (paddingPercentage < 0 || paddingPercentage > 100) {
        throw new Error("Padding percentage must be between 0 and 100");
    }

    const availableWidth = boundingBox.width * (1 - paddingPercentage / 100);
    const availableHeight = boundingBox.height * (1 - paddingPercentage / 100);

    return {
        width: availableWidth,
        height: availableHeight
    };
}

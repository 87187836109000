import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SetShowSaveAsPayload {
    showSaveAs: boolean;
    isDuplicatingDesign?: boolean;
    isRenamingProject?: boolean;
}

const showSaveAsSlice = createSlice({
    name: "showSaveAs",
    initialState: false,
    reducers: {
        setShowSaveAs: (state, action: PayloadAction<SetShowSaveAsPayload>) => action.payload.showSaveAs
    }
});

export const { setShowSaveAs } = showSaveAsSlice.actions;
export const showSaveAs = showSaveAsSlice.reducer;

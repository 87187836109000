// 10 seconds
const WORK_ID_EXPIRATION = 10 * 1000;

const isWorkEntityFresh = (workEntity: { timestamp: string | number | Date }) => {
    const workAge = new Date().valueOf() - new Date(workEntity.timestamp).valueOf();
    return workAge < WORK_ID_EXPIRATION;
};

export function getWorkEntityFromSession(workId: string) {
    try {
        if (window && window.sessionStorage) {
            const sessionWorkText = sessionStorage.getItem(`workId:${workId}`);
            sessionStorage.removeItem(`workId:${workId}`);
            const sessionWork = sessionWorkText !== null ? JSON.parse(sessionWorkText) : null;
            if (sessionWork && sessionWork.work && sessionWork.timestamp && isWorkEntityFresh(sessionWork)) {
                return sessionWork.work;
            }
        }
        // eslint-disable-next-line no-empty -- This function is an optimization, it's okay if they fail silently
    } catch (e) {}
    return null;
}

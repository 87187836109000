import type { AnyTextContent } from "@design-stack-vista/cdif-types";
import type { DSS } from "@vp/types-ddif";

function recursivelyRemoveColorForRaisedFoil(content: AnyTextContent[]) {
    content.forEach((field: AnyTextContent) => {
        if (field.overprints && field.overprints.length > 0 && field.overprints[0].includes("RaisedFoil")) {
            field.overprints = field.overprints.slice(0, 1);
            // @ts-ignore (cdif types define color as a required field)
            delete field.color;
        }
        if (field.content && Array.isArray(field.content)) {
            recursivelyRemoveColorForRaisedFoil(field.content);
        }
    });
}

export function updateCimdocForRaisedFoilColor(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                // @ts-expect-error -- textFields are not part of the cimdoc spec but can occur on older documents or templates, so to be safe we check for both
                recursivelyRemoveColorForRaisedFoil(textArea.content || textArea.textFields);
            });
        }
    });
}

import React, { useMemo } from "react";

import { getSVGPath } from "../utils";
import { Section } from "../types";

interface NonDesignAreaProps {
    section: Section;
    width: number;
    height: number;
    strokeWidth: number;
}

function computeDiagonalLine(strokeWidth: number) {
    return `M${strokeWidth + 1},1 l-2,-2
    M0,0 l${strokeWidth},${strokeWidth}
    M1,${strokeWidth + 1} l-2,-2`;
}

enum HiddenPatternTheme {
    STROKE_COLOR = "#AEB2B6",
    BACKGROUND_COLOR = "transparent"
}

const StrokeWidthMultiplicativeValue = 5;

export const NonDesignableArea = ({ section, height, width, strokeWidth }: NonDesignAreaProps) => {
    const hiddenPaths = useMemo(() => getSVGPath(section.paths), [section]);
    const patternStoke = useMemo(() => strokeWidth * StrokeWidthMultiplicativeValue, [strokeWidth]);
    const hiddenPatternId = `hidden-pattern-${section.id}`;

    return (
        <svg
            viewBox={`0 0 ${width} ${height}`}
            data-attribute={`studio3d-${section.name}-non-designable`}
            aria-labelledby={`studio3d-${section.name}-non-designable`}
        >
            <title id={`studio3d-${section.name}-non-designable`}>{section.name}</title>
            <pattern
                id={hiddenPatternId}
                x="0"
                y="0"
                width={patternStoke}
                height={patternStoke}
                patternUnits="userSpaceOnUse"
            >
                <rect
                    x="0"
                    y="0"
                    width={patternStoke}
                    height={patternStoke}
                    fill={HiddenPatternTheme.BACKGROUND_COLOR}
                />
                <path
                    d={computeDiagonalLine(patternStoke)}
                    stroke={HiddenPatternTheme.STROKE_COLOR}
                    strokeWidth={strokeWidth}
                />
            </pattern>
            <path d={hiddenPaths} fill={`url(#${hiddenPatternId})`} />
        </svg>
    );
};

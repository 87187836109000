import { useContext } from "react";
import { SimsContext } from "./SimsProvider";

export const useSims = () => {
    const simsManager = useContext(SimsContext);
    if (simsManager === undefined) {
        throw new Error("Calls to useSims must be wrapped inside a <SimsProvider />");
    }
    return simsManager;
};

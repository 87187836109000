import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showPremiumFinishMaskUploadModalSlice = createSlice({
    name: "showPremiumFinishMaskUploadModal",
    initialState: false,
    reducers: {
        setShowPremiumFinishMaskUploadModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowPremiumFinishMaskUploadModal } = showPremiumFinishMaskUploadModalSlice.actions;
export const showPremiumFinishMaskUploadModal = showPremiumFinishMaskUploadModalSlice.reducer;

import React, { createContext, useContext } from "react";

interface ToastHelpCenterUrlProviderProps extends React.PropsWithChildren {
    helpCenterUrl?: string;
}

const toastHelpCenterUrlContext = createContext<string | undefined | null>(null);

export function ToastHelpCenterUrlProvider({ helpCenterUrl, children }: Readonly<ToastHelpCenterUrlProviderProps>) {
    const { Provider } = toastHelpCenterUrlContext;

    return <Provider value={helpCenterUrl}>{children}</Provider>;
}

export const useToastHelpCenterUrl = () => {
    const ctx = useContext(toastHelpCenterUrlContext);
    if (ctx === null) {
        throw new Error("Component must be wrapped into <ToastHelpCenterUrlProvider> to use toastHelpCenterUrl");
    }
    return ctx;
};

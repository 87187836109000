import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// when there are no items with premium finish on canvas, the warning turns on and state goes true.

const isPremiumFinishWarningOnSlice = createSlice({
    name: "isPremiumFinishWarningOn",
    initialState: false,
    reducers: {
        setPremiumFinishWarning: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setPremiumFinishWarning } = isPremiumFinishWarningOnSlice.actions;
export const isPremiumFinishWarningOn = isPremiumFinishWarningOnSlice.reducer;

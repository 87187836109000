import isEqual from "lodash/isEqual";
import type { IReactionDisposer } from "mobx";
import { action, comparer, computed, flow, flowResult, makeObservable, observable, reaction } from "mobx";
import type { ColorAdjustment, ImageItem, Item } from "@design-stack-vista/cdif-types";
import type { DesignState, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { getColorAdjustmentWithLightnessCorrection, isImageItem } from "@design-stack-vista/cimdoc-state-manager";
import type { HSL } from "@design-stack-vista/utility-core";
import {
    boundHue,
    filterDistinctiveRgbPalette,
    getImageAsPromise,
    rgb2hsl,
    RGB
} from "@design-stack-vista/utility-core";
import type { DesignExtensionSystem } from "@design-stack-vista/interactive-design-engine-core";
import {
    BaseExtension,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    ItemSelectionExtension
} from "@design-stack-vista/interactive-design-engine-core";
import { decorateItemPreviewModel, type ItemPreviewModelDecorator } from "@design-stack-vista/core-features";
import { isSherbertAssetUrl } from "@design-stack-ct/assets-sdk";
import { defaultColorAdjustment } from "./helpers";
import { getColorPaletteFromImage } from "./utilities";

type CancellablePromise<T> = Promise<T> & { cancel(): void };
function catchCancellation(promise: CancellablePromise<void>) {
    promise.catch(() => {});
    return promise;
}

/**
 * This file was copied from @design-stack-vista/core-features ImageColorsExtension and modified to be used with instant uploads and to retrieve full list of colors.
 */

type ImageColorsStatus = "loading" | "ready" | "modified" | "failed";

/**
 * It was decided at some point that the results of color adjustment look better if we fudge with the lightness. This function does that logic.
 */
function normalizeColorAdjustment(colorAdjustment: ColorAdjustment): ColorAdjustment {
    let { lightnessOffset, lightnessMultiplier } = colorAdjustment;

    if (lightnessOffset > 0) {
        lightnessMultiplier = 1 + lightnessOffset;
        lightnessOffset = 0;
    }

    return {
        ...colorAdjustment,
        lightnessOffset,
        lightnessMultiplier
    };
}

export class ImageColorsExtension extends BaseExtension implements ItemPreviewModelDecorator<Item> {
    declare designState: ItemState<ImageItem>;

    static override inject? = [DESIGN_EXTENSION_SYSTEM_TOKEN];

    static supports(state: DesignState): boolean {
        return state.isImageItem();
    }

    /**
     * The `intialColor` serves different purposes depending on whether the image contains a single color.
     *   * For single-color images (e.g. clip-art), it represents the base color of the image before it is modified (e.g. via a color palette component)
     *   * For multi-colored images, it's mostly used to get an intial hue to be used on a color sliders component for tinting the HSL values.
     */
    @observable initialColor?: HSL;

    /**
     * Images with only one color, like clip-art, will have this boolean set to `true`.
     */
    @observable isSingleColor?: boolean;

    /**
     * Images with a temporary preview url will have this set to 'true'. Once the instant upload has finished processing, the preview url should become a sherbert url.
     */
    @observable isInstantUpload?: boolean;

    /**
     * List of colors image contains
     */
    @observable colors?: RGB[];

    /**
     * A local store of an image item's `colorAdjustment` property that can be used and updated without having to update the cimDoc on every change of a slider.
     */
    @observable colorAdjustment: ColorAdjustment = { ...defaultColorAdjustment };

    @observable private status: ImageColorsStatus = "loading";

    private pendingColorRead: CancellablePromise<void>;

    private disposeImageReplaceReaction: IReactionDisposer;

    private disposeColorCommitReaction: IReactionDisposer;

    private disposeSelectionChangeReaction: IReactionDisposer;

    constructor(designState: DesignState, private designExtensionSystem: DesignExtensionSystem) {
        super(designState);

        makeObservable(this);

        /**
         * Whenever the image item's source `previewUrl` (NOT the item preview) changes, rerun the calculation to fetch its colors.
         * This will typically happen via replacement, and thus should only rerun rarely per-item.
         */
        this.disposeImageReplaceReaction = reaction(
            () => this.designState.model.previewUrl,
            () => {
                if (this.pendingColorRead) {
                    this.pendingColorRead.cancel();
                }

                /**
                 * The instant upload's preview url will become a sherbert asset when it is finished processing.
                 * Skip extracting image colors again to avoid resetting them, we already have them.
                 */
                if (this.isInstantUpload && isSherbertAssetUrl(this.designState.model.previewUrl)) {
                    this.isInstantUpload = false;
                    return;
                }

                this.pendingColorRead = catchCancellation(flowResult(this.extractImageColors()));
            }
        );

        /**
         * When the item's colorAdjustment changes on the cimDoc, update the local (normalized) colorAdjustment and set the status back to "ready"
         */
        this.disposeColorCommitReaction = reaction(
            () => this.designState.model.colorAdjustment,
            colorAdjustment => {
                this.colorAdjustment = normalizeColorAdjustment({ ...defaultColorAdjustment, ...colorAdjustment });

                if (this.hasUnsavedChanges) {
                    this.status = "ready";
                }
            },
            { equals: comparer.shallow }
        );

        /**
         * If the user deselects the item without actually commiting the changes to the colorAdjustment,
         * then reset the local colorAdjustment back to what's stored on the model
         */
        this.disposeSelectionChangeReaction = reaction(
            () => this.isSelected,
            isSelected => {
                if (isSelected) {
                    return;
                }

                if (this.hasUnsavedChanges) {
                    this.colorAdjustment = normalizeColorAdjustment({
                        ...defaultColorAdjustment,
                        ...this.designState.model.colorAdjustment
                    });
                    this.status = "ready";
                }
            }
        );

        this.pendingColorRead = catchCancellation(flowResult(this.extractImageColors()));
    }

    /**
     * Uses the `getColorPaletteFromImage` function (powered by color thief) to calculate the dominant colors in the image.
     */
    @flow
    private *extractImageColors() {
        this.status = "loading";
        this.initialColor = undefined;
        this.isSingleColor = undefined;
        this.isInstantUpload = false;

        this.colorAdjustment = normalizeColorAdjustment({
            ...defaultColorAdjustment,
            ...this.designState.model.colorAdjustment
        });

        const image: HTMLImageElement = this.designState.model.previewUrl
            ? yield getImageAsPromise(this.designState.model.previewUrl)
            : undefined;

        // ignoring this rule becuase we have a polyfill for requestIdleCallback for safari, but compat doesn't know that

        requestIdleCallback(() => {
            try {
                if (this.designState.model.previewUrl) {
                    const colorMap = filterDistinctiveRgbPalette(getColorPaletteFromImage(image));
                    this.initialColor = rgb2hsl(colorMap[0]); // Most prevelant color is first in the array
                    this.isSingleColor = colorMap.length === 1;
                    this.isInstantUpload = this.designState.model.previewUrl.startsWith("blob:");
                } else {
                    this.colors = [];
                }
                this.status = "ready";
            } catch {
                this.status = "failed";
            }
        });
    }

    @action.bound
    setColorAdjustment({ h, s, l }: HSL) {
        if ((this.status !== "ready" && this.status !== "modified") || !this.enabled) {
            return;
        }

        const initialHue = this.initialColor!.h;

        this.colorAdjustment.hueOffset = h - initialHue;
        this.colorAdjustment.saturationMultiplier = s * 2;
        this.colorAdjustment.lightnessMultiplier = l * 2;

        this.status = "modified";
    }

    /**
     * Returns the current HSL values for the color adjustment, accounting for differences in business logic for images containing a single color (e.g. clip-art)
     */
    @computed
    get currentHsl() {
        if ((this.status !== "ready" && this.status !== "modified") || !this.enabled) {
            return undefined;
        }

        const initialHue = this.initialColor!.h;
        const { colorAdjustment: originalColorAdjustment } = this.designState.model;

        if (this.isSingleColor) {
            return originalColorAdjustment
                ? {
                      h: originalColorAdjustment.hueOffset,
                      s: originalColorAdjustment.saturationOffset,
                      l: originalColorAdjustment.lightnessOffset
                  }
                : this.initialColor;
        }

        if (this.hasUnsavedChanges) {
            return {
                h: boundHue(this.colorAdjustment!.hueOffset + initialHue),
                s: this.colorAdjustment!.saturationMultiplier / 2,
                l: this.colorAdjustment!.lightnessMultiplier / 2
            };
        }

        let lightnessMultiplier = originalColorAdjustment?.lightnessMultiplier ?? 1;
        if (originalColorAdjustment && originalColorAdjustment.lightnessOffset > 0) {
            lightnessMultiplier = originalColorAdjustment.lightnessOffset + 1;
        }

        return {
            h: boundHue((originalColorAdjustment?.hueOffset ?? 0) + initialHue),
            s: (originalColorAdjustment?.saturationMultiplier ?? 1) / 2,
            l: lightnessMultiplier / 2
        };
    }

    @computed
    get hasColorAdjustment() {
        return !isEqual(this.colorAdjustment, defaultColorAdjustment);
    }

    @computed
    get hasUnsavedChanges() {
        return this.status === "modified";
    }

    @computed
    get isReady() {
        return this.status === "ready";
    }

    @computed
    get isLoading() {
        return this.status === "loading";
    }

    @computed
    get isFailed() {
        return this.status === "failed";
    }

    @computed
    get enabled() {
        // Images without a previewUrl (e.g. premium finish with opaque overlays) cannot have color adjusted.
        return this.designState.model.previewUrl != null;
    }

    [decorateItemPreviewModel](previewModel: Item): void {
        if (!this.hasUnsavedChanges || !isImageItem(previewModel) || !this.isSelected) {
            return;
        }

        previewModel.colorAdjustment = getColorAdjustmentWithLightnessCorrection(this.colorAdjustment);
    }

    @computed
    private get isSelected() {
        const selectionExtension = this.designExtensionSystem.getExtension(
            this.designState.iid,
            ItemSelectionExtension
        );

        return selectionExtension?.isSelected ?? false;
    }

    dispose() {
        super.dispose();

        this.disposeImageReplaceReaction();
        this.disposeColorCommitReaction();
        this.disposeSelectionChangeReaction();

        this.pendingColorRead.cancel();
    }
}

import { toJS } from "mobx";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useCutlineConfiguration } from "./CutlineConfigurationProvider";

export const useGetCutlineMetadata = () => {
    const designEngine = useAvailableDesignEngine();
    const { setImageData } = useCutlineConfiguration();

    if (!designEngine) {
        return {};
    }

    const {
        cimDocStore: { metadata }
    } = designEngine;

    setImageData(metadata?.cutlineDetails?.cachedImageData);

    return {
        cutlineItem: metadata?.cutlineDetails?.cutlineItem,
        cutlinePath: metadata?.cutlineDetails?.cutlinePath,
        cutlineShape: metadata?.cutlineDetails?.selectedCutlineShape,
        imageVariantType: metadata?.imageVariantType,
        cutlineBaseImageVariantType: metadata?.cutlineBaseImageVariantType,
        cachedImageData: toJS(metadata?.cutlineDetails?.cachedImageData),
        backgroundRemovedSuccess: Boolean(metadata?.cutlineDetails?.cachedImageData?.backgroundRemovedImageItem)
    };
};

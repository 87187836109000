import React, { useCallback, useEffect } from "react";
import { INPMetricWithAttribution, Metric, onFCP } from "web-vitals";
import { onINP } from "web-vitals/attribution";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { shouldTrack } from "@shared/utils/Tracking";

export function Performance() {
    const trackingClient = useTrackingClient();

    const sendFCPToAnalytics = useCallback(
        (metric: Metric) => {
            const fcp = metric.value; // first contentful paint from https://web.dev/vitals/
            trackingClient.track(Events.StudioPerformance, {
                eventDetail: "First Contentful Paint",
                label: "FCP",
                timeSinceLoad: fcp
            });
        },
        [trackingClient]
    );

    // this is taken from https://web.dev/articles/find-slow-interactions-in-the-field
    const sendSlowINPToNewRelic = useCallback(({ value, id, attribution }: INPMetricWithAttribution) => {
        // Destructure the attribution object
        const { eventEntry, eventTarget, eventType, loadState } = attribution;

        if (eventEntry) {
            // Get timings from the event timing entry
            const { startTime, processingStart, processingEnd, duration, interactionId } = eventEntry;

            const eventParams = {
                // The page's INP value:
                metric_inp_value: value,
                // A unique ID for the page session, which is useful
                // for computing totals when you group by the ID.
                metric_id: id,
                // The event target (a CSS selector string pointing
                // to the element responsible for the interaction):
                metric_inp_event_target: eventTarget,
                // The type of event that triggered the interaction:
                metric_inp_event_type: eventType,
                // Whether the page was loaded when the interaction
                // took place. Useful for identifying startup versus
                // post-load interactions:
                metric_inp_load_state: loadState,
                // The time (in milliseconds) after page load when
                // the interaction took place:
                metric_inp_start_time: startTime,
                // When processing of the event callbacks in the
                // interaction started to run:
                metric_inp_processing_start: processingStart,
                // When processing of the event callbacks in the
                // interaction finished:
                metric_inp_processing_end: processingEnd,
                // The total duration of the interaction. Note: this
                // value is rounded to 8 milliseconds of granularity:
                metric_inp_duration: duration,
                // The interaction ID assigned to the interaction by
                // the Event Timing API. This could be useful in cases
                // where you might want to aggregate related events:
                metric_inp_interaction_id: interactionId
            };

            // we'll only track interactions that are slow, in this case longer than 200 ms
            // interactions longer than 200 ms are considered 'needs improvement'
            if (value >= 200) {
                newRelicWrapper.addPageAction("studio-slow-inp", eventParams);
            }
        }
    }, []);

    useEffect(() => {
        onFCP(sendFCPToAnalytics);
    }, [sendFCPToAnalytics]);

    useEffect(() => {
        // only track 10% of sessions to reduce NR usage
        if (shouldTrack()) {
            // onINP will not fire for every interaction unfortunately, even with attribution.
            // instead it will fire when INP is calculated for the page (when visibility changes, possibly when the page unloads)
            // reportAllChanges configures onINP to fire whenever the INP changes as well in order to get a bit more information
            onINP(sendSlowINPToNewRelic, { reportAllChanges: true });
        }
    }, [sendSlowINPToNewRelic]);

    return <></>;
}

Performance.displayName = "Performance";

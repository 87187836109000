import React, { Fragment } from "react";
import type { ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { PanelState, parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { DesignLayer } from "@design-stack-vista/ida-framework";
import { useBreakpointInfo } from "@design-stack-vista/utility-react";
import { useDesignEngine, getOptionalExtension } from "@design-stack-vista/core-features";
import { DielineInfo, PanelSectionSetWithBoundingBox, PanelSectionWithBoundingBox } from "../types";
import { isSectionDesignable } from "../utils";
import { PanelSectionExtension } from "./PanelSectionExtension";
import { NonDesignableArea } from "./NonDesignableArea";
import { PanelSectionMask } from "./PanelSectionMask";

export interface PanelSectionsDesignLayerProps {
    panel: PanelState;
    dieline: DielineInfo;
    mapSection?: (panel: PanelState, dieline: DielineInfo, onClick?: () => void) => ReactNode | ReactNode[];
    onClick?: () => void;
}

function MapSectionOverlays(panel: PanelState, dieline: DielineInfo, onClick?: () => void) {
    const { isSmallDevice } = useBreakpointInfo();
    const { NONDESIGNABLE } = dieline;

    const designEngine = useDesignEngine();
    const panelSectionExtension = getOptionalExtension(designEngine, panel, PanelSectionExtension);

    const strokeWidth = 1 / designEngine.layoutStore.zoom;

    if (!panelSectionExtension || !panelSectionExtension.panelSectionDataForPanel) {
        return null;
    }
    const { width, height } = parsePanelDimensionsToMm(panel);

    const allSectionsFromSectionSet = panelSectionExtension.panelSectionDataForPanel
        ?.map((sectionSet: PanelSectionSetWithBoundingBox) => sectionSet.sections)
        .flat();
    const { selectedSectionId, hoveredSectionId } = panelSectionExtension;

    const handleSectionClick = (sectionId: string) => {
        panelSectionExtension.setSelectedSection(sectionId);
        onClick && onClick();
    };

    return (
        allSectionsFromSectionSet &&
        allSectionsFromSectionSet.map((section: PanelSectionWithBoundingBox) => {
            const isDesignable = section && isSectionDesignable(section);
            const isSelected = section.id === selectedSectionId;
            const isHovered = !isSmallDevice && section.id === hoveredSectionId;
            return section ? (
                <Fragment key={section.id}>
                    {isDesignable ? (
                        <PanelSectionMask
                            key={section.id}
                            name={section.name}
                            width={width}
                            height={height}
                            boundingBox={section.boundingBox}
                            strokeWidth={strokeWidth * 2}
                            isSelected={isSelected}
                            isHovered={isHovered}
                            mouseHoverState={(status: boolean) =>
                                panelSectionExtension.setHoveredSectionId(status ? section.id : null)
                            }
                            onSectionClick={() => {
                                handleSectionClick(section.id);
                            }}
                        />
                    ) : (
                        NONDESIGNABLE?.enabled &&
                        !NONDESIGNABLE.hidden && (
                            <NonDesignableArea
                                width={width}
                                height={height}
                                strokeWidth={strokeWidth}
                                section={section}
                            />
                        )
                    )}
                </Fragment>
            ) : null;
        })
    );
}

export const PanelSectionsDesignLayer = observer(
    ({ panel, dieline, mapSection = MapSectionOverlays, onClick }: PanelSectionsDesignLayerProps) => {
        return (
            <DesignLayer data-attribute="panel-sections-design-layer" name="panel-sections">
                {mapSection(panel, dieline, onClick)}
            </DesignLayer>
        );
    }
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showPDFProofModalSlice = createSlice({
    name: "showPDFProofModal",
    initialState: false,
    reducers: {
        setShowPDFProofModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowPDFProofModal } = showPDFProofModalSlice.actions;
export const showPDFProofModal = showPDFProofModalSlice.reducer;

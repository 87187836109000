import { useIsFlagEnabled } from "@internal/utils-flags";
import { name } from "./simName.js";
const usePrototypeSim = () => {
  const enableMockSim = /* @__PURE__ */ useIsFlagEnabled("mockSim");
  return {
    [name]: enableMockSim
  };
};
export {
  usePrototypeSim
};

/* eslint-disable no-restricted-imports */
// @note ^^ This file merges the two sim shapes into one. Eventually this will be extracted fully into "@internal/advanced-editor-sim-text"
import React from "react";
import type { SimInterface } from "@internal/sim-framework";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TextIcon } from "@internal/ui-studio-chrome";
import { DialogCoreValues } from "@internal/utils-active-dialog";
import textSim from "@internal/advanced-editor-sim-text";
import { TextEditingDialogTypes } from "../../textLikeEditing";

const messages = defineMessages({
    textTitle: {
        id: "editorUI.addContent.panel.text.title",
        defaultMessage: "Text",
        description: {
            note: "Content Panel title text for adding text"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    return children({
        icon: <TextIcon aria-hidden="true" />,
        label: t(messages.textTitle.id)
    });
};

export const legacyTextSim: SimInterface<"ContentDiscoveryZone" | "SelectedZone"> = {
    name: textSim.name,
    core: {
        extensions: [],
        contentDiscoveryZone: {
            Text: { activeDialogType: DialogCoreValues.Text, component: CDZButton }
        },
        selectedZone: {
            detailZoneIds: new Set([{ type: "ColorPicker", activeDialogType: TextEditingDialogTypes.TextColorPicker }])
        }
    },
    api: textSim.api,
    deferredLoader: () => import("./index.deferred")
};

/* eslint-disable no-underscore-dangle */
import qs from "qs";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { removeQueryParam } from "@internal/utils-browser";
import { getDesignViewsForProduct } from "@internal/data-access-design-specifications-service";
import type { SceneConfig } from "@internal/data-access-calcifer";
import { buildTransientSceneUrl, convertSceneUrlToView, View } from "../purcsFallbackUtilities";

/* Purcs is acronym for Product Unified Rendering Context Service
 *  This service will serve scene-related assets based on selected inputs
 */
const purcsEntityCode = 18;

// we have to send some sort of instructions to purcs so that it can determine whether to add the
// ignoreProjection parameter.  just send a fake one that we can replace later.
// This is replaced in filterViews.  An example of usage is in PreviewsContext.
export const FAKE_INSTRUCTIONS = "fakeinstructions";
export const DESIGN_PREVIEW_WIDTH = 1360;

type GetAllViewsParams = {
    purpose: string;
    locale: string;
    width?: number;
    previewInstructionsUri?: string;
    studioSelectedProductOptions: Record<string, string>;
    product: { key: string; version: number };
};

// TODO: (DX-426) figure out a better way to provide a default width
export async function getAllViews({
    purpose,
    locale,
    width = 1360,
    previewInstructionsUri,
    studioSelectedProductOptions,
    product
}: GetAllViewsParams): Promise<{ views: View[] }> {
    const requestParams = {
        purpose: encodeURIComponent(purpose),
        width: previewInstructionsUri && encodeURIComponent(width),
        previewInstructionsUri,
        optionSelections: JSON.stringify(studioSelectedProductOptions),
        productVersion: product.version
    };
    const productKey = product.key;
    const productVersion = product.version;

    const queryString = qs.stringify(requestParams);
    const url = `${PURCS_URL}/${encodeURIComponent(SCENE_SERVICE_TENANT_ID)}/${encodeURIComponent(
        locale
    )}/product/${encodeURIComponent(productKey)}/allViews?${queryString}`;

    try {
        const result = await tryFetch({
            url,
            options: {
                headers: {
                    From: REQUESTOR
                }
            },
            moduleFunction: "purcsClient:getAllViews",
            friendlyDescription: "retrieve scenes",
            entityCode: purcsEntityCode,
            retryCount: 3
        });
        return result;
    } catch {
        if (productVersion === null) {
            throw Error("Product version is not defined");
        }
        const product = await getDesignViewsForProduct(
            productKey,
            productVersion,
            studioSelectedProductOptions,
            locale
        );
        const result = [];

        for (let i = 0; i < product.designViews.length; i++) {
            const view = product.designViews[i];
            // eslint-disable-next-line no-await-in-loop
            const transientSceneUrl = await buildTransientSceneUrl(i, view.heightCm, view.widthCm, width);
            result.push(
                convertSceneUrlToView({
                    renderingOptions: {
                        previewInstructionsUri,
                        width
                    },
                    sceneType: "NoScene",
                    transientSceneUrl,
                    name: view.name
                })
            );
        }

        return { views: result };
    }
}

function buildSrcSet(baseUrl: string, width: number) {
    return [1, 2, 3].map(dpr => `${baseUrl}&width=${dpr * width} ${dpr}x`).join(", ");
}

export function filterViews(views: View[], previewInstructionsUri: string, width = 600) {
    return views.map(view => {
        let builtUrl = removeQueryParam(view._links.image.href, "width");

        // see example in PreviewsContext.js
        if (builtUrl.includes(FAKE_INSTRUCTIONS)) {
            builtUrl = builtUrl.replace(FAKE_INSTRUCTIONS, encodeURIComponent(previewInstructionsUri));
        } else if (!builtUrl.includes("instructions_uri")) {
            builtUrl = `${builtUrl}&instructions_uri=${encodeURIComponent(previewInstructionsUri)}`;
        }
        const urlWithWidth = `${builtUrl}&width=${width}`;

        return {
            title: view._links.image.title || view.name,
            name: view.name,
            src: urlWithWidth,
            srcSet: buildSrcSet(builtUrl, width)
        };
    });
}

export function filterScenePreviews(views: SceneConfig[], previewInstructionsUri: string, width = 600) {
    return views
        .filter(view => !!view.imageUrl)
        .map(view => {
            let builtUrl = removeQueryParam(view.imageUrl!, "width");

            // see example in PreviewsContext.js
            if (builtUrl.includes(FAKE_INSTRUCTIONS)) {
                builtUrl = builtUrl.replace(FAKE_INSTRUCTIONS, encodeURIComponent(previewInstructionsUri));
            } else if (!builtUrl.includes("instructions_uri")) {
                builtUrl = `${builtUrl}&instructions_uri=${encodeURIComponent(previewInstructionsUri)}`;
            }
            const urlWithWidth = `${builtUrl}&width=${width}`;

            return {
                title: view.title || view.name,
                name: view.name,
                src: urlWithWidth,
                srcSet: buildSrcSet(builtUrl, width)
            };
        });
}

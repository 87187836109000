import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showFontBrowserSlice = createSlice({
    name: "showFontBrowser",
    initialState: false,
    reducers: {
        setShowFontBrowser: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowFontBrowser } = showFontBrowserSlice.actions;
export const showFontBrowser = showFontBrowserSlice.reducer;

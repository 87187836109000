var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { action, makeObservable, observable } from "mobx";
import { QRCodeURLPanelMode, QRCodeTabType } from "../shared/types/qrCodeItemRef.js";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp2(target, key, result);
  return result;
};
class QRCodeStore {
  constructor() {
    __publicField(this, "qrCodeURLPanelMode", QRCodeURLPanelMode.Add);
    __publicField(this, "hoverImage", null);
    __publicField(this, "hoverNature", null);
    __publicField(this, "isExtraImageColorEditMode", false);
    __publicField(this, "isLogoColorSame", true);
    __publicField(this, "currentTab", QRCodeTabType.Shape);
    makeObservable(this);
    this.currentTab = QRCodeTabType.Url;
  }
  setURLPanelMode(mode) {
    this.qrCodeURLPanelMode = mode;
  }
  setHoverImage(image) {
    this.hoverImage = image;
  }
  setHoverNature(nature) {
    this.hoverNature = nature;
  }
  setExtraImageColorEditMode(mode) {
    this.isExtraImageColorEditMode = mode;
  }
  setCurrentTab(tab) {
    this.currentTab = tab;
  }
  setIsLogoColorSame(status) {
    this.isLogoColorSame = status;
  }
}
__decorateClass([observable], QRCodeStore.prototype, "qrCodeURLPanelMode", 2);
__decorateClass([observable], QRCodeStore.prototype, "hoverImage", 2);
__decorateClass([observable], QRCodeStore.prototype, "hoverNature", 2);
__decorateClass([observable], QRCodeStore.prototype, "isExtraImageColorEditMode", 2);
__decorateClass([observable], QRCodeStore.prototype, "isLogoColorSame", 2);
__decorateClass([observable], QRCodeStore.prototype, "currentTab", 2);
__decorateClass([action.bound], QRCodeStore.prototype, "setURLPanelMode", 1);
__decorateClass([action.bound], QRCodeStore.prototype, "setHoverImage", 1);
__decorateClass([action.bound], QRCodeStore.prototype, "setHoverNature", 1);
__decorateClass([action.bound], QRCodeStore.prototype, "setExtraImageColorEditMode", 1);
__decorateClass([action.bound], QRCodeStore.prototype, "setCurrentTab", 1);
__decorateClass([action.bound], QRCodeStore.prototype, "setIsLogoColorSame", 1);
const qrCodeStore = new QRCodeStore();
export {
  qrCodeStore
};

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { action, makeObservable, observable, computed } from "mobx";
import { isLineItem, parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { checkIfItemsBlockBackgroundOrPattern } from "@internal/utils-background-color";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp2(target, key, result);
  return result;
};
class PatternItemExtension extends BaseExtension {
  constructor(designState) {
    super(designState);
    __publicField(this, "initialPatternColors", []);
    __publicField(this, "initialPatternUrl", "");
    makeObservable(this);
  }
  static supports(designState) {
    return designState.isPanelState();
  }
  get patternItem() {
    const patternItems = this.designState.items.filter((item) => item.isItemReference() && item.model.type === ItemReferenceTypes.TILE_IMAGE);
    if (patternItems.length > 0) {
      return patternItems[0];
    }
    return void 0;
  }
  get patternUrl() {
    var _a;
    return (_a = this.patternItem) == null ? void 0 : _a.model.data.image.previewUrl;
  }
  get patternFileUrl() {
    if (!this.patternUrl) {
      return null;
    }
    return new URLSearchParams(new URL(this.patternUrl).search).get("fileUrl") || this.patternUrl;
  }
  setInitialPatternColors(patternColors) {
    this.initialPatternColors = [...patternColors];
  }
  setInitialPatternUrl(patternUrl) {
    this.initialPatternUrl = patternUrl;
  }
  get itemBlocksPattern() {
    const currentItems = this.designState.items.filter((item) => item.isItemState() && !isLineItem(item.model) && item.model.position.height && item.model.position.width && (item.isImageItem() || item.isShapeItem() && item.model.color));
    const {
      width,
      height
    } = parsePanelDimensionsToMm(this.designState);
    return checkIfItemsBlockBackgroundOrPattern(currentItems, width, height);
  }
}
__decorateClass([observable], PatternItemExtension.prototype, "initialPatternColors", 2);
__decorateClass([observable], PatternItemExtension.prototype, "initialPatternUrl", 2);
__decorateClass([computed], PatternItemExtension.prototype, "patternItem", 1);
__decorateClass([computed], PatternItemExtension.prototype, "patternUrl", 1);
__decorateClass([computed], PatternItemExtension.prototype, "patternFileUrl", 1);
__decorateClass([action.bound], PatternItemExtension.prototype, "setInitialPatternColors", 1);
__decorateClass([action.bound], PatternItemExtension.prototype, "setInitialPatternUrl", 1);
__decorateClass([computed], PatternItemExtension.prototype, "itemBlocksPattern", 1);
export {
  PatternItemExtension
};

import React, { useContext, createContext, useEffect, useState, useMemo, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ExperimentData, useAbTestContext } from "@internal/ab-test-framework";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

const LOCALE_LIST = [
    "en-ie",
    "es-es",
    "it-it",
    "fr-fr",
    "de-de",
    "de-at",
    "de-ch",
    "de-fr",
    "it-ch",
    "nl-nl",
    "fr-be",
    "nl-be",
    "da-dk",
    "pt-pt",
    "sv-se",
    "fi-fi",
    "nb-no"
];

type ContextData = {
    isMileStone1Enabled: boolean;
    isMileStone2Enabled: boolean;
    isMileStone3Enabled: boolean;
    isTestActive: boolean;
};

const context = createContext<ContextData | undefined>(undefined);

export function useStudioFlexibility() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a StudioFlexibilityABProvider");
    }
    return result;
}
enum Variations {
    Control = "control",
    Enabled = "variation"
}

export const Milestone1ExperimentData: ExperimentData = {
    experimentKey: "flexibility_milestone_1",
    experimentName: "Milestone 1 - Flexibility features",
    variations: Variations
};

export const Milestone2ExperimentData: ExperimentData = {
    experimentKey: "flexibility_milestone_2",
    experimentName: "Milestone 2 - Flexibility features",
    variations: Variations
};

export const Milestone3ExperimentData: ExperimentData = {
    experimentKey: "flexibility_milestone_3",
    experimentName: "Milestone 3 - Flexibility features",
    variations: Variations
};

interface Props {
    useProductOptionTools: boolean;
}

export const StudioFlexibilityABProvider = observer(
    ({ children, useProductOptionTools }: React.PropsWithChildren<Props>) => {
        const { Provider } = context;
        const ABTest = useAbTestContext();
        const { experimentKey: milestone1Key } = Milestone1ExperimentData;
        const { experimentKey: milestone2Key } = Milestone2ExperimentData;
        const { experimentKey: milestone3Key } = Milestone3ExperimentData;
        const [isMileStone1Enabled, setIsMileStone1Enabled] = useState(false);
        const [isMileStone2Enabled, setIsMileStone2Enabled] = useState(false);
        const [isMileStone3Enabled, setIsMileStone3Enabled] = useState(false);
        const [isTestActive, setIsTestActive] = useState(false);

        const { locale } = useProductAndProjectStateManager().data;

        const updateExperiment = useCallback(
            (experimentKey: string, setter: (b: boolean) => void) => {
                if (!experimentKey || !ABTest) {
                    return;
                }
                const { isExperimentUsingVariation, trackImpression, isExperimentActive } = ABTest;
                isExperimentActive(experimentKey).then((active: boolean) => setIsTestActive(active));
                const trackImpressionIfInVariant = async (variation: Variations) => {
                    const res = await isExperimentUsingVariation(experimentKey, variation);
                    const shouldEnableTest = useProductOptionTools && LOCALE_LIST.includes(locale.toLowerCase());
                    if (res && shouldEnableTest) {
                        trackImpression(experimentKey, variation);
                        setter(variation !== Variations.Control);
                    }
                };

                trackImpressionIfInVariant(Variations.Enabled);
                trackImpressionIfInVariant(Variations.Control);
            },
            [ABTest, useProductOptionTools, locale]
        );

        useEffect(
            () => updateExperiment(milestone1Key, setIsMileStone1Enabled),
            [milestone1Key, updateExperiment, ABTest]
        );
        useEffect(
            () => updateExperiment(milestone2Key, setIsMileStone2Enabled),
            [milestone2Key, updateExperiment, ABTest]
        );
        useEffect(
            () => updateExperiment(milestone3Key, setIsMileStone3Enabled),
            [milestone3Key, updateExperiment, ABTest]
        );

        const contextObject = useMemo(
            () => ({
                isMileStone1Enabled,
                isMileStone2Enabled,
                isMileStone3Enabled,
                isTestActive
            }),
            [isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled, isTestActive]
        );

        return <Provider value={contextObject}>{children}</Provider>;
    }
);

StudioFlexibilityABProvider.displayName = "StudioFlexibilityABProvider";

export type Fulfiller = "sim" | "studio";

/**
 * This represents a typical slot schema. A fulfiller (sim or CDZ module)
 * may fill a `type: single` with 1 value and a `type: multiple` with many.
 */
interface SlotSchema {
    /**
     * Defines if a fulfiller can put 1 item (`single`) or many (`multiple`)
     * in a slot. Note that `"single"` types can only be fullfilled once across
     * *all* fulfillers. For example, if Text defines its `type` as `"single"`,
     * this means only 1 SIM or CDZ can fill that slot with a single value.
     * It does not mean that many sims can fill that slot with a single value.
     */
    readonly type: "single" | "multiple";
    /**
     * Defines who is allowed to fulfill this slot
     */
    readonly fulfiller: Fulfiller | Readonly<Fulfiller[]>;
}

/**
 * Slots can be fulfilled by either a "sim",
 * "studio", or both.
 */
export const contentDiscoverySlotSchema = {
    Text: { type: "single", fulfiller: "sim" },
    TeamsName: { type: "single", fulfiller: "sim" },
    Images: { type: "single", fulfiller: "sim" },
    QRCodes: { type: "single", fulfiller: "sim" },
    Cutline: { type: "single", fulfiller: "sim" },
    Tables: { type: "single", fulfiller: "sim" },
    TemplatesAndSubstrates: { type: "single", fulfiller: "studio" },
    Flexibility: { type: "single", fulfiller: "studio" },
    Layers: { type: "single", fulfiller: "studio" },
    /**
     * `"Finish"` is set to `"multiple"` to support different `activeDialogValues`
     * for full bleed premium finish vs. premium finishes
     */
    Finish: { type: "multiple", fulfiller: "studio" },
    Default: { type: "multiple", fulfiller: "sim" },
    Pattern: { type: "single", fulfiller: "sim" },
    Background: { type: "single", fulfiller: "sim" },
    /**
     * Specifying both here is required for Studio and SIMs to offer `"GraphicsAndShapes"`
     * support. However, SIMs do not implement the "single" type slot and instead
     * implement a custom API defined within the SIM framework.
     */
    GraphicsAndShapes: { type: "single", fulfiller: ["sim", "studio"] },
    Designs: { type: "single", fulfiller: "studio" },
    ChangeSize: { type: "single", fulfiller: "studio" }
} as const satisfies Record<string, SlotSchema>;

export type ContentDiscoverySlotSchema = typeof contentDiscoverySlotSchema;

export type ContentDiscoveryAllSlotNames = keyof ContentDiscoverySlotSchema;

type IsFulfilledBy<F extends Fulfiller, T extends SlotSchema> = T extends {
    fulfiller: F;
}
    ? T
    : T["fulfiller"] extends Readonly<Array<string>>
    ? T extends { [K in T["fulfiller"] as K extends F ? K : never]: any }
        ? T
        : never
    : never;

/**
 * This type returns only a subset of `ContentDiscoverySlotSchema`, including
 * only entries of `fulfiller: "sim"`
 */
export type ContentDiscoverySlotSimSchema = {
    /**
     * Iterate over all the keys in `ContentDiscoverySlotSchema`
     * Then, check to see if the value associated with key `ContentDiscoverySlotSchema[K]`
     * has the fulfiller of `"sim"` or not. If so, include that key (`K`), else omit it (`never`)
     */
    [K in keyof ContentDiscoverySlotSchema as IsFulfilledBy<"sim", ContentDiscoverySlotSchema[K]> extends never
        ? never
        : K]: ContentDiscoverySlotSchema[K];
};

export type ContentDiscoverySimSlotNames = keyof ContentDiscoverySlotSimSchema;

/**
 * This type returns only a subset of `ContentDiscoverySlotSchema`, including
 * only entries of `fulfiller: "sim"`
 */
export type ContentDiscoverySlotStudioSchema = {
    /**
     * Iterate over all the keys in `ContentDiscoverySlotSchema`
     * Then, check to see if the value associated with key `ContentDiscoverySlotSchema[K]`
     * has the fulfiller of `"studio"` or not. If so, include that key (`K`), else omit it (`never`)
     */
    [K in keyof ContentDiscoverySlotSchema as IsFulfilledBy<"studio", ContentDiscoverySlotSchema[K]> extends never
        ? never
        : K]: ContentDiscoverySlotSchema[K];
};

export type ContentDiscoveryStudioSlotNames = keyof ContentDiscoverySlotStudioSchema;

import React from "react";
import { Button, ColorSwatches } from "@vp/swan";
import type { CMYK } from "@design-stack-vista/utility-core";
import { useLocalized } from "../localized";
import { ColorSpace } from "@internal/utils-color";
import { TransparentColorSwatch } from "@vp/diy-editor-ui";
import styles from "./NoColorSwatch.module.scss";
import classNames from "classnames";

interface Props {
    /** Is the no color option selected */
    noColorSelected: boolean;
    /** Function called when the No Color button has been clicked */
    onChange?: (value: CMYK, colorSpace: ColorSpace) => void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

export function NoColorSwatch({ onChange = () => {}, noColorSelected, className, trackUsage }: Props) {
    const localized = useLocalized();

    const noColorCmyk = { c: 0, m: 0, y: 0, k: 0, a: 0 };

    const buttonClicked = () => {
        onChange(noColorCmyk, ColorSpace.NoColor);
        if (trackUsage) {
            trackUsage("No Color", noColorCmyk.toString());
        }
    };

    return (
        <ColorSwatches size="super" margin={0}>
            <Button
                size="mini"
                skin="unstyled"
                display="flex"
                buttonShape="round"
                onClick={buttonClicked}
                className={classNames(className, styles.noColor)}
            >
                <TransparentColorSwatch margin={0} display="flex" accessibleText={localized.get("noColor")} />
            </Button>
        </ColorSwatches>
    );
}

NoColorSwatch.displayName = "NoColorSwatch";

import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useErrors } from "@internal/utils-errors";

export const useExtraErrorDetails = () => {
    const {
        errorState: { errorMessage, errorStackTrace, errorExtraData }
    } = useErrors();
    const { productKey, quantity } = useProductAndProjectStateManager().data;

    return {
        productKey,
        Quantity: quantity,
        errorStackTrace,
        errorExtraData,
        errorMessage: (errorMessage as any)?.errorMessage ?? errorMessage?.toString()
    };
};

import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useReducer } from "react";

interface State {
    engravingGuidelineModalOpen: boolean;
    engravingGuidelineModalShown: boolean;
}

const initialState: State = {
    engravingGuidelineModalOpen: false,
    engravingGuidelineModalShown: false
};

enum ActionTypes {
    openEngravingGuidelineModal = "openEngravingGuidelineModal",
    closeEngravingGuidelineModal = "closeEngravingGuidelineModal"
}

interface OpenEngravingGuidelineModalAction {
    type: ActionTypes.openEngravingGuidelineModal;
}

interface CloseEngravingGuidelineModalAction {
    type: ActionTypes.closeEngravingGuidelineModal;
}

type Action = OpenEngravingGuidelineModalAction | CloseEngravingGuidelineModalAction;

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionTypes.openEngravingGuidelineModal: {
            return {
                engravingGuidelineModalOpen: true,
                engravingGuidelineModalShown: state.engravingGuidelineModalShown
            };
        }
        case ActionTypes.closeEngravingGuidelineModal: {
            return {
                engravingGuidelineModalOpen: false,
                engravingGuidelineModalShown: true
            };
        }
        default: {
            throw new Error(`Invalid action specified: ${action}`);
        }
    }
};

interface EngravingContextData {
    state: State;
    closeEngravingGuidelineModal: () => void;
    openEngravingGuidelineModal: () => void;
}

const context = createContext<EngravingContextData | undefined>(undefined);

export function useEngraving() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within an EngravingProvider");
    }
    return result;
}

export const EngravingProvider = ({ children }: PropsWithChildren) => {
    const { Provider } = context;
    const [state, dispatch] = useReducer(reducer, initialState);

    const closeEngravingGuidelineModal = useCallback(() => {
        dispatch({ type: ActionTypes.closeEngravingGuidelineModal });
    }, []);

    const openEngravingGuidelineModal = useCallback(() => {
        dispatch({ type: ActionTypes.openEngravingGuidelineModal });
    }, []);

    const contextObject = useMemo(
        () => ({
            state,
            closeEngravingGuidelineModal,
            openEngravingGuidelineModal
        }),
        [state, closeEngravingGuidelineModal, openEngravingGuidelineModal]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

EngravingProvider.displayName = "EngravingProvider";

import { Commands, SectionPath } from "../types";

export function normalizePath(pathOperations: SectionPath[]): SectionPath[] {
    const normalizedPathOperations: SectionPath[] = [];
    let previousOperation: SectionPath | undefined;
    for (let i = 0; i < pathOperations.length; i++) {
        const originalCPathOperation = pathOperations[i];
        const pathOperationCopy = { ...originalCPathOperation };

        if (
            pathOperationCopy.command === Commands.quadraticCurveTo &&
            previousOperation &&
            pathOperationCopy.x1 &&
            pathOperationCopy.y1
        ) {
            // reference: https://fontforge.org/docs/techref/bezier.html#converting-truetype-to-postscript
            const cp1 = {
                x: previousOperation.x + (2 / 3) * (pathOperationCopy.x1 - previousOperation.x),
                y: previousOperation.y + (2 / 3) * (pathOperationCopy.y1 - previousOperation.y)
            };
            const cp2 = {
                x: pathOperationCopy.x + (2 / 3) * (pathOperationCopy.x1 - pathOperationCopy.x),
                y: pathOperationCopy.y + (2 / 3) * (pathOperationCopy.y1 - pathOperationCopy.y)
            };

            pathOperationCopy.x1 = cp1.x;
            pathOperationCopy.y1 = cp1.y;

            pathOperationCopy.x2 = cp2.x;
            pathOperationCopy.y2 = cp2.y;
        }

        previousOperation = originalCPathOperation;
        normalizedPathOperations.push(pathOperationCopy);
    }

    return normalizedPathOperations;
}

import { useContext } from "react";
import { RecentColorsContext } from "./RecentColorsProvider";

/**
 * Returns the `RecentColorsManager` instance created in the `RecentColorsProvider`.
 */
export const useRecentColors = () => {
    const recentColorsManager = useContext(RecentColorsContext);

    if (recentColorsManager === undefined) {
        throw new Error("Component must be wrapped in a RecentColorsProvider.");
    }

    return recentColorsManager;
};

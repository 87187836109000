export interface StudioError {
    url?: string;
    bodyLength?: string;
    stack?: string;
    message: string;
}

interface ErrorExtraData {
    moduleFunction: string;
    friendlyDescription: string;
}

export interface ErrorPayload {
    errorCode?: string;
    errorMessage?: string;
    showErrorPage?: boolean;
    hideErrorToast?: boolean;
    errorStackTrace?: string | null;
    errorExtraData?: ErrorExtraData;
}

export class ErrorWithStack extends Error {
    constructor(message: string, readonly errorCodeStack: string, readonly stack: any) {
        super(message);
    }
}

export type ErrorHandler = (
    originalError: StudioError,
    studioErrorCode: string | number,
    entityCode: number,
    showErrorPage?: boolean,
    logError?: boolean,
    hideErrorToast?: boolean
) => ErrorWithStack;

import { DSS } from "@vp/types-ddif";

/**
 * If the text area content and the template placeholder holder metadata are the same, strip off the actual content,
 * this text area is considered to be a placeholder based off of logic from cimpress designer/studio5
 * @param cimDoc
 */
export function removeTextAreaPlaceholderContent(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                if (cimDoc.metadata?.template) {
                    const templateMetadata = cimDoc.metadata?.template.find(
                        templateMetadata => templateMetadata.id === textArea.id
                    );
                    if (textArea.content[0].content === templateMetadata?.placeholder) {
                        textArea.content[0].content = "";
                    }
                }
            });
        }
    });
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const hasDesignsSlice = createSlice({
    name: "hasDesigns",
    initialState: false as boolean,
    reducers: {
        setHasDesigns: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setHasDesigns } = hasDesignsSlice.actions;
export const hasDesigns = hasDesignsSlice.reducer;

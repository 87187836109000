import { retry } from "@internal/utils-network";

function bufferToBase64(buf: any) {
    const binstr = Array.prototype.map.call(buf, (ch: any) => String.fromCharCode(ch)).join("");
    return btoa(binstr);
}
export async function encodeForRenderingApis<T>(object: T) {
    const dataJson = JSON.stringify(object);
    const dataDeflated = await retry(() => import("pako"), { name: "import" }).then((pako: any) =>
        pako.deflateRaw(dataJson)
    );
    return bufferToBase64(dataDeflated);
}

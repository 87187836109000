import React from "react";
import classNames from "classnames";

import { getTransformedColorList, isUnique } from "../utils";
import styles from "./FillColorsIcon.module.scss";

const MAX_COLORS_IN_FILL_BUTTON = 4;

interface FillColorsIconProps {
    className?: string;
    colors: string[];
}

export function FillColorsIcon({ className = styles.square, colors }: FillColorsIconProps) {
    const elementColors = colors
        .map(color => color)
        .filter(isUnique)
        .slice(0, MAX_COLORS_IN_FILL_BUTTON);

    const transformedColorList = getTransformedColorList(elementColors);

    return (
        <div className={classNames(styles.fillColorsIconWrapper, className)}>
            {transformedColorList.map((color, index) => (
                <div key={index} style={{ backgroundColor: color }} className={styles.color} />
            ))}
        </div>
    );
}

FillColorsIcon.displayName = "FillColorsIcon";

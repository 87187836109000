import { useCallback } from "react";
import { useSurfaceUpsellData } from "./SurfaceUpsellDataProvider";

export function useHasAvailableUpsell() {
    const { surfaceUpsellData } = useSurfaceUpsellData();

    return useCallback(
        (name: string) => {
            const surfaceUpsell = surfaceUpsellData[name];
            return !!surfaceUpsell;
        },
        [surfaceUpsellData]
    );
}

import { mergeColorOverrides } from "../shared/utils/mergeColorOverrides.js";
import { COLOR_SWATCHES_LIMIT } from "../constants.js";
import { getCimDocNodeById } from "@design-stack-vista/cimdoc-state-manager";
import { NewrelicError } from "@internal/utils-newrelic";
import { isVectorItem } from "../shared/utils/vectorItemTypeGuard.js";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/core-features";
import { ERROR_CODES } from "@internal/utils-errors";
import { newrelic } from "../logger.js";
const colorOverridesMatch = (override1, override2, keepOldValues) => {
  return override1.length === override2.length && override1.every((o1) => override2.some((o2) => {
    const o1Keys = /* @__PURE__ */ Object.keys(o1);
    const o2Keys = /* @__PURE__ */ Object.keys(o2);
    return keepOldValues ? o1Keys[0] === o2Keys[0] : o1Keys[0] === o2Keys[0] && o1[o1Keys[0]] === o2[o2Keys[0]];
  }));
};
function setColorOverrides(cimDoc, itemReferenceId, overrides, keepOldValues) {
  const node = /* @__PURE__ */ getCimDocNodeById(cimDoc, itemReferenceId);
  if (!node) {
    newrelic.noticeError(new NewrelicError("No item reference is associated with the given item reference ID.", {
      code: ERROR_CODES.VECTORS
    }), {
      itemReferenceId
    });
    throw new Error(`No item reference found with the matching ID: ${itemReferenceId}`);
  }
  const {
    element: item
  } = node;
  if (!isVectorItem(item)) {
    newrelic.noticeError(new NewrelicError("No vector item reference passed to setColorOverrides.", {
      code: ERROR_CODES.VECTORS
    }), {
      item: /* @__PURE__ */ JSON.stringify(item)
    });
    throw new Error("Only vector item references support setting color overrides at this time.");
  }
  if (!Array.isArray(overrides)) {
    const message = "Overrides must be an array of strings.";
    newrelic.noticeError(new NewrelicError(message, {
      code: ERROR_CODES.VECTORS
    }), {
      overrides: /* @__PURE__ */ JSON.stringify(overrides)
    });
    throw new Error(message);
  }
  item.data.colorOverrides = /* @__PURE__ */ mergeColorOverrides(item.data.colorOverrides, overrides, keepOldValues).slice(0, COLOR_SWATCHES_LIMIT);
}
export {
  colorOverridesMatch,
  setColorOverrides
};

import { useMemo } from "react";
import type { AfterDuplicatePanelCallback, BaseSim } from "./BaseSim";
import { useSims } from "./useSims";
import type { StudioConfigurationState } from "@internal/utils-studio-configuration-core";

interface SimInterfaceWithUseAfterDuplicatePanel extends Omit<BaseSim, "core"> {
    core: Omit<NonNullable<BaseSim["core"]>, "useAfterDuplicatePanel"> & {
        afterDuplicatePanelCallback: AfterDuplicatePanelCallback;
    };
}

const isSimInterfaceWithUseAfterDuplicatePanel = (sim: BaseSim): sim is SimInterfaceWithUseAfterDuplicatePanel => {
    return Boolean(sim.core.afterDuplicatePanelCallback);
};

export const useSimAfterDuplicatePanel = (
    configuration: StudioConfigurationState
): ReturnType<AfterDuplicatePanelCallback>[] => {
    const simsManager = useSims();

    return useMemo(
        () =>
            simsManager.activeSims
                .filter(isSimInterfaceWithUseAfterDuplicatePanel)
                .map(sim => sim.core.afterDuplicatePanelCallback(configuration)),
        [configuration, simsManager]
    );
};

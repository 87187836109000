import { produce } from "immer";
import type { DecorationTechnology } from "@internal/utils-deco-tech";
import { defaultConfigValues } from "./defaultDesignExperienceConfig";
import type { StudioConfigurationState, DesignExperienceManagementState } from "../DesignExperienceManagerTypes";
import type { ConfigMatch, StudioDesignExperienceExtension } from "./DesignExperienceExtensionTypes";

export interface ComposeConfig {
    baseConfig: DesignExperienceManagementState;
    decorationTechnology: DecorationTechnology | undefined;
    extensions: Set<StudioDesignExperienceExtension>;
}

export function applyDesignExperienceExtensionOverrides(config: ComposeConfig): StudioConfigurationState {
    const { baseConfig, decorationTechnology, extensions } = config;
    return produce(baseConfig as StudioConfigurationState, (draft: StudioConfigurationState) => {
        // applies defaults
        for (const key of Object.keys(defaultConfigValues) as Array<keyof typeof defaultConfigValues>) {
            (draft as Record<typeof key, (typeof defaultConfigValues)[keyof typeof defaultConfigValues]>)[key] =
                defaultConfigValues[key];
        }

        // applies overrides
        for (const extension of extensions) {
            for (const matchKey of Object.keys(extension) as Array<keyof typeof extension>) {
                const key = matchKey as keyof DesignExperienceManagementState;
                const configs = extension[key] as ConfigMatch<typeof key>[] | undefined;

                // Check if there's a matching value
                const matchingOverride = configs?.find((config: ConfigMatch<typeof key>) => {
                    return (
                        config.conditions.value === draft[matchKey] &&
                        // if configuration specifies an undefined deco tech then it supports all
                        (config.conditions.decorationTechnologies === undefined ||
                            config.conditions.decorationTechnologies.includes(
                                (decorationTechnology as DecorationTechnology) ?? "unknown"
                            ))
                    );
                });

                if (!matchingOverride) {
                    continue;
                }

                // Apply the override values
                for (const key of Object.keys(matchingOverride.overrides) as Array<
                    keyof typeof matchingOverride.overrides
                >) {
                    (
                        draft as Record<
                            typeof key,
                            (typeof matchingOverride.overrides)[keyof typeof matchingOverride.overrides]
                        >
                    )[key] = matchingOverride.overrides[key];
                }
            }
        }
    });
}

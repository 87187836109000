class StudioError extends Error {
    constructor(
        message: string,
        readonly moduleFunction: string,
        readonly status: string,
        readonly originalStack: any,
        readonly originalMessage: string,
        readonly errorCodeStack: string,
        readonly errorMessage: string,
        readonly response: any | null,
        readonly friendlyDescription: string | undefined,
        readonly url: string | undefined,
        readonly bodyLength: number | undefined
    ) {
        super(message);
    }
}

/**
 *
 * @param {any} moduleFunction
 * @param {any} message
 * @param {any} entityCode
 * @param {any} status
 * @param {any} ex
 * @param {any} response
 * @param {any} friendlyDescription
 * @param {string} url
 * @param {number} bodyLength
 * @returns
 */
export function formatError(
    moduleFunction: string,
    message: string,
    entityCode: number,
    status: string,
    ex: Error | undefined,
    response: any | null = null,
    friendlyDescription: string | undefined = undefined,
    url: string | undefined = undefined,
    bodyLength: number | undefined = undefined
) {
    const e = {
        moduleFunction,
        response,
        errorMessage: message,
        errorCodeStack: `${entityCode}-${status}`,
        friendlyDescription
    };
    const error = new StudioError(
        JSON.stringify(e),
        moduleFunction,
        status,
        ex && ((ex as any).originalStack || ex.stack),
        message,
        e.errorCodeStack,
        message,
        response,
        friendlyDescription,
        url || response?.url,
        bodyLength
    );
    return error;
}

import { useAppDispatch, setShowFullBleedPremiumFinishErrorModal, showStudioErrorDialog } from "@shared/redux";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
// eslint-disable-next-line import/no-restricted-paths
import { usePremiumFinishReviewInstructionsRequired } from "@six/features/editorUI/premiumFinish/usePremiumFinishReviewInstructionsRequired";
import { observer } from "mobx-react-lite";
import { Errors } from "./validationMessages";

interface CanvasValidations {
    validateCanvasErrors: () => boolean;
}

const CanvasValidationsContext = React.createContext<CanvasValidations>({ validateCanvasErrors: () => false });

export function useCanvasValidations() {
    if (CanvasValidationsContext === undefined) {
        throw new Error("useCanvasValidations must be used within a CanvasValidationsProvider");
    }
    return useContext(CanvasValidationsContext);
}

export const CanvasValidationsProvider = observer(
    ({
        children,
        designErrors,
        activeCanvasName
    }: {
        children: ReactNode | ReactNode[];
        designErrors: Errors[];
        activeCanvasName: string | undefined;
    }) => {
        const premiumFinishReviewInstructionsRequired = usePremiumFinishReviewInstructionsRequired(activeCanvasName);
        const dispatch = useAppDispatch();

        const validateCanvasErrors = useCallback(() => {
            if (premiumFinishReviewInstructionsRequired) {
                dispatch(setShowFullBleedPremiumFinishErrorModal(true));
                return false;
            }
            if (designErrors.length) {
                dispatch(showStudioErrorDialog(true));
                return false;
            }
            return true;
        }, [dispatch, designErrors, premiumFinishReviewInstructionsRequired]);

        const contextObject = useMemo(
            () => ({
                validateCanvasErrors
            }),
            [validateCanvasErrors]
        );

        return <CanvasValidationsContext.Provider value={contextObject}>{children}</CanvasValidationsContext.Provider>;
    }
);

CanvasValidationsProvider.displayName = "CanvasValidationsProvider";

import { initI18n } from "@vp/i18n-helper";
import { isDevelopmentMode, getQueryParams, windowExists } from "@internal/utils-browser";
// eslint-disable-next-line import/no-restricted-paths
import { ALL_SIM_MODULES } from "@six/features/sims";
import { defaultTranslations as cimdocDefaultTranslations } from "@internal/utils-cimdoc";
import defaultMessagesJSON from "./en-US.json";

function getTranslationsLocale(locale: string) {
    // if we are running locally or with studio_bundle_source, use local copy unless explicitly specified in a QP
    return getQueryParams().translationsLocale || getQueryParams().locale || (isDevelopmentMode() ? "local" : locale);
}

function getDefaultSIMMessages() {
    let defaultSimMessages = {};
    ALL_SIM_MODULES.forEach(sim => {
        const { defaultTranslations } = sim.core;
        defaultSimMessages = { ...defaultSimMessages, ...defaultTranslations };
    });
    return defaultSimMessages;
}

export function initializeI18n(locale: string, studioTranslations: any, simTranslations: any) {
    const translationsLocale = getTranslationsLocale(locale).toLowerCase();
    const defaultSimMessages = getDefaultSIMMessages();
    const defaultMessages = { ...defaultMessagesJSON, ...defaultSimMessages, ...cimdocDefaultTranslations };

    // const useLocale = translationsLocale === "local" ? "en-us" : translationsLocale;
    const localeMessages =
        translationsLocale === "local"
            ? defaultMessages
            : // start with the default messages in order to provide fallbacks for en-us
              {
                  ...defaultMessages,
                  ...studioTranslations,
                  ...simTranslations
              };

    const setup = {
        defaultMessages,
        projectName: "studio",
        translationsLocale,
        baseURL: "https://translations.design.vpsvc.com",
        setCultureOnBody: windowExists(),
        overrides: {
            debug: false
        },
        localeMessages
    };
    return initI18n(setup);
}

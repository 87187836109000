import type { ProductOption } from "@internal/data-access-product";
import type {
    CompatibleProductOption,
    DesignAttributeMapping,
    MappedProductOption
} from "./DesignExperienceManagerTypes";

export function mapDesignAttributeToProductOption(
    designAttributeMappings: ProductOption[],
    designAttributeName: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            mapping => mapping.designAttributeName === designAttributeName
        );
        if (possibleMapping) {
            return possibleMapping.productOptionName;
        }
    }
    return designAttributeName;
}

export function mapDesignAttributeValueToProductOptionValue(
    designAttributeMappings: ProductOption[],
    designAttributeName: string,
    designAttributeValue: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            mapping =>
                mapping.designAttributeName === designAttributeName &&
                mapping.designAttributeValue === designAttributeValue
        );
        if (possibleMapping) {
            return possibleMapping.productOptionValue;
        }
    }
    return designAttributeValue;
}

export function mapProductOptionValueToDesignAttributeValue(
    designAttributeMappings: ProductOption[],
    productOptionName: string,
    productOptionValue: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            mapping =>
                mapping.productOptionName === productOptionName && mapping.productOptionValue === productOptionValue
        );
        if (possibleMapping) {
            return possibleMapping.designAttributeValue;
        }
    }
    return productOptionValue;
}

// map product options with design attributes
export const mapProductOptionsToDesignAttributes = (
    designAttributeMappings: DesignAttributeMapping[],
    compatibleProductOptions: CompatibleProductOption[]
): MappedProductOption[] => {
    const mappedAttributes: MappedProductOption[] = [];
    compatibleProductOptions.forEach((productOption: CompatibleProductOption) => {
        const { name: productOptionName, values: productOptionValues } = productOption;
        productOptionValues.forEach((productOptionValue: string) => {
            const designAttribute = designAttributeMappings.find(
                (mapping: DesignAttributeMapping) => mapping.productOptionValue === productOptionValue
            );
            if (designAttribute) {
                // add matching design attribute if available
                mappedAttributes.push(designAttribute);
            } else {
                // add product option in case no matching design attribute available
                mappedAttributes.push({ productOptionName, productOptionValue });
            }
        });
    });
    return mappedAttributes;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
    show: boolean;
    startTime?: number;
};

const showMyProjectsSlice = createSlice({
    name: "showMyProjects",
    initialState: { show: false } as State,
    reducers: {
        setShowMyProjects: (state, action: PayloadAction<State>) => action.payload
    }
});

export const { setShowMyProjects } = showMyProjectsSlice.actions;
export const showMyProjects = showMyProjectsSlice.reducer;

import { jsxs, jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FlexBox, Visible, H1, Typography } from "@vp/swan";
import { useTransferUploadToSherbert, useCutlineConfiguration, useGetCutlineMetadata, useApplyCutlineItems } from "@internal/feature-cutline-generation";
import { getActivePanel, useDesignEngine } from "@design-stack-vista/core-features";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ContentBox, ContentBoxItem, ContentBoxButton } from "@vp/diy-editor-ui";
import { CircleCutlineIcon } from "./CutlineIcons/CircleCutlineIcon.js";
import { RectangleCutlineIcon } from "./CutlineIcons/RectangleCutlineIcon.js";
import { RoundedRectangleCutlineIcon } from "./CutlineIcons/RoundedRectangleIcon.js";
import { CustomCutlineIcon } from "./CutlineIcons/CustomCutlineIcon.js";
import '../../../assets/CutlineGenerationPanel.css';const cutlineGridWrapper = "_cutlineGridWrapper_o0thp_5";
const cutlineSelectorGrid = "_cutlineSelectorGrid_o0thp_5";
const cutlineIcon = "_cutlineIcon_o0thp_31";
const cutlineLabel = "_cutlineLabel_o0thp_45";
const styles = {
  cutlineGridWrapper,
  cutlineSelectorGrid,
  cutlineIcon,
  cutlineLabel
};
const messages = /* @__PURE__ */ defineMessages({
  panelTitle: {
    defaultMessage: "Cutline",
    id: "studio.sims.cutlineGeneration.cutlinePanel.title"
  },
  panelDescription: {
    defaultMessage: "Select the shape for your sticker.",
    id: "studio.sims.cutlineGeneration.cutlinePanel.description"
  },
  rectangle: {
    defaultMessage: "Rectangle",
    id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.rectangle"
  },
  circle: {
    defaultMessage: "Circle",
    id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.circle"
  },
  roundedRectangle: {
    defaultMessage: "Rounded Rectangle",
    id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.roundedRectangle"
  },
  custom: {
    defaultMessage: "Custom",
    id: "studio.sims.cutlineGeneration.cutlinePanel.shapeSelector.shapes.custom"
  }
});
const CutlineGenerationPanel = /* @__PURE__ */ observer(({
  dispatchToast
}) => {
  const designEngine = /* @__PURE__ */ useDesignEngine();
  const {
    cutlineShape,
    setCutlineShape
  } = /* @__PURE__ */ useCutlineConfiguration();
  const {
    applyCutlineItems
  } = /* @__PURE__ */ useApplyCutlineItems({
    dispatchToast
  });
  const {
    cutlineShape: metadataCutlineShape,
    imageVariantType,
    backgroundRemovedSuccess
  } = /* @__PURE__ */ useGetCutlineMetadata();
  const {
    t
  } = /* @__PURE__ */ useTranslationSSR();
  useTransferUploadToSherbert({
    dispatchToast
  });
  useEffect(() => {
    if (metadataCutlineShape !== cutlineShape) {
      setCutlineShape(metadataCutlineShape);
    }
  }, [metadataCutlineShape, setCutlineShape, cutlineShape]);
  const updateSelectedValue = (selectedValue) => {
    var _a;
    if (selectedValue === cutlineShape) {
      return;
    }
    setCutlineShape(selectedValue);
    const panelId = (_a = getActivePanel(designEngine)) == null ? void 0 : _a.id;
    const applyCutlineItemsInput = {
      panelId,
      cutlineShape: selectedValue,
      imageData: {
        imageVariantType,
        cutlineBaseImageVariantType: selectedValue === "custom" && backgroundRemovedSuccess && imageVariantType === "original" ? "saliencyMask" : imageVariantType
      }
    };
    applyCutlineItems(applyCutlineItemsInput);
  };
  return /* @__PURE__ */ jsxs(FlexBox, {
    flexDirection: "column",
    gap: "4",
    paddingX: {
      xs: "6",
      sm: 0
    },
    paddingBottom: {
      xs: "6",
      sm: 0
    },
    className: styles.cutlineGridWrapper,
    children: [
      /* @__PURE__ */ jsx(Visible, {
        sm: true,
        md: true,
        lg: true,
        xl: true,
        children: /* @__PURE__ */ jsx(H1, {
          fontSize: "large",
          fontWeight: "bold",
          my: 0,
          children: /* @__PURE__ */ t(messages.panelTitle.id)
        })
      }),
      /* @__PURE__ */ jsx(Typography, {
        fontSize: "small",
        textColor: "subtle",
        children: /* @__PURE__ */ t(messages.panelDescription.id)
      }),
      /* @__PURE__ */ jsxs(ContentBox, {
        allowDelete: false,
        allowMultiSelect: false,
        selectedValue: cutlineShape,
        onSelectedValueChange: updateSelectedValue,
        legend: /* @__PURE__ */ t(messages.panelDescription.id),
        className: styles.cutlineSelectorGrid,
        children: [
          /* @__PURE__ */ jsxs(ContentBoxItem, {
            value: "rectangle",
            children: [
              /* @__PURE__ */ jsx(ContentBoxButton, {
                "aria-label": /* @__PURE__ */ t(messages.rectangle.id),
                backgroundColor: "standard",
                children: /* @__PURE__ */ jsx(RectangleCutlineIcon, {
                  className: styles.cutlineIcon,
                  width: "100%",
                  height: "100%"
                })
              }),
              /* @__PURE__ */ jsx(Typography, {
                className: styles.cutlineLabel,
                textAlign: "center",
                fontSize: "xsmall",
                marginTop: "2",
                "aria-hidden": "true",
                children: /* @__PURE__ */ t(messages.rectangle.id)
              })
            ]
          }),
          /* @__PURE__ */ jsxs(ContentBoxItem, {
            value: "circle",
            children: [
              /* @__PURE__ */ jsx(ContentBoxButton, {
                "aria-label": /* @__PURE__ */ t(messages.circle.id),
                backgroundColor: "standard",
                children: /* @__PURE__ */ jsx(CircleCutlineIcon, {
                  className: styles.cutlineIcon,
                  width: "100%",
                  height: "100%"
                })
              }),
              /* @__PURE__ */ jsx(Typography, {
                className: styles.cutlineLabel,
                textAlign: "center",
                fontSize: "xsmall",
                marginTop: "2",
                "aria-hidden": "true",
                children: /* @__PURE__ */ t(messages.circle.id)
              })
            ]
          }),
          /* @__PURE__ */ jsxs(ContentBoxItem, {
            value: "roundedRectangle",
            children: [
              /* @__PURE__ */ jsx(ContentBoxButton, {
                "aria-label": /* @__PURE__ */ t(messages.roundedRectangle.id),
                backgroundColor: "standard",
                children: /* @__PURE__ */ jsx(RoundedRectangleCutlineIcon, {
                  className: styles.cutlineIcon,
                  width: "100%",
                  height: "100%"
                })
              }),
              /* @__PURE__ */ jsx(Typography, {
                className: styles.cutlineLabel,
                textAlign: "center",
                fontSize: "xsmall",
                marginTop: "2",
                "aria-hidden": "true",
                children: /* @__PURE__ */ t(messages.roundedRectangle.id)
              })
            ]
          }),
          /* @__PURE__ */ jsxs(ContentBoxItem, {
            value: "custom",
            children: [
              /* @__PURE__ */ jsx(ContentBoxButton, {
                "aria-label": /* @__PURE__ */ t(messages.custom.id),
                backgroundColor: "standard",
                children: /* @__PURE__ */ jsx(CustomCutlineIcon, {
                  className: styles.cutlineIcon,
                  width: "100%",
                  height: "100%"
                })
              }),
              /* @__PURE__ */ jsx(Typography, {
                className: styles.cutlineLabel,
                textAlign: "center",
                fontSize: "xsmall",
                marginTop: "2",
                "aria-hidden": "true",
                children: /* @__PURE__ */ t(messages.custom.id)
              })
            ]
          })
        ]
      })
    ]
  });
});
export {
  CutlineGenerationPanel,
  messages
};

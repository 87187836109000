import dexExtension3D from "@internal/dex-extension-3d";
import dexExtensionAI from "@internal/dex-extension-ai";
import dexExtensionCutlineGeneration from "@internal/dex-extension-cutline-generation";
import dexExtensionDigital from "@internal/dex-extension-digital";

export const DESIGN_EXPERIENCE_EXTENSIONS = new Set([
    dexExtension3D,
    dexExtensionAI,
    dexExtensionCutlineGeneration,
    dexExtensionDigital
]);

import { SectionPath } from "../types";

export function getSVGPath(paths: SectionPath[]): string {
    const reducedPath = paths.reduce((previousPath: string, path: SectionPath) => {
        const { command, x, y, x1, x2, y1, y2 } = path;
        let pathString = "";
        if (command === "c") {
            pathString = `${command.toUpperCase()} ${x1} ${y1} ${x2} ${y2} ${x} ${y} `;
        } else {
            pathString = `${command.toUpperCase()} ${x} ${y} `;
        }
        const result = previousPath + pathString;
        return result;
    }, "");
    return reducedPath.trim();
}

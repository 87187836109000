import { makeObservable, computed } from "mobx";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { DEFAULT_BASE_COLOR } from "../shared/utils/upgradeLegacyQRCode.js";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = __getOwnPropDesc(target, key);
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = decorator(target, key, result) || result;
  if (result) __defProp(target, key, result);
  return result;
};
class QRCodeExtension extends BaseExtension {
  static supports(designState) {
    return designState.isItemReference() && designState.model.type === ItemReferenceTypes.QR_CODE;
  }
  constructor(designState) {
    super(designState);
    makeObservable(this);
  }
  get id() {
    return this.designState.model.id;
  }
  get QRData() {
    return this.designState.model.data;
  }
  get url() {
    return this.designState.model.data.url;
  }
  get corners() {
    return this.designState.model.data.eyeOptions;
  }
  get dots() {
    return this.designState.model.data.dotsOptions;
  }
  get isBackgroundInvertActive() {
    return this.designState.model.data.backgroundColor !== DEFAULT_BASE_COLOR;
  }
}
__decorateClass([computed], QRCodeExtension.prototype, "id");
__decorateClass([computed], QRCodeExtension.prototype, "QRData");
__decorateClass([computed], QRCodeExtension.prototype, "url");
__decorateClass([computed], QRCodeExtension.prototype, "corners");
__decorateClass([computed], QRCodeExtension.prototype, "dots");
__decorateClass([computed], QRCodeExtension.prototype, "isBackgroundInvertActive");
export {
  QRCodeExtension
};

import { isSingleColor } from "../SingleColor";
import { DecorationTechnology } from "./decorationTechnologyConfiguration";

export const singleColor: DecorationTechnology = {
    configuration: {
        allowBackgroundColor: false,
        allowPDFProofDownload: true,
        allowTransparency: false,
        enableFontBrowser: true,
        allowVectors: false
    },
    isActive: panel => isSingleColor(panel.panelProperties.decorationTechnology)
};

import React, { useState, useMemo } from "react";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { RichTextControlWrapper } from "@design-stack-vista/core-features";
import { FlexBox, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders } from "@vp/swan";
import { SheetHorizontalScroller } from "@internal/ui-studio-chrome";
import { DesktopExperience, MobileExperience, useStudioLayout } from "@internal/feature-responsive-design";
import { ColorSpace, colorToString, SelectableColor, StateColorObject } from "@internal/utils-color";
import { useGetCutlineMetadata } from "@internal/feature-cutline-generation";
import { CMYK, HSV, RGB } from "@design-stack-vista/utility-core";
import { CustomHsvColorPicker } from "../CustomHsvColorPicker";
import { HexColorInput } from "../HexColorInput";
import { NoColorSwatch } from "../ColorSwatch/NoColorSwatch";
import { ColorPalette } from "../ColorSwatch/ColorPalette";
import { CustomCmykColorPicker } from "../CustomCmykColorPicker";
import styles from "./ColorPickerPanelContent.module.scss";
import { useLocalized } from "../localized";
import { ToggleBackgroundImage } from "./ToggleBackgroundImage";

interface Props {
    /**
     * Array of recommended colors from the design. See the ColorPalette component
     */
    recentColors?: SelectableColor[];
    /**
     * Array of standard colors. See ColorPalette component
     */
    paletteColors: SelectableColor[];
    /**
     * Show the custom color picker (wheel/sliders).
     * @default true
     */
    allowCustom?: boolean;
    /**
     * Show the 'No color' option.
     * @default false
     */
    allowTransparency?: boolean;
    className?: string;
    onColorChange: (newColor: string | CMYK | RGB | HSV, colorSpace: ColorSpace) => void;
    currentColorValues: StateColorObject;
    eyeDropperNode: React.ReactNode;
    trackComponentUsage?: (component: string) => void;
    descriptionTitle?: string;
    enableToggleBackground: boolean;
}

enum TabTypes {
    Swatches = "Swatches",
    Custom = "Custom",
    CMYK = "CMYK",
    Composition = "Composition"
}

export function ColorPickerPanelContent({
    recentColors,
    paletteColors,
    allowCustom = true,
    allowTransparency = false,
    className,
    onColorChange,
    currentColorValues,
    eyeDropperNode,
    trackComponentUsage,
    descriptionTitle,
    enableToggleBackground
}: Props) {
    const localized = useLocalized();
    const locale = localized.locale;
    const { isSmall } = useStudioLayout();
    const { backgroundRemovedSuccess } = useGetCutlineMetadata();

    const [selectedTabId, setSelectedTabId] = useState<string | null>(
        enableToggleBackground && isSmall && backgroundRemovedSuccess ? "Composition" : "Swatches"
    );

    const debouncedColorChange = useMemo(() => debounce(onColorChange, 200), [onColorChange]);

    if (allowCustom) {
        return (
            <>
                <DesktopExperience>
                    <div className={classNames(styles.colorPickerPanel, className)} data-testid="color-picker-content">
                        <CustomHsvColorPicker
                            onChange={debouncedColorChange}
                            value={currentColorValues.hsv}
                            trackUsage={trackComponentUsage}
                        />
                        <FlexBox
                            alignItems="center"
                            justifyContent="space-between"
                            className={styles.colorPickerMidRibbon}
                        >
                            <HexColorInput
                                onChange={onColorChange}
                                value={currentColorValues.hex}
                                trackUsage={trackComponentUsage}
                                className={styles.colorInput}
                            />
                            {eyeDropperNode}
                            {allowTransparency && (
                                <NoColorSwatch
                                    noColorSelected={currentColorValues.noColor}
                                    onChange={onColorChange}
                                    trackUsage={trackComponentUsage}
                                    className={styles.noColor}
                                />
                            )}
                        </FlexBox>
                        <Tabs
                            defaultSelectedTabId={selectedTabId}
                            onRequestTabChange={requestedTabId => setSelectedTabId(requestedTabId)}
                        >
                            <TabsHeaders compactMode>
                                <RichTextControlWrapper>
                                    <TabHeader tabId={TabTypes.Swatches}>{`${localized.get("swatches")}`}</TabHeader>
                                    <TabHeader tabId={TabTypes.CMYK}>CMYK</TabHeader>
                                </RichTextControlWrapper>
                            </TabsHeaders>
                            <TabsContents>
                                <TabContent tabId={TabTypes.Swatches}>
                                    <ColorPalette
                                        selectedColor={colorToString(currentColorValues.rgb)}
                                        paletteColors={paletteColors.slice(0, 28)}
                                        recentColors={recentColors?.slice(0, 7)}
                                        onSelectColor={onColorChange}
                                        trackUsage={trackComponentUsage}
                                        locale={locale}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.CMYK}>
                                    <CustomCmykColorPicker
                                        onChange={debouncedColorChange}
                                        value={currentColorValues.cmyk}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                            </TabsContents>
                        </Tabs>
                    </div>
                </DesktopExperience>
                <MobileExperience>
                    <div className={styles.colorPickerPanel} data-testid="color-picker-content">
                        <FlexBox
                            alignItems="center"
                            justifyContent="space-between"
                            className={styles.colorPickerMidRibbon}
                            marginY={0}
                        >
                            <HexColorInput
                                onChange={onColorChange}
                                value={currentColorValues.hex}
                                trackUsage={trackComponentUsage}
                                className={styles.colorInput}
                            />
                            {allowTransparency && (
                                <NoColorSwatch
                                    noColorSelected={currentColorValues.noColor}
                                    onChange={onColorChange}
                                    trackUsage={trackComponentUsage}
                                    className={styles.noColor}
                                />
                            )}
                        </FlexBox>
                        <Tabs
                            defaultSelectedTabId={selectedTabId}
                            onRequestTabChange={requestedTabId => setSelectedTabId(requestedTabId)}
                            mt={0}
                            className={styles.smallScreenTabs}
                        >
                            <SheetHorizontalScroller
                                scrollLeftText={localized.get("scrollLeft")}
                                scrollRightText={localized.get("scrollRight")}
                            >
                                <TabsHeaders compactMode>
                                    {enableToggleBackground && backgroundRemovedSuccess && (
                                        <TabHeader tabId={TabTypes.Composition}>{`${localized.get(
                                            "composition"
                                        )}`}</TabHeader>
                                    )}
                                    <TabHeader tabId={TabTypes.Swatches}>{`${localized.get("swatches")}`}</TabHeader>
                                    <TabHeader tabId={TabTypes.Custom}>{`${localized.get("custom")}`}</TabHeader>
                                    <TabHeader tabId={TabTypes.CMYK}>CMYK</TabHeader>
                                </TabsHeaders>
                            </SheetHorizontalScroller>
                            <TabsContents>
                                {enableToggleBackground && backgroundRemovedSuccess && (
                                    <TabContent tabId={TabTypes.Composition}>
                                        <ToggleBackgroundImage />
                                    </TabContent>
                                )}
                                <TabContent tabId={TabTypes.Swatches}>
                                    <ColorPalette
                                        selectedColor={colorToString(currentColorValues.rgb)}
                                        paletteColors={paletteColors.slice(0, 28)}
                                        recentColors={recentColors?.slice(0, 7)}
                                        onSelectColor={onColorChange}
                                        trackUsage={trackComponentUsage}
                                        descriptionTitle={descriptionTitle}
                                        locale={locale}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.Custom}>
                                    <CustomHsvColorPicker
                                        onChange={debouncedColorChange}
                                        value={currentColorValues.hsv}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                                <TabContent tabId={TabTypes.CMYK}>
                                    <CustomCmykColorPicker
                                        onChange={debouncedColorChange}
                                        value={currentColorValues.cmyk}
                                        trackUsage={trackComponentUsage}
                                    />
                                </TabContent>
                            </TabsContents>
                        </Tabs>
                    </div>
                </MobileExperience>
            </>
        );
    }

    return (
        <ColorPalette
            selectedColor={colorToString(currentColorValues.other ?? currentColorValues.rgb)}
            paletteColors={paletteColors}
            recentColors={recentColors}
            onSelectColor={onColorChange}
            trackUsage={trackComponentUsage}
            descriptionTitle={descriptionTitle}
            locale={locale}
        />
    );
}

ColorPickerPanelContent.displayName = "ColorPickerPanelContent";

import { StudioPanelDesignRequirements } from "@shared/features/Product";
import { SUPPORTED_FINISHES } from "./constants";

function filterByAvailableFinishes(finishes: string[]) {
    return finishes.filter(finish => SUPPORTED_FINISHES.includes(finish));
}

/**
 * Checks if the given panel has premium finishes available on it
 * @param {StudioPanelDesignRequirements} panel
 * @returns {boolean} true if available, false otherwise
 */
export function hasPremiumFinishes(panel: StudioPanelDesignRequirements) {
    return Boolean(filterByAvailableFinishes(panel.availablePremiumFinishes).length > 0);
}

/**
 * Gets the available premium finishes from the provided panel
 * @param {StudioPanelDesignRequirements} panel
 * @return {string[]} return an arrya of finishes that are available.
 *  The first item in the array is the active finish.
 */
export function getPremiumFinishes(panel: StudioPanelDesignRequirements): string[] {
    return filterByAvailableFinishes(panel.availablePremiumFinishes);
}

import { getQueryParams } from "../queryString";
import { windowExists } from "../windowExists";
import { isDebugMode } from "./debugMode";

export function isNextSite() {
    return windowExists() && /next/.test(window.location.hostname);
}

export function isExplicitDevelopmentMode() {
    return windowExists() && !!getQueryParams().developmentMode;
}

export function isLocalHost() {
    return windowExists() && /localhost|127\.0\.0\.1|::1/.test(window.location.hostname);
}

export function isStaging() {
    return windowExists() && /staging/.test(window.location.hostname);
}

export function isBranch() {
    return windowExists() && /branches/.test(window.location.hostname);
}

export function isDevelopmentMode() {
    return isExplicitDevelopmentMode() || isStaging() || isLocalHost() || isBranch();
}

export function isProd() {
    return !isLocalHost() && !isStaging() && !isBranch();
}

export function isShowWarningsMode() {
    return isDebugMode() || isNextSite();
}

import type { GuideType, MaskType } from "@design-stack-vista/ida-framework";

export enum Commands {
    moveTo = "m",
    lineTo = "l",
    quadraticCurveTo = "q",
    bezierCurveTo = "c",
    arcTo = "a"
}

/**
 * An interface for the section path.
 */
export interface SectionPath {
    /**
     * Type of commands that the path contains.
     */
    command: Commands;
    /**
     * End point co-ordinate x of the curve.
     */
    x: number;
    /**
     * End point co-ordinate y of the curve.
     */
    y: number;
    /**
     * First control point co-ordinate x of the curve.
     */
    x1?: number;
    /**
     * First control point co-ordinate y of the curve.
     */
    y1?: number;
    /**
     * Second control point co-ordinate x of the curve.
     */
    x2?: number;
    /**
     * Second control point co-ordinate y of the curve.
     */
    y2?: number;
}

/**
 * An interface for a section.
 */
export interface Section {
    /**
     * Id of the section.
     */
    id: string;
    /**
     * Name of the section.
     */
    name: string;
    /**
     * Paths of the section.
     */
    paths: SectionPath[];
    /**
     * Meta data related to section.
     */
    metadata: any;
}

/**
 * An interface for section set.
 */
export interface SectionSet {
    /**
     * Id of the section set.
     */
    id: string;
    /**
     * Name of the section set.
     */
    name: string;
    /**
     * Array of sections contains in section set.
     */
    sections: Section[];
}

/**
 * An interface for section meta data.
 */
export interface SectionMetadata {
    /**
     * The section is designable or not.
     */
    isDesignable: boolean;
    /**
     * The section is preferred for design or not.
     */
    isPreferredDesignable: boolean;
    /**
     * The orientation of the content on the section.
     */
    contentOrientation: string;
}

/**
 * An interface for panel section data.
 */
export interface PanelSectionData {
    /**
     * Panel section Id.
     */
    panelId: string;
    /**
     * Information of section sets on that panel.
     */
    sectionSets: SectionSet[];
    /**
     * A surface location for section set.
     */
    location: string;
}

/**
 * An interface for bounding box for any item.
 */
export interface BoundingBox {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

/**
 * An interface for point co-ordinate.
 */
export interface Point {
    x: number;
    y: number;
}

export interface PanelSectionWithBoundingBox extends Section {
    boundingBox: BoundingBox;
    metadata: SectionMetadata;
    panelId: string;
}
export interface PanelSectionSetWithBoundingBox {
    id: string;
    name: string;
    sections: PanelSectionWithBoundingBox[];
}

export interface PanelSectionSet {
    id: string;
    name: string;
    sections: Section[] | PanelSectionWithBoundingBox[];
    metadata: SectionMetadata;
}

// Todo - Need to move to single place (copied it from viewItemType).

export type OnlyMaskType = Exclude<MaskType, object>;
export type OnlyGuideType = Exclude<GuideType, object>;
export type LineType = Partial<OnlyMaskType | OnlyGuideType>;

export type DielineInfo = {
    [lineType in LineType | "NONDESIGNABLE"]?: {
        showToggle: boolean;
        hidden: boolean;
        enabled: boolean;
    };
};

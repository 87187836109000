import { AlertBox, AlertBoxDismissButton, Box } from "@vp/swan";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useToastManager } from "../../hooks";
import { useLocalized } from "../../localized";
import styles from "./Toast.module.scss";

export const AlertToast = observer(() => {
    const localized = useLocalized();
    const toastManager = useToastManager();

    const [render, setRender] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (toastManager.toasts.size > 0) {
            setShow(true);
            setRender(true);
        }
    }, [toastManager.toasts.size]);

    const dismissToast = useCallback(
        (key: string) => {
            setRender(false);
            toastManager.removeToast(key);
        },
        [toastManager]
    );

    const onAnimationEnd = (key: string) => {
        if (!render) {
            dismissToast(key);
        }
    };

    const handleRequestDismiss = (key: string) => {
        dismissToast(key);
    };

    return (
        <>
            {Array.from(toastManager.toasts).map(([key, toast]) => {
                const isToastAMessage = typeof toast.content === "string";
                return (
                    <AlertBox
                        key={key}
                        dismissed={!show}
                        onRequestDismiss={() => handleRequestDismiss(key)}
                        onAnimationEnd={() => onAnimationEnd(key)}
                        className={render ? styles.fadeIn : styles.fadeOut}
                        skin={toast?.skin ?? "positive"}
                    >
                        {isToastAMessage ? (
                            <Box className={styles.toastMessageContainer} key={key}>
                                {toast.content}
                            </Box>
                        ) : (
                            toast.content
                        )}
                        <AlertBoxDismissButton
                            visuallyHiddenLabel={localized.get("closeToastButton")}
                            aria-label={localized.get("closeToastButton")}
                        />
                    </AlertBox>
                );
            })}
        </>
    );
});

AlertToast.displayName = "AlertToast";

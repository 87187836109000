import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showFullBleedPremiumFinishErrorModalSlice = createSlice({
    name: "showFullBleedPremiumFinishErrorModal",
    initialState: { showModal: false },
    reducers: {
        setShowFullBleedPremiumFinishErrorModal: (state, action: PayloadAction<boolean>) => ({
            showModal: action.payload
        })
    }
});

export const { setShowFullBleedPremiumFinishErrorModal } = showFullBleedPremiumFinishErrorModalSlice.actions;
export const showFullBleedPremiumFinishErrorModal = showFullBleedPremiumFinishErrorModalSlice.reducer;

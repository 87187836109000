import { ItemPreloaderExtension } from "@internal/sim-framework";
import { override } from "mobx";
import { name } from "../name.js";
import { isTeamsNameItemReference } from "../utils/isTeamsNameItemReference.js";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = __getOwnPropDesc(target, key);
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = decorator(target, key, result) || result;
  if (result) __defProp(target, key, result);
  return result;
};
class TeamsNamePluginPreloaderPanelExtension extends ItemPreloaderExtension {
  get simName() {
    return name;
  }
  get shouldPreload() {
    return this.designState.items.some(isTeamsNameItemReference);
  }
}
__decorateClass([override], TeamsNamePluginPreloaderPanelExtension.prototype, "shouldPreload");
export {
  TeamsNamePluginPreloaderPanelExtension
};

import type { StudioSecondaryConfig } from "@internal/data-access-calcifer";
import { useQuerySecondaryConfig } from "./useQuerySecondaryConfig";

const selector = (data: StudioSecondaryConfig) => data.surfaceUpsells;

export function useProductSurfaceUpsells() {
    const productSurfaceUpsells = useQuerySecondaryConfig(selector).data;
    if (productSurfaceUpsells) {
        return productSurfaceUpsells;
    }
    return [];
}

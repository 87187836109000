import { action, makeAutoObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { Toast } from "../types";

const DEFAULT_TOAST_NOTIFICATION_TIME_MS = 5000;

export class ToastManager {
    @observable toasts: Map<string, Toast>;
    private toastTimeouts: Map<string, number>;

    constructor() {
        makeAutoObservable(this);
        this.toasts = new Map();
        this.toastTimeouts = new Map();
    }

    @action.bound
    pushToast(toast: Toast): void {
        const key = uuid();
        this.toasts.set(key, toast);
        if (toast.autoClose) {
            this.autoCloseAlert(key, toast.autoCloseTimeOutDuration);
        }
    }

    @action.bound
    private autoCloseAlert(key: string, timeOutDuration: number = DEFAULT_TOAST_NOTIFICATION_TIME_MS): void {
        const timeoutId = window.setTimeout(() => this.removeToast(key), timeOutDuration);
        this.toastTimeouts.set(key, timeoutId);
    }

    @action.bound
    removeToast(key: string): void {
        const timeoutId = this.toastTimeouts.get(key);
        if (timeoutId) {
            this.clearToastTimeout(key, timeoutId);
        }

        this.toasts.delete(key);
    }

    @action.bound
    private clearToastTimeout(key: string, timeoutId: number): void {
        window.clearTimeout(timeoutId);
        this.toastTimeouts.delete(key);
    }

    @action.bound
    resetToasts(): void {
        this.toasts.clear();
        this.toastTimeouts.clear();
    }
}

import { jsxs, jsx } from "react/jsx-runtime";
const CustomCutlineIcon = (props) => /* @__PURE__ */ jsxs("svg", {
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: [
    /* @__PURE__ */ jsx("circle", {
      cx: 16,
      cy: 16,
      r: 15,
      stroke: "black",
      strokeWidth: 1.5,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeDasharray: "5 5"
    }),
    /* @__PURE__ */ jsx("path", {
      d: "M19.5 19c-2.759 1.73-4.23 1.67-7 0",
      stroke: "black",
      strokeWidth: 1.5,
      strokeLinecap: "round",
      strokeLinejoin: "round"
    }),
    /* @__PURE__ */ jsx("circle", {
      cx: 16,
      cy: 16,
      r: 10,
      stroke: "black",
      strokeWidth: 1.5
    }),
    /* @__PURE__ */ jsx("circle", {
      cx: 13,
      cy: 14,
      r: 1,
      fill: "black"
    }),
    /* @__PURE__ */ jsx("circle", {
      cx: 19,
      cy: 14,
      r: 1,
      fill: "black"
    })
  ]
});
export {
  CustomCutlineIcon
};

import { ContentDiscoveryAllSlotNames, ContentDiscoveryZoneSlotDivider } from "@internal/utils-content-discovery-zone";

export const allLargeScreencontentDiscoveryZoneOrderedSlots: Array<
    ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider
> = [
    "Text",
    "TeamsName",
    "Designs",
    "Images",
    "GraphicsAndShapes",
    "QRCodes",
    "Cutline",
    "Tables",
    "Flexibility",
    "DIVIDER",
    "ChangeSize",
    "Layers",
    "Background",
    "Finish",
    "Pattern",
    "Default"
];

export const allSmallScreencontentDiscoveryZoneOrderedSlots: Array<
    ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider
> = [
    "Text",
    "TeamsName",
    "Designs",
    "Images",
    "GraphicsAndShapes",
    "ChangeSize",
    "Cutline",
    "Tables",
    "QRCodes",
    "Flexibility",
    "Layers",
    "Background",
    "Finish",
    "Pattern",
    "Default"
];

module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-helmet-async@1.2.3_gatsby@4.25.9_@swc+core@1.4.17_@swc+helpers@0.5.11__ba_t6v6n54a2ahyqhth54yzkmgpoe/node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-smoothscroll@1.2.0/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.9_@swc+core@1.4.17_@swc+helpers@0.5.11__babel-eslint@10.1.0_eslint@8.57.0__eslint_g4kdizwsx6ncsr5rytixlqy3zq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { defineMessages } from "@vp/i18n-helper";
const cutlineSimMessages = /* @__PURE__ */ defineMessages({
  customCutlineError: {
    id: "easel.components.customCutlineGeneration.error",
    defaultMessage: "Custom cutline isn't available. Try another cutline, sticker size or image.",
    description: {
      note: "Toast for when custom cutline generation fails"
    }
  },
  basicCutlineError: {
    id: "easel.components.basicCutlineGeneration.error",
    defaultMessage: "Basic cutline isn't available. Try another cutline, sticker size or image.",
    description: {
      note: "Toast for when basic cutline generation fails"
    }
  }
});
export {
  cutlineSimMessages
};

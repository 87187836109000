import { CimDoc, ShapeItem, CurveItemV2 } from "@design-stack-vista/cdif-types";
import cloneDeep from "lodash/cloneDeep";
import {
    OUTER_CUTLINE_SHAPE_ID,
    OUTER_CUTLINE_STROKE_COLOR,
    CUTLINE_THICKNESS,
    OUTER_CUTLINE_OVERPRINT
} from "./cutlineConstants";

type OuterCutlineShapeItem = Omit<CurveItemV2["stroke"], "color">;

interface GenerateOuterCutlineArgs {
    cimDoc: CimDoc;
    innerCutlineItem: ShapeItem;
    distanceInMm: number;
    accessToken: string;
}

interface GetOuterCutlineForBasicShapeArgs {
    innerCutlineItem: ShapeItem;
    distanceInMm: number;
}

const outerCutlineStroke = {
    overprints: [OUTER_CUTLINE_STROKE_COLOR],
    thickness: CUTLINE_THICKNESS
};

const getOuterCutlineForRectangle = ({ innerCutlineItem, distanceInMm }: GetOuterCutlineForBasicShapeArgs) => {
    if (!("position" in innerCutlineItem)) {
        throw new Error();
    }

    const {
        position: { x, y, width, height }
    } = innerCutlineItem;

    const outerCutlinePosition = {
        x: `${parseFloat(x) - distanceInMm}mm`,
        y: `${parseFloat(y) - distanceInMm}mm`,
        width: `${parseFloat(width) + 2 * distanceInMm}mm`,
        height: `${parseFloat(height) + 2 * distanceInMm}mm`
    };

    return {
        ...innerCutlineItem,
        id: OUTER_CUTLINE_SHAPE_ID,
        position: outerCutlinePosition,
        stroke: outerCutlineStroke,
        overprints: [OUTER_CUTLINE_OVERPRINT],
        color: undefined
    };
};

const getOuterCutlineForCircle = ({ innerCutlineItem, distanceInMm }: GetOuterCutlineForBasicShapeArgs) => {
    if (!("position" in innerCutlineItem)) {
        throw new Error();
    }

    const {
        position: { x, y, width, height }
    } = innerCutlineItem;

    const outerCutlinePosition = {
        x: `${parseFloat(x) - distanceInMm}mm`,
        y: `${parseFloat(y) - distanceInMm}mm`,
        width: `${parseFloat(width) + 2 * distanceInMm}mm`,
        height: `${parseFloat(height) + 2 * distanceInMm}mm`
    };

    return {
        ...innerCutlineItem,
        id: OUTER_CUTLINE_SHAPE_ID,
        position: outerCutlinePosition,
        stroke: outerCutlineStroke,
        overprints: [OUTER_CUTLINE_OVERPRINT],
        color: undefined
    };
};

export const generateOuterCutline = async ({
    cimDoc,
    innerCutlineItem,
    distanceInMm,
    accessToken
}: GenerateOuterCutlineArgs): Promise<OuterCutlineShapeItem> => {
    if (innerCutlineItem.type === "rectangle") {
        return getOuterCutlineForRectangle({
            innerCutlineItem,
            distanceInMm
        });
    }

    if (innerCutlineItem.type === "ellipse") {
        return getOuterCutlineForCircle({
            innerCutlineItem,
            distanceInMm
        });
    }

    const inputCimDoc = cloneDeep(cimDoc);
    delete innerCutlineItem.stroke?.dashPattern;

    inputCimDoc.document.panels[0].images = [];
    inputCimDoc.document.panels[0].shapes = [innerCutlineItem];

    const { generateCutlineFromCimdoc } = await import("@prepress/custom-cutline-utility");

    // generate outer cutline for custom cutline document
    const {
        shapes: [customOuterCutline]
    } = await generateCutlineFromCimdoc({
        // @ts-expect-error - type mismatch, resolve after @prepress/custom-cutline-utility is updated
        cimdoc: inputCimDoc,
        safetyDistanceInMM: distanceInMm,
        accessToken,
        strokeObject: outerCutlineStroke,
        overprintsColor: [OUTER_CUTLINE_OVERPRINT]
    });

    return {
        ...customOuterCutline,
        id: OUTER_CUTLINE_SHAPE_ID
    };
};

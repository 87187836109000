import { useEffect } from "react";
import type {
    UseInitializeStudioConfigurationManager,
    UseInitializeStudioConfigurationManagerConfig
} from "@internal/utils-studio-configuration-core";
import { useThrowAsyncErrorToBoundary } from "@design-stack-vista/utility-react";
import { useStudioConfigurationManager } from "./useStudioConfigurationManager";
import { DESIGN_EXPERIENCE_EXTENSIONS } from "./designExperienceExtensions";
import { CustomVCSData, VCSUrlSchema } from "./applyVCSModeOverrides";

export type UseInitializeStudioConfigurationManagerConfigVCS = UseInitializeStudioConfigurationManagerConfig & {
    brandedProductId?: string;
    storeId?: string;
};

/**
 * This hook is used to initialize the state of the of the Studio Configuration Manager.
 * @param config
 */
export const useInitializeStudioConfigurationManager: UseInitializeStudioConfigurationManager<
    UseInitializeStudioConfigurationManagerConfigVCS
> = config => {
    const studioConfigurationManager = useStudioConfigurationManager();
    const throwError = useThrowAsyncErrorToBoundary();

    const {
        decorationTechnology,
        productGroupConfigurationState,
        designExperienceManagementState,
        locale,
        isTeamsProduct,
        isDesignAssistantTabEnabled,
        brandedProductId,
        storeId
    } = config ?? {};

    useEffect(() => {
        if (
            decorationTechnology !== undefined &&
            productGroupConfigurationState !== undefined &&
            designExperienceManagementState !== undefined &&
            locale !== undefined &&
            isTeamsProduct !== undefined &&
            isDesignAssistantTabEnabled !== undefined
        ) {
            const searchParams = new URLSearchParams(window.location.search);

            // todo: how does this work with a work?  where does this data come from?
            const result = VCSUrlSchema.safeParse({
                mode: searchParams.get("mode") || "shopper",
                brandedProductId: brandedProductId || searchParams.get("brandedProductId"),
                storeId: storeId || searchParams.get("storeId")
            });

            if (result.success) {
                const extraData: CustomVCSData = { url: result.data };

                studioConfigurationManager.init(
                    {
                        decorationTechnology: decorationTechnology,
                        productGroupConfigurationState: productGroupConfigurationState,
                        designExperienceManagementState: designExperienceManagementState,
                        locale: locale,
                        isTeamsProduct: isTeamsProduct,
                        studioDXExtensions: DESIGN_EXPERIENCE_EXTENSIONS,
                        isDesignAssistantTabEnabled: isDesignAssistantTabEnabled
                    },
                    extraData
                );
            } else {
                throwError(new Error(`Failed to parse VCS URL correctly: ${result.error.message}`));
            }
        }
    }, [
        decorationTechnology,
        productGroupConfigurationState,
        designExperienceManagementState,
        locale,
        isTeamsProduct,
        studioConfigurationManager,
        throwError,
        isDesignAssistantTabEnabled,
        brandedProductId,
        storeId
    ]);
};

import { observer } from "mobx-react-lite";
import React, { createContext, useContext, useState } from "react";
import { InstantUploadStore } from "./InstantUploadStore";

const InstantUploadStoreContext = createContext<InstantUploadStore | undefined>(undefined);

export const useInstantUploadStore = () => {
    const instantUploadStore = useContext(InstantUploadStoreContext);

    if (instantUploadStore === undefined) {
        throw new Error("Component must be wrapped in a InstantUploadStoreProvider.");
    }

    return instantUploadStore;
};

export const InstantUploadStoreProvider = observer((props: React.PropsWithChildren<{}>) => {
    const [instantUploadStore] = useState(() => new InstantUploadStore());

    return (
        <InstantUploadStoreContext.Provider value={instantUploadStore}>
            {props.children}
        </InstantUploadStoreContext.Provider>
    );
});

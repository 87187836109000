import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showTeamsGuideModalSlice = createSlice({
    name: "showTeamsGuideModal",
    initialState: false,
    reducers: {
        setShowTeamsGuideModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowTeamsGuideModal } = showTeamsGuideModalSlice.actions;
export const showTeamsGuideModal = showTeamsGuideModalSlice.reducer;

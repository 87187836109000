import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StudioContentDiscoveryZoneManager } from "./StudioContentDiscoveryZoneManager";
import type { StudioContentDiscoveryZone, StudioContentDiscoveryZoneName } from "./types";

const StudioContentDiscoveryZoneContext = React.createContext<undefined | StudioContentDiscoveryZoneManager>(undefined);

type Overrides = Record<StudioContentDiscoveryZoneName, boolean>;

type StudioContentDiscoveryZoneProviderProps = Overrides & {
    modules: Set<StudioContentDiscoveryZone>;
};

export const StudioContentDiscoveryZoneProvider = observer(
    (props: React.PropsWithChildren<StudioContentDiscoveryZoneProviderProps>) => {
        const { modules, children, ...overrides } = props;
        const [moduleManager] = useState(() => new StudioContentDiscoveryZoneManager(modules));

        // Handle activating and deactivating each content zone module
        useEffect(() => {
            modules.forEach(module => {
                const isActive = overrides[module.name] ?? true;
                moduleManager.setActivation(module.name, isActive);
            });
        }, [modules, moduleManager, overrides]);

        return (
            <StudioContentDiscoveryZoneContext.Provider value={moduleManager}>
                {children}
            </StudioContentDiscoveryZoneContext.Provider>
        );
    }
);

StudioContentDiscoveryZoneProvider.displayName = "StudioContentDiscoveryZoneProvider";

export const useStudioContentDiscoveryZoneManager = () => {
    const manager = useContext(StudioContentDiscoveryZoneContext);

    if (manager === undefined) {
        throw new Error(
            "useContentDiscoveryZoneModule must be used as a child of <ContentDiscoveryZoneModuleProvider />"
        );
    }

    return manager;
};

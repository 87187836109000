import {
    ColorRestriction,
    ColorRestrictionType,
    ItemColorResolver,
    defaultCimDocItemColorResolver,
    getCimDocNodeById,
    initializeMetadata,
    isImageItem,
    isImageWithoutUrl,
    validateColorsByItem
} from "@design-stack-vista/cimdoc-state-manager";
import {
    CimDoc,
    ColorAdjustment,
    ColorOverrides,
    DclMetadataItem,
    ImageOverlay,
    PlaceholderImage
} from "@design-stack-vista/cdif-types";
import { isPresent } from "@design-stack-vista/utility-core";

interface ImageTransformationsMetadata {
    appliedProcesses: string[];
}

interface ImageAttributes {
    pageNumber?: number;
    previewUrl?: string;
    printUrl?: string;
    overlays?: ImageOverlay[];
    originalSourceUrl?: string;
    colorOverrides?: ColorOverrides[];
    colorAdjustment?: ColorAdjustment;
    mimeType?: string;
    imageTransformations?: ImageTransformationsMetadata;
}

export interface ReplaceImageInCimdocParameters {
    id: string;
    imageAttributes: ImageAttributes;
    colorRestrictions?: ColorRestriction;
    itemColorResolver?: ItemColorResolver;
}

export function replaceImageInCimDoc(
    cimDoc: CimDoc,
    {
        id,
        imageAttributes,
        colorRestrictions = { type: ColorRestrictionType.ProcessColorsAndSpotColors },
        itemColorResolver = defaultCimDocItemColorResolver
    }: ReplaceImageInCimdocParameters
) {
    const {
        previewUrl,
        printUrl,
        overlays,
        originalSourceUrl,
        pageNumber,
        colorOverrides,
        colorAdjustment,
        mimeType,
        imageTransformations
    } = imageAttributes;

    const node = getCimDocNodeById(cimDoc, id);
    if (!node) {
        return;
    }
    const { element: item } = node;

    if (isImageItem(item)) {
        delete item.colorOverrides;
        delete item.colorAdjustment;

        item.previewUrl = previewUrl;
        item.printUrl = printUrl;
        item.overlays = overlays;

        if (!item.printUrl && !item.previewUrl && !isImageWithoutUrl(item)) {
            throw new Error("replaceImage: cannot have an image without either a printUrl, previewUrl, or overlays");
        }

        if (originalSourceUrl) {
            item.originalSourceUrl = originalSourceUrl;
        }

        if (isPresent(pageNumber)) {
            item.pageNumber = pageNumber;
        }

        if (colorOverrides) {
            item.colorOverrides = colorOverrides;
            validateColorsByItem(cimDoc, {
                item,
                colorRestrictions,
                itemColorResolver
            });
        }
        if (colorAdjustment) {
            item.colorAdjustment = colorAdjustment;
        }

        if (cimDoc.metadata && cimDoc.metadata.template) {
            const templateIndex = cimDoc.metadata.template.findIndex(template => template.id === id);
            const template = cimDoc.metadata.template[templateIndex] as PlaceholderImage;
            if (template) {
                template.placeholderReplaced = true;
            }
        }

        initializeMetadata(cimDoc, { initializeDclMetadata: true });
        const dclMetadataItem = cimDoc.metadata!.dclMetadata!.find(metadataItem => metadataItem.id === id);

        if (dclMetadataItem) {
            dclMetadataItem.mimeType = mimeType;
            dclMetadataItem.imageTransformations = imageTransformations;
        } else {
            const newDclMetadataItem: DclMetadataItem = {
                id,
                mimeType,
                imageTransformations
            };

            cimDoc.metadata!.dclMetadata!.push(newDclMetadataItem);
        }
    }
}

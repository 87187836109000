import React, { useState, useEffect } from "react";
import { FlexBox } from "@vp/swan";
import { HSV } from "@design-stack-vista/utility-core";
import styles from "./CustomHsvColorPicker.module.scss";
import { HueColorSlider } from "./HueColorSlider";
import { ValueAndSaturationBox } from "./ValueAndSaturationBox";
import { ColorSpace } from "@internal/utils-color";

interface Props {
    /** Currently selected color */
    value: HSV;
    /** Function called when a color has been selected */
    onChange: (newValue: HSV, colorSpace: ColorSpace) => void;
    /** Optional class names to add to component */
    className?: string;
    /** Function to track usage */
    trackUsage?: (component: string, value?: string) => void;
}

export function CustomHsvColorPicker({ onChange = () => {}, value, className, trackUsage }: Props) {
    const [sv, setSv] = useState({ s: value.s, v: value.v });
    const [hue, setHue] = useState(value.h);

    const hueChange = (h: number) => {
        setHue(h);
        const newHsv = { h, s: sv.s, v: sv.v };
        onChange(newHsv, ColorSpace.HSV);
        if (trackUsage) {
            trackUsage("Hue", newHsv.toString());
        }
    };

    const svChange = (sv: { s: number; v: number }) => {
        setSv(sv);
        const newHsv = { h: hue, s: sv.s, v: sv.v };
        onChange(newHsv, ColorSpace.HSV);
        if (trackUsage) {
            trackUsage("HSV", newHsv.toString());
        }
    };

    useEffect(() => {
        if (value) {
            const { h, s, v } = value;
            setHue(h);
            setSv({ s, v });
        }
    }, [value]);

    return (
        <FlexBox flexDirection="row" flexWrap="wrap" className={className} pt={"4"}>
            <div className={styles.customHsvColorPickerBox}>
                <ValueAndSaturationBox value={{ ...sv, h: hue }} onChange={svChange} />
            </div>
            <div className={styles.customHsvColorPickerSlider}>
                <HueColorSlider value={hue} onChange={hueChange} className="color-slider" />
            </div>
        </FlexBox>
    );
}

CustomHsvColorPicker.displayName = "CustomHsvColorPicker";

import { SimInterface } from "@internal/sim-framework";
import { RecolorizationDialogTypes } from "@six/features/editorUI/recolorization";

export const legacyIconSim: SimInterface<"SelectedZone"> = {
    name: "@internal/advanced-editor-sim-icon-legacy",
    core: {
        extensions: [],
        selectedZone: {
            detailZoneIds: new Set([
                { type: "ColorPicker", activeDialogType: RecolorizationDialogTypes.SingleColorImageColorPicker }
            ])
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { useDesignEngine, useActivePanel } from "@design-stack-vista/core-features";
import { useColorPaletteForDecorationTechnology } from "@internal/feature-color-palette";
import { COLOR_PICKER_PANEL_TYPES, ColorPickerPanel, ColorSwatch } from "@internal/feature-color-picker";
import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { ExpandingButton, ExpandingButtonHandles } from "@internal/ui-studio-chrome";
import { SelectableColor } from "@internal/utils-color";
import React, { useMemo, useRef } from "react";
import { useSingleColorContrast } from "../../../hooks";
import { useLocalized } from "../../../localized";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import styles from "./AdjustContrastColorPicker.module.scss";
import { STUDIO_TRACKING_EVENTS } from "../../../TrackingEventConstants";

const noop = () => {};

export function AdjustContrastColorPicker(props: { enableToggleBackground: boolean }) {
    const { enableToggleBackground } = props;
    const localized = useLocalized();
    const { locale } = localized;
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const targetPanel = activePanel ?? designEngine.layoutStore.visiblePanels[0];

    const { decorationTechnology } = targetPanel.panelProperties;

    const colorPalette = useColorPaletteForDecorationTechnology(decorationTechnology);
    const { color: selectedColor, handleChangeColor: onColorChange } = useSingleColorContrast();

    const buttonRef = useRef<ExpandingButtonHandles>(null);
    const trackingClient = useTrackingClient();

    const singleColorPalette: SelectableColor[] = useMemo(() => {
        if (colorPalette) {
            return colorPalette.entries.map(entry => {
                return {
                    value: entry.cdifValue,
                    cssBackground: entry.cssBackground
                };
            });
        }
        return [];
    }, [colorPalette]);

    const handleColorChange = (cdifColor: string) => {
        const chosenColor = singleColorPalette.find(colorEntity => colorEntity.value === cdifColor);
        if (chosenColor) {
            buttonRef.current?.close();
            onColorChange(chosenColor);
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_UPDATE_COLOR
            });
        }
    };

    if (!selectedColor) {
        return null;
    }

    return (
        <React.Fragment>
            <DesktopExperience>
                <ExpandingButton
                    showArrow
                    content={
                        <ColorPickerPanel
                            color={selectedColor.value}
                            onChange={handleColorChange}
                            panelType={COLOR_PICKER_PANEL_TYPES.SINGLE_COLOR_IMAGE}
                            descriptionTitle={localized.get("colorsDescriptionTitle")}
                            enableToggleBackground={enableToggleBackground}
                        />
                    }
                    expandingButtonDownArrowClassName={styles.expandingButtonDownArrow}
                    buttonClassName={styles.buttonWrapper}
                    contentClassName={styles.colorPalette}
                    ref={buttonRef}
                >
                    <ColorSwatch
                        component="span"
                        className={styles.colorSwatch}
                        color={selectedColor}
                        toggleColor={noop}
                        locale={locale}
                    />
                </ExpandingButton>
            </DesktopExperience>
            <MobileExperience>
                <ColorPickerPanel
                    color={selectedColor.value}
                    onChange={handleColorChange}
                    panelType={COLOR_PICKER_PANEL_TYPES.SINGLE_COLOR_IMAGE}
                    descriptionTitle={localized.get("colorsDescriptionTitle")}
                    className={styles.colorPickerPanel}
                    enableToggleBackground={enableToggleBackground}
                />
            </MobileExperience>
        </React.Fragment>
    );
}

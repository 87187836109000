import { action, computed, makeObservable, observable } from "mobx";
import { DEFAULT_PRODUCT_AND_STATE_DATA, ProductAndProjectState } from "./types";

export class ProductAndProjectStateManager {
    @observable private _data: ProductAndProjectState = { ...Object.freeze(DEFAULT_PRODUCT_AND_STATE_DATA) };

    constructor() {
        makeObservable(this);
    }

    @computed
    get data() {
        return this._data;
    }

    @action.bound
    initializeConfiguration(data: ProductAndProjectState) {
        const originalKeys = Object.keys(this._data) as (keyof ProductAndProjectState)[];

        /**
         * @note: Instead of setting `this._data = data`, instead we iterate over each
         * key in the new data set and assign it to the corresponding value
         * of the base object. This results in more optimal updates for MobX
         * because it can observe changes to the individual properties of
         * `this._data`. If we replaced the entire reference of `this._data`,
         * by setting `this._data = data`, it results in rerendering every component
         * that observes any property of `this._data`.
         */
        for (const key of originalKeys) {
            this.handleUpdateByKey(key, data);
        }
    }

    private handleUpdateByKey = <Key extends keyof ProductAndProjectState>(
        key: Key,
        data: Partial<ProductAndProjectState>
    ) => {
        const result = data[key];
        // null is not actually a valid type but the initial state from the Product Loading Provider is really screwy atm
        if (result !== undefined && result !== null) {
            if (key === "locale" && typeof result === "string") {
                this._data[key] = result.toLowerCase() as ProductAndProjectState[Key];
            } else {
                this._data[key] = result;
            }
        }
    };

    @action.bound
    updateConfiguration(data: Partial<ProductAndProjectState>) {
        const newKeys = Object.keys(data) as (keyof ProductAndProjectState)[];
        for (const key of newKeys) {
            this.handleUpdateByKey(key, data);
        }
    }

    /**
     * Unset, or delete, a key in the product/project configuration
     * @param key
     */
    @action.bound
    unsetConfigurationKey(key: keyof ProductAndProjectState) {
        delete this._data[key];
    }
}

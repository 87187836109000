import { jsxs, jsx } from "react/jsx-runtime";
import { useDesignEngine, useActivePanel, useOptionalExtension, useRequiredExtension, PanelLayoutExtension, applyZoom, ItemPosition } from "@design-stack-vista/core-features";
import { ItemPreview } from "@design-stack-vista/ida-framework";
import { observer } from "mobx-react-lite";
import { useState, useRef, Fragment } from "react";
import { getCanvasDimensionAndPosition } from "../../shared/PositionAndDimensionCalculations.js";
import { PatternItemPreview } from "./PatternItemPreview.js";
import "../../extensions/PatternItemExtension.js";
import "../../extensions/PatternStore.js";
import { PatternExtension } from "../../extensions/PatternExtension.js";
import "../../shared/patternMessage.js";
import "@internal/utils-custom-metadata";
import { toMmValues } from "@design-stack-vista/utility-core";
import "@design-stack-vista/pattern-sdk";
import "@design-stack-vista/i18n-color-name";
import { getItemStatePreviewUrl } from "../../shared/getItemStatePreviewUrl.js";
import { getLayoutDimensions } from "../../shared/getLayoutDimensions.js";
const BUILD_ITEM_PREVIEW_URL_TENANT = "vistaprint";
const PatternItemPreviewWrapper = observer(({
  item
}) => {
  const designEngine = useDesignEngine();
  const {
    activePanel
  } = useActivePanel();
  const [imagePreviewLoaded, setImagePreviewLoaded] = useState(false);
  const patternItemExtension = useOptionalExtension(item, PatternExtension);
  const panelPreviewBox = useRequiredExtension(item.parent, PanelLayoutExtension);
  const oldPreviewUrl = useRef("");
  const previewUrl = getItemStatePreviewUrl({
    itemState: item,
    tenant: BUILD_ITEM_PREVIEW_URL_TENANT,
    cimDocProperties: designEngine.cimDocStore.cimDocProperties,
    zoom: designEngine.layoutStore.zoom
  });
  if (previewUrl && oldPreviewUrl.current !== previewUrl) {
    oldPreviewUrl.current = previewUrl;
    setImagePreviewLoaded(false);
  }
  if (!patternItemExtension) {
    return null;
  }
  const patternFileUrl = patternItemExtension == null ? void 0 : patternItemExtension.fileUrl;
  const position = applyZoom(toMmValues(item.model.position), designEngine.layoutStore.zoom);
  const getPatternDimension = () => {
    if (patternItemExtension) {
      return patternItemExtension.isInEditingMode && activePanel ? getCanvasDimensionAndPosition(panelPreviewBox.dimensions.width, panelPreviewBox.dimensions.height, patternItemExtension.dimensionRatio, patternItemExtension.xOffsetRatio, patternItemExtension.yOffsetRatio, 1) : {
        canvasWidth: position.width,
        canvasHeight: position.height,
        patternTileDimension: getLayoutDimensions(designEngine, patternItemExtension.tileDimensions),
        canvasPositionX: position.x,
        canvasPositionY: position.y
      };
    }
    return {
      canvasWidth: 0,
      canvasHeight: 0,
      patternTileDimension: {
        width: 0,
        height: 0
      },
      canvasPositionX: 0,
      canvasPositionY: 0
    };
  };
  const {
    canvasWidth,
    canvasHeight,
    patternTileDimension,
    canvasPositionX,
    canvasPositionY
  } = getPatternDimension();
  const showRenderingPreview = () => {
    patternItemExtension == null ? void 0 : patternItemExtension.setIsInEditingMode(false);
  };
  const onImagePreviewLoad = () => {
    setImagePreviewLoaded(true);
  };
  const showPatternClientSidePreview = (patternItemExtension == null ? void 0 : patternItemExtension.isInEditingMode) || !(patternItemExtension == null ? void 0 : patternItemExtension.isInEditingMode) && !imagePreviewLoaded || !!previewUrl;
  return patternItemExtension ? /* @__PURE__ */ jsxs(Fragment, {
    children: [/* @__PURE__ */ jsx("div", {
      style: {
        transform: `translate(${canvasPositionX}px, ${canvasPositionY}px) rotate(${patternItemExtension.rotationAngle}deg)`,
        width: canvasWidth,
        height: canvasHeight,
        zIndex: -999,
        display: showPatternClientSidePreview ? "block" : "none"
      },
      children: /* @__PURE__ */ jsx(PatternItemPreview, {
        show: !!showPatternClientSidePreview,
        height: canvasHeight,
        width: canvasWidth,
        patternTileWidth: patternTileDimension.width,
        patternTileHeight: patternTileDimension.height,
        isUrlSvg: patternItemExtension.isUrlSvg,
        svgData: patternItemExtension == null ? void 0 : patternItemExtension.svgData,
        patternFileUrl,
        fallbackToRenderingPreview: showRenderingPreview,
        repeatPattern: true
      })
    }), /* @__PURE__ */ jsx(ItemPosition, {
      item,
      children: /* @__PURE__ */ jsx(ItemPreview, {
        src: previewUrl,
        onImageLoad: onImagePreviewLoad,
        show: !showPatternClientSidePreview
      })
    })]
  }) : null;
});
export {
  PatternItemPreviewWrapper
};

import { Measurement } from "@design-stack-vista/utility-core";
const getLayoutDimensions = (designEngine, dimensions) => {
  const {
    zoom
  } = designEngine.layoutStore;
  const {
    width: cdifWidth,
    height: cdifHeight
  } = dimensions;
  const width = new Measurement(cdifWidth).mm;
  const height = new Measurement(cdifHeight).mm;
  return {
    width: width * zoom,
    height: height * zoom
  };
};
export {
  getLayoutDimensions
};

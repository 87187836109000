export const DEFAULT_PRODUCT_AND_STATE_DATA = {
    productKey: "",
    productVersion: 0,
    customerSelectedProductOptions: {},
    studioSelectedProductOptions: {},
    quantity: 0,
    quantityPerSize: undefined,
    mpvId: undefined,
    productName: "",
    productCategory: "",
    locale: "",
    template: undefined,
    owner: undefined,
    documentUrl: undefined,
    productDataLoadAttempted: false,
    productDataLoadSuccessful: false,
    isFullBleed: false,
    mcpSku: undefined,
    mcpVersion: undefined,
    workId: undefined,
    workRevisionId: undefined,
    workName: undefined,
    workLastSaved: undefined
};

export interface ProductAndProjectState {
    productKey: string;
    productVersion: number;
    customerSelectedProductOptions: Record<string, string>;
    studioSelectedProductOptions: Record<string, string>;
    quantity: number;
    quantityPerSize?: string;
    mpvId?: string;
    locale: string;
    productName: string;
    productCategory: string;
    template?: string;
    owner?: string;
    documentUrl?: string;
    productDataLoadAttempted: boolean;
    productDataLoadSuccessful: boolean;
    isFullBleed: boolean;
    mcpSku?: string;
    mcpVersion?: number;
    workId?: string;
    workRevisionId?: string;
    workName?: string;
    workLastSaved?: string;
}

import { getBoundsOfCurve } from "./getBoundsOfCurve";
import { normalizePath } from "./normalizePath";
import { SectionPath, BoundingBox, Commands } from "../types";

/**
 * Get bounding box from the array of path objects.
 * @param {Object[]} paths - Information of section paths with commands and co-ordinates.
 * @returns Bounding box with left, right, top, bottom of section paths.
 */
export function getBoundingBox(paths: SectionPath[]): BoundingBox {
    const boundingBox = {
        left: Infinity,
        top: -Infinity,
        right: -Infinity,
        bottom: Infinity
    };
    const normalizedPath = normalizePath(paths);

    for (let index = 0; index < normalizedPath.length; index++) {
        const command = normalizedPath[index];

        const positionX = command.x;
        const positionY = command.y;
        const { x1, y1, x2, y2 } = command;

        if (command.command === Commands.bezierCurveTo && index > 0 && x1 && y1 && x2 && y2) {
            const controlPoint1 = {
                x: x1,
                y: y1
            };
            const controlPoint2 = {
                x: x2,
                y: y2
            };
            const previousCommand = normalizedPath[index - 1];
            const startPoint = {
                x: previousCommand.x,
                y: previousCommand.y
            };
            const endPoint = {
                x: command.x,
                y: command.y
            };
            const bounds = getBoundsOfCurve(startPoint, controlPoint1, controlPoint2, endPoint);
            boundingBox.left = Math.min(boundingBox.left, bounds.left);
            boundingBox.bottom = Math.min(boundingBox.bottom, bounds.top);
            boundingBox.right = Math.max(boundingBox.right, bounds.right);
            boundingBox.top = Math.max(boundingBox.top, bounds.bottom);
        } else {
            boundingBox.left = Math.min(boundingBox.left, positionX);
            boundingBox.bottom = Math.min(boundingBox.bottom, positionY);
            boundingBox.right = Math.max(boundingBox.right, positionX);
            boundingBox.top = Math.max(boundingBox.top, positionY);
        }
    }

    return boundingBox;
}

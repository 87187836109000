import {
    BLANK_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE
} from "@internal/data-access-template";
import React, { createContext, useCallback, useContext } from "react";
import { DesignTileCallback, DesignTileCallbackProps } from "@shared/utils/DesignPanel";
import { observer } from "mobx-react-lite";
import { useGetDesignTileTemplates } from "./hooks/useGetDesignTileTemplates";
import { useSelectBlankCallback } from "./hooks/useSelectBlankCallback";
import { useSelectDuplicateCallback } from "./hooks/useSelectDuplicateCallback";
import { useFullbleedCallback } from "./hooks/useSelectFullbleedCallback";
import { useSelectStartFromScratchCallback } from "./hooks/useSelectStartFromScratchCallback";
import { useSelectTemplateCallback } from "./hooks/useSelectTemplateCallback";

const DesignSelectionCallbackContext = createContext<(selectedOption: string) => DesignTileCallback | undefined>(
    () => undefined
);

export function useDesignSelectionCallback() {
    const context = useContext(DesignSelectionCallbackContext);
    if (context === undefined) {
        throw new Error("useDesignSelectionCallback must be used within an DesignSelectionCallbackProvider");
    }
    return context;
}

export const DesignSelectionCallbackProvider = observer((props: React.PropsWithChildren<{}>) => {
    const selectBlank = useSelectBlankCallback();
    const selectDuplicate = useSelectDuplicateCallback();
    const selectFullbleed = useFullbleedCallback();
    const selectScratch = useSelectStartFromScratchCallback();
    const selectTemplate = useSelectTemplateCallback();
    const { validTemplates } = useGetDesignTileTemplates();

    const getSelectedCallback = useCallback(
        (selectedOption: string) => {
            switch (selectedOption) {
                case BLANK_SELECTED_TEMPLATE:
                    return selectBlank;
                case DUPLICATE_FIRST_PANEL_TEMPLATE:
                    return selectDuplicate;
                case FULLBLEED_SELECTED_TEMPLATE:
                    return selectFullbleed;
                case CUSTOM_SELECTED_TEMPLATE:
                    return selectScratch;
                default: {
                    // We need to assume this is a template iD;
                    const validTemplate = validTemplates.find(template => template.templateToken === selectedOption);
                    if (validTemplate) {
                        return (params: DesignTileCallbackProps) => {
                            return selectTemplate({ ...params, ...validTemplate });
                        };
                    }
                    return undefined;
                }
            }
        },
        [selectBlank, selectDuplicate, selectFullbleed, selectScratch, selectTemplate, validTemplates]
    );

    return (
        <DesignSelectionCallbackContext.Provider value={getSelectedCallback}>
            {props.children}
        </DesignSelectionCallbackContext.Provider>
    );
});

DesignSelectionCallbackProvider.displayName = "DesignSelectionCallbackProvider";

export interface WorkEntityMerchandising {
    quantity: number;
    mpvUrl: string;
    merchandisingSelections: Record<string, string>;
    metadata: Record<string, string>;
}

export interface WorkEntityDesign {
    editUrl: string;
    designUrl: string;
    displayUrl: string;
    manufactureUrl: string;
    docRefUrl: string;
    metadata: Record<string, string>;
}

export interface WorkEntityProduct {
    key: string;
    version: number;
}

export interface WorkEntity {
    workId: string;
    workRevisionId: string;
    workName: string;
    ownerId: string;
    created: string; // date?
    modified: string; // date?
    merchandising: WorkEntityMerchandising;
    design: WorkEntityDesign;
    product: WorkEntityProduct;
    resources: Record<string, string>;
    tenant: string;
}

export type WorkEntityToCreate = Omit<
    WorkEntity,
    "workId" | "workRevisionId" | "created" | "modified" | "tenant" | "design"
> &
    Partial<Omit<WorkEntity, "design">> & { design: Partial<WorkEntityDesign> };

export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
};

export enum WesSortOptions {
    LAST_CREATED = "LastCreated",
    LAST_MODIFIED = "LastModified",
    NAME_ASCENDING = "NameAscending",
    NAME_DESCENDING = "NameDescending"
}

import { AbTestContextProvider } from "@internal/ab-test-framework";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useStudioConfigurationManager } from "@internal/dex";
import { observer } from "mobx-react-lite";
import React from "react";

type Props = {
    locale: string;
};

export const AbTestContextProviderWrapper = observer((props: React.PropsWithChildren<Props>) => {
    const configuration = useStudioConfigurationManager().data;
    const { productKey } = useProductAndProjectStateManager().data;
    return (
        <AbTestContextProvider configuration={configuration} productKey={productKey} locale={props.locale}>
            {props.children}
        </AbTestContextProvider>
    );
});

AbTestContextProviderWrapper.displayName = "AbTestContextProviderWrapper";

import { createSlice } from "@reduxjs/toolkit";
import { setIsPremiumFinishModalOpen } from "./isPremiumFinishModalOpen";

const premiumFinishModalOpenContextSlice = createSlice({
    name: "premiumFinishModalOpenContext",
    initialState: "",
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            setIsPremiumFinishModalOpen,
            (state, action) => action.payload.premiumFinishModalOpenedContext ?? ""
        );
    }
});

export const premiumFinishModalOpenContext = premiumFinishModalOpenContextSlice.reducer;

/* eslint-disable max-len */

import { defineMessages } from "@vp/i18n-helper";

export const FinishMessages = defineMessages({
    applyFinish: {
        id: "studio.components.modals.fullbleedpremiumfinish.applyFinish",
        defaultMessage: "Apply finish",
        description: {
            note: "Appears as modal heading and on button"
        }
    },
    modalTitleMetallic: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.title.metallic",
        defaultMessage: "Foil accent finish",
        description: {
            note:
                "North America (NA) brands as Metallic as `Foil Accent`." +
                " Non NA does not use that branding - usually 'metallic' should be used instead of 'foil accent'"
        }
    },
    modalTitleRaisedInk: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.title.raisedink",
        defaultMessage: "Embossed gloss finish",
        description: {
            note:
                "North America (NA) brands as raised ink as `Embossed Gloss`." +
                "Non NA does not use that branding - 'raised, glossy' or 'Spot UV' should be used instead."
        }
    },
    modalTitleRaisedFoilGold: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.title.raisedfoilgold",
        defaultMessage: "Gold embossed foil finish",
        description: {
            note:
                " North America (NA) brands Gold foil as 'Gold Embossed Foil'." +
                " Non NA does not use that branding. 'Gold Foil' should be used instead."
        }
    },
    modalTitleRaisedFoilSilver: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.title.raisedfoilsilver",
        defaultMessage: "Silver embossed foil finish",
        description: {
            note:
                " North America (NA) brands Silver foil as 'Silver Embossed Foil'." +
                " Non NA does not use that branding. 'Silver Foil' should be used instead."
        }
    },
    modalTitleRaisedFoilGlitterSilver: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.title.raisedfoilglittersilver",
        defaultMessage: "Embossed glitter finish"
    },
    modalSublineMetallic: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.subline.metallic",
        defaultMessage: "Choose where you want us to apply foil accent to your design.",
        description: {
            note:
                "North America (NA) brands as Matellic as `Foil Accent`." +
                " Non NA does not use that branding - usually 'metallic' should be used instead of 'foil accent'"
        }
    },
    modalSublineRaisedInk: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.subline.raisedink",
        defaultMessage: "Choose where you want us to apply embossed gloss finish to your design.",
        description: {
            note:
                "North America (NA) brands as raised ink as `Embossed Gloss`." +
                "Non NA does not use that branding - 'raised, glossy' or 'Spot UV' should be used instead."
        }
    },
    modalSublineRaisedFoilGold: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.subline.raisedfoilgold",
        defaultMessage: "Choose where you want us to apply gold embossed foil to your design.",
        description: {
            note:
                " North America (NA) brands Gold foil as 'Gold Embossed Foil'." +
                " Non NA does not use that branding. 'Gold Foil' should be used instead."
        }
    },
    modalSublineRaisedFoilSilver: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.subline.raisedfoilsilver",
        defaultMessage: "Choose where you want us to apply silver embossed foil to your design.",
        description: {
            note:
                " North America (NA) brands Silver foil as 'Silver Embossed Foil'." +
                " Non NA does not use that branding. 'Silver Foil' should be used instead."
        }
    },
    modalSublineRaisedFoilGlitterSilver: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.subline.raisedfoilglittersilver",
        defaultMessage: "Choose where you want us to apply embossed glitter finish to your design."
    },
    textOnlyTitle: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.textOnly",
        defaultMessage: "Text only"
    },
    imageOnlyTitle: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.imageOnly",
        defaultMessage: "Image only"
    },
    textAndImageTitle: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.textAndImage",
        defaultMessage: "Text and images"
    },

    textOnlySubline: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.textOnlyMetallic",
        defaultMessage: "Make your name and contact details stand out."
    },
    imageOnlySubline: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.imageOnlyMetallic",
        defaultMessage: "Highlight your logo or design details."
    },
    textAndImageSubline: {
        id: "studio.components.modals.fullbleedpremiumfinish.option.textAndImageMetallic",
        defaultMessage: "Apply this finish to your entire design."
    },

    ariaCloseLabel: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.ariaCloseLabel",
        defaultMessage: "Close premium finish modal"
    },
    ariaModalLabel: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.ariaModalLabel",
        defaultMessage: "Provide instructions for designers to apply a premium finish"
    },
    instructions: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.instructions",
        defaultMessage: "Instructions (optional)"
    },
    instructionsPlaceholder: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.instructionsPlaceholder",
        defaultMessage: "Tell us where to apply the finish"
    },
    cta: {
        id: "studio.components.modals.fullbleedpremiumfinish.modal.cta",
        defaultMessage: "Save selection"
    },
    errorModalHeader: {
        id: "studio.components.modals.fullbleedpremiumfinish.errormodal.header",
        defaultMessage: "[[finishType]] finish required"
    },
    errorModalBody: {
        id: "studio.components.modals.fullbleedpremiumfinish.errormodal.body",
        defaultMessage:
            "To continue, choose where you want us to apply [[finishType]] finish. We can apply it to text, images or both.",
        description: {
            note: "A paragraph displayed in a modal warning users that they need to apply brilliant finish to at least one item on the front of the design"
        }
    },
    instructionsEdit: {
        id: "studio.components.modals.fullbleedpremiumfinish.instructions.edit",
        defaultMessage: "Edit"
    },
    image: {
        id: "studio.components.modals.fullbleedpremiumfinish.image",
        defaultMessage: "Full bleed premium finish",
        description: {
            note: "Descriptive alt label for Full bleed premium finish image"
        }
    },
    Metallic: {
        id: "easel.ui.metallic.finish",
        defaultMessage: "Foil Accent",
        description: {
            note:
                "Shown on toggle on metallic finish that changes on and off state. " +
                " North America (NA) brands as `Foil Accent`. " +
                " Non NA does not use that branding. " +
                " Usually 'Metallic' is used instead."
        }
    },
    RaisedInk: {
        id: "easel.ui.raised.ink",
        defaultMessage: "Embossed Gloss",
        description: {
            note:
                "Appears on a toggle denoting a raised ink (Sometimes known as Spot UV) on and off state. " +
                " North America (NA) brands Raised Ink finish as `Embossed Gloss`." +
                " Non NA does not use that branding. Raised Ink or Spot UV should be used instead (locale dependent)."
        }
    },
    RaisedFoilGold: {
        id: "easel.ui.gold.foil",
        defaultMessage: "Gold Embossed Foil",
        description: {
            note:
                "Appears on a toggle denoting a gold foil on and off state" +
                " North America (NA) brands Gold foil as 'Gold Embossed Foil'." +
                " Non NA does not use that branding. 'Gold Foil' should be used instead."
        }
    },
    RaisedFoilSilver: {
        id: "easel.ui.silver.foil",
        defaultMessage: "Silver Embossed Foil",
        description: {
            note:
                "Appears on a toggle denoting a silver foil on and off state" +
                " North America (NA) brands Silver foil as 'Silver Embossed Foil'." +
                " Non NA does not use that branding. 'Silver Foil' should be used instead."
        }
    },
    RaisedFoilGlitterSilver: {
        id: "easel.ui.silver.glitter.foil",
        defaultMessage: "Glitter Embossed Foil",
        description: {
            note: "Appears on a toggle denoting a glitter silver foil on and off state"
        }
    },
    toggleOn: {
        id: "easel.ui.toggle.on",
        defaultMessage: "[[textContent]] on",
        description: {
            note: "Toggle state of on"
        }
    },
    finishOn: {
        id: "studio.components.modals.premiumfinish.modal.finishOn",
        defaultMessage: "Finish on",
        description: {
            note: "Appears next to the lock icon when a premium finish cannot be turned off"
        }
    },
    finishOnMessage: {
        id: "studio.components.modals.premiumfinish.modal.finishOnMsg",
        defaultMessage: "The element is required to have a finish in this template",
        description: {
            note: "Appears in a tooltip whenever the toggle switch or status message is hovered for a locked premium finish item"
        }
    },
    toggleOff: {
        id: "easel.ui.toggle.off",
        defaultMessage: "[[textContent]] off",
        description: {
            note: "Toggle state of off"
        }
    },
    MetallicIsOn: {
        id: "easel.ui.metallic.finish.on",
        defaultMessage: "Foil Accent is ON for this text",
        description: {
            note:
                "Appears below the left text panel to indicate that the 2-star icons in the editor indicate that" +
                " metallic finish is enabled for the text. " +
                " North America (NA) brands 'Metallic' as `Foil Accent`. " +
                " Non NA does not use that branding - 'metallic' should be used instead of 'foil accent'"
        }
    },
    RaisedInkIsOn: {
        id: "easel.ui.raised.ink.on",
        defaultMessage: "Embossed Gloss is ON for this text",
        description: {
            note:
                "Appears below the left text panel to indicate that the 2-star icons in the editor indicate that" +
                " raised ink is enabled for the text." +
                " North America (NA) brands 'Raised Ink' finish as `Embossed Gloss`." +
                " Non NA does not use the `Embossed Gloss` branding. " +
                " Raised Ink or Spot UV should be used instead (locale dependent)."
        }
    },
    RaisedFoilGoldIsOn: {
        id: "easel.ui.gold.foil.on",
        defaultMessage: "Gold Embossed Foil is ON for this text",
        description: {
            note:
                "Appears below the left text panel to indicate that the 2-star icons in the editor indicate that" +
                " gold foil is enabled for the text." +
                " North America (NA) brands Gold foil as 'Gold Embossed Foil'." +
                " Non NA does not use that branding. 'Gold Foil' should be used instead."
        }
    },
    RaisedFoilSilverIsOn: {
        id: "easel.ui.silver.foil.on",
        defaultMessage: "Silver Embossed Foil is ON for this text",
        description: {
            note:
                "Appears below the left text panel to indicate that the 2-star icons in the editor indicate that" +
                " silver foil is enabled for the text." +
                " North America (NA) brands Silver foil as 'Silver Embossed Foil'." +
                " Non NA does not use that branding. 'Silver Foil' should be used instead."
        }
    },
    RaisedFoilGlitterSilverIsOn: {
        id: "easel.ui.glitter.silver.foil.on",
        defaultMessage: "Glitter Embossed Foil is ON for this text",
        description: {
            note:
                "Appears below the left text panel to indicate that the 2-star icons in the editor indicate that" +
                " glitter silver foil is enabled for the text."
        }
    },
    MetallicFinishDescriptionListItem1: {
        id: "easel.ui.MetallicFinishDescriptionListItemText.item1",
        defaultMessage: "Add foil to specific text and images.",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as first
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    MetallicFinishDescriptionListItem2: {
        id: "easel.ui.MetallicFinishDescriptionListItemText.item2",
        defaultMessage: "Finish available on front side only",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as second
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    MetallicFinishDescriptionListItem3: {
        id: "easel.ui.MetallicFinishDescriptionListItemText.item3",
        defaultMessage: "Paper stock options: Matte or Glossy paper",
        description: {
            note: `NA calls Metallic Finish “Foil Accent”. Shown on toggle on metallic finish that changes on and off state as third
            of the three bullet points that describes the finish that will be applied.`
        }
    },

    RaisedInkFinishDescriptionListItem1: {
        id: "easel.ui.RaisedInkFinishDescriptionListItemText.item1",
        defaultMessage: "Raised, clear polymer gloss",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as first
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    RaisedInkFinishDescriptionListItem2: {
        id: "easel.ui.RaisedInkFinishDescriptionListItemText.item2",
        defaultMessage: "Finish available on front side only",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as second
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    RaisedInkFinishDescriptionListItem3: {
        id: "easel.ui.RaisedInkFinishDescriptionListItemText.item3",
        defaultMessage: "Paper stock options: Standard or Premium Matte",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as third
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    defaultFinishDescriptionListItem1: {
        id: "easel.ui.defaultFinishDescriptionListItemText.item1",
        defaultMessage: "Raised, clear polymer gloss",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as first
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    defaultFinishDescriptionListItem2: {
        id: "easel.ui.defaultFinishDescriptionListItemText.item2",
        defaultMessage: "Finish available on front side only",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as second
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    defaultFinishDescriptionListItem3: {
        id: "easel.ui.defaultFinishDescriptionListItemText.item3",
        defaultMessage: "Paper stock options: Matte and Soft touch",
        description: {
            note: `Shown on toggle on metallic finish that changes on and off state as third
            of the three bullet points that describes the finish that will be applied.`
        }
    },
    noPremiumFinishHeader: {
        id: "easel.ui.noPremiumFinishErrorModal.header",
        defaultMessage: "[[finishType]] finish required",
        description: {
            note: "Heading for a modal notifying the user that they need to apply a premium finish"
        }
    },
    noPremiumFinishBody: {
        id: "easel.ui.noPremiumFinishErrorModal.body",
        defaultMessage:
            "At least one object in your design requires a [[finishType]] finish before you can finalize your design. Choose which objects you’d like to apply [[finishType]] finish.",
        description: {
            note: "A paragraph displayed in a modal warning users that they need to apply a premium finish to at least one item"
        }
    },
    premiumFinishOption: {
        id: "studio.components.modals.premiumfinish.modal.premiumFinishOption",
        defaultMessage: "Premium finish options",
        description: {
            note: "Premium finish options button in tooltip"
        }
    },
    placeholderWarningMessage: {
        id: "studio.components.modals.premiumfinish.modal.placeholderWarningMessage",
        defaultMessage: "This is placeholder text and will not be printed. Edit this text in your design.",
        description: {
            note: "Appears when there is no edited placeholder in premium finish"
        }
    },
    placeholderWarningTitle: {
        id: "studio.components.modals.premiumfinish.modal.placeholderWarningTitle",
        defaultMessage: "Premium finish placeholder warning.",
        description: {
            note: "The title for the premium finish placeholder warning"
        }
    }
});

import React, { useReducer, useMemo } from "react";
import { DialogCoreValues } from "./DialogCoreValues";
import { DialogTypeValue } from "./types";

export interface ActiveDialogState {
    currentActiveDialog: DialogTypeValue;
    addButtonOffset: number;
    showHighlight: boolean;
}

export interface SetActiveDialogOptions {
    showHighlight?: boolean;
}

export interface ActiveDialogSetters {
    buttonOffset: (nextOffset: number) => void;
    setHighlight: (highlight: boolean) => void;
    dialog: (activeDialog: DialogTypeValue, options?: SetActiveDialogOptions) => void;
}

const initialState: ActiveDialogState = {
    currentActiveDialog: DialogCoreValues.None,
    addButtonOffset: 0,
    showHighlight: false
};

export const DialogTypeValueContext = React.createContext<ActiveDialogState>(initialState);

export const DialogSetters = React.createContext<
    ActiveDialogSetters & { setCurrentActiveDialog: ActiveDialogSetters["dialog"] }
>({
    buttonOffset() {},
    setHighlight() {},
    dialog() {},
    setCurrentActiveDialog() {}
});

type Action =
    | {
          type: "UPDATE_ACTIVE_DIALOG";
          payload: {
              nextDialog: DialogTypeValue;
              options?: SetActiveDialogOptions;
          };
      }
    | {
          type: "UPDATE_BUTTON_OFFSET";
          payload: number;
      }
    | {
          type: "UPDATE_HIGHLIGHT";
          payload: Required<SetActiveDialogOptions>["showHighlight"];
      };

const reducer = (state: ActiveDialogState, action: Action): ActiveDialogState => {
    switch (action.type) {
        case "UPDATE_ACTIVE_DIALOG": {
            return {
                ...state,
                currentActiveDialog: action.payload.nextDialog,
                showHighlight: action.payload.options?.showHighlight ?? false
            };
        }
        case "UPDATE_BUTTON_OFFSET": {
            return {
                ...state,
                addButtonOffset: action.payload
            };
        }
        case "UPDATE_HIGHLIGHT": {
            return {
                ...state,
                showHighlight: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

// TODO: explore rewriting this to use the Jack Herrington/use-subscripion hook pattern
export const ActiveDialogProvider = (props: React.PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setters: ActiveDialogSetters & { setCurrentActiveDialog: ActiveDialogSetters["dialog"] } = useMemo(() => {
        const buttonOffset: ActiveDialogSetters["buttonOffset"] = nextOffset => {
            dispatch({
                type: "UPDATE_BUTTON_OFFSET",
                payload: nextOffset
            });
        };

        const highlight: ActiveDialogSetters["setHighlight"] = nextHighlight => {
            dispatch({
                type: "UPDATE_HIGHLIGHT",
                payload: nextHighlight
            });
        };

        const dialog: ActiveDialogSetters["dialog"] = (activeDialog, options) => {
            dispatch({
                type: "UPDATE_ACTIVE_DIALOG",
                payload: {
                    nextDialog: activeDialog,
                    options
                }
            });
        };

        return {
            buttonOffset,
            setHighlight: highlight,
            dialog,
            setCurrentActiveDialog: dialog
        };
    }, []);

    return (
        <DialogSetters.Provider value={setters}>
            <DialogTypeValueContext.Provider value={state}>{props.children}</DialogTypeValueContext.Provider>
        </DialogSetters.Provider>
    );
};
ActiveDialogProvider.displayName = "ActiveDialogProvider";

import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { InvertColorsIcon } from "@internal/ui-studio-chrome";
import { Button, Link } from "@vp/swan";
import React, { useCallback, type JSX } from "react";
import { useSingleColorContrast } from "../../hooks";
import { useLocalized } from "../../localized";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "../../TrackingEventConstants";
import styles from "./InvertButton.module.scss";

export const AdjustContrastInvertColor = (): JSX.Element => {
    const localized = useLocalized();
    const { handleInvertStatusChange } = useSingleColorContrast();
    const trackingClient = useTrackingClient();

    const invertColor = useCallback(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_INVERT_COLORS_BUTTON
        });
        handleInvertStatusChange();
    }, [handleInvertStatusChange, trackingClient]);

    return (
        <React.Fragment>
            <DesktopExperience>
                <Button
                    skin="secondary"
                    size="mini"
                    iconPosition="left"
                    fontWeight="bold"
                    fontSize="xsmall"
                    onClick={invertColor}
                    paddingX={"5"}
                    ml={"5"}
                >
                    <InvertColorsIcon className={styles.invertColorsIcon} />
                    {localized.get("invertColors")}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <Link
                    render={p => (
                        <Button skin="link" className={p.className} onClick={invertColor} fontWeight="bold">
                            {p.children}
                        </Button>
                    )}
                >
                    {localized.get("invertColors")}
                </Link>
            </MobileExperience>
        </React.Fragment>
    );
};

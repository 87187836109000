import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { LayersIcon } from "@internal/ui-studio-chrome";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { isCareAgent } from "@internal/utils-browser";
import type {
    StudioContentDiscoveryZone,
    UseOverrideEnableState
} from "@internal/feature-studio-content-discovery-zone";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { LayersContentPanelDialogType } from "../LayersContentPanelDialogTypes";

const messages = defineMessages({
    layersTitle: {
        id: "editorUI.addContent.panel.layers.title",
        defaultMessage: "Layers",
        description: {
            note: "Content Panel title text for changing layers"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    return children({
        icon: <LayersIcon aria-hidden="true" />,
        label: t(messages.layersTitle.id)
    });
};

const moduleName = "@internal/advanced-editor-content-discovery-zone-layer-legacy" as const;

const useLayerTools: UseOverrideEnableState = () => {
    return { [moduleName]: useIsFlagEnabled("advancedTools") || isCareAgent() };
};

export const layersContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: moduleName,
    core: {
        Layers: { activeDialogType: LayersContentPanelDialogType.layers, component: CDZButton }
    },
    deferredLoader: () => import("./index.deferred"),
    useOverrideEnableState: useLayerTools
};

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";

type ContextData = {
    isNewLockedStateABEnabled: boolean;
    trackImpressionForNewLockedState: () => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useNewLockedState() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a NewLockedStateABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const NewLockedStateExperimentData: ExperimentData = {
    experimentKey: "new_locked_state",
    experimentName: "New Locked State",
    variations: Variations
};

export const NewLockedStateABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = NewLockedStateExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);

    const trackImpressionForNewLockedState = useCallback(() => {
        if (!experimentKey || !ABTest || isTrackingComplete) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            } else if (variation === Variations.Test) {
                setIsEnabled(false);
            }
        };

        trackImpressionIfInVariant(Variations.Test);
        trackImpressionIfInVariant(Variations.Control);
        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete]);

    const contextObject = useMemo(
        () => ({
            isNewLockedStateABEnabled: isEnabled,
            trackImpressionForNewLockedState
        }),
        [isEnabled, trackImpressionForNewLockedState]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

NewLockedStateABProvider.displayName = "NewLockedStateABProvider";

import { useCallback, useMemo } from "react";
import { useDesignEngine } from "@design-stack-vista/core-features";
import {
    addImage,
    isBackgroundItem,
    removeItem,
    changeNaturalDimensions,
    executeCommand,
    getNewIdAndNextZIndex,
    type Command,
    type CommandArgsType,
    type ExecuteCommandResult
} from "@design-stack-vista/cimdoc-state-manager";
import { CimDoc, DclMetadataItem, ImageItem } from "@design-stack-vista/cdif-types";
import { StudioMetadataItem, metadataItemsForImageId, removeCustomMetadata } from "@internal/utils-custom-metadata";
import { useGenerateCutline } from "./useGenerateCutline";
import { CutlineShapeType, CachedCutlineImageData } from "./CutlineConfigurationManager";
import { UnitlessBoundingBox } from "@prepress/custom-cutline-utility";
import type { DispatchToast, DispatchToastProps } from "@internal/utils-detail-zone";
import { UnitlessDimensions } from "@design-stack-vista/utility-core";
import { useCutlineConfiguration } from "./CutlineConfigurationProvider";
import { useGetCutlineMetadata } from "./useGetCutlineMetadata";
import { useTranslationSSR } from "@vp/i18n-helper";
import cloneDeep from "lodash/cloneDeep";

type CustomExecuteCommand = <T, R>(
    command: Command<T, R>,
    commandArgs: CommandArgsType<Command<T, R>>
) => ExecuteCommandResult<R>;

export type ImageVariantType = "original" | "backgroundRemoved";

export type CutlineBaseImageVariantType = ImageVariantType | "saliencyMask";

export type ApplyCutlineItemsArgs = {
    imageData?: {
        originalImageItem?: ImageItem;
        originalImageDimensions?: UnitlessDimensions;
        backgroundRemovedImageItem?: ImageItem;
        backgroundRemovedCropBoundingBox?: UnitlessBoundingBox;
        imageMetadata?: {
            dclMetadata?: Omit<DclMetadataItem, "id">;
            studioMetadata?: StudioMetadataItem;
        };
        imageVariantType: ImageVariantType;
        cutlineBaseImageVariantType: CutlineBaseImageVariantType;
    };
    cutlineShape?: CutlineShapeType;
    panelId?: string;
    cimDoc?: CimDoc;
    altCutlineBoundingBox?: UnitlessBoundingBox;
};

/**
 * Create a cimDoc with only an input image for cutline generation. Only the image is necessary for cutline generation,
 * if other items are included it could result in an unexpected cutline shape.
 */
const createImageOnlyDocument = (cimDoc: CimDoc, imageItem: ImageItem) => {
    const {
        version,
        fontRepositoryUrl,
        document: {
            panels: [panel]
        }
    } = cimDoc;
    const { width, height, decorationTechnology } = panel;

    return {
        version,
        fontRepositoryUrl,
        document: {
            panels: [
                {
                    width,
                    height,
                    decorationTechnology,
                    id: "panel-for-cutline-generation",
                    name: "panel-for-cutline-generation",
                    images: [imageItem]
                }
            ]
        }
    };
};

const getSaliencyPositionData = (
    imageDimensions: UnitlessDimensions,
    cropBoundingBox: UnitlessBoundingBox,
    adjustedContentBounds: UnitlessBoundingBox
): UnitlessBoundingBox => {
    const { x: cropX, y: cropY, width: cropWidth, height: cropHeight } = cropBoundingBox;
    const { width: imageWidth, height: imageHeight } = imageDimensions;
    const { x: adjustedX, y: adjustedY, width: adjustedWidth, height: adjustedHeight } = adjustedContentBounds;

    const newWidth = (imageWidth * adjustedWidth) / cropWidth;
    const newHeight = (imageHeight * adjustedHeight) / cropHeight;
    const newX = adjustedX - (cropX * newWidth) / imageWidth;
    const newY = adjustedY - (cropY * newHeight) / imageHeight;

    return {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight
    };
};

const fitsWithin = (contentBox: UnitlessBoundingBox, containerBox: UnitlessBoundingBox, margin: number) =>
    contentBox.x - containerBox.x >= margin &&
    containerBox.x + containerBox.width - contentBox.x - contentBox.width >= margin &&
    contentBox.y - containerBox.y >= margin &&
    containerBox.y + containerBox.height - contentBox.y - contentBox.height >= margin;

export const useApplyCutlineItems: (props: { dispatchToast?: DispatchToast }) => {
    applyCutlineItems: ({
        imageData,
        panelId,
        cutlineShape,
        cimDoc,
        altCutlineBoundingBox
    }: ApplyCutlineItemsArgs) => Promise<CimDoc | undefined>;
} = (props: { dispatchToast?: DispatchToast }) => {
    // on the off chance that dispatchToast isn't defined, log the message as a warning to not block experience
    const dispatchToast = useMemo(
        () =>
            props.dispatchToast
                ? props.dispatchToast
                : (props: DispatchToastProps) => {
                      // eslint-disable-next-line no-console -- this is only run as a fallback
                      console.warn(props.message);
                  },
        [props.dispatchToast]
    );
    const { generateCutline } = useGenerateCutline({ dispatchToast });
    const designEngine = useDesignEngine();
    const { t } = useTranslationSSR();
    const cutlineConfigurationManager = useCutlineConfiguration();
    const {
        cutlineShape: metadataCutlineShape,
        cachedImageData: metadataCachedImageData,
        cutlineBaseImageVariantType: metadataCutlineBaseImageVariantType
    } = useGetCutlineMetadata();

    const applyCutlineItems = useCallback(
        async ({ imageData, panelId, cutlineShape, cimDoc, altCutlineBoundingBox }: ApplyCutlineItemsArgs) => {
            // don't need to enable loading state for a cimdoc that isn't in the design engine
            if (!cimDoc) {
                cutlineConfigurationManager.setIsGeneratingCutline(true);
            }

            let currentDocument = designEngine.cimDocStore.asJson;

            let executeCommandFn: typeof designEngine.executeCommand | CustomExecuteCommand =
                designEngine.executeCommand;

            if (cimDoc) {
                executeCommandFn = (command, commandArgs) => {
                    return executeCommand(cimDoc, command, commandArgs);
                };

                currentDocument = cimDoc;
            }

            let updatedDocument;

            try {
                const inputCutlineShape: CutlineShapeType = cutlineShape || metadataCutlineShape || "custom";
                // check if an image already exists on the doc
                const existingImage = currentDocument.document.panels[0].images?.[0];
                let imageId = existingImage?.id;
                let imageItemForCutlineGeneration = existingImage;
                let imageItemForDocument = existingImage;
                let cutlineVariantTypeForDocument: CutlineBaseImageVariantType = metadataCutlineBaseImageVariantType;
                let backgroundRemovedCropBoundingBoxForDocument: UnitlessBoundingBox | undefined;
                let originalImageDimensionsForDocument: UnitlessDimensions | undefined;

                const cutlineMessages = cutlineConfigurationManager.getCutlineMessages();
                const cachedImageData = cutlineConfigurationManager.getCachedImageData() || metadataCachedImageData;

                if (imageData) {
                    const {
                        cutlineBaseImageVariantType,
                        backgroundRemovedImageItem,
                        originalImageItem,
                        originalImageDimensions
                    } = imageData;
                    cutlineVariantTypeForDocument = cutlineBaseImageVariantType;

                    // default to generating cutline around original image
                    imageItemForCutlineGeneration = originalImageItem || cachedImageData?.originalImageItem;
                    imageItemForDocument = originalImageItem || cachedImageData?.originalImageItem;

                    // if there's background removed image data, use the background removed image for cutline generation and on the document
                    if (
                        cutlineBaseImageVariantType === "backgroundRemoved" &&
                        (backgroundRemovedImageItem || cachedImageData?.backgroundRemovedImageItem)
                    ) {
                        imageItemForCutlineGeneration =
                            backgroundRemovedImageItem || cachedImageData?.backgroundRemovedImageItem;
                        imageItemForDocument =
                            backgroundRemovedImageItem || cachedImageData?.backgroundRemovedImageItem;
                    }

                    // if trying to retain original background, use background removed image for cutline generation and
                    // use original image on the document. If there's no background removed image use the original for cutline generation
                    if (cutlineBaseImageVariantType === "saliencyMask" && inputCutlineShape === "custom") {
                        imageItemForCutlineGeneration =
                            backgroundRemovedImageItem ||
                            cachedImageData?.backgroundRemovedImageItem ||
                            cachedImageData?.originalImageItem;
                        imageItemForDocument = originalImageItem || cachedImageData?.originalImageItem;
                    }

                    backgroundRemovedCropBoundingBoxForDocument =
                        imageData.backgroundRemovedCropBoundingBox || cachedImageData?.backgroundRemovedCropBoundingBox;

                    originalImageDimensionsForDocument =
                        originalImageDimensions || cachedImageData.originalImageDimensions;
                }

                if (!imageItemForCutlineGeneration) {
                    throw new Error("Cannot apply cutline items without new or existing image.");
                }

                const inputCimDoc = createImageOnlyDocument(currentDocument, imageItemForCutlineGeneration);
                const targetPanelId = panelId ? panelId : currentDocument.document.panels[0].id;
                const panelState = designEngine.cimDocStore.panels.find(panel => panel.id === targetPanelId);

                if (!panelState) {
                    throw new Error("Cannot find target panel to apply cutline items to.");
                }

                // get initial cutline data
                let {
                    cutlineItem,
                    cutlinePath,
                    adjustedContentBounds,
                    cutlineBoundingBox,
                    safetyDistanceInMm,
                    cutlinePrecision
                } = await generateCutline(inputCimDoc, panelState, inputCutlineShape, altCutlineBoundingBox);

                let imagePosition = adjustedContentBounds;

                // validate and update image position if attempting to retain original image background
                if (
                    imageData?.cutlineBaseImageVariantType === "saliencyMask" &&
                    inputCutlineShape === "custom" &&
                    backgroundRemovedCropBoundingBoxForDocument
                ) {
                    const { getContentBoundsForCutline } = await import("@prepress/custom-cutline-utility"); // Lazy load for bundle-size concerns
                    const cutlineContentBounds = getContentBoundsForCutline({
                        // @ts-ignore the ternary below limits the inputs to the correct ones
                        cutlineShape: cutlineShape === "roundedRectangle" ? "rectangle" : inputCutlineShape,
                        cutlineBoundingBox,
                        safetyDistanceInMm,
                        contentDimensions: cutlineBoundingBox
                    });

                    if (!originalImageDimensionsForDocument) {
                        dispatchToast({
                            skin: "standard",
                            message: t(cutlineMessages.customCutlineError.id),
                            autoClose: true
                        });

                        throw new Error("Original image dimensions not found.");
                    }

                    const newImagePosition = getSaliencyPositionData(
                        originalImageDimensionsForDocument,
                        backgroundRemovedCropBoundingBoxForDocument,
                        adjustedContentBounds
                    );

                    // if the original image with background can't fill the entire cutline fallback to generating cutline
                    // around entire original image instead of the salient part of it
                    if (!fitsWithin(cutlineContentBounds, newImagePosition, safetyDistanceInMm)) {
                        dispatchToast({
                            skin: "standard",
                            message: t(cutlineMessages.customCutlineError.id),
                            autoClose: true
                        });

                        if (!imageItemForDocument) {
                            throw new Error("Cannot apply cutline items without new or existing image.");
                        }

                        const fallbackInputCimDoc = createImageOnlyDocument(
                            designEngine.cimDocStore.asJson,
                            imageItemForDocument
                        );
                        const fallbackCutlineData = await generateCutline(
                            fallbackInputCimDoc,
                            panelState,
                            inputCutlineShape,
                            altCutlineBoundingBox
                        );

                        cutlineItem = fallbackCutlineData.cutlineItem;
                        cutlinePath = fallbackCutlineData.cutlinePath;
                        adjustedContentBounds = fallbackCutlineData.adjustedContentBounds;
                        cutlineBoundingBox = fallbackCutlineData.cutlineBoundingBox;
                        safetyDistanceInMm = fallbackCutlineData.safetyDistanceInMm;
                        cutlinePrecision = fallbackCutlineData.cutlinePrecision;

                        imagePosition = adjustedContentBounds;
                        cutlineVariantTypeForDocument = "original";
                    } else {
                        imagePosition = newImagePosition;
                    }
                }

                let imageDataToCache: CachedCutlineImageData;

                // always attempt to cache image data as long as the original image data is available
                if (imageData?.originalImageItem && imageData?.originalImageDimensions) {
                    imageDataToCache = {
                        originalImageItem: imageData.originalImageItem,
                        originalImageDimensions: imageData.originalImageDimensions,
                        backgroundRemovedImageItem: imageData.backgroundRemovedImageItem,
                        backgroundRemovedCropBoundingBox: imageData.backgroundRemovedCropBoundingBox
                    };

                    cutlineConfigurationManager.setImageData(imageDataToCache);
                }

                const { cimDoc: commandResultDoc } = executeCommandFn(cimDoc => {
                    // Add/replace the image if we receive a new one
                    const originalDclMetadata = cloneDeep(cimDoc.metadata?.dclMetadata);
                    const originalStudioMetadata = cloneDeep(cimDoc.metadata?.studioMetadata);

                    if (imageData) {
                        const { imageVariantType, imageMetadata } = imageData;

                        if (existingImage) {
                            removeCustomMetadata(cimDoc, { ids: [existingImage.id] });
                            removeItem(cimDoc, { id: existingImage.id });
                        }

                        if (!imageItemForDocument) {
                            throw new Error("Cannot apply cutline items without new or existing image.");
                        }

                        const { id } = addImage(cimDoc, { panelId: targetPanelId, image: imageItemForDocument });
                        imageId = id;

                        // Update metadata for the image
                        const { dclMetadataItem, studioMetadataItem } = metadataItemsForImageId(cimDoc, imageId);

                        // Copy metadata back into cimdoc for when image is the same but only minor changes are made
                        // e.g background removal, change size
                        const originalDclMetadataItem = originalDclMetadata?.[0];
                        const [originalStudioMetadataItem] = Object.values(originalStudioMetadata);

                        // applied processes array needs to match image variant
                        if (originalDclMetadataItem && originalDclMetadataItem.imageTransformations?.appliedProcesses) {
                            const index =
                                originalDclMetadataItem.imageTransformations?.appliedProcesses.indexOf(
                                    "backgroundRemove"
                                );
                            if (imageVariantType === "original" && index !== undefined && index !== -1) {
                                originalDclMetadataItem.imageTransformations?.appliedProcesses.splice(index, 1);
                            } else if (imageVariantType === "backgroundRemoved" && index === -1) {
                                originalDclMetadataItem.imageTransformations?.appliedProcesses.push("backgroundRemove");
                            }
                            const { id, ...partialDclMetadataItem } = originalDclMetadataItem;
                            Object.assign(dclMetadataItem, partialDclMetadataItem);
                        }
                        if (originalStudioMetadataItem) {
                            Object.assign(studioMetadataItem, originalStudioMetadataItem);
                        }
                        // Passed in metadata takes precedence, as it could be new metadata from changing to a completely different image
                        Object.assign(dclMetadataItem, imageMetadata?.dclMetadata);
                        Object.assign(studioMetadataItem, imageMetadata?.studioMetadata);

                        cimDoc.metadata = {
                            ...cimDoc.metadata,
                            imageVariantType,
                            cutlineBaseImageVariantType: cutlineVariantTypeForDocument
                        };
                    }

                    if (imageId) {
                        changeNaturalDimensions(cimDoc, {
                            id: imageId,
                            newWidth: imagePosition.width,
                            newHeight: imagePosition.height,
                            newX: imagePosition.x,
                            newY: imagePosition.y
                        });
                    }

                    const backgroundItem = cimDoc.document.panels
                        .find(panel => panel.id === targetPanelId)
                        ?.shapes?.find(shapeItem => isBackgroundItem(shapeItem, cimDoc.metadata));
                    const bgColor = backgroundItem && "color" in backgroundItem ? backgroundItem.color : undefined;

                    const { zIndex } = getNewIdAndNextZIndex(cimDoc, targetPanelId);

                    cimDoc.metadata = {
                        ...cimDoc.metadata,
                        cutlineDetails: {
                            ...cimDoc.metadata?.cutlineDetails,
                            bgColor,
                            cutlineItem: {
                                ...cutlineItem,
                                zIndex: zIndex || 2
                            },
                            cutlinePath,
                            selectedCutlineShape: inputCutlineShape,
                            selectedCutlinePrecision: cutlinePrecision,
                            safetyDistanceInMM: safetyDistanceInMm
                        }
                    };

                    // add image data to the document so reloaded docs can work
                    if (imageDataToCache) {
                        cimDoc.metadata.cutlineDetails.cachedImageData = imageDataToCache;
                    }
                }, {});
                updatedDocument = commandResultDoc;
            } catch {
                cutlineConfigurationManager.setIsGeneratingCutline(false);
            }

            cutlineConfigurationManager.setIsGeneratingCutline(false);

            // update cutline panel value to match the actual cutline that was generated
            if (cutlineShape && cutlineConfigurationManager.cutlineShape !== cutlineShape) {
                cutlineConfigurationManager.setCutlineShape(cutlineShape);
            }

            return updatedDocument;
        },
        [
            designEngine,
            generateCutline,
            metadataCutlineShape,
            cutlineConfigurationManager,
            metadataCachedImageData,
            dispatchToast,
            t,
            metadataCutlineBaseImageVariantType
        ]
    );

    return {
        applyCutlineItems
    };
};

import { createSlice } from "@reduxjs/toolkit";
import { setShowSaveAs } from "./showSaveAs";

const isDuplicatingDesignSlice = createSlice({
    name: "isDuplicatingDesign",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setShowSaveAs, (state, action) => action.payload.isDuplicatingDesign ?? false);
    }
});

export const isDuplicatingDesign = isDuplicatingDesignSlice.reducer;

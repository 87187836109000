import { getQueryParams, windowExists, isDebugMode, isCareAgent, tryParse } from "@internal/utils-browser";
import { getUserSessionId, getStudioUniqueSessionId } from "@internal/utils-tracking";
import type { DSS } from "@vp/types-ddif";

function getQMReplayLink(): string | undefined {
    return (
        (windowExists() &&
            window.QuantumMetricAPI &&
            window.QuantumMetricAPI.getReplay &&
            window.QuantumMetricAPI.getReplay()) ||
        undefined
    );
}

// add some sampling to only track a tenth of customers for some metrics
const shouldTrackInSegment = Math.random() <= 0.1;
export function shouldTrack() {
    return shouldTrackInSegment || isDebugMode();
}

let trackingDocumentMetadata = {};
export function generateDocumentMetadata(document: DSS.DesignDocument) {
    const firstPanelId = document.document.panels[0].id;
    const documentSource =
        document.metadata?.documentSources?.panels?.find(p => p.id === firstPanelId)?.source ??
        document.metadata?.documentSources?.panels?.[0]?.source;
    trackingDocumentMetadata = {
        documentSource,
        decorationTechnology: document.document.panels[0].decorationTechnology
    };
}

export function getGeneralTrackingData() {
    return {
        studioUniqueSession: getStudioUniqueSessionId(),
        studioUserSession: getUserSessionId(),
        qmReplayLink: getQMReplayLink()
    };
}

function getTrackExperimentData() {
    const experimentData =
        (windowExists() &&
            localStorage.getItem("trackExperiments") &&
            tryParse(localStorage.getItem("trackExperiments"), {})) ||
        {};

    return Object.keys(experimentData).length > 0 ? experimentData : undefined;
}

export function getStudioTrackingMetadata() {
    return {
        careAgent: isCareAgent(),
        debugMode: isDebugMode(),
        trackExperiments: getTrackExperimentData(),
        ...trackingDocumentMetadata
    };
}

export function getQueryStringTrackingData() {
    const queryParams = getQueryParams();
    const quickStudioAnalytics = tryParse<Record<string, string> | undefined>(
        queryParams.quickStudioAnalytics,
        undefined
    );
    const galleryMetadata = tryParse<Record<string, string> | undefined>(queryParams.metadata, undefined);

    return {
        quickStudioAnalytics,
        galleryMetadata
    };
}

import type { ImageItem } from "@design-stack-vista/cdif-types";

export function getOriginalImageSourceUrl(imageItem: ImageItem): string | undefined {
    const { originalSourceUrl, overlays } = imageItem;

    if (originalSourceUrl) {
        return originalSourceUrl;
    }

    if (Array.isArray(overlays) && overlays.length > 0) {
        return overlays[0].printUrl;
    }

    return undefined;
}

import { useState } from "react";
import { PriceQuantityData, getProductQuantityWithPricing } from "@internal/data-access-calcifer";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import { useAsyncEffect } from "@design-stack-vista/utility-react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

export const useGetQuantityAttributes = (): PriceQuantityData | undefined => {
    const { productKey, productVersion, studioSelectedProductOptions, locale } =
        useProductAndProjectStateManager().data;
    const { isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const [quantityAttributesData, setQuantityAttributesData] = useState<PriceQuantityData>();

    useAsyncEffect(
        helper => {
            (async () => {
                if (!isMileStone2Enabled && !isMileStone3Enabled) {
                    helper.runIfMounted(() => setQuantityAttributesData(undefined));
                    return;
                }

                if (!productKey || !productVersion) {
                    helper.runIfMounted(() => setQuantityAttributesData(undefined));
                    return;
                }

                const quantityData = await getProductQuantityWithPricing(
                    productKey,
                    productVersion,
                    studioSelectedProductOptions,
                    locale
                );
                helper.runIfMounted(() => setQuantityAttributesData(quantityData));
            })();
        },
        [isMileStone2Enabled, isMileStone3Enabled, locale, productKey, productVersion, studioSelectedProductOptions]
    );

    return quantityAttributesData;
};

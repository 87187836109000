import type { CimDocStore, DesignState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { computed, makeObservable } from "mobx";
import { BaseExtension, CIMDOC_STORE_TOKEN } from "@design-stack-vista/interactive-design-engine-core";
import { DSS } from "@vp/types-ddif";

/**
 * Provides information about the design source of the panel
 * as well as other information useful when determining surface upsells
 */
export class PanelSourceExtension extends BaseExtension {
    declare designState: PanelState;

    static supports(state: DesignState) {
        return state.isPanelState();
    }

    static override inject = [CIMDOC_STORE_TOKEN];

    constructor(designState: DesignState, private cimdocStore: CimDocStore) {
        super(designState);
        makeObservable(this);
    }

    @computed
    get panelSource(): undefined | DSS.DocumentPanelSource {
        if (!this.cimdocStore.metadata) {
            return undefined;
        }
        return (this.cimdocStore.metadata as DSS.Metadata).documentSources?.panels.find(
            panelSource => panelSource.id === this.designState.id
        );
    }

    @computed
    get colorMode() {
        return this.designState.panelProperties.colorMode;
    }

    @computed
    get index(): number {
        return this.cimdocStore.panels.findIndex(panel => panel.id === this.designState.id);
    }

    @computed
    get hasItems(): boolean {
        return this.designState.items.length > 0;
    }
}

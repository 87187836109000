import { rgb2hex, type RGBA } from "@design-stack-vista/utility-core";
import { type InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import type { ColorPaletteType } from "@internal/feature-color-palette";
import { type SelectableColor } from "@internal/utils-color";
import { getActivePanel } from "@design-stack-vista/core-features";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { convertToRGBA } from "@mcp-artwork/color-converter";
import { findSelectableColor } from "./getSelectableColorFromImageURL";

interface Props {
    designEngine: InteractiveDesignEngine;
}

function convertToRGBAColor(firstColor: string): RGBA | undefined {
    try {
        return convertToRGBA(firstColor);
    } catch (error) {
        // Log error in case of MissingPantoneColorsError or InvalidPantoneColorError
        newRelicWrapper.noticeError(error as Error, {
            color: firstColor
        });
    }
    return undefined;
}

function createSelectableColor(color: string, rgba: RGBA): SelectableColor | undefined {
    return {
        value: color,
        cssBackground: rgb2hex(rgba)
    };
}

export const getSelectableColorFromActivePanel = ({ designEngine }: Props): SelectableColor | undefined => {
    const activePanel = getActivePanel(designEngine) || designEngine.layoutStore.visiblePanels[0];

    if (!activePanel) {
        return undefined;
    }

    // For a single color product, there will be only one color in this array
    const [firstColor] = designEngine.colorRestrictionStore.currentColors[activePanel.id];

    if (firstColor) {
        const rgbaColor = convertToRGBAColor(firstColor);
        if (rgbaColor) {
            return createSelectableColor(firstColor, rgbaColor);
        }
    }

    return undefined;
};

/**
 * Finds a selectable color from the given color value and color palette.
 * If a selectable color is not found in the palette, it attempts to convert the color value to RGBA
 * and returns it as a selectable color option.
 *
 * @param colorValue - The color value to find a selectable color for.
 * @param selectedItemColorPalette - The color palette to search for a selectable color.
 * @returns The selectable color option, or null if no selectable color is found.
 */
export function getSelectableColorFromColorPalette(
    colorValue: string,
    selectedItemColorPalette: ColorPaletteType
): SelectableColor | undefined {
    const matchedColor = findSelectableColor(selectedItemColorPalette, colorValue);
    if (matchedColor) {
        return matchedColor;
    }

    const rgbaColor = convertToRGBAColor(colorValue);
    if (rgbaColor) {
        return createSelectableColor(colorValue, rgbaColor);
    }
    return undefined;
}

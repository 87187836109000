var DotPattern = /* @__PURE__ */ ((DotPattern2) => {
  DotPattern2["Curved"] = "curved";
  DotPattern2["Square"] = "square";
  DotPattern2["Dots"] = "dots";
  DotPattern2["ClassyBlocks"] = "classy-blocks";
  DotPattern2["ConnectHorizontal"] = "connect-horizontal";
  return DotPattern2;
})(DotPattern || {});
var EyePattern = /* @__PURE__ */ ((EyePattern2) => {
  EyePattern2["Square"] = "square";
  EyePattern2["Curved"] = "curved";
  EyePattern2["LeftLeaf"] = "leftLeaf";
  EyePattern2["RightLeaf"] = "rightLeaf";
  EyePattern2["Rounded"] = "rounded";
  return EyePattern2;
})(EyePattern || {});
var QRShapeType = /* @__PURE__ */ ((QRShapeType2) => {
  QRShapeType2["Corners"] = "corners";
  QRShapeType2["Dots"] = "dots";
  return QRShapeType2;
})(QRShapeType || {});
var QRCodeURLPanelMode = /* @__PURE__ */ ((QRCodeURLPanelMode2) => {
  QRCodeURLPanelMode2["Add"] = "add";
  QRCodeURLPanelMode2["Edit"] = "edit";
  QRCodeURLPanelMode2["AddMore"] = "addMore";
  return QRCodeURLPanelMode2;
})(QRCodeURLPanelMode || {});
var QRCodeTabType = /* @__PURE__ */ ((QRCodeTabType2) => {
  QRCodeTabType2["Url"] = "URL";
  QRCodeTabType2["Shape"] = "Shape";
  QRCodeTabType2["Color"] = "Color";
  QRCodeTabType2["ExtraImages"] = "Extra Images";
  return QRCodeTabType2;
})(QRCodeTabType || {});
export {
  DotPattern,
  EyePattern,
  QRCodeTabType,
  QRCodeURLPanelMode,
  QRShapeType
};

import * as React from "react";
import { SVGProps } from "react";

export const LightBlueOXIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 182 209" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
        <g opacity={0.1} filter="url(#a)">
            <path
                d="M171.5 191.805c0 3.59-36.041 6.5-80.5 6.5s-80.5-2.91-80.5-6.5 36.041-6.5 80.5-6.5 80.5 2.91 80.5 6.5Z"
                fill="#000"
            />
        </g>
        <path
            d="M7.5 43.305a2 2 0 0 1 2-2h144.252v18.887a2 2 0 0 0 2 2H174.5v85.113a2 2 0 0 1-2 2H9.5a2 2 0 0 1-2-2v-104Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 43.805v103h162V64.692h-16.248a4.5 4.5 0 0 1-4.5-4.5V43.805H10Zm146.252 3.564 12.241 12.323h-12.241V47.369ZM5 43.305a4.5 4.5 0 0 1 4.5-4.5h144.252a2.5 2.5 0 0 1 1.773.738l20.749 20.887a2.5 2.5 0 0 1 .726 1.762v85.113a4.5 4.5 0 0 1-4.5 4.5H9.5a4.5 4.5 0 0 1-4.5-4.5v-104Z"
            fill="#6A7076"
        />
        <path
            className="color-element"
            fillRule="evenodd"
            clipRule="evenodd"
            d="m129.299 85.376-3.536-3.535-3.536 3.535-3.535-3.535-3.536 3.535 3.536 3.536-3.536 3.535 3.536 3.536 3.535-3.536 3.536 3.536 3.535-3.536-3.535-3.535 3.536-3.536ZM81.5 113.805v-2.499 2.499a2.5 2.5 0 0 1 0-5v2.501-2.501h22a2.5 2.5 0 0 1 0 5h-22Zm-14-24.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-7 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
            fill="#36B7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.093 3.356a2 2 0 0 1-2 2c-15.106 0-22.598.864-31.906 2.318 1.73.178 3.618.36 5.602.551l2.472.239c4.462.434 9.23.92 13.551 1.508 2.178.297 3.902.62 5.192.978.645.179 1.227.379 1.722.61.469.217 1.006.529 1.423 1 .47.53.83 1.334.628 2.276-.174.805-.689 1.344-1.052 1.65-.729.615-1.802 1.085-2.886 1.47-4.456 1.586-13.437 3.175-22.984 4.574-.407.06-.785.12-1.135.178 1.814.486 4.005.965 6.154 1.424l.642.138c2.318.495 4.572.976 5.968 1.403a2 2 0 1 1-1.17 3.825c-1.22-.373-3.304-.818-5.72-1.335l-.558-.12c-2.576-.55-5.417-1.17-7.583-1.82-1.066-.32-2.092-.682-2.868-1.1-.376-.203-.834-.492-1.202-.901-.382-.425-.86-1.22-.633-2.245.201-.913.853-1.448 1.26-1.715.449-.295.984-.517 1.531-.697 1.108-.366 2.674-.69 4.734-.992 8.977-1.316 17.016-2.744 21.323-4.086a49.994 49.994 0 0 0-3.326-.551c-4.237-.578-8.938-1.057-13.398-1.49l-2.444-.237c-3.53-.34-6.819-.656-9.44-.975-1.606-.195-3.01-.397-4.09-.614a12.95 12.95 0 0 1-1.466-.37c-.334-.11-.954-.331-1.425-.793A2.243 2.243 0 0 1 68.32 7.9a2.243 2.243 0 0 1 .644-1.605c.471-.488 1.112-.735 1.492-.868.91-.319 2.318-.616 4.193-.919l.046-.007c11.988-1.935 19.478-3.145 37.398-3.145a2 2 0 0 1 2 2Zm-6.948 10.073h-.001c.016-.015.019-.015.001 0ZM79.206 23.605l.004-.003a.164.164 0 0 1-.004.003ZM71.527 6.391Zm.061 2.886Z"
            fill="#6A7076"
        />
        <defs>
            <filter
                id="a"
                x={0.5}
                y={175.305}
                width={181}
                height={33}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur" />
            </filter>
        </defs>
    </svg>
);

LightBlueOXIcon.displayName = "LightBlueXOIcon";

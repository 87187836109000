var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { action, makeObservable, reaction, flowResult, observable, flow, computed } from "mobx";
import { getPanelDimensionsInMmValue } from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { getColorsFromPatternUrl, getSvgDataWithColorOverride, urlIsSvg, getDefaultPatternPosition, getPatternOffsetPercentage } from "@design-stack-vista/pattern-sdk";
import { parseQueryParams } from "@design-stack-vista/utility-core";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp2(target, key, result);
  return result;
};
function catchCancellation(promise) {
  promise.catch(() => {
  });
  return promise;
}
const defaultPatternToPanelDimensionRatio = 0.3;
class PatternExtension extends BaseExtension {
  constructor(designState) {
    super(designState);
    __publicField(this, "dimensionRatio", 0.3);
    __publicField(this, "rotationAngle", 0);
    __publicField(this, "xOffsetRatio", 0);
    __publicField(this, "yOffsetRatio", 0);
    __publicField(this, "isInEditingMode", false);
    __publicField(this, "svgData");
    __publicField(this, "fileUrl");
    __publicField(this, "isUrlSvg", true);
    __publicField(this, "pendingSvgDataRead");
    __publicField(this, "disposeImagePositionHeightReaction");
    __publicField(this, "disposeItemPositionXReaction");
    __publicField(this, "disposeItemPositionYReaction");
    __publicField(this, "disposeImageRotationReaction");
    __publicField(this, "disposeImageReplaceReaction");
    makeObservable(this);
    this.disposeImageReplaceReaction = reaction(() => this.designState.model.data.image.previewUrl, () => {
      if (this.pendingSvgDataRead) {
        this.pendingSvgDataRead.cancel();
      }
      this.pendingSvgDataRead = catchCancellation(flowResult(this.extractSvgData()));
      catchCancellation(flowResult(this.checkUrl()));
    });
    this.disposeImagePositionHeightReaction = reaction(() => this.designState.model.data.image.position.height, () => {
      this.setDimensionRatio(this.patternToPanelDimensionRatio);
    });
    this.disposeItemPositionXReaction = reaction(() => this.designState.model.position.x, () => {
      this.setXOffsetRatio(100 - this.patternXOffsetPercentage);
    });
    this.disposeItemPositionYReaction = reaction(() => this.designState.model.position.y, () => {
      this.setYOffsetRatio(100 - this.patternYOffsetPercentage);
    });
    this.disposeImageRotationReaction = reaction(() => this.designState.model.rotationAngle, () => {
      this.setRotationAngle(this.patternRotationAngle);
    });
    this.pendingSvgDataRead = catchCancellation(flowResult(this.extractSvgData()));
    catchCancellation(flowResult(this.checkUrl()));
    this.setDimensionRatio(this.patternToPanelDimensionRatio);
    this.setXOffsetRatio(100 - this.patternXOffsetPercentage);
    this.setYOffsetRatio(100 - this.patternYOffsetPercentage);
    this.setRotationAngle(this.patternRotationAngle);
  }
  static supports(designState) {
    return designState.isItemReference() && designState.model.type === ItemReferenceTypes.TILE_IMAGE;
  }
  *extractSvgData() {
    try {
      const selectedPatternUrl = this.designState.model.data.image.previewUrl;
      const hasColorInUrl = !!parseQueryParams(selectedPatternUrl).colorGroup;
      let colorGroup = {};
      if (hasColorInUrl) {
        colorGroup = getColorsFromPatternUrl(selectedPatternUrl);
      }
      const uploadFileUrl = parseQueryParams(selectedPatternUrl).fileUrl || selectedPatternUrl;
      this.fileUrl = uploadFileUrl;
      const svgData = yield getSvgDataWithColorOverride(uploadFileUrl, colorGroup);
      this.svgData = svgData;
    } catch {
      this.svgData = void 0;
    }
  }
  *checkUrl() {
    const url = this.designState.model.data.image.previewUrl;
    this.isUrlSvg = yield urlIsSvg(url);
  }
  get patternToPanelDimensionRatio() {
    const designPanel = this.designState.parent;
    if (designPanel.isPanelState()) {
      const {
        height,
        width
      } = getPanelDimensionsInMmValue(designPanel.panelProperties);
      const ratio = parseFloat(this.designState.model.data.image.position.height) * 100 / Math.min(height, width);
      return ratio / 100;
    }
    return defaultPatternToPanelDimensionRatio;
  }
  get patternRotationAngle() {
    const {
      rotationAngle
    } = this.designState.model;
    if (rotationAngle) {
      return parseFloat(rotationAngle);
    }
    return 0;
  }
  get defaultPatternPosition() {
    const designPanel = this.designState.parent;
    if (designPanel.isPanelState()) {
      return getDefaultPatternPosition(designPanel.panelProperties);
    }
    return {
      dimension: 0,
      x: 0,
      y: 0
    };
  }
  get patternXOffsetPercentage() {
    const designPanel = this.designState.parent;
    if (designPanel.isPanelState()) {
      return getPatternOffsetPercentage(designPanel.panelProperties, this.designState.model.position.x, this.patternToPanelDimensionRatio, this.defaultPatternPosition.x);
    }
    return 0;
  }
  get tileDimensions() {
    return this.designState.model.data.image.position;
  }
  get patternYOffsetPercentage() {
    const designPanel = this.designState.parent;
    if (designPanel.isPanelState()) {
      return getPatternOffsetPercentage(designPanel.panelProperties, this.designState.model.position.y, this.patternToPanelDimensionRatio, this.defaultPatternPosition.y);
    }
    return 0;
  }
  async setDimensionRatio(ratio) {
    this.dimensionRatio = ratio;
  }
  async setXOffsetRatio(ratio) {
    this.xOffsetRatio = ratio;
  }
  async setYOffsetRatio(ratio) {
    this.yOffsetRatio = ratio;
  }
  async setRotationAngle(angle) {
    this.rotationAngle = angle;
  }
  async setIsInEditingMode(isEditing) {
    this.isInEditingMode = isEditing;
  }
  dispose() {
    super.dispose();
    this.disposeImagePositionHeightReaction();
    this.disposeItemPositionXReaction();
    this.disposeItemPositionYReaction();
    this.disposeImageRotationReaction();
    this.disposeImageReplaceReaction();
  }
}
__decorateClass([observable], PatternExtension.prototype, "dimensionRatio", 2);
__decorateClass([observable], PatternExtension.prototype, "rotationAngle", 2);
__decorateClass([observable], PatternExtension.prototype, "xOffsetRatio", 2);
__decorateClass([observable], PatternExtension.prototype, "yOffsetRatio", 2);
__decorateClass([observable], PatternExtension.prototype, "isInEditingMode", 2);
__decorateClass([observable], PatternExtension.prototype, "svgData", 2);
__decorateClass([observable], PatternExtension.prototype, "fileUrl", 2);
__decorateClass([observable], PatternExtension.prototype, "isUrlSvg", 2);
__decorateClass([flow], PatternExtension.prototype, "extractSvgData", 1);
__decorateClass([flow], PatternExtension.prototype, "checkUrl", 1);
__decorateClass([computed], PatternExtension.prototype, "patternToPanelDimensionRatio", 1);
__decorateClass([computed], PatternExtension.prototype, "patternRotationAngle", 1);
__decorateClass([computed], PatternExtension.prototype, "defaultPatternPosition", 1);
__decorateClass([computed], PatternExtension.prototype, "patternXOffsetPercentage", 1);
__decorateClass([computed], PatternExtension.prototype, "tileDimensions", 1);
__decorateClass([computed], PatternExtension.prototype, "patternYOffsetPercentage", 1);
__decorateClass([action.bound], PatternExtension.prototype, "setDimensionRatio", 1);
__decorateClass([action.bound], PatternExtension.prototype, "setXOffsetRatio", 1);
__decorateClass([action.bound], PatternExtension.prototype, "setYOffsetRatio", 1);
__decorateClass([action.bound], PatternExtension.prototype, "setRotationAngle", 1);
__decorateClass([action.bound], PatternExtension.prototype, "setIsInEditingMode", 1);
export {
  PatternExtension,
  defaultPatternToPanelDimensionRatio
};

import React from "react";
import type { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { FlexibilityIcon } from "@internal/ui-studio-chrome";
import { FlexibilityDialogTypes } from "../FlexibilityDialogTypes";

const messages = defineMessages({
    productOptionsTitle: {
        id: "editorUI.addContent.drawer.productOptions.title",
        defaultMessage: "Product Options",
        description: {
            note: "Content Panel title text for changing product options"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    if (screenSize === "largeScreen") {
        return children({
            icon: <FlexibilityIcon aria-hidden="true" />,
            label: t(messages.productOptionsTitle.id)
        });
    }

    return children({
        icon: <FlexibilityIcon aria-hidden="true" />,
        label: t(messages.productOptionsTitle.id)
    });
};

export const flexibilityContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-flexibility",
    core: {
        Flexibility: {
            activeDialogType: FlexibilityDialogTypes.flexibility,
            component: CDZButton
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import React, { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { SwanHead, SWAN_STYLE_KEY_MAP, getRootClassNames, useBrowserClasses } from "@vp/swan";
import { initCA } from "@vp/chat-anywhere";
import { studioTitle, designHelpTenant } from "@internal/dex";

const styleSheetKeys = [
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.alertBox,
    // premium finish toggle from easel requires this
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.modalDialog,
    // this is used by the consent manager
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.pagination,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.spinner
];

interface Props {
    children: ReactNode;
    locale: string;
    UILibraryDomain?: string;
}

export function HeadCollection({ children, locale, UILibraryDomain = "https://www.vistaprint.ie" }: Props) {
    const bodyClassName = getRootClassNames();
    const htmlClassName = useBrowserClasses();

    const language = locale.split("-")[0];

    // This hook is added to to make sure the zooming gesture doesn't interfere with Safari accessibility zoom.
    // See https://use-gesture.netlify.app/docs/gestures/#about-the-pinch-gesture
    useEffect(() => {
        const preventGesture = (e: Event) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventGesture);
        document.addEventListener("gesturechange", preventGesture);
        return () => {
            document.removeEventListener("gesturestart", preventGesture);
            document.removeEventListener("gesturechange", preventGesture);
        };
    }, []);

    useEffect(() => {
        if (designHelpTenant) {
            initCA(designHelpTenant);
        }
    }, []);

    return (
        <>
            <SwanHead styleSheetKeys={styleSheetKeys} renderWith={Helmet} renderStyleContentAsChildren />
            <Helmet htmlAttributes={{ class: htmlClassName }}>
                <html lang={language || "en"} />
                <title>{studioTitle}</title>

                <meta name="viewport" content="width=device-width maximum-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="theme-color" content="#006196" />
                <link rel="preconnect" href="https://rendering.documents.cimpress.io" />
                <link rel="shortcut icon" href={`${UILibraryDomain}/ui-library/images/favicon/favicon.ico`} />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-76-76-2014-2x.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-60-60-2014-2x.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-76-76-2014.png`}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href={`${UILibraryDomain}/ui-library/images/favicon/vistaprint-favorites-60-60-2014.png`}
                />
            </Helmet>
            {/* SWAN suggests putting bodyClassName on <body />, However the i18n-helper lib does not play well with this */}
            <div className={bodyClassName}>{children}</div>
        </>
    );
}

HeadCollection.displayName = "HeadCollection";

import { addPrototypeSimItem } from "../addPrototypeSimItem.js";
const afterDuplicatePanelCallback = () => (cimDoc, panelId) => {
  if (cimDoc.document.panels.find((panel) => panel.id === panelId)) {
    const newDoc = /* @__PURE__ */ structuredClone(cimDoc);
    const newItem = /* @__PURE__ */ addPrototypeSimItem(newDoc, {
      content: "prototype sim, after duplicate panel",
      panelId
    });
    return {
      doc: newDoc,
      transformations: [{
        id: newItem.id,
        type: "new-field"
      }]
    };
  }
  return void 0;
};
export {
  afterDuplicatePanelCallback
};

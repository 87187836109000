import type { Item } from "@design-stack-vista/cdif-types/v2";
import { Measurement } from "@design-stack-vista/utility-core";
import { isBackgroundItem, isLineItem, ItemState, SubpanelState } from "@design-stack-vista/cimdoc-state-manager";

// This is copied from Studio 5 and modified to work in Studio 6
// What percent of an item is of total canvas surface area counts as blocking
const BLOCKING_THRESHOLD = 0.95;
const BACKGROUND_Z_INDEX = -1001;
const NO_COLOR_RGBA = "rgba(0, 0, 0, 0)";
const NO_COLOR_VALUE = "cmyka(0,0,0,0,0)";

// TODO: This helper is only used by the BackgroundColorExtension and the PatternItemExtension. Once they are merged within a single SIM, this should probably be relocated there as well
export const checkIfItemsBlockBackgroundOrPattern = (
    items: (ItemState<Item> | SubpanelState)[],
    canvasWidth: number,
    canvasHeight: number
) => {
    let itemBlockingBackgroundPattern = false;
    let i = 0;
    while (!itemBlockingBackgroundPattern && i < items.length) {
        const item = items[i];
        // Only check images and non-transparent shapes
        const shouldCheckItem =
            (item.isImageItem() && !isBackgroundItem(item.asJson)) ||
            (item.isShapeItem() &&
                !isLineItem(item.model) &&
                item.model.color !== NO_COLOR_VALUE &&
                item.model.color !== NO_COLOR_RGBA &&
                item.model.zIndex !== BACKGROUND_Z_INDEX);

        if (shouldCheckItem) {
            if ("position" in item.model) {
                const { width, height, x, y } = item.model.position;
                const itemWidth = new Measurement(width).mm;
                const itemHeight = new Measurement(height).mm;
                const itemX = new Measurement(x).mm;
                const itemY = new Measurement(y).mm;

                const itemWidthOnCanvas = Math.min(itemWidth + itemX, canvasWidth) - Math.max(itemX, 0);
                const itemHeightOnCanvas = Math.min(itemHeight + itemY, canvasHeight) - Math.max(itemY, 0);
                const itemSurfaceArea = itemWidthOnCanvas * itemHeightOnCanvas;
                const canvasSurfaceArea = canvasWidth * canvasHeight;
                itemBlockingBackgroundPattern = itemSurfaceArea / canvasSurfaceArea > BLOCKING_THRESHOLD;
            }
        }
        i += 1;
    }
    return itemBlockingBackgroundPattern;
};

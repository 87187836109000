import { createContext, useContext } from "react";
import { VCSStudioConfigurationManager } from "./VCSStudioConfigurationManager";
import type { UseStudioConfigurationManager } from "@internal/utils-studio-configuration-core";

const Context = createContext<VCSStudioConfigurationManager>(new VCSStudioConfigurationManager());

/**
 * This hook provides a reference to the instance of the active
 * Studio Configuration Manager.
 */
export const useStudioConfigurationManager: UseStudioConfigurationManager = () => useContext(Context);

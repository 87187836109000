import { DecorationTechnology } from "./decorationTechnologyConfiguration";
import { DecorationTechnologies } from "./interface";

export const cmykPrint: DecorationTechnology = {
    configuration: {
        allowBackgroundColor: true,
        allowPDFProofDownload: true,
        allowTransparency: true,
        // commitColorChange: undefined,
        enableFontBrowser: true,
        allowVectors: true
    },
    isActive: panel =>
        [
            DecorationTechnologies.Offset,
            DecorationTechnologies.Digital,
            DecorationTechnologies.OffsetOrDigital,
            DecorationTechnologies.DirectToGarment,
            DecorationTechnologies.HeatTransfer,
            DecorationTechnologies.InkJet,
            DecorationTechnologies.Sublimation
        ].includes(panel?.panelProperties?.decorationTechnology as DecorationTechnologies)
};

import React, { PropsWithChildren } from "react";
import { FlexBox, Icon, Typography } from "@vp/swan";
import { useStudioLayout } from "@internal/feature-responsive-design";

export interface GuidelineModalSectionProps {
    title: string;
    text: string;
}

export const GuidelineModalSection = (props: PropsWithChildren<GuidelineModalSectionProps>) => {
    const { title, text, children } = props;

    const { isSmall } = useStudioLayout();

    return (
        <FlexBox flexDirection="column" gap={"3"}>
            <FlexBox alignItems="center" gap={"3"}>
                <Icon iconType="check" skin="accent" size="24p" />
                <Typography fontSkin="title-subsection">{title}</Typography>
            </FlexBox>
            <Typography textColor="subtle" fontSkin="body-standard">
                {text}
            </Typography>
            <FlexBox gap={isSmall ? "4" : "5"}>{children}</FlexBox>
        </FlexBox>
    );
};

GuidelineModalSection.displayName = "GuidelineModalSection";

import { ShapeTypes } from "@cimpress-technology/svg-path";
import type { ShapeItem } from "@design-stack-vista/cdif-types";
import type { DSS } from "@vp/types-ddif";

export const isHorizontalArrow = (shape: ShapeItem, document: DSS.DesignDocument) => {
    const studioMetadata = document?.metadata?.studioMetadata;

    if (!studioMetadata || !shape) {
        return false;
    }

    const { rotationAngle } = shape;
    const metadata = studioMetadata[shape.metadata?.originalId || shape.id];

    if (!metadata) {
        return false;
    }

    return (
        // A rotation angle of undefined is considered to be 0.
        // cdif-types defines rotation as a string but it can be a number
        // @ts-ignore FIXME: must handle implicit `any` type
        // eslint-disable-next-line dot-notation
        metadata["shapeMetadata"]?.type === ShapeTypes.Arrow &&
        (!rotationAngle || (rotationAngle as unknown as number) === 180 || rotationAngle === "180")
    );
};

import type { DSS, DTR } from "@vp/types-ddif";
import { produce } from "immer";

export const applyPlaceholderTextToDocument = (cimDoc: DSS.DesignDocument, defaultPlaceholderText: string = "") => {
    return produce(cimDoc, draft => {
        draft.document.panels.forEach(panel => {
            if (panel.itemReferences) {
                panel.itemReferences.forEach(reference => {
                    if (reference.data && !reference.data.content) {
                        const metadata = (cimDoc.metadata?.template ?? []).find(
                            templateItem => templateItem.id === reference.id
                        );
                        if (metadata) {
                            reference.data.content = metadata.placeholder || defaultPlaceholderText;
                        }
                    }
                });
            }
            if (panel.textAreas) {
                panel.textAreas.forEach(textArea => {
                    if (textArea?.content?.[0] && !textArea.content[0].content) {
                        const metadata = (cimDoc.metadata?.template ?? []).find(
                            templateItem => templateItem.id === textArea.id
                        );
                        if (metadata) {
                            textArea.content[0].content = (metadata.placeholder as string) || defaultPlaceholderText;
                        }
                    }
                });
            }
        });
    });
};

interface Item {
    id: string;
}

export function loopOverItems<T extends Item>(
    document: DSS.DesignDocument,
    items: T[] | undefined,
    designAttributeValue: string,
    callback: (item: T, metadata: DTR.TemplateMetadata, designAttributeValue: string) => void
) {
    return items?.forEach(item => {
        const { id } = item;
        const itemMetadata = document.metadata?.template?.find((template: DTR.TemplateMetadata) => template.id === id);
        if (itemMetadata) {
            callback(item, itemMetadata, designAttributeValue);
        }
    });
}

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

type ContextData = {
    isEmptyTextABEnabled: boolean;
    trackImpressionForEmptyText: () => void;
};

const disabledProductCategories = ["Packaging"];

const context = createContext<ContextData | undefined>(undefined);

export function useEmptyText() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a EmptyTextABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const EmptyTextExperimentData: ExperimentData = {
    experimentKey: "empty_placeholder_on_canvas",
    experimentName: "Empty Text Placeholder On Canvas",
    variations: Variations
};

export const EmptyTextABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = EmptyTextExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);
    const { productCategory } = useProductAndProjectStateManager().data;

    useEffect(() => {
        if (!experimentKey || !ABTest) {
            return;
        }
        const { isExperimentUsingVariation } = ABTest;

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Test);
            // Packaging products shouldn't be in the test
            const enabled = res && !disabledProductCategories.includes(productCategory);
            setIsEnabled(!!enabled);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest, productCategory]);

    const trackImpressionForEmptyText = useCallback(() => {
        if (!experimentKey || !ABTest || isTrackingComplete) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);

            if (res && !disabledProductCategories.includes(productCategory)) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            } else if (variation === Variations.Test) {
                setIsEnabled(false);
            }
        };

        trackImpressionIfInVariant(Variations.Test);
        trackImpressionIfInVariant(Variations.Control);
        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete, productCategory]);

    const contextObject = useMemo(
        () => ({
            isEmptyTextABEnabled: isEnabled,
            trackImpressionForEmptyText
        }),
        [isEnabled, trackImpressionForEmptyText]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

EmptyTextABProvider.displayName = "EmptyTextABProvider";

import { jsx } from "react/jsx-runtime";
const RectangleCutlineIcon = (props) => /* @__PURE__ */ jsx("svg", {
  viewBox: "0 0 30 30",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ jsx("path", {
    d: "M.75.75h28.5v28.5H.75V.75Z",
    stroke: "black",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })
});
export {
  RectangleCutlineIcon
};

import React from "react";
import classnames from "classnames";
import styles from "./ErrorDetail.module.scss";

export interface ErrorDetailProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Name of the error detail
     */
    name: string;
    /**
     * Value for this error detail
     */
    value: string;
}

export const ErrorDetail = ({ name, value, className, ...rest }: ErrorDetailProps) => {
    return (
        <div className={classnames(className, styles.errorDetail)} {...rest}>
            <strong>{`${name}:`}</strong>
            {` ${value}`}
        </div>
    );
};

ErrorDetail.displayName = "ErrorDetail";

import React, { useContext, createContext, ReactNode, useMemo } from "react";

interface DesignToolTrackingEventInterface {
    eventDetail: string;
    label?: string;
    pageNameBase?: string;
    extraData?: () => Record<string, unknown> | undefined;
}

interface Props {
    children: ReactNode | ReactNode[];
    fireEventTracker: (designToolTrackingEvent: DesignToolTrackingEventInterface) => void;
}

const eventContext = createContext({
    trackEvent: (designToolTrackingEvent: DesignToolTrackingEventInterface) => {} // noop
});

/**
 * @deprecated Use the `TrackingClientProvider` and `useTrackingClient` hook instead with the `DesignToolUsed` event.
 */
export function useTrackEvents() {
    return useContext(eventContext);
}

/**
 * @deprecated Use the `TrackingClientProvider` instead.
 */
export function TrackEventsProvider({ children, fireEventTracker }: Props) {
    const { Provider } = eventContext;

    const contextObject = useMemo(
        () => ({
            trackEvent: fireEventTracker
        }),
        [fireEventTracker]
    );

    return <Provider value={contextObject}>{children}</Provider>;
}

TrackEventsProvider.displayName = "TrackEventsProvider";

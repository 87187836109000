import React from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { LocalizedProvider, useLocalizedValues } from "../localized";
import { ToastHelpCenterUrlProvider } from "./ToastHelpCenterUrlProvider";

interface ToastProviderProps extends React.PropsWithChildren {
    helpCenterUrl?: string;
}

export function ToastProvider({ helpCenterUrl, children }: Readonly<ToastProviderProps>) {
    const localizedValues = useLocalizedValues();
    const { locale } = useProductAndProjectStateManager().data;
    return (
        <LocalizedProvider localizedValues={localizedValues} locale={locale}>
            <ToastHelpCenterUrlProvider helpCenterUrl={helpCenterUrl}>{children}</ToastHelpCenterUrlProvider>
        </LocalizedProvider>
    );
}

import { AssetErrorMessage, AssetUrlParseError } from "@design-stack-ct/assets-sdk";
import type { VistaAsset, VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import { waitForImageToBePrepared } from "@internal/utils-assets";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getHash } from "./getHash";

type getWebPreviewType = {
    originalUrl: string;
    assetStore: VistaAssetStore;
    uploadFile: any;
};

type AssetCache = Record<string, VistaAsset | undefined>;

const memoizedAsset: AssetCache = {};

function isAssetNotFoundOrUrlInvalid(error: Error) {
    // This is a Hack skip incorrect Error description.
    // It has been raised https://vistaprint.slack.com/archives/C03UX2G8HK9/p1717660878094549?thread_ts=1717408644.907939&cid=C03UX2G8HK9
    // On fix, this will be removed
    return (
        error.message.includes(AssetErrorMessage.AssetNotFound) || error.message.includes(AssetUrlParseError.InvalidUrl)
    );
}

function isAssetPresignFailed(error: Error) {
    return error.message.includes(AssetErrorMessage.PresignFailed);
}

/**
 * @description This function returns the asset of the provided url. It also caches the result to prevent future n/w calls.
 * If the asset is not found in Sherbert it's uploaded to it and the asset is fetched
 * @param {{originalUrl, assetStore}}
 * @returns
 */
export async function getOriginalImageAsset({ assetStore, originalUrl, uploadFile }: getWebPreviewType) {
    try {
        const hash = getHash(originalUrl);
        const webPreviewFromCache = memoizedAsset[hash];
        if (webPreviewFromCache) {
            return webPreviewFromCache;
        }

        const uploadFileToSherbertAsHiddenAsset = async () => {
            const uploadedAsset = await uploadFile(originalUrl, {
                hidden: true
            });
            await uploadedAsset.prepare();
            return uploadedAsset;
        };

        const asset: VistaAsset = await assetStore
            .fetchSingleAsset({ url: originalUrl })
            .catch(error => {
                if (isAssetNotFoundOrUrlInvalid(error)) {
                    return (async () => {
                        return uploadFileToSherbertAsHiddenAsset();
                    })();
                }
                return undefined;
            })
            .then(async singleAsset => {
                if (singleAsset) {
                    await waitForImageToBePrepared(singleAsset);
                    return singleAsset;
                }
                return undefined;
            })
            .catch((error: Error) => {
                /**
                 * Note; this is an hack to fix the presign issue.
                 * An open discussion is going on in here - https://gitlab.com/Cimpress-Technology/doc-ext/customer-resources-manager/-/issues/8
                 * Cause for doing it this way; We are uploading assets as hidden, when the ownership of asset transfer occurs from guest/anonymous to user, the presign fails for hidden assets.
                 */
                if (isAssetPresignFailed(error)) {
                    return (async () => {
                        const presignableAsset = await uploadFileToSherbertAsHiddenAsset();
                        if (presignableAsset) {
                            await waitForImageToBePrepared(presignableAsset);
                            return presignableAsset;
                        }
                        return undefined;
                    })();
                }
                return;
            });
        if (asset && asset.isPresigned) {
            memoizedAsset[hash] = asset;
            return asset;
        }
        return undefined;
    } catch (error) {
        newRelicWrapper.noticeError(new Error("Unable to fetch requested asset"), {
            message: (error as Error).message
        });
    }
    return undefined;
}

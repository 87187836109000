import React from "react";
import ReactDOM from "react-dom";

import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import { Toaster } from "./Toaster";
import styles from "./ToastContainer.module.scss";

export function ToastContainer() {
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;

    let portalElement;
    if (typeof document !== "undefined") {
        portalElement = document.getElementsByClassName(`swan-site-content`)?.[0];
    }

    return isFlexibilityFeatureEnabled && portalElement ? (
        <>{ReactDOM.createPortal(<Toaster className={styles.flexibilityEnabled} />, portalElement)}</>
    ) : (
        <Toaster />
    );
}

ToastContainer.displayName = "ToastContainer";

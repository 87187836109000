import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { Box, FlexBox, Range, Typography } from "@vp/swan";
import once from "lodash/once";
import React, { ChangeEvent, useCallback, useRef, type JSX } from "react";
import { STUDIO_TRACKING_EVENTS } from "../../TrackingEventConstants";
import { useSingleColorContrast } from "../../hooks";
import { useLocalized } from "../../localized";

export const AdjustContrastRange = (): JSX.Element => {
    const localized = useLocalized();
    const { threshold, handleThresholdUpdate } = useSingleColorContrast();
    const trackingClient = useTrackingClient();

    const inputRangeId = localized.get("contrastSliderLabel").toLowerCase().replaceAll(" ", "-");

    /**
     * A ref that tracks the usage of the contrast slider once per session.
     * This function is called once when the contrast slider is used to update the single color threshold.
     */
    const sliderTrackingOnceRef = useRef(
        once(() => {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.UPDATE_SINGLE_COLOR_THRESHOLD_SLIDER
            });
        })
    );

    const onRangeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            sliderTrackingOnceRef.current();
            handleThresholdUpdate(Number(event.target.value));
        },
        [handleThresholdUpdate]
    );

    return (
        <>
            <DesktopExperience>
                <FlexBox fontSize="xsmall" fontWeight="bold" alignItems={"center"} mx={"5"} mt={"5"} mb={"4"} gap={"4"}>
                    <Typography noWrap>
                        <label htmlFor={inputRangeId}>{localized.get("contrastSliderLabel")}</label>
                    </Typography>
                    <Range
                        id={inputRangeId}
                        min={1}
                        max={100}
                        value={threshold}
                        onChange={onRangeChange}
                        aria-label={localized.get("contrastSliderLabel")}
                    />
                </FlexBox>
            </DesktopExperience>
            <MobileExperience>
                <Box mb={"4"} mx={"5"}>
                    <Typography noWrap>
                        <label htmlFor={inputRangeId} className="swan-visually-hidden">
                            {localized.get("contrastSliderLabel")}
                        </label>
                    </Typography>
                    <Range
                        id={inputRangeId}
                        min={1}
                        max={100}
                        value={threshold}
                        onChange={onRangeChange}
                        aria-label={localized.get("contrastSliderLabel")}
                    />
                </Box>
            </MobileExperience>
        </>
    );
};

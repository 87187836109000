import React, { useContext, useState, createContext, useMemo, useCallback, useEffect, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { useTrackEvents } from "@shared/features/Tracking";
import { useAppSelector, useAppDispatch, setDesignDialogsSeen } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { GetDocument } from "@internal/utils-cimdoc";
import type { DesignTileCallback, LoadNewDesignForApplyOption, TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { COLOR_MODE, useGetCanvasUpsellContext, useSurfaceUpsellData } from "@internal/utils-surface-upsell";
import { Toast, ToastSkin, useToastManager } from "@internal/feature-toasts";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { formatPrice, Sign } from "@internal/data-access-pricing";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { BLANK_SELECTED_TEMPLATE } from "@internal/data-access-template";
import { DesignSelectionCallbackProvider } from "./DesignSelectionCallbackProvider";
import { generateDesignTileFireInteractionTimedEvent } from "./utils";

export const isBlankDesign = (design: string) => design.toLowerCase() === COLOR_MODE.BLANK;

export enum DesignPanelType {
    None, // No panel
    Add, // Opened from the "Add Button" on the canvas selector
    CheckoutAdd, // Opened from the "Next Button" on the UI.
    Change, // Opened from the "Change" button (pencil on mobile) on the canvas selector.
    ChangeAutoShow, // Opened when canvas becomes active for the first time, is full-color, and has no items
    ChangeAutoShowFromNext // Opened when canvas becomes active (due to next button) for the first time, is full-color, and has no items
}

export enum CanvasUpdateStatus {
    UPDATED,
    RESET
}

export type DialogCanvasData = {
    title: string;
    name: string;
    src: string;
};

type ChangeCanvasParams = {
    canvasName?: string;
    selectedOption: string;
    callback: DesignTileCallback;
    reset?: boolean;
    fireInteractionTimedEvent?: () => void;
};

type Data = {
    dialogSelectionOption: string;
    canvasOptions: any;
    changeCanvas: (params: ChangeCanvasParams) => void;
    // What type of design panel are we displaying
    designPanelType: DesignPanelType;
    setDesignPanelType: (designPanelType: DesignPanelType) => void;
    // What is the name of the current panel we are showing information for
    dialogCanvasName: string;
    dialogCanvasTitle: string;
    setDialogCanvas: (canvas: DialogCanvasData | undefined) => void;
    getHasDialogBeenSeen: (panelName: string) => boolean;
    isCanvasBlank: boolean;
    isAddDialog: () => boolean;
    canvasUpdateStatus: CanvasUpdateStatus;
    setCanvasUpdateStatus: (status: CanvasUpdateStatus) => void;
    getDocumentWithNonServerRenderableItems: GetDocument;
    getDocument: GetDocument;
    loadNewDesign: LoadNewDesignForApplyOption;
    fireInteractionTimedEvent?: () => void;
};

interface DisplayPricingToastsConfig {
    canvasName: string;
    newDocumentSourceType: string;
    oldDocumentSourceType: string;
}

const surfaceUpsellMessage = defineMessages({
    upsellMessageForBackSide: {
        id: "studio.components.Toast.upsellMessageForBackSide",
        defaultMessage: "Back design has been added for [[pricing]]",
        description: "This message is displayed when a backside design is added"
    },
    upsellMessageForBlankBackSide: {
        id: "studio.components.Toast.upsellMessageForBlankBackSide",
        defaultMessage: "Back design has been removed for [[pricing]]",
        description: "This message is displayed when a backside design is removed"
    },
    upsellMessageForDefault: {
        id: "studio.components.Toast.upsellMessageForDefault",
        defaultMessage: "Design has been added for [[pricing]]",
        description: "This message is displayed when a new side design is added"
    },
    upsellMessageForBlankDefault: {
        id: "studio.components.Toast.upsellMessageForBlankDefault",
        defaultMessage: "Design has been removed for [[pricing]]",
        description: "This message is displayed when a new side design is removed"
    }
});

const context = createContext<Data | undefined>(undefined);

export function useDesignDialog() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context.  This must be called within a DesignDialogProvider");
    }
    return result;
}

interface Props {
    activeCanvasName?: string;
    triggerCanvasChange: TriggerCanvasChange;
    children: ReactNode | ReactNode[];
    getDocumentWithNonServerRenderableItems: GetDocument;
    getDocument: GetDocument;
    loadNewDesign: LoadNewDesignForApplyOption;
}

export const DesignDialogProvider = observer(
    ({
        children,
        activeCanvasName,
        triggerCanvasChange,
        getDocumentWithNonServerRenderableItems,
        getDocument,
        loadNewDesign
    }: Props) => {
        const [canvasOptions, setCanvasOptions] = useState<any>({});
        const [hasBeenSeen, setHasBeenSeen] = useState<any>({});
        const [canvasUpdateStatus, setCanvasUpdateStatus] = useState<CanvasUpdateStatus>(CanvasUpdateStatus.RESET);

        // What is the name of the canvas the dialog is going to show information for
        const [dialogCanvas, setDialogCanvas] = useState<DialogCanvasData | undefined>(undefined);

        // What type of design panel are we showing?
        const [designPanelType, setDesignPanelType] = useState<DesignPanelType>(DesignPanelType.None);
        const switchingProject = useAppSelector(state => state.switchingProject);
        const designRequirements = useDesignRequirementsContext();
        const activeCanvasColorMode = activeCanvasName
            ? designRequirements?.getPanelByName(activeCanvasName)?.colorMode
            : "";

        const dispatch = useAppDispatch();
        const { trackEvent } = useTrackEvents();
        const getUpsellsByCanvasName = useGetCanvasUpsellContext();
        const changeDesignData = useAppSelector(state => state.changeDesignData);
        const { handleError } = useErrors();
        const { t } = useTranslationSSR();
        const toastManager = useToastManager();
        const { locale } = useProductAndProjectStateManager().data;
        const { currency } = useSurfaceUpsellData();

        const { Provider } = context;
        const dialogCanvasName = dialogCanvas?.name || "";
        const dialogCanvasTitle = dialogCanvas?.title || "";
        const dialogCanvasColorType = dialogCanvasName
            ? designRequirements?.getPanelByName(dialogCanvasName)?.colorMode
            : "";

        const fireInteractionTimedEvent = useCallback(() => {
            if (!changeDesignData) {
                return undefined;
            }

            const { selectedOption } = changeDesignData;
            return generateDesignTileFireInteractionTimedEvent(selectedOption, dialogCanvasName);
        }, [dialogCanvasName, changeDesignData]);

        const isCanvasBlank = useMemo(() => {
            if (switchingProject) return true;
            return !!(activeCanvasColorMode && isBlankDesign(activeCanvasColorMode));
        }, [activeCanvasColorMode, switchingProject]);

        const displayPricingToasts = useCallback(
            (config: DisplayPricingToastsConfig) => {
                const { canvasName: panelName, newDocumentSourceType, oldDocumentSourceType: oldSourceType } = config;
                const discountPrice = getUpsellsByCanvasName(panelName)?.discountPrice;

                if (discountPrice) {
                    const makeAlert = (key: string, sign: string, skin: ToastSkin): Toast => ({
                        skin,
                        autoClose: true,
                        content: t(key, {
                            pricing: `${sign}${formatPrice(discountPrice, locale, currency)}`
                        })
                    });

                    toastManager.resetToasts();
                    const isBackSide = panelName === "Back";
                    // When the state transitions from a blank design to a template, custom or a fullbleed
                    if (
                        newDocumentSourceType !== BLANK_SELECTED_TEMPLATE &&
                        (oldSourceType === BLANK_SELECTED_TEMPLATE || !oldSourceType)
                    ) {
                        toastManager.pushToast(
                            makeAlert(
                                isBackSide
                                    ? surfaceUpsellMessage.upsellMessageForBackSide.id
                                    : surfaceUpsellMessage.upsellMessageForDefault.id,
                                Sign.POSITIVE,
                                "positive"
                            )
                        );
                    }
                    // When the state transitions from a template, custom or a fullbleed to blank design
                    if (
                        newDocumentSourceType === BLANK_SELECTED_TEMPLATE &&
                        oldSourceType &&
                        oldSourceType !== BLANK_SELECTED_TEMPLATE
                    ) {
                        toastManager.pushToast(
                            makeAlert(
                                isBackSide
                                    ? surfaceUpsellMessage.upsellMessageForBlankBackSide.id
                                    : surfaceUpsellMessage.upsellMessageForBlankDefault.id,
                                Sign.NEGATIVE,
                                "positive"
                            )
                        );
                    }
                }
            },
            [currency, getUpsellsByCanvasName, locale, t, toastManager]
        );

        const changeCanvas = useCallback(
            ({
                canvasName = dialogCanvasName,
                selectedOption,
                callback,
                reset = false,
                fireInteractionTimedEvent
            }: ChangeCanvasParams) => {
                // even if we return because nothing changed, we still want to hide the design panel
                setDialogCanvas(undefined);
                setDesignPanelType(DesignPanelType.None);

                if (!reset && selectedOption === canvasOptions[canvasName]) {
                    return;
                }
                trackEvent({
                    eventDetail: `${
                        reset ? STUDIO_TRACKING_EVENTS.CLICK_RESET_TEMPLATE : STUDIO_TRACKING_EVENTS.CLICK_TEMPLATE
                    }${selectedOption}`,
                    label: "Template"
                });

                try {
                    dispatch(
                        callback({
                            panelName: canvasName,
                            resetting: reset,
                            fireInteractionTimedEvent,
                            getDocument: getDocumentWithNonServerRenderableItems,
                            loadNewDesign
                        })
                    );
                    // this is a bit of a hack, but previously upsell pricing was bugged
                    // when switching canvases we hadn't actually loaded the pricing for the new canvas, that only happened when we had the design panel open
                    // so we would never show a pricing toast when switching canvases (which is accurate anyway)
                    // Always hide pricing for grayscale - pricing isn't correct for grayscale
                    //  and grayscale is not supported by Studio5
                    if (dialogCanvas?.name === canvasName && dialogCanvasColorType !== COLOR_MODE.GRAYSCALE) {
                        displayPricingToasts({
                            canvasName,
                            newDocumentSourceType: selectedOption,
                            oldDocumentSourceType: canvasOptions[canvasName]
                        });
                    }
                    setCanvasOptions({ ...canvasOptions, [canvasName]: selectedOption });
                    triggerCanvasChange(canvasName);
                } catch (e) {
                    handleError(e, ERROR_CODES.SURFACE_UPSELL_CHANGE, ENTITY_CODE);
                }
            },
            [
                dialogCanvasName,
                canvasOptions,
                trackEvent,
                dispatch,
                getDocumentWithNonServerRenderableItems,
                loadNewDesign,
                dialogCanvas?.name,
                dialogCanvasColorType,
                triggerCanvasChange,
                displayPricingToasts,
                handleError
            ]
        );

        const dialogSelectionOption = canvasOptions[dialogCanvasName];

        useEffect(() => {
            if (!dialogCanvasName) {
                return;
            }
            setHasBeenSeen((current: any) => ({
                ...current,
                [dialogCanvasName]: true
            }));
        }, [dialogCanvasName]);

        useEffect(() => {
            dispatch(setDesignDialogsSeen(hasBeenSeen));
        }, [dispatch, hasBeenSeen]);

        // returns whether the dialog has been seen in the current studio session
        const getHasDialogBeenSeen = useCallback(
            (panelName: string) => {
                return hasBeenSeen[panelName] || false;
            },
            [hasBeenSeen]
        );

        const isAddDialog = useCallback(() => {
            return designPanelType === DesignPanelType.Add || designPanelType === DesignPanelType.CheckoutAdd;
        }, [designPanelType]);

        const contextObject = useMemo(
            () => ({
                dialogSelectionOption,
                canvasOptions,
                designPanelType,
                setDesignPanelType,
                dialogCanvasName,
                dialogCanvasTitle,
                setDialogCanvas,
                changeCanvas,
                getHasDialogBeenSeen,
                isCanvasBlank,
                isAddDialog,
                canvasUpdateStatus,
                setCanvasUpdateStatus,
                getDocumentWithNonServerRenderableItems,
                getDocument,
                loadNewDesign,
                fireInteractionTimedEvent
            }),
            [
                dialogSelectionOption,
                canvasOptions,
                designPanelType,
                setDesignPanelType,
                dialogCanvasName,
                dialogCanvasTitle,
                setDialogCanvas,
                changeCanvas,
                getHasDialogBeenSeen,
                isCanvasBlank,
                isAddDialog,
                canvasUpdateStatus,
                setCanvasUpdateStatus,
                getDocumentWithNonServerRenderableItems,
                getDocument,
                loadNewDesign,
                fireInteractionTimedEvent
            ]
        );

        return (
            <Provider value={contextObject}>
                <DesignSelectionCallbackProvider>{children}</DesignSelectionCallbackProvider>
            </Provider>
        );
    }
);
DesignDialogProvider.displayName = "DesignDialogProvider";

import { tryFetch, REQUESTOR } from "@internal/utils-network";

const entityCode = 25;
const MSX_URL = "https://merchandising-site-experience.prod.merch.vpsvc.com/api/v1";
export const MERCHANDISING_TENANT = "vistaprint";

export function getMPVId(merchandisingMpvUrl: string, mpvId?: string): string | undefined {
    if (merchandisingMpvUrl && !mpvId) {
        // parse merchandisingMpvUrl if no mpvId provided
        const ind = merchandisingMpvUrl.lastIndexOf("/");
        // Check if url contains query to truncate
        if (merchandisingMpvUrl.includes("?")) {
            const queryInd = merchandisingMpvUrl.indexOf("?");
            return merchandisingMpvUrl.substring(ind + 1, queryInd);
        }
        return merchandisingMpvUrl.substring(ind + 1);
    }
    return mpvId;
}

export async function getProductName(productKey: string, locale: string): Promise<string> {
    const response = await tryFetch({
        url:
            `${MSX_URL}/tenant/${MERCHANDISING_TENANT}/productKey/${productKey}/merchandisedProperty/productName` +
            `?requestor=${REQUESTOR}&cultures=${locale.toLowerCase()}&lower=true`,
        moduleFunction: "merchandisingProductClient:getProductName",
        friendlyDescription: "retrieve name for product",
        entityCode
    });

    return response && response[locale.toLowerCase()] && response[locale.toLowerCase()].productName;
}

export async function getProductKey(mpvId: string, locale: string): Promise<string> {
    const response = await tryFetch({
        url: `${MSX_URL}/tenant/${MERCHANDISING_TENANT}/mpvs/${mpvId}?requestor=${REQUESTOR}&cultures=${locale.toLowerCase()}`,
        moduleFunction: "merchandisingProductClient:getProductKey",
        friendlyDescription: "retrieve product key from MPV",
        entityCode
    });

    return response && response[locale.toLowerCase()];
}

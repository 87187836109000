import { isStickerDexDocument, convertFromCutlineDocument } from "@internal/feature-cutline-generation";
import { ERROR_CODES } from "@internal/utils-errors";
import type { DSS } from "@vp/types-ddif";
import type { ErrorHandler, StudioError } from "@internal/utils-errors";
import { ENTITY_CODE } from "../plpEntityCode";

/**
 * Updates for the custom cutline (custom sticker) experience.  This should only be run when the custom cutline experience is active as it destructively modifies documents
 * to function in that experience
 * @param cimdoc
 * @param handleError
 * @returns
 */
export function updatesForCustomCutlineDocuments(cimdoc: DSS.DesignDocument, handleError: ErrorHandler) {
    // To support documents created in the Sticker DEX, we need to convert these.
    try {
        if (isStickerDexDocument(cimdoc)) {
            cimdoc = convertFromCutlineDocument(cimdoc);
        }
    } catch (err) {
        // I'm not quite sure what this error is supposed to be but this provides the same functionality as before
        handleError(err as StudioError, ERROR_CODES.LOAD_PROJECT, ENTITY_CODE, true);
    }

    // custom cutline fails with image placeholders so we'll remove them if they exist
    // remove any that might be stored in metadata
    if (cimdoc.metadata?.placeholders) {
        delete cimdoc.metadata?.placeholders;
    }
    // also remove any that may be on the design, say from the full-bleed flow
    // we know in this flow there should only be one image, the placeholder
    if (cimdoc.document.panels.length > 0 && cimdoc.document.panels[0].images?.length === 1) {
        const imageId = cimdoc.document.panels[0].images[0].id;
        const isPlaceholder = cimdoc.metadata?.template?.find(t => t.placeholder === true && t.id === imageId);
        if (isPlaceholder) {
            cimdoc.document.panels[0].images = [];
        }
    }

    return cimdoc;
}

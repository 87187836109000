import { makeObservable, computed } from "mobx";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import type { DesignState } from "@design-stack-vista/cimdoc-state-manager";
import { SimInterface } from "../ComposedSim";

/**
 * This class should be extended in order to preload the `deferred` aspects of
 * a sim based on the items in a `Panel`.
 *
 * An example use case is: I have a shape sim and the we have deferred
 * loading certain aspects of the sim such as contextual edit tools. If I load
 * a document or switch panels to a panel that has a shape, Studio should
 * fetch the deferred aspects of the sim so that everything is loaded and ready.
 */
export class ItemPreloaderExtension extends BaseExtension {
    /**
     * This should evaluate the items on the panel and return `true`
     * if any of the items require preloading the deferred
     * aspects of the sim. e.g., in a Shapes specific sim may write
     *
     * ```ts
     * @override
     * get shouldPreload() {
     *   return this.designState.items.some(item => item.isShapeItem());
     * }
     * ```
     *
     */
    @computed
    get shouldPreload(): boolean {
        return false;
    }

    /**
     * Used to tell Studio the name of the sim so it knows
     * which to sims to load.
     */
    get simName(): SimInterface["name"] {
        return "@internal/advanced-editor-sim-UNIMPLEMENTED";
    }

    /**
     * Do not override this
     * @param state
     * @returns
     */
    static supports(state: DesignState) {
        return state.isPanelState();
    }

    constructor(designState: DesignState) {
        super(designState);
        makeObservable(this);
    }
}

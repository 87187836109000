const apiConfig = {
  qrCodeGenerator: {
    baseUrl: DYNAMIC_QR_CODE_URL,
    itemRefEndPoint: "/api/qrcode/itemref"
  },
  qrCodeAsset: {
    baseUrl: DYNAMIC_QR_CODE_ASSETS_URL
  }
};
export {
  apiConfig
};

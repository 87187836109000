import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    showPanel: boolean;
    isContinueToCheckout?: boolean;
}

const showValidationsSlice = createSlice({
    name: "showValidations",
    initialState: { showPanel: false } as State,
    reducers: {
        setShowValidations: (state, action: PayloadAction<State>) => ({ ...state, ...action.payload })
    }
});

export const { setShowValidations } = showValidationsSlice.actions;
export const showValidations = showValidationsSlice.reducer;

import type { RGB } from "@design-stack-vista/utility-core";
import { hex2rgb } from "@design-stack-vista/utility-core";
import { detectColors } from "@docext/algolib";

type ColorsType = {
    output: {
        colors: {
            colorInfo: {
                color: string;
            };
            percentage: number;
        }[];
    };
};

/**
 * @description Identifies the dominant color from the image-url
 * @param imageURL
 */
export async function getDominantColorFromImage(file: File): Promise<RGB> {
    const colors: ColorsType = await detectColors(file);
    const dominantColor = colors.output.colors.reduce((colorA, colorB) => {
        if (colorA) {
            return colorA.percentage > colorB.percentage ? colorA : colorB;
        }
        return colorA;
    });
    return hex2rgb(`#${dominantColor.colorInfo.color}`);
}

import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useCurrentFinish } from "../useCurrentFinish";
import { useStudio6AvailablePremiumFinishesOnCanvas } from "../useStudio6AvailablePremiumFinishesOnCanvas";
import { useAvailablePremiumFinishesForSurfaces } from "../useAvailablePremiumFinishesForSurfaces";
import { SUPPORTED_FINISHES } from "../constants";

export function useFullBleedPremiumFinish() {
    const { isFullBleed } = useProductAndProjectStateManager().data;
    const { hasPremiumFinishesCurrentCanvas } = useStudio6AvailablePremiumFinishesOnCanvas();
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();
    const currentFinish = useCurrentFinish();

    return {
        isButtonVisible:
            isFullBleed &&
            hasPremiumFinishesCurrentCanvas &&
            !!currentFinish &&
            SUPPORTED_FINISHES.includes(currentFinish),
        isFullBleedPremiumFinish: isFullBleed && hasPremiumFinishesAvailable
    };
}

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { reportErrorToNewRelic, reportErrorToSegment } from "./errorReporting";
import { useTrackingClient } from "@internal/utils-tracking";
import { getCleanErrorMessage, getErrorCode, getStackTrace, parseMessage } from "./errorParsing";
import { ErrorWithStack, type ErrorHandler, type ErrorPayload, type StudioError } from "./types";
import { Dispatch } from "redux";

interface ErrorProviderContext {
    resetErrorState: () => void;
    handleError: ErrorHandler;
    errorState: ErrorPayload;
}

interface ErrorProviderProps {
    useDispatch: () => Dispatch<any>;
    hideLoader: () => void;
    setLoadingMessage: (message?: string | null) => void;
}

const Context = createContext<ErrorProviderContext | undefined>(undefined);

export const useErrors = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within an ErrorProvider");
    }

    return result;
};

export const ErrorProvider = ({
    children,
    useDispatch,
    hideLoader,
    setLoadingMessage
}: React.PropsWithChildren<ErrorProviderProps>) => {
    const { Provider } = Context;
    const [errorPayload, setErrorPayload] = useState<ErrorPayload>({});
    const trackingClient = useTrackingClient();
    const dispatch = useDispatch();

    const resetErrorState = useCallback(() => {
        setErrorPayload({});
        dispatch(hideLoader());
        dispatch(setLoadingMessage(null));
    }, [dispatch, hideLoader, setLoadingMessage]);

    const handleError = useCallback(
        (
            originalError: StudioError,
            studioErrorCode: string | number,
            entityCode: number,
            showErrorPage = false,
            logError = true,
            hideErrorToast = false,
            scope = "studio"
        ) => {
            const convertedError = parseMessage(originalError);
            const errorCode = getErrorCode(convertedError, studioErrorCode, entityCode);

            const cleanErrorMessage = getCleanErrorMessage(convertedError, errorCode);

            if (logError) {
                setErrorPayload({
                    errorCode,
                    errorStackTrace: getStackTrace(originalError),
                    errorExtraData: convertedError,
                    errorMessage: cleanErrorMessage,
                    showErrorPage,
                    hideErrorToast
                });
                dispatch(hideLoader());
                dispatch(setLoadingMessage(null));
            }

            let errorMessage = `Error Code: ${errorCode} - `;
            if (convertedError.moduleFunction) {
                errorMessage += convertedError.moduleFunction;
            }
            if (convertedError.url) {
                errorMessage += `[${convertedError.url}]`;
            }
            if (convertedError.errorMessage) {
                errorMessage += ` ${convertedError.errorMessage}`;
            }
            if (typeof originalError === "string") {
                errorMessage += ` ${convertedError}`;
            }

            if (logError) {
                reportErrorToNewRelic(originalError, convertedError, errorCode, errorMessage, cleanErrorMessage, scope);
                reportErrorToSegment(
                    originalError,
                    convertedError,
                    errorCode,
                    errorMessage,
                    cleanErrorMessage,
                    trackingClient,
                    showErrorPage
                );
            }

            const error = new ErrorWithStack(cleanErrorMessage, errorCode, originalError.stack);
            return error;
        },
        [trackingClient, dispatch, hideLoader, setLoadingMessage]
    );

    const contextObject = useMemo(
        () => ({
            handleError,
            resetErrorState,
            errorState: errorPayload
        }),
        [handleError, resetErrorState, errorPayload]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

import { useCallback } from "react";
import { getImageAsPromise } from "@design-stack-vista/utility-core";
import { useSingleColorContrast } from "./SingleColorContrastProvider";
import { drawImageOnCanvas, drawSingleColor } from "../util";

export function useAdjustContrastCanvas() {
    const { imageUrl, color, threshold, inverted } = useSingleColorContrast();

    const drawPreview = useCallback(
        (canvas: HTMLCanvasElement) => {
            if (imageUrl) {
                getImageAsPromise(imageUrl).then(async (image: HTMLImageElement) => {
                    if (canvas && color) {
                        const imgData = drawImageOnCanvas(image, canvas);
                        await drawSingleColor(canvas, color.cssBackground, threshold, inverted, image, imgData);
                    }
                });
            }
        },
        [imageUrl, color, threshold, inverted]
    );

    return { drawPreview };
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DTeC } from "@vp/types-ddif";

type MatchingTemplateInfo = Omit<DTeC.EnsembleTemplateDto, "ensemblePanelType"> & {
    previewUrl: string;
    ensemblePanelType?: DTeC.PanelType;
};

const matchingTemplatesSlice = createSlice({
    name: "matchingTemplates",
    initialState: null as MatchingTemplateInfo[] | null,
    reducers: {
        setMatchingTemplates: (state, action: PayloadAction<MatchingTemplateInfo[] | null>) => action.payload
    }
});

export const { setMatchingTemplates } = matchingTemplatesSlice.actions;
export const matchingTemplates = matchingTemplatesSlice.reducer;

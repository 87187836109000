import { jsx } from "react/jsx-runtime";
import { getImageAsPromise } from "@design-stack-vista/utility-core";
import { useRef, useMemo, useCallback, useEffect } from "react";
import { getImageAsPromiseFromSvg } from "../../shared/getImageAsPromiseFromSvg.js";
import { useTranslationSSR } from "@vp/i18n-helper";
import { patternMessage } from "../../shared/patternMessage.js";
import "@internal/utils-custom-metadata";
import "@design-stack-vista/pattern-sdk";
import "@design-stack-vista/i18n-color-name";
import "@design-stack-ct/preview-sdk";
import "@design-stack-vista/cimdoc-state-manager";
const PatternItemPreview = ({
  show,
  isUrlSvg,
  svgData,
  height,
  width,
  patternFileUrl,
  patternTileWidth,
  patternTileHeight,
  fallbackToRenderingPreview
}) => {
  const visibleCanvasRef = useRef(null);
  const {
    t
  } = useTranslationSSR();
  const patternTileDimension = useMemo(() => {
    return {
      width: patternTileWidth,
      height: patternTileHeight
    };
  }, [patternTileHeight, patternTileWidth]);
  const modificationCanvasRef = useRef(null);
  if (!modificationCanvasRef.current) {
    modificationCanvasRef.current = document.createElement("canvas");
  }
  const repeatImageOnCanvas = (canvas, tileDimension, image) => {
    const context = canvas.getContext("2d");
    const {
      height: tileDimensionHeight,
      width: tileDimensionWidth
    } = tileDimension;
    if (context) {
      for (let heightOffset = 0; heightOffset < canvas.height; heightOffset += tileDimensionHeight) {
        for (let widthOffset = 0; widthOffset < canvas.width; widthOffset += tileDimensionWidth) {
          context.drawImage(image, widthOffset, heightOffset, tileDimensionWidth, tileDimensionHeight);
        }
      }
    }
  };
  const drawCanvas = useCallback(async () => {
    if (patternFileUrl) {
      if (visibleCanvasRef.current && modificationCanvasRef.current) {
        try {
          let image;
          if (!isUrlSvg) {
            image = await getImageAsPromise(patternFileUrl);
          } else if (svgData) {
            image = await getImageAsPromiseFromSvg(svgData, patternTileDimension);
          }
          if (image) {
            const visibleCanvas = visibleCanvasRef.current;
            if (visibleCanvas) {
              visibleCanvas.height = height;
              visibleCanvas.width = width;
            }
            const modificationCanvas = modificationCanvasRef.current;
            modificationCanvas.height = height;
            modificationCanvas.width = width;
            const context = modificationCanvas.getContext("2d");
            context.clearRect(0, 0, modificationCanvas.width, modificationCanvas.height);
            repeatImageOnCanvas(modificationCanvas, patternTileDimension, image);
            const visibleContext = visibleCanvas.getContext("2d");
            if (visibleContext) {
              visibleContext.drawImage(modificationCanvas, 0, 0, width, height);
            }
          }
        } catch (error) {
          fallbackToRenderingPreview();
        }
      }
    }
  }, [patternFileUrl, isUrlSvg, svgData, patternTileDimension, height, width, fallbackToRenderingPreview]);
  useEffect(() => {
    if (visibleCanvasRef.current) {
      drawCanvas();
    }
  }, [drawCanvas]);
  return /* @__PURE__ */ jsx("canvas", {
    style: {
      display: show ? "block" : "none"
    },
    "aria-label": t(patternMessage.patternPreviewCanvas.id),
    role: "img",
    ref: visibleCanvasRef
  });
};
export {
  PatternItemPreview
};

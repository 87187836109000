import { jsx } from "react/jsx-runtime";
import { observer } from "mobx-react-lite";
import { ItemPosition } from "@design-stack-vista/ida-framework";
import { ItemPreviewExtension, ItemLayoutExtension, DesignLayerItemVisibilityExtension, applyZoom, useDesignEngine, FUSION_ANTIALIAS_PADDING } from "@design-stack-vista/core-features";
import { useEffect, useRef } from "react";
function getCanvasStyle(previewBox) {
  if (!previewBox) {
    return {
      display: "none"
    };
  }
  return {
    transform: `translate(${-FUSION_ANTIALIAS_PADDING / devicePixelRatio}px,
        ${-FUSION_ANTIALIAS_PADDING / devicePixelRatio}px)`,
    height: `calc(100% + ${2 / devicePixelRatio * FUSION_ANTIALIAS_PADDING}px)`,
    width: `calc(100% + ${2 / devicePixelRatio * FUSION_ANTIALIAS_PADDING}px)`
  };
}
const ItemPreview = /* @__PURE__ */ observer(({
  item
}) => {
  const canvas = /* @__PURE__ */ useRef(null);
  const {
    designExtensionSystem: extensions,
    layoutStore
  } = /* @__PURE__ */ useDesignEngine();
  const preview = /* @__PURE__ */ extensions.getExtension(item.iid, ItemPreviewExtension);
  const layout = /* @__PURE__ */ extensions.getExtension(item.iid, ItemLayoutExtension);
  const visibility = /* @__PURE__ */ extensions.getExtension(item.iid, DesignLayerItemVisibilityExtension);
  useEffect(() => {
    if (canvas.current && preview) {
      preview.setCanvas(canvas.current);
    }
  }, [canvas, preview]);
  if (!preview || !layout) {
    return null;
  }
  const defaultPreviewBox = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotation: 0
  };
  const previewBox = applyZoom(layout.interactivePreviewBox, layoutStore.zoom) || defaultPreviewBox;
  const dimensions = previewBox;
  const position = previewBox;
  const {
    rotation
  } = previewBox;
  const {
    zIndex
  } = item.model;
  const style = /* @__PURE__ */ getCanvasStyle(previewBox);
  const isPreviewVisible = (visibility == null ? void 0 : visibility.getLayerVisibility("item-preview")) ?? true;
  return /* @__PURE__ */ jsx(ItemPosition, {
    dimensions,
    position,
    rotation,
    zIndex,
    children: /* @__PURE__ */ jsx("canvas", {
      hidden: !isPreviewVisible || !style,
      ref: canvas,
      style: {
        ...style,
        opacity: 0.5,
        backgroundColor: "yellow"
      }
    })
  });
});
export {
  ItemPreview
};

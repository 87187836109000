import { type JSX } from "react";
import { DesktopExperience, MobileExperience } from "@internal/feature-responsive-design";
import { Divider, FlexBox, Typography } from "@vp/swan";
import React from "react";
import { useLocalized } from "../../localized";
import { AdjustContrastInvertColor } from "./AdjustContrastInvertColor";
import { AdjustContrastRange } from "./AdjustContrastRange";
import { SingleColorColorPicker } from "./SingleColorColorPicker";

interface AdjustContrastColorProps {
    enableToggleBackground: boolean;
}

export const AdjustContrastColor = (props: AdjustContrastColorProps): JSX.Element => {
    const { enableToggleBackground } = props;
    const localized = useLocalized();

    return (
        <>
            <DesktopExperience>
                <FlexBox justifyContent="center" alignItems="center" fontSize="small" marginX={"4"} marginY={"5"}>
                    <SingleColorColorPicker enableToggleBackground={enableToggleBackground} />
                    <AdjustContrastInvertColor />
                </FlexBox>
            </DesktopExperience>
            <MobileExperience>
                <FlexBox mt={"5"} mb={"4"} mx={"5"} alignItems="center" justifyContent="space-between" fontSize="small">
                    <Typography>{localized.get("contrastSliderLabel")}</Typography>
                    <AdjustContrastInvertColor />
                </FlexBox>
                <AdjustContrastRange />
                <Divider mb={"4"} mx={"5"} />
                <SingleColorColorPicker enableToggleBackground={enableToggleBackground} />
            </MobileExperience>
        </>
    );
};

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { DocumentSourceType } from "@internal/data-access-design-specifications-service";
import { useStudioLayout } from "@internal/feature-responsive-design";

type ContextData = {
    isResizeNoDesignVariationsABEnabled: boolean;
    isTrackingComplete: boolean;
    trackImpression: (documentSource: DocumentSourceType) => Promise<void>;
    trackExperimentInteraction: (eventDetail: string, experimentDetail: string | undefined) => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useResizeNoDesignVariationsExperiment() {
    const result = useContext(context);

    if (!result) {
        throw Error("Missing context. This must be called within a ResizeNoDesignVariationsABProvider");
    }

    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const resizeNoDesignVariationsExperimentData: ExperimentData = {
    experimentKey: "studio_resize_without_design_variations",
    experimentName: "Resize without design variations",
    variations: Variations
};

export const ResizeNoDesignVariationsABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const trackingClient = useTrackingClient();
    const { experimentKey } = resizeNoDesignVariationsExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);
    const { isSmall } = useStudioLayout();

    const trackImpression = useCallback(
        async (documentSource: DocumentSourceType) => {
            if (
                !experimentKey ||
                !ABTest ||
                documentSource !== DocumentSourceType.TEMPLATE_TOKEN || // Only track if there is a template token
                isSmall || // Only track for desktop
                isTrackingComplete
            ) {
                return;
            }

            const trackImpressionIfInVariant = async (variation: Variations) => {
                const { isExperimentUsingVariation, trackImpression } = ABTest;
                const res = await isExperimentUsingVariation(experimentKey, variation);

                if (res) {
                    trackImpression(experimentKey, variation);
                    setIsEnabled(variation === Variations.Test);
                }
            };

            await Promise.all([
                trackImpressionIfInVariant(Variations.Test),
                trackImpressionIfInVariant(Variations.Control)
            ]);

            setIsTrackingComplete(true);
        },
        [ABTest, experimentKey, isSmall, isTrackingComplete]
    );

    const trackExperimentInteraction = useCallback(
        (eventDetail: string, experimentDetail = "") => {
            if (!experimentKey || !ABTest || !isEnabled || !trackingClient) {
                return;
            }

            trackingClient.track(Events.ExperimentClicked, {
                eventDetail,
                experimentDetail,
                experimentData: {
                    experimentId: experimentKey,
                    experimentName: experimentKey,
                    variationId: Variations.Test,
                    variationName: Variations.Test
                }
            });
        },
        [ABTest, experimentKey, isEnabled, trackingClient]
    );

    const contextObject = useMemo(
        () => ({
            isResizeNoDesignVariationsABEnabled: isEnabled,
            isTrackingComplete,
            trackImpression,
            trackExperimentInteraction
        }),
        [isEnabled, isTrackingComplete, trackExperimentInteraction, trackImpression]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

ResizeNoDesignVariationsABProvider.displayName = "ResizeNoDesignVariationsABProvider";

import { useDesignEngine } from "@design-stack-vista/core-features";
import { useColorPaletteForDecorationTechnology } from "@internal/feature-color-palette";
import { useState } from "react";
import { RecentColorsManager } from "./RecentColorsManager";
import { useGetAllColorsFromCimDoc } from "./useGetAllColorsFromCimDoc";

/**
 * Returns a `RecentColorsManager` instance that is pre-populated
 * with the CimDoc's used colors.
 */
export const useInitializeRecentColorsManager = () => {
    const getAllColorsMap = useGetAllColorsFromCimDoc();
    const designEngine = useDesignEngine();
    const colorPalette = useColorPaletteForDecorationTechnology(
        designEngine.cimDocStore.panels[0].panelProperties.decorationTechnology
    );
    const [recentColorManager] = useState(() => new RecentColorsManager(getAllColorsMap, colorPalette!));

    return recentColorManager;
};

import React from "react";
import classnames from "classnames";
import { Button, Typography, Row, Column, GridContainer } from "@vp/swan";
import styles from "./ErrorMessagingCTA.module.scss";

/* Define custom props for your component */
export interface ErrorMessagingCTAProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Primary header shown.  Should be succinct.
     */
    errorHeader: string;
    /**
     * Sub header. should be descriptive
     */
    errorSubHeader?: string;
    /**
     * Message for the primary CTA
     */
    primaryButtonMessage: string;
    /**
     * CSS color value for the text
     */
    primaryOnClick: () => void;
    /**
     * Message for the secondary CTA
     */
    secondaryButtonMessage?: string;
    /**
     * Action for the secondary CTA
     */
    secondaryOnClick?: () => void;
}

export function ErrorMessagingCTA({
    errorHeader,
    errorSubHeader,
    primaryButtonMessage,
    primaryOnClick,
    secondaryButtonMessage,
    secondaryOnClick,
    ...rest
}: ErrorMessagingCTAProps) {
    return (
        <GridContainer {...rest}>
            <Row>
                <Column className={classnames(styles.errorHeaderCol, "p-0")} span={12}>
                    <Typography className={styles.primaryErrorHeader} component="h3">
                        {errorHeader}
                    </Typography>
                </Column>
            </Row>
            <Row>
                <Column className={classnames(styles.errorHeaderCol, "p-0")} span={12}>
                    <Typography className={styles.secondaryErrorHeader}>{errorSubHeader}</Typography>
                </Column>
            </Row>
            <Row>
                <div className={styles.errorButtonContainer}>
                    <Button className={styles.errorCTA} onClick={primaryOnClick} component="a" skin="primary" my={"2"}>
                        {primaryButtonMessage}
                    </Button>

                    {secondaryButtonMessage && secondaryOnClick && (
                        <Button
                            className={styles.errorCTA}
                            onClick={secondaryOnClick}
                            component="a"
                            skin="secondary"
                            my={"2"}
                        >
                            {secondaryButtonMessage}
                        </Button>
                    )}
                </div>
            </Row>
        </GridContainer>
    );
}
ErrorMessagingCTA.displayName = "ErrorPageCTA";

import { Box } from "@vp/swan";
import { getOptionalExtension, useDesignEngine, useSelectedItems } from "@design-stack-vista/core-features";
import { ItemProcessingExtension } from "@design-stack-vista/interactive-design-engine-core";
import { type SceneConfig } from "@internal/data-access-calcifer";
import { ContrastIcon, ToolbarButton } from "@internal/ui-studio-chrome";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { singleColorContrastModalStore } from "../SingleColorContrastModalStore";
import { STUDIO_TRACKING_EVENTS } from "../TrackingEventConstants";
import { SingleColorContrastProvider } from "../hooks";
import { useLocalized } from "../localized";
import { AdjustContrastModal } from "./adjustContrastModal";

interface AdjustContrastButtonProps {
    underlaySceneConfiguration?: SceneConfig;
    enableToggleBackground: boolean;
}

export const AdjustContrastButton = observer(
    ({ underlaySceneConfiguration, enableToggleBackground }: AdjustContrastButtonProps) => {
        const designEngine = useDesignEngine();
        const trackingClient = useTrackingClient();
        const localized = useLocalized();
        const { selectedItems } = useSelectedItems();

        if (!singleColorContrastModalStore) {
            return null;
        }

        const isDisabled = useMemo(
            () =>
                selectedItems.some(
                    item => getOptionalExtension(designEngine, item, ItemProcessingExtension)?.isProcessing
                ),
            [designEngine, selectedItems]
        );

        const { openModal } = singleColorContrastModalStore;

        const onButtonClick = useCallback(() => {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_IMAGE_CHANGE_COLOR_BUTTON
            });
            openModal();
        }, [openModal, trackingClient]);

        return (
            <>
                <ToolbarButton onClick={onButtonClick} disabled={isDisabled}>
                    <ContrastIcon />
                    <Box mx={"3"} className="dcl-ctx-button-label">
                        {localized.get("contrastButtonLabel")}
                    </Box>
                </ToolbarButton>
                <SingleColorContrastProvider underlaySceneConfiguration={underlaySceneConfiguration}>
                    <AdjustContrastModal enableToggleBackground={enableToggleBackground} />
                </SingleColorContrastProvider>
            </>
        );
    }
);

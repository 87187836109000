var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import "../shared/patternMessage.js";
import { PatternPanelMode } from "../shared/patternsTypeGuard.js";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/pattern-sdk";
import "@design-stack-vista/i18n-color-name";
import "@design-stack-ct/preview-sdk";
import "@design-stack-vista/cimdoc-state-manager";
import { action, makeObservable, observable } from "mobx";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp2(target, key, result);
  return result;
};
class PatternStore {
  constructor() {
    __publicField(this, "patternPanelMode", PatternPanelMode.Add);
    makeObservable(this);
  }
  setPatternPanelMode(mode) {
    this.patternPanelMode = mode;
  }
}
__decorateClass([observable], PatternStore.prototype, "patternPanelMode", 2);
__decorateClass([action.bound], PatternStore.prototype, "setPatternPanelMode", 1);
const patternStore = new PatternStore();
export {
  patternStore
};

import type { ColorAdjustment } from "@design-stack-vista/cdif-types";

export const defaultColorAdjustment: ColorAdjustment = {
    hueOffset: 0,
    hueMultiplier: 1,
    saturationOffset: 0,
    saturationMultiplier: 1,
    lightnessOffset: 0,
    lightnessMultiplier: 1
};

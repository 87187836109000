import { useEffect, useState } from "react";
import { when } from "mobx";
import { CimDoc, ImageItem } from "@design-stack-vista/cdif-types";
import { VistaAssetStore, VistaAsset, AssetStatusType } from "@design-stack-vista/vista-assets-sdk";
import { ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { Vector2 } from "@design-stack-vista/utility-core";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { DispatchToast } from "@internal/utils-detail-zone";
import { useCutlineConfiguration } from "./CutlineConfigurationProvider";
import { useAddReuploadedImageWithCutline } from "./useAddReuploadedImageWithCutline";
import { ImageVariantType, CutlineBaseImageVariantType } from "./useApplyCutlineItems";

type ImageInfo = NonNullable<NonNullable<VistaAsset["data"]>["info"]["image"]>;
interface AddImageOptionalParams {
    panelId?: string;
    location?: Vector2;
    imageInfo?: ImageInfo;
    pageNumber?: number;
    /**
     * Optional ongoing upload to use as part of the instant uploads flow.
     * When not provided, `addImage` instead tries to fetch a previously cached asset (for icons/deposit photos) or creates a new (hidden) asset if one cannot be found
     */
    upload?: Promise<void | VistaAsset>;
    /** Must be passed as property to avoid race condition */
    itemForReplacing?: ItemState<ImageItem>;
}

interface Props {
    dispatchToast?: DispatchToast;
}

export const useTransferUploadToSherbert = ({ dispatchToast }: Props) => {
    const [stickerDEXUploadTransferred, setStickerDEXUploadTransferred] = useState(false);
    const { setIsReuploadingImage } = useCutlineConfiguration();
    const { addReuploadedImageWithCutline } = useAddReuploadedImageWithCutline({ dispatchToast });
    const { assetStore } = useUploadManager();
    const designEngine = useDesignEngine();

    useEffect(() => {
        async function transferUploadToSherbert(currentDocument: CimDoc, assetStore: VistaAssetStore) {
            const imageVariantType: ImageVariantType = currentDocument.metadata?.imageVariantType;
            const cutlineBaseImageVariantType: CutlineBaseImageVariantType =
                currentDocument.metadata?.cutlineBaseImageVariantType;
            const existingImage = currentDocument.document.panels[0].images?.[0];
            const uri = existingImage?.originalSourceUrl || "";
            const newSherbertAsset = await assetStore.upload({
                uri,
                expires: "never",
                info: {
                    asset: {
                        hidden: true
                    }
                }
            });

            const isAssetProcessed = (asset: VistaAsset): boolean => {
                return !!(asset.status.type === AssetStatusType.Prepared && asset.data?.info?.image);
            };

            when(
                () => isAssetProcessed(newSherbertAsset),
                () =>
                    addReuploadedImageWithCutline(newSherbertAsset, {
                        imageVariantType,
                        cutlineBaseImageVariantType
                    } as AddImageOptionalParams & {
                        cutlineBaseImageVariantType?: CutlineBaseImageVariantType;
                        imageVariantType?: ImageVariantType;
                    })
            );
        }

        if (stickerDEXUploadTransferred || !designEngine || !assetStore) {
            return;
        }

        const currentDocument = designEngine.cimDocStore.asJson;
        // if theres a cutline path but no cached image data, the image needs to be reuploaded to sherbert
        if (
            currentDocument.metadata?.cutlineDetails?.cutlinePath &&
            !currentDocument.metadata?.cutlineDetails?.cachedImageData
        ) {
            setStickerDEXUploadTransferred(true);
            try {
                setIsReuploadingImage(true);
                transferUploadToSherbert(currentDocument, assetStore);
            } catch (e) {
                setIsReuploadingImage(false);
            }
        }
    }, [addReuploadedImageWithCutline, assetStore, designEngine, setIsReuploadingImage, stickerDEXUploadTransferred]);
};

import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    ariaCloseLabel: string;
    modalAriaLabel: string;
    singleColorContrastModalTitle: string;
    invertColors: string;
    contrastSliderLabel: string;
    cancelContrast: string;
    applyContrast: string;
    colorsDescriptionTitle: string;
    contrastButtonLabel: string;
    altImageDescription: string;
    engravingGuidelineAriaCloseLabel: string;
    engravingGuidelineHeader: string;
    engravingGuidelineTextTipTitle: string;
    engravingGuidelineTextTipText: string;
    engravingGuidelineTextBadImage: string;
    engravingGuidelineTextGoodImage: string;
    engravingGuidelineGraphicsTipTitle: string;
    engravingGuidelineGraphicsTipText: string;
    engravingGuidelineGraphicsBadImage: string;
    engravingGuidelineGraphicsGoodImage: string;
    engravingGuidelineLinesTipTitle: string;
    engravingGuidelineLinesTipText: string;
    engravingGuidelineLinesBadImage: string;
    engravingGuidelineLinesGoodImage: string;
    engravingGuidelineCloseButtonText: string;
    engravingTipsButton: string;
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    contrastButtonLabel: {
        id: "studio.component.singleColorContrastModal.contrastButtonLabel",
        defaultMessage: "Adjust contrast",
        description: "label for contrast button on the selection zone toolbar for single color"
    },
    ariaCloseLabel: {
        id: "studio.components.singleColorContrastModal.ariaCloseLabel",
        defaultMessage: "Close adjust contrast for single color modal",
        description: "aria label for modal close button"
    },
    singleColorContrastModalTitle: {
        id: "studio.components.singleColorContrastModal.singleColorContrastModalTitle",
        defaultMessage: "Adjust Contrast",
        description: "title for the modal for adjusting single color contrast"
    },
    modalAriaLabel: {
        id: "studio.components.singleColorContrastModal.modalAriaLabel",
        defaultMessage: "Adjust image modal",
        description: "aria label for adjust contrast modal"
    },
    invertColors: {
        id: "studio.component.singleColorContrastModal.invertColors",
        defaultMessage: "Invert colors",
        description: "label for Invert colors button in contrast modal for single color"
    },
    contrastSliderLabel: {
        id: "studio.component.singleColorContrastModal.contrastSliderLabel",
        defaultMessage: "Image contrast",
        description: "label for image contrast slider for contrast modal for single color"
    },
    cancelContrast: {
        id: "studio.components.singleColorContrastModal.cancelBtnLabel",
        defaultMessage: "Cancel",
        description: "aria label for close button in the corner of contrast modal for single color"
    },
    applyContrast: {
        id: "studio.components.singleColorContrastModal.applyBtnLabel",
        defaultMessage: "Apply",
        description: "apply button in contrast modal for single color"
    },
    colorsDescriptionTitle: {
        id: "studio.component.singleColorContrastModal.colorsDescriptionTitle",
        defaultMessage: "Available colors",
        description: "Label for palette description for single color palette"
    },
    altImageDescription: {
        id: "studio.component.singleColorContrastModal.imageAltText",
        defaultMessage: "Single Color Image preview",
        description: "Alt text of the image preview in contrast modal for single color"
    },
    engravingGuidelineAriaCloseLabel: {
        id: "studio.engravingGuidelineModal.ariaCloseLabel",
        defaultMessage: "Close engraving guideline dialog"
    },
    engravingGuidelineHeader: {
        id: "studio.engravingGuidelineModal.header",
        defaultMessage: "Engraving Tips"
    },
    engravingGuidelineTextTipTitle: {
        id: "studio.engravingGuidelineModal.textTip.title",
        defaultMessage: "Use large text"
    },
    engravingGuidelineTextTipText: {
        id: "studio.engravingGuidelineModal.textTip.description",
        defaultMessage: "Small text doesn't reproduce well. Use large letters for better results."
    },
    engravingGuidelineTextBadImage: {
        id: "studio.engravingGuidelineModal.textTip.badImage",
        defaultMessage: "Bad text example"
    },
    engravingGuidelineTextGoodImage: {
        id: "studio.engravingGuidelineModal.textTip.goodImage",
        defaultMessage: "Good text example"
    },
    engravingGuidelineGraphicsTipTitle: {
        id: "studio.engravingGuidelineModal.graphicsTip.title",
        defaultMessage: "Opt for simple graphics over photos"
    },
    engravingGuidelineGraphicsTipText: {
        id: "studio.engravingGuidelineModal.graphicsTip.description",
        defaultMessage:
            "Complex imagery like photos and gradients may not translate well. Stick with sharp, minimal graphics."
    },
    engravingGuidelineGraphicsBadImage: {
        id: "studio.engravingGuidelineModal.graphicsTip.badImage",
        defaultMessage: "Bad graphics example"
    },
    engravingGuidelineGraphicsGoodImage: {
        id: "studio.engravingGuidelineModal.graphicsTip.goodImage",
        defaultMessage: "Good graphics example"
    },
    engravingGuidelineLinesTipTitle: {
        id: "studio.engravingGuidelineModal.linesTip.title",
        defaultMessage: "Use bold lines"
    },
    engravingGuidelineLinesTipText: {
        id: "studio.engravingGuidelineModal.linesTip.description",
        defaultMessage: "Engraving looks best with bold, defined elements."
    },
    engravingGuidelineLinesBadImage: {
        id: "studio.engravingGuidelineModal.linesTip.badImage",
        defaultMessage: "Bad lines example"
    },
    engravingGuidelineLinesGoodImage: {
        id: "studio.engravingGuidelineModal.linesTip.goodImage",
        defaultMessage: "Good lines example"
    },
    engravingGuidelineCloseButtonText: {
        id: "studio.engravingGuidelineModal.closeButtonText",
        defaultMessage: "Get started"
    },
    engravingTipsButton: {
        id: "studio.engraving.tipsButton",
        defaultMessage: "Engraving tips"
    }
};

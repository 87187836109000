const getPanelColors = (panel) => {
  var _a;
  const shapeColors = [];
  (_a = panel.shapes) == null ? void 0 : _a.forEach((shape) => {
    var _a2, _b;
    if (shape.color) {
      shapeColors.push(shape.color);
    }
    if ((_a2 = shape.stroke) == null ? void 0 : _a2.color) {
      shapeColors.push((_b = shape.stroke) == null ? void 0 : _b.color);
    }
  });
  return [...new Set(shapeColors)];
};
export {
  getPanelColors
};

import React, { createContext, ReactNode, useState, useMemo, useContext, useEffect } from "react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { getItemsFromCart, type LineItem, getItemInCart } from "@internal/data-access-cart";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";

type UseAppSelector<TState = any> = <TSelected = unknown>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
) => TSelected;

interface CartContextProviderProps {
    children: ReactNode;
    useAppSelector: UseAppSelector;
    isQuantityPageEnabled: boolean;
}
interface ContextData {
    isItemInCart: boolean;
    itemsInCart: LineItem[] | undefined;
}

const CartContext = createContext<ContextData | undefined>(undefined);

export const useCartContext = () => {
    const result = useContext(CartContext);
    if (!result) {
        throw Error("Missing context.  This must be called within a CartContextProvider");
    }
    return result;
};

export const CartContextProvider = observer(
    ({ children, useAppSelector, isQuantityPageEnabled }: CartContextProviderProps) => {
        const {
            isIdentityInitialized,
            auth,
            identity: { shopperId, anonymousUserId }
        } = useIdentityContext();
        const ownerId = shopperId || anonymousUserId;
        const productAndProjectStateManager = useProductAndProjectStateManager();
        const { locale, workId, quantity, quantityPerSize } = productAndProjectStateManager.data;
        const [itemsInCart, setItemsInCart] = useState<LineItem[] | undefined>();
        const [isItemInCart, setIsItemInCart] = useState<boolean>(false);

        const easelLoaded = useAppSelector(state => state.easelLoaded);

        useEffect(() => {
            if (easelLoaded) {
                const authToken = isIdentityInitialized && auth.getToken();
                if (
                    authToken &&
                    workId &&
                    ((quantity && !isQuantityPageEnabled) || (isQuantityPageEnabled && quantityPerSize !== "0"))
                ) {
                    getItemsFromCart(authToken, ownerId, locale)
                        .then(cartItems => {
                            setItemsInCart(cartItems?.lineItems);
                            const itemInCart = getItemInCart(cartItems?.lineItems, workId);
                            setIsItemInCart(!!itemInCart);
                            if (itemInCart) {
                                productAndProjectStateManager.updateConfiguration({ quantity: itemInCart?.quantity });
                            }
                        })
                        .catch(e => {
                            setItemsInCart(undefined);
                            setIsItemInCart(false);
                        });
                } else {
                    setItemsInCart(undefined);
                    setIsItemInCart(false);
                }
            }
        }, [
            ownerId,
            auth,
            easelLoaded,
            isIdentityInitialized,
            locale,
            workId,
            quantity,
            isQuantityPageEnabled,
            quantityPerSize,
            productAndProjectStateManager
        ]);

        const contextObject = useMemo(() => {
            return {
                isItemInCart,
                itemsInCart
            };
        }, [isItemInCart, itemsInCart]);

        return <CartContext.Provider value={contextObject}>{children}</CartContext.Provider>;
    }
);

CartContextProvider.displayName = "CartContextProvider";

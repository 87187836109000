/*
 * Base Dialog Types found in Studio
 */

import { buildCoreActiveDialogTypes } from "./buildPluginActiveDialogTypes";

// TODO: "Text" should probably replaced with something like "Default" or "Reset".
// "Text" is meant to represent the initial selected left-panel. Ideally "Text"
// would be registered somehow as an alias to the a "Default" but "Text" would
// be owned by the Text sim
export const DialogCoreValues = buildCoreActiveDialogTypes(["None", "Text"]);

import { CimDoc } from "@design-stack-vista/cdif-types";
import { initializeMetadata } from "@design-stack-vista/cimdoc-state-manager";

/**
 * Pro-actively creates metadata on the document in case any code forgets to check for existence
 * @param cimDoc
 */
export function initializeItemMetadata(cimDoc: CimDoc) {
    if (!cimDoc.metadata || !cimDoc.metadata?.dclMetadata) {
        initializeMetadata(cimDoc, { initializeDclMetadata: true });
    }
    if (!cimDoc.metadata!.studioMetadata) {
        cimDoc.metadata!.studioMetadata = {};
    }
}

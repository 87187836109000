import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useQuery } from "@tanstack/react-query";
import { StudioSecondaryConfig } from "@internal/data-access-calcifer";
import { useCreateSecondaryConfigQueryOptions } from "@internal/dex";

/**
 * Query Calcifer for "Secondary Config" data
 * @param select refine the return value to just the data you need to observe to minimize re-renders
 * @returns
 */
export const useQuerySecondaryConfig = <TData = StudioSecondaryConfig>(
    select: (data: StudioSecondaryConfig) => TData
) => {
    const { productDataLoadSuccessful } = useProductAndProjectStateManager().data;
    const queryOptions = useCreateSecondaryConfigQueryOptions();

    return useQuery({
        ...queryOptions,
        enabled: productDataLoadSuccessful,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        placeholderData: previousData =>
            previousData || {
                templates: [],
                surfaceUpsells: undefined,
                warnings: [],
                designVariations: [],
                templateColorVariations: [],
                templateAvailableFinishes: [],
                primaryTemplateToken: "",
                isQuantityPageEnabled: false,
                designAttributes: [],
                compatibleOptions: [],
                productAttributeAndValues: []
            },
        select
    });
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const isChangingTemplateSlice = createSlice({
    name: "isChangingTemplate",
    initialState: false,
    reducers: {
        setIsChangingTemplate: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setIsChangingTemplate } = isChangingTemplateSlice.actions;
export const isChangingTemplate = isChangingTemplateSlice.reducer;

import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { TeamsNameContentDiscoveryZoneButton } from "./contentDiscoveryZone/TeamsNameContentDiscoveryZoneButton.js";
import "react/jsx-runtime";
import "./contentDiscoveryZone/components/TeamsNameContentPanel.js";
import "./contentDiscoveryZone/components/TeamsNamePlaceholderButtons.js";
import "@vp/i18n-helper";
import "@internal/ui-studio-chrome";
import { dialogTypes } from "./dialogTypes.js";
import { name } from "./name.js";
import { TeamsNamePluginPreloaderPanelExtension } from "./extensions/TeamsNamePluginPreloaderPanelExtension.js";
import { teamsNameNumberValidations, teamsNameTextValidations } from "./utils/teamsNameValidators.js";
import { ItemTeamsNameInfoTooltip } from "./contextualTooltip/ItemTeamsNameInfoTooltip.js";
import { SimTeamsNameProvider } from "./shared/SimTeamsNameProvider.js";
import { isTeamsNameItemReference } from "./utils/isTeamsNameItemReference.js";
import "react";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/core-features";
import "@design-stack-vista/cimdoc-state-manager";
import "@internal/utils-item-position";
import "@internal/utils-item-color";
import "@internal/utils-product-and-project-state";
import { useGetTeamsNameItems } from "./hooks/useGetTeamsNameItems.js";
import { useHasTeamsNameItems } from "./hooks/useHasTeamsNameItems.js";
const defaultTranslations = {
  "sims.teamsName.contentDiscoveryZone.howToDialog.dialogTitle": "Design once and personalize for the entire group",
  "sims.teamsName.contentDiscoveryZone.howToDialog.closeAccessibilityText": "Close the modal",
  "sims.teamsName.contentDiscoveryZone.howToDialog.stepOneDescription": "Decide where you want to position the placeholders.",
  "sims.teamsName.contentDiscoveryZone.howToDialog.stepTwoDescription": "On the next page, provide the details of each member of your group.",
  "sims.teamsName.contentDiscoveryZone.howToDialog.stepThreeDescription": "Review every personalized item before adding to cart.",
  "sims.teamsName.contentDiscoveryZone.howToDialog.getStartedButtonText.text": "Get started",
  "sims.teamsName.contentDiscoveryZone.howToDialog.openDialogButtonText.text": "How to use placeholders",
  "sims.teamsName.contentDiscoveryZone.howToDialog.carouselPreviousLabel": "Go to previous slide",
  "sims.teamsName.contentDiscoveryZone.howToDialog.carouselNextLabel": "Go to next slide",
  "sims.teamsName.contentDiscoveryZone.howToDialog.carouselStep": "Step [[currentStep]] of 3",
  "sims.teamsName.contentDiscoveryZone.teamsNamePanel.title": "Make each one unique!",
  "sims.teamsName.contentDiscoveryZone.teamsNamePanel.contentTitle": "Teams content panel",
  "sims.teamsName.contentDiscoveryZone.teamsNamePanel.info": "Pick a placeholder and adjust its style, color, and position. In the next step you will add details for each person!",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNamePlaceholder": "Name",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonTitle": "Name",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonDetails": "Give everyone their own personal item",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNameButtonImageAlt": "Name placeholder",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRolePlaceholder": "Role",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonTitle": "Role/job title",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonDetails": "Ideal for your staff, colleagues and personnel",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addRoleButtonImageAlt": "Role/Job title placeholder",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberPlaceholder": "Number",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonTitle": "Number",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonDetails": "Perfect to give your sports team the pro look it deserves",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addNumberButtonImageAlt": "Number placeholder",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholder": "Placeholder",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonTitle": "Add your own placeholder",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonDetails": "You can use this for phone numbers, emails and more",
  "sims.teamsName.contentDiscoveryZone.placeholderButtons.addTextPlaceholderButtonImageAlt": "Text placeholder",
  "sims.teamsName.contentDiscoveryZone.teamsNameSheet": "Names Sheet",
  "sims.teamsName.contentDiscoveryZone.teamsNameButton": "Names",
  "sims.teamsName.placeholderTooltipText": "You will add each name in the next step. This is a placeholder for positioning and styling the name on the item.",
  "sims.teamsName.placeholderTooltipTitle": "Teams item tooltip"
};
const teamsNameSim = {
  name,
  deferredLoader: () => import("./index.deferred.js"),
  core: {
    defaultTranslations,
    extensions: [TeamsNamePluginPreloaderPanelExtension],
    contentDiscoveryZone: {
      TeamsName: {
        activeDialogType: dialogTypes.groupsAndTeams,
        component: TeamsNameContentDiscoveryZoneButton
      }
    },
    afterDuplicatePanelCallback: (configuration) => (cimDoc, panelId) => {
      const panel = /* @__PURE__ */ cimDoc.document.panels.find((panel2) => panel2.id === panelId);
      const teamsNameItems = /* @__PURE__ */ ((panel == null ? void 0 : panel.itemReferences) || []).filter((item) => (item == null ? void 0 : item.type) === ItemReferenceTypes.TEAMS_NAME);
      teamsNameItems.forEach((item) => {
        var _a;
        if (!item) {
          return;
        }
        (_a = cimDoc.metadata) == null ? void 0 : _a.teamsPlaceholders.push({
          key: item.id,
          itemReferenceType: item.type,
          name: item.data.placeholderKey,
          type: item.data.placeholderType,
          validations: item.data.placeholderType === "Number" ? teamsNameNumberValidations : teamsNameTextValidations
        });
      });
      return void 0;
    }
  }
};
export {
  ItemTeamsNameInfoTooltip,
  SimTeamsNameProvider,
  teamsNameSim as default,
  dialogTypes,
  isTeamsNameItemReference,
  useGetTeamsNameItems,
  useHasTeamsNameItems
};

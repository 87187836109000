import type { DSS } from "@vp/types-ddif";
import type { DclMetadataItem, TemplateItem } from "@design-stack-vista/cdif-types";

interface MetadataItemsForImage {
    dclMetadataItem: DclMetadataItem;
    studioMetadataItem: DSS.StudioMetadata;
}

/**
 * Gets the studioMetadata and dclMetadata objects for the given item ID (initializing them if necessary)
 *
 * @note Meant to be used as part of a Command, as it directly mutates the cimDoc
 */
export function metadataItemsForImageId(cimDoc: DSS.DesignDocument, id: string): MetadataItemsForImage {
    // Now retrieve / build the metadata items
    let dclMetadataItem = cimDoc.metadata!.dclMetadata!.find(metadataItem => metadataItem.id === id);
    let studioMetadataItem = cimDoc.metadata!.studioMetadata![id];

    if (!dclMetadataItem) {
        dclMetadataItem = { id };
        cimDoc.metadata!.dclMetadata?.push(dclMetadataItem);
    }
    if (!studioMetadataItem) {
        studioMetadataItem = {};

        cimDoc.metadata!.studioMetadata![id] = studioMetadataItem;
    }

    return {
        dclMetadataItem,
        studioMetadataItem
    };
}

// This is kept separate because we don't always want to initialize templateMetadata for a newly added image
// at time of writing, this is only used to lock an embroidery image when it is first added (to then be unlocked once stitches have finished generating)
/**
 * Gets the template metadata object for the given item ID (initializing it if necessary)
 *
 * @note Meant to be used as part of a Command, as it directly mutates the cimDoc
 */
export function templateMetadataForImageId(cimDoc: DSS.DesignDocument, id: string): TemplateItem {
    let templateMetadataItem = cimDoc.metadata?.template?.find(metadataItem => metadataItem.id === id);
    if (!templateMetadataItem) {
        templateMetadataItem = { id };
        if (!cimDoc.metadata!.template) {
            cimDoc.metadata!.template = [templateMetadataItem];
        } else {
            cimDoc.metadata!.template.push(templateMetadataItem);
        }
    }
    return templateMetadataItem;
}

import { useActiveDialog } from "@internal/utils-active-dialog";
import { useEffect } from "react";
import { RecentColorsManager } from "./RecentColorsManager";

/**
 * Update recent colors when the currentActiveDialog changes,
 * used to handle colors selected in the background color panel
 *
 * @param {RecentColorsManager} recentColorsManager
 */
export const useUpdateRecentColorsOnDialogChange = (recentColorsManager: RecentColorsManager) => {
    const { currentActiveDialog } = useActiveDialog();

    useEffect(() => {
        if (recentColorsManager.lastSelectedColor) {
            recentColorsManager.pushSelectedColor(recentColorsManager.lastSelectedColor);
            recentColorsManager.resetLastSelectedColor();
        }
    }, [currentActiveDialog, recentColorsManager]);
};

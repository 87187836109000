import { action, makeObservable, observable } from "mobx";

class SingleColorContrastModalStore {
    @observable isOpen: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    closeModal() {
        this.isOpen = false;
    }

    @action.bound
    openModal() {
        this.isOpen = true;
    }
}

const singleColorContrastModalStore = new SingleColorContrastModalStore();

export { singleColorContrastModalStore };

/**
 * Converts a content orientation angle string to a number between 0 and 360.
 * Takes a modulo 360 of the input angle to map it into the 0-360 range.
 * If the modulo results in a negative number, 360 is added to make it positive.
 */
export function getContentOrientationAngle(contentOrientation: string): number {
    const parsedNumber = parseFloat(contentOrientation);
    if (isNaN(parsedNumber)) {
        throw new Error("ContentOrientation is not a number");
    }
    const useFullAngle = parsedNumber % 360;
    const positiveAngle = useFullAngle < 0 ? 360 + useFullAngle : useFullAngle;
    return positiveAngle;
}

import { action, computed, makeObservable, observable, toJS } from "mobx";
import { SimInterface } from "./ComposedSim";
import type { DragItemType } from "@internal/feature-drag-and-drop";
import type { ScopedSimName } from "@internal/utils-sim-types";
import {
    contentDiscoverySlotSchema,
    type ActivationWrapper,
    type ContentDiscoverySimSlotNames,
    type ContentDiscoveryZoneFulfiller,
    type ContentDiscoveryZoneSlotMap
} from "@internal/utils-content-discovery-zone";
import type { CreateDragThumbnail, UseHandleDropTarget, UseHandleItemPlaceholderDropTarget } from "./dragAndDrop/types";
import { isSimWithDragAndDrop, isSimWithDragAndDropAndDeferredLoaded } from "./dragAndDrop/isSimWithDragAndDrop";
import {
    isSimWithDiscoveryContentZone,
    isSimWithDiscoveryContentZoneAndDeferredLoaded
} from "./contentDiscoveryZone/isSimWithContentDiscoveryZone";
import type {
    SimContentDiscoveryZoneGraphicsAndShapesCore,
    SimContentDiscoveryZoneGraphicsAndShapesDeferred,
    SimCoreContentDiscoveryZone
} from "./contentDiscoveryZone/types";
import type { DetailZoneFulfiller, DetailZoneStatus, ScreenSize } from "@internal/utils-detail-zone";
import { isSimWithSelectedZone, isSimWithSelectedZoneAndDeferredLoaded } from "./selectedZone/isSimWithSelectedZone";
import { isSimWithItemPreview } from "./itemPreview/isSimWithItemPreview";
import type { CustomItemPreviewFeature, DecorateWithItemPreview } from "./itemPreview/types";
import { BaseSim } from "./BaseSim";
import type { StudioConfigurationManager } from "@internal/utils-studio-configuration-core";
import type { z } from "zod";

type ItemPreviewAPI = DecorateWithItemPreview<BaseSim, CustomItemPreviewFeature>["core"]["itemPreview"];

interface DragAndDropMap {
    useHandleDropTarget?: UseHandleDropTarget<unknown>;
    useHandleItemPlaceholderDropTarget?: UseHandleItemPlaceholderDropTarget<unknown>;
    createDragThumbnail?: CreateDragThumbnail<unknown>;
    isActive: boolean;
}

export class SimsManager
    implements ContentDiscoveryZoneFulfiller<"sim", ScopedSimName>, DetailZoneFulfiller<"sim", ScopedSimName>
{
    @observable
    private _sims: Map<ScopedSimName, ActivationWrapper<SimInterface>>;

    @observable
    studioConfigurationManagerDelegate: StudioConfigurationManager;

    constructor(simSet: Set<SimInterface>, delegate: StudioConfigurationManager) {
        // creates a new Map and initializes all sims to inactive
        this._sims = Array.from(simSet).reduce<typeof this._sims>((acc, sim) => {
            acc.set(sim.name, {
                data: sim,
                active: false
            });
            return acc;
        }, new Map());

        this.studioConfigurationManagerDelegate = delegate;

        makeObservable(this);
    }

    configuration<Schema extends z.AnyZodObject>(simName: ScopedSimName, schema: Schema): z.infer<Schema> {
        return this.studioConfigurationManagerDelegate.simData(simName, schema);
    }

    @action
    addSim(sim: SimInterface, active: boolean = false) {
        this._sims.set(sim.name, {
            data: sim,
            active: active
        });
    }

    @computed
    get allSims() {
        return Array.from(this._sims);
    }

    @computed
    get activeSims() {
        return this.allSims.filter(([, value]) => value.active === true).map(([, value]) => value.data);
    }

    @computed
    get inactiveSims() {
        return this.allSims.filter(([, value]) => value.active === false).map(([, value]) => value.data);
    }

    @action
    setActivation(name: ScopedSimName, active: boolean) {
        const simData = this._sims.get(name);
        if (!simData) {
            return;
        }

        simData.active = active;
        this._sims.set(name, simData);
    }

    @action.bound
    setDeferred(name: string, deferred: NonNullable<SimInterface["deferred"]>) {
        let matchingWrapper: ActivationWrapper<SimInterface> | undefined = undefined;

        for (const wrapper of this._sims.values()) {
            if (wrapper.data.name === name) {
                matchingWrapper = wrapper;
                break;
            }
        }

        if (!matchingWrapper || matchingWrapper.data.deferred) {
            return;
        }
        matchingWrapper.data.deferred = deferred;
    }

    private createNoMatchResult(config: {
        simName: ScopedSimName;
        zone: "contentDiscoveryZone" | "selectedZone";
        activeDialogType: symbol;
        screenSize: ScreenSize;
    }) {
        return {
            fulfiller: "sim",
            status: "noMatch",
            message: `The SIM "${
                config.simName
            }" does not provide a component for the symbol "${config.activeDialogType.toString()} at \`deferred.${
                config.zone
            }.detailZone.${config.screenSize}\`."`
        } as const;
    }

    @computed
    get detailZone() {
        type SimDetailZoneStatus = DetailZoneStatus<"sim", ScopedSimName>;

        type ActiveDialogToSimDetailZoneStatus = Map<symbol, SimDetailZoneStatus>;

        const results: Map<ScreenSize, ActiveDialogToSimDetailZoneStatus> = new Map([
            ["largeScreen", new Map()],
            ["smallScreen", new Map()]
        ]);

        for (const sim of this.activeSims) {
            for (const screenSize of ["largeScreen", "smallScreen"] as const) {
                /**
                 * Handle selected zone content
                 */
                if (isSimWithSelectedZone(sim)) {
                    for (const { activeDialogType } of sim.core.selectedZone.detailZoneIds) {
                        /**
                         * Using the `core.selectedZone.detailZoneIds` as the source of truth,
                         * first iterate through these symbols and create a placeholder entry
                         * at each screen size
                         */

                        results.get(screenSize)!.set(activeDialogType, {
                            fulfiller: "sim",
                            status: "matchButDeferredNotLoaded",
                            moduleName: sim.name
                        });

                        // if the sim's deferred code isn't ready, skip to the next one
                        if (!isSimWithSelectedZoneAndDeferredLoaded(sim)) {
                            continue;
                        }

                        if (!sim.deferred.selectedZone.detailZone) {
                            results.get(screenSize)!.set(
                                activeDialogType,
                                this.createNoMatchResult({
                                    simName: sim.name,
                                    activeDialogType: activeDialogType,
                                    zone: "selectedZone",
                                    screenSize: screenSize
                                })
                            );
                            continue;
                        }

                        const detailZoneFromSelectedZone = sim.deferred.selectedZone.detailZone;

                        const component = detailZoneFromSelectedZone[screenSize]?.get(activeDialogType);

                        if (component) {
                            results.get(screenSize)!.set(activeDialogType, {
                                fulfiller: "sim",
                                status: "match",
                                moduleName: sim.name,
                                component
                            });
                        } else {
                            results.get(screenSize)!.set(
                                activeDialogType,
                                this.createNoMatchResult({
                                    simName: sim.name,
                                    activeDialogType: activeDialogType,
                                    zone: "selectedZone",
                                    screenSize: screenSize
                                })
                            );
                        }
                    }
                }

                /**
                 * Handle content discovery zone content
                 */

                if (isSimWithDiscoveryContentZone(sim)) {
                    for (const key in sim.core.contentDiscoveryZone) {
                        if (!Object.prototype.hasOwnProperty.call(sim.core.contentDiscoveryZone, key)) {
                            continue;
                        }
                        const slotName = key as ContentDiscoverySimSlotNames;

                        // GraphicsAndShapes is handled with a custom method
                        if (slotName === "GraphicsAndShapes") {
                            continue;
                        }

                        const slotValue = sim.core.contentDiscoveryZone[slotName];

                        if (!slotValue) {
                            continue;
                        }

                        // normalize the value to an array to simplify logic below
                        const valuesInSlot = normalizeValueToArray(slotValue);

                        for (const valueInSlot of valuesInSlot) {
                            results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                                fulfiller: "sim",
                                status: "matchButDeferredNotLoaded",
                                moduleName: sim.name
                            });

                            // if the sim's deferred code isn't ready, skip to the next one
                            if (!isSimWithDiscoveryContentZoneAndDeferredLoaded(sim)) {
                                continue;
                            }

                            const detailZoneFromCDZ = sim.deferred.contentDiscoveryZone.detailZone;

                            if (!detailZoneFromCDZ) {
                                results.get(screenSize)!.set(
                                    valueInSlot.activeDialogType,
                                    this.createNoMatchResult({
                                        simName: sim.name,
                                        activeDialogType: valueInSlot.activeDialogType,
                                        zone: "contentDiscoveryZone",
                                        screenSize: screenSize
                                    })
                                );
                                continue;
                            }

                            const component = detailZoneFromCDZ[screenSize]?.get(valueInSlot.activeDialogType);

                            /**
                             * We need to allow SIM CDZ to not always provide a component for every screen size. Thus,
                             * we can "silenty" fail here.
                             */

                            if (component) {
                                results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                                    fulfiller: "sim",
                                    status: "match",
                                    moduleName: sim.name,
                                    component
                                });
                            } else {
                                results.get(screenSize)!.set(
                                    valueInSlot.activeDialogType,
                                    this.createNoMatchResult({
                                        simName: sim.name,
                                        activeDialogType: valueInSlot.activeDialogType,
                                        zone: "contentDiscoveryZone",
                                        screenSize: screenSize
                                    })
                                );
                            }
                        }
                    }
                }
            }
        }

        return results;
    }

    /**
     * Flattens all the sim dragAndDrop data into a single Map
     */
    @computed
    get dragAndDropMap(): Map<DragItemType, DragAndDropMap> {
        return (
            this.allSims
                // removes any of the MobX symbols it attaches
                .map(([, value]) => [toJS(value.data), value.active] as const)
                .reduce((map, [sim, isActive]) => {
                    if (isSimWithDragAndDrop(sim)) {
                        for (const key of Object.getOwnPropertySymbols(sim.core.dragAndDrop)) {
                            const entry: DragAndDropMap = {
                                useHandleDropTarget: sim.core.dragAndDrop[key].useHandleDropTarget,
                                useHandleItemPlaceholderDropTarget:
                                    sim.core.dragAndDrop[key].useHandleItemPlaceholderDropTarget,
                                isActive
                            };
                            map.set(key, entry);
                        }
                    }

                    if (isSimWithDragAndDropAndDeferredLoaded(sim)) {
                        for (const key of Object.getOwnPropertySymbols(sim.deferred.dragAndDrop)) {
                            const entry: DragAndDropMap = {
                                ...map.get(key),
                                createDragThumbnail: sim.deferred.dragAndDrop[key].createDragThumbnail,
                                isActive
                            };
                            map.set(key, entry);
                        }
                    }

                    return map;
                }, new Map<DragItemType, DragAndDropMap>())
        );
    }

    @computed
    get graphicsAndShapes() {
        type GraphicsAndShapeResults =
            | {
                  name: ScopedSimName;
                  isActive: boolean;
                  status: "notReady";
                  value: SimCoreContentDiscoveryZone["GraphicsAndShapes"];
              }
            | {
                  name: ScopedSimName;
                  isActive: boolean;
                  status: "ready";
                  value: Record<
                      symbol,
                      SimContentDiscoveryZoneGraphicsAndShapesCore & SimContentDiscoveryZoneGraphicsAndShapesDeferred
                  >;
              };

        const results: Array<GraphicsAndShapeResults> = [];

        for (const [, simWrapper] of this.allSims) {
            const { active: isActive, data: sim } = simWrapper;

            if (!isSimWithDiscoveryContentZone(sim) || !sim.core.contentDiscoveryZone.GraphicsAndShapes) {
                continue;
            }

            const coreGraphicsPanel = sim.core.contentDiscoveryZone.GraphicsAndShapes;

            if (
                isSimWithDiscoveryContentZoneAndDeferredLoaded(sim) &&
                sim.deferred.contentDiscoveryZone.GraphicsAndShapes
            ) {
                // toJS() strips away any MobX symbols
                const deferredGraphicsPanel = toJS(sim.deferred.contentDiscoveryZone.GraphicsAndShapes);

                const readyConfig: GraphicsAndShapeResults = {
                    name: sim.name,
                    isActive: isActive,
                    status: "ready",
                    value: {}
                };

                for (const symbol of Object.getOwnPropertySymbols(deferredGraphicsPanel)) {
                    readyConfig.value[symbol] = {
                        primaryView: deferredGraphicsPanel[symbol].primaryView,
                        detailView: deferredGraphicsPanel[symbol].detailView,
                        showMoreDescription: deferredGraphicsPanel[symbol].showMoreDescription,
                        searchPlaceholder: deferredGraphicsPanel[symbol].searchPlaceholder,
                        searchButton: deferredGraphicsPanel[symbol].searchButton,
                        panelTitle: coreGraphicsPanel[symbol].panelTitle
                    };
                }

                results.push(readyConfig);
            } else {
                results.push({
                    name: sim.name,
                    isActive: isActive,
                    status: "notReady",
                    value: coreGraphicsPanel
                });
            }
        }

        return results;
    }

    @computed
    get contentDiscoveryZoneSlots() {
        const results: ContentDiscoveryZoneSlotMap<"sim", ScopedSimName> = new Map();

        for (const sim of this.activeSims) {
            if (!isSimWithDiscoveryContentZone(sim)) {
                continue;
            }

            for (const key in sim.core.contentDiscoveryZone) {
                if (!Object.prototype.hasOwnProperty.call(sim.core.contentDiscoveryZone, key)) {
                    continue;
                }
                const slotName = key as ContentDiscoverySimSlotNames;

                if (slotName === "GraphicsAndShapes") {
                    continue;
                }

                const slotSchema = contentDiscoverySlotSchema[slotName];
                const slotValue = sim.core.contentDiscoveryZone[slotName];

                if (!slotValue || !slotSchema) {
                    continue;
                }

                // normalize the value to an array to simplify logic below
                const valuesInSlot = normalizeValueToArray(slotValue);

                switch (slotSchema.type) {
                    case "single": {
                        const existingValueWrapper = results.get(slotName);

                        if (existingValueWrapper) {
                            results.set(slotName, {
                                type: "error",
                                fulfiller: "sim",
                                name: sim.name,
                                value: new Error(`Two or more SIMs are attempting to fulfill the slot "${slotName}"`)
                            });
                        } else {
                            for (const valueInSlot of valuesInSlot) {
                                results.set(slotName, {
                                    type: "single",
                                    fulfiller: "sim",
                                    name: sim.name,
                                    value: valueInSlot
                                });
                            }
                        }

                        break;
                    }
                    case "multiple": {
                        const existingValueWrapper = results.get(slotName);

                        if (!existingValueWrapper) {
                            results.set(slotName, {
                                type: "multiple",
                                fulfiller: "sim",
                                name: sim.name,
                                value: valuesInSlot
                            });
                        } else if (existingValueWrapper.type === "multiple") {
                            results.set(slotName, {
                                type: "multiple",
                                fulfiller: "sim",
                                name: sim.name,
                                value: existingValueWrapper.value.concat(valuesInSlot)
                            });
                        }

                        break;
                    }
                    default: {
                        throw new Error(
                            // @ts-expect-error -- this helps validate that `slotSchema.type` is exhaustive
                            `The SIM CDZ "${sim.name}" has an unhandled slot type "${slotSchema.type}" given the slot name "${slotName}".`
                        );
                    }
                }
            }
        }
        return results;
    }

    @computed
    get customItemPreviews(): ItemPreviewAPI[] {
        return this.activeSims.filter(isSimWithItemPreview).reduce<ItemPreviewAPI[]>((allCustomPreviews, current) => {
            allCustomPreviews.push(current.core.itemPreview);
            return allCustomPreviews;
        }, []);
    }

    @computed
    get symbolsBySlot() {
        const results: Map<ContentDiscoverySimSlotNames, symbol> = new Map();

        for (const sim of this.activeSims) {
            if (isSimWithDiscoveryContentZone(sim)) {
                for (const key in sim.core.contentDiscoveryZone) {
                    if (!Object.prototype.hasOwnProperty.call(sim.core.contentDiscoveryZone, key)) {
                        continue;
                    }
                    const slotName = key as ContentDiscoverySimSlotNames;
                    /**
                     * The SIMs concept of "GraphicsAndShapes" is actually a
                     * child of the Studio CDZ Module. Therefore, we ignore it here
                     * and let Studio CDZ handle it.
                     */
                    if (slotName === "GraphicsAndShapes") {
                        continue;
                    }
                    const slotValue = sim.core.contentDiscoveryZone[slotName];

                    if (!slotValue) {
                        continue;
                    }

                    for (const valueInSlot of normalizeValueToArray(slotValue)) {
                        if (!results.has(slotName)) {
                            results.set(slotName, valueInSlot.activeDialogType);
                        }
                    }
                }
            }
        }

        return results;
    }
}

const normalizeValueToArray = <T extends {}>(data: T | T[]): T[] => {
    if (Array.isArray(data)) {
        return data;
    }
    return [data];
};

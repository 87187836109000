// Using this as a default values, as found this to have a balance of contrast when tested with images.
// And also this was also the default values used in S5
const DEFAULT_THRESHOLD = 75;
const DEFAULT_INVERSION = false;

export const getDefaultThreshold = () => {
    return DEFAULT_THRESHOLD;
};

export const getDefaultInversion = () => {
    return DEFAULT_INVERSION;
};

import { dialogTypes } from "../dialogTypes.js";
import { qrCodeStore } from "../extensions/qrCodeStore.js";
import "@design-stack-vista/utility-core";
import "@internal/utils-color";
import "../shared/utils/validation.js";
import { isQrCodeTypeReference, isImageQRPlaceholder } from "../shared/utils/qrCodeTypeGuard.js";
import "../shared/utils/upgradeLegacyQRCode.js";
import { QRCodeTabType, QRCodeURLPanelMode } from "../shared/types/qrCodeItemRef.js";
function openContentPanelOnItemSelect({
  firstSelectedItem,
  designEngine
}) {
  if (isQrCodeTypeReference(firstSelectedItem)) {
    qrCodeStore.setCurrentTab(QRCodeTabType.Shape);
    qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Edit);
    return dialogTypes.qrcode;
  }
  const isSelectedItemQRCodePlaceholder = firstSelectedItem.isImageItem() && isImageQRPlaceholder(firstSelectedItem, designEngine);
  if (isSelectedItemQRCodePlaceholder) {
    qrCodeStore.setCurrentTab(QRCodeTabType.Url);
    qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Add);
    return dialogTypes.qrcode;
  }
  qrCodeStore.setCurrentTab(QRCodeTabType.Url);
  qrCodeStore.setURLPanelMode(QRCodeURLPanelMode.Add);
  return;
}
export {
  openContentPanelOnItemSelect
};

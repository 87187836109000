import { isSherbertAssetUrl } from "@design-stack-ct/assets-sdk";
import type { DSS } from "@vp/types-ddif";

/**
 * Remove images with preview/print urls that are missing a signature.
 * This is for sherbert assets only.
 * Fixes an issue that causes renders and orders to fail. User would never have
 * seen these images on the design anyway so we are removing from them from the cimdoc.
 */
export function removeImagesWithoutSignatures(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.images) {
            panel.images = panel.images.filter(image => {
                if (image.previewUrl && isSherbertAssetUrl(image.previewUrl)) {
                    const previewUrl = new URL(image.previewUrl);
                    const previewUrlHasSignature = Boolean(previewUrl.searchParams.get("signature"));
                    if (!previewUrlHasSignature) {
                        return false;
                    }
                }
                if (image.printUrl && isSherbertAssetUrl(image.printUrl)) {
                    const printUrl = new URL(image.printUrl);
                    const printUrlHasSignature = Boolean(printUrl.searchParams.get("signature"));
                    if (!printUrlHasSignature) {
                        return false;
                    }
                }
                return true;
            });
        }
    });
}

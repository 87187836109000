import type { BaseSim } from "../BaseSim";
import { DecorateWithDiscoveryZone } from "./types";

type DeferredLoaded = Omit<DecorateWithDiscoveryZone<BaseSim>, "deferred"> & {
    deferred: NonNullable<DecorateWithDiscoveryZone<BaseSim>["deferred"]>;
};

export const isSimWithDiscoveryContentZone = (sim: BaseSim): sim is DecorateWithDiscoveryZone<BaseSim> => {
    return "contentDiscoveryZone" in sim.core;
};

export const isSimWithDiscoveryContentZoneAndDeferredLoaded = (sim: BaseSim): sim is DeferredLoaded => {
    return isSimWithDiscoveryContentZone(sim) && Boolean(sim.deferred);
};

import { FigureItem } from "@design-stack-vista/cdif-types";
import type { Path, Point } from "@design-stack-vista/interactive-design-engine-core";

// TODO: extend the ellipse function to accept not circular ellipses (width != height)
interface ConvertEllipseToPathParams {
    x: number;
    y: number;
    width: number;
}

interface ConvertRectangleToPathParams extends ConvertEllipseToPathParams {
    height: number;
    cornerRadius: number;
}

// Define the constant for a cubic bezier curve (~0.5522847498307933)
export const CUBIC_BEZIER_CONSTANT = (4 * (Math.SQRT2 - 1)) / 3;

export const convertFigureItemToPath = (figureItem: FigureItem): Path => {
    // Convert all figureItem position values into floating point numbers
    const { x, y, width, height } = Object.fromEntries(
        Object.entries(figureItem.position).map(([key, value]) => [key, parseFloat(value)])
    );

    if (figureItem.type === "ellipse") {
        return convertEllipseToPath({ x, y, width });
    }

    return convertRectangleToPath({
        x,
        y,
        width,
        height,
        cornerRadius: figureItem.cornerRadius ? parseFloat(figureItem.cornerRadius) : 0
    });
};

/**
 * Currently this function only supports converting a circle i.e. an ellipse where width === height
 * This may need to be extended in the future but for the purposes of the v1 cutline sticker experience this is all we need
 */
const convertEllipseToPath = ({ x, y, width }: ConvertEllipseToPathParams): Path => {
    const height = width;
    const radius = width / 2;
    const offset = CUBIC_BEZIER_CONSTANT * radius;

    /**
     * Define the four path points which, if normalized to be centered at (0,0) and have radius 1
     * correspond to (1,0), (1,1), (-1,0), (-1,-1).
     *
     * (x,y) are the coordinate for the top-left corner of the bounding box.
     */
    const c1 = {
        x: x + width,
        y: y + radius,
        controlPoints: {
            first: {
                x: x + radius + offset,
                y
            },
            second: {
                x: x + width,
                y: y + radius - offset
            }
        }
    };

    const c2 = {
        x: x + radius,
        y: y + height,
        controlPoints: {
            first: {
                x: x + width,
                y: y + radius + offset
            },
            second: {
                x: x + radius + offset,
                y: y + height
            }
        }
    };

    const c3 = {
        x,
        y: y + radius,
        controlPoints: {
            first: {
                x: x + radius - offset,
                y: y + height
            },
            second: {
                x,
                y: y + radius + offset
            }
        }
    };

    const c4 = {
        x: x + radius,
        y,
        controlPoints: {
            first: {
                x,
                y: y + radius - offset
            },
            second: {
                x: x + radius - offset,
                y
            }
        }
    };

    return { anchor: { x: x + radius, y }, points: [c1, c2, c3, c4] };
};

const convertRectangleToPath = ({ x, y, width, height, cornerRadius }: ConvertRectangleToPathParams): Path => {
    if (cornerRadius === 0) {
        return {
            anchor: { x, y },
            points: [
                { x: x + width, y },
                { x: x + width, y: y + height },
                { x, y: y + height }
            ]
        };
    }

    const offset = CUBIC_BEZIER_CONSTANT * cornerRadius;

    // Bottom left corner
    const c1: Point = {
        x: x + cornerRadius,
        y,
        controlPoints: {
            first: {
                x,
                y: y + cornerRadius - offset
            },
            second: {
                x: x + cornerRadius - offset,
                y
            }
        }
    };

    // Bottom edge
    const e1: Point = {
        x: x + width - cornerRadius,
        y
    };

    // Bottom right corner
    const c2: Point = {
        x: x + width,
        y: y + cornerRadius,
        controlPoints: {
            first: {
                x: x + width - offset,
                y
            },
            second: {
                x: x + width,
                y: y + offset
            }
        }
    };

    // Right edge
    const e2: Point = {
        x: x + width,
        y: y + height - cornerRadius
    };

    // Top right corner
    const c3: Point = {
        x: x + width - cornerRadius,
        y: y + height,
        controlPoints: {
            first: {
                x: x + width,
                y: y + height - offset
            },
            second: {
                x: x + width - offset,
                y: y + height
            }
        }
    };

    // Top edge
    const e3: Point = {
        x: x + cornerRadius,
        y: y + height
    };

    // Top right corner
    const c4: Point = {
        x,
        y: y + height - cornerRadius,
        controlPoints: {
            first: {
                x: x + cornerRadius - offset,
                y: y + height
            },
            second: {
                x,
                y: y + height - offset
            }
        }
    };

    return {
        anchor: {
            x,
            y: y + cornerRadius
        },
        points: [c1, e1, c2, e2, c3, e3, c4]
    };
};

import { jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { addPrototypeSimItem } from "../addPrototypeSimItem.js";
const PrototypeDraggableValue = /* @__PURE__ */ Symbol("MarvelousDoodad");
const useHandleDropTarget = () => {
  const designEngine = /* @__PURE__ */ useDesignEngine();
  return useCallback((config) => {
    const {
      panelId,
      position
    } = config;
    const {
      commandOutput
    } = /* @__PURE__ */ designEngine.executeCommand(addPrototypeSimItem, {
      content: "prototype sim, after drag and drop",
      position: {
        x: `${position.x}mm`,
        y: `${position.y}mm`,
        height: "10mm",
        width: "50mm"
      },
      panelId
    });
    designEngine.idaStore.setSelectedIds([commandOutput.id]);
  }, [designEngine]);
};
const createDragThumbnail = (item) => {
  const {
    url,
    width,
    height
  } = item;
  return /* @__PURE__ */ jsx("img", {
    style: {
      width,
      height,
      transform: `translate(-50%,-50%)`
    },
    alt: "",
    src: url
  });
};
export {
  PrototypeDraggableValue,
  createDragThumbnail,
  useHandleDropTarget
};

import { observer } from "mobx-react-lite";
import React, { createContext } from "react";
import { RecentColorsManager } from "./RecentColorsManager";
import { useInitializeRecentColorsManager } from "./useInitializeRecentColorsManager";
import { useUpdateRecentColorsOnDialogChange } from "./useUpdateRecentColorsOnDialogChange";
import { useUpdateRecentColorsOnPanelChange } from "./useUpdateRecentColorsOnPanelChange";
import { useUpdateRecentColorsOnSelectionChange } from "./useUpdateRecentColorsOnSelectionChange";

export const RecentColorsContext = createContext<RecentColorsManager | undefined>(undefined);

export const RecentColorsProvider = observer((props: React.PropsWithChildren<{}>) => {
    const recentColorsManager = useInitializeRecentColorsManager();
    useUpdateRecentColorsOnSelectionChange(recentColorsManager);
    useUpdateRecentColorsOnDialogChange(recentColorsManager);
    useUpdateRecentColorsOnPanelChange(recentColorsManager);

    return <RecentColorsContext.Provider value={recentColorsManager}>{props.children}</RecentColorsContext.Provider>;
});

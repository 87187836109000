import { useActivePanel } from "@design-stack-vista/core-features";
import { CMYK, hex2rgb, HSV, RGB } from "@design-stack-vista/utility-core";
import { useRecentColors } from "@internal/feature-recent-colors";
import { useCallback, useEffect, useState } from "react";
import { useColorPaletteForDecorationTechnology } from "@internal/feature-color-palette";
import {
    ColorSpace,
    colorToString,
    calculateColorValues,
    convertToSelectableColor,
    defaultStateColorObject,
    isHsv,
    NO_COLOR_VALUE,
    parseColor,
    StateColorObject
} from "@internal/utils-color";

interface ParseAndCalculateColorResult {
    colorObj: StateColorObject;
    colorSpace?: ColorSpace;
}

function parseAndCalculateColor(color: string | undefined): ParseAndCalculateColorResult {
    if (!color) {
        return { colorObj: defaultStateColorObject };
    }

    const parsedColor = parseColor(color);
    if (!parsedColor) {
        return { colorObj: defaultStateColorObject };
    }

    return {
        colorObj: calculateColorValues(parsedColor.value, parsedColor.colorSpace),
        colorSpace: parsedColor.colorSpace
    };
}
export function useSelectedItemColors(color: string | undefined) {
    const { setLastSelectedColor, resetLastSelectedColor } = useRecentColors();
    const [currentColorValues, setCurrentColorValues] = useState<StateColorObject>(
        () => parseAndCalculateColor(color).colorObj
    );
    const { activePanel } = useActivePanel();
    const colorPalette = useColorPaletteForDecorationTechnology(
        activePanel?.panelProperties.decorationTechnology ?? ""
    );

    // The value selected in the color picker can sometimes change when it is actually applied to the item.
    // The conversion between color formats may be lossy, or a 3-digit hex may have been expanded to 6
    // We need to be careful updating the colorpicker directly while the user is interacting with it because sliders may jump, or inputs could change, and thats confusing
    // This effect tries to only update the picker if the value has really changed.  The user selected a new item with the colorpicker open, or used undo/redo
    // If the change in color was not due to these (but the lossy format conversion for example) we would expect one of the formats to match and we'll skip the update
    useEffect(() => {
        if (color) {
            const { colorObj: newColor, colorSpace } = parseAndCalculateColor(color);

            if (colorSpace === ColorSpace.Thread && newColor.other !== currentColorValues.other) {
                setCurrentColorValues(newColor);
            } else if (
                newColor.cmyk !== currentColorValues.cmyk &&
                newColor.rgb !== currentColorValues.rgb &&
                colorToString(hex2rgb(newColor.hex)) !== colorToString(hex2rgb(currentColorValues.hex)) &&
                newColor.hsv !== currentColorValues.hsv &&
                (newColor.other !== currentColorValues.other || newColor.other === null)
            ) {
                setCurrentColorValues(newColor);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- I don't want to rerun this when currentColorValues changes
    }, [color]);

    const getCdifColor = useCallback(
        (newColor: string | RGB | CMYK | HSV, colorSpace: ColorSpace): string => {
            const color = newColor || "";
            const calculatedColorValues = calculateColorValues(color, colorSpace);
            setCurrentColorValues(calculatedColorValues);

            // We need to set the last selected color so it can be tracked as a 'recent' color
            // We don't want to track 'no color'.  We also don't bother tracking hex values less than 6
            // The reason for the last bit is a bit lost to me but its parity with studio5
            if (
                colorSpace !== ColorSpace.NoColor &&
                !(colorSpace === ColorSpace.HEX && (newColor as string).length < 6)
            ) {
                // 'other' colors are stuff like threads and spot colors.  They get special treatment because they shouldn't be converted to cmyk/rgb
                // and the displayed color (css background) needs to be provided along with the raw color
                // otherwise we either store the cmyk value or if its not cmyk, we store an rgb value
                // We only store rgb/cmyk in documents so there's no reason to store any other type of value (hsv) as a recent color
                // It would only cause confusion because duplicate colors might show up
                setLastSelectedColor(
                    calculatedColorValues.other
                        ? convertToSelectableColor(
                              calculatedColorValues.other,
                              colorPalette?.fromCdif(calculatedColorValues.other)?.cssBackground
                          )
                        : convertToSelectableColor(
                              colorSpace === ColorSpace.CMYK ? calculatedColorValues.cmyk : calculatedColorValues.rgb
                          )
                );
            }

            // A little kludgy right now, but if its no color then return this constant directly.
            // otherwise we get extra spaces in the value and the direct comparison fails
            if (colorSpace === ColorSpace.NoColor) {
                resetLastSelectedColor();
                return NO_COLOR_VALUE;
            }

            return colorToString(
                isHsv(newColor) || colorSpace === ColorSpace.HEX ? calculatedColorValues.rgb : newColor
            );
        },
        [colorPalette, resetLastSelectedColor, setLastSelectedColor]
    );

    return {
        currentColorValues,
        setCurrentColorValues,
        getCdifColor,
        colorPalette
    };
}

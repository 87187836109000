import React from "react";
import { CartContextProvider } from "@internal/utils-cart";
import { useAppSelector } from "@shared/redux";
import { useIsQuantityPageEnabled } from "@shared/features/StudioBootstrap";

export const CartContextProviderWrapper = ({ children }: React.PropsWithChildren) => {
    const isQuantityPageEnabled = useIsQuantityPageEnabled();

    return (
        <CartContextProvider useAppSelector={useAppSelector} isQuantityPageEnabled={!!isQuantityPageEnabled}>
            {children}
        </CartContextProvider>
    );
};

import React from "react";
import classnames from "classnames";
import { FlexBox } from "@vp/swan";
import styles from "./ErrorIcon.module.scss";

export interface ErrorIconProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Type of icon to show.  Example icons are provided in the ErrorIconTypes object
     */
    Icon: React.ReactNode;
    /**
     * Action when the user clicks on the error logo.  Generally used to show additional details
     */
    handleErrorLogoClick: () => void;
}

export const ErrorIcon = ({ Icon, handleErrorLogoClick, className, ...rest }: ErrorIconProps) => {
    return (
        <FlexBox
            className={classnames(styles.errorIconContainer, className)}
            flexDirection="column"
            alignItems="center"
            {...rest}
        >
            <button data-testid="logobutton" className={styles.errorIcon} type="button" onClick={handleErrorLogoClick}>
                {Icon}
            </button>
        </FlexBox>
    );
};

ErrorIcon.displayName = "ErrorIcon";

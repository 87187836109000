import React from "react";
import type { SimInterface } from "@internal/sim-framework";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { BackgroundColorIcon } from "@internal/ui-studio-chrome";
import { BackgroundColorDialogTypes } from "../BackgroundColorDialogTypes";

const messages = defineMessages({
    tablesTitle: {
        id: "editorUI.addContent.drawer.backgroundColorTitle.title",
        defaultMessage: "Background",
        description: {
            note: "Content Panel title text for changing background color"
        }
    }
});

const CDZButton: ContentDiscoveryZoneButton = ({ screenSize, children }) => {
    const { t } = useTranslationSSR();

    return children({
        icon: <BackgroundColorIcon aria-hidden="true" />,
        label: t(messages.tablesTitle.id)
    });
};

export const legacyBackgroundSim: SimInterface<"ContentDiscoveryZone"> = {
    name: "@internal/advanced-editor-sim-background-legacy",
    core: {
        extensions: [],
        contentDiscoveryZone: {
            Background: {
                activeDialogType: BackgroundColorDialogTypes.backgroundColor,
                component: CDZButton
            }
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import { DSS, DTR } from "@vp/types-ddif";

/**
 * Moves the ID from either a textField or content field to the parent textArea.
 * @note textFields are not part of the cimdoc spec but can occur on older documents or templates,
 * so to be safe we check for both
 * @param designDocument
 */
export function updateTextAreaIDs(designDocument: DTR.EditDocV2 | DSS.DesignDocument) {
    designDocument.document.panels.forEach((panel): void => {
        panel.textAreas?.forEach((textArea): void => {
            if ("textFields" in textArea) {
                // If there are no textFields or the first textField does not have an ID, return
                // This should never happen if the content is valid
                if ((textArea.textFields.length && textArea.textFields.length < 1) || !textArea.textFields[0].id) {
                    return;
                }

                // Set the textArea ID to the first textField's ID
                if (!textArea.id) {
                    if (textArea.textFields.length > 0) {
                        textArea.id = textArea.textFields[0].id;

                        // Remove the ID from the textFields
                        textArea.textFields.forEach((textField): void => {
                            delete textField.id;
                        });
                    }
                }
            }

            if ("content" in textArea) {
                // If there is no content or the first content does not have an ID, return
                if (
                    (textArea.content?.length && textArea.content.length < 1) ||
                    (textArea.content && !("id" in textArea.content[0]))
                ) {
                    return;
                }

                // Set the textArea ID to the first content's ID
                if (!textArea.id) {
                    if (textArea.content.length > 0) {
                        if ("id" in textArea.content[0]) {
                            textArea.id = textArea.content[0].id as string;
                        }

                        // Remove the ID from the content
                        textArea.content.forEach((content): void => {
                            if ("id" in content) {
                                delete content.id;
                            }
                        });
                    }
                }
            }
        });
    });
}

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import type { DSS, DTeC } from "@vp/types-ddif";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { getQueryParams } from "@internal/utils-browser";
import type { PanelSectionData } from "@internal/feature-panel-sections";
import type {
    DesignExperienceManagementState,
    ProductGroupConfiguration
} from "@internal/utils-studio-configuration-core";
import { getCountry } from "@internal/utils-i18n";
import type {
    CompatibleOptionsEntry,
    ProductAttributesKeyAndValues,
    ProductOption
} from "@internal/data-access-product";
import type { CalciferSceneConfiguration, TemplateColorVariation } from "../types";

// Interfaces for return values are taken from the source code
// to studio-calcifer

const STUDIO_CALCIFER_URL = "https://cdn.studio-calcifer.design.vpsvc.com";
const host = getQueryParams().calciferUrl || STUDIO_CALCIFER_URL;
const { templateLocale } = getQueryParams();
const MERCHANT_ID = "designtechprod";
const entityCode = 40;

function removeNullQueryStringParams<T extends {}>(params: T): Partial<T> {
    const newParams = params;
    Object.keys(newParams).forEach(key =>
        // @ts-ignore FIXME: must handle implicit `any` type
        newParams[key] === undefined || newParams[key] === null ? delete newParams[key] : {}
    );
    return newParams;
}

interface DesignViews {
    designViews: DSS.DesignView[];
}

export interface StudioConfig {
    mpvId: string | null;
    productVersion: number;
    productName: string;
    productCategory: string | null;
    quantity: number;
    selectedOptions: Record<string, string>;
    customerSelectedOptions: Record<string, string>;
    productKey: string | null;
    scenesConfiguration: CalciferSceneConfiguration;
    designViews: DesignViews;
    designDocument: DSS.DesignDocument;
    warnings: string[];
    studioConfiguration: DesignExperienceManagementState;
    productGroupConfiguration: ProductGroupConfiguration;
    mcpSku: string;
    mcpVersion: number;
    designs: number;
    panelSectionSets: PanelSectionData[] | null;
    isTeamsProduct: boolean;
}

export async function getStudioConfigFromCalciferV2(
    productKey: string | undefined | null,
    mpvId: string | undefined | null,
    productVersion: number | undefined | null,
    customerSelectedProductOptions: Record<string, string> | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    qty: number | undefined,
    locale: string,
    merchandisingMpvUrl: string | undefined | null,
    isFullBleed: boolean,
    template: string | undefined | null,
    documentRevisionUrl?: string | undefined | null,
    allowDynamicUnderlayScene?: boolean | undefined
): Promise<StudioConfig> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        mpvId,
        productVersion,
        selectedOptions: JSON.stringify(customerSelectedProductOptions),
        studioSelectedProductOptions: JSON.stringify(studioSelectedProductOptions),
        qty: qty ? qty.toString() : null,
        market: getCountry(locale),
        locale,
        merchandisingMpvUrl,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : template,
        templateLocale,
        documentUrl: documentRevisionUrl,
        // if this is false then we shouldn't bother including it as it screws up caches and tests
        // this is only meant for overrides
        allowDynamicUnderlayScene: allowDynamicUnderlayScene || undefined
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/initializedStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getStudioConfigFromCalciferV2",
        friendlyDescription: "retrieve studio configuration from calcifer",
        entityCode
    });

    return initializedConfig;
}

export interface SurfaceUpsell {
    panelName: string;
    panelId: string;
    optionName: string;
    blankOption?: string;
    colorOption?: string;
    templateToken?: string;
    ensemblePanelType?: string;
}

export type TemplateInfo = DTeC.EnsembleTemplateDto & { panelName?: string };

export interface StudioSecondaryConfig {
    templates: TemplateInfo[];
    surfaceUpsells?: SurfaceUpsell[];
    warnings: string[];
    designVariations: any[];
    templateColorVariations: TemplateColorVariation[];
    templateAvailableFinishes: DTeC.FinishType[];
    primaryTemplateToken: string;
    isQuantityPageEnabled: boolean;
    designAttributes: ProductOption[];
    compatibleOptions: CompatibleOptionsEntry[];
    productAttributeAndValues: ProductAttributesKeyAndValues[];
}

export async function getSecondaryConfigFromCalciferV2(
    productKey: string,
    productVersion: string | number | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    locale: string,
    isFullBleed: boolean,
    template: string | undefined | null
): Promise<StudioSecondaryConfig> {
    if (!productKey || !productVersion || !locale) {
        throw new Error("Cannot fetch secondary Calcifer config without a productKey, productVersion, or locale.");
    }

    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions: JSON.stringify(studioSelectedProductOptions),
        locale,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : template,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/secondaryStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getSecondaryConfigFromCalciferV2",
        friendlyDescription: "retrieve secondary studio configuration from calcifer",
        entityCode
    });
    return initializedConfig;
}

interface ProductEntry {
    name: string;
    coreProductId: string;
    mpvId: string;
}

export async function getProductsFromCalcifer(locale: string): Promise<ProductEntry[]> {
    const requestParams = removeNullQueryStringParams({
        locale,
        requestor: REQUESTOR,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/products/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductsFromCalcifer",
        friendlyDescription: "retrieve the list of valid products for a locale from calcifer",
        entityCode
    });

    return initializedConfig;
}

interface ProductDefaults {
    productKey: string;
    productVersion: any;
    selectedOptions: Record<string, string>;
    quantity: number;
}

export async function getProductDefaultsFromCalcifer(productKey: string, locale: string): Promise<ProductDefaults> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        locale,
        requestor: REQUESTOR,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productDefaults/?${queryString}`;
    const defaults = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductDefaultsFromCalcifer",
        friendlyDescription: "retrieve a set of product defaults for a product & locale from calcifer",
        entityCode
    });

    return defaults;
}

interface CompatibleColorsWithStockInfo {
    [key: string]: {
        primaryColor: string;
        secondaryColor?: string;
        title: string;
        disabled: boolean;
    };
}

export async function getSubstrateColorsWithStockInfoFromCalciferV2(
    mpvId: string,
    locale: string,
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string> | undefined,
    productOptionName: string
): Promise<CompatibleColorsWithStockInfo> {
    const requestParams = removeNullQueryStringParams({
        mpvId,
        locale,
        productKey,
        productVersion,
        selectedOptions,
        requestor: REQUESTOR,
        templateLocale,
        productOptionName
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/substrateColorsWithStockInfo?${queryString}`;
    const substrateColorsWithStockInfo = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getSubstrateColorsWithStockInfoFromCalciferV2",
        friendlyDescription: "retrieve substrate colors with stock info for a mpv",
        entityCode
    });

    return substrateColorsWithStockInfo;
}

export interface TaxAwarePrice {
    taxed: number;
    untaxed: number;
}

interface PriceBreakdownEntry {
    name: string;
    value: string;
    listPrice: TaxAwarePrice;
    discountPrice: TaxAwarePrice;
}

interface PriceValuesWithBreakdown {
    totalListPrice: TaxAwarePrice;
    totalDiscountedPrice: TaxAwarePrice;
    unitListPrice: TaxAwarePrice;
    unitDiscountedPrice: TaxAwarePrice;
    baseListPrice: TaxAwarePrice;
    baseDiscountPrice: TaxAwarePrice;
    breakdown?: PriceBreakdownEntry[];
    baseAmountSaved?: TaxAwarePrice;
    basePercentageOff?: number;
    totalAmountSaved?: TaxAwarePrice;
    totalPercentageOff?: number;
    taxRate?: number;
    additionalFees?: any;
    shippingPriceStatus: "NotRequested" | "Success" | "NotFound" | "UnknownIssue" | "Misconfigured";
    shippingListPrice?: TaxAwarePrice;
    shippingDiscountedPrice?: TaxAwarePrice;
    freeShippingDetails?: {
        orderThreshold: number;
        amountToFree: number;
        includesTax: boolean;
    };
    shippingDiscountSources?: any[];
}

export interface PriceQuantityData {
    currency: string;
    fractionDigits: number;
    estimatedPrices: {
        [quantity: string]: PriceValuesWithBreakdown;
    };
}

export interface ProductVersion {
    version: number;
}

export async function getProductQuantityWithPricing(
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string> | undefined,
    locale: string
): Promise<PriceQuantityData> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions,
        market: getCountry(locale),
        merchantId: MERCHANT_ID
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productQuantityWithPricing?${queryString}`;
    const productQuantitiesWithPrices = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductQuantityWithPricingFromCalciferV2",
        friendlyDescription: "retrieve product quantities with estimated price, discount",
        entityCode
    });

    return productQuantitiesWithPrices;
}

export async function getProductCurrentVersion(productKey: string): Promise<ProductVersion> {
    const requestParams = removeNullQueryStringParams({
        productKey
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productCurrentVersion?${queryString}`;
    const productVersion = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getProductCurrentVersion",
        friendlyDescription: "retrieve product current version",
        entityCode
    });

    return productVersion;
}

export async function getProductCompatibleOptions(
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>
): Promise<CompatibleOptionsEntry[]> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/productCompatibleOptions?${queryString}`;
    return tryFetch<CompatibleOptionsEntry[]>({
        url,
        moduleFunction: "studioCalciferClient:getProductCompatibleOptions",
        friendlyDescription: "retrieve product compatible options",
        entityCode
    });
}

import * as React from "react";
import { SVGProps } from "react";

export const LightBlueXOIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 182 209" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
        <g opacity={0.1} filter="url(#a)">
            <path
                d="M171.5 191.8c0 3.6-36 6.5-80.5 6.5s-80.5-3-80.5-6.5c0-3.6 36-6.5 80.5-6.5s80.5 3 80.5 6.5Z"
                fill="#000"
            />
        </g>
        <path
            d="M7.5 43.3c0-1.1.9-2 2-2h144.3v18.9c0 1.1.8 2 2 2h18.7v85.1a2 2 0 0 1-2 2H9.5a2 2 0 0 1-2-2v-104Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 43.8v103h162V64.7h-16.2c-2.5 0-4.5-2-4.5-4.5V43.8H10Zm146.3 3.6 12.2 12.3h-12.2V47.4ZM5 43.3c0-2.5 2-4.5 4.5-4.5h144.3c.6 0 1.3.3 1.7.7l20.8 21c.4.4.7 1 .7 1.7v85.1c0 2.5-2 4.5-4.5 4.5H9.5c-2.5 0-4.5-2-4.5-4.5v-104Z"
            fill="#6A7076"
        />
        <path
            className="color-element"
            fillRule="evenodd"
            clipRule="evenodd"
            d="m68.2 85.4-3.6-3.6-3.5 3.6-3.5-3.6-3.6 3.6 3.6 3.5-3.6 3.5 3.6 3.6 3.5-3.6 3.5 3.6 3.6-3.6-3.6-3.5 3.6-3.5Zm13.3 28.4v-2.5 2.5a2.5 2.5 0 1 1 0-5v2.5-2.5h22a2.5 2.5 0 0 1 0 5h-22Zm48-24.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-7 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
            fill="#36B7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114 3.4a2 2 0 0 1-2 2c-15 0-22.5.8-31.8 2.3l5.6.5a2635 2635 0 0 1 16 1.8 41 41 0 0 1 7 1.6c.4.2 1 .5 1.3 1 .5.5.9 1.3.7 2.2a3 3 0 0 1-1 1.7c-.8.6-1.9 1-3 1.5a152 152 0 0 1-24 4.7 131.3 131.3 0 0 0 6.7 1.6l6 1.4a2 2 0 1 1-1.2 3.8 85 85 0 0 0-5.7-1.3L88 28a105.8 105.8 0 0 1-10.4-2.9l-1.2-.9c-.4-.4-.9-1.2-.7-2.2.3-1 .9-1.5 1.3-1.7.5-.3 1-.6 1.5-.7 1.1-.4 2.7-.7 4.8-1 9-1.3 17-2.8 21.3-4.1l-3.3-.6a361.6 361.6 0 0 0-15.9-1.7A413.5 413.5 0 0 1 72 10.6l-1.5-.3c-.3-.2-1-.4-1.4-.8a2.2 2.2 0 0 1 0-3.2c.4-.5 1-.7 1.5-.9a191.5 191.5 0 0 1 41.6-4 2 2 0 0 1 2 2Zm-6.9 10ZM79.2 23.6ZM71.5 6.4Zm0 2.9Z"
            fill="#6A7076"
        />
        <defs>
            <filter
                id="a"
                x={0.5}
                y={175.3}
                width={181}
                height={33}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur" />
            </filter>
        </defs>
    </svg>
);

LightBlueXOIcon.displayName = "LightBlueXOIcon";

import type { ScopedSimName } from "@internal/utils-sim-types";
import type { z } from "zod";

type Cache<T extends Record<ScopedSimName, z.AnyZodObject>> = {
    [K in keyof T]: T[K] extends z.AnyZodObject ? z.infer<T[K]> : never;
};

type InferredSchema<Key, R> = Key extends keyof R ? (R[Key] extends z.AnyZodObject ? R[Key] : never) : never;
type InferredType<Key, R, Result = InferredSchema<Key, R>> = Result extends z.AnyZodObject ? z.infer<Result> : never;

/**
 * A utility class for caching sim configuration results
 */
export class SimConfigurationCache<T extends Record<ScopedSimName, z.AnyZodObject>> {
    private cache: Partial<Cache<T>> = {};
    private validated: Partial<Record<keyof T, boolean>> = {};

    set<Key extends keyof T = keyof T, V extends InferredType<Key, T> = InferredType<Key, T>>(simName: Key, value: V) {
        this.cache[simName] = value;
        this.validated[simName] = false;
    }

    get<Key extends keyof T = keyof T, S extends z.AnyZodObject = InferredSchema<Key, T>>(
        simName: Key,
        schema: S
    ): z.infer<S> {
        const cachedResult = this.cache[simName];

        if (cachedResult && this.validated[simName]) {
            return cachedResult;
        }

        const result = schema.parse(this.cache[simName]);

        this.validated[simName] = true;

        return result;
    }
}

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { getCountry } from "@internal/utils-i18n";
import type { WorkEntity } from "@internal/data-access-work-entity-service";

function getTenant(locale: string) {
    return `VP-${getCountry(locale)}-PROD`;
}

const host = "https://vistacart.orders.vpsvc.com";
const entityCode = 71;

export enum CartVersion {
    CART_V1 = 1,
    CART_V2 = 2
}

// These interfaces are incomplete, but contain everything Studio actually uses.
export interface CartItems {
    lineItems: LineItem[];
}

export interface LineItem {
    correlationId: string;
    designData: {
        editDocumentUrl: string;
    };
    customOrderData: {
        fulfillment: {
            workId: string;
        };
        analytics: {
            workId: string;
            product_id: string;
        };
        modData: {
            AccessoryRelationship?: {
                ParentWorkEntityId: string;
            };
        };
    };
    merchandisingData: {
        editOptionsUrl: string;
    };
    quantity: number;
    version: CartVersion;
}

function buildDocRefUrl(work: WorkEntity) {
    if (!work.design.docRefUrl) {
        // derived from how WATCH originally built a documentReferenceUrl
        // https://gitlab.com/vistaprint-org/design-technology/work-add-to-cart-handler/-/blob/master/src/Domain/Cart/CartLineItemBuilder.cs#L92
        const initialUrl = work.design.designUrl.replace(
            "uds.documents.cimpress.io/v0",
            "uds.documents.cimpress.io/v3"
        );
        const url = new URL(initialUrl);
        url.pathname = url.pathname.replace("/+", "/");
        url.pathname = url.pathname.endsWith("/") ? url.pathname : `${url.pathname}/docref`;
        return url.toString();
    }
    return work.design.docRefUrl;
}

export const hasRelatedAccessoryInCart = (lineItems: LineItem[] | undefined, workId: string) => {
    const hasRelatedAccessoryInCart = lineItems?.find(
        (lineItem: LineItem) => lineItem.customOrderData.modData?.AccessoryRelationship?.ParentWorkEntityId === workId
    );

    return !!hasRelatedAccessoryInCart;
};

export const getItemInCart = (lineItems: LineItem[] | undefined, workId: string) => {
    const itemFromFulfillment = lineItems?.find(
        (item: LineItem) => item.customOrderData?.fulfillment?.workId === workId
    );
    const itemFromAnalytics = lineItems?.find((item: LineItem) => item.customOrderData?.analytics?.workId === workId);
    const itemFromEditDocumentUrl = lineItems?.find((item: LineItem) => {
        // example editDocumentUrl: "/studio/?workId=<WORKID>&editSource=cart"
        const splitUrl = item.designData?.editDocumentUrl?.split("?");

        if (splitUrl && splitUrl.length > 1) {
            const parsed = qs.parse(splitUrl[1]);
            if (parsed.workId === workId) {
                return true;
            }
        }
        return undefined;
    });

    if (hasRelatedAccessoryInCart(lineItems, workId)) {
        return undefined;
    }

    // some properties from VistaCart may be empty so check for workId in any of these
    const lineItem = itemFromFulfillment || itemFromAnalytics || itemFromEditDocumentUrl;

    // We don't support line items from cartV1 or missing a correlation id since we can't upsert them
    if (lineItem?.version === CartVersion.CART_V1 || !lineItem?.correlationId) {
        return undefined;
    }

    return lineItem;
};

export async function getItemsFromCart(authToken: string, ownerId: string, locale: string): Promise<CartItems> {
    const cartUrl = `${host}/tenants/${getTenant(locale)}/carts/${ownerId}`;

    return tryFetch({
        url: cartUrl,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        },
        moduleFunction: "vistaCartClient:getItemsFromCart",
        friendlyDescription: "get items from VistaCart",
        entityCode
    });
}

export async function callUpsertCart(
    authToken: string,
    locale: string,
    ownerId: string,
    work: WorkEntity,
    selectedProductOptions: Record<string, string>,
    mpvId: string | undefined
) {
    const existingCartItems = await getItemsFromCart(authToken, ownerId, locale);
    const existingItemInCart = getItemInCart(existingCartItems?.lineItems, work.workId);

    await upsertCartV2(
        authToken,
        locale,
        ownerId,
        work,
        existingItemInCart?.correlationId || work.workId,
        selectedProductOptions,
        mpvId,
        existingItemInCart
    );
}

async function upsertCartV2(
    authToken: string,
    locale: string,
    ownerId: string,
    work: WorkEntity,
    correlationId: string,
    selectedProductOptions: Record<string, string>,
    mpvId: string | undefined,
    existingItemInCart?: LineItem
) {
    const url = `${host}/tenants/${getTenant(
        locale
    )}/carts/${ownerId}/v2/items/correlationId/${correlationId}/?requestor=${REQUESTOR}&locale=${locale}`;

    const selectedAttributes = Object.entries(selectedProductOptions).map(([key, value]) => {
        return { key, value };
    });

    const editDocumentUrl = work.design.editUrl.replace(/\${workId}/, work.workId);

    const body = JSON.stringify({
        quantity: work.merchandising.quantity,
        product: {
            productKey: work.product.key,
            productVersion: work.product.version,
            selectedAttributes,
            editOptionsUrl: !existingItemInCart
                ? `/pdc/${locale}?workId=${work.workId}`
                : existingItemInCart?.merchandisingData.editOptionsUrl
        },
        design: {
            documentReferenceUrl: buildDocRefUrl(work),
            editDocumentUrl,
            livePreviewUrl: undefined // set to undefined to clear out existing preview url and let cart generate its own
        },
        customOrderData: {
            analytics: {
                // Required for a minimum edit in cart, which references this field rather than the designData
                workId: work.workId,
                workRevisionId: work.workRevisionId,
                product_id: mpvId, // mpvId required for x sell
                productKey: work.product.key
            },
            fullfillment: {
                workId: work.workId // required for envelopes
            }
        }
    });

    const content = await tryFetch({
        url,
        options: {
            method: "PUT",
            body,
            headers: {
                Authorization: `Bearer ${authToken}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "vistaCartClient:upsertCart",
        friendlyDescription: "update cart",
        entityCode
    });

    return content;
}

import { z } from "zod";
const defaultTranslations = {};
const api = /* @__PURE__ */ z.object({
  addNewTextItem: /* @__PURE__ */ z.array(/* @__PURE__ */ z.enum(["text", "wordart"])),
  allowDeleteText: /* @__PURE__ */ z.boolean()
});
const textSim = {
  name: "@internal/advanced-editor-sim-text",
  core: {
    defaultTranslations,
    extensions: []
  },
  api
};
export {
  textSim as default
};

import React from "react";
import type { StudioContentDiscoveryZone } from "@internal/feature-studio-content-discovery-zone";
import type { ContentDiscoveryZoneButton } from "@internal/utils-content-discovery-zone";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { hasRelatedAccessoryInCart } from "@internal/data-access-cart";
import { useCartContext } from "@internal/utils-cart";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Icon } from "@vp/diy-editor-ui";
import { ChangeProductSizeIcon } from "@internal/ui-studio-chrome";
import { useChangeSizePlacementMobileButton } from "@internal/ab-test-change-size-button-placement-mobile";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { messages } from "../FlexibilityPanelContent";
import { ChangeSizeDialogTypes } from "../ChangeSizeDialogTypes";
import { useActiveFlexibilityOptions } from "../ActiveFlexibilityProvider";
import { FlexibilityDesignAttributes } from "../constants";
import { useMultiSizeProduct } from "../common/hooks/useMultiSizeProduct";
import { changeProductSizeMessages } from "../Size/messages";

// null doesn't have a display name
// eslint-disable-next-line react/display-name
const CDZButton: ContentDiscoveryZoneButton = ({ children }) => {
    const { t } = useTranslationSSR();
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const { isMultiSizeProduct } = useMultiSizeProduct();
    const { isChangeSizeButtonPlacementMobileABEnabled } = useChangeSizePlacementMobileButton();
    const { isChangeSizeButtonPlacementDesktopABEnabled } = useChangeSizePlacementDesktopButton();
    const isChangeSizeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);
    const isAnyFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;
    const showChangeSizeBtn = !isAnyFlexibilityFeatureEnabled && isChangeSizeActive;

    // Holiday 2023 bandaid to prevent change-size from being used on holiday cards which have a matching envelope in cart
    // Because there's an issue with the envelopes flow that doesn't give customers the correctly sized envelope in that case
    const { itemsInCart } = useCartContext();
    const { workId } = useProductAndProjectStateManager().data;
    const hasRelatedCartItem = workId ? hasRelatedAccessoryInCart(itemsInCart, workId) : false;

    const isButtonVisible = showChangeSizeBtn && !hasRelatedCartItem && !isMultiSizeProduct;
    const isPlacementABTestEnabled =
        isChangeSizeButtonPlacementMobileABEnabled || isChangeSizeButtonPlacementDesktopABEnabled;

    if (isButtonVisible) {
        return children({
            icon: isPlacementABTestEnabled ? (
                <Icon iconType="twoPapersDifferentSizes" />
            ) : (
                <ChangeProductSizeIcon aria-hidden="true" />
            ),
            label: isPlacementABTestEnabled
                ? t(changeProductSizeMessages.productSizeButtonTitle.id)
                : t(messages.sizeTitle.id)
        });
    }

    return null;
};

export const ChangeSizeContentDiscoveryModule: StudioContentDiscoveryZone = {
    name: "@internal/advanced-editor-content-discovery-zone-change-size",
    core: {
        ChangeSize: {
            activeDialogType: ChangeSizeDialogTypes.changeSize,
            component: CDZButton
        }
    },
    deferredLoader: () => import("./index.deferred")
};

import type { DSS } from "@vp/types-ddif";

export function replaceChooseForMeInstructions(cimDoc: DSS.DesignDocument) {
    if (cimDoc?.metadata?.reviewInstructions) {
        cimDoc?.metadata?.reviewInstructions.forEach(instr => {
            if (instr.finishOption === "Choose For Me") {
                instr.finishOption = "Text and Image";
            }
        });
    }
}

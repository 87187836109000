import React, { createContext, useEffect, useState, useMemo, useContext, useCallback } from "react";
import * as reader from "@vp/ab-reader";
import { windowExists, getQueryParams } from "@internal/utils-browser";
import { isLocalHost, isStaging } from "@internal/utils-debug";
import type { StudioConfigurationState } from "@internal/utils-studio-configuration-core";
import { observer } from "mobx-react-lite";

interface Data {
    /**
     * Returns a boolean saying whether or not the variation is set
     * Will return true or false
     */
    isExperimentUsingVariation: (experimentKey: string, variationKey: string) => Promise<boolean>;
    /**
     * Given an experiment and variation key, will return whether or not the variation is a forced one
     */
    isForcedVariation: (experimentKey: string, variationKey: string) => boolean;

    getForcedVariation: (experimentKey: string) => string;

    isExperimentActive: (experimentKey: string) => Promise<boolean>;

    trackImpression: (experimentKey: string, variationKey: string) => Promise<void>;

    getVariation: (experimentKey: string) => string;

    locale: string;

    productKey: string;

    configuration: StudioConfigurationState;
}

export const AbTestContext = createContext<Data | undefined>(undefined);

export const useAbTestContext = () => {
    return useContext(AbTestContext);
};

interface Props {
    productKey: string;
    locale: string;
    configuration: StudioConfigurationState;
}

export const AbTestContextProvider = observer((props: React.PropsWithChildren<Props>) => {
    const { children, productKey, locale, configuration } = props;
    const [forcedVariations, setForcedVariations] = useState<Record<string, string>>({});
    const [readerAvailable, setReaderAvailable] = useState(false);
    const localHost = isLocalHost();
    const stagingHost = isStaging();

    // initialize AB test client and create list of all experiments and variations
    useEffect(() => {
        const init = async () => {
            try {
                reader.initialize();
                reader.whenAvailable(() => setReaderAvailable(true), 2000);
                const queryParams = getQueryParams();
                const forcedVariationQueryParams = Object.keys(queryParams)
                    .filter(key => key.startsWith("AB_"))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = queryParams[experimentQueryParamKey];
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1]
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});

                const forcedVariationLocalhost = Object.keys(localStorage)
                    .filter(key => key.startsWith("AB_"))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = localStorage.getItem(experimentQueryParamKey);
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1]
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});

                const forcedVariationsOverloads = Object.assign(
                    {},
                    forcedVariationLocalhost,
                    forcedVariationQueryParams
                );

                // Go through the overloads and make sure they are all in sync between query parm and local storage
                Object.entries(forcedVariationsOverloads).forEach(([key, value]) => {
                    localStorage.setItem(key, value as string);
                });

                setForcedVariations(forcedVariationsOverloads);
            } catch (error) {
                throw new Error(String(error));
            }
        };
        init();
    }, []);

    const getForcedVariation = useCallback(
        (experimentKey: string) => forcedVariations[experimentKey],
        [forcedVariations]
    );

    const context = useMemo(() => {
        // If we're on local host, the cookie for loading the reader might not be on developers machines
        // As such the reader never calls the "whenavailable" callback seeting reader available to be true.
        // Thus, if we're on local host, it's ok to set all these functions up so that the debug menu works.
        if (readerAvailable || localHost || stagingHost) {
            const disableReader = !!getQueryParams().noTrack;
            return {
                getVariation: (experimentKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    return forcedVariation || (!disableReader && reader.getVariation(experimentKey)) || "";
                },
                isExperimentUsingVariation: async (experimentKey: string, variationKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    const clientVariation = forcedVariation || (!disableReader && reader.getVariation(experimentKey));
                    return clientVariation === variationKey;
                },
                trackImpression: async (experimentKey: string, variationKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    const storeExpKey = windowExists()
                        ? JSON.parse(localStorage.getItem("trackExperiments") || "{}")
                        : {};
                    localStorage.setItem(
                        "trackExperiments",
                        JSON.stringify({ ...storeExpKey, [experimentKey]: variationKey })
                    );
                    if (disableReader) {
                        return;
                    }
                    if (forcedVariation) {
                        if (localHost || stagingHost) {
                            // eslint-disable-next-line no-console -- This debug statement is only run locally or in staging
                            console.log("will track impression for:", { experimentKey, forcedVariation });
                        }
                        return;
                    }
                    await reader.fireImpression(experimentKey, reader.getVariation(experimentKey) ?? "");
                },
                isForcedVariation: (experimentKey: string, variationKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    return forcedVariation === variationKey;
                },
                getForcedVariation,

                isExperimentActive: async (experimentKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    if (forcedVariation) {
                        return false;
                    }

                    if (disableReader) {
                        return false;
                    }

                    try {
                        const variation = reader.getVariation(experimentKey);
                        if (!variation) {
                            return false;
                        }
                    } catch {
                        // if unable to get key from optimizely just disable experiment
                        return false;
                    }

                    return true;
                },

                locale: locale,
                productKey: productKey,
                configuration: configuration
            };
        }

        return undefined;
    }, [readerAvailable, localHost, stagingHost, getForcedVariation, locale, productKey, configuration]);

    const { Provider } = AbTestContext;

    return <Provider value={context}>{children}</Provider>;
});

AbTestContextProvider.displayName = "AbTestContextProvider";

/**
 * Error code to identify studio-siteflow module in the error log
 */
export const entityCode = 32;

/**
 * The cookie key used for cross-site content initialization - `sci`
 * and cross-site proxy target - `scx`
 */
export const siteContentCookieKey = "sci-scx";

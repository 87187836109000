import { prototypeActiveDialogTypes } from "../prototypeContentSymbols.js";
import { isPrototypeSimItemState } from "../prototypeSimTypes.js";
function openContentPanelAfterItemSelectionCallback({
  firstSelectedItem
}) {
  if (isPrototypeSimItemState(firstSelectedItem)) {
    return prototypeActiveDialogTypes["thing A"];
  }
  return void 0;
}
export {
  openContentPanelAfterItemSelectionCallback
};

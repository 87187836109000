import { DecorationTechnology } from "./decorationTechnologyConfiguration";
import { DecorationTechnologies } from "./interface";

export const embroidery: DecorationTechnology = {
    configuration: {
        allowBackgroundColor: false,
        allowPDFProofDownload: false,
        allowTransparency: false,
        // commitColorChange: undefined,
        enableFontBrowser: false,
        allowVectors: false
    },
    isActive: panel =>
        [DecorationTechnologies.EmbroideryFlat, DecorationTechnologies.EmbroideryCylinder].includes(
            panel?.panelProperties?.decorationTechnology as DecorationTechnologies
        )
};

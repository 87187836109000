import { Box, FluidImage } from "@vp/swan";
import React from "react";

interface GuidelineModalImageProps {
    src: string;
    alt: string;
}

export const GuidelineModalImage = ({ src, alt }: GuidelineModalImageProps) => {
    return (
        <Box>
            <FluidImage src={src} alt={alt} />
        </Box>
    );
};

GuidelineModalImage.displayName = "GuidelineModalImage";

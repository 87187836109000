import { DecorationTechnology } from "@internal/utils-deco-tech";
import type { StudioDesignExperienceExtension } from "@internal/utils-studio-configuration-core";

const enableCutlineGenerationExperience: StudioDesignExperienceExtension = {
    enableCutlineGenerationExperience: [
        {
            conditions: {
                value: true,
                decorationTechnologies: [
                    DecorationTechnology.None,
                    DecorationTechnology.Offset,
                    DecorationTechnology.OffsetOrDigital,
                    DecorationTechnology.Thermography,
                    DecorationTechnology.Digital,
                    DecorationTechnology.InkJet
                ]
            },
            overrides: {
                defaultSlotName: "Images",
                connectedDesignArea: "StickerDEX",
                useSingleImageUploadWithCutlineGeneration: true,
                shouldSaveAdaptStickerDocument: true,
                openSelectImageModalOnLoad: true,
                shouldShowCutline: true,
                shouldShowQRCodes: false,
                shouldShowImages: true,
                shouldShowGraphics: false,
                shouldShowTables: false,
                shouldShowBackgroundColor: false,
                shouldShowTeams: false,
                shouldShowText: false,
                shouldHideImageDiscoveryTab: true,
                disableContextualTools: true,
                itemPreviewMixedBlendMultiply: false,
                shouldShowToolDivider: false,
                disableKeyboardShortcuts: true,
                onlyShowChecklistImageItem: true,
                enableToggleBackground: true,
                useUnitPricing: true,
                useUploadAndGoHelpButton: true,
                disableViewControls: true,
                disableFlexibilityPanelPreviews: true,
                useReactVortexPreview: false
            }
        }
    ]
};

export default enableCutlineGenerationExperience;

import { convertDocumentSourceType, DocumentSourceType } from "@internal/data-access-design-specifications-service";
import type { DSS } from "@vp/types-ddif";
import set from "lodash/set";

/**
 * With the new canvas selectors, we introduced the possibility of some bad documents
 * for users with a work of a no upsell, multi-surfaced products with a back designed
 * and saved without updating their back design option.
 * @param designDocument
 */
export function fixDocumentSources(designDocument: DSS.DesignDocument) {
    // Should only do this fix for no upsell products
    designDocument.document.panels.forEach(panel => {
        const existingDocumentSource = designDocument.metadata?.documentSources?.panels?.find(documentSourcePanel => {
            return documentSourcePanel.id === panel.id;
        });
        // Check to see if there is a document source for our panels
        const { textAreas, images, itemReferences, shapes } = panel;
        const hasItems = !!(textAreas?.length || images?.length || itemReferences?.length || shapes?.length);
        if (!existingDocumentSource) {
            // Check to see if there are items on that panel
            if (hasItems) {
                const newDocumentSource = {
                    id: panel.id,
                    source: convertDocumentSourceType(DocumentSourceType.EMPTY),
                    data: ""
                };
                // If document source doesnt exist at all, initiate it
                if (!designDocument.metadata?.documentSources?.panels) {
                    set(designDocument, ["metadata", "documentSources", "panels"], []);
                }
                // Adds the correct document source for this panel
                designDocument.metadata!.documentSources!.panels.push(newDocumentSource);
            }
        }
        // sometimes documents get sent with the wrong document source when the panel is meant to be blank
        else if (
            !hasItems && // so if we have no items on the panel
            panel.colorMode?.toLowerCase() === "blank" && // and the panel is meant to be blank
            existingDocumentSource // a document source is provided
        ) {
            // delete this one.  blank empty panels should not have document sources
            designDocument.metadata!.documentSources!.panels = designDocument.metadata!.documentSources!.panels.filter(
                documentSourcePanel => {
                    return documentSourcePanel.id !== panel.id;
                }
            );
        }
    });

    // Check if the existing document source's are legacy to update them to be compatable with the new canvas selectors
    const hasLegacySource = designDocument.metadata?.documentSources?.panels?.find(documentSourcePanel => {
        return documentSourcePanel.source === convertDocumentSourceType(DocumentSourceType.LEGACY);
    });

    if (hasLegacySource) {
        designDocument.metadata!.documentSources!.panels = designDocument.metadata!.documentSources!.panels.reduce(
            (acc, panel, index) => {
                // early exit if index doesn't match
                if (!designDocument.document.panels[index]) {
                    return acc;
                }
                // Update the id and the document source if it's legacy
                if (panel.source === convertDocumentSourceType(DocumentSourceType.LEGACY)) {
                    return [
                        ...acc,
                        {
                            ...panel,
                            id: designDocument.document.panels[index].id,
                            source: convertDocumentSourceType(DocumentSourceType.EMPTY)
                        }
                    ];
                }
                // If not only update the id only so its not longer using the incorrect name
                return [
                    ...acc,
                    {
                        ...panel,
                        id: designDocument.document.panels[index].id
                    }
                ];
            },
            [] as DSS.DocumentPanelSource[]
        );
    }
}

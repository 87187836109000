import { createSlice } from "@reduxjs/toolkit";
import { setNextStepUrl } from "./nextStepUrl";

const predictedStepsSlice = createSlice({
    name: "predictedSteps",
    initialState: [] as string[],
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setNextStepUrl, (state, action) => action.payload.predictedFlow?.map(step => step.url) ?? []);
    }
});

export const predictedSteps = predictedStepsSlice.reducer;

import { CMYK, cmyk2hex, Hex, HSV, RGB, rgb2hex } from "@design-stack-vista/utility-core";
import { calculateColorValues, convertToSelectableColor, NO_COLOR_VALUE, parseColor, rgbToString } from "./utilities";
import { ColorOverride, ColorOverrides, SelectableColor } from "./types";

export function isNotNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function getRgbString(color: string | RGB | CMYK | HSV): string | undefined {
    const parsedColor = parseColor(color);
    if (!parsedColor) {
        return undefined;
    }
    const colorValues = calculateColorValues(parsedColor.value, parsedColor.colorSpace);
    return rgbToString(colorValues.rgb);
}

export const getFullColorOverridesWithDefaultValues = (colors: string[]): ColorOverrides => {
    return colors
        .map(color => {
            const normalizedValue = getRgbString(color);
            if (normalizedValue) {
                return { [color]: normalizedValue } as ColorOverride;
            }
            return null;
        })
        .filter(isNotNullable);
};

export const getColorOverrideKey = (colorOverride: ColorOverride): string => {
    return Object.keys(colorOverride).filter(key => key !== "id")[0];
};

export const getColorOverrideValue = (colorOverride?: ColorOverride): string => {
    if (!colorOverride) {
        return NO_COLOR_VALUE;
    }
    return colorOverride[getColorOverrideKey(colorOverride)];
};

export const getColorOverrideValueOfSelectedColor = (
    selectedColor: ColorOverride | undefined,
    colorOverrides: ColorOverrides | undefined
) => {
    const selectedKey = Object.keys(selectedColor ?? {})[0];
    const matchingOverride = colorOverrides?.find(obj => obj[selectedKey]);
    return matchingOverride?.[selectedKey] ?? NO_COLOR_VALUE;
};

export const getItemColorsWithColorOverrides = (colorOverrides: ColorOverrides) => {
    return colorOverrides.reduce<SelectableColor[]>((accumulator, item) => {
        return [
            ...accumulator,
            convertToSelectableColor(getColorOverrideKey(item), getRgbString(getColorOverrideValue(item)))
        ];
    }, []);
};

export const getFillColorsWithColorOverrides = (colors: string[], colorOverrides?: ColorOverrides): string[] => {
    if (colors?.length) {
        return colors
            .map(color => {
                const colorRgb = getRgbString(color);
                if (colorRgb) {
                    if (colorOverrides?.length) {
                        const colorToReplaceIndex = colorOverrides.findIndex(item => item[color]);
                        if (colorToReplaceIndex !== -1) {
                            const colorToReplace = colorOverrides[colorToReplaceIndex]?.[color];
                            return getRgbString(colorToReplace);
                        }
                    }
                    return colorRgb;
                }
                return null;
            })
            .filter(isNotNullable);
    }

    return [];
};

/*
    convert short hex color #09f to #0099ff
    we need it, because cimdoc converter uses full hex color
*/
function expandShortHex(colorHex: string): string {
    const isShortHex = /^#([0-9a-fA-F]{3})$/.test(colorHex);

    if (!isShortHex) {
        return colorHex;
    }

    const [, r, g, b] = colorHex.match(/^#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])$/)!;

    const expandedHex = `#${r.repeat(2)}${g.repeat(2)}${b.repeat(2)}`;

    return expandedHex;
}

export const getInitialColorOverrides = (colorValues: string[]): ColorOverrides =>
    getFullColorOverridesWithDefaultValues(
        colorValues.map(color => {
            return `rgb(${expandShortHex(color)})`;
        })
    );

// rgbString is the resulting string from the getInitialColorOverrides util function (see this file)
export const rgbString2hex = (rgbString: string): Hex => {
    const [, r, g, b] = rgbString.match(/^rgb\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*\)$/)!;
    return rgb2hex({
        r: parseInt(r),
        g: parseInt(g),
        b: parseInt(b)
    });
};

export const cmykString2hex = (cmykString: string): Hex => {
    const [, c, m, y, k] = cmykString.match(/^cmyk\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*\)$/)!;
    return cmyk2hex({
        c: parseInt(c),
        m: parseInt(m),
        y: parseInt(y),
        k: parseInt(k)
    });
};

export const convertArrayToSelectableColors = (initialColors: string[], newColors: string[]) =>
    newColors.map((color, index) => convertToSelectableColor(`rgb(${initialColors[index]})`, getRgbString(color)));

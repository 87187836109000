import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useStudioLayout } from "@internal/feature-responsive-design";

type ContextData = {
    isChangeSizeButtonPlacementDesktopABEnabled: boolean;
    isTrackingComplete: boolean;
    trackImpression: () => Promise<void>;
    trackExperimentInteraction: (eventDetail: string, experimentDetail: string | undefined) => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useChangeSizePlacementDesktopButton() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a ChangeSizeButtonPlacementDesktop");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const changeSizeButtonPlacementDesktopExperimentData: ExperimentData = {
    experimentKey: "change_size_button_placement_desktop",
    experimentName: "Change Size [2]: Button Placement (Desktop)",
    variations: Variations
};

export const ChangeSizeButtonPlacementDesktopABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const trackingClient = useTrackingClient();
    const { isSmall } = useStudioLayout();
    const { experimentKey } = changeSizeButtonPlacementDesktopExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);

    const trackImpression = useCallback(async () => {
        if (isSmall) {
            return;
        }

        if (!experimentKey || !ABTest || isTrackingComplete) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            }
        };

        await Promise.all([
            trackImpressionIfInVariant(Variations.Test),
            trackImpressionIfInVariant(Variations.Control)
        ]);

        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete, isSmall]);

    const trackExperimentInteraction = useCallback(
        (eventDetail: string, experimentDetail = "") => {
            if (!experimentKey || !ABTest || !isEnabled || !trackingClient) {
                return;
            }

            trackingClient.track(Events.ExperimentClicked, {
                eventDetail,
                experimentDetail,
                experimentData: {
                    experimentId: experimentKey,
                    experimentName: experimentKey,
                    variationId: Variations.Test,
                    variationName: Variations.Test
                }
            });
        },
        [ABTest, experimentKey, isEnabled, trackingClient]
    );

    const contextObject = useMemo(
        () => ({
            isChangeSizeButtonPlacementDesktopABEnabled: isEnabled,
            isTrackingComplete,
            trackImpression,
            trackExperimentInteraction
        }),
        [isEnabled, isTrackingComplete, trackExperimentInteraction, trackImpression]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

ChangeSizeButtonPlacementDesktopABProvider.displayName = "ChangeSizeButtonPlacementDesktopABProvider";

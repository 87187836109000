/**
 * Different ItemReferenceTypes used in Studio
 */
export enum ItemReferenceTypes {
    WORD_ART = "Word Art",
    TABLE = "Table",
    QR_CODE = "QR Code",
    CALENDAR_GRID = "Calendar Grid",
    TEAMS_NAME = "Teams Name",
    /**
     * Type used for Patterns
     */
    TILE_IMAGE = "panel",
    VECTOR_PANEL = "Vector"
}

export function tryParse<Type>(item: string | null | undefined, fallback: Type): Type {
    if (!item) {
        return fallback;
    }
    try {
        return JSON.parse(item);
    } catch {
        return fallback;
    }
}

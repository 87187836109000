import React from "react";
import { Box, Li, Typography, Ul } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import * as styles from "./WarningContent.module.scss";

interface WarningContentProps {
    warnings: Array<string>;
}

const warningMessage = defineMessages({
    warningTitle: {
        id: "studio.warningToast.title",
        defaultMessage: "Warning(s)",
        description: "Title for the warning toast"
    }
});

export const WarningContent = ({ warnings }: WarningContentProps) => {
    const { t } = useTranslationSSR();
    return (
        <Box className={styles.toastMessageContainer}>
            <Typography fontWeight="bold">{t(warningMessage.warningTitle.id)}:</Typography>
            <Ul>
                {warnings.map((warning, index) => (
                    <Li key={index}>{warning}</Li>
                ))}
            </Ul>
        </Box>
    );
};

WarningContent.displayName = "WarningContent";

import { createSlice } from "@reduxjs/toolkit";

const blankDocumentAlertSlice = createSlice({
    name: "showBlankDocumentAlert",
    initialState: false,
    reducers: {
        setShowBlankDocumentAlert: (state, action) => action.payload
    }
});

export const { setShowBlankDocumentAlert } = blankDocumentAlertSlice.actions;
export const showBlankDocumentAlert = blankDocumentAlertSlice.reducer;

import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import type { CimDoc } from "@design-stack-vista/cdif-types";

/**
 * This will ensure that every pattern added is in background mode and locked
 * Also this will help extensions correctly detect the pattern
 */
export function pushMissingMetadataToPatternItems(designDocument: CimDoc) {
    designDocument.document.panels.forEach(panel => {
        const patternItem = panel.itemReferences?.find(({ type }) => type === ItemReferenceTypes.TILE_IMAGE);
        if (patternItem) {
            const patternMetadata = designDocument.metadata?.template?.find(({ id }) => id === patternItem.id);

            // Add metadata to identify the pattern as a background item if the metadata is missing
            if (!patternMetadata) {
                designDocument.metadata?.template?.push({
                    id: patternItem.id,
                    background: true,
                    locks: {
                        edit: false,
                        transform: false,
                        zIndex: true
                    }
                });
            }
        }
    });
}

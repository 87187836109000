// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import type { DSS, DTeC } from "@vp/types-ddif";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { getQueryParams } from "@internal/utils-browser";
import type { PanelSectionData } from "@internal/feature-panel-sections";
import type {
    DesignExperienceManagementState,
    ProductGroupConfiguration
} from "@internal/utils-studio-configuration-core";
import { getCountry } from "@internal/utils-i18n";
import type {
    CompatibleOptionsEntry,
    ProductAttributesKeyAndValues,
    ProductOption
} from "@internal/data-access-product";
import type { CalciferSceneConfiguration, TemplateColorVariation } from "../types";

// Interfaces for return values are taken from the source code
// to studio-calcifer

const VCS_STUDIO_CALCIFER_URL = "https://cdn.studio-calcifer.design.vpsvc.com/vcs";
const host = getQueryParams().calciferUrl || VCS_STUDIO_CALCIFER_URL;
const { templateLocale } = getQueryParams();
const entityCode = 40;

function removeNullQueryStringParams<T extends {}>(params: T): Partial<T> {
    const newParams = params;
    Object.keys(newParams).forEach(key =>
        // @ts-ignore FIXME: must handle implicit `any` type
        newParams[key] === undefined || newParams[key] === null ? delete newParams[key] : {}
    );
    return newParams;
}

interface DesignViews {
    designViews: DSS.DesignView[];
}

export interface StudioConfig {
    mpvId: string | null;
    productVersion: number;
    productName: string;
    productCategory: string | null;
    quantity: number;
    selectedOptions: Record<string, string>;
    customerSelectedOptions: Record<string, string>;
    productKey: string | null;
    scenesConfiguration: CalciferSceneConfiguration;
    designViews: DesignViews;
    designDocument: DSS.DesignDocument;
    warnings: string[];
    studioConfiguration: DesignExperienceManagementState;
    productGroupConfiguration: ProductGroupConfiguration;
    mcpSku: string;
    mcpVersion: number;
    designs: number;
    panelSectionSets: PanelSectionData[] | null;
    isTeamsProduct: boolean;
}

export async function getStudioConfigFromCalciferVcsV2(
    productKey: string | undefined | null,
    mpvId: string | undefined | null,
    productVersion: number | undefined | null,
    customerSelectedProductOptions: Record<string, string> | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    qty: number | undefined,
    locale: string,
    merchandisingMpvUrl: string | undefined | null,
    isFullBleed: boolean,
    documentRevisionUrl?: string | undefined | null,
    allowDynamicUnderlayScene?: boolean | undefined,
    brandedProductId?: string | undefined | null,
    vcsTenant?: string,
    storeId?: string,
    mode?: string,
    authToken?: string
): Promise<StudioConfig> {
    const requestParams = removeNullQueryStringParams({
        productKey,
        mpvId,
        productVersion,
        selectedOptions: JSON.stringify(customerSelectedProductOptions),
        studioSelectedProductOptions: JSON.stringify(studioSelectedProductOptions),
        qty: qty ? qty.toString() : null,
        market: getCountry(locale),
        locale,
        merchandisingMpvUrl,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : brandedProductId,
        templateLocale,
        tenant: vcsTenant,
        storeId,
        mode,
        documentUrl: documentRevisionUrl,
        // if this is false then we shouldn't bother including it as it screws up caches and tests
        // this is only meant for overrides
        allowDynamicUnderlayScene: allowDynamicUnderlayScene || undefined
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/initializedStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getStudioConfigFromCalciferV2",
        friendlyDescription: "retrieve studio configuration from calcifer",
        entityCode,
        options: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });

    return initializedConfig;
}

export interface SurfaceUpsell {
    panelName: string;
    panelId: string;
    optionName: string;
    blankOption?: string;
    colorOption?: string;
    templateToken?: string;
    ensemblePanelType?: string;
}

export type TemplateInfo = DTeC.EnsembleTemplateDto & { panelName?: string };

export interface StudioSecondaryConfig {
    templates: TemplateInfo[];
    surfaceUpsells?: SurfaceUpsell[];
    warnings: string[];
    designVariations: any[];
    templateColorVariations: TemplateColorVariation[];
    templateAvailableFinishes: DTeC.FinishType[];
    primaryTemplateToken: string;
    isQuantityPageEnabled: boolean;
    designAttributes: ProductOption[];
    compatibleOptions: CompatibleOptionsEntry[];
    productAttributeAndValues: ProductAttributesKeyAndValues[];
}

export async function getSecondaryConfigFromCalciferVcsV2(
    productKey: string,
    productVersion: string | number | undefined,
    studioSelectedProductOptions: Record<string, string> | undefined,
    locale: string,
    isFullBleed: boolean,
    template: string | undefined | null
): Promise<StudioSecondaryConfig> {
    if (!productKey || !productVersion || !locale) {
        throw new Error("Cannot fetch secondary Calcifer config without a productKey, productVersion, or locale.");
    }

    const requestParams = removeNullQueryStringParams({
        productKey,
        productVersion,
        selectedOptions: JSON.stringify(studioSelectedProductOptions),
        locale,
        requestor: REQUESTOR,
        isFullBleed,
        template: isFullBleed ? null : template,
        templateLocale
    });

    const queryString = qs.stringify(requestParams);
    const url = `${host}/v2/secondaryStudioConfig/?${queryString}`;
    const initializedConfig = await tryFetch({
        url,
        moduleFunction: "studioCalciferClient:getSecondaryConfigFromCalciferV2",
        friendlyDescription: "retrieve secondary studio configuration from calcifer",
        entityCode
    });
    return initializedConfig;
}

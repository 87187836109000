import * as React from "react";
import { SVGProps } from "react";

export const LightBlueSurprisedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 182 199" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
        <g opacity={0.1} filter="url(#a)">
            <path
                d="M171.5 182.5c0 3.6-36 6.5-80.5 6.5s-80.5-3-80.5-6.5c0-3.6 36-6.5 80.5-6.5s80.5 3 80.5 6.5Z"
                fill="#000"
            />
        </g>
        <path
            d="M7.5 34c0-1.1.9-2 2-2h144.3v18.9c0 1 .8 2 2 2h18.7V138a2 2 0 0 1-2 2H9.5a2 2 0 0 1-2-2V34Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 34.5v103h162V55.4h-16.2c-2.5 0-4.5-2-4.5-4.5V34.5H10Zm146.3 3.6 12.2 12.3h-12.2V38ZM5 34c0-2.5 2-4.5 4.5-4.5h144.3c.6 0 1.3.3 1.7.7l20.8 21c.4.4.7 1 .7 1.7V138c0 2.5-2 4.5-4.5 4.5H9.5c-2.5 0-4.5-2-4.5-4.5V34Z"
            fill="#6A7076"
        />
        <path
            className="color-element"
            d="M64.5 79a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm62 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-25 23.5c0 3-3.8 5.5-8.5 5.5s-8.5-2.5-8.5-5.5S88.3 97 93 97s8.5 2.5 8.5 5.5Z"
            fill="#36B7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.7 15.8c-1 1-2.5 1-3.5 0l-4.5-4.5a2.5 2.5 0 0 1 3.6-3.5l4.4 4.4c1 1 1 2.6 0 3.6Zm13.5-.8c-1.2.6-2.7 0-3.3-1.2L70 5.4a2.5 2.5 0 1 1 4.5-2.1l4 8.4c.5 1.3 0 2.8-1.3 3.3Zm39.1 1.9c-1-1-.9-2.6.2-3.5l4.8-4.4a2.5 2.5 0 0 1 3.3 3.7l-4.8 4.4c-1 .9-2.6.8-3.5-.2Zm-11.5-2.1c1.2.6 2.7 0 3.3-1.2l3.7-8a2.5 2.5 0 1 0-4.6-2l-3.7 7.9c-.5 1.2 0 2.7 1.3 3.3ZM91 14a2.5 2.5 0 0 1-2.5-2.5v-9a2.5 2.5 0 0 1 5 0v9c0 1.4-1.1 2.5-2.5 2.5Z"
            fill="#6A7076"
        />
        <defs>
            <filter
                id="a"
                x={0.5}
                y={166}
                width={181}
                height={33}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur" />
            </filter>
        </defs>
    </svg>
);

LightBlueSurprisedIcon.displayName = "LightBlueSurprisedIcon";

import React from "react";
import { Button, ResetIcon } from "@internal/ui-studio-chrome";
import classnames from "classnames";
import { Slider } from "./Slider";
import styles from "./SliderRow.module.scss";

interface RowProps {
    value: number;
    min: number;
    max: number;
    label: string;
    resetAriaLabel?: string;
    onChange: (value: number) => void;
    onReset?: () => void;
    onEdit?: (value: number) => void;
    onRelease?: () => void;
    sliderBackground?: string;
    /** Inline styles passed to the handle */
    handleStyle?: { [key: string]: string };
    tickMark: boolean;
    startSwatchColor?: string;
    endSwatchColor?: string;
}

export function SliderRow({
    value,
    min,
    max,
    label,
    resetAriaLabel,
    onChange,
    onEdit,
    onReset,
    onRelease,
    sliderBackground,
    handleStyle,
    tickMark,
    startSwatchColor,
    endSwatchColor
}: RowProps) {
    return (
        <div
            className={classnames("easel-hslrecolorization-sliderrow", {
                "tick-mark-slider": tickMark
            })}
        >
            <span className={classnames("easel-hslrecolorization-sliderrow-label")}>{label}</span>
            <Slider
                value={value}
                min={min}
                max={max}
                onChange={onChange}
                onRelease={onRelease}
                barStyle={{ background: sliderBackground || "" }}
                ariaLabel={label}
                handleStyle={handleStyle}
                startSwatchColor={startSwatchColor}
                endSwatchColor={endSwatchColor}
            />
            {onReset && (
                <Button
                    className={classnames("easel-hslrecolorization-sliderrow-reset")}
                    title={resetAriaLabel}
                    aria-label={resetAriaLabel}
                    onClick={onReset}
                >
                    <ResetIcon />
                </Button>
            )}
            {onEdit && (
                <input
                    className={classnames(styles.easelHslrecolorizationSliderrowText)}
                    type="number"
                    min={min}
                    max={max}
                    title={label}
                    aria-label={label}
                    value={value}
                    onChange={event => {
                        const text: string = event.target.value;
                        if (text) {
                            const val = parseInt(text, 10);
                            if (val < min || val > max) {
                                return;
                            }
                            onEdit(val);
                        }
                    }}
                />
            )}
        </div>
    );
}
SliderRow.displayName = "SliderRow";

import { type RefObject } from "react";

export function scrollToRef(
    parentRef: RefObject<HTMLElement>,
    scrollToElement: RefObject<HTMLElement>,
    offset = 0,
    vertical = true,
    scrollBehavior: "auto" | "instant" | "smooth" = "auto"
) {
    const margin = 10;
    const optionList = parentRef.current;
    if (optionList && scrollToElement.current) {
        if (vertical) {
            const topPosition = scrollToElement.current.offsetTop - margin - offset; // offset could be some padding/margin that is applied on the list
            if (!optionList.scrollTo) {
                optionList.scrollTop = topPosition;
            } else {
                optionList.scrollTo({
                    top: topPosition,
                    behavior: scrollBehavior
                });
            }
        } else {
            const leftPosition = scrollToElement.current.offsetLeft - margin - offset; // offset could be some padding/margin that is applied on the list
            if (!optionList.scrollTo) {
                optionList.scrollLeft = leftPosition;
            } else {
                optionList.scrollTo({
                    left: leftPosition,
                    behavior: scrollBehavior
                });
            }
        }
    }
}

import { ThingAContentDiscoveryZoneButton, ThingBContentDiscoveryZoneButton } from "./contentDiscoveryZone/ContentDiscoveryZoneButtons.js";
import { PrototypeSimPreloaderPanelExtension } from "./extensions/PrototypePreloaderItemExtension.js";
import { name } from "./simName.js";
import { afterDuplicatePanelCallback } from "./afterDuplicatePanel/afterDuplicatePanelCallback.js";
import { PrototypeDraggableValue, useHandleDropTarget } from "./dragAndDrop/dragAndDrop.js";
import { openContentPanelAfterItemSelectionCallback } from "./openContentPanelAfterItemSelectionCallback/openContentPanelAfterItemSelectionCallback.js";
import { usePrototypeSim } from "./useOverrideEnableState.js";
import { prototypeActiveDialogTypes } from "./prototypeContentSymbols.js";
import { ItemPreview } from "./itemPreview/ItemPreview.js";
import { PROTOTYPE_SIM_TYPE } from "./prototypeSimTypes.js";
const defaultTranslations = {
  "sim.prototype.thingAText": "Large Screen `A` Content",
  "sim.prototype.thingBText": "Large Screen `B` Content",
  "sim.prototype.colorPicker": "Color Picker",
  "sim.prototype.selectedZoneUI": "Selected UI"
};
const prototypeSim = {
  name,
  core: {
    defaultTranslations,
    extensions: [PrototypeSimPreloaderPanelExtension],
    contentDiscoveryZone: {
      Default: [{
        activeDialogType: prototypeActiveDialogTypes["thing A"],
        component: ThingAContentDiscoveryZoneButton
      }, {
        activeDialogType: prototypeActiveDialogTypes["thing B"],
        component: ThingBContentDiscoveryZoneButton
      }]
    },
    afterDuplicatePanelCallback,
    dragAndDrop: {
      [PrototypeDraggableValue]: {
        useHandleDropTarget
      }
    },
    selectedZone: {
      detailZoneIds: /* @__PURE__ */ new Set([{
        type: "ColorPicker",
        activeDialogType: prototypeActiveDialogTypes.ColorPicker
      }, {
        type: "General",
        activeDialogType: prototypeActiveDialogTypes.SomeSelectionToolUI
      }])
    },
    openContentPanelAfterItemSelectionCallback,
    useOverrideEnableState: usePrototypeSim,
    itemPreview: {
      matchSelection: `itemReference/${PROTOTYPE_SIM_TYPE}`,
      ItemPreview
    }
  },
  deferredLoader: () => import("./index.deferred.js")
};
export {
  prototypeSim as default
};

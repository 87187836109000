import React, { PropsWithChildren } from "react";
import { Button, FlexBox, ModalDialogTitle, Typography } from "@vp/swan";
import { useStudioLayout } from "@internal/feature-responsive-design";
import {
    SidebarModal,
    SidebarModalBody,
    SidebarModalFooter,
    SidebarModalHeader,
    SidebarSingleColumnLayout
} from "@vp/diy-editor-ui";

export interface GuidelineModalProps {
    isOpen: boolean;
    closeDialog: () => void;
    closeButtonAccessibleText: string;
    title: string;
    closeButtonText: string;
}

const dialogTitleId = "guideline-dialog-title";

export const GuidelineModal = (props: PropsWithChildren<GuidelineModalProps>) => {
    const { isOpen, closeDialog, closeButtonAccessibleText, title, closeButtonText, children } = props;

    const { isSmall } = useStudioLayout();

    return (
        <SidebarModal
            isOpen={isOpen}
            onRequestDismiss={closeDialog}
            contentAriaLabel={title}
            closeButtonAccessibleText={closeButtonAccessibleText}
            aria-labelledby={dialogTitleId}
        >
            <SidebarSingleColumnLayout>
                <SidebarModalHeader mb={isSmall ? 0 : "5"}>
                    <ModalDialogTitle id={dialogTitleId}>{title}</ModalDialogTitle>
                </SidebarModalHeader>
                <SidebarModalBody tabIndex={0}>
                    <FlexBox flexDirection="column" gap={isSmall ? "6" : "7"}>
                        {children}
                    </FlexBox>
                </SidebarModalBody>
                {isSmall && (
                    <SidebarModalFooter bgc="strong">
                        <Button width="full-width" size="mini" skin="primary" onClick={closeDialog}>
                            <Typography fontSkin="body-standard-bold">{closeButtonText}</Typography>
                        </Button>
                    </SidebarModalFooter>
                )}
            </SidebarSingleColumnLayout>
        </SidebarModal>
    );
};

GuidelineModal.displayName = "GuidelineModal";

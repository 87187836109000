import { jsx } from "react/jsx-runtime";
import { QRCodeIcon } from "@internal/ui-studio-chrome";
const QRCodesContentDiscoveryButton = ({
  screenSize,
  children
}) => {
  return children({
    icon: /* @__PURE__ */ jsx(QRCodeIcon, {
      "aria-hidden": "true"
    }),
    label: "QR Codes"
  });
};
export {
  QRCodesContentDiscoveryButton
};

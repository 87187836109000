import React, { createContext, useContext, useMemo } from "react";
import { LocalizedKeys } from "./localizations";

type ReplaceValuesType = Record<string, string>;

interface LocalizedContextType {
    get(key: string, replaceValues?: ReplaceValuesType): string;
    locale: string;
}

export const LocalizedContext = createContext<LocalizedContextType | undefined>(undefined);

export interface LocalizedProviderProps extends React.PropsWithChildren {
    readonly localizedValues: LocalizedKeys;
    readonly locale: string;
}

/**
 * This component should be wrapping Other components.
 * It provides all pre-localized strings to child components.
 *
 * @param {LocalizedProviderProps} props
 * @returns JSX.Element
 */
export const LocalizedProvider = (props: LocalizedProviderProps) => {
    const { children, localizedValues, locale } = props;
    const context = useMemo(() => {
        return {
            get(key: keyof LocalizedKeys, replaceValues?: ReplaceValuesType) {
                let localizedString = localizedValues[key];
                for (const replaceKey in replaceValues) {
                    if (!Object.prototype.hasOwnProperty.call(replaceValues, replaceKey)) {
                        continue;
                    }
                    localizedString = localizedString.replaceAll(`[[${replaceKey}]]`, replaceValues[replaceKey]);
                }

                return localizedString;
            },
            locale
        };
    }, [localizedValues, locale]);

    return <LocalizedContext.Provider value={context}>{children}</LocalizedContext.Provider>;
};

export const useLocalized = () => {
    const ctx = useContext(LocalizedContext);
    if (ctx === undefined) {
        throw new Error("This component must be wrapped into <LocalizedProvider> to use translations");
    }

    return ctx;
};

import { UnitlessBox } from "./types";
import { getAvailableSpace } from "./getAvailableSpace";
import { getItemDimensionFitOnRotation } from "./getItemDimensionFitOnRotation";
import { getContentOrientationAngle } from "./getContentOrientationAngle";

/**
 *
 * @description Returns the position of an item in a bounding box, fitting on rotation.
 *
 * @param targetBoundingBox
 * This is the bounding-box of the section where the item is to be placed
 *
 * @param itemAspectRatio
 * Aspect ratio of the item needs to be maintained within the bounding-box
 *
 * @param contentOrientationAngle
 * Orientation of the item in the bounding-box
 *
 * @param paddingPercentage
 * Padding that needs to be maintained within the bounding-box; Default = 0
 *
 *
 * @returns
 */
export function getItemPositionFitOnRotation(
    targetBoundingBox: UnitlessBox,
    itemAspectRatio: number,
    contentOrientationAngle: number,
    paddingPercentage = 0
): UnitlessBox {
    if (paddingPercentage < 0 || paddingPercentage > 100) {
        throw new Error("Padding Percentage must be between 0 and 100");
    }

    const availableSpace = getAvailableSpace(targetBoundingBox, paddingPercentage);

    const { width, height } = getItemDimensionFitOnRotation({
        targetDimensions: { width: availableSpace.width, height: availableSpace.height },
        itemAspectRatio,
        contentOrientationAngle: getContentOrientationAngle(contentOrientationAngle.toString())
    });

    const x = targetBoundingBox.x + (targetBoundingBox.width - width) / 2;
    const y = targetBoundingBox.y + (targetBoundingBox.height - height) / 2;

    return {
        width,
        height,
        x,
        y
    };
}

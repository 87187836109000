import { useDesignEngine } from "@design-stack-vista/core-features";
import { reaction } from "mobx";
import { useEffect } from "react";
import { RecentColorsManager } from "./RecentColorsManager";
import { useGetAllColorsFromCimDoc } from "./useGetAllColorsFromCimDoc";

/**
 * Observes when the IDA selection changes and then updates the recent colors list.
 * This is used when the color panel/sheet/dropdown is open, the user selects a color,
 * and the clicks outside the the drop down on the IDA.
 * This is also used when the user makes an initial selection and we have no recent colors
 * This can happen if we loaded a blank (or full-bleed) design.  The user adds an image and
 * we don't fetch recent colors until they actually select something
 *
 * @param {RecentColorsManager} recentColorsManager
 */
export const useUpdateRecentColorsOnSelectionChange = (recentColorsManager: RecentColorsManager) => {
    const designEngine = useDesignEngine();
    const getAllColorsMap = useGetAllColorsFromCimDoc();

    useEffect(() => {
        const dispose = reaction(
            () => Array.from(designEngine.idaStore.selectedIds),
            () => {
                if (recentColorsManager.recentColors.length === 0 && designEngine.idaStore.selectedIds.size > 0) {
                    recentColorsManager.pushColor(getAllColorsMap);
                } else if (recentColorsManager.lastSelectedColor) {
                    recentColorsManager.pushSelectedColor(recentColorsManager.lastSelectedColor);
                    recentColorsManager.resetLastSelectedColor();
                }
            }
        );
        return dispose;
    }, [designEngine, getAllColorsMap, recentColorsManager]);
};

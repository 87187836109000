import { getQueryParams, removeQueryParam } from "../queryString";
import { windowExists } from "../windowExists";

const VP_STUDIO_DEBUG_MODE = "vp-studio-debug-mode";
export function isDebugMode() {
    try {
        if (!windowExists()) {
            return false;
        }
        const urlParams = getQueryParams();

        const debugModeQueryParamValue =
            urlParams.debugMode ||
            urlParams.debug ||
            urlParams.debugmode ||
            urlParams.isdebugmode ||
            urlParams.isDebugMode;

        if (debugModeQueryParamValue === "false" || debugModeQueryParamValue === "0") {
            window.localStorage.removeItem(VP_STUDIO_DEBUG_MODE);
        }

        if (debugModeQueryParamValue === "true" || debugModeQueryParamValue === "1") {
            window.localStorage.setItem(VP_STUDIO_DEBUG_MODE, "true");
        }

        return window.localStorage.getItem(VP_STUDIO_DEBUG_MODE) === "true";
    } catch {
        return false;
    }
}
export function disableDebugMode() {
    window.localStorage.removeItem(VP_STUDIO_DEBUG_MODE);
    const urlParams = getQueryParams();
    const debugParam = Object.keys(urlParams).find(key =>
        ["debugMode", "debug", "debugmode", "isdebugmode", "isDebugMode"].includes(key)
    );
    window.location.href = removeQueryParam(window.location.href, debugParam);
}

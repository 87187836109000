import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Divider } from "@vp/swan";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { COLOR_MODE, useHasAvailableUpsell, useNeedAddButtonContext } from "@internal/utils-surface-upsell";
import { useCanvasSelectorPreviews } from "@shared/features/CanvasSelectors";
import { useOnAddClick, useOnContinueClick } from "@shared/features/DesignPanel";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { useActiveFlexibilityOptions } from "./ActiveFlexibilityProvider";
import { FlexibilityCard } from "./common/components/FlexibilityCard";
import { FlexibilityDesignAttributes } from "./constants";
import * as styles from "./FlexibilityPanelContent.module.scss";

export const messages = defineMessages({
    sizeTitle: {
        id: "studio.components.panels.flexibilityPanel.size.card.title",
        defaultMessage: "Size"
    },
    quantityTitle: {
        id: "studio.components.panels.flexibilityPanel.quantity.title",
        defaultMessage: "Quantity"
    },
    colorBackSideDescription: {
        id: "studio.components.panels.flexibilityPanel.color.backSide.title",
        defaultMessage: "Full color back"
    },
    blankBackSideDescription: {
        id: "studio.components.panels.flexibilityPanel.blank.backSide.title",
        defaultMessage: "No back"
    },
    premiumFinishTitle: {
        id: "studio.components.panels.flexibilityPanel.premiumFinish.card.title",
        defaultMessage: "Premium finish"
    },
    optionNone: {
        id: "studio.components.panels.flexibilityPanel.noselection",
        defaultMessage: "None"
    },
    trimTitle: {
        id: "studio.components.panels.flexibilityPanel.trim.card.title",
        defaultMessage: "Trim"
    },
    backSideTitle: {
        id: "studio.components.panels.flexibilityPanel.backSide.title",
        defaultMessage: "Back design"
    }
});

const label = "click on design attribute on product options panel";

export const FlexibilityPanelContent = observer(
    ({ onClick }: { onClick?: (option?: FlexibilityDesignAttributes) => void }) => {
        const { t } = useTranslationSSR();
        const { onClickFlexibilityOptions } = useActiveFlexibilityOptions();
        const { quantity } = useProductAndProjectStateManager().data;
        const canvasSelectorPreviewsData = useCanvasSelectorPreviews();
        const needAddButton = useNeedAddButtonContext();
        const hasAvailableUpsell = useHasAvailableUpsell();
        const onAddClick = useOnAddClick();
        const onContinueClick = useOnContinueClick();
        const designRequirements = useDesignRequirementsContext();

        if (!designRequirements || !canvasSelectorPreviewsData) {
            return null;
        }

        const { panels } = designRequirements;
        if (!panels) {
            return null;
        }

        const { canvasSelectorUrls } = canvasSelectorPreviewsData;
        const backIndex = canvasSelectorUrls.findIndex((canvas: any) => canvas.name === "Back");
        const backCanvas = panels[backIndex];
        const canvasData = canvasSelectorUrls[backIndex];

        const onClickBackSide = () => {
            const { name } = canvasData;
            if (!needAddButton(backCanvas, name) && (backIndex !== 0 || hasAvailableUpsell(name))) {
                onContinueClick(backIndex, canvasData);
            } else {
                onAddClick(canvasData);
            }
        };

        return (
            <div className={styles.flexibilityOptions}>
                <FlexibilityCard
                    className={styles.flexibilityCard}
                    title={t(messages.sizeTitle.id)}
                    designAttributeName={FlexibilityDesignAttributes.Size}
                    showPreview={false}
                    onClick={() => {
                        onClick?.(FlexibilityDesignAttributes.Size);
                        onClickFlexibilityOptions(
                            STUDIO_TRACKING_EVENTS.CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL,
                            label,
                            FlexibilityDesignAttributes.Size
                        );
                    }}
                />
                <FlexibilityCard
                    className={styles.flexibilityCard}
                    title={t(messages.quantityTitle.id)}
                    designAttributeName={FlexibilityDesignAttributes.Quantity}
                    description={quantity.toString()}
                    showPreview={false}
                    onClick={() => {
                        onClick?.(FlexibilityDesignAttributes.Quantity);
                        onClickFlexibilityOptions(
                            STUDIO_TRACKING_EVENTS.CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL,
                            label,
                            FlexibilityDesignAttributes.Quantity
                        );
                    }}
                />
                <Divider className={styles.flexibilityOptionsDivider} />
                <FlexibilityCard
                    className={styles.flexibilityCard}
                    title={t(messages.premiumFinishTitle.id)}
                    designAttributeName={FlexibilityDesignAttributes.PremiumFinish}
                    onClick={() => {
                        onClick?.(FlexibilityDesignAttributes.PremiumFinish);
                        onClickFlexibilityOptions(
                            STUDIO_TRACKING_EVENTS.CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL,
                            label,
                            FlexibilityDesignAttributes.PremiumFinish
                        );
                    }}
                />
                <FlexibilityCard
                    className={styles.flexibilityCard}
                    title={t(messages.trimTitle.id)}
                    designAttributeName={FlexibilityDesignAttributes.Trim}
                    onClick={() => {
                        onClick && onClick(FlexibilityDesignAttributes.Trim);
                        onClickFlexibilityOptions(
                            STUDIO_TRACKING_EVENTS.CLICK_DESIGN_ATTRIBUTE_ON_PRODUCT_OPTIONS_PANEL,
                            label,
                            FlexibilityDesignAttributes.Trim
                        );
                    }}
                />
                {canvasData && (
                    <FlexibilityCard
                        className={styles.flexibilityCard}
                        title="Back"
                        description={
                            panels[backIndex].colorMode === COLOR_MODE.BLANK
                                ? t(messages.blankBackSideDescription.id)
                                : t(messages.colorBackSideDescription.id)
                        }
                        previewUrl={canvasData.src}
                        onClick={() => {
                            onClick?.();
                            onClickBackSide();
                        }}
                    />
                )}
            </div>
        );
    }
);
FlexibilityPanelContent.displayName = "FlexibilityPanelContent";

import { FinishMessages } from "./messages";

export const PF_INSTRUCTIONS_TYPE = "Premium Finish";

export const FINISH_OPTION = {
    TextOnly: "Text Only",
    ImageOnly: "Image Only",
    TextAndImage: "Text and Image"
};

// enum of all premium finish spot colors
export enum PremiumFinishSpotColors {
    Metallic = "Metallic",
    RaisedInk = "RaisedInk",
    RaisedFoilGold = "RaisedFoilGold",
    RaisedFoilSilver = "RaisedFoilSilver",
    RaisedFoilGlitterSilver = "RaisedFoilGlitterSilver",
    None = "None"
}

export const SUPPORTED_FINISHES = [
    "Metallic",
    "RaisedInk",
    "RaisedFoilGold",
    "RaisedFoilSilver",
    "RaisedFoilGlitterSilver"
];

export type PremiumFinishSelectionOption = {
    option: string;
    img: string;
    title: string;
    text: string;
};

export const PremiumFinishSelectionSet = {
    Metallic: [
        {
            option: FINISH_OPTION.TextOnly,
            img: "foilAccent-text-only-001.png",
            title: FinishMessages.textOnlyTitle.id,
            text: FinishMessages.textOnlySubline.id
        },
        {
            option: FINISH_OPTION.ImageOnly,
            img: "foil-accent-image-only-001.png",
            title: FinishMessages.imageOnlyTitle.id,
            text: FinishMessages.imageOnlySubline.id
        },
        {
            option: FINISH_OPTION.TextAndImage,
            img: "foil-accent-image-text-001.png",
            title: FinishMessages.textAndImageTitle.id,
            text: FinishMessages.textAndImageSubline.id
        }
    ],
    RaisedInk: [
        {
            option: FINISH_OPTION.TextOnly,
            img: "spot-gloss-text-only-001.png",
            title: FinishMessages.textOnlyTitle.id,
            text: FinishMessages.textOnlySubline.id
        },
        {
            option: FINISH_OPTION.ImageOnly,
            img: "spot-gloss-image-only-001.png",
            title: FinishMessages.imageOnlyTitle.id,
            text: FinishMessages.imageOnlySubline.id
        },
        {
            option: FINISH_OPTION.TextAndImage,
            img: "spot-gloss-image-text-001.png",
            title: FinishMessages.textAndImageTitle.id,
            text: FinishMessages.textAndImageSubline.id
        }
    ],
    RaisedFoilGold: [
        {
            option: FINISH_OPTION.TextOnly,
            img: "raisedFoil-text-only-001.png",
            title: FinishMessages.textOnlyTitle.id,
            text: FinishMessages.textOnlySubline.id
        },
        {
            option: FINISH_OPTION.ImageOnly,
            img: "raisedFoil-image-only-001.png",
            title: FinishMessages.imageOnlyTitle.id,
            text: FinishMessages.imageOnlySubline.id
        },
        {
            option: FINISH_OPTION.TextAndImage,
            img: "raisedFoil-image-text-001.png",
            title: FinishMessages.textAndImageTitle.id,
            text: FinishMessages.textAndImageSubline.id
        }
    ],
    RaisedFoilSilver: [
        {
            option: FINISH_OPTION.TextOnly,
            img: "raisedFoil-text-only-001.png",
            title: FinishMessages.textOnlyTitle.id,
            text: FinishMessages.textOnlySubline.id
        },
        {
            option: FINISH_OPTION.ImageOnly,
            img: "raisedFoil-image-only-001.png",
            title: FinishMessages.imageOnlyTitle.id,
            text: FinishMessages.imageOnlySubline.id
        },
        {
            option: FINISH_OPTION.TextAndImage,
            img: "raisedFoil-image-text-001.png",
            title: FinishMessages.textAndImageTitle.id,
            text: FinishMessages.textAndImageSubline.id
        }
    ],
    RaisedFoilGlitterSilver: [
        {
            option: FINISH_OPTION.TextOnly,
            img: "raisedFoil-text-only-001.png",
            title: FinishMessages.textOnlyTitle.id,
            text: FinishMessages.textOnlySubline.id
        },
        {
            option: FINISH_OPTION.ImageOnly,
            img: "raisedFoil-image-only-001.png",
            title: FinishMessages.imageOnlyTitle.id,
            text: FinishMessages.imageOnlySubline.id
        },
        {
            option: FINISH_OPTION.TextAndImage,
            img: "raisedFoil-image-text-001.png",
            title: FinishMessages.textAndImageTitle.id,
            text: FinishMessages.textAndImageSubline.id
        }
    ]
};

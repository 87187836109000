export const getTransformedColorList = (colorList: string[]): string[] => {
    let result;

    switch (colorList.length) {
        case 1:
            result = [colorList[0], colorList[0], colorList[0], colorList[0]];
            break;
        case 2:
            result = [colorList[0], colorList[1], colorList[0], colorList[1]];
            break;
        case 3:
            result = [colorList[0], colorList[1], colorList[2], colorList[2]];
            break;
        default:
            result = colorList;
    }

    return result;
};

export const isUnique = <T>(element: T, index: number, array: T[]): boolean => array.indexOf(element) === index;

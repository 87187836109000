import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@shared/redux";
import { type Design, getDesignVariation } from "@internal/data-access-gallery";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { usePrimaryTemplateToken } from "@shared/features/StudioBootstrap";

export function useDesignVariations(designVariationOptions: string) {
    const { productKey, customerSelectedProductOptions, locale, template } = useProductAndProjectStateManager().data;

    const primaryTemplateToken = usePrimaryTemplateToken();
    const [designVariations, setDesignVariations] = useState<Design[]>([]);
    const easelLoaded = useAppSelector(state => state.easelLoaded);

    const getDesignVariations = useCallback(
        (templateToken: string) => {
            if (!productKey || !customerSelectedProductOptions || !locale || !easelLoaded) {
                return;
            }

            const newCustomerSelectedProductOptions = JSON.parse(JSON.stringify(customerSelectedProductOptions));
            if (newCustomerSelectedProductOptions?.[designVariationOptions]) {
                delete newCustomerSelectedProductOptions?.[designVariationOptions];
            }
            getDesignVariation({
                key: productKey,
                studioSelectedProductOptions: newCustomerSelectedProductOptions,
                templateToken,
                locale,
                variableOptions: designVariationOptions
            }).then(setDesignVariations);
        },
        [easelLoaded, customerSelectedProductOptions, designVariationOptions, locale, productKey]
    );

    useEffect(() => {
        if (!template) {
            return;
        }
        getDesignVariations(primaryTemplateToken || template);
    }, [primaryTemplateToken, template, getDesignVariations]);

    return { designVariations };
}

useDesignVariations.displayName = "useDesignVariations";

import React from "react";
import { observer } from "mobx-react-lite";
import { useErrors } from "@internal/utils-errors";
import { ErrorToast, AlertToast } from "./systemMessages";
import classNames from "classnames";
import styles from "./Toaster.module.scss";
import { Box } from "@vp/swan";

interface ToasterProps {
    className?: string;
}

export const Toaster = observer(({ className }: Readonly<ToasterProps>) => {
    const { errorState } = useErrors();

    return (
        <Box className={classNames(styles.toastWrapper, className)}>
            <Box className={styles.toasts} aria-live="assertive" aria-atomic="true" data-testid="toasts-container">
                {!errorState.showErrorPage && (
                    <>
                        <AlertToast />
                        <ErrorToast />
                    </>
                )}
            </Box>
        </Box>
    );
});
Toaster.displayName = "Toaster";

import { type PanelProperties } from "@design-stack-vista/cimdoc-state-manager";
import { SurfaceUpsell } from "@internal/data-access-calcifer";
import {
    CUSTOM_SELECTED_TEMPLATE,
    BLANK_SELECTED_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE,
    DUPLICATE_FIRST_PANEL_TEMPLATE
} from "@internal/data-access-template";

/**
 * Enum representing the available print color options for a product.
 */
export enum PrintColorProductOptions {
    /**
     * The product has a 0/4 - 0/CMYK print color option.
     */
    insideOnly = "0/4 - 0/CMYK",
    /**
     * The product has a 4/0 - CMYK print color option.
     */
    outsideOnly = "4/0 - CMYK"
}

/**
 * Enum representing the available color modes for a panel design.
 */
export enum COLOR_MODE {
    /**
     * The panel design is in full color.
     */
    COLOR = "color",
    /**
     * The panel design is blank.
     */
    BLANK = "blank",
    /**
     * The panel design is in monochrome.
     */
    MONOCHROME = "monochrome",
    /**
     * The panel design is in grayscale.
     */
    GRAYSCALE = "grayscale"
}

export type TemplateType =
    | typeof CUSTOM_SELECTED_TEMPLATE
    | typeof BLANK_SELECTED_TEMPLATE
    | typeof FULLBLEED_SELECTED_TEMPLATE
    | typeof DUPLICATE_FIRST_PANEL_TEMPLATE;

export interface ExtendedSurfaceUpsell extends SurfaceUpsell {
    selectedTemplate?: TemplateType | string;
    pricing?: Record<string, { differentialDiscountPrice: number; differentialListPrice: number }>;
}

export type SurfaceUpsellDataType = Record<string, ExtendedSurfaceUpsell>;

export interface SurfaceUpsellPanelProperties extends Omit<PanelProperties, "width" | "height"> {
    width: number;
    height: number;
}

import type { CdifColor } from "@design-stack-vista/cimdoc-state-manager";
import { rgb2hex } from "@design-stack-vista/utility-core";
import { parseColor } from "@mcp-artwork/color-converter";
import { isSingleColor } from "@internal/utils-deco-tech";
import type { ColorPaletteType, ColorPaletteEntry } from "../types";

export class SingleColorColorPalette implements ColorPaletteType {
    readonly entries: ColorPaletteEntry[];

    readonly customColors = {
        enabled: false
    };

    constructor(entries: ColorPaletteEntry[]) {
        this.entries = entries;
    }

    supports(decorationTechnology: string): boolean {
        return isSingleColor(decorationTechnology);
    }

    fromCdif(cdifColor: CdifColor): ColorPaletteEntry | undefined {
        return this.entries.find(entry => entry.cdifValue === cdifColor);
    }

    toCdif(color: string): CdifColor | undefined {
        try {
            const parsedColor = parseColor(color);

            if (!parsedColor) {
                return undefined;
            }

            switch (parsedColor?.colorSpace) {
                case "rgb": {
                    const sixDigitHex = rgb2hex(parsedColor.value);
                    return this.entries.find(entry => entry.cssBackground === sixDigitHex)?.cdifValue;
                }
                default:
                    return color;
            }
        } catch {
            return undefined;
        }
    }
}

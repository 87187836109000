/**
 * @note sim imports are OK in this file. This is how Studio imports all sims.
 * However, no other file in the Studio app should direclty import sims.
 */
/* eslint-disable no-restricted-imports */
import type { SimInterface } from "@internal/sim-framework";
import { legacyTextSim } from "@six/features/editorUI/textEditing";
import { legacyImageSim } from "@six/features/editorUI/imageContentPanel";
import { legacyTableSim } from "@six/features/editorUI/tableEditing";
import { legacyBackgroundSim } from "@six/features/editorUI/backgroundColor";
import { legacyIconSim, legacyShapeSim } from "@six/features/editorUI/shapesContentPanel";

import { sims } from "@internal/dex";

const LEGACY_SIMS = [legacyTextSim, legacyImageSim, legacyTableSim, legacyBackgroundSim, legacyIconSim, legacyShapeSim];

/**
 * Since legacy sims aren't yet extracted, we use this to map sim names to
 * their respective legacy sim modules. This is some tech debt we'll pay down as we extract
 * legacy sims.
 */
const simModules = Array.from(sims).map(sim => {
    if (typeof sim === "string") {
        const matchingSim = LEGACY_SIMS.find(legacySim => legacySim.name === sim);

        if (!matchingSim) {
            throw new Error(`Unknown sim: ${sim}`);
        }

        return matchingSim;
    }
    return sim;
});

export const ALL_SIM_MODULES = new Set<SimInterface>(simModules);

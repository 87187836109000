import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { ColorPaletteEntry } from "../types";

const standardPaletteColorsForEmbroidery = [
    { thread: "thread(mcp0015)", hex: "#FFFFFF" },
    { thread: "thread(mcp0145)", hex: "#C5D3DA" },
    { thread: "thread(mcp0108)", hex: "#7F878B" },
    { thread: "thread(mcp0132)", hex: "#4A555D" },
    { thread: "thread(mcp0020)", hex: "#282E2E" },
    { thread: "thread(mcp3241)", hex: "#8C91B1" },
    { thread: "thread(mcp2830)", hex: "#9377B1" },
    { thread: "thread(mcp2905)", hex: "#604D99" },
    { thread: "thread(mcp3110)", hex: "#383574" },
    { thread: "thread(mcp3640)", hex: "#99BDE8" },
    { thread: "thread(mcp3962)", hex: "#A7DBF2" },
    { thread: "thread(mcp4111)", hex: "#43B5CD" },
    { thread: "thread(mcp3901)", hex: "#14A1FF" },
    { thread: "thread(mcp3902)", hex: "#0B72A7" },
    { thread: "thread(mcp3743)", hex: "#2F506B" },
    { thread: "thread(mcp3323)", hex: "#263663" },
    { thread: "thread(mcp3544)", hex: "#174D95" },
    { thread: "thread(mcp3522)", hex: "#054ABD" },
    { thread: "thread(mcp3732)", hex: "#001C73" },
    { thread: "thread(mcp0520)", hex: "#F4E79F" },
    { thread: "thread(mcp0600)", hex: "#FAD815" },
    { thread: "thread(mcp0940)", hex: "#E6CA00" },
    { thread: "thread(mcp0904)", hex: "#F8BE00" },
    { thread: "thread(mcp0703)", hex: "#FDB500" },
    { thread: "thread(mcp1102)", hex: "#ED9206" },
    { thread: "thread(mcp1300)", hex: "#F66B29" },
    { thread: "thread(mcp2113)", hex: "#6C2431" },
    { thread: "thread(mcp1904)", hex: "#AF2529" },
    { thread: "thread(mcp1800)", hex: "#D90211" },
    { thread: "thread(mcp1903)", hex: "#DE0005" },
    { thread: "thread(mcp1703)", hex: "#FF2621" },
    { thread: "thread(mcp2363)", hex: "#F7DEEA" },
    { thread: "thread(mcp2550)", hex: "#E49CC4" },
    { thread: "thread(mcp2153)", hex: "#C68494" },
    { thread: "thread(mcp1950)", hex: "#E34D79" },
    { thread: "thread(mcp2508)", hex: "#BC3F88" },
    { thread: "thread(mcp2300)", hex: "#B92860" },
    { thread: "thread(mcp5050)", hex: "#B8E4DD" },
    { thread: "thread(mcp5220)", hex: "#93D7B9" },
    { thread: "thread(mcp4610)", hex: "#3EA19C" },
    { thread: "thread(mcp5500)", hex: "#7CE553" },
    { thread: "thread(mcp5510)", hex: "#4BA044" },
    { thread: "thread(mcp5324)", hex: "#245E41" },
    { thread: "thread(mcp6051)", hex: "#BFCF93" },
    { thread: "thread(mcp6031)", hex: "#C9D000" },
    { thread: "thread(mcp1060)", hex: "#F1DABB" },
    { thread: "thread(mcp1141)", hex: "#CEA984" },
    { thread: "thread(mcp0721)", hex: "#B38533" },
    { thread: "thread(mcp0931)", hex: "#B06A23" },
    { thread: "thread(mcp0933)", hex: "#6D4A2F" },
    { thread: "thread(mcp0747)", hex: "#655330" },
    { thread: "thread(mcp0672)", hex: "#C3BDA7" },
    { thread: "thread(mcp1172)", hex: "#CFBFA0" },
    { thread: "thread(mcp0853)", hex: "#977F5E" },
    { thread: "thread(mcp6133)", hex: "#887A31" },
    { thread: "thread(mcp0776)", hex: "#776F62" }
];

export const getEmbroideryColorPalette = (locale: string): ColorPaletteEntry[] => {
    return standardPaletteColorsForEmbroidery.map(({ thread, hex }) => ({
        cdifValue: thread,
        cssBackground: hex,
        name: i18nColorName(hex, { culture: locale, colorPalette: "gallery" })
    }));
};

import type { ScopedSimName } from "@internal/utils-sim-types";
import type { DialogRecord, DialogTypeValue, ActiveDialogScope } from "./types";

export const buildPluginActiveDialogTypes =
    <Scope extends ActiveDialogScope | ScopedSimName>(scope: Scope) =>
    <Type extends keyof DialogRecord>(dialogTypes: Type[]): Record<Type, DialogTypeValue> => {
        const obj = dialogTypes.reduce((acc, currentValue) => {
            return {
                ...acc,
                [currentValue]: Symbol(`${scope}/${currentValue}`)
            };
        }, {} as Record<Type, DialogTypeValue>);

        return obj satisfies DialogRecord;
    };

export const buildVistaActiveDialogTypes = (simName: string) =>
    buildPluginActiveDialogTypes(`@internal/advanced-editor-sim-${simName}`);

export const buildLegacyActiveDialogTypes = buildPluginActiveDialogTypes("@legacy");
export const buildCoreActiveDialogTypes = buildPluginActiveDialogTypes("@core");
export const buildContentDiscoveryZoneActiveDialogTypes = buildPluginActiveDialogTypes("@cdz");

import { createSlice } from "@reduxjs/toolkit";
import { setShowSaveAs } from "./showSaveAs";

const isRenamingProjectSlice = createSlice({
    name: "isRenamingProject",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setShowSaveAs, (state, action) => action.payload.isRenamingProject ?? false);
    }
});

export const isRenamingProject = isRenamingProjectSlice.reducer;

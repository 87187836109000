import type { ImageItem } from "@design-stack-vista/cdif-types";
import { changeColor, type ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemPreviewExtension, useDesignEngine, useSelectedItems } from "@design-stack-vista/core-features";
import { ItemProcessingExtension } from "@design-stack-vista/interactive-design-engine-core";
import { useAsyncEffect } from "@design-stack-vista/utility-react";
import { type SceneConfig } from "@internal/data-access-calcifer";
import { useColorPaletteForSelectedItems } from "@internal/feature-color-palette";
import { type SelectableColor } from "@internal/utils-color";
import { when } from "mobx";
import { observer } from "mobx-react-lite";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    type PropsWithChildren
} from "react";
import { SingleColorImageExtension } from "../extension";
import { type SingleColorDclMetadata } from "../types";
import {
    getDefaultInversion,
    getDefaultThreshold,
    getSelectableColorFromActivePanel,
    getSelectableColorFromImageURL,
    waitForImageToBeReady,
    replaceImageInCimDoc
} from "../util";
import { useSingleColorContrastProperties } from "./useSingleColorContrastProperties";

interface SingleColorContrastType {
    inverted: boolean;
    threshold: number;
    color?: SelectableColor;
    preview?: string;
    underlaySceneConfiguration?: SceneConfig;
    imageUrl?: string;
    handleApplyChange: () => void;
    handleChangeColor: (color: SelectableColor) => void;
    handleInvertStatusChange: () => void;
    handleThresholdUpdate: (threshold: number) => void;
    handleCloseModal: () => void;
}

const SingleColorContext = createContext<SingleColorContrastType | undefined>(undefined);

export const useSingleColorContrast = () => {
    const result = useContext(SingleColorContext);
    if (!result) {
        throw Error("Please call this within an SingleColorContrastProvider");
    }
    return result;
};

interface SingleColorContrastProviderProps {
    underlaySceneConfiguration?: SceneConfig;
}

export const SingleColorContrastProvider = observer(
    ({ children, underlaySceneConfiguration }: PropsWithChildren<SingleColorContrastProviderProps>) => {
        const designEngine = useDesignEngine();
        const { selectedItems } = useSelectedItems();
        const selectedItemColorPalette = useColorPaletteForSelectedItems();
        const { rasterizedImageFile, getMonochromeUrl, imageUrl } = useSingleColorContrastProperties();

        const { designExtensionSystem, executeCommand } = designEngine;
        const selectImageItem = selectedItems[0];

        const singleColorExtension = designExtensionSystem.getExtension(selectImageItem.iid, SingleColorImageExtension);
        const itemProcessingExtension = designExtensionSystem.getExtension(
            selectImageItem.iid,
            ItemProcessingExtension
        );
        const itemPreviewExtension = designExtensionSystem.getExtension(selectImageItem.iid, ItemPreviewExtension);

        if (!singleColorExtension) {
            throw new Error("SingleColorImageExtension must be provided");
        }

        if (!itemProcessingExtension) {
            throw new Error("ItemProcessingExtension must be provided");
        }

        if (!itemPreviewExtension) {
            throw new Error("ItemPreviewExtension must be provided");
        }

        if (!selectedItemColorPalette) {
            throw new Error("Single ColorPalette must be provided");
        }

        const [threshold, setThreshold] = useState(getDefaultThreshold);
        const [isInverted, setIsInverted] = useState(getDefaultInversion);
        const [color, setColor] = useState<SelectableColor>();

        /**
         * This effect is responsible for calculating the dominant color.
         * On addition of new image the extension return undefined color therefore we calculate it here itself
         * to show it in preview.
         * This use-effect is to be executed only if `color` isn't available from the extension.
         */
        useAsyncEffect(
            helper => {
                helper.runIfMounted(async () => {
                    if (!color) {
                        // While uploading the single color extension does not returns a color so we need to calculate ourselves
                        // First we check color on the panel if it's not an engraving product
                        // For engraving products the panel color will be `spotEngraving`
                        // So skipping for engraving products
                        if (!singleColorExtension.isEngraving) {
                            const colorOnPanel = getSelectableColorFromActivePanel({ designEngine });
                            if (colorOnPanel) {
                                setColor(colorOnPanel);
                                return;
                            }
                        }

                        // If there is no item on active panel we calculate the dominant color
                        if (rasterizedImageFile) {
                            const dominantSelectableColorFromImage = await getSelectableColorFromImageURL(
                                rasterizedImageFile,
                                selectedItemColorPalette
                            );
                            if (dominantSelectableColorFromImage) {
                                setColor(dominantSelectableColorFromImage);
                            }
                        }
                    }
                });
            },
            [rasterizedImageFile, selectedItemColorPalette, designEngine, color, singleColorExtension.isEngraving]
        );

        const updateProperties = useCallback(() => {
            const colorValue = singleColorExtension.getColorProperty(selectedItemColorPalette);
            setColor(colorValue);
            const thresholdAndInvertStatus = singleColorExtension.thresholdAndInvertedStatusValues;
            setIsInverted(thresholdAndInvertStatus.invert);
            setThreshold(thresholdAndInvertStatus.threshold);
        }, [selectedItemColorPalette, singleColorExtension]);

        useEffect(() => {
            /**
             * When the user clicks on an Image this effect should run and get the (color, invert, threshold) values from
             * SingleColorExtension to show correct values on modal render
             */
            updateProperties();
        }, [updateProperties]);

        const handleImageReplace = useCallback(
            async (imageItem: ItemState<ImageItem>, selectedColor: SelectableColor) => {
                const {
                    id: imageId,
                    model: { originalSourceUrl }
                } = imageItem;

                const monoChromeStackedVariantURL = await getMonochromeUrl({ isInverted, threshold });

                if (monoChromeStackedVariantURL) {
                    await waitForImageToBeReady(monoChromeStackedVariantURL, "update-monochrome-url");
                    const imageAttributes = {
                        originalSourceUrl,
                        overlays: [
                            {
                                color: selectedColor.value,
                                printUrl: monoChromeStackedVariantURL,
                                previewUrl: monoChromeStackedVariantURL
                            }
                        ]
                    };
                    executeCommand(cimdoc => {
                        replaceImageInCimDoc(cimdoc, { id: imageId, imageAttributes });
                        const imageDclMetaData = cimdoc.metadata?.dclMetadata?.filter(meta => meta.id === imageId);
                        if (imageDclMetaData?.length) {
                            const metadata = imageDclMetaData[0] as unknown as SingleColorDclMetadata;
                            metadata.threshold = threshold;
                            metadata.inverted = isInverted;
                        }
                    }, {});
                    /**
                     * When the user replaces the image we need to update the color, invert and threshold values
                     */
                    updateProperties();
                }
            },
            [executeCommand, getMonochromeUrl, isInverted, updateProperties, threshold]
        );

        const processItemPreviewStatus = useCallback(
            (itemProcessingState: Function) => {
                when(
                    () => itemPreviewExtension?.renderingStatus === "COMPLETE",
                    () => {
                        itemProcessingState();
                    }
                );
            },
            [itemPreviewExtension?.renderingStatus]
        );

        const handleColorUpdate = useCallback(
            (imageId: string, cdifColorValue: string) => {
                executeCommand(cimdoc => {
                    changeColor(cimdoc, {
                        itemId: imageId,
                        color: cdifColorValue,
                        propertyPath: "overlays[0].color"
                    });
                }, {});
            },
            [executeCommand]
        );

        const hasOnlyColorChanged = useCallback((): boolean => {
            if (color) {
                const imageColorProp = singleColorExtension.getColorProperty(selectedItemColorPalette);
                const thresholdAndInvertStatus = singleColorExtension.thresholdAndInvertedStatusValues;
                return (
                    color.value !== imageColorProp?.value &&
                    thresholdAndInvertStatus.invert === isInverted &&
                    thresholdAndInvertStatus.threshold === threshold
                );
            }
            return false;
        }, [color, isInverted, selectedItemColorPalette, singleColorExtension, threshold]);

        const applyContrastChanges = useCallback(async () => {
            if (selectImageItem.isImageItem() && color) {
                const { id: imageId } = selectImageItem;
                // When there is only change in color, we don't need to
                // generate a new asset we'll only update the color property
                const onlyColorPropertyUpdated = hasOnlyColorChanged();

                if (onlyColorPropertyUpdated) {
                    /**
                     * Check if printUrl is already present in overlays array. If it's not present that means monochromization is
                     * in progress and we may end up pushing an invalid CimDoc with overlays having only color property
                     */
                    if (singleColorExtension.hasOverlayPrintUrl) {
                        handleColorUpdate(imageId, color.value);
                    }
                } else {
                    await handleImageReplace(selectImageItem, color);
                }
            }
        }, [color, handleColorUpdate, handleImageReplace, hasOnlyColorChanged, selectImageItem, singleColorExtension]);

        const handleApplyChange = useCallback(async () => {
            const itemProcessingState = itemProcessingExtension.startProcessing();
            await applyContrastChanges();
            processItemPreviewStatus(itemProcessingState);
        }, [itemProcessingExtension, processItemPreviewStatus, applyContrastChanges]);

        const handleChangeColor = useCallback((chosenColor: SelectableColor) => {
            setColor(chosenColor);
        }, []);

        const handleInvertStatusChange = useCallback(() => {
            setIsInverted(status => !status);
        }, []);

        const handleThresholdUpdate = useCallback((value: number) => {
            setThreshold(value);
        }, []);

        const contextObject = useMemo(() => {
            return {
                color,
                inverted: isInverted,
                threshold,
                handleApplyChange,
                handleChangeColor,
                handleInvertStatusChange,
                handleThresholdUpdate,
                handleCloseModal: updateProperties,
                underlaySceneConfiguration,
                imageUrl
            };
        }, [
            color,
            isInverted,
            threshold,
            handleApplyChange,
            handleChangeColor,
            handleInvertStatusChange,
            handleThresholdUpdate,
            updateProperties,
            underlaySceneConfiguration,
            imageUrl
        ]);

        return <SingleColorContext.Provider value={contextObject}>{children}</SingleColorContext.Provider>;
    }
);

import React, { FC } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { SiteContent, SiteMain, SkipLink } from "@vp/swan";
import { useTrackingClient } from "@internal/utils-tracking";
import { Performance } from "@shared/features/Performance";
import { Studio6ErrorBoundary } from "./Studio6ErrorBoundary";

const messages = defineMessages({
    linkTitle: {
        id: "studio.components.skipLink.linkTitle",
        defaultMessage: "Skip to main",
        description: {
            note: "Title for skip link"
        }
    }
});

export const Studio6SiteContent: FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { t } = useTranslationSSR();
    const trackingClient = useTrackingClient();

    return (
        <SiteContent>
            <SkipLink>{t(messages.linkTitle.id)}</SkipLink>
            <SiteMain role="main">
                <Studio6ErrorBoundary trackingClient={trackingClient}>
                    <SkeletonTheme baseColor="#d8d8d8" highlightColor="#bdbdbd">
                        {children}
                    </SkeletonTheme>
                    <Performance />
                </Studio6ErrorBoundary>
            </SiteMain>
        </SiteContent>
    );
};

Studio6SiteContent.displayName = "Studio6SiteContent";

import { useMemo } from "react";
import { useStudioConfigurationManager } from "@internal/dex";
import {
    mapDesignAttributeToProductOption,
    mapDesignAttributeValueToProductOptionValue
} from "@internal/utils-studio-configuration-core";
import { useCartContext } from "@internal/utils-cart";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { Path } from "@design-stack-vista/ida-framework";
import { useDesignAttributeMappings } from "@shared/features/StudioBootstrap";
import { decoTechCategories, useGetDecorationTechnology } from "@internal/utils-deco-tech";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import type { ProductOption } from "@internal/data-access-product";
import type { Design } from "@internal/data-access-gallery";
import { useResizeNoDesignVariationsExperiment } from "@internal/ab-test-resize-no-design-variations";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { useCompatibleFlexibilityDesignAttributes } from "../common/hooks/useCompatibleFlexibilityDesignAttributes";
import { FlexibilityDesignAttributes } from "../constants";
import { useDesignVariations } from "../common/hooks/useDesignVariations";
import { useSizeOptionsAvailabilityTracking } from "./useSizeOptionsAvailabilityTracking";

const filterDesignAttributes = (
    designAttributes: string[],
    designAttributeMappings: ProductOption[],
    designVariations: Design[],
    productOptionName: string
) =>
    designAttributes?.filter(designAttribute => {
        const productOptionValue = mapDesignAttributeValueToProductOptionValue(
            designAttributeMappings,
            FlexibilityDesignAttributes.Size,
            designAttribute
        );
        return designVariations.find(
            (designVariation: any) => designVariation.fullProductOptions[productOptionName] === productOptionValue
        );
    }) || [];

const isDisabled = (
    hasMultipleSafetyOrTrim: boolean,
    itemInCart: boolean,
    emptyByConfigurationFlag: boolean,
    isNotPrintDecorationTechnology: boolean,
    disabledByDesignVariations: boolean,
    disabledByDesignAttributes: boolean
) => {
    return (
        hasMultipleSafetyOrTrim ||
        itemInCart ||
        emptyByConfigurationFlag ||
        isNotPrintDecorationTechnology ||
        disabledByDesignVariations ||
        disabledByDesignAttributes
    );
};

export const useSizeCompatibleFlexibilityDesignAttributes = () => {
    const { isItemInCart } = useCartContext();
    const designAttributeMappings = useDesignAttributeMappings();
    const designAttributes = useCompatibleFlexibilityDesignAttributes(FlexibilityDesignAttributes.Size);
    const productOptionName = mapDesignAttributeToProductOption(
        designAttributeMappings,
        FlexibilityDesignAttributes.Size
    );
    const { designVariations } = useDesignVariations(productOptionName);
    const { showChangeSize } = useStudioConfigurationManager().data;
    const { template } = useProductAndProjectStateManager().data;
    const decorationTechnology = useGetDecorationTechnology();
    const designRequirements = useDesignRequirementsContext();
    const sizeOptionsAvailabilityTracking = useSizeOptionsAvailabilityTracking();
    const { isResizeNoDesignVariationsABEnabled } = useResizeNoDesignVariationsExperiment();
    const disableResizeAllValidation = useIsFlagEnabled("disableResizeAllValidation");
    const disableResizeDecoTechValidation = useIsFlagEnabled("disableResizeDecoTechValidation");
    const disableResizeDesignVariationsValidation = useIsFlagEnabled("disableResizeDesignVariationsValidation");

    // Artwork generation /resize endpoint does not support multiple trim/safety paths
    // https://vistaprint.slack.com/archives/C05NM71RJQM/p1706085274616499?thread_ts=1706063605.685229&cid=C05NM71RJQM
    // since we may need to use that endpoint when change size is used, we have to disable the feature such products
    const hasMultipleSafetyOrTrim =
        designRequirements?.panels.some(panel => {
            const safetyMasks =
                panel.masks
                    ?.filter(mask => mask.type === "SAFE")
                    .reduce((acc, current) => {
                        return [...acc, ...current.paths];
                    }, [] as Path[]) ?? [];
            const trimMasks =
                panel.masks
                    ?.filter(mask => mask.type === "TRIM")
                    .reduce((acc, current) => {
                        return [...acc, ...current.paths];
                    }, [] as Path[]) ?? [];
            return safetyMasks.length > 1 || trimMasks?.length > 1;
        }) ?? false;
    const isPrintDecorationTechnology =
        Boolean(decorationTechnology) &&
        // @ts-ignore FIXME: must handle implicit `any` type
        decoTechCategories[decorationTechnology] === "print";

    const sizeAttributes = useMemo(() => {
        const disabledByDesignVariations =
            !disableResizeDesignVariationsValidation &&
            !!template &&
            (!designVariations || designVariations.length < 1);
        const disabledByDesignAttributes = !designAttributes || designAttributes.length <= 1;
        const emptyByConfigurationFlag = !showChangeSize;
        const isNotPrintDecorationTechnology = !disableResizeDecoTechValidation && !isPrintDecorationTechnology;

        if (disableResizeAllValidation) {
            return designAttributes;
        }

        // we still want to respect print deco tech, multiple safety/trim, item is in cart, and the show size configuration flag
        if (
            isResizeNoDesignVariationsABEnabled &&
            designAttributes &&
            designAttributes.length > 1 &&
            !isNotPrintDecorationTechnology &&
            !hasMultipleSafetyOrTrim &&
            !isItemInCart &&
            !emptyByConfigurationFlag
        ) {
            return designAttributes;
        }

        if (
            isDisabled(
                hasMultipleSafetyOrTrim,
                isItemInCart,
                emptyByConfigurationFlag,
                isNotPrintDecorationTechnology,
                disabledByDesignVariations,
                disabledByDesignAttributes
            )
        ) {
            sizeOptionsAvailabilityTracking({
                disabledByDesignVariations,
                disabledByDesignAttributes,
                hasMultipleSafetyOrTrim,
                isItemInCart,
                emptyByConfigurationFlag,
                isNotPrintDecorationTechnology,
                decorationTechnology
            });
            return [];
        }

        sizeOptionsAvailabilityTracking();

        return template
            ? filterDesignAttributes(designAttributes, designAttributeMappings, designVariations, productOptionName)
            : designAttributes;
    }, [
        disableResizeDesignVariationsValidation,
        template,
        designVariations,
        designAttributes,
        showChangeSize,
        disableResizeDecoTechValidation,
        isPrintDecorationTechnology,
        disableResizeAllValidation,
        isResizeNoDesignVariationsABEnabled,
        hasMultipleSafetyOrTrim,
        isItemInCart,
        sizeOptionsAvailabilityTracking,
        designAttributeMappings,
        productOptionName,
        decorationTechnology
    ]);

    return sizeAttributes;
};

import { jsx } from "react/jsx-runtime";
const RoundedRectangleCutlineIcon = (props) => /* @__PURE__ */ jsx("svg", {
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props,
  children: /* @__PURE__ */ jsx("path", {
    d: "M32.438 1.313H8.061a6.5 6.5 0 0 0-6.5 6.5v24.375a6.5 6.5 0 0 0 6.5 6.5h24.376a6.5 6.5 0 0 0 6.5-6.5V7.811a6.5 6.5 0 0 0-6.5-6.5Z",
    fill: "#fff",
    stroke: "black",
    strokeWidth: 1.625
  })
});
export {
  RoundedRectangleCutlineIcon
};

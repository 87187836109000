import { CurveItemV2 } from "@design-stack-vista/cdif-types";
import { Path, Point } from "@design-stack-vista/interactive-design-engine-core";
export const convertCurveItemToPath = (curveItem: CurveItemV2): Path => {
    // Split the path by space or comma
    const commands = curveItem.svgPathData.split(/[\s,]+/);

    if (commands[0][0] !== "M") {
        throw new Error("Path must start with an M command");
    }

    // Extract the anchor point
    const anchor = {
        // use substring to remove the leading 'M'
        x: parseFloat(commands[0].substring(1)),
        y: parseFloat(commands[1])
    };

    const points: Point[] = [];
    for (let i = 2; i < commands.length; i += 6) {
        if (commands[i][0] !== "C") {
            throw new Error("Expected a C command");
        }

        // Create a point with control points
        // See https://www.w3.org/TR/SVG/paths.html#PathDataCubicBezierCommands
        points.push({
            x: parseFloat(commands[i + 4]),
            y: parseFloat(commands[i + 5]),
            controlPoints: {
                // use substring to remove the leading 'C'
                first: { x: parseFloat(commands[i].substring(1)), y: parseFloat(commands[i + 1]) },
                second: { x: parseFloat(commands[i + 2]), y: parseFloat(commands[i + 3]) }
            }
        });
    }

    return {
        points,
        anchor
    };
};

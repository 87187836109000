import type { UnitlessDimensions } from "@design-stack-vista/utility-core";
import { degreesToRadians } from "@design-stack-vista/utility-core";
import { getContentOrientationAngle } from "./getContentOrientationAngle";

function angleOfSwitchSide(sectionAspectRatio: number, itemAspectRatio: number) {
    const tanOfAngleOfSwitch = (itemAspectRatio - sectionAspectRatio) / (itemAspectRatio * sectionAspectRatio - 1);
    return Math.atan(tanOfAngleOfSwitch);
}

function calculateWidthWhenTouchingSideA(angleOfRotation: number, sectionWidth: number, itemAspectRatio: number) {
    return (sectionWidth * itemAspectRatio) / (itemAspectRatio * Math.cos(angleOfRotation) + Math.sin(angleOfRotation));
}

function calculateWidthWhenTouchingSideB(angleOfRotation: number, sectionHeight: number, itemAspectRatio: number) {
    return (
        (sectionHeight * itemAspectRatio) / (itemAspectRatio * Math.sin(angleOfRotation) + Math.cos(angleOfRotation))
    );
}

function getAngleMadeWithXAxis(angleInDegree: number) {
    if (angleInDegree >= 0 && angleInDegree < 90) {
        return angleInDegree;
    }
    if (angleInDegree >= 90 && angleInDegree < 180) {
        return 180 - angleInDegree;
    }
    if (angleInDegree >= 180 && angleInDegree < 270) {
        return angleInDegree - 180;
    }
    if (angleInDegree >= 270 && angleInDegree < 360) {
        return 360 - angleInDegree;
    }
    return angleInDegree;
}

type ItemDimensionFitOnRotation = {
    targetDimensions: UnitlessDimensions;
    itemAspectRatio: number;
    contentOrientationAngle: number;
};

/**
 * @description gets dimensions of the item when it is added to the section.
 */
export function getItemDimensionFitOnRotation({
    targetDimensions,
    itemAspectRatio,
    contentOrientationAngle
}: ItemDimensionFitOnRotation): UnitlessDimensions {
    const sectionAspectRatio = targetDimensions.width / targetDimensions.height;

    const contentOrientation = getContentOrientationAngle(contentOrientationAngle.toString());

    const angleWithXAxis = getAngleMadeWithXAxis(contentOrientation);

    const orientationAngle = degreesToRadians(angleWithXAxis);

    const angleOfSwitch = angleOfSwitchSide(sectionAspectRatio, itemAspectRatio); // negative angleOfSwitch means that we can not switch

    let newWidth;

    if (itemAspectRatio > sectionAspectRatio) {
        newWidth =
            orientationAngle <= angleOfSwitch || angleOfSwitch < 0
                ? calculateWidthWhenTouchingSideA(orientationAngle, targetDimensions.width, itemAspectRatio)
                : calculateWidthWhenTouchingSideB(orientationAngle, targetDimensions.height, itemAspectRatio);
    } else {
        newWidth =
            orientationAngle <= angleOfSwitch || angleOfSwitch < 0
                ? calculateWidthWhenTouchingSideB(orientationAngle, targetDimensions.height, itemAspectRatio)
                : calculateWidthWhenTouchingSideA(orientationAngle, targetDimensions.width, itemAspectRatio);
    }

    const width = Math.abs(newWidth);
    const height = Math.abs(newWidth) / itemAspectRatio;

    return { width, height };
}

export enum DecorationTechnologies {
    None = "none",
    Offset = "offset",
    Digital = "digital",
    OffsetOrDigital = "offsetOrDigital",
    DirectToGarment = "directToGarment",
    HeatTransfer = "heatTransfer",
    LaserEngraving = "laserEngraving",
    ScreenPrint = "screenPrint",
    PadPrint = "padPrint",
    InkJet = "inkJet",
    Thermography = "thermography",
    EmbroideryFlat = "embroideryFlat",
    EmbroideryCylinder = "embroideryCylinder",
    Sublimation = "sublimation",
    Unknown = "unknown"
}

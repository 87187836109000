import { useAvailableDesignEngine } from "@design-stack-vista/core-features";

export function useGetDecorationTechnology() {
    const designEngine = useAvailableDesignEngine();

    if (!designEngine || !designEngine.cimDocStore.panels.length) {
        return undefined;
    }
    return designEngine.cimDocStore.panels[0].panelProperties.decorationTechnology;
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showStudioErrorDialogSlice = createSlice({
    name: "showStudioErrorDialog",
    initialState: false,
    reducers: {
        showStudioErrorDialog(state, action: PayloadAction<boolean>) {
            return action.payload;
        }
    }
});

export const { showStudioErrorDialog } = showStudioErrorDialogSlice.actions;
export const studioErrorDialogOpen = showStudioErrorDialogSlice.reducer;

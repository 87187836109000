import React, { createContext } from "react";

export const SimLocaleContext = createContext<string | undefined>(undefined);

export interface SimLocaleProviderProps {
    locale: string;
}

/**
 * Many sims need access to arbitrary data such as locale. This provider simply exposes the locale
 * data to underlying sims. It is the responsibility of the Design Experience/Studio to populate the
 * locale value.
 * @param props
 * @returns
 */
export const SimLocaleProvider = (props: React.PropsWithChildren<SimLocaleProviderProps>) => {
    return <SimLocaleContext.Provider value={props.locale}>{props.children}</SimLocaleContext.Provider>;
};

SimLocaleProvider.displayName = "SimLocaleProvider";

import { createSlice } from "@reduxjs/toolkit";
import { setShowReplaceImageModal } from "./showReplaceImageModal";

const isReplaceOpenOnCanvasChangeSlice = createSlice({
    name: "showReplaceImageModal",
    initialState: false,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(setShowReplaceImageModal, (state, action) => action.payload.isReplaceOpenOnCanvasChange);
    }
});

export const isReplaceOpenOnCanvasChange = isReplaceOpenOnCanvasChangeSlice.reducer;

import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
const isPatternTypeReference = (item) => {
  return item.isItemReference() && item.model.type === ItemReferenceTypes.TILE_IMAGE;
};
var PatternPanelMode = /* @__PURE__ */ ((PatternPanelMode2) => {
  PatternPanelMode2["Add"] = "Add";
  PatternPanelMode2["Edit"] = "Edit";
  return PatternPanelMode2;
})(PatternPanelMode || {});
var PatternEditTabsMode = /* @__PURE__ */ ((PatternEditTabsMode2) => {
  PatternEditTabsMode2["Colors"] = "Colors";
  PatternEditTabsMode2["Theme"] = "Theme";
  PatternEditTabsMode2["Resize"] = "Resize";
  return PatternEditTabsMode2;
})(PatternEditTabsMode || {});
export {
  PatternEditTabsMode,
  PatternPanelMode,
  isPatternTypeReference
};

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { upgradeCimDoc } from "@design-stack-vista/cimdoc-state-manager";
import type { DSS } from "@vp/types-ddif";
import { tryFetch } from "@internal/utils-network";
import { getQueryParams } from "@internal/utils-browser";

const host = getQueryParams().dssUrl || "https://design-specifications.design.vpsvc.com";
const requestor = "studio";
const { templateLocale } = getQueryParams();
const entityCode = 12;

// This is copied from @vp/types-ddif because importing it here caused Gatsby errors.
export enum DocumentSourceType {
    EMPTY = "EMPTY",
    TEMPLATE_TOKEN = "TEMPLATE_TOKEN",
    MATCHING_SIGNATURE = "MATCHING_SIGNATURE",
    NONE = "NONE",
    FULLBLEED = "FULLBLEED",
    LEGACY = "LEGACY_DOCUMENT",
    DUPLICATE = "DUPLICATE"
}

export const documentSourceTypeMap = {
    EMPTY: DocumentSourceType.EMPTY,
    TEMPLATE_TOKEN: DocumentSourceType.TEMPLATE_TOKEN,
    MATCHING_SIGNATURE: DocumentSourceType.MATCHING_SIGNATURE,
    NONE: DocumentSourceType.NONE,
    FULLBLEED: DocumentSourceType.FULLBLEED,
    LEGACY: DocumentSourceType.LEGACY,
    DUPLICATE: DocumentSourceType.DUPLICATE
};

export function convertDocumentSourceType(sourceType: DocumentSourceType): DSS.DocumentSourceType {
    return sourceType as any as DSS.DocumentSourceType;
}

export const createDocumentPanelSource = (
    id: string,
    source: DocumentSourceType,
    data: string
): DSS.DocumentPanelSource => {
    // @ts-ignore FIXME: must handle implicit `any` type
    if (!documentSourceTypeMap[source]) {
        throw new Error("Document source does not match a valid source type");
    }
    return { id, source: convertDocumentSourceType(source), data };
};

export function generateDesignViewsURL(
    productKey: string,
    productVersion: number,
    optionSelections: Record<string, string>,
    locale: string
): string {
    const queryString = qs.stringify({
        requester: requestor,
        optionSelections,
        culture: templateLocale || locale
    });
    const url = `${host}/v2/designViews/products/${productKey}/${productVersion}?${queryString}`;

    return url;
}

export interface V2DesignViewsResponse {
    designViews: DSS.DesignView[];
    projectionId: string;
}

export async function getDesignDocFromTemplateToken(
    productKey: string,
    version: string | number,
    selectedOptions: Record<string, string>,
    templateToken: string,
    locale: string
): Promise<DSS.DesignDocument> {
    const queryString = qs.stringify({
        optionSelections: selectedOptions,
        templateToken,
        culture: templateLocale || locale
    });
    const url = `${host}/v2/designDocuments/products/${productKey}/${version}?${queryString}`;

    return upgradeCimDoc(
        await tryFetch({
            url,
            options: {
                headers: {
                    "Content-Type": "application/json",
                    From: "studio"
                }
            },
            moduleFunction: "designSpecificationsClient:getDesignDocFromTemplateToken",
            friendlyDescription: "Uses template token to get document with applied design",
            entityCode
        })
    );
}
export async function getDesignDocForFullbleed(
    productKey: string,
    version: string | number,
    selectedOptions: Record<string, string>,
    locale: string
): Promise<DSS.DesignDocument> {
    const queryString = qs.stringify({
        optionSelections: selectedOptions,
        fullBleed: true,
        culture: templateLocale || locale
    });
    const url = `${host}/v2/designDocuments/products/${productKey}/${version}?${queryString}`;

    return upgradeCimDoc(
        await tryFetch({
            url,
            options: {
                headers: {
                    "Content-Type": "application/json",
                    From: "studio"
                }
            },
            moduleFunction: "designSpecificationsClient:getDesignDocForFullbleed",
            friendlyDescription: "Get document for fullbleed upload",
            entityCode
        })
    );
}
export async function getDesignDocumentWithSources(
    productKey: string,
    version: string | number,
    selectedOptions: Record<string, string> | undefined,
    designDocument: DSS.DesignDocument,
    documentSources: DSS.DocumentSources | undefined,
    colorMode: "print" | "web" | undefined,
    ignoreSavedPanelIds: Array<string> = [],
    sessionId: string,
    transformations: Array<{ id: string; type: string }> | undefined,
    locale: string
): Promise<DSS.DesignDocument> {
    const queryString = qs.stringify({
        optionSelections: selectedOptions,
        colorMode,
        culture: templateLocale || locale,
        requestor: requestor,
        ignoreSavedPanelIds,
        sessionId
    });
    const body = JSON.stringify({
        designDocument,
        documentSources,
        transformations
    });

    const url = `${host}/v2/designDocuments/products/${productKey}/${version}?${queryString}`;
    return upgradeCimDoc(
        await tryFetch({
            url,
            options: {
                method: "PUT",
                body,
                headers: {
                    "Content-Type": "application/json",
                    From: "studio"
                }
            },
            moduleFunction: "designSpecificationsClient:getDesignDocumentWithSources",
            friendlyDescription: "retrieve a V2 design document with specified document sources and selected options",
            entityCode
        })
    );
}
export async function updateDocumentDimensions(
    productKey: string,
    version: string | number,
    selectedOptions: Record<string, string>,
    designDocument: DSS.DesignDocument,
    dimensionTolerancePercentage: number,
    locale: string,
    ignoreSavedPanels = false
): Promise<DSS.DesignDocument> {
    const queryString = qs.stringify({
        optionSelections: selectedOptions,
        dimensionTolerancePercentage,
        culture: templateLocale || locale,
        requestor: requestor,
        ignoreSavedPanels
    });
    const body = JSON.stringify({
        designDocument
    });

    const url = `${host}/v2/designDocuments/products/${productKey}/${version}?${queryString}`;
    return upgradeCimDoc(
        await tryFetch({
            url,
            options: {
                method: "PUT",
                body,
                headers: {
                    "Content-Type": "application/json",
                    From: "studio"
                }
            },
            moduleFunction: "designSpecificationsClient:updateDocumentDimensions",
            friendlyDescription: "retrieve a V2 design document with corrected document dimensions",
            entityCode
        })
    );
}
export async function getDesignViewsForProduct(
    productKey: string,
    version: number,
    selectedOptions: Record<string, string>,
    locale: string
): Promise<V2DesignViewsResponse> {
    const url = generateDesignViewsURL(productKey, version, selectedOptions, locale);
    const designViews = await tryFetch({
        url,
        options: {
            headers: {
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "designSpecificationsClient:getDesignViewsForProduct",
        friendlyDescription: "retrieve V2 designs views for product",
        entityCode
    });

    return designViews;
}
export async function upgradeV1DesignDocument(designDocument: DSS.DesignDocument): Promise<DSS.DesignDocument> {
    const body = JSON.stringify({
        designDocument
    });

    const url = `${host}/v2/designDocuments/upgrade?requestor=${requestor}`;
    return upgradeCimDoc(
        await tryFetch({
            url,
            options: {
                method: "POST",
                body,
                headers: {
                    "Content-Type": "application/json",
                    From: "studio"
                }
            },
            moduleFunction: "designSpecificationsClient:getDesignDocumentWithSources",
            friendlyDescription: "upgrades a V1 design document to V2",
            entityCode
        })
    );
}

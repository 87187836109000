import { useDesignEngine } from "@design-stack-vista/core-features";
import { useAvailablePremiumFinishesOnCanvas } from "./useAvailablePremiumFinishesOnCanvas";

export function useStudio6AvailablePremiumFinishesOnCanvas() {
    const designEngine = useDesignEngine();

    const activePanel = designEngine.cimDocStore.panels.find(p => designEngine.idaStore.activeDesignPanelId === p.id);

    return useAvailablePremiumFinishesOnCanvas(activePanel?.panelProperties.name);
}

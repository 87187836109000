import { containsOrTouchesPoint, Vector2 } from "@design-stack-vista/utility-core";
import type { PanelSectionSetWithBoundingBox } from "../types";
import { isSectionDesignable } from "./isSectionDesignable";

export function findSectionByPoint(panelSectionData: PanelSectionSetWithBoundingBox, point: Vector2) {
    const selectedPanelSection = panelSectionData.sections.find(section => {
        const isDesignable = isSectionDesignable(section);
        if (isDesignable && section.boundingBox) {
            const { left, right, top, bottom } = section.boundingBox;
            return containsOrTouchesPoint(point, {
                x: left,
                y: bottom,
                width: right - left,
                height: top - bottom
            });
        }
        return false;
    });
    return selectedPanelSection;
}

import React, { useCallback } from "react";
import {
    Box,
    Button,
    Divider,
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogTitle,
    Typography
} from "@vp/swan";
import { observer } from "mobx-react-lite";
import { DesktopExperience, MobileExperience, useStudioLayout } from "@internal/feature-responsive-design";
import { SingleColorPreview } from "./singleColorPreview/SingleColorPreview";
import { AdjustContrastRange } from "./AdjustContrastRange";
import { AdjustContrastColor } from "./AdjustContrastColor";
import { useLocalized } from "../../localized";
import { useSingleColorContrast } from "../../hooks";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "../../TrackingEventConstants";
import { singleColorContrastModalStore } from "../../SingleColorContrastModalStore";

interface AdjustContrastModalProps {
    enableToggleBackground: boolean;
}

export const AdjustContrastModal = observer((props: AdjustContrastModalProps) => {
    const { enableToggleBackground } = props;
    const localized = useLocalized();
    const { isSmall } = useStudioLayout();
    const { color, handleApplyChange, handleCloseModal } = useSingleColorContrast();
    const trackingClient = useTrackingClient();

    if (!singleColorContrastModalStore) {
        return null;
    }

    const { isOpen, closeModal } = singleColorContrastModalStore;

    const applyContrast = useCallback(() => {
        if (color) {
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SINGLE_COLOR_IMAGE_APPLY_BUTTON
            });
            closeModal();
            handleApplyChange();
        }
    }, [closeModal, color, handleApplyChange, trackingClient]);

    const onClose = () => {
        closeModal();
        handleCloseModal();
    };

    return (
        <ModalDialog onlyRenderWhenOpen isOpen={isOpen} onRequestDismiss={onClose}>
            <ModalDialogContent fullBleed aria-label={localized.get("modalAriaLabel")}>
                <ModalDialogHeader>
                    <ModalDialogTitle>
                        <Typography
                            fontWeight="bold"
                            fontSize={isSmall ? "large" : "standard"}
                            textAlign={isSmall ? "left" : "center"}
                            pl={"5"}
                        >
                            {localized.get("singleColorContrastModalTitle")}
                        </Typography>
                    </ModalDialogTitle>
                    <ModalDialogCloseButton visuallyHiddenLabel={localized.get("ariaCloseLabel")} />
                </ModalDialogHeader>
                <Divider />
                <ModalDialogBody>
                    <Box>
                        <DesktopExperience>
                            <>
                                <AdjustContrastColor enableToggleBackground={enableToggleBackground} />
                                <SingleColorPreview />
                                <AdjustContrastRange />
                            </>
                        </DesktopExperience>
                        <MobileExperience>
                            <>
                                <SingleColorPreview />
                                <AdjustContrastColor enableToggleBackground={enableToggleBackground} />
                            </>
                        </MobileExperience>
                    </Box>
                </ModalDialogBody>
                <ModalDialogFooter pinned>
                    <FlexBox justifyContent="center" mx={"5"} mb={"4"}>
                        <ModalDialogButtons>
                            <Button
                                skin="secondary"
                                size="mini"
                                mr={"3"}
                                onClick={onClose}
                                aria-label={localized.get("cancelContrast")}
                            >
                                {localized.get("cancelContrast")}
                            </Button>
                            <Button
                                skin="primary"
                                size="mini"
                                onClick={applyContrast}
                                aria-label={localized.get("applyContrast")}
                            >
                                {localized.get("applyContrast")}
                            </Button>
                        </ModalDialogButtons>
                    </FlexBox>
                </ModalDialogFooter>
            </ModalDialogContent>
        </ModalDialog>
    );
});

import { jsx } from "react/jsx-runtime";
import { TeamsIcon } from "@internal/ui-studio-chrome";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
const messages = /* @__PURE__ */ defineMessages({
  teamsNameButton: {
    id: "sims.teamsName.contentDiscoveryZone.teamsNameButton",
    defaultMessage: "Names",
    description: {
      note: "Label for the groups and teams button"
    }
  }
});
const TeamsNameContentDiscoveryZoneButton = ({
  children
}) => {
  const {
    t
  } = /* @__PURE__ */ useTranslationSSR();
  return children({
    icon: /* @__PURE__ */ jsx(TeamsIcon, {
      "aria-hidden": "true"
    }),
    label: /* @__PURE__ */ t(messages.teamsNameButton.id)
  });
};
export {
  TeamsNameContentDiscoveryZoneButton
};

import { useDesignEngine } from "@design-stack-vista/core-features";
import { reaction } from "mobx";
import { useEffect } from "react";
import { RecentColorsManager } from "./RecentColorsManager";
import { useGetAllColorsFromCimDoc } from "./useGetAllColorsFromCimDoc";

/**
 * Observes when the IDA active panel changes and then updates the recent colors list.
 * This is used when the color panel/sheet/dropdown is open, the user selects a color,
 * and then changes the active panel.
 *
 * @param {RecentColorsManager} recentColorsManager
 */
export const useUpdateRecentColorsOnPanelChange = (recentColorsManager: RecentColorsManager) => {
    const designEngine = useDesignEngine();
    const getAllColorsMap = useGetAllColorsFromCimDoc();

    useEffect(() => {
        const dispose = reaction(
            () => designEngine.idaStore.activeDesignPanelId,
            () => {
                if (recentColorsManager.lastSelectedColor) {
                    recentColorsManager.pushSelectedColor(recentColorsManager.lastSelectedColor);
                    recentColorsManager.resetLastSelectedColor();
                }
            }
        );
        return dispose;
    }, [designEngine, getAllColorsMap, recentColorsManager]);
};

import { createSlice } from "@reduxjs/toolkit";

const showPreviewSlice = createSlice({
    name: "showPreview",
    initialState: false,
    reducers: {
        showPreview() {
            return true;
        },
        hidePreview() {
            return false;
        }
    }
});

export const { showPreview, hidePreview } = showPreviewSlice.actions;
export const previewOpen = showPreviewSlice.reducer;

import { ArrowShape, GetSvg, ShapeTypes } from "@cimpress-technology/svg-path";
import { convertSvgToCimdoc } from "@cimpress-technology/svg-to-cimdoc";

const convert = async (oldArrow: any) => {
    const {
        id,
        zIndex,
        position,
        position: { y, width: widthInMM, height: heightInMM },
        rotationAngle: oldAngle,
        data: { color }
    } = oldArrow;

    let rotationAngle = (oldAngle || 0) - 180;
    if (rotationAngle < 0) {
        rotationAngle += 360;
    }
    const width = parseFloat(widthInMM);
    const height = parseFloat(heightInMM);
    const sidelength = Math.min(height, (2 * ((2 * width) / 3)) / Math.sqrt(3));
    const triangleHeight = (sidelength * Math.sqrt(3)) / 2;
    const headWidth = (triangleHeight * 100) / width;

    // If the height is bigger than the sideLength, we won't get an equilateral triangle. Adjust the height to reflect that
    const heightToUse = Math.min(sidelength, height);
    const newY = `${parseFloat(y) + (height - heightToUse) / 2}mm`;

    // The headWidth can be calculated by a percentage of the triangleHeight and the width.
    // The tailWidth is always the same size in the monolith compared to the head.
    // Using the smartshape interface the tailWidth was determined to be 15 to match what's exported out of the monolith.
    const shape: ArrowShape = {
        type: ShapeTypes.Arrow,
        headWidth,
        tailWidth: 33.5
    };

    const svg = GetSvg(shape, {});
    const curves = await convertSvgToCimdoc({ svgString: svg, widthInMM: parseInt(widthInMM, 10) });
    // We only get the first curve out of here as arrows from the monolith are only one curve.
    return {
        curve: {
            ...curves[0],
            id,
            zIndex,
            rotationAngle,
            stroke: { thickness: "0pt", color },
            color,
            position: { ...position, height: heightToUse, y: newY }
        },
        metadata: shape
    };
};

/**
 * Converts legacy arrows defined as item references to CDIF Shapes
 * @param cimDoc
 */
export function importItemReferenceArrows(cimDoc: any) {
    cimDoc.document.panels.forEach((panel: any) => {
        if (panel.itemReferences) {
            const arrows = panel.itemReferences.filter((reference: any) => reference.type === "Arrow");
            arrows.forEach(async (arrowReference: any) => {
                const { curve, metadata } = await convert(arrowReference);
                if (!panel.shapes) {
                    panel.shapes = [];
                }
                panel.shapes.push(curve);
                cimDoc.metadata.studioMetadata[arrowReference.id] = {
                    shapeMetadata: metadata,
                    defaultShapeMetadata: metadata
                };
            });
        }
    });
}

import { setImageColor } from "@design-stack-vista/cimdoc-state-manager";
import type { CimDoc } from "@design-stack-vista/cdif-types";
import type { HSL } from "@design-stack-vista/utility-core";
import { useDesignEngine, useSelectedItems } from "@design-stack-vista/core-features";
import { ImageColorsExtension } from "@internal/utils-color";

export interface HSLColorAdjustment {
    hueOffset: number;
    saturationMultiplier: number;
    lightnessMultiplier: number;
}

export function useSingleColor() {
    const designEngine = useDesignEngine();
    const { selectedItems } = useSelectedItems();
    const imageColorsExtension = designEngine.designExtensionSystem.getExtension(
        selectedItems[0]?.iid,
        ImageColorsExtension
    );
    function onCommitColorChange(color: HSL) {
        designEngine.executeCommand((cimDoc: CimDoc) => {
            selectedItems.forEach(selectedItem => {
                if (imageColorsExtension?.isSingleColor) {
                    setImageColor(cimDoc, { id: selectedItem.id, color });
                }
            });
        }, {});
    }

    if (selectedItems.length !== 1 || !selectedItems[0].isImageItem()) {
        return { isEnabled: false, onCommitColorChange };
    }

    return {
        enabled: imageColorsExtension?.isSingleColor,
        color: imageColorsExtension?.currentHsl,
        onCommitColorChange
    };
}

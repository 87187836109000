import { hex2rgb, rgb2hex } from "@design-stack-vista/utility-core";
import type { ColorPaletteType } from "@internal/feature-color-palette";
import type { SelectableColor } from "@internal/utils-color";
import { getDominantColorFromImage } from "./getDominantColorFromImage";
import { searchForSimilarColor } from "./searchForSimilarColor";

export function findSelectableColor(
    selectedItemColorPalette: ColorPaletteType,
    colorValue: string
): SelectableColor | undefined {
    const selectedItemFromCdif = selectedItemColorPalette.fromCdif(colorValue);
    if (selectedItemFromCdif) {
        return {
            cssBackground: selectedItemFromCdif.cssBackground,
            value: selectedItemFromCdif.cdifValue
        };
    }
    return undefined;
}

export async function getSelectableColorFromImageURL(file: File, colorPalette: ColorPaletteType) {
    if (file && colorPalette) {
        const dominantColorInImage = await getDominantColorFromImage(file);
        const colorEntriesInRGB = colorPalette.entries.map(color => hex2rgb(color.cssBackground));
        const bestColor = searchForSimilarColor(dominantColorInImage, colorEntriesInRGB);
        if (bestColor) {
            const hex = rgb2hex(bestColor);
            const colorValueFromImage = colorPalette?.toCdif(hex);
            if (colorValueFromImage) {
                const identifiedColorFromImage = findSelectableColor(colorPalette, colorValueFromImage);
                return identifiedColorFromImage;
            }
        }
    }
    return undefined;
}

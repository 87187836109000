import type { StudioSecondaryConfig } from "@internal/data-access-calcifer";
import { DTeC } from "@vp/types-ddif";
import { useQuerySecondaryConfig } from "./useQuerySecondaryConfig";

function capitalizeFirstLetter(string: DTeC.FinishType): Capitalize<DTeC.FinishType> {
    return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<DTeC.FinishType>;
}

const selector = (data: StudioSecondaryConfig) => data.templateAvailableFinishes.map(capitalizeFirstLetter);

export function useTemplateAvailableFinishes(): Capitalize<DTeC.FinishType>[] | undefined {
    return useQuerySecondaryConfig(selector).data;
}

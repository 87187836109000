import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showSingleColorImageModalSlice = createSlice({
    name: "showSingleColorImageModal",
    initialState: false,
    reducers: {
        showSingleColorImageModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { showSingleColorImageModal } = showSingleColorImageModalSlice.actions;
export const singleColorImageModalOpen = showSingleColorImageModalSlice.reducer;

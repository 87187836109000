import { useContext } from "react";
import { LocalizedContext } from "./LocalizedProvider";

export const useLocalized = () => {
    const result = useContext(LocalizedContext);
    if (result === undefined) {
        throw new Error("This component must be wrapped into <LocalizedProvider> to use translations");
    }

    return result;
};

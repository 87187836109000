import { isFigureItem, isRectangleItem, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import type { AnyTextContent, Item } from "@design-stack-vista/cdif-types/v2";
import { DesignExtensionSystem } from "@design-stack-vista/interactive-design-engine-core";
import { isString, rgb2hex } from "@design-stack-vista/utility-core";
import { ImageColorsExtension } from "@internal/utils-color";

function getTextAreaColor(incomingColors: string[], content: AnyTextContent[]) {
    const currentColors = [...incomingColors];
    for (const c of content) {
        if (typeof c.content === "string") {
            if (c.color) {
                currentColors.push(c.color);
            }
        } else {
            currentColors.push(...getTextAreaColor([], c.content));
        }
    }
    return currentColors;
}

export function countColorsPerItem(item: ItemState<Item>, designExtensionSystem: DesignExtensionSystem): string[] {
    const colors: string[] = [];
    // Shapes
    if (item.isShapeItem()) {
        if (item.model.stroke?.color) {
            colors.push(item.model.stroke?.color);
        }
        if (isFigureItem(item.model) || isRectangleItem(item.model)) {
            if (item.model.color) {
                colors.push(item.model.color);
            }
        }
        // Text
    } else if (item.isTextAreaItem()) {
        colors.push(...getTextAreaColor([], item.model.content));
    } else if (item.isItemReference()) {
        // Wordart, Teams
        if (item.model.data?.color && isString(item.model.data.color)) {
            colors.push(item.model.data.color);
        }
        // tables
        if (
            item.model.data?.predefinedStyle?.backgroundColor &&
            isString(item.model.data.predefinedStyle.backgroundColor)
        ) {
            colors.push(item.model.data.predefinedStyle.backgroundColor);
        }
        // tables
        if (item.model.data?.predefinedStyle?.fontColor && isString(item.model.data.predefinedStyle.fontColor)) {
            colors.push(item.model.data.predefinedStyle.fontColor);
        }
        // Images
    } else if (item.isImageItem()) {
        const imageColorsExtension = designExtensionSystem.getExtension(item.iid, ImageColorsExtension);
        if (imageColorsExtension?.isReady && imageColorsExtension.colors) {
            colors.push(...imageColorsExtension.colors.map(rgb2hex));
        }
    }

    return colors;
}

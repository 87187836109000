import React, { createContext, useContext, ReactNode } from "react";
import { useSelectedItems } from "@design-stack-vista/core-features";
import type { ColorPaletteType } from "./types";

const ColorPaletteContext = createContext<ColorPaletteType[]>([]);

export interface ColorPaletteProviderProps {
    children: ReactNode | ReactNode[];
    colorPalettes: ColorPaletteType[];
}

export function ColorPaletteProvider({ colorPalettes, children }: ColorPaletteProviderProps) {
    return <ColorPaletteContext.Provider value={colorPalettes}>{children}</ColorPaletteContext.Provider>;
}

export function useColorPaletteForDecorationTechnology(decorationTechnology: string): ColorPaletteType | undefined {
    const colorPalettes = useContext(ColorPaletteContext);

    return colorPalettes.find(palette => palette.supports(decorationTechnology));
}

export function useColorPaletteForSelectedItems(): ColorPaletteType | undefined {
    const colorPalettes = useContext(ColorPaletteContext);
    const { selectedItems } = useSelectedItems();

    // TODO: when there's more information about subpanels, add the appropriate logic
    const itemTechnologies = selectedItems.flatMap(item => {
        if (item.parent.isSubpanelState()) {
            return [];
        }
        return item.parent.panelProperties.decorationTechnology;
    });
    if (itemTechnologies.length === 0) {
        return undefined;
    }
    const uniqueTechnologies = [...new Set(itemTechnologies)];

    return colorPalettes.find(palette => uniqueTechnologies.every(technology => palette.supports(technology)));
}

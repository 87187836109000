import { defineMessages } from "@vp/i18n-helper";

export const translatedErrors = defineMessages({
    defaultHeader: {
        id: "studio.components.errorPage.defaultHeader",
        defaultMessage: "Oops! Something went wrong.",
        description: {
            note: "This header shows up when no other error has been specified"
        }
    },
    defaultSubHeader: {
        id: "studio.components.errorPage.defaultSubHeader",
        defaultMessage:
            "Please contact our Customer Care Team at your convenience. We’d like to get this sorted for you as quickly as possible.",
        description: {
            note: "This error message shows up when no other error has been specified"
        }
    },
    returnToHomeButtonText: {
        id: "studio.components.errorPage.returnToHomeButtonText",
        defaultMessage: "Visit our Homepage",
        description: {
            note: "This button text shows up by default as secondary CTA on an error page"
        }
    },
    getHelpButtonText: {
        id: "studio.components.errorPage.getHelpButtonText",
        defaultMessage: "Contact Customer Care",
        description: {
            note: "This button text shows up by default as primary CTA on an error page"
        }
    },
    getMoreHelpButtonText: {
        id: "studio.components.errorPage.getMoreHelpButtonText",
        defaultMessage: "Get more help",
        description: {
            note: "This button text shows up as a care callout for certain error pages"
        }
    },
    defaultErrorText: {
        id: "studio.components.errorPage.defaultErrorText",
        defaultMessage: "It appears that Studio is currently unavailable.",
        description: {
            note: "This text shows up by default when error happens"
        }
    },
    unknownErrorHeader: {
        id: "studio.components.errorPage.unknownErrorHeader",
        defaultMessage: "We’re sorry – something went wrong on our end.",
        description: {
            note: "This header shows up when error happened that doesn't match any other error types"
        }
    },
    unknownErrorSubHeader: {
        id: "studio.components.errorPage.unknownErrorSubHeader",
        defaultMessage:
            "An error has occurred, and we’re working on it! Check back later, or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened that doesn't match any other error types"
        }
    },
    invalidOptionsErrorHeader: {
        id: "studio.components.errorPage.invalidOptionsErrorHeader",
        defaultMessage: "The product and options you selected are currently unavailable.",
        description: {
            note: "This header shows up when error happened due to invalid options"
        }
    },
    invalidOptionsErrorSubHeader: {
        id: "studio.components.errorPage.invalidOptionsErrorSubHeader",
        defaultMessage: "Please choose another product or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to invalid options"
        }
    },
    chooseAnotherProductButtonText: {
        id: "studio.components.errorPage.chooseAnotherProductButtonText",
        defaultMessage: "Choose another product",
        description: {
            note: "This subheader shows up when error happened due to invalid options"
        }
    },
    tryAgainButtonText: {
        id: "studio.components.errorPage.tryAgainButtonText",
        defaultMessage: "Try again",
        description: {
            note: "This subheader shows up when error happened due to invalid options"
        }
    },
    mpvNotFoundErrorHeader: {
        id: "studio.components.errorPage.mpvNotFoundErrorHeader",
        defaultMessage: "The product you selected is currently unavailable.",
        description: {
            note: "This header shows up when error happened due to mpv not found"
        }
    },
    mpvNotFoundErrorSubHeader: {
        id: "studio.components.errorPage.mpvNotFoundErrorSubHeader",
        defaultMessage: "Please go back to the homepage to choose another product or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to mpv not found"
        }
    },
    criticalFailErrorHeader: {
        id: "studio.components.errorPage.criticalFailErrorHeader",
        defaultMessage: "We’re having trouble loading Studio for you.",
        description: {
            note: "This header shows up when error happened due to critical 400 fail"
        }
    },
    criticalFailErrorSubHeader: {
        id: "studio.components.errorPage.criticalFailErrorSubHeader",
        defaultMessage:
            "Studio is temporarily unavailable, and we’re working on it. Check back later, or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to critical 400 fail"
        }
    },
    loadWorkNotPermittedErrorHeader: {
        id: "studio.components.errorPage.loadWorkNotPermittedErrorHeader",
        defaultMessage: "It appears you don’t have permission to edit this design.",
        description: {
            note: "This header shows up when error happened due to load work not permitted"
        }
    },
    loadWorkNotPermittedErrorSubHeader: {
        id: "studio.components.errorPage.loadWorkNotPermittedErrorSubHeader",
        defaultMessage:
            "If you believe this to be a mistake, please contact our Customer Care Team so we can help you.",
        description: {
            note: "This subheader shows up when error happened due to load work not permitted"
        }
    },
    noSurfacesDefinedErrorHeader: {
        id: "studio.components.errorPage.noSurfacesDefinedErrorHeader",
        defaultMessage: "The product you selected cannot be customized.",
        description: {
            note: "This header shows up when error happened due to there being no surfaces defined."
        }
    },
    noSurfacesDefinedErrorSubHeader: {
        id: "studio.components.errorPage.noSurfacesDefinedErrorSubHeader",
        defaultMessage: "Please go back to the homepage to choose another product or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to there being no surfaces defined."
        }
    },
    urlContainsNoParamsErrorHeader: {
        id: "studio.components.errorPage.urlContainsNoParamsErrorHeader",
        defaultMessage: "We’re having trouble loading Studio for you.",
        description: {
            note: "This header shows up when error happened due to url containing no params"
        }
    },
    urlContainsNoParamsErrorSubHeader: {
        id: "studio.components.errorPage.urlContainsNoParamsErrorSubHeader",
        defaultMessage:
            "We are unable to load Studio with this link. Please check that you have a valid link, or return to our homepage.",
        description: {
            note: "This subheader shows up when error happened due to url containing no params"
        }
    },
    urlContainsAltDocIdErrorSubHeader: {
        id: "studio.components.errorPage.urlContainsAltDocIdErrorSubHeader",
        defaultMessage:
            "Please go to My Projects to choose the design you’d like to edit, or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to url containing no alt_doc_id"
        }
    },
    urlContainsAltDocIdErrorHeader: {
        id: "studio.components.errorPage.urlContainsAltDocIdErrorHeader",
        defaultMessage: "We’re having trouble loading your design. ",
        description: {
            note: "This header shows up when error happened due to url containing alt_doc_id"
        }
    },
    goToProjectButtonText: {
        id: "studio.components.errorPage.goToProjectButtonText",
        defaultMessage: "Go to project",
        description: {
            note: "This text is on the CTA to reload or go to a project"
        }
    },
    returnToMyProjectsButtonText: {
        id: "studio.components.errorPage.returnToMyProjectsButtonText",
        defaultMessage: "Go to My Projects",
        description: {
            note: "This text is on the CTA to go to my Projects"
        }
    },
    urlContainsProjectIdErrorSubHeader: {
        id: "studio.components.errorPage.urlContainsProjectIdErrorSubHeader",
        defaultMessage: "Please go back to the homepage to choose another product or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to url containing no project_id"
        }
    },
    docSurfaceMismatchErrorSubHeader: {
        id: "studio.components.errorPage.docSurfaceMismatchErrorSubHeader",
        defaultMessage: "Please go to My Projects to choose the design again, or let us help you in the meantime.",
        description: {
            note: "This subheader shows up when error happened due to url document surface mismatch"
        }
    },
    docSurfaceMismatchErrorHeader: {
        id: "studio.components.errorPage.docSurfaceMismatchErrorHeader",
        defaultMessage: "This design needs to be resized due to product updates.",
        description: {
            note: "This header shows up when error happened due to document surface mismatch"
        }
    },
    closeWindowText: {
        id: "studio.components.errorPage.closeWindowText",
        defaultMessage: "Close Window",
        description: {
            note: `This button shows up for Care Agents when they encounter an error where they can't proceed.
            The window closing action works if an agent launches Studio from DesignUI (which they should always do).
            The window will not close if Studio is loaded directly via URL.`
        }
    }
});

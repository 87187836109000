import { type ImageItem } from "@design-stack-vista/cdif-types";
import { CimDocStore, getRootPanel, type DesignState, type ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { BaseExtension, CIMDOC_STORE_TOKEN } from "@design-stack-vista/interactive-design-engine-core";
import { type ColorPaletteType } from "@internal/feature-color-palette";
import { type SelectableColor } from "@internal/utils-color";
import { metadataItemsForImageId } from "@internal/utils-custom-metadata";
import {
    DEFAULT_ENGRAVING_SELECTABLE_COLOR,
    DEFAULT_SINGLE_COLOR_SELECTABLE_COLOR,
    isEngraved,
    isSingleColor
} from "@internal/utils-deco-tech";
import { action, computed, makeObservable, observable } from "mobx";
import { type SingleColorDclMetadata } from "../types";
import { getDefaultThreshold, getOriginalImageSourceUrl, getSelectableColorFromColorPalette } from "../util";

/**
 * Extension specific for single color
 * to retrieve properties related to single color
 */
export class SingleColorImageExtension extends BaseExtension {
    declare designState: ItemState<ImageItem>;

    static override supports(state: DesignState): boolean {
        if (state.isImageItem()) {
            const { decorationTechnology } = getRootPanel(state).panelProperties;
            return isSingleColor(decorationTechnology);
        }
        return false;
    }

    static override inject = [CIMDOC_STORE_TOKEN];

    @observable private defaultColorSelection: SelectableColor;
    @observable private defaultThreshold: number;
    @observable isEngraving: boolean = false;

    constructor(designState: DesignState, private cimdocStore: CimDocStore) {
        super(designState);
        makeObservable(this);

        const { decorationTechnology } = getRootPanel(this.designState).panelProperties;
        this.isEngraving = isEngraved(decorationTechnology);

        this.defaultColorSelection = this.isEngraving
            ? DEFAULT_ENGRAVING_SELECTABLE_COLOR
            : DEFAULT_SINGLE_COLOR_SELECTABLE_COLOR;
        this.defaultThreshold = getDefaultThreshold();
    }

    /**
     * @summary: Initially ICONs have cdn links which are not sherbert links.
     * cdn link get replaced with sherbert link when the item is updated.
     * This helps in getting the updated link
     */
    @computed
    get getOriginalImageUrl() {
        const { model } = this.designState;
        const originalSource = getOriginalImageSourceUrl(model);
        if (!originalSource) {
            throw new Error("No Image URL found");
        }
        return originalSource;
    }

    /**
     * @returns printUrl from overlays array of a single color image from CimDoc.
     */
    @computed
    get hasOverlayPrintUrl() {
        const { model } = this.designState;
        const { overlays } = model;
        if (Array.isArray(overlays) && overlays.length > 0) {
            return Boolean(overlays[0].printUrl);
        }
        return false;
    }

    // Remark; Instead of passing in the colorPalette every time we need to get the colors
    // If the colorPalette is passed into the constructor, method could be changed to getter of the color
    @action.bound
    getColorProperty(selectedItemColorPalette?: ColorPaletteType): SelectableColor | undefined {
        const { overlays } = this.designState.model;
        if (!overlays) {
            return undefined;
        }
        const colorValue = Array.isArray(overlays) && overlays.length ? overlays[0].color : undefined;
        if (selectedItemColorPalette && colorValue) {
            const selectableColor = getSelectableColorFromColorPalette(colorValue, selectedItemColorPalette);
            if (selectableColor) {
                return selectableColor;
            }
        }
        // When there are no color palette entries, use default color.
        return this.defaultColorSelection;
    }

    @computed
    get thresholdAndInvertedStatusValues() {
        try {
            const metadata = metadataItemsForImageId(this.cimdocStore.asJson, this.designState.id);
            return {
                threshold:
                    (metadata?.dclMetadataItem as unknown as SingleColorDclMetadata).threshold ?? this.defaultThreshold,
                invert: (metadata?.dclMetadataItem as unknown as SingleColorDclMetadata).inverted ?? false
            };
        } catch {
            return {
                threshold: this.defaultThreshold,
                invert: false
            };
        }
    }
}

import React, { createContext, useState, useContext, ReactNode, useMemo } from "react";

interface DownloadDialogContextType {
    isOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
}

const DownloadDialogContext = createContext<DownloadDialogContextType | undefined>(undefined);

interface DownloadDialogProviderProps {
    children: ReactNode;
}

export const useDownloadDialog = (): DownloadDialogContextType => {
    const context = useContext(DownloadDialogContext);
    if (!context) {
        throw new Error("useDownloadDialog must be used within a DownloadDialogProvider");
    }
    return context;
};

const openDialog = (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => () => setIsOpen(true);

const closeDialog = (setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => () => setIsOpen(false);

export const DownloadDialogProvider: React.FC<DownloadDialogProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const contextValue = useMemo(
        () => ({
            isOpen,
            openDialog: openDialog(setIsOpen),
            closeDialog: closeDialog(setIsOpen)
        }),
        [isOpen]
    );

    return <DownloadDialogContext.Provider value={contextValue}>{children}</DownloadDialogContext.Provider>;
};

export interface SelectableColor {
    value: string;
    cssBackground: string;
}

export interface EmbroiderySelectableColor extends SelectableColor {
    ordinal: number;
}

export enum ColorSpace {
    HSL = "hsl",
    HSV = "hsv",
    RGB = "rgb",
    CMYK = "cmyk",
    HEX = "hex",
    NoColor = "No Color",
    Pantone = "pantone",
    Thread = "thread"
}

export type ColorOverride = Record<string, string>;

export type ColorOverrides = ColorOverride[];

import type { DSS } from "@vp/types-ddif";
import type { DesignExperienceManagementState } from "@internal/utils-studio-configuration-core";
import { getDocument } from "@internal/data-access-document-storage";
import { getQueryParams } from "@internal/utils-browser";
import {
    getDesignViewsForProduct,
    getDesignDocForFullbleed,
    getDesignDocFromTemplateToken
} from "@internal/data-access-design-specifications-service";
import type { DesignSpecification } from "@internal/data-access-calcifer";
import { type ErrorHandler } from "@internal/utils-errors";
import { prepareStudioCimDoc } from "./prepareStudioCimDoc";

export interface CimDocResolutionParams {
    designSpecification: DesignSpecification;
    existingDesignDocument?: DSS.DesignDocument;
    productVersion: number;
    productKey: string;
    studioSelectedProductOptions: Record<string, string>;
    isFullBleed: boolean;
    locale: string;
    template: string | null;
    documentRevisionUrl: string | null;
    authToken: string;
    useDraggablePlaceholders: boolean;
    designExperienceManagementState?: DesignExperienceManagementState;
    handleError: ErrorHandler;
    showAdjustmentAlert: (changes: string) => void;
}

export async function resolveCimDocAndViews({
    designSpecification,
    existingDesignDocument,
    productVersion,
    productKey,
    studioSelectedProductOptions,
    isFullBleed,
    locale,
    template,
    documentRevisionUrl,
    authToken,
    useDraggablePlaceholders,
    designExperienceManagementState,
    handleError,
    showAdjustmentAlert
}: CimDocResolutionParams): Promise<{ designSpecification: DesignSpecification; cimDoc: DSS.DesignDocument }> {
    const { dssUrl } = getQueryParams();
    if (dssUrl) {
        designSpecification.views = (
            await getDesignViewsForProduct(productKey, productVersion, studioSelectedProductOptions, locale)
        ).designViews;
        designSpecification.designDocument = isFullBleed
            ? await getDesignDocForFullbleed(productKey, productVersion, studioSelectedProductOptions, locale)
            : await getDesignDocFromTemplateToken(
                  productKey,
                  productVersion,
                  studioSelectedProductOptions,
                  template ?? "",
                  locale
              );
    }

    let cimDoc;
    if (existingDesignDocument) {
        cimDoc = existingDesignDocument;
    } else if (documentRevisionUrl && authToken) {
        cimDoc = await getDocument(documentRevisionUrl, authToken);
    } else {
        cimDoc = designSpecification.designDocument;
    }

    if (productVersion === null) {
        throw Error("Product version is not defined");
    }
    const preparedCimDoc = await prepareStudioCimDoc({
        cimDoc,
        designSpecification,
        productKey,
        productVersion,
        studioSelectedProductOptions,
        locale,
        useDraggablePlaceholders,
        designExperienceManagementState,
        handleError,
        showAdjustmentAlert
    });

    return { designSpecification, cimDoc: preparedCimDoc };
}

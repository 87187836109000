import { name } from "./name.js";
import { VectorPluginPreloaderPanelExtension } from "./extensions/VectorPluginPreloaderPanelExtension.js";
import { VectorExtension } from "./extensions/VectorExtension.js";
import { vectorMessages } from "./shared/vectorMessages.js";
import { VectorDraggableValue } from "./contentDiscoveryZone/dragAndDrop/draggableValue.js";
import "react/jsx-runtime";
import { useHandleDropTarget } from "./contentDiscoveryZone/hooks/useHandleDropTarget.js";
import { ClipartsPanel } from "./panelTypes.js";
import { dialogTypes } from "./dialogTypes.js";
import { isVectorItem, isVectorItemReference } from "./shared/utils/vectorItemTypeGuard.js";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/core-features";
import "@design-stack-vista/cimdoc-state-manager";
const defaultTranslations = {
  "sims.vector.contentDiscoveryZone.multiColorVector.title": "Object colors",
  "sims.vector.contentDiscoveryZone.draggableAlt": "Clipart item",
  "sims.vector.contentDiscoveryZone.closeButtonAccessibilityLabel": "Close",
  "sims.vector.contentDiscoveryZone.backButtonAccessibilityLabel": "Back",
  "sims.vector.colorPickerSheet": "Colorize Vector Sheet",
  "sims.vector.selectedZone.MultiColorPickerButtonLabel": "Colors",
  "sims.vector.contentDiscoveryZone.panelTitle": "Clipart",
  "sims.vector.contentDiscoveryZone.showMoreDescription": "Show more clipart",
  "sims.vector.contentDiscoveryZone.searchPlaceholder": "Search for clipart...",
  "sims.vector.contentDiscoveryZone.searchButton": "Search clipart"
};
const vectorSim = {
  name,
  core: {
    defaultTranslations,
    extensions: [VectorPluginPreloaderPanelExtension, VectorExtension],
    selectedZone: {
      detailZoneIds: /* @__PURE__ */ new Set([{
        type: "ColorPicker",
        activeDialogType: dialogTypes.VectorColorPicker
      }])
    },
    contentDiscoveryZone: {
      GraphicsAndShapes: {
        [ClipartsPanel]: {
          panelTitle: vectorMessages.panelTitle.id
        }
      }
    },
    dragAndDrop: {
      [VectorDraggableValue]: {
        useHandleDropTarget
      }
    }
  },
  deferredLoader: () => import("./index.deferred.js")
};
export {
  vectorSim as default,
  isVectorItem,
  isVectorItemReference
};

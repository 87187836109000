import { ColorPaletteType } from "@internal/feature-color-palette";
import { SelectableColor } from "@internal/utils-color";
import { DEFAULT_ENGRAVING_SELECTABLE_COLOR } from "@internal/utils-deco-tech";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getSelectableColorFromImageURL } from "./getSelectableColorFromImageURL";

interface SelectedColorParams {
    isEngravingProduct: boolean;
    previousColor?: SelectableColor;
    selectedItemColorPalette: ColorPaletteType;
    engravingColor?: string;
    imageFile: File;
}

const getEngravingSelectableColor = (engravingColor: string): SelectableColor => {
    return {
        cssBackground: engravingColor,
        value: "spot(Engraving)"
    };
};

/**
 * Determines the appropriate selectable color to use for an image
 *
 * @returns The selectable color to use, or `undefined` if a suitable color could not be determined.
 */
export async function getSelectedSingleColor({
    isEngravingProduct,
    previousColor,
    selectedItemColorPalette,
    engravingColor,
    imageFile
}: SelectedColorParams): Promise<SelectableColor | undefined> {
    if (isEngravingProduct && engravingColor) {
        return getEngravingSelectableColor(engravingColor);
    }

    if (isEngravingProduct) {
        return DEFAULT_ENGRAVING_SELECTABLE_COLOR;
    }

    if (previousColor) {
        return previousColor;
    }

    try {
        return getSelectableColorFromImageURL(imageFile, selectedItemColorPalette);
    } catch (error: unknown) {
        if (error instanceof Error) {
            newRelicWrapper.noticeError(new Error("Detecting color from image failed"), {
                method: "getSelectedSingleColor",
                message: error.message
            });
        }
    }
    return undefined;
}

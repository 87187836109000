import { DSS } from "@vp/types-ddif";
import { CustomMetadataBase } from "../types/customMetadataBase";

export interface RemoveCustomMetadataArgs {
    /**
     * item ids whose custom metadata needs to be removed
     */
    ids: string[];
}
/**
 * Removes custom metadata from metadata object in CimDoc for the passed ids.
 */
export const removeCustomMetadata = (cimDoc: DSS.DesignDocument, { ids }: RemoveCustomMetadataArgs) => {
    const { dclMetadata, template, dsPanelLayout, ...customMetadata } = cimDoc.metadata!;
    if (customMetadata) {
        Object.entries(customMetadata).forEach(([key, value]: [string, CustomMetadataBase]) => {
            if (Array.isArray(value)) {
                const itemIndex = value.findIndex((metaDataItem: CustomMetadataBase) => ids.includes(metaDataItem.id));
                if (itemIndex !== -1) {
                    cimDoc.metadata![key].splice(itemIndex, 1);
                }
            } else if (key === "studioMetadata") {
                ids.forEach(id => {
                    if (value[id] && cimDoc.metadata!.studioMetadata) {
                        delete cimDoc.metadata!.studioMetadata[id];
                    }
                });
            }
        });
    }
};

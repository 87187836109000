import { isItemReference } from "@design-stack-vista/cimdoc-state-manager";
const PROTOTYPE_SIM_TYPE = "PrototypeSim";
const isPrototypeSimItemInCimdoc = (cimDoc, itemId) => {
  return cimDoc.document.panels.find((panel) => {
    var _a;
    return (_a = panel.itemReferences) == null ? void 0 : _a.find((item) => item.id === itemId && isPrototypeSimItem(item));
  });
};
const isPrototypeSimItemState = (item) => {
  return item.isItemReference() && item.model.type === PROTOTYPE_SIM_TYPE;
};
function isPrototypeSimItem(item) {
  return isItemReference(item) && item.type === PROTOTYPE_SIM_TYPE;
}
export {
  PROTOTYPE_SIM_TYPE,
  isPrototypeSimItem,
  isPrototypeSimItemInCimdoc,
  isPrototypeSimItemState
};

import type { BaseSim } from "../BaseSim";
import { DecorateWithDragAndDrop } from "./types";

type DeferredLoaded = Omit<DecorateWithDragAndDrop<BaseSim>, "deferred"> & {
    deferred: NonNullable<DecorateWithDragAndDrop<BaseSim>["deferred"]>;
};

export const isSimWithDragAndDrop = (sim: BaseSim): sim is DecorateWithDragAndDrop<BaseSim> => {
    return "dragAndDrop" in sim.core;
};

export const isSimWithDragAndDropAndDeferredLoaded = (sim: BaseSim): sim is DeferredLoaded => {
    return isSimWithDragAndDrop(sim) && Boolean(sim.deferred);
};

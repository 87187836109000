import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showTableEditorModalSlice = createSlice({
    name: "showTableEditorModal",
    initialState: false,
    reducers: {
        setShowTableEditorModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowTableEditorModal } = showTableEditorModalSlice.actions;
export const showTableEditorModal = showTableEditorModalSlice.reducer;

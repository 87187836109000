import { getBoundingBox } from "@design-stack-vista/utility-core";
import { getRequiredExtension, PanelChromesExtension } from "@design-stack-vista/core-features";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
const SHAPE_SIZE_FACTOR = 0.21;
const getLargestSafeArea = (designEngine, panel) => {
  const panelExtension = /* @__PURE__ */ getRequiredExtension(designEngine, panel, PanelChromesExtension);
  if (!panelExtension.masks.length) {
    const {
      width,
      height
    } = /* @__PURE__ */ parsePanelDimensionsToMm(panel);
    return {
      width,
      height,
      x: 0,
      y: 0
    };
  }
  return panelExtension.masks.filter((mask) => mask.type === "SAFE").map((mask) => getBoundingBox(mask.paths[0].points)).reduce((largest, current) => {
    const currentArea = current.width * current.height;
    const largestArea = largest ? largest.width * largest.height : 0;
    return currentArea > largestArea ? current : largest;
  }, {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
};
const getDefaultSizeForShape = ({
  currentPanel,
  designEngine,
  scaleX = 1,
  scaleY = 1,
  initialDimensions
}) => {
  const {
    width: safeAreaWidth,
    height: safeAreaHeight
  } = /* @__PURE__ */ getLargestSafeArea(designEngine, currentPanel);
  const maxSize = Math.min(safeAreaWidth, safeAreaHeight) * SHAPE_SIZE_FACTOR;
  const shapeWidth = initialDimensions ? initialDimensions.width : maxSize;
  const shapeHeight = initialDimensions ? initialDimensions.height : maxSize;
  const widthRatio = /* @__PURE__ */ Math.max(shapeHeight / shapeWidth, 1);
  const heightRatio = /* @__PURE__ */ Math.max(shapeWidth / shapeHeight, 1);
  const itemWidth = maxSize / widthRatio * scaleX;
  const itemHeight = maxSize / heightRatio * scaleY;
  return {
    width: itemWidth,
    height: itemHeight
  };
};
export {
  SHAPE_SIZE_FACTOR,
  getDefaultSizeForShape,
  getLargestSafeArea
};

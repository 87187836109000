import { i18nColorName } from "@design-stack-vista/i18n-color-name";
import { ColorPaletteEntry } from "../types";

export const standardPaletteColorStrings = [
    // grey
    "#FFFFFF",
    "#AAAAAA",
    "#555555",
    "#000000",
    // blue
    "#B1E2F3",
    "#66C6E8",
    "#1CAADE",
    "#127194",
    // green
    "#C2F0BC",
    "#87E27B",
    "#4CD43B",
    "#328E27",
    // yellow
    "#FEF2B8",
    "#FDE674",
    "#FDDB31",
    "#A99220",
    // orange
    "#FBD5A8",
    "#F8AC54",
    "#F58400",
    "#A45800",
    // red
    "#FBBCB7",
    "#F87B72",
    "#F63A2E",
    "#A4261E",
    // purple
    "#E6A9F0",
    "#CF56E2",
    "#B804D5",
    "#7B028E"
];

export const getStandardColorPalette = (locale: string): ColorPaletteEntry[] => {
    return standardPaletteColorStrings.map(hex => ({
        cdifValue: `rgb(${hex})`,
        cssBackground: hex,
        name: i18nColorName(hex, { culture: locale, colorPalette: "gallery" })
    }));
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const processingPDFSlice = createSlice({
    name: "processingPDF",
    initialState: false,
    reducers: {
        setProcessingPDF: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setProcessingPDF } = processingPDFSlice.actions;
export const processingPDF = processingPDFSlice.reducer;

// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import pricingContext from "@vp/pricing-context-module";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { isDevelopmentMode } from "@internal/utils-browser";
import { getCountry } from "@internal/utils-i18n";
import { PriceQuantityData } from "@internal/data-access-calcifer";
import { PricingApiResponse } from "./types";

const PRICING_SERVICE_URL = "https://website-pricing-service.prices.cimpress.io/v4/prices";
// The PCT package expects uppercase.  Lowercase works but results in duplicate calls.
const PRICING_VISTAPRINT_MERCHANT = "VISTAPRINT";

const host = PRICING_SERVICE_URL;
const entityCode = 24;

export function getPricingContextModule() {
    // currently the pricing context module only exports when run server side
    return pricingContext.init ? pricingContext : document.pcxtV3;
}

export async function initPricingContextModule(locale: string) {
    // lets us be able to override locale if we want
    return getPricingContextModule().init(PRICING_VISTAPRINT_MERCHANT, getCountry(locale), isDevelopmentMode());
}

export function isVatInclusive() {
    return getPricingContextModule().isVatInclusive();
}

// export interface AdditionalFees {
//     setupFee: FeeBreakdown;
//     mailingServiceFee: FeeBreakdown;
//     postageFee: FeeBreakdown;
//     rushProcessingFee: FeeBreakdown;
// }

// export interface ResponseChoiceGroup {
//     priceCalculationFailed: boolean;
//     failureReason?: string;
//     choiceGroupSelections: Record<string, string>;
//     totalListPrice: TaxAwarePrice;
//     totalDiscountPrice: TaxAwarePrice;
//     unitListPrice: TaxAwarePrice;
//     unitDiscountedPrice: TaxAwarePrice;
//     differentialListPrice: TaxAwarePrice;
//     differentialDiscountPrice: TaxAwarePrice;
//     differentialUnitListPrice: TaxAwarePrice;
//     differentialUnitDiscountPrice: TaxAwarePrice;
//     additionalFees: AdditionalFees;
// }

// type TaxAwareCentPrice = TaxAwarePrice;

// export interface FeeBreakdown {
//     listPrice: TaxAwarePrice;
//     discountedPrice: TaxAwarePrice;
//     listPriceCents: TaxAwareCentPrice;
//     discountedPriceCents: TaxAwareCentPrice;
// }

// export interface DifferentialPricingResult {
//     productKey: string;
//     productVersion: number;
//     fractionDigits: number;
//     currency: string;
//     additionalFees: AdditionalFees;
//     baseSelectionsListPrice: TaxAwarePrice;
//     baseSelectionsDiscountedPrice: TaxAwarePrice;
//     baseSelectionsUnitListPrice: TaxAwarePrice;
//     baseSelectionsUnitDiscountedPrice: TaxAwarePrice;
//     baseSelections: Record<string, string>;
//     choiceGroups: Record<string, ResponseChoiceGroup>;
// }

export async function getDifferentialPricing(
    productKey: string,
    studioSelectedProductOptions: Record<string, string>,
    quantity: number,
    choiceGroups: Record<string, Record<string, string>>,
    productVersion: number
): Promise<PricingApiResponse> {
    const body = JSON.stringify({
        productKey,
        quantity,
        merchantId: PRICING_VISTAPRINT_MERCHANT,
        selections: studioSelectedProductOptions,
        choiceGroups,
        pricingContext: getPricingContextModule().getEncodedContextString(),
        productVersion
    });
    const response: PricingApiResponse = await tryFetch({
        url: `${host}/differential?requestor=${REQUESTOR}`,
        options: {
            method: "POST",
            body,
            headers: {
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "pricingServiceClient:getDifferentialPricing",
        friendlyDescription: "get differential pricing",
        entityCode,
        retryCount: 10
    });

    if (
        !response.choiceGroups ||
        Object.keys(response.choiceGroups).length === 0 ||
        // this may not be an error - perhaps the option was just invalid and we shouldn't offer it?
        Object.values(response.choiceGroups).some(choice => choice.priceCalculationFailed)
    ) {
        throw Error(`Could not get differential pricing.  Body: ${body}  response: ${JSON.stringify(response)}`);
    }

    return response;
}

export async function getEstimatedPricing(
    productKey: string,
    quantity: number,
    studioSelectedProductOptions: Record<string, string>,
    productVersion: number
): Promise<PriceQuantityData> {
    const requestParams = {
        requestor: REQUESTOR,
        productKey,
        quantities: quantity,
        merchantId: PRICING_VISTAPRINT_MERCHANT,
        selections: studioSelectedProductOptions,
        market: getMarket(),
        productVersion
    };
    const queryString = qs.stringify(requestParams);

    const response: PriceQuantityData = await tryFetch({
        url: `${host}/estimated?${queryString}`,
        options: {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        },
        moduleFunction: "pricingServiceClient:getEstimatedPricing",
        friendlyDescription: "get estimated pricing",
        entityCode,
        retryCount: 5
    });

    return response;
}

export function getMarket() {
    const { market } = getPricingContextModule().getPricingContext();
    return market;
}

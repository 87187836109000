import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const cropImageModalSlice = createSlice({
    name: "cropImageModalState",
    initialState: false,
    reducers: {
        setShowCropImageModal(state, action: PayloadAction<boolean>) {
            return action.payload;
        }
    }
});

export const { setShowCropImageModal } = cropImageModalSlice.actions;
export const cropImageModalState = cropImageModalSlice.reducer;

import { ItemPreloaderExtension } from "@internal/sim-framework";
import { override } from "mobx";
import { name } from "../name.js";
import "@design-stack-vista/utility-core";
import "@internal/utils-color";
import "../shared/utils/validation.js";
import { isQrCodeTypeReference } from "../shared/utils/qrCodeTypeGuard.js";
import "../shared/utils/upgradeLegacyQRCode.js";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = __getOwnPropDesc(target, key);
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = decorator(target, key, result) || result;
  if (result) __defProp(target, key, result);
  return result;
};
class QRCodePluginPreloaderPanelExtension extends ItemPreloaderExtension {
  get simName() {
    return name;
  }
  get shouldPreload() {
    return this.designState.items.some((item) => isQrCodeTypeReference(item));
  }
}
__decorateClass([override], QRCodePluginPreloaderPanelExtension.prototype, "shouldPreload");
export {
  QRCodePluginPreloaderPanelExtension
};

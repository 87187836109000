import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { decoTechCategories } from "@internal/utils-deco-tech";

interface TrackImpressionParams {
    decorationTechnology?: string;
}

type ContextData = {
    isInlineCroppingABEnabled: boolean;
    trackImpressionForInlineCropping: (params: TrackImpressionParams) => void;
    checkVariationForInlineCropping: (params: TrackImpressionParams) => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useInlineCroppingExperiment() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a InlineCroppingABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const InlineCroppingExperimentData: ExperimentData = {
    experimentKey: "crop_adoption_experiment",
    experimentName: "Inline Cropping Adoption (Desktop)",
    variations: Variations
};

export const InlineCroppingABProvider = ({ children }: React.PropsWithChildren) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { isSmall } = useStudioLayout();

    const { experimentKey } = InlineCroppingExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const isTrackingComplete = useRef(false);

    const checkVariation = useCallback(
        ({ decorationTechnology }: TrackImpressionParams) => {
            const isPrintDecorationTechnology =
                Boolean(decorationTechnology) &&
                // @ts-ignore FIXME: must handle implicit `any` type
                decoTechCategories[decorationTechnology] === "print";

            if (isSmall || !experimentKey || !ABTest || !isPrintDecorationTechnology) {
                setIsEnabled(false);
                return;
            }

            const checkEnabledVariation = async () => {
                const { isExperimentUsingVariation } = ABTest;
                const res = await isExperimentUsingVariation(experimentKey, Variations.Test);
                setIsEnabled(!!res);
            };

            checkEnabledVariation();
        },
        [ABTest, experimentKey, isSmall]
    );

    const trackImpression = useCallback(
        ({ decorationTechnology }: TrackImpressionParams) => {
            const isPrintDecorationTechnology =
                Boolean(decorationTechnology) &&
                // @ts-ignore FIXME: must handle implicit `any` type
                decoTechCategories[decorationTechnology] === "print";

            if (isSmall || !experimentKey || !ABTest || !isPrintDecorationTechnology || isTrackingComplete.current) {
                return;
            }

            const trackImpressionIfInVariant = async (variation: Variations) => {
                const { isExperimentUsingVariation, trackImpression } = ABTest;
                const res = await isExperimentUsingVariation(experimentKey, variation);
                if (res) {
                    trackImpression(experimentKey, variation);
                }
            };

            trackImpressionIfInVariant(Variations.Test);
            trackImpressionIfInVariant(Variations.Control);

            isTrackingComplete.current = true;
        },
        [ABTest, experimentKey, isSmall]
    );

    const contextObject = useMemo(
        () => ({
            isInlineCroppingABEnabled: isEnabled,
            trackImpressionForInlineCropping: trackImpression,
            checkVariationForInlineCropping: checkVariation
        }),
        [isEnabled, trackImpression, checkVariation]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

InlineCroppingABProvider.displayName = "InlineCroppingABProvider";

import { REQUESTOR, tryFetch } from "@internal/utils-network";
import { fireImpression } from "@vp/ab-reader";
import { ensureUserTestId } from "@vp/ab-test-cookie";
import Cookies from "js-cookie";
import * as qs from "query-string";
import { siteContentCookieKey, entityCode } from "./constant";
import { Context, FlowResponse } from "./types";

const host = "https://siteflow.merch.vpsvc.com/v2";

/**
 * Retrieves the next step in the site flow for the given product, product version, option values, and locale.
 *
 * @param productKey - The unique identifier for the product.
 * @param productVersion - The version of the product.
 * @param optionValues - An object containing the option values for the product.
 * @param locale - The locale for the site flow.
 * @returns A promise that resolves to the next step in the site flow.
 */
export async function getNextStep(
    productKey: string,
    productVersion: number,
    optionValues: Record<string, string>,
    locale: string
): Promise<FlowResponse> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        optionValues,
        siteContext: Cookies.get(siteContentCookieKey),
        optimizelyEndUserId: ensureUserTestId()
    });
    return tryFetch({
        url: `${host}/nextstep/vistaprint/${locale}/Studio/${productKey}/${productVersion}?${query}`,
        moduleFunction: "siteFlowClient:getNextStep",
        friendlyDescription: "get next step in site flow",
        entityCode
    });
}

// GetAction is an endpoint on site flow that allows us to navigate to specific pages
// We're using it to send customers to pages get customers out of error states
export async function getAction(
    productKey: string,
    productVersion: string | number,
    optionValues: Record<string, string>,
    locale: string,
    action: string
): Promise<FlowResponse> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        optionValues,
        siteContext: Cookies.get(siteContentCookieKey),
        optimizelyEndUserId: ensureUserTestId()
    });
    return tryFetch({
        url: `${host}/action/vistaprint/${locale}/${action}/${productKey}/${productVersion}?${query}`,
        moduleFunction: "siteFlowClient:getAction",
        friendlyDescription: `get next step for ${action}`,
        entityCode
    });
}

// fireImpressionsForContexts is an utility to fire impressions for all the experiments
// considered as viewed at the moment the user navigates to the next page
/**
 * Fires impressions for the given experiment and variation contexts.
 *
 * @param contexts - An array of experiment and variation contexts to fire impressions for.
 * @returns A promise that resolves when all impressions have been fired, or silently fails if any impression fails to fire.
 */
export async function fireImpressionsForContexts(contexts: Context[]): Promise<void> {
    if (contexts.length > 0) {
        try {
            await Promise.all(contexts.map(context => fireImpression(context.experiment, context.variation)));
        } catch {
            /* failure to fire an impression should be fine, i'm not sure what we would do with a failure anyway */
        }
    }
}

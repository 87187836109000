import { DecorationTechnology } from "@internal/utils-deco-tech";
import type { StudioDesignExperienceExtension } from "@internal/utils-studio-configuration-core";

const enable3DExperience: StudioDesignExperienceExtension = {
    enable3DExperience: [
        {
            conditions: {
                value: true,
                decorationTechnologies: [
                    DecorationTechnology.None,
                    DecorationTechnology.Offset,
                    DecorationTechnology.OffsetOrDigital,
                    DecorationTechnology.Thermography,
                    DecorationTechnology.Digital,
                    DecorationTechnology.InkJet
                ],
                featureFlags: ["canvas3DView"]
            },
            overrides: {
                defaultSlotName: "Text",
                connectedDesignArea: "Default",
                shouldShowQRCodes: false,
                shouldShowImages: true,
                shouldShowGraphics: true,
                shouldShowTables: false,
                shouldShowBackgroundColor: true,
                shouldShowTeams: false,
                shouldShowText: true,
                shouldHideImageDiscoveryTab: false,
                shouldShowPatterns: true
            }
        }
    ],
    enablePanelSections: [
        {
            conditions: {
                value: true,
                decorationTechnologies: [
                    DecorationTechnology.None,
                    DecorationTechnology.Offset,
                    DecorationTechnology.OffsetOrDigital,
                    DecorationTechnology.Thermography,
                    DecorationTechnology.Digital,
                    DecorationTechnology.InkJet
                ],
                featureFlags: ["panelSections"]
            },
            overrides: {
                defaultSlotName: "Text",
                connectedDesignArea: "Default",
                shouldShowQRCodes: false,
                shouldShowImages: true,
                shouldShowGraphics: true,
                shouldShowTables: false,
                shouldShowBackgroundColor: true,
                shouldShowTeams: false,
                shouldShowText: true,
                shouldHideImageDiscoveryTab: false,
                shouldShowPatterns: true
            }
        }
    ]
};

export default enable3DExperience;

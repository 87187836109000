import type { FamilyRestrictions } from "@design-stack-vista/cimdoc-state-manager";

export const embroideryFontFamilyOverride: FamilyRestrictions = {
    Arial: { minPtSize: 30, maxPtSize: 120 },
    Arial_Rounded: { minPtSize: 30, maxPtSize: 120 },
    Calibri: { minPtSize: 30, maxPtSize: 120 },
    AdageScriptJF: { minPtSize: 35, maxPtSize: 120 },
    AthleticBlock: { minPtSize: 30, maxPtSize: 120 },
    "Kennedy sm caps book GD": { minPtSize: 32, maxPtSize: 120 },
    "Melanie BT": { minPtSize: 36, maxPtSize: 120 },
    JohnAndrewJF: { minPtSize: 32, maxPtSize: 120 },
    "BrushScript BT": { minPtSize: 34, maxPtSize: 120 },
    FranklinGotMdITCTT: { minPtSize: 30, maxPtSize: 120 },
    CabernetJF: { minPtSize: 30, maxPtSize: 120 },
    Pearl: { minPtSize: 36, maxPtSize: 120 },
    "BankGothic Md BT": { minPtSize: 30, maxPtSize: 120 },
    "Times new roman": { minPtSize: 30, maxPtSize: 120 },
    "MS Gothic": { minPtSize: 30, maxPtSize: 120 },
    Arial_Tiny: { minPtSize: 30, maxPtSize: 35 }
};

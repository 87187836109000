import React, { useState, useEffect, useRef } from "react";
import type { SharedComponentProps } from "@design-stack-vista/ida-framework";
import { panelSectionsTheme } from "../constants";
import { tokens } from "@vp/swan";
import style from "./PanelSectionMask.module.scss";
import { BoundingBox } from "../types";

function getSectionStrokeColor(isSelected: boolean, isHovered: boolean) {
    if (isSelected) {
        return panelSectionsTheme.sectionSelectedColor;
    }
    if (isHovered) {
        return panelSectionsTheme.sectionHighlightColor;
    }
    return "transparent";
}

export interface PanelSectionMaskProps extends SharedComponentProps {
    width: number;
    height: number;
    boundingBox: BoundingBox;
    strokeWidth?: number;
    name: string;
    isSelected: boolean;
    isHovered: boolean;
    mouseHoverState: (state: boolean) => void;
    onSectionClick: () => void;
}

export function PanelSectionMask({
    width,
    height,
    boundingBox,
    strokeWidth = 0.1,
    name,
    isSelected,
    isHovered,
    mouseHoverState,
    onSectionClick
}: PanelSectionMaskProps) {
    const sectionLabel = name.toLowerCase();
    const sectionNameRef = useRef<SVGTextElement>(null);
    const { left, top, right, bottom } = boundingBox;

    const bBoxPath = `M ${left},${top} L ${right},${top} L ${right},${bottom} L ${left},${bottom} Z`;
    const textPadding = strokeWidth * 2;
    const textFont = strokeWidth * 5;

    /*
     ** Manually update the reference attributes value to calculate BBox
     */
    sectionNameRef.current?.setAttribute("x", `${left + textPadding}`);
    sectionNameRef.current?.setAttribute("y", `${bottom - textPadding}`);
    sectionNameRef.current?.setAttribute("font-size", `${textFont}`);
    const getTextBbox = sectionNameRef.current?.getBBox();
    const defaultTextWidth = strokeWidth * 20;
    const defaultTextHeight = strokeWidth * 7;

    const [labelColor, setLabelColor] = useState<string>(tokens.SwanBaseColorWhite);

    useEffect(() => {
        if (isSelected) {
            setLabelColor(tokens.SwanBaseColorWhite);
        } else {
            setLabelColor(tokens.SwanBaseColorBlack);
        }
    }, [isSelected]);

    const textWidth = getTextBbox ? getTextBbox.width + 2 * textPadding : defaultTextWidth;
    const textHeight = getTextBbox ? getTextBbox.height : defaultTextHeight;
    const normalizedSectionLabel = sectionLabel.replace(/\s/g, "-");

    /**
     * The way accessability works for svgs relys primarily on the title for svgs according to the w3c specification
     * https://www.w3.org/TR/SVG11/struct.html#DescriptionAndTitleElements
     * Chromium and Firefox have a bug related to how screenreaders work that require the usage of aria-labelledby
     * to appropriately provide the aria text to the screenreader.
     */
    return (
        <svg
            data-testid={`panelSection-${name}`}
            className={style.maskPathStyle}
            viewBox={`0 0 ${width} ${height}`}
            style={{ zIndex: isSelected ? 0 : 1 }}
            data-attribute={`studio3d-${normalizedSectionLabel}-sectionmask`}
            aria-labelledby={`studio3d-${normalizedSectionLabel}-sectionmask`}
            onMouseOver={() => mouseHoverState(true)}
            onMouseLeave={() => mouseHoverState(false)}
            onClick={onSectionClick}
            onPointerUp={onSectionClick}
        >
            <g className={style.hideLabel}>
                <rect
                    display={isSelected || isHovered ? "block" : "none"}
                    x={left}
                    y={bottom - textHeight}
                    width={textWidth}
                    height={textHeight}
                    fill={
                        isSelected ? panelSectionsTheme.sectionSelectedColor : panelSectionsTheme.sectionHighlightColor
                    }
                    stroke={
                        isSelected ? panelSectionsTheme.sectionSelectedColor : panelSectionsTheme.sectionHighlightColor
                    }
                />
                <text
                    fill={labelColor}
                    opacity={isSelected || isHovered ? 1 : 0}
                    ref={sectionNameRef}
                    data-attribute={`studio3d-${normalizedSectionLabel}-sectionmask`}
                >
                    {sectionLabel}
                </text>
            </g>
            <path
                data-testid={`sectionBorder-${name}`}
                data-selected={isSelected}
                data-hovered={isHovered}
                className={style.basePathStyle}
                stroke={getSectionStrokeColor(isSelected, isHovered)}
                d={bBoxPath}
                fillRule="evenodd"
            />
        </svg>
    );
}

import { observable, computed, action, makeObservable } from "mobx";
import type { StudioContentDiscoveryZone, StudioContentDiscoveryZoneName } from "./types";
import {
    contentDiscoverySlotSchema,
    type ActivationWrapper,
    type ContentDiscoveryStudioSlotNames,
    type ContentDiscoveryZoneFulfiller,
    type ContentDiscoveryZoneSlotMap
} from "@internal/utils-content-discovery-zone";
import { DetailZoneStatus, ScreenSize, DetailZoneFulfiller } from "@internal/utils-detail-zone";

export class StudioContentDiscoveryZoneManager
    implements
        ContentDiscoveryZoneFulfiller<"studio", StudioContentDiscoveryZoneName>,
        DetailZoneFulfiller<"studio", StudioContentDiscoveryZoneName>
{
    @observable
    modules: Map<StudioContentDiscoveryZoneName, ActivationWrapper<StudioContentDiscoveryZone>>;

    constructor(modules: Set<StudioContentDiscoveryZone>) {
        this.modules = Array.from(modules).reduce<typeof this.modules>((acc, module) => {
            acc.set(module.name, { data: module, active: false });
            return acc;
        }, new Map());
        makeObservable(this);
    }

    @computed
    get asArray(): Array<readonly [StudioContentDiscoveryZoneName, ActivationWrapper<StudioContentDiscoveryZone>]> {
        return Array.from(this.modules).map(([name, wrapper]) => {
            return [name, wrapper] as const;
        });
    }

    @computed
    get asActiveModuleArray() {
        return this.asArray
            .filter(([, wrapper]) => wrapper.active)
            .map(([name, wrapper]) => [name, wrapper.data] as const);
    }

    @action
    setActivation(name: StudioContentDiscoveryZoneName, active: boolean) {
        const wrapper = this.modules.get(name);
        if (!wrapper) {
            return;
        }

        wrapper.active = active;
        this.modules.set(name, wrapper);
    }

    @action.bound
    setDeferred(name: StudioContentDiscoveryZoneName, deferred: NonNullable<StudioContentDiscoveryZone["deferred"]>) {
        const module = this.modules.get(name);

        if (!module || module.data.deferred) {
            return;
        }

        module.data.deferred = deferred;
    }

    @computed
    get detailZone() {
        type StudioDetailZoneStatus = DetailZoneStatus<"studio", StudioContentDiscoveryZoneName>;
        type ActiveDialogToStudioDetailZoneStatus = Map<symbol, StudioDetailZoneStatus>;

        const results: Map<ScreenSize, ActiveDialogToStudioDetailZoneStatus> = new Map([
            ["largeScreen", new Map()],
            ["smallScreen", new Map()]
        ]);

        for (const [, studioModule] of this.asActiveModuleArray) {
            for (const screenSize of ["largeScreen", "smallScreen"] as const) {
                for (const key in studioModule.core) {
                    if (!Object.prototype.hasOwnProperty.call(studioModule.core, key)) {
                        continue;
                    }
                    const slotName = key as ContentDiscoveryStudioSlotNames;

                    const slotValue = studioModule.core[slotName];

                    if (!slotValue) {
                        continue;
                    }

                    // normalize the value to an array to simplify logic below
                    const valuesInSlot = normalizeValueToArray(slotValue);

                    for (const valueInSlot of valuesInSlot) {
                        results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                            fulfiller: "studio",
                            status: "matchButDeferredNotLoaded",
                            moduleName: studioModule.name
                        });

                        // if the module's deferred code isn't ready, skip to the next one
                        if (!hasDeferredLoaded(studioModule)) {
                            continue;
                        }

                        const detailZone = studioModule.deferred.detailZone;

                        if (!detailZone) {
                            results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                                fulfiller: "studio",
                                status: "noMatch",
                                message: `The Studio CDZ "${
                                    studioModule.name
                                }" does not provide a component for the symbol "${valueInSlot.activeDialogType.toString()} at \`deferred.detailZone.${screenSize}\`."`
                            });
                            continue;
                        }

                        const component = detailZone[screenSize]?.get(valueInSlot.activeDialogType);

                        /**
                         * We need to allow Studio CDZ to not always provide a component for every screen size. Thus,
                         * we can "silenty" fail here.
                         */

                        if (component) {
                            results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                                fulfiller: "studio",
                                status: "match",
                                moduleName: studioModule.name,
                                component
                            });
                        } else {
                            results.get(screenSize)!.set(valueInSlot.activeDialogType, {
                                fulfiller: "studio",
                                status: "noMatch",
                                message: `The Studio CDZ "${
                                    studioModule.name
                                }" does not provide a component for the symbol "${valueInSlot.activeDialogType.toString()} at \`deferred.detailZone.${screenSize}\`."`
                            });
                        }
                    }
                }
            }
        }

        return results;
    }

    @computed
    get contentDiscoveryZoneSlots() {
        const results: ContentDiscoveryZoneSlotMap<"studio", StudioContentDiscoveryZoneName> = new Map();

        for (const [, studioModule] of this.asActiveModuleArray) {
            for (const key in studioModule.core) {
                if (!Object.prototype.hasOwnProperty.call(studioModule.core, key)) {
                    continue;
                }
                const slotName = key as ContentDiscoveryStudioSlotNames;

                const slotSchema = contentDiscoverySlotSchema[slotName];
                const slotValue = studioModule.core[slotName];

                if (!slotValue || !slotSchema) {
                    continue;
                }

                // normalize the value to an array to simplify logic below
                const valuesInSlot = normalizeValueToArray(slotValue);

                switch (slotSchema.type) {
                    case "single": {
                        const existingValueWrapper = results.get(slotName);

                        if (existingValueWrapper) {
                            results.set(slotName, {
                                type: "error",
                                fulfiller: "studio",
                                name: studioModule.name,
                                value: new Error(
                                    `Two or more Studio CDZs are attempting to fulfill the slot "${slotName}"`
                                )
                            });
                        } else {
                            for (const valueInSlot of valuesInSlot) {
                                results.set(slotName, {
                                    type: "single",
                                    fulfiller: "studio",
                                    name: studioModule.name,
                                    value: valueInSlot
                                });
                            }
                        }

                        break;
                    }
                    case "multiple": {
                        const existingValueWrapper = results.get(slotName);

                        if (!existingValueWrapper) {
                            results.set(slotName, {
                                type: "multiple",
                                fulfiller: "studio",
                                name: studioModule.name,
                                value: valuesInSlot
                            });
                        } else if (existingValueWrapper.type === "multiple") {
                            results.set(slotName, {
                                type: "multiple",
                                fulfiller: "studio",
                                name: studioModule.name,
                                value: existingValueWrapper.value.concat(valuesInSlot)
                            });
                        }

                        break;
                    }
                    default: {
                        throw new Error(
                            // @ts-expect-error -- this helps validate that `slotSchema.type` is exhaustive
                            `The Studio CDZ "${studioModule.name}" has an unhandled slot type "${slotSchema.type}" given the slot name "${slotName}".`
                        );
                    }
                }
            }
        }

        return results;
    }

    @computed
    get symbolsBySlot() {
        const results: Map<ContentDiscoveryStudioSlotNames, symbol> = new Map();

        for (const [, module] of this.asActiveModuleArray) {
            for (const key in module.core) {
                if (!Object.prototype.hasOwnProperty.call(module.core, key)) {
                    continue;
                }
                const slotName = key as ContentDiscoveryStudioSlotNames;
                const slotValue = module.core[slotName];

                if (!slotValue) {
                    continue;
                }

                for (const valueInSlot of normalizeValueToArray(slotValue)) {
                    results.set(slotName, valueInSlot.activeDialogType);
                }
            }
        }

        return results;
    }
}

type DeferredLoaded = Omit<StudioContentDiscoveryZone, "deferred"> & {
    deferred: NonNullable<StudioContentDiscoveryZone["deferred"]>;
};

function hasDeferredLoaded(module: StudioContentDiscoveryZone): module is DeferredLoaded {
    return Boolean(module.deferred);
}

const normalizeValueToArray = <T extends {}>(data: T | T[]): T[] => {
    if (Array.isArray(data)) {
        return data;
    }
    return [data];
};

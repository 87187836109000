function getImageAsPromiseFromSvg(svgData, dimension) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(svgData, "image/svg+xml");
    const svgElement = svgDocument.documentElement;
    const svgHeight = svgElement.getAttribute("height");
    const svgWidth = svgElement.getAttribute("width");
    let svg = svgData;
    if (!svgHeight && !svgWidth) {
      svgElement.setAttribute("height", `${dimension.height}`);
      svgElement.setAttribute("width", `${dimension.width}`);
      svg = svgElement.outerHTML;
    }
    const url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
    image.onload = () => resolve(image);
    image.onerror = () => reject(image);
    image.src = url;
    image.setAttribute("crossOrigin", "");
  });
}
export {
  getImageAsPromiseFromSvg
};

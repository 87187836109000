import { DotPattern, EyePattern } from "../types/qrCodeItemRef.js";
import { apiConfig } from "../clients/apiConfig.js";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
const DEFAULT_QR_CODE_COLOR = "#000000";
const DEFAULT_BASE_COLOR = "#ffffff";
const DEFAULT_QR_CODE_DATA = {
  backgroundColor: DEFAULT_BASE_COLOR,
  dotsOptions: {
    pattern: DotPattern.Square,
    color: DEFAULT_QR_CODE_COLOR
  },
  eyeOptions: {
    eyePattern: EyePattern.Square,
    eyeColor: DEFAULT_QR_CODE_COLOR,
    eyeBorderColor: DEFAULT_QR_CODE_COLOR
  }
};
function fillQRDataWithDefaultValues(partialQRData) {
  const {
    color,
    ...rest
  } = partialQRData;
  const QRCodeData = {
    ...DEFAULT_QR_CODE_DATA,
    ...rest
  };
  return QRCodeData;
}
const upgradeLegacyQRCode = (cimDoc) => {
  const {
    qrCodeGenerator: {
      baseUrl,
      itemRefEndPoint
    }
  } = apiConfig;
  cimDoc.document.panels.forEach((panel) => {
    var _a;
    (_a = panel.itemReferences) == null ? void 0 : _a.forEach((itemRef) => {
      if (itemRef.type === ItemReferenceTypes.QR_CODE && !itemRef.data.dotsOptions) {
        itemRef.url = `${baseUrl}${itemRefEndPoint}`;
        itemRef.data = /* @__PURE__ */ fillQRDataWithDefaultValues(itemRef.data);
      }
    });
  });
};
export {
  DEFAULT_BASE_COLOR,
  DEFAULT_QR_CODE_COLOR,
  DEFAULT_QR_CODE_DATA,
  fillQRDataWithDefaultValues,
  upgradeLegacyQRCode
};

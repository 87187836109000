import { useCallback, useMemo } from "react";
import { useDesignEngine, getOptionalExtension, useActivePanel } from "@design-stack-vista/core-features";
import { useLayoutContainers } from "@design-stack-vista/ida-framework";
import { PanelSectionExtension } from "./PanelSectionExtension";

export function useSetPanelSectionFromPointer() {
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const { panContainer } = useLayoutContainers();
    const panArea = useMemo(() => panContainer?.getBoundingClientRect() ?? { x: 0, y: 0 }, [panContainer]);

    const panelSectionExtension = activePanel && getOptionalExtension(designEngine, activePanel, PanelSectionExtension);

    const setPanelSectionWithEventPointer = useCallback(
        (x: number, y: number) => {
            panelSectionExtension?.setSelectedSectionWithEventPointer(x, y, panArea);
        },
        [panArea, panelSectionExtension]
    );

    return setPanelSectionWithEventPointer;
}

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { Events, useTrackingClient } from "@internal/utils-tracking";

type ContextData = {
    isChangeSizeClickableRulerABEnabled: boolean;
    isTrackingComplete: boolean;
    trackImpression: () => Promise<void>;
    trackExperimentInteraction: (eventDetail: string, experimentDetail: string | undefined) => void;
};

const context = createContext<ContextData | undefined>(undefined);

export function useChangeSizeClickableRulers() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a ChangeSizeClickableRulerExperimentData");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const ChangeSizeClickableRulerExperimentData: ExperimentData = {
    experimentKey: "change_size_clickable_ruler_",
    experimentName: "Change Size: Clickable Ruler",
    variations: Variations
};

export const ChangeSizeClickableRulerABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const trackingClient = useTrackingClient();
    const { experimentKey } = ChangeSizeClickableRulerExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);

    const trackImpression = useCallback(async () => {
        if (!experimentKey || !ABTest || isTrackingComplete) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            }
        };

        await Promise.all([
            trackImpressionIfInVariant(Variations.Test),
            trackImpressionIfInVariant(Variations.Control)
        ]);

        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete]);

    const trackExperimentInteraction = useCallback(
        (eventDetail: string, experimentDetail = "") => {
            if (!experimentKey || !ABTest || !isEnabled || !trackingClient) {
                return;
            }

            trackingClient.track(Events.ExperimentClicked, {
                eventDetail,
                experimentDetail,
                experimentData: {
                    experimentId: experimentKey,
                    experimentName: experimentKey,
                    variationId: Variations.Test,
                    variationName: Variations.Test
                }
            });
        },
        [ABTest, experimentKey, isEnabled, trackingClient]
    );

    const contextObject = useMemo(
        () => ({
            isChangeSizeClickableRulerABEnabled: isEnabled,
            isTrackingComplete,
            trackImpression,
            trackExperimentInteraction
        }),
        [isEnabled, isTrackingComplete, trackExperimentInteraction, trackImpression]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

ChangeSizeClickableRulerABProvider.displayName = "ChangeSizeClickableRulerABProvider";

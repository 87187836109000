import { PROTOTYPE_SIM_TYPE } from "./prototypeSimTypes.js";
import { getNewIdAndNextZIndex, initializeMetadata } from "@design-stack-vista/cimdoc-state-manager";
function addPrototypeSimItem(cimDoc, {
  content,
  panelId,
  position
}) {
  var _a, _b;
  const targetPanel = panelId ? cimDoc.document.panels.find((panel) => panel.id === panelId) : cimDoc.document.panels[0];
  if (!targetPanel.itemReferences) {
    targetPanel.itemReferences = [];
  }
  const {
    zIndex,
    id
  } = /* @__PURE__ */ getNewIdAndNextZIndex(cimDoc, targetPanel.id);
  targetPanel.itemReferences.push({
    id,
    position: position || {
      x: "6mm",
      y: "6mm",
      height: "10mm",
      width: "50mm"
    },
    type: PROTOTYPE_SIM_TYPE,
    url: "https://udsinterop.document.vpsvc.com/api/itemref/wordart",
    data: {
      content,
      color: "rgb(#000000)",
      fontFamily: "Arimo",
      fontStyle: "normal"
    },
    zIndex
  });
  initializeMetadata(cimDoc, {
    initializeTemplate: true
  });
  (_b = (_a = cimDoc.metadata) == null ? void 0 : _a.template) == null ? void 0 : _b.push({
    id,
    locks: {
      edit: false,
      transform: false,
      zIndex: false
    }
  });
  return {
    id
  };
}
export {
  addPrototypeSimItem
};

/**
 * This client talks to UDS in order to save documents, get documents, and get document references
 */
import { upgradeCimDoc } from "@design-stack-vista/cimdoc-state-manager";
import type { DSS } from "@vp/types-ddif";
import { formatError } from "@internal/utils-errors";
import { tryFetch, retry } from "@internal/utils-network";
import { encodeForRenderingApis } from "../encoding";
import { checkResponseError } from "../checkResponseError";

const entityCode = 39;
const UDS_STORAGE_V3_URL = "https://storage.documents.cimpress.io/v3";

const TRANSIENT_INSTRUCTIONS_SIZE_LIMIT = 7000;

interface UdsLink {
    href: string;
}

interface UdsLinks {
    self: UdsLink;
    documentRevision: UdsLink;
    documentReference: UdsLink;
    previewInstructions: UdsLink;
    drawingInstructions: UdsLink;
}

export interface UdsResult {
    _links: UdsLinks;
}

export interface DocRef {
    _links: UdsLinks;
    cimDocUrl?: string;
    prepressInstructionsUrl?: string;
    renderingInstructionsUrl?: string;
    variableDataUri?: string;
}

/**
 * Saves the given docJson to UDS, checking if the document URL already exists
 * @param docJson The document JSON
 * @param authToken The authorization token for the request to UDS
 * @param [documentUrl] For existing documents, the existing UDS document URL. May be null
 * @return The UDS response
 */
export async function postOrPutDocument(
    docJson: DSS.DesignDocument,
    authToken: string,
    documentUrl?: string,
    retryCount?: number
): Promise<UdsResult> {
    const moduleFunction = "udsClient:saveDocument";

    const uri = documentUrl || `${UDS_STORAGE_V3_URL}/documents`;
    const body = JSON.stringify(docJson);

    try {
        // this call is a major source of network errors, and is blocking as the customer cannot save their changes
        // Since this can be a PUT/POST, retrying is not as simple as a GET, but for this particular call I do not think it an issue
        // the concern is that a network error may occur but the request still gets through and is processed by UDS
        // If this is a PUT, then calling this again will just create another UDS document.  The previous one will just be abandoned.
        // If this is a POST, then a new revision will be created
        // either way, the worst that happens is a tiny bit of extra storage space is used.  The customer should not be effected.
        const response = await retry(
            additionalHeaders =>
                fetch(uri, {
                    method: documentUrl ? "PUT" : "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                        "If-Match": "*",
                        ...additionalHeaders
                    },
                    body
                }),
            {
                name: moduleFunction,
                retryCount,
                retryWhenNoException: (result: Response) => {
                    // In testing 500s may work when retried
                    return result.status === 500;
                }
            }
        );

        const resp = await checkResponseError(moduleFunction, response, entityCode);
        return resp;
    } catch (ex: any) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex, undefined, undefined, uri, body?.length);
    }
}

export async function saveTransientDocument(docJson: DSS.DesignDocument, authToken: string): Promise<DocRef> {
    const moduleFunction = "udsClient:saveTransientDocument";
    const url = `${UDS_STORAGE_V3_URL}/documents`;

    const result = await tryFetch({
        url,
        options: {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
                "If-Match": "*"
            },
            body: JSON.stringify({
                ...docJson,
                deleteAfterDays: 1
            })
        },
        moduleFunction,
        entityCode
    });

    return result;
}

export async function getTransientDocumentUrl(
    document: DSS.DesignDocument,
    authToken: string,
    sizeLimit: number = TRANSIENT_INSTRUCTIONS_SIZE_LIMIT
): Promise<string> {
    const base64Document = await encodeForRenderingApis(document);
    const docLength = base64Document.length;

    if (docLength < sizeLimit) {
        return `${UDS_STORAGE_V3_URL}/documents/transient?document=${encodeURIComponent(base64Document)}`;
    }

    return (await saveTransientDocument(document, authToken))._links.documentRevision.href;
}

/**
 * Gets the document reference for a given document revision URL
 * @param documentRevisionUrl The existing document revision URL
 * @return The document reference response
 */
export async function getDocumentReference(documentRevisionUrl: string): Promise<DocRef> {
    const moduleFunction = "udsClient:getDocumentReference";
    try {
        const docRefResponse = await retry(
            additionalHeaders => fetch(`${documentRevisionUrl}/docref`, { headers: additionalHeaders }),
            { name: moduleFunction }
        );

        return checkResponseError(moduleFunction, docRefResponse, entityCode);
    } catch (ex: any) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex);
    }
}

/**
 * Gets the document for a given document revision URL
 * @param documentRevisionUrl The existing document revision URL
 * @param authToken The authorization token for the request to UDS
 * @return The UDS response for that document
 */
export async function getDocument(documentRevisionUrl: string, authToken: string) {
    const moduleFunction = "udsClient:getDocument";
    let url = "";
    try {
        // designer expects units to be in mm
        const hasQuestionMark = documentRevisionUrl.includes("?");
        url = `${documentRevisionUrl}${hasQuestionMark ? "&" : "?"}normalize=true&unit=mm`;
        const rawResponse = await retry(
            additionalHeaders =>
                fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                        ...additionalHeaders
                    }
                }),
            { name: moduleFunction }
        );
        const cimDoc = await checkResponseError(moduleFunction, rawResponse, entityCode);
        upgradeCimDoc(cimDoc);

        return cimDoc;
    } catch (ex: any) {
        if (ex.moduleFunction) {
            throw ex;
        }
        throw formatError(moduleFunction, ex.message, entityCode, "000", ex, undefined, undefined, url);
    }
}

import { FlexBox } from "@vp/swan";
import { observer } from "mobx-react-lite";
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useAdjustContrastCanvas, useSingleColorContrast } from "../../../hooks";
import { useLocalized } from "../../../localized";
import { ImagePreviewDimension } from "../../../types";
import { getBackgroundPreview } from "../../../util";
import styles from "./SingleColorPreview.module.scss";

export const SingleColorPreview = observer(() => {
    const localized = useLocalized();
    const { underlaySceneConfiguration } = useSingleColorContrast();
    const { drawPreview } = useAdjustContrastCanvas();
    const [dimension, setDimension] = useState<ImagePreviewDimension>({ height: 0, width: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
    const imageCanvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            setDimension({ width, height });
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const canvas = (imageCanvasRef as MutableRefObject<HTMLCanvasElement>)?.current;
        drawPreview(canvas);
    }, [drawPreview]);

    const styleProperty = useMemo(
        () =>
            underlaySceneConfiguration
                ? getBackgroundPreview({
                      dimension,
                      underlayScene: underlaySceneConfiguration
                  })
                : undefined,
        [dimension, underlaySceneConfiguration]
    );

    return (
        <FlexBox
            justifyContent="center"
            alignItems="center"
            className={styles.container}
            style={styleProperty}
            ref={containerRef}
        >
            <canvas
                className={styles.singleColorImagePreview}
                ref={imageCanvasRef}
                aria-label={localized.get("altImageDescription")}
            />
        </FlexBox>
    );
});

SingleColorPreview.displayName = "SingleColorPreview";

import { z } from "zod";
const defaultTranslations = {};
const api = /* @__PURE__ */ z.object({
  allowDeleteImage: /* @__PURE__ */ z.boolean()
});
const imageSim = {
  name: "@internal/advanced-editor-sim-image",
  core: {
    defaultTranslations,
    extensions: []
  },
  api
};
export {
  imageSim as default
};

import { DecorationTechnology } from "./types";

enum DecoTechCategory {
    print = "print",
    pantone = "pantone",
    engraved = "engraved",
    embroidery = "embroidery"
}

export const decoTechCategories = {
    [DecorationTechnology.Offset]: DecoTechCategory.print,
    [DecorationTechnology.Unknown]: DecoTechCategory.print,
    [DecorationTechnology.Digital]: DecoTechCategory.print,
    [DecorationTechnology.OffsetOrDigital]: DecoTechCategory.print,
    [DecorationTechnology.DirectToGarment]: DecoTechCategory.print,
    [DecorationTechnology.HeatTransfer]: DecoTechCategory.print,
    [DecorationTechnology.LaserEngraving]: DecoTechCategory.engraved,
    [DecorationTechnology.ScreenPrint]: DecoTechCategory.pantone,
    [DecorationTechnology.PadPrint]: DecoTechCategory.pantone,
    [DecorationTechnology.InkJet]: DecoTechCategory.print,
    [DecorationTechnology.Thermography]: DecoTechCategory.print,
    [DecorationTechnology.EmbroideryFlat]: DecoTechCategory.embroidery,
    [DecorationTechnology.EmbroideryCylinder]: DecoTechCategory.embroidery,
    [DecorationTechnology.Sublimation]: DecoTechCategory.print
};

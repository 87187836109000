import type { DSS, MCP } from "@vp/types-ddif";
import type { Panel } from "@design-stack-vista/cdif-types";
import { Measurement } from "@design-stack-vista/utility-core";
import { DesignSpecification } from "@internal/data-access-calcifer";
import { updateDocumentDimensions } from "@internal/data-access-design-specifications-service";

export const DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE = 1.5;

function getViewDimensionsInCm(view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface) {
    const width = view.widthCm;
    const height = view.heightCm;

    return {
        width,
        height
    };
}

/**
 * Return an object with the difference in cm of a panel's and view's width and height
 */
function getPanelAndViewDimensionDifferenceInCm(
    panel: Panel,
    view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface
) {
    const panelWidthCm = new Measurement(panel.width).cm;
    const panelHeightCm = new Measurement(panel.height).cm;
    const viewDimensions = getViewDimensionsInCm(view);

    return {
        width: Math.abs(panelWidthCm - viewDimensions.width),
        height: Math.abs(panelHeightCm - viewDimensions.height)
    };
}

/**
 * Determine whether or not a panel's width and height are within a specified
 * percentage of the size of a corresponding view's dimensions.
 */
function panelDimensionsWithinPercentage(
    panel: Panel,
    view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface,
    percentage: number = DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE
) {
    const differences = getPanelAndViewDimensionDifferenceInCm(panel, view);
    const viewDimensions = getViewDimensionsInCm(view);

    return (
        differences.width <= (percentage / 100) * viewDimensions.width &&
        differences.height <= (percentage / 100) * viewDimensions.height
    );
}

/**
 * Determine whether or not a panel's dimensions match the dimensions of a design view.
 */
function panelAndViewDimensionsMatch(panel: Panel, view: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface) {
    const differences = getPanelAndViewDimensionDifferenceInCm(panel, view);

    // don't care if they are .00000004 different because of float nonsense
    const widthIsClose = differences.width < 0.001;
    const heightIsClose = differences.height < 0.001;

    return widthIsClose && heightIsClose;
}

/**
 * Determine whether a document should be adjusted. This function returns true if
 * both of the following conditions are met:
 *  1) One or more document panels has different dimensions from those of its corresponding design view.
 *  2) The dimensions of any mismatched panels differ by no more than the specified percentage relative
 *     to the corresponding design views.
 *  3) Document has different number of panels than the surface
 */
function shouldAdjustDocument(
    designDocument: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]
) {
    if (!designDocument.document || !designDocument.document.panels) {
        throw Error("shouldAdjustDocument: design document does not contain document panels");
    }

    if (designDocument.document.panels.length !== views.length) {
        return true;
    }

    const documentPanels = designDocument.document.panels;
    let documentHasDimensionMismatch = false;

    documentPanels.forEach((panel, index) => {
        if (!!views[index] && !panelAndViewDimensionsMatch(panel, views[index])) {
            documentHasDimensionMismatch = true;
        }
    });

    return documentHasDimensionMismatch;
}

/**
 * Determines whether the document panels and surfaces have a dimension mismatch greater than DSS can handle
 */
export function documentDimensionsFailure(
    designDocument: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[],
    maxPanelAdjustmentPercentage: number = DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE
) {
    if (!designDocument.document || !designDocument.document.panels) {
        throw Error("documentDimensionsFailure: design document does not contain document panels");
    }

    const documentPanels = designDocument.document.panels;
    let documentHasDimensionMismatch = false;

    documentPanels.forEach((panel, index) => {
        if (!!views[index] && !panelAndViewDimensionsMatch(panel, views[index])) {
            documentHasDimensionMismatch = true;
        }
    });

    return (
        documentHasDimensionMismatch &&
        documentPanels.some(
            (panel, index) =>
                !!views[index] && !panelDimensionsWithinPercentage(panel, views[index], maxPanelAdjustmentPercentage)
        )
    );
}

/**
 * Updates document dimensions to match view (surface) dimensions.  Can show an alert with dimension change information.
 * @param cimDoc
 * @param designSpecification
 * @param productKey
 * @param productVersion
 * @param studioSelectedProductOptions
 * @param locale
 * @param showAdjustmentAlert
 * @returns
 */
export async function updateForDimensionsMismatch(
    cimDoc: DSS.DesignDocument,
    designSpecification: DesignSpecification,
    productKey: string,
    productVersion: number,
    studioSelectedProductOptions: Record<string, string>,
    locale: string,
    showAdjustmentAlert: (changes: string) => void
) {
    if (shouldAdjustDocument(cimDoc, designSpecification.views)) {
        const showAlert = cimDoc.document.panels.some(
            (panel, index) =>
                designSpecification.views[index] &&
                !panelDimensionsWithinPercentage(
                    panel,
                    designSpecification.views[index],
                    DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE
                )
        );

        if (showAlert) {
            // Figure out what has changed
            let changes = "";
            cimDoc.document.panels.forEach((panel, index) => {
                const view = designSpecification.views[index];
                if (view) {
                    const viewDimensions = getViewDimensionsInCm(view);
                    const difference = getPanelAndViewDimensionDifferenceInCm(panel, view);

                    if (difference.width > (DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE / 100) * viewDimensions.width) {
                        changes += `<li>${panel.name} panel width adjusted from
                            ${Math.round((new Measurement(panel.width).cm + Number.EPSILON) * 1000) / 1000} cm to ${
                            viewDimensions.width
                        } cm.</li>`;
                    }
                    if (difference.height > (DEFAULT_MAX_PANEL_ADJUSTMENT_PERCENTAGE / 100) * viewDimensions.height) {
                        changes += `<li>${panel.name} panel height adjusted from
                            ${Math.round((new Measurement(panel.height).cm + Number.EPSILON) * 1000) / 1000} cm to ${
                            viewDimensions.height
                        } cm.</li>`;
                    }
                }
            });

            showAdjustmentAlert(changes);
        }

        const tolerancePercent = 1000000;

        cimDoc = await updateDocumentDimensions(
            productKey,
            productVersion!,
            studioSelectedProductOptions,
            cimDoc,
            tolerancePercent,
            locale
        );
    }

    return cimDoc;
}

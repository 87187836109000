import type { DSS } from "@vp/types-ddif";
import type { CurveItem, CurveItemV2, EllipseItem, LineItem, RectangleItem } from "@design-stack-vista/cdif-types";
import { isSingleColor } from "@internal/utils-deco-tech";

function isShapeWithColor(
    shape: RectangleItem | EllipseItem | CurveItem | CurveItemV2 | LineItem
): shape is RectangleItem | EllipseItem | CurveItem | CurveItemV2 {
    return shape.type !== "line";
}

// If the shape doesn't have a color on it, Designer created it to be a shape with no fill but a border
// We need to add the no color string to it so it doesn't break Studio
export function fixFillColorUndefined(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        const { shapes, decorationTechnology } = panel;
        // having a diff color in cimDoc for a single color product would be invalid
        if (shapes && !isSingleColor(decorationTechnology)) {
            shapes.forEach(shape => {
                if (isShapeWithColor(shape) && !shape.color) {
                    shape.color = "cmyka(0,0,0,0,0)";
                }
            });
        }
    });
}

import { getSingleColorThresholding } from "@docext/algolib";
// eslint-disable-next-line no-restricted-imports -- FIXME use scopedNewrelicWrapper
import { newRelicWrapper } from "@internal/utils-newrelic";

const MAX_DIMENSION_SIZE = 1000;

export function exceedsMaxImageSize(width: number, height: number) {
    return height > MAX_DIMENSION_SIZE || width > MAX_DIMENSION_SIZE;
}

export function scaleImageToMaxResolution(width: number, height: number) {
    const ratio = Math.min(MAX_DIMENSION_SIZE / width, MAX_DIMENSION_SIZE / height);
    return { width: width * ratio, height: height * ratio };
}

export function drawImageOnCanvas(image: HTMLImageElement, canvas: HTMLCanvasElement) {
    const context = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;

    if (exceedsMaxImageSize(image.width, image.height)) {
        const { width, height } = scaleImageToMaxResolution(image.width, image.height);
        canvas.height = height;
        canvas.width = width;
        context?.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
        context?.drawImage(image, 0, 0);
    }
    const { width, height } = canvas;
    const imgData = context?.getImageData(0, 0, width, height);

    return imgData;
}

export async function drawSingleColor(
    canvas: HTMLCanvasElement,
    color: string,
    threshold: number,
    inverted: boolean,
    image: HTMLImageElement,
    imageData?: ImageData
) {
    const context = canvas.getContext("2d");
    const imgData = imageData || drawImageOnCanvas(image, canvas);

    if (imgData && context) {
        try {
            const newImgData: ImageData = await getSingleColorThresholding(imgData, threshold, color, inverted, "RAW");
            context.putImageData(newImgData, 0, 0);
        } catch (error) {
            newRelicWrapper.noticeError("Error in drawing single color preview", {
                message: (error as Error).message
            });
        }
    }
}

import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import type { ImageItem } from "@design-stack-vista/cdif-types/v2";
import { useActivePanel } from "@design-stack-vista/core-features";
import { useStudioLayout } from "@internal/feature-responsive-design";
import {
    SelectionSet,
    GridContainer,
    Row,
    Column,
    SelectionSetInput,
    SelectionSetLabel,
    FlexBox,
    Typography,
    Divider
} from "@vp/swan";
import {
    useApplyCutlineItems,
    useCutlineConfiguration,
    useGetCutlineMetadata
} from "@internal/feature-cutline-generation";
import { useLocalized } from "../localized/useLocalized";
import styles from "./ToggleBackgroundImage.module.scss";
import { LocalizedKeys } from "../localized";
import { useColorPickerDispatchToast } from "./ColorPickerDispatchToastProvider";

type BackgroundSelectionSetElementProps = {
    imageUrl: string | undefined;
    selectedValue: string;
    localizedLabelKey: keyof LocalizedKeys;
};

const convertUrlToThumbnailVariant = (url: string | undefined): string => {
    if (!url) {
        return "";
    }
    if (url.includes("sherbert")) {
        return url.replace("webPreview", "thumb");
    }
    return url.replace("preview", "thumb");
};

const BackgroundSelectionSetElement = (props: BackgroundSelectionSetElementProps) => {
    const { imageUrl, selectedValue, localizedLabelKey } = props;
    const localized = useLocalized();
    const { activePanel } = useActivePanel();

    const panelImageItem: ImageItem | undefined = activePanel?.items.find(item => item.type === "image")
        ?.asJson as ImageItem;

    return (
        <SelectionSetInput value={selectedValue} disabled={!panelImageItem}>
            <SelectionSetLabel px={"3"} py={"4"}>
                <FlexBox
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection="column"
                    className={styles.backgroundToggleSelectionFlexBox}
                    gap={"3"}
                >
                    <div className={styles.backgroundToggleImageFlexBox}>
                        {localizedLabelKey === "noBackground" && <div className={styles.checkerboard}></div>}
                        {imageUrl && (
                            <img
                                className={styles.backgroundToggleImageItem}
                                src={imageUrl}
                                alt={`${localized.get(localizedLabelKey)}`}
                            />
                        )}
                    </div>
                    <Typography fontSize="xsmall" fontWeight="bold">
                        {`${localized.get(localizedLabelKey)}`}
                    </Typography>
                </FlexBox>
            </SelectionSetLabel>
        </SelectionSetInput>
    );
};

export const ToggleBackgroundImage = observer(() => {
    const { dispatchToast } = useColorPickerDispatchToast();
    const localized = useLocalized();
    const { isSmall } = useStudioLayout();
    const { activePanel } = useActivePanel();

    const { applyCutlineItems } = useApplyCutlineItems({ dispatchToast });
    const cutlineConfigurationManager = useCutlineConfiguration();
    const { cachedImageData: cachedImageDataFromMetadata, imageVariantType, cutlineShape } = useGetCutlineMetadata();

    const cachedImageData = cutlineConfigurationManager.getCachedImageData() || cachedImageDataFromMetadata;
    const backgroundRemovedImageUrl = cachedImageData?.backgroundRemovedImageItem?.previewUrl;
    const originalImageUrl = cachedImageData?.originalImageItem?.previewUrl;
    const backgroundRemovedThumbnailUrl = convertUrlToThumbnailVariant(backgroundRemovedImageUrl);
    const originalThumbnailUrl = convertUrlToThumbnailVariant(originalImageUrl);

    const handleSelectedValueChange = useCallback(
        async (selectedValue: string | null) => {
            if (!selectedValue) {
                return;
            }

            if (selectedValue === "originalBackground") {
                await applyCutlineItems({
                    panelId: activePanel?.id,
                    imageData: {
                        imageVariantType: "original",
                        cutlineBaseImageVariantType: cutlineShape === "custom" ? "saliencyMask" : "original"
                    }
                });
            }

            if (selectedValue === "noBackground") {
                await applyCutlineItems({
                    panelId: activePanel?.id,
                    imageData: {
                        imageVariantType: "backgroundRemoved",
                        cutlineBaseImageVariantType: "backgroundRemoved"
                    }
                });
            }
        },
        [activePanel, applyCutlineItems, cutlineShape]
    );

    return (
        <>
            <Typography
                fontSize={isSmall ? "small" : "xsmall"}
                fontWeight={"normal"}
                mb={"3"}
                textColor="subtle"
                id="backgroundRemovalTitle"
            >
                {`${localized.get("backgroundRemovalTitle")}`}
            </Typography>
            <SelectionSet
                variant="single-select"
                selectedValue={imageVariantType === "original" ? "originalBackground" : "noBackground"}
                onSelectedValueChange={handleSelectedValueChange}
                aria-labelledby="backgroundRemovalTitle"
            >
                <GridContainer>
                    <Row>
                        <Column
                            span={6}
                            spanXs={6}
                            pl={0}
                            className={classNames(styles.backgroundToggleColumn, styles.backgroundLeftColumn)}
                        >
                            <BackgroundSelectionSetElement
                                imageUrl={originalThumbnailUrl}
                                selectedValue="originalBackground"
                                localizedLabelKey="original"
                            />
                        </Column>
                        <Column
                            span={6}
                            spanXs={6}
                            pr={0}
                            className={classNames(styles.backgroundToggleColumn, styles.backgroundRightColumn)}
                        >
                            <BackgroundSelectionSetElement
                                imageUrl={backgroundRemovedThumbnailUrl}
                                selectedValue="noBackground"
                                localizedLabelKey="noBackground"
                            />
                        </Column>
                    </Row>
                </GridContainer>
            </SelectionSet>
            {!isSmall && <Divider mb={"2"} />}
        </>
    );
});

ToggleBackgroundImage.displayName = "ToggleBackgroundImage";

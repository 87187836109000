import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// TODO: This looks like it was unset in the initial state before,
// the reducer implied taht it should default to true, but it looks like
// it should be false?

const showDesignIssuesSlice = createSlice({
    name: "showDesignIssues",
    initialState: true,
    reducers: {
        setShowDesignIssues: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { setShowDesignIssues } = showDesignIssuesSlice.actions;
export const showDesignIssues = showDesignIssuesSlice.reducer;

async function getPanel(subpanelUrl) {
  const response = await fetch(subpanelUrl, {
    method: "GET",
    headers: {
      Accept: "application/json"
    }
  });
  return response.json();
}
export {
  getPanel
};

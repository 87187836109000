import { isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import { ItemTemplateExtension } from "@design-stack-vista/interactive-design-engine-core";
import { getRequiredExtension } from "@design-stack-vista/core-features";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
const isQrCodeTypeReference = (item) => {
  return item.isItemReference() && item.model.type === ItemReferenceTypes.QR_CODE;
};
function isQRCodeItem(item) {
  return isItemReference(item) && item.type === ItemReferenceTypes.QR_CODE;
}
const QR_CODE_INTENT = "QR Code";
function isImageQRPlaceholder(item, designEngine) {
  const {
    intent
  } = /* @__PURE__ */ getRequiredExtension(designEngine, item, ItemTemplateExtension);
  return intent === QR_CODE_INTENT;
}
export {
  isImageQRPlaceholder,
  isQRCodeItem,
  isQrCodeTypeReference
};

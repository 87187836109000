import { createSlice } from "@reduxjs/toolkit";

const pricingInitializedSlice = createSlice({
    name: "pricingInitialized",
    initialState: false,
    reducers: {
        setPricingInitialized: () => true
    }
});

export const { setPricingInitialized } = pricingInitializedSlice.actions;
export const pricingInitialized = pricingInitializedSlice.reducer;

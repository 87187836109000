import type { DSS } from "@vp/types-ddif";
import type { AnyTextContent } from "@design-stack-vista/cdif-types";
import { colorToString, isCmyka, parseColor } from "@internal/utils-color";

function recursivelyRemoveAlphaFromCMYKAColors(content: AnyTextContent[]) {
    content.forEach((field: AnyTextContent) => {
        if (field.color) {
            const newColor = parseColor(field.color);
            if (newColor && isCmyka(newColor.value)) {
                // @ts-ignore (cdif types define color as a required field)
                delete newColor.value.a;
                field.color = colorToString(newColor.value);
            }
        }
        if (field.content && Array.isArray(field.content)) {
            recursivelyRemoveAlphaFromCMYKAColors(field.content);
        }
    });
}

export function updateCimdocToRemoveAlphaFromCMYKAColors(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas.forEach(textArea => {
                // @ts-expect-error -- textFields are not part of the cimdoc spec but can occur on older documents or templates, so to be safe we check for both
                recursivelyRemoveAlphaFromCMYKAColors(textArea.content || textArea.textFields);
            });
        }
    });
}

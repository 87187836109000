import { isVatInclusive } from "./pricingServiceClient";
import { ChoiceGroupResponse, PricingType, PricingChoiceGroup, PricingApiResponse, Pricing } from "./types";

export function formatPrice(price: number, locale: string, currency: string) {
    const formatter = new Intl.NumberFormat(locale, {
        currency,
        minimumFractionDigits: 2,
        style: "currency"
    });
    return formatter.format(price);
}

export const Sign = {
    POSITIVE: "+",
    NEGATIVE: "-"
};

export const DEFAULT_FRACTION_DIGITS = 2;

// save only relevant choice group values from the api response
export const formatChoiceGroupsPrice = (
    choiceGroups: Record<string, ChoiceGroupResponse>,
    priceToUse: PricingType
): Record<string, PricingChoiceGroup> => {
    return Object.entries(choiceGroups).reduce((acc, [choiceName, choiceValue]) => {
        const {
            totalListPrice,
            totalDiscountPrice,
            differentialListPrice,
            differentialDiscountPrice,
            unitListPrice,
            unitDiscountedPrice
        } = choiceValue;
        return {
            ...acc,
            [choiceName]: {
                // @ts-ignore FIXME: must handle implicit `any` type
                totalListPrice: totalListPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                totalDiscountPrice: totalDiscountPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                differentialListPrice: differentialListPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                differentialDiscountPrice: differentialDiscountPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                unitListPrice: unitListPrice[priceToUse],
                // @ts-ignore FIXME: must handle implicit `any` type
                unitDiscountedPrice: unitDiscountedPrice[priceToUse]
            }
        };
    }, {});
};

// format api response before saving
export const formatPricingData = (pricingData: PricingApiResponse): Pricing => {
    const { baseSelectionsDiscountedPrice, baseSelectionsListPrice, choiceGroups, currency } = pricingData;
    const priceToUse = isVatInclusive() ? PricingType.TAXED : PricingType.UNTAXED;

    const formattedChoiceGroups = formatChoiceGroupsPrice(choiceGroups, priceToUse);

    const formattedPricing = {
        currency,
        // @ts-ignore FIXME: must handle implicit `any` type
        baseSelectionsDiscountedPrice: baseSelectionsDiscountedPrice[priceToUse],
        // @ts-ignore FIXME: must handle implicit `any` type
        baseSelectionsListPrice: baseSelectionsListPrice[priceToUse],
        choiceGroups: formattedChoiceGroups
    };
    return formattedPricing;
};

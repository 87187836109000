import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { DSS } from "@vp/types-ddif";

const reviewInstructionsSlice = createSlice({
    name: "reviewInstructions",
    initialState: [] as DSS.ReviewInstructions[],
    reducers: {
        patchReviewInstructions(state, action: PayloadAction<DSS.ReviewInstructions>) {
            return [
                ...state.filter(
                    instruction =>
                        instruction.panelId !== action.payload.panelId ||
                        instruction.instructionType !== action.payload.instructionType
                ),
                action.payload
            ];
        },
        setReviewInstructions(state, action: PayloadAction<DSS.ReviewInstructions[]>) {
            return action.payload;
        }
    }
});

export const { setReviewInstructions, patchReviewInstructions } = reviewInstructionsSlice.actions;
export const reviewInstructions = reviewInstructionsSlice.reducer;

import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { SurfaceUpsellDataType } from "../types";
import { SurfaceUpsell } from "@internal/data-access-calcifer";

interface SurfaceUpsellData {
    isSurfaceUpsellDataLoaded: boolean;
    surfaceUpsellData: SurfaceUpsellDataType;
    currency: string;
    showPanelSections: boolean;
    propductSurfaceUpsells: SurfaceUpsell[];
    handleSurfaceUpsellDataLoaded: () => void;
    updateSurfaceUpsellData: (data: SurfaceUpsellDataType) => void;
    handleCurrencyUpdate: (currency: string) => void;
}

const SurfaceUpsellDataContext = createContext<SurfaceUpsellData | undefined>(undefined);

export const useSurfaceUpsellData = (): SurfaceUpsellData => {
    const result = useContext(SurfaceUpsellDataContext);

    if (!result) {
        throw Error("Please call this within a SurfaceUpsellDataProvider");
    }
    return result;
};

interface SurfaceUpsellDataProviderProps {
    useSurfaceUpsells: () => SurfaceUpsell[];
    showPanelSections: boolean;
}

export function SurfaceUpsellDataProvider({
    children,
    useSurfaceUpsells,
    showPanelSections
}: PropsWithChildren<SurfaceUpsellDataProviderProps>) {
    const { Provider } = SurfaceUpsellDataContext;

    const [isSurfaceUpsellDataLoaded, setIsSurfaceUpsellDataLoaded] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>("");
    const [surfaceUpsellData, setSurfaceUpsellData] = useState<SurfaceUpsellDataType>({});

    const propductSurfaceUpsells = useSurfaceUpsells();

    const handleSurfaceUpsellDataLoaded = useCallback(() => {
        setIsSurfaceUpsellDataLoaded(true);
    }, []);

    const updateSurfaceUpsellData = useCallback(
        (data: SurfaceUpsellDataType) => {
            setSurfaceUpsellData(data);
            handleSurfaceUpsellDataLoaded();
        },
        [handleSurfaceUpsellDataLoaded]
    );

    const handleCurrencyUpdate = useCallback((currency: string) => {
        setCurrency(currency);
    }, []);

    const value = useMemo(() => {
        return {
            isSurfaceUpsellDataLoaded,
            surfaceUpsellData,
            currency,
            setCurrency,
            updateSurfaceUpsellData,
            handleCurrencyUpdate,
            propductSurfaceUpsells,
            showPanelSections,
            handleSurfaceUpsellDataLoaded
        };
    }, [
        currency,
        handleCurrencyUpdate,
        handleSurfaceUpsellDataLoaded,
        isSurfaceUpsellDataLoaded,
        propductSurfaceUpsells,
        showPanelSections,
        surfaceUpsellData,
        updateSurfaceUpsellData
    ]);

    return <Provider value={value}>{children}</Provider>;
}

import React from "react";
import { observer } from "mobx-react-lite";
import { ColorOverride, getColorOverrideKey, SelectableColor } from "@internal/utils-color";
import { ItemColors } from "./ItemColors";

interface Props {
    colorsList: SelectableColor[] | null;
    selectedColor: ColorOverride | undefined;
    onSelectColor(color: SelectableColor): void;
}

export const ItemColorsWrapper = observer(({ colorsList, selectedColor, onSelectColor }: Props) => {
    if (!colorsList) {
        return null;
    }

    return (
        <ItemColors
            onSelectColor={onSelectColor}
            colors={colorsList}
            selectedColor={selectedColor ? getColorOverrideKey(selectedColor) : undefined}
        />
    );
});
ItemColorsWrapper.displayName = "ItemColorsWrapper";

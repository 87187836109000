import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";

type PageInfo = { width: number; height: number; pageNumber: number };
type VistaAssetWithMultiPageInfo = VistaAsset & {
    data: { info: { image: { numPages: number; pagesInfo: PageInfo[] } } };
};
/**
 * Checks if a given VistaAsset has multiple pages.
 *
 * @param asset - The VistaAsset to check.
 * @returns `true` if the asset has multiple pages, `false` otherwise.
 */
export function isAssetMultiPage(asset: VistaAsset): asset is VistaAssetWithMultiPageInfo {
    if (asset.data && asset.data.info.image) {
        return "numPages" in asset.data.info.image;
    }
    return false;
}

import { ImageItem } from "@design-stack-vista/cdif-types";
import { Path } from "@design-stack-vista/interactive-design-engine-core";
import { UnitlessDimensions } from "@design-stack-vista/utility-core";
import type { CutlineShapeItem, UnitlessBoundingBox } from "@prepress/custom-cutline-utility";
import { makeObservable, observable, action, toJS } from "mobx";

export type CutlineShapeType = "custom" | "rectangle" | "roundedRectangle" | "circle";
export type CutlinePrecisionType = "tight" | "medium" | "loose";

type CutlineConfigurationManagerArgs = {
    cutlineShape?: CutlineShapeType;
    isGeneratingCutline?: boolean;
};

export interface CustomCutlineData {
    cutlineItem: CutlineShapeItem;
    adjustedContentBounds: UnitlessBoundingBox;
    cutlinePath: Path;
    cutlineBoundingBox: UnitlessBoundingBox;
    safetyDistanceInMm: number;
    cutlinePrecision: CutlinePrecisionType;
}

export type CachedCutlineImageData = {
    originalImageItem: ImageItem;
    originalImageDimensions: UnitlessDimensions;
    backgroundRemovedImageItem?: ImageItem;
    backgroundRemovedCropBoundingBox?: UnitlessBoundingBox;
};

type CutlineMessagesType = {
    customCutlineError: {
        id: string;
        defaultMessage: string;
        description: {
            note: string;
        };
    };
    basicCutlineError: {
        id: string;
        defaultMessage: string;
        description: {
            note: string;
        };
    };
};

const defaultCutlineMessages: CutlineMessagesType = {
    customCutlineError: {
        id: "easel.components.customCutlineGeneration.error",
        defaultMessage: "Custom cutline isn't available. Try another cutline, sticker size or image.",
        description: {
            note: "Toast for when custom cutline generation fails"
        }
    },
    basicCutlineError: {
        id: "easel.components.basicCutlineGeneration.error",
        defaultMessage: "Basic cutline isn't available. Try another cutline, sticker size or image.",
        description: {
            note: "Toast for when basic cutline generation fails"
        }
    }
};

export class CutlineConfigurationManager {
    @observable
    cutlineShape: CutlineShapeType;

    @observable
    imageData?: CachedCutlineImageData;

    @observable
    private customCutlineCache: Map<string, CustomCutlineData> = new Map();

    @observable
    private cutlineMessages: CutlineMessagesType;

    @observable
    isGeneratingCutline: boolean;

    @observable
    isReuploadingImage: boolean;

    constructor({ cutlineShape }: CutlineConfigurationManagerArgs) {
        this.cutlineShape = cutlineShape ?? "custom";
        this.cutlineMessages = defaultCutlineMessages;
        this.isGeneratingCutline = false;
        this.isReuploadingImage = false;
        // @kludge - enables destructuring of of this method without losing `this` context.
        // recommended pattern is to avoid destructuring methods/properties off a class instance.
        this.getCachedImageData = this.getCachedImageData.bind(this);
        makeObservable(this);
    }

    @action.bound
    setIsGeneratingCutline(isGeneratingCutline: boolean) {
        this.isGeneratingCutline = isGeneratingCutline;
    }

    @action.bound
    setIsReuploadingImage(isReuploadingImage: boolean) {
        this.isReuploadingImage = isReuploadingImage;
    }

    @action.bound
    setCutlineShape(cutlineShape: CutlineShapeType) {
        this.cutlineShape = cutlineShape;
    }

    @action.bound
    setCutlineMessages(cutlineMessages: CutlineMessagesType) {
        this.cutlineMessages = cutlineMessages;
    }

    @action.bound
    updateCutlineCache(imageUrl: string, cutlineData: CustomCutlineData): void {
        this.customCutlineCache.set(imageUrl, cutlineData);
    }

    getCachedCutline(imageUrl: string): CustomCutlineData | undefined {
        return toJS(this.customCutlineCache.get(imageUrl));
    }

    getCutlineMessages() {
        return this.cutlineMessages;
    }

    getCachedImageData() {
        if (!this.imageData) {
            return;
        }

        // we provide this to some external library that tries to freeze data from this object, thus we convert it to vanilla JS
        return toJS(this.imageData);
    }

    @action.bound
    clearCache() {
        this.customCutlineCache = new Map();
    }

    @action.bound
    setImageData(imageData: CachedCutlineImageData) {
        this.imageData = imageData;
    }
}

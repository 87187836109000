import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Payload = {
    isPremiumFinishModalOpen: boolean;
    premiumFinishModalOpenedContext?: string;
};

const isPremiumFinishModalOpenSlice = createSlice({
    name: "isPremiumFinishModalOpen",
    initialState: false,
    reducers: {
        setIsPremiumFinishModalOpen: (state, action: PayloadAction<Payload>) => action.payload.isPremiumFinishModalOpen
    }
});

export const { setIsPremiumFinishModalOpen } = isPremiumFinishModalOpenSlice.actions;
export const isPremiumFinishModalOpen = isPremiumFinishModalOpenSlice.reducer;

import { buildItemPreviewUrl } from "@design-stack-ct/preview-sdk";
import { getRenderingPixelValue } from "@design-stack-vista/utility-core";
import { getRootPanel } from "@design-stack-vista/cimdoc-state-manager";
function prepareItemForCachedRequests(model) {
  const item = {
    ...model,
    id: "",
    rotationAngle: "0",
    zIndex: 0
  };
  if ("position" in item) {
    item.position = {
      ...item.position,
      x: "0mm",
      y: "0mm"
    };
  }
  return item;
}
function getItemStatePreviewUrl({
  itemState,
  tenant,
  cimDocProperties,
  zoom,
  previewModelOverride,
  itemPropertyOverrides
}) {
  const {
    version,
    fontRepositoryUrl
  } = cimDocProperties;
  const {
    width,
    height,
    decorationTechnology
  } = getRootPanel(itemState).panelProperties;
  const cimDocInfo = {
    version,
    fontRepositoryUrl,
    width,
    height,
    decorationTechnology
  };
  return buildItemPreviewUrl({
    // @ts-ignore caused by typing conflict from @design-stack-ct/cdif-types vs @design-stack-vista/cdif-types preview-sdk is outdated
    item: prepareItemForCachedRequests(previewModelOverride || itemState.model),
    cimDocInfo,
    // @ts-ignore caused by typing conflict from @design-stack-ct/cdif-types vs @design-stack-vista/cdif-types preview-sdk is outdated
    itemPropertyOverrides,
    renderSize: getRenderingPixelValue(zoom),
    tenant
  });
}
export {
  getItemStatePreviewUrl,
  prepareItemForCachedRequests
};

import type { DSS } from "@vp/types-ddif";

/**
 * Remove text areas that have content and textFields fields missing.
 */
export function removeTextAreasWithoutContent(cimDoc: DSS.DesignDocument) {
    cimDoc.document.panels.forEach(panel => {
        if (panel.textAreas) {
            panel.textAreas = panel.textAreas.filter(textArea => {
                return (
                    textArea.content ||
                    // @ts-expect-error -- textFields are not part of the cimdoc spec but can occur on older documents or templates, so to be safe we check for both
                    textArea.textFields
                );
            });
        }
    });
}

import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemPreloaderExtension } from "@internal/sim-framework";
import { override } from "mobx";
import { name } from "../sim/name";

export class ImagePluginPreloaderPanelExtension extends ItemPreloaderExtension {
    declare designState: PanelState;

    // eslint-disable-next-line class-methods-use-this
    get simName() {
        return name;
    }

    // eslint-disable-next-line class-methods-use-this
    @override
    get shouldPreload() {
        return true;
    }
}

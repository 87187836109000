import { isCareAgent } from "@internal/utils-browser";
import { useIsFlagEnabled } from "@internal/utils-flags";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import { Box, Link, P } from "@vp/swan";
import { usePageContext } from "@shared/features/StudioConfiguration";

const messages = defineMessages({
    significantAdjustedDimensions: {
        id: "studio.components.Toast.significantAdjustedDimensions",
        defaultMessage:
            "We upgraded this product and had to resize your image to fit. Please make sure the image looks just right.",
        description: {
            note: "This message is displayed when a design has been automatically resized due to a change to the underlying product."
        }
    },
    designSupportCareContact: {
        id: "studio.components.Toast.contactCare",
        defaultMessage: "For design support, please contact us",
        description: {
            note: `This bit of text is meant to be added to the end of alerts that we want to allow customers to contact care agents for
                design support.`
        }
    }
});

interface ToastCareMessageProps {
    careOnlyMessage: string;
}

export const ToastCareMessage = ({ careOnlyMessage }: ToastCareMessageProps) => {
    const { t } = useTranslationSSR();
    const { helpCenterUrl } = usePageContext();
    const advancedTools = useIsFlagEnabled("advancedTools");
    const showCareMessages = isCareAgent() || advancedTools;

    return (
        <Box>
            {t(messages.significantAdjustedDimensions.id)}
            <Box>
                <Link href={helpCenterUrl}>{t(messages.designSupportCareContact.id)}</Link>.
            </Box>
            {showCareMessages && careOnlyMessage && (
                <Box>
                    <br />
                    <P dangerouslySetInnerHTML={{ __html: careOnlyMessage }} />
                </Box>
            )}
        </Box>
    );
};

ToastCareMessage.displayName = "ToastCareMessage";

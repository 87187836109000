import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const showEmbroideryImageColorModalSlice = createSlice({
    name: "showEmbroideryImageColorModal",
    initialState: false,
    reducers: {
        showEmbroideryImageColorModal: (state, action: PayloadAction<boolean>) => action.payload
    }
});

export const { showEmbroideryImageColorModal } = showEmbroideryImageColorModalSlice.actions;
export const embroideryImageColorModalOpen = showEmbroideryImageColorModalSlice.reducer;

import { RGB, findRgbDifference } from "@design-stack-vista/utility-core";

/**
 * Searches for the color in the provided `colors` array that is closest to the `targetColor`.
 *
 * @param targetColor - The color to find the closest match for.
 * @param colors - The array of colors to search through.
 * @returns The color from the `colors` array that is closest to the `targetColor`.
 */
export function searchForSimilarColor(targetColor: RGB, colors: RGB[]) {
    let bestMatch: { color: RGB; colorDifference: number } | undefined;

    colors.forEach(color => {
        const colorDifference = findRgbDifference(targetColor, color);

        if (!bestMatch || bestMatch.colorDifference > colorDifference) {
            bestMatch = { colorDifference, color };
        }
    });

    return bestMatch?.color;
}

import React from "react";
import { SpinnerIcon, SettingsIcon } from "@internal/ui-studio-chrome";
import classNames from "classnames";
import { isDarkRGB, SelectableColor } from "@internal/utils-color";
import styles from "./ItemColors.module.scss";
import { ColorSwatch } from "../ColorSwatch/ColorSwatch";
import { useLocalized } from "../localized";

interface ItemColorsProps {
    onSelectColor(color: SelectableColor): void;
    colors: SelectableColor[] | null;
    selectedColor: string | undefined;
}

export const ItemColors = (props: ItemColorsProps) => {
    const { selectedColor, colors, onSelectColor } = props;
    const locale = useLocalized().locale;

    if (!colors) {
        return <SpinnerIcon className={styles.spinner} />;
    }

    return (
        <div className={styles.itemColorsWrapper}>
            <div className={styles.itemColors} data-testid="item-colors">
                {colors?.map((color, index) => {
                    const isDark = isDarkRGB(color.cssBackground);

                    return (
                        <div key={color.value + index} className={styles.itemColorButtonWrapper}>
                            {color.value === selectedColor && (
                                <SettingsIcon
                                    aria-hidden={true}
                                    className={classNames(styles.stylesItemColorsIcon, {
                                        [styles.dark]: isDark
                                    })}
                                />
                            )}
                            <ColorSwatch
                                isSelected={color.value === selectedColor}
                                color={color}
                                toggleColor={onSelectColor}
                                className={styles.colorSwatch}
                                locale={locale}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ItemColors.displayName = "ItemColors";

import { ALL_SIM_MODULES } from "@six/features/sims";
import type { ScopedSimName } from "@internal/utils-sim-types";
import { useStudioConfigurationManager } from "@internal/dex";

/**
 * Aggregates the results of any overrides that activate or deactivate
 * sims based on feature flags, ab tests, etc.
 *
 * @note this must be called based on the `ALL_SIM_MODULES` and not
 * via `useSims()` because the value must be created *before* the
 * SimsManager is instantiated. The result is then used
 * to update the `active` flag on each sim within the SimsManager.
 */
export const useAggregateAllSimOverrides = () => {
    const studioConfigurationManager = useStudioConfigurationManager();
    const simOverrides = Array.from(ALL_SIM_MODULES).reduce<Record<ScopedSimName, boolean | undefined>>((acc, sim) => {
        const { useOverrideEnableState } = sim.core;

        if (!useOverrideEnableState) {
            return acc;
        }

        return {
            ...acc,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            ...useOverrideEnableState(studioConfigurationManager.data)
        };
    }, {});

    return simOverrides;
};

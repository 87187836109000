import React, { useCallback, type JSX } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { CMYK, HSV, RGB } from "@design-stack-vista/utility-core";
import { RichTextControlWrapper } from "@design-stack-vista/core-features";
import { PanelContent, PanelTitle } from "@internal/ui-studio-chrome";
import type { DispatchToast } from "@internal/utils-detail-zone";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useRecentColors } from "@internal/feature-recent-colors";
import { ColorSpace } from "@internal/utils-color";
import { useGetCutlineMetadata, useTransferUploadToSherbert } from "@internal/feature-cutline-generation";
import { useSelectedItemColors } from "@internal/utils-item-color";
import { ColorPickerPanelWrapper, ColorPickerProps } from "./ColorPickerPanelWrapper";
import { ToggleBackgroundImage } from "./ToggleBackgroundImage";
import { ColorPickerDispatchToastProvider } from "./ColorPickerDispatchToastProvider";
import styles from "./ColorPickerPanel.module.scss";

interface Props extends Omit<ColorPickerProps, "currentColorValues" | "onChange"> {
    allowTransparency?: boolean;
    onChange: (cdifColor: string) => void;
    color: string | undefined;
    title?: string;
    panelType?: string;
    renderItemColors?: JSX.Element;
    showColorPickerPanelWrapper?: boolean;
    dispatchToast?: DispatchToast;
    className?: string;
    enableToggleBackground: boolean;
}

export const ColorPickerPanel = observer((props: Props) => {
    const {
        title,
        allowTransparency,
        onChange,
        color,
        panelType,
        renderItemColors,
        showColorPickerPanelWrapper = true,
        dispatchToast,
        className,
        descriptionTitle,
        enableToggleBackground
    } = props;
    const { currentColorValues, getCdifColor, colorPalette } = useSelectedItemColors(color);
    const { recentColors } = useRecentColors();

    const { isSmall } = useStudioLayout();
    const { backgroundRemovedSuccess } = useGetCutlineMetadata();

    // images from Sticker DEX need to be reuploaded to Sherbert
    // and added back to the document for cutline shape and background composition panels to work
    useTransferUploadToSherbert({ dispatchToast });

    const handleChangeColor = useCallback(
        (newColor: string | RGB | CMYK | HSV, colorSpace: ColorSpace) => {
            const cdifColor = getCdifColor(newColor, colorSpace);

            if (cdifColor) {
                onChange(cdifColor);
            }
        },
        [onChange, getCdifColor]
    );

    return (
        <ColorPickerDispatchToastProvider dispatchToast={dispatchToast}>
            <RichTextControlWrapper>
                <PanelContent
                    className={classNames(
                        styles.colorPickerPanel,
                        {
                            [styles.colorPickerPanelSmallHeight]: !showColorPickerPanelWrapper,
                            [styles.colorPickerPanelLargeHeight]:
                                showColorPickerPanelWrapper && renderItemColors && recentColors.length
                        },
                        className
                    )}
                >
                    {title && (
                        <div className={styles.colorPickerPanelHeader}>
                            <PanelTitle>{title}</PanelTitle>
                        </div>
                    )}
                    {enableToggleBackground && !isSmall && backgroundRemovedSuccess && <ToggleBackgroundImage />}
                    {renderItemColors}
                    {showColorPickerPanelWrapper && (
                        <ColorPickerPanelWrapper
                            currentColorValues={currentColorValues}
                            colorPalette={colorPalette}
                            allowTransparency={allowTransparency}
                            onChange={handleChangeColor}
                            panelType={panelType}
                            descriptionTitle={descriptionTitle}
                            enableToggleBackground={enableToggleBackground}
                        />
                    )}
                </PanelContent>
            </RichTextControlWrapper>
        </ColorPickerDispatchToastProvider>
    );
});
ColorPickerPanel.displayName = "ColorPickerPanel";

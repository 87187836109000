import { getNewIdAndNextZIndex } from "@design-stack-vista/cimdoc-state-manager";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
const VECTOR_URL = "https://items.documents.cimpress.io/v1/vector";
const addVectorItem = (cimDoc, {
  panelId,
  data,
  position
}) => {
  const panel = /* @__PURE__ */ cimDoc.document.panels.find((p) => p.id === panelId);
  if (!panel) {
    throw new Error(`addVectorItem: no panel found with id: ${panelId}`);
  }
  const {
    zIndex,
    id
  } = /* @__PURE__ */ getNewIdAndNextZIndex(cimDoc, panelId);
  const itemReferenceItem = {
    id,
    type: ItemReferenceTypes.VECTOR_PANEL,
    url: VECTOR_URL,
    data,
    zIndex,
    position
  };
  if (panel.itemReferences) {
    panel.itemReferences.push(itemReferenceItem);
  } else {
    panel.itemReferences = [itemReferenceItem];
  }
  return {
    id: itemReferenceItem.id
  };
};
export {
  addVectorItem
};

import { useAppSelector } from "@shared/redux";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useAvailablePremiumFinishesOnCanvas } from "./useAvailablePremiumFinishesOnCanvas";
import { PF_INSTRUCTIONS_TYPE, SUPPORTED_FINISHES } from "./constants";

export function usePremiumFinishReviewInstructionsRequired(activeCanvasName?: string) {
    const designRequirements = useDesignRequirementsContext();
    const { enabledCanvasFinish } = useAvailablePremiumFinishesOnCanvas(activeCanvasName);

    const reviewInstructions = useAppSelector(state => state.reviewInstructions);
    const { isFullBleed } = useProductAndProjectStateManager().data;

    const finishType = enabledCanvasFinish.length > 0 ? enabledCanvasFinish[0] : null;

    if (!(activeCanvasName && finishType && SUPPORTED_FINISHES.includes(finishType) && isFullBleed)) {
        return null;
    }

    const activeCanvasId = designRequirements?.getPanelByName(activeCanvasName)?.id;

    const panelFullBleedInstructions = reviewInstructions.find(
        instr => instr.instructionType === PF_INSTRUCTIONS_TYPE && instr.panelId === activeCanvasId
    );

    if (!panelFullBleedInstructions || !panelFullBleedInstructions.finishOption) {
        return true;
    }
    return false;
}

import { type SelectableColor } from "@internal/utils-color";

export const DEFAULT_ENGRAVING_SELECTABLE_COLOR: SelectableColor = {
    value: "spot(Engraving)",
    cssBackground: "#C0C0C0"
};
export const DEFAULT_SINGLE_COLOR_SELECTABLE_COLOR: SelectableColor = {
    value: "pantone(White)",
    cssBackground: "#FFFFFF"
};

import React, { useContext, createContext, ReactNode, useMemo, useCallback, useState } from "react";
import { ChangeTrimIcon, ChangeTrimToRoundedIcon, ChangeTrimToStandardIcon } from "@internal/ui-studio-chrome";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import {
    mapDesignAttributeToProductOption,
    mapProductOptionValueToDesignAttributeValue
} from "@internal/utils-studio-configuration-core";
import { cm2in } from "@design-stack-vista/utility-core";
import { useSurfaceSpecs } from "@shared/features/SurfaceSpecifications";
import type { GetDocument } from "@internal/utils-cimdoc";
import type { DSS } from "@vp/types-ddif";
import { observer } from "mobx-react-lite";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { FlexibilityDesignAttributes, TrimIconTypes } from "./constants";
import { useSizeCompatibleFlexibilityDesignAttributes } from "./Size/useSizeCompatibleFlexibilityDesignAttributes";
import { useCompatibleFlexibilityDesignAttributes } from "./common/hooks/useCompatibleFlexibilityDesignAttributes";
import { usePremiumFinishCompatibleFlexibilityDesignAttributes } from "./PremiumFinish/usePremiumFinishCompatibleFlexibilityDesignAttributes";
import { useGetQuantityAttributes } from "./Quantity/useGetQuantityAttributes";
import { useFlexibilityMerchandisingData } from "./common/hooks/useFlexibilityMerchandisingData";
import { useDesignAttributeMappings } from "../StudioBootstrap";

export type LoadNewProductForFlexibility = (props: LoadNewProductForFlexibilityProps) => Promise<void>;

export interface LoadNewProductForFlexibilityProps {
    productKey: string;
    locale: string;
    customerSelectedProductOptions: {
        [x: string]: string;
    };
    productVersion: number | undefined;
    quantity: number;
    quantityPerSize: string | undefined;
    template: string | undefined;
    targetDocument: DSS.DesignDocument;
    saveWork: boolean;
    isFullBleed: boolean;
}

type ContextData = {
    getDesignAttributeValues: (designAttributeName: string) => string[];
    isDesignAttributeActive: (designAttributeName: string) => boolean;
    isAnyEnabled: boolean;
    displayedDesignAttributeName: FlexibilityDesignAttributes | undefined;
    setDisplayedDesignAttributeName: (designAttributeName: FlexibilityDesignAttributes | undefined) => void;
    getCurrentDesignAttributeValue: (designAtributeName: string) => string | undefined;
    onClickFlexibilityOptions: (
        eventDetail: string,
        label: string,
        designAttributeName: FlexibilityDesignAttributes
    ) => void;
    getTranslatedOptionName: (designAttributeName: FlexibilityDesignAttributes) => string | undefined;
    getCurrentTrimIcon: () => JSX.Element;
    getProductSize: () => string | undefined;
    loadNewProductForFlexibility: LoadNewProductForFlexibility;
    getDocument: GetDocument;
};

type Props = {
    children: ReactNode | ReactNode[];
    loadNewProductForFlexibility: LoadNewProductForFlexibility;
    getDocument: GetDocument;
    debouncedDesignDocument: DSS.DesignDocument | undefined;
};

const Context = createContext<ContextData | undefined>(undefined);

export const useActiveFlexibilityOptions = () => {
    const result = useContext(Context);
    if (!result) {
        throw Error("Please call this within an ActiveFlexibilityProvider");
    }
    return result;
};
const { Provider } = Context;

const DebouncedDesignDocumentContext = createContext<DSS.DesignDocument | undefined>(undefined);

export const useDebouncedDesignDocument = () => {
    return useContext(DebouncedDesignDocumentContext);
};
const { Provider: DebouncedDesignDocumentProvider } = DebouncedDesignDocumentContext;

export const ActiveFlexibilityProvider = observer((props: Props) => {
    const { children, loadNewProductForFlexibility, getDocument, debouncedDesignDocument } = props;
    const sizeDesignAttributes = useSizeCompatibleFlexibilityDesignAttributes();
    const premiumFinishDesignAttributes = usePremiumFinishCompatibleFlexibilityDesignAttributes();
    const quantityDesignData = useGetQuantityAttributes();
    const quantityEstimatedPrices = quantityDesignData?.estimatedPrices || {};
    const quantityDesignAttributes = Object.keys(quantityEstimatedPrices);
    const trimDesignAttributes = useCompatibleFlexibilityDesignAttributes(FlexibilityDesignAttributes.Trim);
    const [displayedDesignAttributeName, setDisplayedDesignAttributeName] = useState<
        FlexibilityDesignAttributes | undefined
    >(undefined);
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;
    const designAttributeMappings = useDesignAttributeMappings();
    const getFlexibilityMerchandisingData = useFlexibilityMerchandisingData();
    const { surfaceSpecs } = useSurfaceSpecs();

    const designAttributeValueData = useMemo(
        () => ({
            [FlexibilityDesignAttributes.Size]: sizeDesignAttributes,
            [FlexibilityDesignAttributes.Trim]: trimDesignAttributes,
            [FlexibilityDesignAttributes.PremiumFinish]: premiumFinishDesignAttributes,
            [FlexibilityDesignAttributes.Quantity]: quantityDesignAttributes
        }),
        [sizeDesignAttributes, trimDesignAttributes, premiumFinishDesignAttributes, quantityDesignAttributes]
    );

    const isDesignAttributeActive = useCallback(
        (designAttributeName: string) => {
            // @ts-ignore FIXME: must handle implicit `any` type
            return designAttributeValueData[designAttributeName]?.length > 1;
        },
        [designAttributeValueData]
    );

    const getDesignAttributeValues = useCallback(
        (designAttributeName: string) => {
            // @ts-ignore FIXME: must handle implicit `any` type
            return designAttributeValueData[designAttributeName];
        },
        [designAttributeValueData]
    );

    const getCurrentDesignAttributeValue = useCallback(
        (designAttributeName: string): string | undefined => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const currentProductOptionValue = studioSelectedProductOptions[productOptionName];
            return mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                productOptionName,
                currentProductOptionValue
            );
        },
        [designAttributeMappings, studioSelectedProductOptions]
    );

    const isAnyEnabled = useMemo(
        () => Object.keys(designAttributeValueData).some(isDesignAttributeActive),
        [designAttributeValueData, isDesignAttributeActive]
    );

    const onClickFlexibilityOptions = useCallback(
        (eventDetail: string, label: string, designAttributeName: FlexibilityDesignAttributes) => {
            fireDesignToolTrackingEvent({
                eventDetail,
                label,
                extraData: () => ({
                    designAttributeName
                })
            } as any);
            setDisplayedDesignAttributeName(designAttributeName);
        },
        []
    );

    const getTranslatedOptionName = useCallback(
        (designAttributeName: FlexibilityDesignAttributes) => {
            const currentDesignAttributeValue = getCurrentDesignAttributeValue(designAttributeName);
            if (!currentDesignAttributeValue) return undefined;
            const attributesAndValues = getFlexibilityMerchandisingData(
                designAttributeName,
                currentDesignAttributeValue
            );
            let translatedOptionName = currentDesignAttributeValue;
            if (attributesAndValues) {
                translatedOptionName = attributesAndValues.merchandisingName;
            }
            return translatedOptionName;
        },
        [getCurrentDesignAttributeValue, getFlexibilityMerchandisingData]
    );

    const getCurrentTrimIcon = useCallback(() => {
        const iconName = getCurrentDesignAttributeValue(FlexibilityDesignAttributes.Trim);
        switch (iconName) {
            case TrimIconTypes.ROUNDED:
                return <ChangeTrimToRoundedIcon />;
            case TrimIconTypes.STANDARD:
                return <ChangeTrimToStandardIcon />;
            default:
                return <ChangeTrimIcon />;
        }
    }, [getCurrentDesignAttributeValue]);

    const getProductSize = useCallback(() => {
        if (studioSelectedProductOptions[FlexibilityDesignAttributes.Size]) {
            return getTranslatedOptionName(FlexibilityDesignAttributes.Size);
        }
        // NA locale products has shape attribute instead of size with dimensions,
        // hence falling back to Trim surface specs to display size dimensions.
        const surfaceMasks = surfaceSpecs?.surfaceGroups[0]?.surfaces[0]?.docAdditionalData?.masks;
        const position = surfaceMasks?.find(mask => mask.pathType.toLowerCase() === "trim")?.boundingArea?.position;
        const { height = 0, width = 0 } = position || {};
        const heightInInches = cm2in(height);
        const widthInInches = cm2in(width);
        return `${Math.round(widthInInches * 10) / 10}" x ${Math.round(heightInInches * 10) / 10}"`;
    }, [studioSelectedProductOptions, getTranslatedOptionName, surfaceSpecs]);

    const contextObject = useMemo(
        () => ({
            getDesignAttributeValues,
            isAnyEnabled,
            isDesignAttributeActive,
            displayedDesignAttributeName,
            setDisplayedDesignAttributeName,
            getCurrentDesignAttributeValue,
            onClickFlexibilityOptions,
            getTranslatedOptionName,
            getCurrentTrimIcon,
            getProductSize,
            loadNewProductForFlexibility,
            getDocument
        }),
        [
            isDesignAttributeActive,
            getDesignAttributeValues,
            isAnyEnabled,
            displayedDesignAttributeName,
            setDisplayedDesignAttributeName,
            getCurrentDesignAttributeValue,
            onClickFlexibilityOptions,
            getTranslatedOptionName,
            getCurrentTrimIcon,
            getProductSize,
            loadNewProductForFlexibility,
            getDocument
        ]
    );

    return (
        <Provider value={contextObject}>
            <DebouncedDesignDocumentProvider value={debouncedDesignDocument}>
                {children}
            </DebouncedDesignDocumentProvider>
        </Provider>
    );
});

ActiveFlexibilityProvider.displayName = "ActiveFlexibilityProvider";

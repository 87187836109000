import React, { Suspense, useEffect } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { observer } from "mobx-react-lite";
import { DesignExperienceErrorUi } from "@internal/ui-errors";
import { BoundedContent } from "@vp/swan";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { isDebugMode } from "@internal/utils-browser";
import { useErrors } from "@internal/utils-errors";
import { getTrackingInfoBase } from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { useErrorMessageData, useExtraErrorDetails, ErrorDetailsPanel } from "@shared/features/Errors";
import "./ErrorPage.scss";
import { Helmet } from "react-helmet-async";
import { Header, BookendsMetaTag, BOOKEND_TYPES } from "@vp/esi-bookends";
import { useHandleErrorPageForLocale } from "@internal/dex";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

interface Props {
    errorCodeOverride?: string;
    errorMessageOverride?: string;
    DebugToolbar: any;
}

export const ErrorPage = observer(({ errorCodeOverride, errorMessageOverride, DebugToolbar }: Props) => {
    const { t } = useTranslationSSR();
    const { bookendsHeader } = usePageContext();
    const { locale } = useProductAndProjectStateManager().data;
    const {
        errorState: { errorCode: stateErrorCode, errorMessage: stateErrorMessage }
    } = useErrors();
    const extraErrorDetails = useExtraErrorDetails();
    const errorCode = stateErrorCode || errorCodeOverride;
    const errorMessage = stateErrorMessage || errorMessageOverride;

    const errorData = useErrorMessageData(locale, errorCode, errorMessage);

    const { trackingBase } = getTrackingInfoBase("Studio", true);
    const trackingConfiguration = {
        pageSection: trackingBase.pageSection,
        pageStage: trackingBase.pageStage,
        pageName: trackingBase.pageName
    };

    useEffect(() => {
        if (errorCode) {
            newRelicWrapper.addPageAction("studio-errorpage", {
                errorCode,
                errorMessage
            });
            useHandleErrorPageForLocale({ locale });
        }
    }, [errorMessage, errorCode, locale]);

    return (
        <>
            <BookendsMetaTag
                renderWith={Helmet}
                locale={locale}
                tenant="vistaprint"
                headerType={BOOKEND_TYPES.FULL}
                footerType={BOOKEND_TYPES.FULL}
                hideSearch={true}
            />
            <Suspense fallback={<></>}>{isDebugMode() && <DebugToolbar />}</Suspense>
            <div className="error-page-wrapper">
                {/* TODO: Add the abTestData prop when when top level testing is unlocked in Bookends. */}
                <Header
                    {...bookendsHeader}
                    locale={locale}
                    tenant="vistaprint"
                    hideSearch={true}
                    trackingConfiguration={trackingConfiguration}
                    type={BOOKEND_TYPES.FULL}
                />
                <BoundedContent>
                    <div className="error-page-container">
                        <DesignExperienceErrorUi
                            Icon={<errorData.iconType className={errorData.iconColor} />}
                            errorHeader={t(errorData.errorHeader)}
                            errorSubHeader={t(errorData.errorSubHeader)}
                            primaryButtonMessage={t(errorData.errorButtonMessage)}
                            primaryOnClick={errorData.onClick}
                            secondaryButtonMessage={t(errorData.errorButtonMessageSecondary)}
                            secondaryOnClick={errorData.onClickSecondary}
                            errorDetailChildren={
                                <ErrorDetailsPanel
                                    error={errorCode}
                                    session={errorData.studioUniqueSession}
                                    date={new Date().toUTCString()}
                                    errorMessage={errorMessage}
                                    errorStackTrace={extraErrorDetails.errorStackTrace}
                                    moduleFunction={extraErrorDetails.errorExtraData?.moduleFunction ?? ""}
                                    friendlyDescription={extraErrorDetails.errorExtraData?.friendlyDescription ?? ""}
                                    productKey={extraErrorDetails.productKey}
                                    Quantity={extraErrorDetails.Quantity}
                                />
                            }
                        />
                    </div>
                </BoundedContent>
            </div>
        </>
    );
});

ErrorPage.displayName = "ErrorPage";

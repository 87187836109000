import React, { useState, useRef, useLayoutEffect, KeyboardEvent, PointerEvent as ReactPointerEvent } from "react";
import { HSV, rgb2hex, clamp, hsv2rgb } from "@design-stack-vista/utility-core";
import { useElementDimensions } from "@design-stack-vista/utility-react";
import classNames from "classnames";
import styles from "./ValueAndSaturationBox.module.scss";

export type ValueAndSaturation = Pick<HSV, "s" | "v">;

export interface ValueAndSaturationBoxProps {
    /**
     * The selected color in HSV space
     */
    value: HSV;
    /**
     * Invoked when the user moves the handle
     */
    onChange: (value: ValueAndSaturation) => void;
    /**
     * Invoked when the user ends a selection (e.g. releases the mouse button)
     */
    onRelease?: () => void;
}

const BOX_BORDER_WIDTH = 1;
const HANDLE_WIDTH = 16;
const HANDLE_BORDER_WIDTH = 2;
const HANDLE_MARGIN = 2;
const HANDLE_TOTAL_SIZE = HANDLE_WIDTH + HANDLE_BORDER_WIDTH * 2 + HANDLE_MARGIN * 2;
const HANDLE_OFFSET = HANDLE_TOTAL_SIZE / 2;
const BOX_SIZE_OFFSET = (HANDLE_OFFSET + BOX_BORDER_WIDTH) * 2;

function positionToSv(x: number, y: number, width: number, height: number) {
    const s = (x - HANDLE_OFFSET) / width;
    const v = 1 - (y - HANDLE_OFFSET) / height;
    return { s, v };
}

function svToPosition(s: number, v: number, width: number, height: number) {
    const x = s * width + HANDLE_OFFSET;
    const y = (1 - v) * height + HANDLE_OFFSET;
    return { x, y };
}

export function ValueAndSaturationBox({ value, onChange, onRelease }: ValueAndSaturationBoxProps) {
    const [handlePos, setHandlePos] = useState({ x: 0, y: 0 });
    const boxRef = useRef<HTMLDivElement>(null);
    const boxRect = useElementDimensions(boxRef);
    const [isActive, setActive] = useState(false);
    const boxHeight = boxRect.height - BOX_SIZE_OFFSET;
    const boxWidth = boxRect.width - BOX_SIZE_OFFSET;
    const [boxPos, setBoxPos] = useState({ left: 0, top: 0 });

    useLayoutEffect(() => {
        if (!isActive) {
            setHandlePos(svToPosition(value.s, value.v, boxWidth, boxHeight));
        }
    }, [value, isActive, boxHeight, boxWidth]);

    const updatePosition = (
        { clientX, clientY }: ReactPointerEvent<HTMLDivElement>,
        boxPosition: { left: number; top: number }
    ) => {
        const x = clamp(clientX - boxPosition.left, HANDLE_OFFSET, boxWidth + HANDLE_OFFSET);
        const y = clamp(clientY - boxPosition.top, HANDLE_OFFSET, boxHeight + HANDLE_OFFSET);
        setHandlePos({ x, y });
        onChange(positionToSv(x, y, boxWidth, boxHeight));
    };

    function handlePointerMove(event: ReactPointerEvent<HTMLDivElement>) {
        if (isActive) {
            event.preventDefault();
            updatePosition(event, boxPos);
        }
    }

    function handleRelease(event: ReactPointerEvent<HTMLDivElement>) {
        if (isActive) {
            setActive(false);
            boxRef.current?.releasePointerCapture(event.pointerId);
            onRelease?.();
        }
    }

    function handlePointerDown(event: ReactPointerEvent<HTMLDivElement>) {
        event.preventDefault();
        setActive(true);
        const boxPosition = boxRef.current?.getBoundingClientRect() ?? boxPos;
        setBoxPos(boxPosition);
        updatePosition(event, boxPosition);
        boxRef.current?.setPointerCapture(event.pointerId);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();
        let { x, y } = handlePos;
        const delta = event.shiftKey ? 5 : 1;
        switch (event.key) {
            case "ArrowUp":
                y -= delta;
                break;
            case "ArrowDown":
                y += delta;
                break;
            case "ArrowLeft":
                x -= delta;
                break;
            case "ArrowRight":
                x += delta;
                break;
            default:
                break;
        }

        x = clamp(x, HANDLE_OFFSET, boxWidth + HANDLE_OFFSET);
        y = clamp(y, HANDLE_OFFSET, boxHeight + HANDLE_OFFSET);
        if (x !== handlePos.x || y !== handlePos.y) {
            event.preventDefault();
            setHandlePos({ x, y });
            onChange(positionToSv(x, y, boxWidth, boxHeight));
        }
    };

    const hueColor = rgb2hex(hsv2rgb({ ...value, s: 1, v: 1 }));
    const hexColor = rgb2hex(hsv2rgb(value));

    return (
        <div
            className={styles.wrapperStyle}
            ref={boxRef}
            onPointerDown={handlePointerDown}
            onPointerUp={handleRelease}
            onPointerMove={isActive ? handlePointerMove : undefined}
            style={
                {
                    "--box-border-width": `${BOX_BORDER_WIDTH}px`,
                    "--handle-width": `${HANDLE_WIDTH}px`,
                    "--handle-border-width": `${HANDLE_BORDER_WIDTH}px`,
                    "--handle-offset": `${HANDLE_OFFSET}px`
                } as React.CSSProperties
            }
        >
            <div className={styles.boxStyle} style={{ backgroundColor: hueColor }} />
            <div className={classNames(styles.boxStyle, styles.saturationBoxStyle)} />
            <div className={classNames(styles.boxStyle, styles.valueBoxStyle, { [styles.grabbing]: isActive })} />
            {/* the following div is a 2D color selector for which a corresponding ARIA role does not exist */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- @todo: https://vistaprint.atlassian.net/browse/AST-2430 */}
            <div
                className={classNames(styles.handleStyle, {
                    [styles.grabbing]: isActive,
                    [styles.activeHandle]: isActive
                })}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- @todo: https://vistaprint.atlassian.net/browse/AST-2430
                tabIndex={0}
                onKeyDown={handleKeyDown}
                style={{ left: handlePos.x, top: handlePos.y, backgroundColor: hexColor }}
            />
        </div>
    );
}

ValueAndSaturationBox.displayName = "ValueAndSaturationBox";

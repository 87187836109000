import React, { createContext, useCallback, useContext } from "react";
import { observer } from "mobx-react-lite";
import { COLOR_MODE, SurfaceUpsellPanelProperties } from "../types";
import { useSurfaceUpsellData } from "./SurfaceUpsellDataProvider";

type CanvasSurfaceUpsellPricing = {
    listPrice: number;
    discountPrice: number;
};

const GetCanvasUpsellContext = createContext<
    | ((canvasName: string) =>
          | {
                listPrice: number;
                discountPrice: number;
            }
          | undefined)
    | undefined
>(undefined);

export function useGetCanvasUpsellContext() {
    const context = useContext(GetCanvasUpsellContext);
    if (context === undefined) {
        throw new Error("useGetCanvasUpsellContext must be used within an UpsellProvider");
    }
    return context;
}

const IsPaidUpsellContext = createContext<((canvasName: string) => boolean) | undefined>(undefined);

export function useIsPaidUpsellContext() {
    const context = useContext(IsPaidUpsellContext);
    if (context === undefined) {
        throw new Error("useIsPaidUpsellContext must be used within an UpsellProvider");
    }
    return context;
}

const NeedAddButtonContext = createContext<
    ((panel: SurfaceUpsellPanelProperties, name: string) => boolean) | undefined
>(undefined);

export function useNeedAddButtonContext() {
    const context = useContext(NeedAddButtonContext);
    if (context === undefined) {
        throw new Error("useNeedAddButtonContext must be used within an UpsellProvider");
    }
    return context;
}

export const UpsellProvider = observer((props: React.PropsWithChildren<{}>) => {
    const { surfaceUpsellData } = useSurfaceUpsellData();

    const getUpsellsByCanvasName = useCallback(
        (canvasName: string): CanvasSurfaceUpsellPricing | undefined => {
            const dialogSurfaceUpsell = surfaceUpsellData[canvasName];
            if (!dialogSurfaceUpsell) {
                return undefined;
            }
            const { pricing: allPricing, colorOption } = dialogSurfaceUpsell;
            if (!allPricing || !colorOption) {
                return undefined;
            }
            const pricing = allPricing[colorOption];
            if (!pricing) {
                return undefined;
            }
            return {
                listPrice: pricing.differentialListPrice,
                discountPrice: pricing.differentialDiscountPrice
            };
        },
        [surfaceUpsellData]
    );

    const isPaidUpsell = useCallback(
        (canvasName: string) => {
            const surfaceUpsell = surfaceUpsellData[canvasName];
            if (surfaceUpsell) {
                const { pricing } = surfaceUpsell;
                if (pricing && surfaceUpsell.colorOption) {
                    const currentColorPricing = pricing[surfaceUpsell.colorOption];
                    if (currentColorPricing) {
                        return currentColorPricing.differentialListPrice !== 0;
                    }
                }
            }
            return false;
        },
        [surfaceUpsellData]
    );

    /**
     * Determines whether a _Add_ button should be displayed for a given panel,
     * based on the panel's design requirements and whether the upsell for that panel is a paid upsell.
     *
     * @param panel - The panel design requirements object.
     * @param name - The name of the panel.
     * @returns `true` if the "Add" button should be displayed, `false` otherwise.
     */
    const needAddButton = useCallback(
        (panel: SurfaceUpsellPanelProperties, name: string) => {
            const surfaceUpsell = surfaceUpsellData[name];
            const isCanvasBlank = panel.colorMode === COLOR_MODE.BLANK;
            return isCanvasBlank && surfaceUpsell && isPaidUpsell(name);
        },
        [isPaidUpsell, surfaceUpsellData]
    );

    return (
        <GetCanvasUpsellContext.Provider value={getUpsellsByCanvasName}>
            <IsPaidUpsellContext.Provider value={isPaidUpsell}>
                <NeedAddButtonContext.Provider value={needAddButton}>{props.children}</NeedAddButtonContext.Provider>
            </IsPaidUpsellContext.Provider>
        </GetCanvasUpsellContext.Provider>
    );
});

UpsellProvider.displayName = "UpsellProvider";

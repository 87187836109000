import { action, observable, makeObservable, computed } from "mobx";
import { DesignState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { PanelLayoutExtension } from "@design-stack-vista/core-features";
import {
    BaseExtension,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    DesignExtensionSystem,
    LAYOUT_STORE_TOKEN,
    LayoutStore
} from "@design-stack-vista/interactive-design-engine-core";
import { getBoundingBox as getBoundingBoxFromUtility, Vector2 } from "@design-stack-vista/utility-core";
import { PanelSectionData, PanelSectionSetWithBoundingBox, PanelSectionWithBoundingBox } from "../types";
import { findSectionByPoint } from "../utils/findSectionByPoint";
import { getBoundingBox } from "../utils/getBoundingBox";

const DEFAULT_SECTION_FIRST_FALLBACK = "front";
const DEFAULT_SECTION_SECOND_FALLBACK = "top";

// This extension provides support for accessing and setting
// panel section state for the currently active panel
export class PanelSectionExtension extends BaseExtension {
    declare designState: PanelState;

    static override supports(state: DesignState): boolean {
        return state.isPanelState();
    }

    static inject = ["panelSections", DESIGN_EXTENSION_SYSTEM_TOKEN, LAYOUT_STORE_TOKEN];

    @observable
    selectedSectionId?: string | null = null;

    @observable
    hoveredSectionId?: string | null = null;

    @observable
    panelSectionDataWithBoundingBoxes: PanelSectionSetWithBoundingBox[] = [];

    constructor(
        designState: DesignState,
        private panelSections: PanelSectionData[],
        private designExtensionSystem: DesignExtensionSystem,
        private layoutStore: LayoutStore
    ) {
        super(designState);
        makeObservable(this);
        if (panelSections) {
            const panelSectionDataForActivePanel = panelSections.find(panelSections => {
                return panelSections.panelId === this.designState.id;
            });

            const sectionSetsWithBoundingBoxes = panelSectionDataForActivePanel?.sectionSets.map(sectionSet => {
                return {
                    id: this.designState.id,
                    name: panelSectionDataForActivePanel.location,
                    sections: sectionSet.sections.map(section => {
                        const boundingBox = getBoundingBox(section.paths);
                        const metadata =
                            section.metadata && section.metadata.locationSpecificMetadata
                                ? section.metadata.locationSpecificMetadata[panelSectionDataForActivePanel.location]
                                : {};
                        return {
                            ...section,
                            boundingBox,
                            metadata,
                            panelId: this.designState.id
                        };
                    })
                };
            });
            if (sectionSetsWithBoundingBoxes && panelSectionDataForActivePanel) {
                this.panelSectionDataWithBoundingBoxes = sectionSetsWithBoundingBoxes;
            }
        }
    }

    @computed
    get panelSectionDataForPanel(): PanelSectionSetWithBoundingBox[] | undefined {
        return this.panelSectionDataWithBoundingBoxes;
    }

    @computed
    get panelHasSectionData(): boolean {
        return this.panelSectionDataWithBoundingBoxes ? true : false;
    }

    @action.bound
    setSelectedSection(sectionId: string | null) {
        this.selectedSectionId = sectionId;
    }

    @action.bound
    setHoveredSectionId(sectionId: string | null) {
        this.hoveredSectionId = sectionId;
    }

    @computed
    get selectedSection(): PanelSectionWithBoundingBox | null | undefined {
        let currentSelectedSection = null;
        if (this.panelSectionDataWithBoundingBoxes) {
            const allSectionsFromSectionSet = this.panelSectionDataWithBoundingBoxes
                .map(sectionSet => sectionSet.sections)
                .flat();
            if (allSectionsFromSectionSet) {
                currentSelectedSection = allSectionsFromSectionSet.find(
                    (section: { id: string }) => section.id === this.selectedSectionId
                );
            }
        }
        return currentSelectedSection;
    }

    @computed
    get selectedOrDefaultSection(): PanelSectionWithBoundingBox | null | undefined {
        let currentSelectedSection = this.selectedSection;
        const allSectionsFromSectionSet = this.panelSectionDataWithBoundingBoxes
            .map(sectionSet => sectionSet.sections)
            .flat();
        if (!currentSelectedSection && allSectionsFromSectionSet) {
            currentSelectedSection =
                allSectionsFromSectionSet.find(
                    section => section.name.toLowerCase().trim() === DEFAULT_SECTION_FIRST_FALLBACK
                ) ||
                allSectionsFromSectionSet.find(
                    section => section.name.toLowerCase().trim() === DEFAULT_SECTION_SECOND_FALLBACK
                ) ||
                allSectionsFromSectionSet[0];
        }
        return currentSelectedSection;
    }

    @computed
    get sectionContentOrientation(): number {
        // get the content orientation from the metadata or return 0 degree
        if (
            this.selectedOrDefaultSection &&
            this.selectedOrDefaultSection.metadata &&
            this.selectedOrDefaultSection.metadata.contentOrientation
        ) {
            const { contentOrientation } = this.selectedOrDefaultSection.metadata;
            const useFullAngle = parseFloat(contentOrientation) % 360;
            const positiveAngle = useFullAngle < 0 ? 360 + useFullAngle : useFullAngle;
            return positiveAngle;
        }
        return 0;
    }

    @computed
    get activeSectionBoundingBox() {
        const activeSection = this.selectedOrDefaultSection;
        if (activeSection) {
            const { paths } = activeSection;
            return getBoundingBoxFromUtility(paths);
        }
        return null;
    }

    @action.bound
    setSelectedSectionWithEventPointer(clientX: number, clientY: number, panArea: { x: number; y: number }) {
        const panelLayout = this.designExtensionSystem.getExtension(this.designState.iid, PanelLayoutExtension);

        if (!panelLayout?.position) {
            return undefined;
        }

        const { position } = panelLayout;
        const { offset, zoom } = this.layoutStore;

        const normalizedPointerPosition = {
            x: (clientX - (panArea.x + position.x + window.pageXOffset + offset.x)) / zoom,
            y: (clientY - (panArea.y + position.y + window.pageYOffset + offset.y)) / zoom
        };

        this.setSelectedSectionFromCoordinates(normalizedPointerPosition);
    }
    @action.bound
    setSelectedSectionFromCoordinates(point: Vector2) {
        const panelSectionData = this.panelSectionDataWithBoundingBoxes;

        const selectedPanelSection = findSectionByPoint(panelSectionData[0], point);

        if (selectedPanelSection) {
            this.setSelectedSection(selectedPanelSection.id);
        }
    }
    @action.bound
    setHoveredSectionFromCoordinates(point: Vector2) {
        const panelSectionData = this.panelSectionDataWithBoundingBoxes;

        const selectedPanelSection = findSectionByPoint(panelSectionData[0], point);

        if (selectedPanelSection) {
            this.setHoveredSectionId(selectedPanelSection.id);
        }
    }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const shouldSaveOnChangeSlice = createSlice({
    name: "shouldSaveOnChange",
    initialState: false as boolean,
    reducers: {
        setShouldSaveOnChange(state, action: PayloadAction<boolean>) {
            return action.payload;
        }
    }
});

export const { setShouldSaveOnChange } = shouldSaveOnChangeSlice.actions;
export const shouldSaveOnChange = shouldSaveOnChangeSlice.reducer;

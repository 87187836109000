import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    swatches: string; // Label for tab to view and select a color swatch
    scrollLeft: string; // Accessibility text for the scroll left button for scrolling buttons at the bottom of a sheet on mobile
    scrollRight: string; // Accessibility text for the scroll right button for scrolling buttons at the bottom of a sheet on mobile
    custom: string; // Label for tab to select a custom color
    composition: string; // Label for tab to restore or remove the background of an image
    recentColors: string; //Label for recently used colors
    noColor: string; //Label to select no color/transparent
    eyeDropperAriaLabel: string; //Label to Eyedropper Color Tool,
    original: string; // Label for selecting the image with original background
    noBackground: string; // Label for selecting the image with background removed
    backgroundRemovalTitle: string; // Title for image background removal tool
    hexInputAriaLabel: string; // Accessibility text for hex color input
    hueSliderAriaLabel: string; //Accessibility text for hue color slider
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    custom: {
        id: "studio.components.colorpicker.custom",
        defaultMessage: "Custom",
        description: "Label for tab to select a custom color"
    },
    composition: {
        id: "studio.components.colorpicker.composition",
        defaultMessage: "Composition",
        description: "Label for tab to restore or remove the background of an image"
    },
    swatches: {
        id: "studio.components.colorpicker.swatches",
        defaultMessage: "Swatches",
        description: "Label for tab to view and select a color swatch"
    },
    scrollRight: {
        id: "easel.ui.sheet.horizontal.scroll.scrollRight",
        defaultMessage: "Scroll Right",
        description:
            "Accessibility text for the scroll right button for scrolling buttons at the bottom of a sheet on mobile"
    },
    scrollLeft: {
        id: "easel.ui.sheet.horizontal.scroll.scrollLeft",
        defaultMessage: "Scroll Left",
        description:
            "Accessibility text for the scroll left button for scrolling buttons at the bottom of a sheet on mobile"
    },
    recentColors: {
        id: "easel.components.colorpicker.recentColors",
        defaultMessage: "Recent colors",
        description: "Label for recently used colors"
    },
    noColor: {
        id: "easel.components.colorpicker.noColor",
        defaultMessage: "No Color",
        description: "Label to select no color/transparent"
    },
    eyeDropperAriaLabel: {
        id: "easel.components.colorpicker.eyeDropperAriaLabel",
        defaultMessage: "Eyedropper Color Tool",
        description: "Accessibility Label to Eyedropper Color Tool"
    },
    original: {
        id: "easel.components.colorpicker.original",
        defaultMessage: "Original",
        description: "Label for selecting the image with original background"
    },
    noBackground: {
        id: "easel.components.colorpicker.noBackground",
        defaultMessage: "No background",
        description: "Label for selecting the image with background removed"
    },
    backgroundRemovalTitle: {
        id: "easel.components.colorpicker.backgroundRemovalTitle",
        defaultMessage: "Change how you want your image background to appear.",
        description: "Title for image background removal tool"
    },
    hexInputAriaLabel: {
        id: "easel.components.colorpicker.hexInputAriaLabel",
        defaultMessage: "Hex color code",
        description: "Accessibility label for input to enter hex color code (such as #FFFFFF)"
    },
    hueSliderAriaLabel: {
        id: "easel.components.colorpicker.hueSliderAriaLabel",
        defaultMessage: "Color hue value",
        description: "Accessibility label for slider to select a color's hue value"
    }
};

import * as React from "react";
import { SVGProps } from "react";

export const LightBlueXXSadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 182 209" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
        <g opacity={0.1} filter="url(#a)">
            <path
                d="M171.5 191.8c0 3.6-36 6.5-80.5 6.5s-80.5-3-80.5-6.5c0-3.6 36-6.5 80.5-6.5s80.5 3 80.5 6.5Z"
                fill="#000"
            />
        </g>
        <path
            d="M7.5 43.3c0-1.1.9-2 2-2h144.3v18.9c0 1.1.8 2 2 2h18.7v85.1a2 2 0 0 1-2 2H9.5a2 2 0 0 1-2-2v-104Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 43.8v103h162V64.7h-16.2c-2.5 0-4.5-2-4.5-4.5V43.8H10Zm146.3 3.6 12.2 12.3h-12.2V47.4ZM5 43.3c0-2.5 2-4.5 4.5-4.5h144.3c.6 0 1.3.3 1.7.7l20.8 21c.4.4.7 1 .7 1.7v85.1c0 2.5-2 4.5-4.5 4.5H9.5c-2.5 0-4.5-2-4.5-4.5v-104Z"
            fill="#6A7076"
        />
        <path
            className="color-element"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.6 85.4 64 81.8l-3.5 3.6-3.5-3.6-3.6 3.6 3.6 3.5-3.6 3.5L57 96l3.5-3.6L64 96l3.6-3.6L64 89l3.6-3.5Zm61.7 0-3.5-3.6-3.6 3.6-3.5-3.6-3.5 3.6 3.5 3.5-3.5 3.5 3.5 3.6 3.5-3.6 3.6 3.6 3.5-3.6-3.5-3.5 3.5-3.5Zm-47.8 26.9-1.3-2.1a2.5 2.5 0 0 0 2.6 4.2m-1.3-2 1.3 2 .3-.1 1-.5a22.5 22.5 0 0 1 18.2.8 2.5 2.5 0 1 0 2.3-4.5 27.5 27.5 0 0 0-22.4-1 19 19 0 0 0-2 1l1.3 2.2Z"
            fill="#36B7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.9 3.4a2 2 0 0 1-2 2c-15.1 0-22.6.8-32 2.3l5.7.5 2.4.3c4.5.4 9.3.9 13.6 1.5a41 41 0 0 1 6.9 1.6c.5.2 1 .5 1.4 1 .5.5.8 1.3.6 2.2a3 3 0 0 1-1 1.7c-.7.6-1.8 1-2.9 1.5a152 152 0 0 1-24.1 4.7 131.3 131.3 0 0 0 6.8 1.6l6 1.4a2 2 0 1 1-1.2 3.8 85 85 0 0 0-6.3-1.5 105.8 105.8 0 0 1-10.4-2.9c-.4-.2-.9-.5-1.2-.9-.4-.4-1-1.2-.7-2.2.2-1 .9-1.5 1.3-1.7.4-.3 1-.6 1.5-.7 1.1-.4 2.7-.7 4.7-1 9-1.3 17-2.8 21.4-4.1l-3.4-.6a361.4 361.4 0 0 0-15.8-1.7 413.5 413.5 0 0 1-13.5-1.6c-.6 0-1-.2-1.5-.3-.3-.2-1-.4-1.4-.8a2.2 2.2 0 0 1 0-3.2c.4-.5 1-.7 1.4-.9a191.5 191.5 0 0 1 41.6-4 2 2 0 0 1 2 2Zm-7 10ZM79 23.6ZM71.4 6.4Zm0 2.9Z"
            fill="#6A7076"
        />
        <defs>
            <filter
                id="a"
                x={0.5}
                y={175.3}
                width={181}
                height={33}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation={5} result="effect1_foregroundBlur" />
            </filter>
        </defs>
    </svg>
);

LightBlueXXSadIcon.displayName = "LightBlueXXSadIcon";

import { type Context, type PredictedStep } from "@internal/utils-siteflow";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Payload {
    nextStepUrl: string;
    predictedFlow?: PredictedStep[];
    context: Context[];
}

export interface NextStep {
    url: string;
    context: Context[];
}

const nextStepUrlSlice = createSlice({
    name: "nextStepUrl",
    initialState: null as NextStep | null,
    reducers: {
        setNextStepUrl(state, action: PayloadAction<Payload>) {
            return {
                url: action.payload.nextStepUrl,
                context: action.payload.context
            };
        }
    }
});

export const { setNextStepUrl } = nextStepUrlSlice.actions;
export const nextStepUrl = nextStepUrlSlice.reducer;

import { VistaAsset } from "@design-stack-vista/vista-assets-sdk";

/**
 * Waits for a Vista asset to be prepared and presigned.
 *
 * @param asset - The Vista asset to wait for.
 * @returns A Promise that resolves when the asset is prepared and presigned.
 */
export async function waitForImageToBePrepared(asset: VistaAsset) {
    await asset.prepare();
    await asset.presign();
}
